import React from "react"
import PropTypes from "prop-types"
import { Tag } from "antd"
import capitalize from "lodash.capitalize"

import { PROPERTY_STATUS } from "@components/Domain"

const TAG_COLORS_MAP = {
  [PROPERTY_STATUS.ACTIVE]: "orange",
  [PROPERTY_STATUS.CLOSED]: "blue",
  [PROPERTY_STATUS.SOLD]: "green",
}


const PropertyStatusTag = ({ status }) => {
  const color = TAG_COLORS_MAP[status]
  const title = capitalize(status)

  return <Tag color={color}>{title}</Tag>
}

PropertyStatusTag.propTypes = {
  status: PropTypes.string.isRequired,
}

export default PropertyStatusTag
