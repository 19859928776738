import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { Form as AntForm } from "antd"

import { CreateModal } from "@components/Page"
import { createProjectOperation as createOperation } from "@api/services/backstage"

import getCreateInputs from "./helpers/getCreateInputs"

const LABEL_ENTITY = "Project"
const LABEL_CREATE = `Create ${LABEL_ENTITY}`


const ProjectCreateModal = ({
  modal,
  addProject,
}) => {
  const [ form ] = AntForm.useForm()
  const navigate = useNavigate()

  const [ schema, setSchema ] = useState([])

  const projectType = AntForm.useWatch("type", form)

  useEffect(() => {
    const createInputs = getCreateInputs(projectType)
    const createSchema = createOperation.getSchema(createInputs)

    setSchema(createSchema)
  }, [ projectType ])

  const onSuccess = createdProject => {
    addProject(createdProject)
    navigate(`/backstage/project/${createdProject.id}/details`)
  }

  return (
    <CreateModal
      modal={modal}
      title={LABEL_CREATE}
      entity={LABEL_ENTITY}
      schema={schema}
      onSuccess={onSuccess}
      operation={createOperation}
    />
  )
}

ProjectCreateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  addProject: PropTypes.func.isRequired,
}

export default ProjectCreateModal
