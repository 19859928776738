import React from "react"
import ReactDOM from "react-dom/client"
import * as Sentry from "@sentry/react"
import { BrowserRouter } from "react-router-dom"

import { getConfig } from "@components/Config"
import { initializeSentry } from "@components/AppContext"

import AuthenticatorContext from "./components/AuthenticatorContext"

import App from "./App"

const PLATFORM_EMAIL = getConfig("PLATFORM_EMAIL")

const LABEL_ERROR = `Oops, something went wrong. Please try reloading the
page. If the issue persists, contact`

initializeSentry()

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <Sentry.ErrorBoundary fallback={
    <div style={{ margin: "4px 6px", fontSize: 14 }}>
      {LABEL_ERROR} <a href={`mailto:${PLATFORM_EMAIL}`}>{PLATFORM_EMAIL}</a>.
    </div>
  }>
    <BrowserRouter>
      <AuthenticatorContext>
        <App />
      </AuthenticatorContext>
    </BrowserRouter>
  </Sentry.ErrorBoundary>
)
