export const FUNDING_STATUS = {
  FUNDED: "funded",
  PARTIALLY: "partially",
  OVERFUNDED: "overfunded",
  NOT_FUNDED: "notFunded",
  PENDING: "pending",
}

export const LABELS_MAP = {
  [FUNDING_STATUS.FUNDED]: "Funded",
  [FUNDING_STATUS.PARTIALLY]: "Partially",
  [FUNDING_STATUS.OVERFUNDED]: "Overfunded",
  [FUNDING_STATUS.PENDING]: "Pending",
  [FUNDING_STATUS.NOT_FUNDED]: "Not funded",
}

export const computeInvestmentFundingStatus = ({ isFunded, isPartiallyFunded, isOverfunded, hasSentFunds }) => {
  const isNotFunded = !hasSentFunds && !isFunded

  if (isFunded) {
    return FUNDING_STATUS.FUNDED
  }

  if (isPartiallyFunded) {
    return FUNDING_STATUS.PARTIALLY
  }

  if (isOverfunded) {
    return FUNDING_STATUS.OVERFUNDED
  }

  if (isNotFunded) {
    return FUNDING_STATUS.NOT_FUNDED
  }

  return FUNDING_STATUS.PENDING
}
