import React, { useState, useMemo, useRef, useCallback } from "react"
import keyBy from "lodash.keyby"
import sortBy from "lodash.sortby"
import { List, Typography, Spin } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import { useOutletContext } from "react-router-dom"

import { openNewTab } from "@components/Utils"
import { useRequest as useEffect } from "@components/AppContext"
import { ReadBatchOutputShape, indexBatchFilesOperation as indexOperation } from "@api/services/backstage"

import ListItem from "./ListItem"
import CreateFileDrawer from "../CreateFileDrawer"
import useDeleteFileAction from "./helpers/useDeleteFileAction"
import useNoFileInvestments from "./helpers/useNoFileInvestments"


const BatchFilesList = ({ batch = undefined }) => {
  const createFileDrawer = useRef({})

  const [ files, setFiles ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)

  const { activeInvestments } = useOutletContext()

  const investmentsMap = useMemo(() =>
    keyBy(activeInvestments, "id")
  , [ activeInvestments ])

  const getInvestmentName = useCallback(
    ({ investmentId }) => investmentsMap[investmentId]?.name || investmentId
  , [ investmentsMap ])

  const addFile = newFile =>
    setFiles(prevFiles => sortBy([ ...prevFiles, newFile ], getInvestmentName))

  const removeFile = id =>
    setFiles([ ...files.filter(({ id: fileId }) => fileId !== id) ])

  const deleteFileAction = useDeleteFileAction(removeFile)
  const noFileInvestments = useNoFileInvestments(activeInvestments, files)

  const { id: batchId } = batch || {}

  useEffect(request => {
    if (!batchId) {
      return
    }

    setIsLoading(true)
    request(indexOperation, { batchId })
      .then(({ data }) => sortBy(data, getInvestmentName))
      .then(sortedFiles => setFiles(sortedFiles))
      .then(() => setIsLoading(false))
  }, [
    batchId,
    getInvestmentName
  ])

  const renderSourceFileUrl = ({ url, sourceFileName }) => (
    <Typography.Link onClick={() => openNewTab(url)}>
      {sourceFileName}
    </Typography.Link>
  )

  const openCreateFileDrawer = (investmentId, investorAccountId) =>
    createFileDrawer.current.open({ investmentId, investorAccountId })

  const dataSource = [
    ...files,
    ...noFileInvestments
  ]

  return (
    <Spin spinning={isLoading}>
      <List
        size="small"
        dataSource={dataSource}
        renderItem={item => (
          item.url
          ? (
            <ListItem
              icon={<DeleteOutlined />}
              title={getInvestmentName(item)}
              subtitle={renderSourceFileUrl(item)}
              onClick={() => deleteFileAction(item.id, getInvestmentName(item))}
            />
          )
          : (
            <ListItem
              title={item.name}
              onClick={() => openCreateFileDrawer(item.id, item.investorAccountId)}
            />
          )
        )}
      />

      <CreateFileDrawer
        batch={batch}
        drawer={createFileDrawer}
        onSuccess={addFile}
      />
    </Spin>
  )
}

BatchFilesList.propTypes = {
  batch: ReadBatchOutputShape,
}

export default BatchFilesList
