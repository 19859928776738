import React from "react"

import { ZipCodeInput } from "../../Input"


const renderZipCode = (itemProps, inputProps,form, path) => {
  return (
    <ZipCodeInput
      form={form}
      path={path}
      itemProps={itemProps}
      inputProps={inputProps}
    />
  )
}

export default renderZipCode
