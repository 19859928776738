import React, { useState } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { useForm } from "@components/Form"
import { useAppContext } from "@components/AppContext"
import { InvestmentForm } from "@components/Investment"
import { createInvestmentOperation } from "@api/services/investments"

import InvestmentTab from "./InvestmentTab"
import clearInvalidFields from "./helpers/clearInvalidFields"
import useInvestmentFormProps from "./helpers/useInvestmentFormProps"


const InvestmentCreateTab = ({
  isDeletable = true,
  currentForm = undefined,
  index,
  onCancel,
  openCreateTab,
}) => {
  const form = useForm()

  const [ block, setBlock ] = useState()
  const [ isLoading, setIsLoading ] = useState(false)

  const { request } = useAppContext()
  const { profiles, addInvestment } = useOutletContext()

  const isLastInvestment = true

  const [ onSubmit, formProps ] = useInvestmentFormProps({
    form,
    setIsLoading,
    openCreateTab,
    isLastInvestment,
  })

  if (currentForm) {
    currentForm.current.submit = async () => {
      let mutation = form.getFieldsValue()
      const {profileType, investments} = mutation

      mutation.isProfileValid = true

      const hasFormError = await form.validateFields()
        .then(() => false)
        .catch(() => true)

      if (hasFormError) {
        mutation.isProfileValid = false
        mutation = clearInvalidFields(form, mutation)
      }

      if (!profileType) {
        return
      }

      const { projectId } = formProps
      mutation.projectId = projectId

      if (!investments) {
        mutation.investments = []
      }

      return request(createInvestmentOperation, { mutation })
        .then(({ data }) => addInvestment(data))
    }
  }

  return (
    <InvestmentTab
      index={index}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isLoading={isLoading}
      isDeletable={isDeletable}
      isLastInvestment={true}
    >
      <InvestmentForm
        key={index}
        form={form}
        block={block}
        setBlock={setBlock}
        hasSubmitButton={false}
        existingProfiles={profiles}
        {...formProps}
      />
    </InvestmentTab>
  )
}

InvestmentCreateTab.propTypes = {
  index: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDeletable: PropTypes.bool,
  currentForm: PropTypes.shape(),
  openCreateTab: PropTypes.func.isRequired,
}

export default InvestmentCreateTab
