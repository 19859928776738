import capitalize from "lodash.capitalize"

export const ACCOUNTANT = "ACCOUNTANT"
export const ADMIN = "ADMIN"
export const APPROVER = "APPROVER"
export const MANAGER = "MANAGER"
export const SUPPORT = "SUPPORT"

const UserGroupRoleEnum = {
  [ACCOUNTANT]: ACCOUNTANT,
  [ADMIN]: ADMIN,
  [APPROVER]: APPROVER,
  [MANAGER]: MANAGER,
  [SUPPORT]: SUPPORT
}

const UserGroupRoleEnumKeys = Object.keys(UserGroupRoleEnum)

const UserGroupRoleEnumOptions = UserGroupRoleEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  UserGroupRoleEnumKeys,
  UserGroupRoleEnumOptions
}

export default UserGroupRoleEnum
