import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { Bullet } from "@ant-design/plots"

import PartnerShape from "@api/services/backstage/shapes/PartnerShape"
import { getCurrencyValue, getFormattedDecimalValue } from "@components/Amount"

import getPartnersData from "./helpers/getFundsData"

const LABEL_TOTAL = "Total"


const FundGoalsBarsChart = ({ partners = [] }) => {
  const { fundsMap } = useOutletContext()

  const partnersData = getPartnersData(partners, fundsMap)

  const partnerTarget = partnersData
    .map(({ target }) => target)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  const partnerRaised = partnersData
    .map(({ amount }) => amount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  const total = {
    title: LABEL_TOTAL,
    target: partnerTarget,
    amount: partnerRaised,
    percentage: Math.ceil(partnerRaised / partnerTarget * 100),
    rangePercentage: 150,
    targetPercentage: 100,
  }

  const data = [...partnersData, total].reverse()

  const rowsCount = data.length
  const height = rowsCount * 40

  const config = {
    data,
    height,
    color: {
      range: '#f0efff',
      target: '#3d76dd',
      measure: '#5b8ff9',
    },
    rangeField: 'rangePercentage',
    targetField: 'targetPercentage',
    measureField: 'percentage',
    xField: 'title',
    xAxis: { line: null },
    yAxis: {
      line: null,
      min: 0,
      max: 150,
      label: false,
      grid: {
        line: { style: { opacity: 0 } },
      },
    },
    label: {
      target: {
        content: ({ title }) => {
          const item = data.find(d => d.title === title)
          const { target } = item
          return getCurrencyValue(target, 0)
        },
      },
      measure: false
    },
    tooltip: {
      showMarkers: false,
      shared: true,
      customItems: (originalItems) => {
        const formattedOriginalItems = originalItems.filter(({name}) => name !== 'targetPercentage')

        return formattedOriginalItems.map(item => {
          const dataItem = data.find(d => d.title === item.title)
          const amount = getCurrencyValue(dataItem.amount, 0)
          item.name = "Funded"
          item.value = amount

          return item
        })
      }
    },
  }

  return <Bullet {...config} />
}

FundGoalsBarsChart.propTypes = {
  partners: PropTypes.arrayOf(PartnerShape),
}

export default FundGoalsBarsChart
