import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { Input, Form, Button, Space, Table, Typography } from "antd"
import { CloseOutlined } from "@ant-design/icons"

import FormShape from "../../shapes/FormShape"

import './StringListInput.css'

const { Paragraph } = Typography

const LABEL_ADD = "Add"


const StringListInput = ({
 form,
 path,
 inputProps,
 itemProps
}) => {
  const [ items, setItems ] = useState([])

  const { label, name,...restItemProps } = itemProps

  const inputField = `_${name.slice(-1)}`
  const inputName = [...name.slice(0, -1), inputField]
  const inputPath = [...path.slice(0, -1), inputField]

  const sourceValue = Form.useWatch(path, form)

  const setValue = useCallback(() => {
    const value = form.getFieldValue(path)

    if (!value) {
      return
    }

    setItems(value.map((key) => ({ key })))
  }, [ form, path ])

  useEffect(() => {
    setValue()
  }, [ sourceValue, setValue ])

  const deleteItem = (id) => {
    const newValues = items.filter(({key}) => key !== id)
    setItems(newValues)
    form.setFieldValue(path, newValues.map(({key}) => key))
  }

  const addItem = async () => {
    const hasFormError = await form.validateFields([ inputPath ])
      .then(() => false)
      .catch(() => true)

    if (hasFormError) {
      return
    }

    const newValue = form.getFieldValue(inputPath)
    const newValues = [...items, { key: newValue }]

    setItems(newValues)
    form.resetFields([inputPath])
    form.setFieldValue(path, newValues.map(({key}) => key))
  }

  const footer = () => (
    <Form.Item
      key="input"
      {...restItemProps}
      name={inputName}
      validateTrigger={[]}
    >
      <Space.Compact style={{ width: '100%' }}>
        <Input
          onPressEnter={addItem}
          {...inputProps}
        />

        <Button onClick={addItem}>
          {LABEL_ADD}
        </Button>
      </Space.Compact>
    </Form.Item>
  )

  const columns = [
    {
      dataIndex: "key",
      render: value => (
        <div>
          <span style={{ float: "right" }}>
            <Button
              size="small"
              type="text"
              icon={<CloseOutlined />}
              onClick={() => deleteItem(value)}
            />
          </span>

          {value}
        </div>
      )
    },
  ]

  const tableProps = {
    size: "small",
    dataSource: items,
    pagination: false,
    showHeader: false,
    footer,
    columns,
  }

  return (
    <div className="string-list-input">
      <Form.Item
        key="hidden"
        name={name}
        hidden={true}
      >
        <Input />
      </Form.Item>

      <Paragraph strong>
        {label}
      </Paragraph>

      <Table
        {...tableProps}
      />
    </div>
  )
}

StringListInput.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemProps: PropTypes.shape().isRequired,
  inputProps: PropTypes.shape().isRequired,
}

export default StringListInput
