import { TYPE_NONE } from "@components/Form"


const getUpdateInputs = () => {
  return [
      { name: "mobile" },
      { name: "givenName", type: TYPE_NONE },
      { name: "familyName", type: TYPE_NONE }
    ]
}

export default getUpdateInputs
