import React from "react"
import PropTypes from "prop-types"
import { Card, Spin } from "antd"
import { useOutletContext } from "react-router-dom"

import { useAppContext } from "@components/AppContext"
import { isMobile } from "@components"

import UnusedBankAccountCard from "./UnusedBankAccountCard"

const LABEL_TITLE = "Unused Bank Accounts"


const UnusedBankAccountsCard = ({
  cardProps: otherCardProps = {},
}) => {
  const { investments } = useOutletContext()
  const { identity } = useAppContext()

  const { customers } = identity

  const cardProps = {
    type: "inner",
    size: isMobile ? "small": "medium",
    title: LABEL_TITLE
  }

  return (
    <Spin spinning={false}>
      <Card {...cardProps}>
        <Card.Grid
          size={isMobile ? "small": "medium"}
          style={{
            width: "100%",
            padding: isMobile ? 12 : 24,
            paddingBottom: isMobile ? 6 : 12,
          }}
          hoverable={false}
        >
          {
            customers.map((customer, key) => (
              <UnusedBankAccountCard
                key={key}
                customer={customer}
                investments={investments} />
            ))
          }
        </Card.Grid>
      </Card>
    </Spin>
  )
}

UnusedBankAccountsCard.propTypes = {
  cardProps: PropTypes.shape()
}

export default UnusedBankAccountsCard
