import React from "react"
import { Outlet } from "react-router-dom"

import InvestorInvestmentLayoutContextShape from "./shapes/InvestorInvestmentLayoutContextShape"


const InvestorInvestmentLayoutOutlet = ({ context }) => {
  return (
    <Outlet context={context} />
  )
}

InvestorInvestmentLayoutOutlet.propTypes = {
  context: InvestorInvestmentLayoutContextShape.isRequired,
}

export default InvestorInvestmentLayoutOutlet
