import PropTypes from "prop-types"

import InvestmentTransactionReportShape from "./InvestmentTransactionReportShape"

const properties = {
  distributionsAmount: PropTypes.number,
  equityReceivedAmount: PropTypes.number,
  investmentTransactionReports: PropTypes.arrayOf(InvestmentTransactionReportShape),
  projectId: PropTypes.string
}

const ProjectTransactionReportShape = PropTypes.exact(properties)

export default ProjectTransactionReportShape
export { properties }
