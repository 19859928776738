import React from "react"

import {
  Header,
  TYPE_EIN,
  TYPE_ENUM,
  TYPE_RADIO,
  TYPE_OBJECT,
  TYPE_STRING,
  TYPE_HIDDEN,
} from "@components/Form"
import { getUsAddressSchema, getDwollaAddressSchema } from "@components/Address"
import { SHORT_ENTITY_TYPE_TITLES_MAP } from "@components/Domain"

const LABEL_EIN = "EIN"
const LABEL_BUSINESS = "Business"
const LABEL_ENTITY_NAME = "Entity Name"
const LABEL_ENTITY_TYPE = "Entity Type"
const LABEL_CLICK_SELECT = "Click to select"
const LABEL_CLASSIFICATION = "Classification"
const LABEL_ENTITY_ADDRESS = "Business Physical Address"
const LABEL_CLASSIFICATION_GROUP = "Business Classification According to NAICS"
const LABEL_BUSINESS_INFO = `Please enter the entity name and business address
exactly as they appear on your official business documentation, such as your
EIN Letter (IRS-issued SS4 confirmation letter).

PO boxes, registered agent addresses, shipping offices, and virtual offices are
not accepted.`

const getBusinessTypeOptions = businessTypes => businessTypes
  .map(value => ({ value, label: SHORT_ENTITY_TYPE_TITLES_MAP[value] }))

const getBusinessCustomerInputs = (
  groupOptions,
  classificationOptions,
  businessTypes
) => {
  const addressSchema = getUsAddressSchema()
  const hasMultipleBusinessTypes = businessTypes.length > 1

  const businessTypeInputProps = hasMultipleBusinessTypes
    ? {
      type: TYPE_RADIO,
      options: getBusinessTypeOptions(businessTypes),
    }
    : {
      type: TYPE_HIDDEN,
    }

  return [
    {
      name: "_business",
      component: ({ style }) =>
        <div style={style}>
          <Header info={LABEL_BUSINESS_INFO}>
            {LABEL_BUSINESS}
          </Header>
        </div>
    },
    {
      name: "firstName",
      type: TYPE_HIDDEN,
      required: true,
    },
    {
      name: "lastName",
      type: TYPE_HIDDEN,
      required: true,
    },
    {
      name: "businessName",
      type: TYPE_STRING,
      label: LABEL_ENTITY_NAME,
      required: true,
      maxLength: 255,
    },
    {
      name: "businessType",
      label: LABEL_ENTITY_TYPE,
      required: true,
      ...businessTypeInputProps
    },
    {
      name: "ein",
      type: TYPE_EIN,
      label: LABEL_EIN,
      required: true,
    },
    {
      name: "address",
      type: TYPE_OBJECT,
      label: LABEL_ENTITY_ADDRESS,
      schema: getDwollaAddressSchema(addressSchema),
      required: true,
    },
    {
      name: "classificationGroup",
      type: TYPE_ENUM,
      label: LABEL_CLASSIFICATION_GROUP,
      options: groupOptions,
      placeholder: LABEL_CLICK_SELECT,
      inputProps: {
        disabled: groupOptions.length === 0,
        onChange: (event, form) =>
          form.setFieldValue(["businessCustomer", "businessClassificationId"], null)
      },
      required: true,
    },
    {
      name: "businessClassificationId",
      type: TYPE_ENUM,
      label: LABEL_CLASSIFICATION,
      options: classificationOptions,
      placeholder: LABEL_CLICK_SELECT,
      inputProps: {
        disabled: classificationOptions.length === 0
      },
      required: true,
    },
  ]
}

export default getBusinessCustomerInputs
