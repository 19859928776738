import React from "react"
import { Outlet } from "react-router-dom"

import InvestorProjectLayoutContextShape from "./shapes/InvestorProjectLayoutContextShape"


const InvestorProjectLayoutOutlet = ({ context }) => {
  return (
    <Outlet context={context} />
  )
}

InvestorProjectLayoutOutlet.propTypes = {
  context: InvestorProjectLayoutContextShape.isRequired,
}

export default InvestorProjectLayoutOutlet
