import { subdivision } from "iso-3166-2"


const getRegionNameByCountryAndRegionCode = (countryCode, regionCode) => {
  const region = subdivision(`${countryCode}-${regionCode}`)

  return region ? region.name : regionCode
}

export default getRegionNameByCountryAndRegionCode
