import React from "react"
import { useOutletContext } from "react-router-dom"
import { Space, Tag } from "antd"
import PropTypes from "prop-types"


const UserGroupsColumn = ({ userGroupIds }) => {
  const { getUserGroup } = useOutletContext()

  const hasUserGroupIds = userGroupIds.length > 0

  if (!hasUserGroupIds) {
    return null
  }

  const getUserGroupName = id => {
    const userGroup = getUserGroup(id)

    if (!userGroup) {
      return null
    }

    return userGroup.name
  }

  const activeUserGroupNames = userGroupIds
    .filter(id => !!getUserGroupName(id))
    .map(id => getUserGroupName(id))

  return (
    <Space size={0}>
      {
        activeUserGroupNames.map((name, index) => (
          <Tag key={index}>{name}</Tag>
        ))
      }
    </Space>
  )
}

UserGroupsColumn.propTypes = {
  userGroupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default UserGroupsColumn
