import PropTypes from "prop-types"

import AssetShape from "./shapes/AssetShape"
import TagShape from "./shapes/TagShape"
import TierShape from "./shapes/TierShape"
import { ProjectStateEnumKeys } from "./shapes/ProjectStateEnum"
import { ProjectStatusEnumKeys } from "./shapes/ProjectStatusEnum"
import { ProjectTypeEnumKeys } from "./shapes/ProjectTypeEnum"

const properties = {
  amount: PropTypes.number.isRequired,
  bankAccountId: PropTypes.string,
  closeDate: PropTypes.string,
  coverImageUrl: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  description: PropTypes.string,
  distributionBankAccountId: PropTypes.string,
  distributionTemplates: PropTypes.arrayOf(AssetShape),
  documents: PropTypes.arrayOf(AssetShape),
  fundDeadlineDate: PropTypes.string,
  fundOfferingStructure: PropTypes.string,
  id: PropTypes.string.isRequired,
  investmentSubjectIds: PropTypes.arrayOf(PropTypes.string),
  isAchFundingEnabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  nonAccreditedInvestorsMax: PropTypes.number,
  openDate: PropTypes.string,
  ppmTemplateId: PropTypes.string,
  shortName: PropTypes.string,
  signDeadlineDate: PropTypes.string,
  sourceOrganizationId: PropTypes.string,
  sourceProjectId: PropTypes.string,
  state: PropTypes.oneOf(ProjectStateEnumKeys).isRequired,
  status: PropTypes.oneOf(ProjectStatusEnumKeys).isRequired,
  tags: PropTypes.arrayOf(TagShape),
  targetAmount: PropTypes.number,
  targetCloseDate: PropTypes.string,
  tiers: PropTypes.arrayOf(TierShape).isRequired,
  type: PropTypes.oneOf(ProjectTypeEnumKeys).isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  videos: PropTypes.arrayOf(AssetShape)
}

const IndexPublishedProjectsOutputShapeShape = PropTypes.exact(properties)

export default IndexPublishedProjectsOutputShapeShape
export { properties }
