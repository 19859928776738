import React from "react"
import PropTypes from "prop-types"
import { Button } from "antd"

const SUBMIT_HTML_TYPE = "submit"


const SubmitButton = ({
  style = {},
  onClick = undefined,
  isLoading = false,
  isDisabled = false,
  children,
  ...otherProps
}) => {
  const buttonProps = {
    type: "primary",
    size: "large",
    shape: "round",
    style: { width: "100%", ...style },
    loading: isLoading,
    disabled: isDisabled,
    ...otherProps
  }

  if (!onClick) {
    buttonProps.htmlType = SUBMIT_HTML_TYPE
  }

  if (onClick) {
    buttonProps.onClick = onClick
  }

  return (
    <Button {...buttonProps}>
      {children}
    </Button>
  )
}

SubmitButton.propTypes = {
  style: PropTypes.shape(),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default SubmitButton
