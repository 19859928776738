import React, { useEffect } from "react"
import PropTypes from "prop-types"


const BeforeUnload = ({
  children
}) => {
  useEffect(() => {
    const listener = (event) => {
      event.preventDefault()

      if (event.defaultPrevented) {
        return (event.returnValue = '')
      }
    }

    window.addEventListener('beforeunload', listener)

    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [])

  return (
    <>
      {children}
    </>
  )
}

BeforeUnload.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired
}

export default BeforeUnload
