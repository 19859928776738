import { useCallback } from "react"

import { ROLES } from "@components/Domain"


const isBrokerOnly = user =>
  user.roles.includes(ROLES.BROKER) && user.roles.length === 1


const useFilterItemsCallback = () => {
  return useCallback(
    users => users
      .filter(user => !isBrokerOnly(user))
  , [])
}

export default useFilterItemsCallback
