import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { Modal } from "@components/Page"
import Form, { useForm } from "@components/Form"
import { useAppContext } from "@components/AppContext"
import { uploadMyCustomerBeneficialOwnerDocumentOperation } from "@api/services/transactions"

import useInputs from "./helpers/useInputs"

const LABEL_TITLE = "Identity Verification"
const LABEL_SUBMIT = "Continue"
const LABEL_SUCCESS = "Document successfully uploaded"


const UploadDocumentsModal = ({
  onSuccess: onSuccessCustom,
  modal,
  customerId,
}) => {
  const form = useForm()

  const [ isOpen, setIsOpen ] = useState(false)
  const [ beneficialOwner, setBeneficialOwner ] = useState({})

  const [ schema, updateSchema ] = useInputs(form)

  const {
    showSuccessMessage,
    authenticatedRequest,
  } = useAppContext()

  useEffect(() =>
    updateSchema(beneficialOwner)
  , [ beneficialOwner, updateSchema ])

  const onClose = () =>
    setIsOpen(false)

  const onSuccess = async () => {
    showSuccessMessage(LABEL_SUCCESS)
    await onSuccessCustom()
  }

  modal.current.open = newBeneficialOwner => {
    setBeneficialOwner(newBeneficialOwner)
    setIsOpen(true)
  }

  modal.current.close = () =>
    onClose()

  const onSubmit = parameters => {
    const { beneficialOwnerUrl } = beneficialOwner

    parameters.customerId = customerId
    parameters.beneficialOwnerUrl = beneficialOwnerUrl

    return parameters
  }

  const onSubmitFailed = () => form.resetFields()

  const formOptions = {
    item: beneficialOwner,
    request: authenticatedRequest,
    operation: uploadMyCustomerBeneficialOwnerDocumentOperation,
    submitLabel: LABEL_SUBMIT,
    hasSubmitButton: false,
    shouldOptimizeUpdateMutation: false,
    form,
    schema,
    onSubmit,
    onSubmitFailed,
    onSuccess,
  }

  return (
    <Modal
      title={LABEL_TITLE}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Form
        {...formOptions}
      />
    </Modal>
  )
}

UploadDocumentsModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
}

export default UploadDocumentsModal
