import React from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import { Table } from "@components/Table"
import { indexOperation } from "@components/Store/InvestorStore/useDocumentsStore"
import { useProjectItems } from "@components/Project"

import useTableColumns from "./helpers/useTableColumns"

const { Title } = Typography

const LABEL_TITLE = "Documents"

const DEFAULT_SORT_COLUMN = "date"
const DEFAULT_SORT_COLUMN_DIRECTION = "descend"


const DocumentsTable = ({ table }) => {
  const {
    project,
    documents,
    indexDocuments: indexItems,
  } = useOutletContext()

  const items = useProjectItems(documents)
  const columnsConfig = useTableColumns()

  const size = "middle"
  const className = "table-custom-header"
  const hasHeaderFilters = true
  const isColumnSearchEnabled = true
  const defaultSortColumn = DEFAULT_SORT_COLUMN
  const defaultSortColumnDirection = DEFAULT_SORT_COLUMN_DIRECTION

  const scroll = { x: 800 }

  const tableProps = {
    size,
    items,
    table,
    scroll,
    className,
    indexItems,
    columnsConfig,
    indexOperation,
    hasHeaderFilters,
    defaultSortColumn,
    isColumnSearchEnabled,
    defaultSortColumnDirection
  }

  const hasHeader = !project

  return (
    <>
      {
        hasHeader && (
          <div style={{ padding: "0 8px" }}>
            <Title level={3}>
              {LABEL_TITLE}
            </Title>
          </div>
        )
      }

      <Table {...tableProps} />
    </>
  )
}

DocumentsTable.propTypes = {
  table: PropTypes.shape().isRequired,
}

export default DocumentsTable
