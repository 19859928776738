import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { useForm } from "@components/Form"
import { CreateModal } from "@components/Page"

import useSchema, { operation } from "./helpers/useSchema"

const LABEL_ENTITY = "Investor"
const LABEL_CREATE = `Create ${LABEL_ENTITY}`


const InvestorCreateModal = ({ modal, openInvestorDrawer }) => {
  const form = useForm()

  const {
    addAccount,
  } = useOutletContext()

  const schema = useSchema(form, openInvestorDrawer)

  const onSuccess = newInvestor => {
    addAccount(newInvestor)
    openInvestorDrawer(newInvestor.id)
  }

  const createModalProps = {
    title: LABEL_CREATE,
    entity: LABEL_ENTITY,
    successMessage: false,
    form,
    modal,
    schema,
    onSuccess,
    operation,
  }

  return (
    <CreateModal {...createModalProps} />
  )
}

InvestorCreateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  openInvestorDrawer: PropTypes.func.isRequired,
}

export default InvestorCreateModal
