import getImgixUrl from "./getImgixUrl"

const IMG_REGEX = /<img\s+[^>]*src="([^"]*)"[^>]*>/gi


const convertToImgixUrls = (campaignHtml) => {
  if (!campaignHtml) {
    return
  }

  const matches = campaignHtml.matchAll(IMG_REGEX)
  const imgTags = Array.from(matches, (match) => match[0])

  for (const imgTag of imgTags) {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = imgTag

    const imageElement = tempDiv.querySelector('img')
    const { width, height, src: imgUrl } = imageElement

    const options = {
      dpr: 2, // Set device pixel ratio (2x for retina)
    }

    if (width) {
      options.w = width
    }

    if (height) {
      options.h = height
    }

    const imgixUrl = getImgixUrl(imgUrl, options)
    imageElement.src = imgixUrl

    campaignHtml = campaignHtml.replace(imgTag, imageElement.outerHTML)
  }

  return campaignHtml
}

export default convertToImgixUrls
