import React from "react"
import { useOutletContext } from "react-router-dom"
import { PlusOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"

const LABEL_CREATE = "Create"


const useHeaderActions = (openCreateModal) => {
  const { isOrganizationController } = useOutletContext()

  const isController = isOrganizationController()

  const canCreateBankAccount =
    hasAccess(["bank-accounts-write"]) && isController

  if (!canCreateBankAccount) {
    return []
  }

  return [
    {
      icon: <PlusOutlined />,
      title: LABEL_CREATE,
      action: openCreateModal,
      disabled: !canCreateBankAccount,
    },
  ]
}

export default useHeaderActions
