import { TYPE_NONE, TYPE_OBJECT } from "@components/Form"

import getStepInputs from "./getStepInputs"
import getControllerInputs from "./getControllerInputs"
import getBusinessCustomerInputs from "./getBusinessCustomerInputs"
import getSolePropriatorshipCustomerInputs from "./getSolePropriatorshipCustomerInputs"

const getInputs = ({
  form,
  goStep,
  customer,
  currentStep,
  updateSchema,
  groupOptions,
  businessTypes,
  classificationOptions
}) => {
  const { soleProprietorshipCustomer, businessCustomer } = customer

  const updateAddressSchema = () => updateSchema(currentStep)

  if (soleProprietorshipCustomer) {
    return [
      {
        name: "businessCustomer",
        type: TYPE_NONE,
      },
      {
        name: "soleProprietorshipCustomer",
        type: TYPE_OBJECT,
        label: "",
        schema: getSolePropriatorshipCustomerInputs(groupOptions, classificationOptions),
      },
    ]
  }

  if (businessCustomer) {
    const businessInputs = getBusinessCustomerInputs(groupOptions, classificationOptions, businessTypes)
    const controllerInputs = getControllerInputs(updateAddressSchema, form)

    const stepInputs = getStepInputs(currentStep, goStep, businessInputs, controllerInputs)

    return [
      {
        name: "businessCustomer",
        type: TYPE_OBJECT,
        label: "",
        schema: stepInputs,
      },
      {
        name: "soleProprietorshipCustomer",
        type: TYPE_NONE,
      },
    ]
  }

  return []
}

export default getInputs
