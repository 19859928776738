import capitalize from "lodash.capitalize"

export const FUND = "FUND"
export const PORTFOLIO = "PORTFOLIO"
export const SYNDICATION = "SYNDICATION"

const ProjectTypeEnum = {
  [FUND]: FUND,
  [PORTFOLIO]: PORTFOLIO,
  [SYNDICATION]: SYNDICATION
}

const ProjectTypeEnumKeys = Object.keys(ProjectTypeEnum)

const ProjectTypeEnumOptions = ProjectTypeEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  ProjectTypeEnumKeys,
  ProjectTypeEnumOptions
}

export default ProjectTypeEnum
