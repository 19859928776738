import React from "react"
import { useParams } from "react-router-dom"

import { isMobile } from "@components"

import TransactionsList from "./TransactionsList"
import TransactionsTable from "./TransactionsTable"

const LABEL_TRANSACTIONS = "Transactions"
const LABEL_DISTRIBUTIONS = "Distributions"


const InvestorTransactionsPage = () => {
  const { projectId } = useParams()

  const title = projectId
    ? LABEL_DISTRIBUTIONS
    : LABEL_TRANSACTIONS

  if (isMobile) {
    return <TransactionsList title={title} />
  }

  return <TransactionsTable title={title} />
}

export default InvestorTransactionsPage
