import React, { useState } from "react"
import PropTypes from "prop-types"
import { Drawer as AntDrawer } from "antd"

import { HEADER_HEIGHT, DRAWER_WIDTH } from "@components"


const Drawer = ({
  styles: customStyles = {},
  extra = undefined,
  onOpen = () => {},
  onClose = () => {},
  children = undefined,
  title,
  drawer,
  ...otherDrawerProps
}) => {
  const [ isOpen, setIsOpen ] = useState(false)

  const onDrawerOpen = target => {
    onOpen(target)
    setIsOpen(true)
  }

  const onDrawerClose = () => {
    setIsOpen(false)
    onClose()
  }

  drawer.current.open = target =>
    onDrawerOpen(target)

  drawer.current.close = () =>
    onDrawerClose()

  const styles = {
    header: { minHeight: HEADER_HEIGHT + 1 },
    ...customStyles
  }

  return (
    <AntDrawer
      open={isOpen}
      title={title}
      extra={extra}
      width={DRAWER_WIDTH}
      styles={styles}
      onClose={onDrawerClose}
      {...otherDrawerProps}
    >
      {
        children && (
          <>
            {children}
          </>
        )
      }
    </AntDrawer>
  )
}

Drawer.propTypes = {
  extra: PropTypes.shape(),
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.shape().isRequired
  ]).isRequired,
  styles: PropTypes.shape(),
  drawer: PropTypes.shape().isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any
}

export default Drawer
