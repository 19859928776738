import { W1 } from "@components"
import { TYPE_RADIO, TYPE_NONE, TYPE_HIDDEN, TYPE_DATETIME } from "@components/Form"

const LABEL_SEND_NO = "Do not send"
const LABEL_SEND_YES = "Schedule for"
const LABEL_DATETIME = "Date & Time"
const LABEL_NOTIFICATIONS = "Notifications"
const LABEL_TYPE = "Type"
const LABEL_TYPE_PLACEHOLDER = "Select document type"


const getInputs = (sentAt, isSent, typeOptions) => {
  const commonInputProps = {
     size: "medium",
  }

  const shouldSchedule = !isSent

  const scheduledAtProps = !shouldSchedule
    ? { type: TYPE_NONE }
    : {
        type: TYPE_DATETIME,
        label: LABEL_DATETIME,
        required: true,
        itemProps: {
          style: { marginBottom: W1 },
        },
        inputProps: {
          minuteStep: 15,
          showNow: true,
          ...commonInputProps,
        }
      }

  const items = [
    {
      name: "name",
    },
    {
      name: "date",
      type: TYPE_HIDDEN
    },
    {
      name: "documentType",
      label: LABEL_TYPE,
      options: typeOptions,
      required: true,
      placeholder: LABEL_TYPE_PLACEHOLDER,
      hasAutoComplete: true,
    },
  ]

  if (sentAt) {
    return items
  }

  items.push(
    {
      name: "isSent",
      type: TYPE_RADIO,
      label: LABEL_NOTIFICATIONS,
      initialValue: true,
      options: [
        {
          value: true,
          label: LABEL_SEND_NO,
        },
        {
          value: false,
          label: LABEL_SEND_YES,
        },
      ],
      itemProps: {
        style: { marginBottom: W1 },
      },
    },
    {
      name: "scheduledAt",
      label: "",
      ...scheduledAtProps,
    },
  )

  return items
}

export default getInputs
