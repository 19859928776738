import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import {PROJECT_STATUSES} from "@components/Domain"

import ProjectPartners from "./ProjectPartners"
import getCollectionItems from "./helpers/getCollectionItems"


const ProjectFunds = ({ organizationName }) => {
  const [ items, setItems ] = useState([])

  const { sponsors, funds, project, brokers } = useOutletContext()
  const { status, id: projectId, partners = [] } = project

  const isPublishedProject = status === PROJECT_STATUSES.PUBLISHED

  const mainSponsor = sponsors
    .find(({ name }) => name === organizationName)

  useEffect(() => {
    const result = getCollectionItems(projectId, brokers, isPublishedProject, partners, [ mainSponsor ], funds)

    setItems(result)
  }, [
    funds,
    status,
    brokers,
    sponsors,
    partners,
    projectId,
    mainSponsor,
    isPublishedProject,
  ])

  return (
    <ProjectPartners
      items={items}
      setItems={setItems}
    />
  )
}

ProjectFunds.propTypes = {
  organizationName: PropTypes.string.isRequired,
}

export default ProjectFunds
