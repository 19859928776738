import React from "react"
import { Row, Col, Typography } from "antd"

import { Value } from "@components/Text"
import { getFormattedDecimalValue } from "@components/Amount"

const { Text } = Typography


const useRenderItem = () => {
  const render = (id, { name, closeDate, capitalInvested, receiveDistribution }) =>
    <>
      <Row>
        <Col flex="auto">
          <Text strong>{name}</Text>
        </Col>
        <Col>
          <Text type="secondary">Invested: </Text>
          <Value value={getFormattedDecimalValue(capitalInvested, 100, "times")} />
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <Text type="secondary">
            Funded on <Value value={closeDate} />
          </Text>
        </Col>
        <Col>
          <Text type="secondary">Received to date: </Text>
          <Value value={receiveDistribution * 100} />
        </Col>
      </Row>
    </>
  return render
}

export default useRenderItem
