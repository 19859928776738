import React from "react"
import { Link, useOutletContext, useParams } from "react-router-dom"
import { Typography } from "antd"

import { Value } from "@components/Text"


const useTableColumns = () => {
  const { projectId } = useParams()
  const { getInvestmentProjectName } = useOutletContext()

  const columns = []

  const investmentColumnProps = {
    key: "investmentId",
    title: "Investment",
    isFilterEnabled: true,
    compute: ({ investmentId }) =>
      getInvestmentProjectName(investmentId),
    render: (name, document) =>
      <Typography.Text strong>
        <Link to={`/projects/${document.projectId}/about`}>
          {name}
        </Link>
      </Typography.Text>,
  }

  const documentTypeProps = {
    key: "documentType",
    title: "Type",
    isFilterEnabled: true,
  }

  const nameProps = {
    key: "sourceFileName",
    title: "Name",
    render: (value, { url }) =>
      <Typography.Link
        href={url}
        target="_blank"
      >
        {value}
      </Typography.Link>,
  }

  const dateProps = {
    key: "date",
    title: "Date",
    render: value =>
      <Value value={value} />,
  }

  if (!projectId) {
    columns.push({ ...investmentColumnProps, width: "20%" })
  }

  columns.push(
    { ...documentTypeProps, width: "20%" },
    { ...nameProps, width: "40%" },
    { ...dateProps, width: "20%" }
  )

  return columns
}

export default useTableColumns
