import PropTypes from "prop-types"

import CommitmentProfileShape from "./shapes/CommitmentProfileShape"
import InvestorShape from "./shapes/InvestorShape"

const properties = {
  brokerUserId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  hasUser: PropTypes.bool,
  id: PropTypes.string.isRequired,
  investmentIds: PropTypes.arrayOf(PropTypes.string),
  investor: InvestorShape.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isPostponed: PropTypes.bool,
  profiles: PropTypes.arrayOf(CommitmentProfileShape).isRequired,
  projectId: PropTypes.string.isRequired,
  sponsorId: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const CreateCommitmentOutputShapeShape = PropTypes.exact(properties)

export default CreateCommitmentOutputShapeShape
export { properties }
