import PropTypes from "prop-types"

import BusinessClassificationShape from "./BusinessClassificationShape"

const properties = {
  classifications: PropTypes.arrayOf(BusinessClassificationShape).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

const BusinessClassificationGroupShape = PropTypes.exact(properties)

export default BusinessClassificationGroupShape
export { properties }
