import React, { useRef, useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import { App } from "antd"
import { useParams } from "react-router-dom"

import { Table } from "@components/Table"
import { BatchDrawer } from "@modules/backstage/documents"
import { useAppContext } from "@components/AppContext"
import {
  indexProjectBatchesOperation as indexOperation,
  deleteBatchOperation as deleteOperation
} from "@api/services/backstage"

import useColumnsConfig from "./helpers/useColumnsConfig"
import useHeaderActions from "./helpers/useHeaderActions"
import BatchCreateModal from "../BatchCreateModal"
import confirmBatchDelete from "./helpers/confirmBatchDelete"
import BatchFilesCreateModal from "../BatchFilesCreateModal"

const LABEL_DELETE_MESSAGE = "Batch $ is deleted"


const BatchesTable = ({ table }) => {
  const { modal } = App.useApp()

  const drawer = useRef({})
  const batchCreateModal = useRef({})
  const batchFilesCreateModal = useRef({})

  const { id: projectId } = useParams()
  const { request, showSuccessMessage } = useAppContext()

  const openBatchDrawer = useCallback(
    batch => drawer.current.open(batch)
  , [])

  const openDeleteConfirm = useCallback(batchId => {
    const { name: batchName } = table.current.getItem(batchId)

    confirmBatchDelete(modal, request, batchId, () => {
      table.current.deleteItem(batchId)
      showSuccessMessage(LABEL_DELETE_MESSAGE.replace("$", `"${batchName}"`))
    })
  }, [
    table,
    modal,
    request,
    showSuccessMessage
  ])

  const openFileUploadModal = () =>
    batchFilesCreateModal.current.open()

  const openBatchUploadModal = () =>
    batchCreateModal.current.open()

  const onSuccess = newItem =>
    table.current.addItem(newItem)

  const size = "small"
  const className = "last-column-action"
  const columnsConfig = useColumnsConfig(openBatchDrawer, openDeleteConfirm)
  const headerActions = useHeaderActions(openFileUploadModal, openBatchUploadModal)
  const defaultSortColumn = "date"
  const operationParameters = useMemo(() => ({ projectId }), [ projectId ])
  const defaultSortColumnDirection = "descend"

  const tableProps = {
    size,
    table,
    request,
    className,
    headerActions,
    columnsConfig,
    indexOperation,
    deleteOperation,
    defaultSortColumn,
    operationParameters,
    defaultSortColumnDirection,
  }

  return (
    <>
      <Table {...tableProps} />

      <BatchDrawer
        table={table}
        drawer={drawer}
      />

      <BatchCreateModal
        key={`investor-files-${projectId}`}
        modal={batchCreateModal}
        onSuccess={onSuccess}
      />

      <BatchFilesCreateModal
        key={`project-file-${projectId}`}
        modal={batchFilesCreateModal}
        onSuccess={onSuccess}
      />
    </>
  )
}

BatchesTable.propTypes = {
  table: PropTypes.shape().isRequired,
}

export default BatchesTable
