import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"

import { W1 } from "@components"

import BeneficialOwnerCreateModal from "../BeneficialOwnerCreateModal"

const LABEL_ADD = "Add Owner"


const AddBeneficialOwnerButton = ({
  onSuccess,
  customerId,
  isDisabled,
}) => {
  const modal = useRef({})

  const onClick = () =>
    modal.current.open()

  const style = {
    width: "100%",
    marginTop: W1,
    marginBottom: W1
  }

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        type="dashed"
        shape="round"
        style={style}
        onClick={onClick}
        disabled={isDisabled}
      >
        {LABEL_ADD}
      </Button>

      <BeneficialOwnerCreateModal
        modal={modal}
        onSuccess={onSuccess}
        customerId={customerId}
      />
    </>
  )
}

AddBeneficialOwnerButton.propTypes = {
  customerId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default AddBeneficialOwnerButton
