import React from "react"
import PropTypes from "prop-types"
import { Space, Typography, Row, Col } from "antd"

import { DropdownButton } from "@components/Button"
import { W0, W1, HEADER_HEIGHT } from "@components"

import SearchInput from "./SearchInput"

const { Text } = Typography


const Header = ({
  menu = undefined,
  onSearch = undefined,
  titleExtra = undefined,
  title,
}) => {
  const hasMenu =
    !!menu &&
    menu.length > 0

  const paddingLeft = hasMenu
    ? W0
    : W1

  const style = {
    height: HEADER_HEIGHT,
    paddingLeft: paddingLeft + 6,
    paddingRight: W1 + 6,
  }

  return (
    <Row align="middle" style={style}>
      <Col flex="auto">
        <Space>
          {
            hasMenu ? (
              <DropdownButton
                name={title}
                items={menu}
                dropdownRender={items => (
                  <div style={{ minWidth: 180 }}>
                    {React.cloneElement(items, {})}
                  </div>
                )}
              />
            ) : (
              <Text strong style={{ fontSize: 17, paddingLeft: 2, paddingRight: 4 }}>
                {title}
              </Text>
            )
          }
          {titleExtra && titleExtra}
        </Space>
      </Col>

      {
        onSearch && (
          <Col>
            <SearchInput onSearch={onSearch} />
          </Col>
        )
      }
    </Row>
  )
}

Header.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string.isRequired,
  onSearch: PropTypes.func,
  titleExtra: PropTypes.node,
}

export default Header
