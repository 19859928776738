import capitalize from "lodash.capitalize"

export const OTHER = "OTHER"
export const TRUSTEE = "TRUSTEE"

const EntityMembershipInterestsTitleEnum = {
  [OTHER]: OTHER,
  [TRUSTEE]: TRUSTEE
}

const EntityMembershipInterestsTitleEnumKeys = Object.keys(EntityMembershipInterestsTitleEnum)

const EntityMembershipInterestsTitleEnumOptions = EntityMembershipInterestsTitleEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  EntityMembershipInterestsTitleEnumKeys,
  EntityMembershipInterestsTitleEnumOptions
}

export default EntityMembershipInterestsTitleEnum
