
const getAddress = (address = {}) => {
  const {
    streetAddress,
    addressLocality,
    addressRegion,
    postalCode
  } = address

  return [
    streetAddress,
    addressLocality,
    addressRegion,
    postalCode
  ]
    .filter(value => !!value)
    .filter(value => value !== "")
    .filter(value => value !== " ")
    .join(", ")
}

export default getAddress
