

const IndexProjectBatchesItemSchema = {
  date: { format: 'date' },
  isPublicFile: { type: 'boolean' },
  scheduledAt: { format: 'date-time' },
  isSent: { type: 'boolean' },
  sentAt: { format: 'date-time' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexProjectBatchesItemSchema
