import React from "react"
import PropTypes from "prop-types"
import { Dropdown, Space, Typography, Button } from "antd"
import { DownOutlined } from "@ant-design/icons"

import { W0 } from "@components"

const { Text } = Typography


const DropdownButton = ({
  dropdownRender = undefined,
  name,
  items,
}) => {
  const extraProps = {}

  if (dropdownRender) {
    extraProps.dropdownRender = dropdownRender
  }

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      {...extraProps}
    >
      <Button
        type="text"
        style={{
          paddingLeft: W0,
          paddingRight: W0,
        }}
      >
        <Space size={4}>
          <Text strong style={{ fontSize: 17 }}>
            {name}
          </Text>

          <DownOutlined
            style={{
              top: -1,
              fontSize: 11,
              position: "relative",
            }}
          />
        </Space>
      </Button>
    </Dropdown>
  )
}

DropdownButton.propTypes = {
  name: PropTypes.oneOfType([ PropTypes.shape(), PropTypes.string ]).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dropdownRender: PropTypes.func,
}

export default DropdownButton
