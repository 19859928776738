import React, { useEffect, useRef } from "react"
import { useOutletContext } from "react-router-dom"

import { isMobile } from "@components"
import { runRequest } from "@components/AppContext"

import DocumentsList from "./DocumentsList"
import DocumentsTable from "./DocumentsTable"


const InvestorDocumentsPage = () => {
  const table = useRef({})
  const { documents, indexDocuments } = useOutletContext()

  useEffect(() => {
    if (documents) {
      return
    }

    return runRequest(indexDocuments)
  }, [ documents, indexDocuments ])

  if (!isMobile) {
    return <DocumentsTable table={table} />
  }

  return (
    <DocumentsList table={table} />
  )
}

export default InvestorDocumentsPage
