import React from "react"

import FormHeader from "./FormHeader"


const ConfirmSignUpHeader = () => {
  return (
    <FormHeader
      heading="Email Sent"
      // eslint-disable-next-line no-warning-comments
      // TODO: Make sure of this copy for custom components:
      // text="Your code is on the way. To log in, enter the code emailed to a***@***. It might take a few moments for the code to arrive."
    />
  )
}

export default ConfirmSignUpHeader
