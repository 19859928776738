import { getAmountValueFromCurrency } from "@components/Amount"

export const ASCEND = "ascend"
export const DESCEND = "descend"


export const getColumnSortingProps = compute => {
  const props = {
    sorter: (a, b) => {
      let aComputed = compute(a) || ""
      let bComputed = compute(b) || ""

      const isNumber = typeof aComputed === 'number'
      const isString = typeof aComputed === 'string'
      const isMoney = isString && aComputed.startsWith("$")
      const isArray = Array.isArray(aComputed)

      if (isNumber) {
        return aComputed - bComputed
      }

      if (isMoney) {
        return getAmountValueFromCurrency(aComputed) - getAmountValueFromCurrency(bComputed)
      }

      if (isArray) {
        // eslint-disable-next-line prefer-destructuring
        aComputed = aComputed[0] || ""
        // eslint-disable-next-line prefer-destructuring
        bComputed = bComputed[0] || ""
      }

      return aComputed.localeCompare(bComputed)
    },
    sortDirections: [ASCEND, DESCEND]
  }

  return props
}
