import React, { useState, useEffect, useCallback } from "react"
import groupBy from "lodash.groupby"
import { Row, Col, Card } from "antd"
import { useOutletContext } from "react-router-dom"

import { W0 } from "@components"
import { useAppContext } from "@components/AppContext"
import { getProjectStatistics } from "@components/Project"
import { PROFILE_TYPES_LABELS, INVESTMENT_STATUS_ACTIVE } from "@components/Domain"

import PieChart from "./charts/PieChart"
import getPartnersData from "./charts/helpers/getFundsData"
import FundingDonutChart from "./charts/FundingDonutChart"
import FundGoalsBarsChart from "./charts/FundGoalsBarsChart";
import SponsorGoalsBarsChart from "./charts/SponsorGoalsBarsChart"

const LABEL_TYPES = "Profile Types"
const LABEL_FUNDING = "Funding"
const LABEL_CLASSES = "Classes"
const LABEL_FUND_GOALS = "Fund Goals"
const LABEL_SPONSOR_GOALS = "Sponsor Goals"


const InvestmentsExtendedSummary = () => {
  const { project, investments, fundsMap } = useOutletContext()
  const { identity } = useAppContext()
  const { organization: { parentOrganizationId } } = identity

  const [ statistics, setStatistics ] = useState()
  const [ investmentsMapBySponsorId, setInvestmentsMapBySponsorId ] = useState({})

  const updateAmounts = useCallback((projectInvestments = []) => {
    const projectStatistic = getProjectStatistics(project, projectInvestments)
    setStatistics(projectStatistic)

    const activeInvestments = projectInvestments
      .filter(({ status }) => status === INVESTMENT_STATUS_ACTIVE)

    setInvestmentsMapBySponsorId(groupBy(activeInvestments, 'sponsorId'))
  }, [ project ])

  const { partners = [] } = project
  const partnersData = getPartnersData(partners, fundsMap)

  const isChildFundOrganization = !!parentOrganizationId
  const shouldShowFundCharts = !isChildFundOrganization && partnersData.length > 0

  useEffect(() => {
    updateAmounts(investments)
  }, [
    investments,
    updateAmounts
  ])

  const shouldRender = !!project && !!statistics

  if (!shouldRender) {
    return null
  }

  const amountByTier = () => {
    const { tiers } = project
    const { tiersAmount } = statistics

    return tiers
      .filter(({ isPrivate }) => !isPrivate)
      .map(({ name, title }) => {
        const tierData = tiersAmount.find(tier => tier.name === name)

        const value = tierData?.value ? tierData.value : 0
        const count = tierData?.count ? tierData.count : 0

        return {
          type: title,
          countValue: count,
          value,
        }
      })
  }

  const amountByProfileType = () => {
    const items = []

    const {
      profileTypes,
      profileTypesCount
    } = statistics

    // eslint-disable-next-line guard-for-in
    for (const type in profileTypes) {
      const title = PROFILE_TYPES_LABELS[type]
      const value = profileTypes[type]
      const countValue = profileTypesCount[type]

      items.push({
        type: title,
        value,
        countValue
      })
    }
    return items
  }

  const pieChartBodyStyle = { padding: 0 }
  const lineChartBodyStyle = { paddingTop: W0, paddingBottom: W0 }

  return (
    <Row wrap={24} gutter={[ W0, W0 ]}>
      <Col span={8}>
        <Card
          size="small"
          title={LABEL_FUNDING}
          style={{ body: pieChartBodyStyle }}
        >
          <FundingDonutChart statistics={statistics} />
        </Card>
      </Col>
      <Col span={8}>
        <Card
          size="small"
          title={LABEL_CLASSES}
          style={{ body: pieChartBodyStyle }}
        >
          <PieChart data={amountByTier()} />
        </Card>
      </Col>
      <Col span={8}>
        <Card
          size="small"
          title={LABEL_TYPES}
          style={{ body: pieChartBodyStyle }}
        >
          <PieChart data={amountByProfileType()} />
        </Card>
      </Col>
      <Col span={24}>
        <Card
          size="small"
          title={LABEL_SPONSOR_GOALS}
          style={{ body: lineChartBodyStyle }}
        >
          <SponsorGoalsBarsChart
            partners={partners}
            investmentsMapBySponsorId={investmentsMapBySponsorId}
          />
        </Card>
      </Col>
      {
        shouldShowFundCharts && (
          <Col span={24}>
            <Card
              size="small"
              title={LABEL_FUND_GOALS}
              style={{ body: lineChartBodyStyle }}
            >
              <FundGoalsBarsChart
                partners={partners}
              />
            </Card>
          </Col>
        )
      }
    </Row>
  )
}

export default InvestmentsExtendedSummary
