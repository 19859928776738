import PropTypes from "prop-types"


const properties = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  expiration: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  linkToken: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const ReadSourceLinkOutputShapeShape = PropTypes.exact(properties)

export default ReadSourceLinkOutputShapeShape
export { properties }
