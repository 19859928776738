import React from "react"

import { wait } from "@api"
import { getSdiraBusinessName, SdiraConnectBankAccountAlert } from "@components/Dwolla"
import {
  INVESTMENT_PROFILE_TYPE_SDIRA,
  INVESTMENT_PROFILE_TYPE_ENTITY,
} from "@components/Domain"
import { updateMyInvestmentSourcesOperation } from "@api/services/investments"

const LABEL_SDIRA_CONNECT_SOURCE = "Connect Custodian Bank Account"

const NEW_SOURCE_CONNECT_DELAY = 250


const getInvestmentSourceSelectProps = ({
  isSender = false,
  request,
  investment,
  confirmRetryFailedTransactionsModal,
  onInvestmentSourceChanged,
  connectBankAccountPlaidConfirmModal,
  connectBankAccountManuallyConfirmModal,
}) => {
  const {
    id: investmentId,
    name: businessName,
    profileType,
    senderSourceId,
    receiverSourceId,
  } = investment

  const getMutation = (sourceId) => isSender
    ? {
        senderSourceId: sourceId
      }
    : {
        receiverSourceId: sourceId
      }

  const onSelect = (sourceId, source, isNewSource) =>
    wait(isNewSource ? NEW_SOURCE_CONNECT_DELAY : 0)
      .then(
        () => request(updateMyInvestmentSourcesOperation, {
          id: investment.id,
          mutation: getMutation(sourceId)
        })
      )
      .then(({ data }) => onInvestmentSourceChanged(data, isNewSource))

  const sourceId = isSender
    ? senderSourceId
    : receiverSourceId

  const props = {
    sourceId,
    onSelect,
    dropdownProps: {
      onSelectSource: (previousSource, callback) =>
        confirmRetryFailedTransactionsModal
          ? confirmRetryFailedTransactionsModal.current.open(previousSource, [investmentId], callback)
          : callback(),
      onConnectPlaidSelected: onOk => connectBankAccountPlaidConfirmModal
        ? connectBankAccountPlaidConfirmModal.current.open(onOk)
        : onOk()
    }
  }

  if (connectBankAccountManuallyConfirmModal) {
    props.dropdownProps.onConnectConnectManuallySelected = onOk =>
      connectBankAccountManuallyConfirmModal.current.open(onOk)
  }

  const isEntity = profileType === INVESTMENT_PROFILE_TYPE_ENTITY

  if (isEntity) {
    props.businessName = businessName
  }

  const isSdira = profileType === INVESTMENT_PROFILE_TYPE_SDIRA

  if (isSdira) {
    const connectSourceHeader = <SdiraConnectBankAccountAlert />

    props.businessName = getSdiraBusinessName(businessName)
    props.isReceiveOnly = true
    props.dropdownProps = {
      ...props.dropdownProps,
      connectSourceHeader,
      connectSourceTitle: LABEL_SDIRA_CONNECT_SOURCE
    }
  }

  return props
}

export default getInvestmentSourceSelectProps
