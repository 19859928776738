import capitalize from "lodash.capitalize"

export const ACTIVE = "ACTIVE"
export const HOLD = "HOLD"
export const PROSPECT = "PROSPECT"

const AccountStatusEnum = {
  [ACTIVE]: ACTIVE,
  [HOLD]: HOLD,
  [PROSPECT]: PROSPECT
}

const AccountStatusEnumKeys = Object.keys(AccountStatusEnum)

const AccountStatusEnumOptions = AccountStatusEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  AccountStatusEnumKeys,
  AccountStatusEnumOptions
}

export default AccountStatusEnum
