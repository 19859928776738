import React from "react"
import PropTypes from "prop-types"

import { ProgressModal } from "@components/Page"

import useProjectsReport from "./helpers/useProjectsReport"

const LABEL_TITLE = "Projects Report"


const ExportProjectsModal = ({ modal }) => {
  const onReady = () => modal.current.close()

  const [ percent, start, stop ] = useProjectsReport(onReady)

  const modalProps = {
    title: LABEL_TITLE,
    stop,
    modal,
    start,
    percent,
  }

  return (
    <ProgressModal {...modalProps} />
  )
}

ExportProjectsModal.propTypes = {
  modal: PropTypes.shape().isRequired
}

export default ExportProjectsModal
