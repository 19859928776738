import capitalize from "lodash.capitalize"

export const AK = "AK"
export const AL = "AL"
export const AR = "AR"
export const AS = "AS"
export const AZ = "AZ"
export const CA = "CA"
export const CM = "CM"
export const CO = "CO"
export const CT = "CT"
export const DC = "DC"
export const DE = "DE"
export const FL = "FL"
export const GA = "GA"
export const GU = "GU"
export const HI = "HI"
export const IA = "IA"
export const ID = "ID"
export const IL = "IL"
export const IN = "IN"
export const KS = "KS"
export const KY = "KY"
export const LA = "LA"
export const MA = "MA"
export const MD = "MD"
export const ME = "ME"
export const MI = "MI"
export const MN = "MN"
export const MO = "MO"
export const MS = "MS"
export const MT = "MT"
export const NC = "NC"
export const ND = "ND"
export const NE = "NE"
export const NH = "NH"
export const NJ = "NJ"
export const NM = "NM"
export const NV = "NV"
export const NY = "NY"
export const OH = "OH"
export const OK = "OK"
export const OR = "OR"
export const PA = "PA"
export const PR = "PR"
export const RI = "RI"
export const SC = "SC"
export const SD = "SD"
export const TN = "TN"
export const TT = "TT"
export const TX = "TX"
export const UT = "UT"
export const VA = "VA"
export const VI = "VI"
export const VT = "VT"
export const WA = "WA"
export const WI = "WI"
export const WV = "WV"
export const WY = "WY"

const UsStateEnum = {
  [AK]: AK,
  [AL]: AL,
  [AR]: AR,
  [AS]: AS,
  [AZ]: AZ,
  [CA]: CA,
  [CM]: CM,
  [CO]: CO,
  [CT]: CT,
  [DC]: DC,
  [DE]: DE,
  [FL]: FL,
  [GA]: GA,
  [GU]: GU,
  [HI]: HI,
  [IA]: IA,
  [ID]: ID,
  [IL]: IL,
  [IN]: IN,
  [KS]: KS,
  [KY]: KY,
  [LA]: LA,
  [MA]: MA,
  [MD]: MD,
  [ME]: ME,
  [MI]: MI,
  [MN]: MN,
  [MO]: MO,
  [MS]: MS,
  [MT]: MT,
  [NC]: NC,
  [ND]: ND,
  [NE]: NE,
  [NH]: NH,
  [NJ]: NJ,
  [NM]: NM,
  [NV]: NV,
  [NY]: NY,
  [OH]: OH,
  [OK]: OK,
  [OR]: OR,
  [PA]: PA,
  [PR]: PR,
  [RI]: RI,
  [SC]: SC,
  [SD]: SD,
  [TN]: TN,
  [TT]: TT,
  [TX]: TX,
  [UT]: UT,
  [VA]: VA,
  [VI]: VI,
  [VT]: VT,
  [WA]: WA,
  [WI]: WI,
  [WV]: WV,
  [WY]: WY
}

const UsStateEnumKeys = Object.keys(UsStateEnum)

const UsStateEnumOptions = UsStateEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  UsStateEnumKeys,
  UsStateEnumOptions
}

export default UsStateEnum
