import React from "react"
import PropTypes from "prop-types"
import { Alert, List, Typography, Space, Row, Col } from "antd"

const LABEL_RESOLVE = "Resolve"


const InvestmentErrors = ({
  errors = [],
  isFunded,
  onResolve,
}) => {
  const dataSource = errors
    .map((text, key) => ({ key, text }))

  const hasErrors = dataSource.length > 0

  if (!hasErrors) {
    return null
  }

  if (isFunded) {
    return null
  }

  const style = {
    padding: "4px 10px"
  }

  const renderItem = item =>
    <List.Item style={{ padding: "1px 0px 1px 0px", border: "none" }}>
      <Space>
        <Typography.Text strong>&bull;</Typography.Text>
        <Typography.Text>{item.text}</Typography.Text>
      </Space>
    </List.Item>

  const description = (
    <Row>
      <Col flex="auto">
        <List
          size="small"
          gutter={0}
          itemLayout="vertical"
          dataSource={dataSource}
          renderItem={renderItem}
        />
      </Col>
      <Col>
        <Typography.Link onClick={onResolve}>
          {LABEL_RESOLVE}
        </Typography.Link>
      </Col>
    </Row>
  )

  return (
    <div style={{ marginTop: 4, marginBottom: 4 }}>
      <Alert
        type="error"
        style={style}
        description={description}
      />
    </div>
  )
}

InvestmentErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  isFunded: PropTypes.bool.isRequired,
  onResolve: PropTypes.func.isRequired,
}

export default InvestmentErrors
