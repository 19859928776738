import React, { useState } from "react"
import PropTypes from "prop-types"
import { Typography, Button } from "antd"

import { Value } from "@components/Text"
import { useAppContext } from "@components/AppContext"
import { OperationShape } from "@api"


const CreateVerificationRequest = ({
  mobile,
  enableOperation,
  setVerificationRequestId,
}) => {
  const [ isLoading, setIsLoading ] = useState(false)

  const { request, showErrorMessage } = useAppContext()

  const createVerificationRequest = async () => {
    let verificationRequestId

    try {
      const { data } = await request(enableOperation, {}, [ 'UnprocessibleConditionError' ])
      verificationRequestId = data.verificationRequestId

    } catch (error) {
      const isExpected = error.code === 'UnprocessibleConditionError'

      if (!isExpected) {
        console.error(error)
        return
      }

      const message = 'Verification code could be send in 1 minute'
      showErrorMessage(message)
      return
    }

    setVerificationRequestId(verificationRequestId)
  }

  const onClick = async () => {
    setIsLoading(true)
    await createVerificationRequest()
    setIsLoading(false)
  }

  return (
    <>
      <Typography.Paragraph>
        {"To confirm your mobile number, we'll be sending you a verification code through SMS."}
      </Typography.Paragraph>

      <Typography.Paragraph>
        {"Mobile number: "}
        <Typography.Text strong>
          <Value value={mobile} />
        </Typography.Text>
      </Typography.Paragraph>

      <Button
        size="large"
        type="primary"
        loading={isLoading}
        onClick={onClick}
      >
        {"Send verification code"}
      </Button>
    </>
  )
}

CreateVerificationRequest.propTypes = {
  mobile: PropTypes.string.isRequired,
  enableOperation: OperationShape.isRequired,
  setVerificationRequestId: PropTypes.func.isRequired,
}

export default CreateVerificationRequest
