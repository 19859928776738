import React from "react"
import PropTypes from "prop-types"
import { EnvironmentOutlined } from '@ant-design/icons'
import { Descriptions, Typography } from "antd"

import { isMobile } from "@components"
import { EditableGroup } from "@components/Button"

import PropertyShape from "../shapes/PropertyShape"
import PropertyInformationTags from "../PropertyInformationTags"
import { useProjectDetailsContext } from "./ProjectDetailsContext"

const { Item } = Descriptions
const { Title } = Typography


const ProjectPropertyCard = ({ property, isEditable = true }) => {
  const { openPropertyUpdateModal } = useProjectDetailsContext()

  const { name, address, tags = [] } = property
  const { streetAddress, addressLocality, addressRegion } = address

  const addressString = `${streetAddress} ${addressLocality} ${addressRegion}`

  const onClick = () => openPropertyUpdateModal(property.id)

  const title = (
    <Title
      type="secondary"
      level={5}
      style={{ margin: 0 }}
    >
      <EnvironmentOutlined />
      {" "}
      {addressString}
    </Title>
  )

  const items = tags
    .filter(tag => tag.group === PropertyInformationTags.group)
    .map(({ label, value }) => <Item key={label} label={label}>{value}</Item>)

  const labelStyle = {
    width: "50%"
  }

  if (isMobile) {
    labelStyle.padding = 8
  }

  return (
    <EditableGroup
      level={4}
      title={name}
      onClick={onClick}
      isEditable={isEditable}
      marginBottom={0}
    >
      <Descriptions
        bordered
        size="small"
        title={title}
        column={1}
        className="compact black"
        labelStyle={labelStyle}
      >
        { items }
      </Descriptions>
    </EditableGroup>
  )
}

ProjectPropertyCard.propTypes = {
  property: PropertyShape.isRequired,
  isEditable: PropTypes.bool,
}

export default ProjectPropertyCard
