import { TRANSACTION_TYPES } from "@components/Domain"

import getTransactionDetailsValue from "../../TransactionCreateModal/helpers/getTransactionDetailsValue"


const computeTransactionsToCreate = (distributions, date, investments, projectName) => {
  const transactions = []

  for (const distribution of distributions) {
    const {
      rowIndex: _rowIndex,
      id,
      profileName,
      profitOnSale,
      sponsorPromote,
      returnOfCapital,
      investorAccountId,
      returnOnInvestment,
    } = distribution

    const currentInvestment = investments
      .find(({ id: investmentId }) => investmentId === id)

    const entityName = currentInvestment?.name

    const details = getTransactionDetailsValue(date, entityName, projectName)

    const commonFields = {
      investmentId: id,
      date,
      details,
      profileName,
      investorAccountId,
      _rowIndex,
    }

    if (returnOnInvestment > 0) {
      transactions.push({
        ...commonFields,
        type: TRANSACTION_TYPES.INVESTMENT_RETURN,
        amount: returnOnInvestment
      })
    }

    if (profitOnSale > 0) {
      transactions.push({
        ...commonFields,
        type: TRANSACTION_TYPES.SALE_PROFIT,
        amount: profitOnSale
      })
    }

    if (returnOfCapital > 0) {
      transactions.push({
        ...commonFields,
        type: TRANSACTION_TYPES.CAPITAL_RETURN,
        amount: returnOfCapital
      })
    }

    if (sponsorPromote > 0) {
      transactions.push({
        ...commonFields,
        type: TRANSACTION_TYPES.SPONSOR_PROMOTE,
        amount: sponsorPromote,
      })
    }
  }

  return transactions
}

export default computeTransactionsToCreate
