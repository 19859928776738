

const getTransactionDetailsValue = (date, entityName, projectName) => {
  let transactionMonth = ""

  if (date) {
    const [ year, month ] = date.split("-")
    transactionMonth = `${month}/${year}`
  }

  const fullDetailsValue = [
    entityName,
    projectName,
    transactionMonth
  ].join(" - ")

  const isLongDetails = fullDetailsValue.length > 80

  if (isLongDetails) {
    const shortDetailsValue = fullDetailsValue.substring(0, 77)

    return `${shortDetailsValue}...`
  }

  return fullDetailsValue
}

export default getTransactionDetailsValue
