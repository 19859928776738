import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Select } from "antd"
import { useOutletContext } from "react-router-dom"

import { W1 } from "@components"

import getInvestorOptions from "./helpers/getInvestorOptions"

const LABEL_PLACEHOLDER = "Select investor"


const SelectInvestor = ({
  value = undefined,
  onChange
}) => {
  const { accounts } = useOutletContext()

  const filterOption = (input, option) =>
    (option?.text ?? '').toLowerCase().includes(input.toLowerCase())

  const style = {
    width: "100%",
    marginBottom: W1,
  }

  const options = useMemo(() =>
    getInvestorOptions(accounts)
  , [ accounts ])

  const isLoading = !accounts

  const optionFilterProp = "children"

  const selectProps = {
    loading: isLoading,
    showSearch: true,
    placeholder: LABEL_PLACEHOLDER,
    style,
    value,
    options,
    onChange,
    filterOption,
    optionFilterProp,
  }

  return (
    <Select {...selectProps} />
  )
}

SelectInvestor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default SelectInvestor
