import React, { useMemo, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { Bold } from "@components/Text"
import { SelectInput } from "@components/Form/Input"
import { FormShape, useWatch } from "@components/Form"

import useSenders from "./helpers/useSenders"
import EmailIdentityAlert from "./EmailIdentityAlert"

const LABEL_FROM = "From"

const FIELD_FROM_NAME = "fromName"
const FIELD_FROM_ADDRESS = "fromAddress"


const CampaignFromInput = ({
  form,
  name,
  isDisabled,
  saveCampaign
}) => {
  const replyToAddress = useWatch("replyToAddress", form)

  const { organization } = useOutletContext()
  const [ senders, sendersMap ] = useSenders(organization)

  const options = useMemo(() =>
    senders
      .map(({ fromName, fromAddress: value, disabled }) => ({
        label: `${fromName} <${value}>`,
        value,
        disabled,
      }))
  , [ senders ])

  const onChange = useCallback(email => {
    let fromName = ""
    let fromAddress = ""
    let isAvailable = false

    if (email) {
      const sender = sendersMap[email]

      if (sender) {
        const isSenderDisabled = sender.disabled

        if (!isSenderDisabled) {
          fromName = sender.fromName
          fromAddress = sender.fromAddress
          isAvailable = true
        }
      }
    }

    form.setFieldValue(FIELD_FROM_NAME, fromName)
    form.setFieldValue(FIELD_FROM_ADDRESS, fromAddress)

    return isAvailable
  }, [
    form,
    sendersMap
  ])

  const ensureFromAddressAvailable = useCallback(() => {
    if (replyToAddress === undefined) {
      return
    }

    if (!replyToAddress) {
      form.setFieldValue("replyToAddress", undefined)
      return
    }

    const isAvailable = onChange(replyToAddress)

    if (!isAvailable) {
      form.setFieldValue("replyToAddress", "")
    }
  }, [
    form,
    onChange,
    replyToAddress
  ])

  useEffect(() => {
    ensureFromAddressAvailable()
  }, [
    options,
    replyToAddress,
    ensureFromAddressAvailable
  ])

  const itemProps = {
    label: <Bold>{LABEL_FROM}</Bold>,
    name,
  }

  const inputProps = {
    size: "large",
    variant: "filled",
    disabled: isDisabled,
    onChange
  }

  const selectInputProps = {
    isMultiple: false,
    form,
    options,
    itemProps,
    inputProps,
  }

  return (
    <>
      <SelectInput
        {...selectInputProps}
      />

      <EmailIdentityAlert
        organization={organization}
        saveCampaign={saveCampaign}
      />
    </>
  )
}

CampaignFromInput.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  saveCampaign: PropTypes.func.isRequired,
}

export default CampaignFromInput
