import React from "react"
import PropTypes from "prop-types"
import { InfoCircleOutlined } from "@ant-design/icons"
import { Col, Row, Space, Card, Statistic, Tooltip, Typography } from "antd"

import { Value } from "@components/Text"
import { W1, W2, isMobile } from "@components"
import { getFormattedDecimalValue } from "@components/Amount"


import useStatisticItems from "./helpers/useStatisticItems"

const { Text } = Typography

const LABEL_INVESTMENTS = "Dashboard"


const InvestmentsStatistics = ({ hasContainer = true }) => {
  const items = useStatisticItems()


  if (isMobile) {
    const formattedItems = items
      .map(item => {
        const { value } = item
        const isNumber = typeof value === "number"

        return isNumber
          ? {
            ...item,
            value: getFormattedDecimalValue(value, 100, "times")
          }
          : item
      })

    const rowStyle = {
      borderBottom: "1px dashed #dedede",
    }

    const containerStyle = {
      width: "100%",
      ...(hasContainer ? {
        padding: "8px 12px",
        borderBottom: "1px solid #f0f0f0",
      } : {})
    }

    const children =
      <Space
        size={4}
        style={containerStyle}
        direction="vertical"
      >
        {
          formattedItems.map(({ title, value }, key) =>
            <Row key={key} style={key < items.length - 1 ? rowStyle : {}}>
              <Col flex="auto">
                {title}
              </Col>
              <Col>
                <Value value={value} />
              </Col>
            </Row>
          )
        }
      </Space>

    if (!hasContainer) {
      return children
    }

    return (
      <Card
        type="inner"
        size={"small"}
        title={LABEL_INVESTMENTS}
        styles={{ body: { padding: 0 }}}
        bordered={false}
        className="card-mobile"
      >
        {children}
      </Card>
    )
  }

  const renderTitle = (title, tooltip) =>
    <Space>
      <Text type="secondary">{title}</Text>
      {
        tooltip && (
          <Tooltip title={tooltip}>
            <InfoCircleOutlined />
          </Tooltip>
        )
      }
    </Space>

  const style = {
    padding: "0 8px",
    marginTop: W1,
    marginBottom: W2
  }

  return (
    <div style={style}>
      <Row gutter={W2}>
        {
          items.map(({ title, value, tooltip, props = { prefix: "$" } }, key) =>
            <Col key={key} flex="auto">
              <Statistic
                title={renderTitle(title, tooltip)}
                value={value}
                {...props}
              />
            </Col>
          )
        }
      </Row>
    </div>
  )
}

InvestmentsStatistics.propTypes = {
  hasContainer: PropTypes.bool,
}

export default InvestmentsStatistics
