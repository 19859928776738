import { useMemo } from "react"
import keyBy from "lodash.keyby"
import sortBy from "lodash.sortby"


const useNoFileInvestments = (investments, files) => {
  return useMemo(() => {
    if (!investments) {
      return []
    }

    const filesMap = keyBy(files, "investmentId")
    const filteredInvestments = investments
      .filter(({ id }) => !filesMap[id])

    const sortedNoFileInvestments = sortBy(filteredInvestments, "name")

    return sortedNoFileInvestments
  }, [ investments, files ])
}

export default useNoFileInvestments
