import React from "react"
import { Typography } from "antd"
import PropTypes from "prop-types"

const { Text } = Typography


const Bold = ({
  children
}) => {
  return (
    <Text strong>{children}</Text>
  )
}

Bold.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired
}

export default Bold
