import PropTypes from "prop-types"

import { IndexPropertiesOutputShape } from "@api/services/backstage"


const PropertyShape = PropTypes.oneOfType([
  IndexPropertiesOutputShape
])

export default PropertyShape
