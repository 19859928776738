import React from "react"
import PropTypes from "prop-types"
import { Alert } from "antd"

import { W1 } from "@components"

import Markdown from "./Markdown"


const MarkdownAlert = ({
  type = "info",
  style: extraStyle = {},
  text,
}) => {
  const style = {
    paddingTop: 0,
    marginBottom: W1,
    paddingBottom: 0,
    ...extraStyle
  }

  return (
    <Alert
      type={type}
      style={style}
      message={
        <Markdown text={text} />
      }
    />
  )
}

MarkdownAlert.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  style: PropTypes.shape(),
}

export default MarkdownAlert
