import capitalize from "lodash.capitalize"

export const ACTIVE = "ACTIVE"
export const BACKUP = "BACKUP"
export const REJECTED = "REJECTED"

const InvestmentStatusEnum = {
  [ACTIVE]: ACTIVE,
  [BACKUP]: BACKUP,
  [REJECTED]: REJECTED
}

const InvestmentStatusEnumKeys = Object.keys(InvestmentStatusEnum)

const InvestmentStatusEnumOptions = InvestmentStatusEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  InvestmentStatusEnumKeys,
  InvestmentStatusEnumOptions
}

export default InvestmentStatusEnum
