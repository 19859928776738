import { BankAccountTypeEnumKeys } from "./shapes/BankAccountTypeEnum"

const IndexBankAccountsItemSchema = {
  accountType: { enum: BankAccountTypeEnumKeys },
  isSourceVerified: { type: 'boolean' },
  isInvalid: { type: 'boolean' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexBankAccountsItemSchema
