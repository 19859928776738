import PropTypes from "prop-types"


const properties = {
  authenticationJwt: PropTypes.string,
  email: PropTypes.string.isRequired,
  id: PropTypes.string,
  is2FaEnabled: PropTypes.bool
}

const AuthenticateAsOutputShapeShape = PropTypes.exact(properties)

export default AuthenticateAsOutputShapeShape
export { properties }
