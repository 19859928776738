import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { Button, Divider, Input, Select, Space, Spin, Typography, Row, Col } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { usePapaParse } from "react-papaparse"
import sortBy from "lodash.sortby"
import { useOutletContext, useParams } from "react-router-dom"

import { Value } from "@components/Text"
import { parseCsv } from "@components/Import"
import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"
import { getCustomUploadRequest } from "@components/Storage"
import { updateProjectOperation } from "@api/services/backstage"

import parseDistributions from "./helpers/parseDistributions"

const LABEL_ADD = "Add"
const LABEL_PLACEHOLDER_INPUT = "Template name"
const LABEL_PLACEHOLDER_SELECT = "Select a template"

const DOCUMENT_TYPE = "DOCUMENT"


const DistributionTemplates = ({
  investments = [],
  uploadedDistribution = undefined,
  setErrors,
  onSelected,
}) => {
  const inputRef = useRef(null)

  const { request, showErrorMessage } = useAppContext()
  const { id: projectId } = useParams()
  const { readRemoteFile } = usePapaParse()

  const { project, updateProject } = useOutletContext()

  const [ name, setName ] = useState('')
  const [ isLoading, seIsLoading ] = useState(false)

  const { distributionTemplates = [] } = project

  const customRequest = getCustomUploadRequest(request, false)

  const onNameChange = (event) => {
    const { target: { value } } = event
    setName(value)
  }

  const addItem = async () => {
    const countTemplates = distributionTemplates.length

    const defaultName = `New template ${countTemplates + 1}`
    const templateName = name || defaultName

    seIsLoading(true)

    const uploadedFile = async ({ url, path, isPublic }) => {
      const type = DOCUMENT_TYPE
      const createdAt = new Date().toISOString()

      const newDistributionTemplates = [
        ...distributionTemplates,
        {
          name: templateName,
          url,
          type,
          path,
          isPublic,
          createdAt,
        }
      ]

      const mutation = { distributionTemplates: newDistributionTemplates }

      await request(updateProjectOperation, { id: projectId, mutation })
        .then(({ data }) => updateProject(data))
        .then(() => setName(''))
        .then(() => seIsLoading(false))

      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }

    const onError = error => {
      seIsLoading(false)
      showErrorMessage(error.message)
    }

    await customRequest({
      file: uploadedDistribution,
      onError,
      onSuccess: uploadedFile
    })
  }

  const options = sortBy(distributionTemplates, 'createdAt')
    .reverse()
    .map(({ name: label, url: value, createdAt }) => ({
      value,
      label: (
        <Row>
          <Col flex="auto">
            <Typography.Text>{label}</Typography.Text>
          </Col>
          <Col>
            <Typography.Text type="secondary">
              <Value value={createdAt} />
            </Typography.Text>
          </Col>
        </Row>
      )
    }))

  const onChange = async url => {
    readRemoteFile(url, {
      complete: ({ data }) => {
        setErrors()

        const { parseRow, validateRow, buildRow } = parseDistributions(investments, setErrors, projectId)
        const result = parseCsv(data, parseRow, validateRow, buildRow)

        onSelected(result)
      },
    })
  }

  const componentKey = `template-${projectId}`
  const isDisabled = !uploadedDistribution

  return (
    <Spin
      key={componentKey}
      spinning={isLoading}
    >
      <Select
        style={{ width: 360 }}
        options={options}
        onChange={onChange}
        placeholder={LABEL_PLACEHOLDER_SELECT}
        dropdownRender={(menu) => (
          <>
            {menu}

            <Divider style={{ margin: '8px 0' }} />

            <Space style={{ padding: '0 8px 4px' }}>
              <Input
                ref={inputRef}
                value={name}
                disabled={isDisabled}
                onChange={onNameChange}
                placeholder={LABEL_PLACEHOLDER_INPUT}
              />

              <Button
                type="text"
                icon={<PlusOutlined />}
                onClick={addItem}
                disabled={isDisabled}
              >
                {LABEL_ADD}
              </Button>
            </Space>
          </>
        )}
      />
    </Spin>
  )
}

DistributionTemplates.propTypes = {
  setErrors: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  investments: PropTypes.arrayOf(InvestmentShape),
  uploadedDistribution: PropTypes.shape(),
}

export default DistributionTemplates
