import React, { useState } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { hasAccess } from "@components/Authorization"
import { useForm } from "@components/Form"
import { UpdateModal } from "@components/Page"
import { useOnSubmit } from "@modules/backstage/users"
import { useAppContext } from "@components/AppContext"
import {
  readUserOperation as readOperation,
  updateFundMemberOperation
} from "@api/services/backstage"

import useSchema, { updateMemberOperation } from "./helpers/useSchema"

const LABEL_TITLE = `Edit [ENTITY]`
const LABEL_STAFF = "Staff"
const LABEL_SUBMIT = "Save"
const LABEL_USER = "User"


const UserUpdateModal = ({ modal }) => {
  const [ currentUser, setCurrentUser ] = useState({})

  const form = useForm()

  const { updateUser } = useOutletContext()
  const { isConsoleOrganization } = useAppContext()

  const { roles = [] } = currentUser

  const schema = useSchema(form, currentUser)
  const onSubmit = useOnSubmit(roles)

  const onSuccess = user =>
    updateUser(user)

  const onItemRead = user =>
    setCurrentUser(user)

  const isFund = hasAccess(["fund-users-write"])

  const updateOperation = isFund
    ? updateFundMemberOperation
    : updateMemberOperation

  const entity = isConsoleOrganization
    ? LABEL_USER
    : LABEL_STAFF

  const title = LABEL_TITLE
    .replace('[ENTITY]', entity)

  const updateModalProps = {
    operation: updateOperation,
    submitLabel: LABEL_SUBMIT,
    form,
    modal,
    title,
    entity,
    schema,
    onSubmit,
    onSuccess,
    onItemRead,
    readOperation,
  }

  return (
    <UpdateModal {...updateModalProps} />
  )
}

UserUpdateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default UserUpdateModal
