import dayjs from "dayjs"
import { useOutletContext } from "react-router-dom"

import { TRANSACTION_TYPES } from "@components/Domain"


const useAchFundsArrivalDate = investment => {
  const { getInvestmentTransactions } = useOutletContext()

  const { id: investmentId } = investment
  const investmentTransactions = getInvestmentTransactions(investmentId)

  const [ transaction ] = investmentTransactions
    .filter(t => t.type === TRANSACTION_TYPES.FUNDING)

  if (!transaction) {
    return ""
  }

  const { createdAt } = transaction

  const achFundsArrivalDate = dayjs(createdAt)
    .add(9, 'day')
    .format('MM/DD/YYYY')

  return achFundsArrivalDate
}

export default useAchFundsArrivalDate
