import React from "react"
import PropTypes from "prop-types"
import { View, Text, Heading } from "@aws-amplify/ui-react"


const FormHeader = ({
  text = undefined,
  heading = undefined,
}) => {
  return (
    <View>
      {
        heading && (
          <Heading level={4}>
            {heading}
          </Heading>
        )
      }

      {
        text && (
          <Text style={{ marginTop: heading ? 10 : 0 }}>
            {text}
          </Text>
        )
      }
    </View>
  )
}

FormHeader.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
}

export default FormHeader
