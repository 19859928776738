import cloneDeep from "lodash.clonedeep"

import ProjectDealTags from "./ProjectDealTags"

const { group, fieldName } = ProjectDealTags


const extendProjectTags = project => {
  const { tags = [] } = project

  const dealTags = tags
    .filter(tag => tag.group === group)

  const hasDealTags = dealTags.length > 0

  if (hasDealTags) {
    project[fieldName] = cloneDeep(dealTags)
  }

  return project
}

export default extendProjectTags
