import React from "react"
import PropTypes from "prop-types"
import { Descriptions } from "antd"

import { isMobile } from "@components"
import { IndexBankAccountsOutputShape, ReadMyFundingBankAccountOutputShape } from "@api/services/transactions"

import getItems from "./helpers/getItems"

const { Item } = Descriptions


const BankAccount = ({
  isWire = false,
  extraItems = [],
  referenceNote = "",
  bankAccount,
}) => {
  const items = getItems({
    isWire,
    extraItems,
    bankAccount,
    referenceNote,
  })

  let labelStyle = {}

  if (!isMobile) {
    labelStyle = { width: "50%" }
  }

  const size = "small"

  const layout = isMobile
    ? "vertical"
    : "horizontal"

  const className = "compact black"

  return (
    <Descriptions
      bordered
      size={size}
      column={1}
      layout={layout}
      className={className}
      labelStyle={labelStyle}
    >
      {
        items.map(({ label, value }, key) => (
          <Item key={key} label={label}>
            {value}
          </Item>
        ))
      }
    </Descriptions>
  )
}

BankAccount.propTypes = {
  isWire: PropTypes.bool,
  extraItems: PropTypes.arrayOf(PropTypes.shape()),
  bankAccount: PropTypes.oneOfType([
    IndexBankAccountsOutputShape,
    ReadMyFundingBankAccountOutputShape
  ]).isRequired,
  referenceNote: PropTypes.string,
}

export default BankAccount
