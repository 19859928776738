import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Tabs, theme } from "antd"

import { hasAccess } from "@components/Authorization"
import { Notifications } from "@modules/backstage/notifications"
import { AccountActivities } from "@modules/backstage/activities"
import { TRANSACTION_STATUS } from "@components/Domain"
import { AccountTransactions } from "@modules/backstage/transactions"

import Account from "./Account"
import { AccountShape, InvestmentShape, TransactionShape } from "./shapes"

const LABEL_ACCOUNT = "Investor"
const LABEL_ACTIVITY = "Activity"
const LABEL_TRANSACTIONS = "Transactions"
const LABEL_NOTIFICACTIONS = "Notifications"


const InvestorDrawerTabs = ({
  account,
  investments,
  transactions,
  updateAccount,
  setAccountTags,
  indexInvestments,
  indexTransactions,
  openAccountUpdateDrawer,
}) => {
  const { token: { colorBgContainer } } = theme.useToken()

  const activeTransactions = useMemo(() =>
    transactions
      .filter(({ status }) => status !== TRANSACTION_STATUS.REJECTED)
  , [ transactions ])

  const { id: accountId } = account

  const tabBarStyle = {
    marginLeft: -24,
    paddingLeft: 24,
    marginRight: -24,
    paddingRight: 24,
    backgroundColor: colorBgContainer,
  }

  const canReadActivities = hasAccess(["activities-read"])
  const canReadNotifications = hasAccess(['notifications-read'])

  const hasEmail = !!account.investor.email

  const hasNotifications = canReadNotifications && hasEmail

  const items = [
    {
      key: "account",
      label: LABEL_ACCOUNT,
      children: (
        <Account
          onEdit={openAccountUpdateDrawer}
          account={account}
          investments={investments}
          transactions={activeTransactions}
          setAccountTags={setAccountTags}
          indexInvestments={indexInvestments}
          updateAccount={updateAccount}
        />
      ),
    },
    {
      key: "transactions",
      label: LABEL_TRANSACTIONS,
      children: (
        <AccountTransactions
          transactions={transactions}
          indexTransactions={indexTransactions}
        />
      )
    },
  ]

  if (hasNotifications) {
    items.push({
      key: "notifications",
      label: LABEL_NOTIFICACTIONS,
      children: (
        <Notifications recipientId={account.id} />
      ),
    })
  }

  if (canReadActivities) {
    items.push({
      key: "activity",
      label: LABEL_ACTIVITY,
      children: (
        <AccountActivities
          investorAccountId={accountId}
        />
      )
    })
  }

  return (
    <Tabs
      size="small"
      items={items}
      tabBarStyle={tabBarStyle}
    />
  )
}

InvestorDrawerTabs.propTypes = {
  account: AccountShape.isRequired,
  investments: PropTypes.arrayOf(InvestmentShape).isRequired,
  transactions: PropTypes.arrayOf(TransactionShape).isRequired,
  setAccountTags: PropTypes.func.isRequired,
  indexInvestments: PropTypes.func.isRequired,
  indexTransactions: PropTypes.func.isRequired,
  openAccountUpdateDrawer: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
}

export default InvestorDrawerTabs
