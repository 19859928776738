import React from "react"
import PropTypes from "prop-types"
import { Button } from "antd"

const LABEL_BACK = "Back"


const BackButton = ({
  children = undefined,
  onClick,
}) => {
  const style = { width: "100%" }

  return (
    <Button
      size="large"
      shape="round"
      style={style}
      onClick={onClick}
    >
      {children || LABEL_BACK}
    </Button>
  )
}

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
}

export default BackButton
