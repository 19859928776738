import { useAppContext } from "@components/AppContext"

import normalizeCustomerName from "./normalizeCustomerName"

export const CUSTOMER_NAME_PERSONAL = "Personal"
export const CUSTOMER_NAME_IMPERSONATED = "Impersonated"


const getCustomerName = (businessName, impersonatedInvestorAccountId) => {
  if (businessName) {
    return normalizeCustomerName(businessName)
  }

  if (impersonatedInvestorAccountId) {
    return CUSTOMER_NAME_IMPERSONATED
  }

  return CUSTOMER_NAME_PERSONAL
}

const useCustomer = (businessName, impersonatedInvestorAccountId) => {
  const { getCustomer } = useAppContext()

  const customerName = getCustomerName(businessName, impersonatedInvestorAccountId)
  const customer = getCustomer(customerName, impersonatedInvestorAccountId)

  return customer
}

export default useCustomer
