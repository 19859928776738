import React from "react"
import PropsTypes from "prop-types"

import { CreateModal } from "@components/Page"
import { MODAL_WIDTH2 } from "@components"
import { createOperation } from "@components/Store/BackstageStore/useCampaignsStore"

import getInputs from "./helpers/getInputs"

const inputs = getInputs()
const schema = createOperation.getSchema(inputs)

const LABEL_ENTITY = "Campaign"
const LABEL_TITLE = `Create ${LABEL_ENTITY}`


const CampaignCreateModal = ({ modal, onSuccess }) => {
  const createModalProps = {
    width: MODAL_WIDTH2,
    title: LABEL_TITLE,
    entity: LABEL_ENTITY,
    operation: createOperation,
    successMessage: false,
    modal,
    schema,
    onSuccess,
  }

  return <CreateModal {...createModalProps} />
}

CampaignCreateModal.propTypes = {
  modal: PropsTypes.shape().isRequired,
  onSuccess: PropsTypes.func.isRequired,
}

export default CampaignCreateModal
