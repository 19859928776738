import dayjs from "dayjs"

import { US_DATE_FORMAT } from "./usDate"

export const DATETIME_FORMAT = "L, LT"
const TIMEZONE_OFFSET_REGEX = /([-+]\d{2}:\d{2})$/

export const DEFAULT_TIMEZONE = "America/New_York"

const US_TIMEZONES = [
  {
    value: DEFAULT_TIMEZONE,
    label: "EST",
    offset: "-05:00"
  },
  {
    value: "America/Chicago",
    label: "CST",
    offset: "-06:00"
  },
  {
    value: "America/Phoenix",
    label: "MST",
    offset: "-07:00"
  },
  {
    value: "America/Los_Angeles",
    label: "PST",
    offset: "-08:00"
  }
]

export const getTimezoneByOffset = offset => {
  const timeZone = US_TIMEZONES.find(option => option.offset === offset)?.value

  if (!timeZone) {
    throw new Error(`Offset ${offset} is not supported`)
  }

  return timeZone
}


export const getOffset = isoDateTime => {
  const matches = isoDateTime.match(TIMEZONE_OFFSET_REGEX)
  const offset = matches ? matches[1] : null

  return offset
}

export const getFormattedDateTimeWithTimezone = (isoDateTime) => {
  const parsedValues = parseDateTime(isoDateTime)

  if (!parsedValues) {
    return ""
  }

  const [ , , , formattedScheduledAt ] = parsedValues

  return formattedScheduledAt
}


export const parseDateTime = isoDateTime => {
  if (!isoDateTime) {
    return isoDateTime
  }

  const offset = getOffset(isoDateTime)

  if (!offset) {
    const value = dayjs(isoDateTime).format(DATETIME_FORMAT)
    const [ date, time ] = value.split(', ')

    return [ date, time ]
  }

  const timezone = getTimezoneByOffset(offset)

  const format = `${US_DATE_FORMAT}, hh:mm a, z`
  const realOffset = dayjs(isoDateTime).tz(timezone).format("Z")
  const newIsoDateTime = isoDateTime.replace(offset, realOffset)

  const value = dayjs(newIsoDateTime).tz(timezone).format(format)

  const [ date, time, zone ] = value.split(', ')
  const formattedValue = [ date, time, zone ].join(" ")

  return [ date, time, zone, formattedValue ]
}
