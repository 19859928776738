import PropTypes from "prop-types"


const properties = {
  verificationRequestCreatedAt: PropTypes.string,
  verificationRequestId: PropTypes.string
}

const EnableMyUser2FAOutputShapeShape = PropTypes.exact(properties)

export default EnableMyUser2FAOutputShapeShape
export { properties }
