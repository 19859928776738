import PropTypes from "prop-types"


const properties = {
  validationErrorsJson: PropTypes.string
}

const ImportAccountsOutputShapeShape = PropTypes.exact(properties)

export default ImportAccountsOutputShapeShape
export { properties }
