import React from "react"
import PropTypes from "prop-types"
import { Typography, Space } from "antd"

import { getConfig } from "@components/Config"
import { isMobile, W1, W5 } from "@components"

import PoweredByLogo from "./PoweredByLogo"

const PLATFORM_WEBSITE = getConfig("PLATFORM_WEBSITE")
const PLATFORM_VERSION = getConfig("PLATFORM_VERSION")


const PlatformFooter = ({
  marginTop = W5 * 2,
  shouldRenderOnMobile = false,
}) => {
  const shouldRender = !isMobile || shouldRenderOnMobile

  if (!shouldRender) {
    return null
  }

  const yearDate = new Date().getFullYear()

  const style = {
    marginBottom: W1,
    marginTop,
  }

  return (
    <footer style={style}>
      <center>
        <Space direction="vertical" size={0}>
          <PoweredByLogo
            width={260}
            style={{ opacity: 0.25 }}
          />

          <Typography.Text strong style={{ color: "rgb(191, 191, 191)", fontWeight: 500 }}>
            {`© ${yearDate} ${PLATFORM_WEBSITE} / v${PLATFORM_VERSION}`}
          </Typography.Text>
        </Space>
      </center>
    </footer>
  )
}

PlatformFooter.propTypes = {
  marginTop: PropTypes.number,
  shouldRenderOnMobile: PropTypes.bool,
}

export default PlatformFooter
