import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"

import { getUsDateFromIsoDate } from "@components/Date"
import { TRANSACTION_TYPES, ACTIVE_INVESTMENT_STATUSES } from "@components/Domain"
import getStatistics, {
  STATISTIC_LABELS_MAP,
  TITLE_DISTRIBUTIONS,
  OUTGOING_TOTAL_KEY
} from "@components/Investor/helpers/getStatistics"
import { getTransactionTypeLabel } from "@components/Transaction"
import { getFormattedDecimalValue } from "@components/Amount"

const DISTRIBUTIONS_KEY = "distributions"

const LABEL_FUNDING_DATE = "Project Funding Date"

const TOOLTIP_MAP = {
  [DISTRIBUTIONS_KEY]: "Return on capital; return on investment",
  [OUTGOING_TOTAL_KEY]: "Total amount invested",
  [TRANSACTION_TYPES.SALE_PROFIT]: "Total profit amount upon sale",
  [TRANSACTION_TYPES.CAPITAL_RETURN]: "Total amount returned upon sale or refinance; return of capital",
}


const getTransactionStatistics = (investments, transactions, projectId) => {
  const activeInvestmentIds = investments
    .filter(({ status }) => ACTIVE_INVESTMENT_STATUSES.includes(status))
    .map(({ id }) => id)

  const activeInvestmentTransactions = transactions
    .filter(({ investmentId }) => activeInvestmentIds.includes(investmentId))

  if (!projectId) {
    return getStatistics(activeInvestmentTransactions)
  }

  const projectActiveInvestmentTransactions = activeInvestmentTransactions
    .filter(transaction => transaction.projectId === projectId)

  return getStatistics(projectActiveInvestmentTransactions)
}


const useStatisticItems = () => {
  const { project, investments, transactions } = useOutletContext()

  const statistics = useMemo(() =>
    getTransactionStatistics(investments, transactions, project?.id)
  , [ investments, transactions, project ])

  const fundingDate = project?.closeDate

  const fundingUsDate = fundingDate
    ? getUsDateFromIsoDate(fundingDate)
    : "-"

  const investedAmount = statistics[OUTGOING_TOTAL_KEY] || 0
  const profitOnSaleAmount = statistics[TRANSACTION_TYPES.SALE_PROFIT] || 0
  const returnedCapitalAmount = statistics[TRANSACTION_TYPES.CAPITAL_RETURN] || 0


  const profitAmount = statistics[TRANSACTION_TYPES.SALE_PROFIT] || 0
  const amountReturn = statistics[TRANSACTION_TYPES.INVESTMENT_RETURN] || 0

  const returnedAmount = getFormattedDecimalValue(returnedCapitalAmount, profitOnSaleAmount, "plus")
  const totalDistributions = getFormattedDecimalValue(amountReturn, returnedCapitalAmount, "plus")

  const items = [
    {
      title: STATISTIC_LABELS_MAP[OUTGOING_TOTAL_KEY],
      value: investedAmount,
      tooltip: TOOLTIP_MAP[OUTGOING_TOTAL_KEY]
    },
    {
      title: STATISTIC_LABELS_MAP[TRANSACTION_TYPES.CAPITAL_RETURN],
      value: returnedAmount,
      tooltip: TOOLTIP_MAP[TRANSACTION_TYPES.CAPITAL_RETURN]
    },
    {
      title: TITLE_DISTRIBUTIONS,
      value: totalDistributions,
      tooltip: TOOLTIP_MAP[DISTRIBUTIONS_KEY]
    },
    {
      title: getTransactionTypeLabel(TRANSACTION_TYPES.SALE_PROFIT),
      value: profitAmount,
      tooltip: TOOLTIP_MAP[TRANSACTION_TYPES.SALE_PROFIT]
    }
  ]

  if (!fundingDate) {
    return items
  }

  items.push({
    title: LABEL_FUNDING_DATE,
    value: fundingUsDate,
    props: {}
  })

  return items
}

export default useStatisticItems
