import React from "react"
import { NavLink } from "react-router-dom"
import { LinkOutlined } from "@ant-design/icons"

import { useAppContext } from "@components/AppContext"
import { getProjectScfUrl } from "@components/Investment"

const LABEL_SHARE = "Copy Public URL"
const MESSAGE_COPIED = "Soft commit form URL is copied to the clipboard"


const useScfMenuItem = (projectId, sponsors = []) => {
  const { showSuccessMessage, identity, isBroker } = useAppContext()

  const copyProjectScfUrl = sponsorId => {
    const url = getProjectScfUrl(identity, projectId, sponsorId)

    navigator.clipboard.writeText(url)
    showSuccessMessage(MESSAGE_COPIED)
  }

  const getScfMenuItem = () => {
    const { sponsorId } = identity

    if (isBroker) {
      return {
        key: "share",
        icon: <LinkOutlined />,
        label: (
          <NavLink onClick={() => copyProjectScfUrl(sponsorId)}>
            {LABEL_SHARE}
          </NavLink>
        )
      }
    }

    const sponsorItems = sponsors.map(sponsor => ({
      key: sponsor.id,
      label: (
        <NavLink onClick={() => copyProjectScfUrl(sponsor.id)}>
          {sponsor.name}
        </NavLink>
      )
    }))

    const hasNoSponsors = sponsors.length === 0

    if (hasNoSponsors) {
      return {
        key: "share",
        icon: <LinkOutlined />,
        label: LABEL_SHARE,
        disabled: true,
      }
    }

    return {
      key: "share",
      icon: <LinkOutlined />,
      label: LABEL_SHARE,
      children: sponsorItems
    }

  }

  return getScfMenuItem
}

export default useScfMenuItem
