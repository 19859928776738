import React from "react"

import { CheckboxInput } from "../../Input"


const renderCheckbox = (itemProps, inputProps) => {
  return (
    <CheckboxInput
      itemProps={itemProps}
      inputProps={inputProps}
    />
  )
}

export default renderCheckbox
