import PropTypes from "prop-types"

import { hasAccess } from "@components/Authorization"
import { useAppContext } from "@components/AppContext"
import {
  indexFailedTransactionsOperation as indexOperation,
  IndexFailedTransactionsOutputShape,
} from "@api/services/transactions"

import useStore from "../helpers/useStore"


const useFailedTransactionsStore = () => {
  const { request } = useAppContext()

  const shouldAutoLoad = false
  const canReadTransactions = hasAccess(['transactions-read'])

  const {
    items: failedTransactions,
    indexItems
  } = useStore(indexOperation, request, shouldAutoLoad)

  const indexFailedTransactions = canReadTransactions
    ? indexItems
    : () => {}

  return {
    failedTransactions,
    indexFailedTransactions,
  }
}

const failedTransactionsStoreProperties = {
  failedTransactions: PropTypes.arrayOf(IndexFailedTransactionsOutputShape),
  indexFailedTransactions: PropTypes.func.isRequired,
}

export default useFailedTransactionsStore

export {
  indexOperation,
  failedTransactionsStoreProperties
}
