import PropTypes from "prop-types"

import {
  ReadProjectOutputShape,
  ListProjectsOutputShape,
  ReadPublicPublishedProjectOutputShape
} from "@api/services/backstage"


const ProjectShape = PropTypes.oneOfType([
  ReadProjectOutputShape,
  ListProjectsOutputShape,
  ReadPublicPublishedProjectOutputShape
])

export default ProjectShape
