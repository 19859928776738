import React from "react"
import PropTypes from "prop-types"
import { Descriptions } from "antd"

import useItems from "./helpers/useItems"
import { InvestmentShape } from "../../../shapes"

import "./Footer.css"


const Footer = ({
  investment,
  updateInvestment
}) => {
  const items = useItems(investment, updateInvestment)

  const descriptionsProps = {
    size: "small",
    column: 1,
    className: "black",
    bordered: false,
    items,
  }

  return (
    <div className="investment-header">
      <Descriptions {...descriptionsProps} />
    </div>
  )
}

Footer.propTypes = {
  investment: InvestmentShape.isRequired,
  updateInvestment: PropTypes.func.isRequired,
}

export default Footer
