import { useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import { useAppContext } from "@components/AppContext"

import {
  indexSponsorsOperation as indexOperation,
  IndexSponsorsOutputShape,
} from "@api/services/backstage"

import useStore from "../helpers/useStore"


const usePartnersStore = () => {
  const { request } = useAppContext()

  const shouldAutoLoad = false

  const {
    items: sponsors,
    getItem: getSponsor,
    addItem: addSponsor,
    updateItem: updateSponsor,
    removeItem: deleteSponsor,
    indexItems: indexSponsors,
  } = useStore(indexOperation, request, shouldAutoLoad)

  const hasMultipleSponsors = useMemo(() =>
    (sponsors || []).length > 1
  , [ sponsors ])

  const getSponsorName = useCallback(id =>
    getSponsor(id)?.name
  , [ getSponsor ])

  const funds = []
  const fundsMap = {}

  return {
    funds,
    fundsMap,
    sponsors,
    addSponsor,
    getSponsor,
    deleteSponsor,
    updateSponsor,
    indexSponsors,
    getSponsorName,
    hasMultipleSponsors,
  }
}

const partnersStoreProperties = {
    funds: PropTypes.arrayOf(PropTypes.shape()),
    fundsMap: PropTypes.shape().isRequired,
    sponsors: PropTypes.arrayOf(IndexSponsorsOutputShape),
    addSponsor: PropTypes.func.isRequired,
    getSponsor: PropTypes.func.isRequired,
    deleteSponsor: PropTypes.func.isRequired,
    updateSponsor: PropTypes.func.isRequired,
    indexSponsors: PropTypes.func.isRequired,
    getSponsorName: PropTypes.func.isRequired,
    hasMultipleSponsors: PropTypes.bool.isRequired,
}

export default usePartnersStore

export {
  partnersStoreProperties
}
