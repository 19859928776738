import computeProjectInvestmentsFlags from "./computeProjectInvestmentsFlags"


const getProjectInvestmentsUrl = (projectId, investments) => {
  const {
    isProfileComplete,
    isInvestorComplete,
  } = computeProjectInvestmentsFlags(investments)

  if (!isInvestorComplete) {
    return `/investments/${projectId}`
  }

  if (!isProfileComplete) {
    return `/investments/${projectId}/update`
  }

  return `/investments/${projectId}/sign`
}

export default getProjectInvestmentsUrl
