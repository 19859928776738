import { getConfig } from "@components/Config"
import { useAppContext } from "@components/AppContext"

const consoleOrganizationId = getConfig("consoleOrganizationId")


const useOrganizations = () => {
  const { identity } = useAppContext()

  const { organizations } = identity

  const noConsoleOrganizations = organizations
    .filter(({ id }) => id !== consoleOrganizationId)
    .filter(({ isDisabled }) => !isDisabled)

  const sortedOrganizations = noConsoleOrganizations
    .sort((a, b) => a.name > b.name)

  return sortedOrganizations
}

export default useOrganizations
