import PropTypes from "prop-types"

import UsAddressShape from "./shapes/UsAddressShape"

const properties = {
  accountNumber: PropTypes.string.isRequired,
  alternativeRoutingNumber: PropTypes.string,
  bankAddress: UsAddressShape.isRequired,
  bankCodeName: PropTypes.string.isRequired,
  bankName: PropTypes.string.isRequired,
  beneficiaryAddress: UsAddressShape.isRequired,
  beneficiaryName: PropTypes.string.isRequired,
  routingNumber: PropTypes.string.isRequired
}

const ReadMyFundingBankAccountOutputShapeShape = PropTypes.exact(properties)

export default ReadMyFundingBankAccountOutputShapeShape
export { properties }
