import PropTypes from "prop-types"

import { useAppContext } from "@components/AppContext"
import {
  indexPublishedProjectsOperation as indexOperation,
  IndexPublishedProjectsOutputShape,
} from "@api/services/backstage"

import useStore from "../helpers/useStore"


const useProjectsStore = () => {
  const { request } = useAppContext()

  const shouldAutoLoad = false

  const {
    items: projects,
    getItem: getProject,
    indexItems: indexProjects,
  } = useStore(indexOperation, request, shouldAutoLoad)

  return {
    projects,
    getProject,
    indexProjects,
  }
}

const projectsStoreProperties = {
  projects: PropTypes.arrayOf(IndexPublishedProjectsOutputShape),
  getProject: PropTypes.func.isRequired,
  indexProjects: PropTypes.func.isRequired,
}

export default useProjectsStore

export {
  indexOperation,
  projectsStoreProperties
}
