import React, { useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import { Alert } from "antd"

import TierShape from "@api/services/backstage/shapes/TierShape"
import { useAppContext } from "@components/AppContext"
import { OperationShape } from "@api"
import Form, { useForm, useWatch } from "@components/Form"
import { INVESTMENT_PROFILE_TYPE_ENTITY } from "@components/Domain"
import {
  ReadAccountOutputShape,
  createInvestmentOperation,
  updateMyInvestmentOperation
} from "@api/services/investments"

import ProfileShape from "../shapes/ProfileShape"
import getProfilesMap from "./helpers/getProfilesMap"
import InvestmentShape from "../shapes/InvestmentShape"
import useUpdateSchema from "./helpers/useUpdateSchema"
import setDefaultProfile from "./helpers/setDefaultProfile"
import OrganizationShape from "./shapes/OrganizationShape"

const TITLE_ALERT = "Alert"
const ERROR_UPDATE_FAILED = "Failed to update an investment profile"
const ERROR_CREATE_FAILED = "Failed to create an investment profile"


const InvestmentForm = ({
  form: customForm = undefined,
  identity: customIdentity = undefined,
  onFailure: onCustomFailure = () => {},
  block = undefined,
  setBlock = undefined,
  projectId = undefined,
  investment = undefined,
  submitLabel = undefined,
  createOperation = createInvestmentOperation,
  updateOperation = updateMyInvestmentOperation,
  otherInvestments = undefined,
  existingProfiles = undefined,
  isTypeSelectDisabled = false,
  tiers,
  isFund,
  organization,
  onAfterSubmit,
  ...otherFormProps
}) => {
  const form = useForm(customForm)

  const {
    request,
    showErrorMessage
  } = useAppContext()

  const profilesMap = useMemo(() =>
    getProfilesMap(isFund, existingProfiles, otherInvestments)
  , [
    isFund,
    existingProfiles,
    otherInvestments
  ])

  const schema = useUpdateSchema({
    form,
    tiers,
    setBlock,
    investment,
    profilesMap,
    organization,
    customIdentity,
    otherInvestments,
    isTypeSelectDisabled,
  })

  const profileType = useWatch("profileType", form)

  useEffect(() =>
    setDefaultProfile(form, profileType, profilesMap)
  , [
    form,
    profileType,
    profilesMap
  ])

  const isUpdate = !!investment
  const isCreate = !isUpdate

  const formProps = {
    operation: createOperation,
    ...otherFormProps
  }

  if (isUpdate) {
    formProps.item = investment
    formProps.operation = updateOperation
  }

  const onFailure = () => {
    const message = isUpdate
      ? ERROR_UPDATE_FAILED
      : ERROR_CREATE_FAILED

    showErrorMessage(message)
    onCustomFailure()
  }

  const onSubmit = parameters => {
    if (isCreate) {
      parameters.mutation.projectId = projectId
    }

    parameters.mutation.isProfileValid = true

    return parameters
  }

  return (
    <>
      <Form
        form={form}
        schema={schema}
        request={block ? undefined : request}
        onSubmit={onSubmit}
        onSuccess={onAfterSubmit}
        onFailure={onFailure}
        submitLabel={submitLabel}
        {...formProps}
      />

      {
        block && (
          <Alert
            showIcon
            type="warning"
            message={TITLE_ALERT}
            description={block}
          />
        )
      }
    </>
  )
}

InvestmentForm.propTypes = {
  form: PropTypes.shape(),
  tiers: PropTypes.arrayOf(TierShape).isRequired,
  block: PropTypes.oneOfType([ PropTypes.string, PropTypes.shape() ]),
  isFund: PropTypes.bool.isRequired,
  identity: ReadAccountOutputShape,
  setBlock: PropTypes.func,
  projectId: PropTypes.string,
  onFailure: PropTypes.func,
  investment: InvestmentShape,
  submitLabel: PropTypes.string,
  organization: OrganizationShape.isRequired,
  onAfterSubmit: PropTypes.func.isRequired,
  createOperation: OperationShape,
  updateOperation: OperationShape,
  existingProfiles: PropTypes.arrayOf(ProfileShape),
  otherInvestments: PropTypes.arrayOf(InvestmentShape),
  isTypeSelectDisabled: PropTypes.bool,
}

export default InvestmentForm

export const processInvestments = investments => {
  const entityProfileInvestments = investments
    .filter(investment => investment.profileType === INVESTMENT_PROFILE_TYPE_ENTITY)

  for (const investment of entityProfileInvestments) {
    const { profileEntity } = investment

    if (!!profileEntity?.einNumber) {
      // eslint-disable-next-line no-underscore-dangle
      investment.profileEntity._shouldProvideEin = true
    }

    if (!!profileEntity?.beneficialOwnerEinNumber) {
      // eslint-disable-next-line no-underscore-dangle
      investment.profileEntity._shouldProvideBeneficialOwnerEin = true
    }
  }

  return investments
}
