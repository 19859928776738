import React from "react"

import { W0 } from "@components"
import { BackButton, SubmitButton, FormNavigationFooter } from "@components/Form"

const LABEL_SUBMIT = "Connect"
const LABEL_CONTINUE = "Continue"


const hideInput = input => {
  const {
    itemProps = {},
    componentProps = {},
  } = input

  const { style: itemStyle = {} } = itemProps
  const { style: componentStyle = {} } = componentProps

  const hasSchema = !!input.schema

  let schemaInputs = {}

  if (hasSchema) {
    schemaInputs = {
      schema: input.schema.map(hideInput)
    }
  }

  return {
    ...input,
    ...schemaInputs,
    required: false,
    itemProps: {
      ...itemProps,
      style: {
        ...itemStyle,
        display: "none",
      }
    },
    componentProps: {
      ...componentProps,
      style: {
        ...componentStyle,
        display: "none",
      }
    }
  }
}

const getStepInputs = (currentStep, goStep, entityInputs, bankAccountInputs) => {
  const isFirstStep = currentStep === 0

  if (isFirstStep) {
    const hiddenInputs = bankAccountInputs.map(hideInput)
    return [
      ...hiddenInputs,
      ...entityInputs,
      {
        name: "_continue",
        component: ({ form }) => {
          const onClick = () => {
            form
              .validateFields()
              .then(() => goStep(currentStep + 1))
              .catch(() => false)
          }

          return (
            <SubmitButton
              style={{ marginBottom: W0 }}
              onClick={onClick}
            >
              {LABEL_CONTINUE}
            </SubmitButton>
          )
        }
      }
    ]
  }

  const onBack = () => goStep(0)

  const hiddenInputs = entityInputs.map(hideInput)

  return [
    ...hiddenInputs,
    ...bankAccountInputs,
    {
      name: "_submit",
      component: ({ form }) => {
        const isLoading = form.isSubmitting()

        return (
          <FormNavigationFooter>
            <BackButton onClick={onBack} />

            <SubmitButton
              isLoading={isLoading}
            >
              {LABEL_SUBMIT}
            </SubmitButton>
          </FormNavigationFooter>
        )
      }
    }
  ]
}

export default getStepInputs
