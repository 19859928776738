import PropTypes from "prop-types"

import { useAppContext } from "@components/AppContext"
import {
  indexOrganizationsOperation as indexOperation,
  IndexOrganizationsOutputShape,
} from "@api/services/organizations"

import useStore from "../helpers/useStore"


const useOrganizationsStore = () => {
  const { authenticatedRequest: request } = useAppContext()

  const shouldAutoLoad = false

  const {
    items: organizations,
    getItem: getOrganization,
    addItem: addOrganization,
    indexItems: indexOrganizations,
    updateItem: updateOrganization,
  } = useStore(indexOperation, request, shouldAutoLoad)

  const getOrganizationName = id => {
    const { name } = getOrganization(id)
    return name
  }

  return {
    organizations,
    addOrganization,
    updateOrganization,
    indexOrganizations,
    getOrganizationName,
  }
}

const organizationsStoreProperties = {
  organizations: PropTypes.arrayOf(IndexOrganizationsOutputShape),
  addOrganization: PropTypes.func.isRequired,
  updateOrganization: PropTypes.func.isRequired,
  indexOrganizations: PropTypes.func.isRequired,
  getOrganizationName: PropTypes.func.isRequired,
}

export default useOrganizationsStore

export {
  indexOperation,
  organizationsStoreProperties
}
