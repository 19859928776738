import {
  FUNDING,
  SALE_PROFIT,
  CAPITAL_RETURN,
  SPONSOR_PROMOTE,
  INVESTMENT_RETURN,
} from "@api/services/transactions/shapes/TransactionTypeEnum"

export const TRANSACTION_TYPE_LABEL_MAP = {
  [FUNDING]: "Capital Contribution",
  [SALE_PROFIT]: "Profit on Sale",
  [CAPITAL_RETURN]: "Return of Capital",
  [SPONSOR_PROMOTE]: "Sponsor Promote",
  [INVESTMENT_RETURN]: "Return on Investment",
}

const getTransactionTypeLabel = type => TRANSACTION_TYPE_LABEL_MAP[type]

export default getTransactionTypeLabel
