import React, { useState } from "react"
import PropTypes from "prop-types"
import { Space, Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"

import List from "./List"
import ActionsDropdown from "./ActionsDropdown"

const LABEL_TAG = "Tag"


const TagsList = ({
  tagIds = [],
  onChange,
  tagOptions,
}) => {
  const [ isLoading, setIsLoading ] = useState(false)

  return (
    <Space size={0}>
      <List
        tagIds={tagIds}
        untagItem={tagId => onChange(tagId, false)}
      />

      <ActionsDropdown
        tagIds={tagIds}
        onChange={(tagId, isChecked) => onChange(tagId, isChecked)}
        tagOptions={tagOptions}
        setIsLoading={setIsLoading}
      >
        <Button
          icon={<PlusOutlined />}
          size="small"
          type="dashed"
          shape="round"
          loading={isLoading}
        >
          {LABEL_TAG}
        </Button>
      </ActionsDropdown>
    </Space>
  )
}

TagsList.propTypes = {
  tagIds: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  tagOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export default TagsList
