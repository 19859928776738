import React from "react"
import { Tag } from "antd"

const LABEL_ENABLED = "Enabled"


const VerifiedStatus = () => {
  return (
    <Tag
      color="green"
      bordered={false}
    >
      {LABEL_ENABLED}
    </Tag>
  )
}

export default VerifiedStatus
