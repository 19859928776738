import { getCurrencyValue } from "@components/Amount"


const getPieChartConfig = (data) => {
  const legendItemFormatter = title => {
    const item = data.find(d => d.type === title)
    const { value, countValue } = item

    const valueFormatted = getCurrencyValue(value, 0)
    return `${title}:  ${valueFormatted} (${countValue})`
  }

  const tooltipFormatter = ({ type }) => {
    const item = data.find(d => d.type === type)
    const { value, countValue } = item

    const valueFormatted = getCurrencyValue(value, 0)
    const valueString = `${valueFormatted} (${countValue})`

    return { name: type, value: valueString }
  }

  const config = {
    label: false,
    height: 220,
    radius: 0.85,
    angleField: 'value',
    colorField: 'type',
    legend: {
      spacing: 0,
      itemName: false,
      position: 'left-top',
      itemHeight: 10,
      offsetY: 12,
      offsetX: 4,
      itemValue: {
        formatter: legendItemFormatter,
      },
    },
    tooltip: {
      formatter: tooltipFormatter
    },
    data,
  }

  return config
}

export default getPieChartConfig
