import capitalize from "lodash.capitalize"

export const ACCOUNTANT = "ACCOUNTANT"
export const ADMIN = "ADMIN"
export const APPROVER = "APPROVER"
export const BROKER = "BROKER"
export const FUND_ADMIN = "FUND_ADMIN"
export const FUND_MANAGER = "FUND_MANAGER"
export const MANAGER = "MANAGER"
export const SUPPORT = "SUPPORT"

const RoleEnum = {
  [ACCOUNTANT]: ACCOUNTANT,
  [ADMIN]: ADMIN,
  [APPROVER]: APPROVER,
  [BROKER]: BROKER,
  [FUND_ADMIN]: FUND_ADMIN,
  [FUND_MANAGER]: FUND_MANAGER,
  [MANAGER]: MANAGER,
  [SUPPORT]: SUPPORT
}

const RoleEnumKeys = Object.keys(RoleEnum)

const RoleEnumOptions = RoleEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  RoleEnumKeys,
  RoleEnumOptions
}

export default RoleEnum
