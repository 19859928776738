import React from "react"
import { Space, Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import { W2 } from "@components"
import Identity from "@components/Identity"
import { EditButton } from "@components/Button"
import { Markdown, Value } from "@components/Text"
import { TRANSACTION_TYPES } from "@components/Domain"
import { getTransactionTypeLabel } from "@components/Transaction"

import computeState from "./computeState"
import parseStatusReason from "./parseStatusReason"

const { Text } = Typography

const LABEL_ON = "on"
const LABEL_ID = "ID"
const LABEL_TO = "To"
const LABEL_FROM = "From"
const LABEL_TYPE = "Type"
const LABEL_DATE = "Date"
const LABEL_AMOUNT = "Amount"
const LABEL_STATUS = "Status"
const LABEL_CREATED = "Created"
const LABEL_UPDATED = "Last Updated"
const LABEL_RETRIED = "Retried"
const LABEL_BACKFILL = "Backfill"


const useItems = (organizationName, transaction, onEdit) => {
  const { isInvalidBankAccountTransaction } = useOutletContext()

  const {
    id,
    type,
    date,
    amount,
    status,
    details,
    createdAt,
    updatedAt,
    createdBy,
    isExternal,
    isApproved,
    description,
    statusReason,
    senderSourceName = "...",
    receiverSourceName = "...",
    retryTransactionId,
  } = transaction

  const isRejected =
    status === "REJECTED" ||
    status === "ERROR" ||
    status === "FAILED"

  const isFundingTransaction = type === TRANSACTION_TYPES.FUNDING

  const isInvalidBankAccount = isInvalidBankAccountTransaction(transaction)

  const parsedStatusReason = parseStatusReason({
    statusReason,
    organizationName,
    isFundingTransaction,
    isInvalidBankAccount,
  })

  const components = {
    p(props) {
      const { children } = props

      return (
        <Typography.Paragraph>
          {children}
        </Typography.Paragraph>
      )
    },
    ul(props) {
      const { children } = props

      return (
        <ul style={{ margin: 0, paddingLeft: W2 }}>
          {children}
        </ul>
      )
    }
  }

  const getItem = (isCondition, props, altProps) =>
    // eslint-disable-next-line no-nested-ternary
    isCondition
      ? [ props ]
      : altProps ? [altProps] : []

  const typeValue = getTransactionTypeLabel(type)

  const statusValue = computeState(status, isApproved)

  const items = [
    {
      label: LABEL_TYPE,
      value: isExternal
        ? `${typeValue} (${LABEL_BACKFILL})`
        : typeValue
    },
    {
      label: LABEL_DATE,
      value: <Value value={date} />
    },
    {
      label: LABEL_AMOUNT,
      value: (
        <Space>
          <Value value={amount} />
          { onEdit && <EditButton onClick={onEdit} /> }
        </Space>
      )
    },
    ...getItem(!isExternal,
      {
        label: LABEL_STATUS,
        value: !!retryTransactionId
          ? `${statusValue} (${LABEL_RETRIED})`
          : statusValue,
      }
    ),
    ...getItem(isRejected,
      {
        label: "Status Reason",
        value: <Markdown text={parsedStatusReason} components={components} />
      }
    ),
    ...getItem(isExternal,
      {
        label: "Description",
        value: description || "N/A",
      },
      {
        label: "ACH Details",
        value: details || "N/A",
      }
    ),
    {
      label: LABEL_ID,
      value: id,
    },
  ]

  if (!isExternal) {
    items.push(
      {
        label: LABEL_FROM,
        value: senderSourceName,
      },
      {
        label: LABEL_TO,
        value: receiverSourceName,
      },
    )
  }

  items.push(
    {
      label: LABEL_CREATED,
      value: (
        <Space size={6}>
          <Identity id={createdBy} />

          {createdAt && <Text type="secondary">{LABEL_ON}</Text> }
          {createdAt && <Value value={createdAt} />}
        </Space>
      )
    },
    {
      label: LABEL_UPDATED,
      value: <Value value={updatedAt} />
    },
  )

  return items
}

export default useItems
