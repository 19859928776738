import { useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import uniq from "lodash.uniq"
import keyBy from "lodash.keyby"

import { hasAccess } from "@components/Authorization"
import { useAppContext } from "@components/AppContext"
import {
  SEGMENT,
  ACCOUNT_TAG,
  CAMPAIGN_TAG,
  DOCUMENT_TYPE,
  SUBSCRIPTION_TYPE,
  BATCH_DOCUMENT_TYPE,
} from "@api/services/backstage/shapes/OptionGroupEnum"
import {
  readOptionOperation as readOperation,
  indexOptionsOperation as indexOperation,
  createOptionOperation as createOperation,
  updateOptionOperation as updateOperation,
  deleteOptionOperation as deleteOperation,
  IndexOptionsOutputShape,
} from "@api/services/backstage"

import useStore from "../helpers/useStore"

const COMMON_DOCUMENT_TYPES = [
  "Investor Report",
  "Property Management Report",
]

const COMMON_BATCH_DOCUMENT_TYPES = [
  "FMV Report",
  "K-1",
  "Operating Agreement",
  "PPM",
  "Redemption Agreement",
  "Side Letter",
  "Transfer of Ownership Package",
]


const useOptionsStore = () => {
  const { request } = useAppContext()

  const canReadOptions = hasAccess(["options-read"])
  const shouldAutoLoad = false

  const {
    getItem: getOption,
    addItem: addOption,
    updateItem: updateOption,
    items = [],
    removeItem,
    indexItems,
  } = useStore(indexOperation, request, shouldAutoLoad)

  const indexOptions = canReadOptions
    ? indexItems
    : () => {}

  const getOptions = group =>
    items
      .filter(option => option.group === group)
      .sort((a, b) => a.name.localeCompare(b.name))

  const segments = useMemo(() =>
    items
      .filter(({ group }) => group === SEGMENT)
      .sort((a, b) => a.name.localeCompare(b.name))
  , [ items ])

  const createSegment = async (name, description) => {
    const mutation = { group: SEGMENT, name, description }
    const { data } = await request(createOperation, { mutation })

    addOption(data)
  }

  const accountTags = useMemo(() =>
    items
      .filter(({ group }) => group === ACCOUNT_TAG)
      .sort((a, b) => a.name.localeCompare(b.name))
  , [ items ])

  const campaignTags = useMemo(() =>
    items
      .filter(({ group }) => group === CAMPAIGN_TAG)
      .sort((a, b) => a.name.localeCompare(b.name))
  , [ items ])

  const documentTypeOptions = useMemo(() =>
    uniq([
      ...COMMON_DOCUMENT_TYPES,
      ...items.filter(({ group }) => group === DOCUMENT_TYPE).map(({ name }) => name)
    ]).sort((a, b) => a.localeCompare(b))
  , [ items ])

  const batchDocumentTypeOptions = useMemo(() =>
    uniq([
      ...COMMON_BATCH_DOCUMENT_TYPES,
      ...items.filter(({ group }) => group === BATCH_DOCUMENT_TYPE).map(({ name }) => name)
    ]).sort((a, b) => a.localeCompare(b))
  , [ items ])

  const subscriptionTypeOptions = useMemo(() =>
    uniq([
      ...items
        .filter(({ group }) => group === SUBSCRIPTION_TYPE)
        .map(({ id, name }) => ({ value: id, label: name }))
    ]).sort((a, b) => a.label.localeCompare(b.label))
  , [ items ])

  const ensureOption = async (group, value) => {
    const option = items.find(item => item.name === value && item.group === group)

    if (option) {
      return
    }

    const mutation = { group, name: value }
    const { data } = await request(createOperation, { mutation })

    addOption(data)

    return data
  }

  const ensureAccountTagTypeOption = value =>
    ensureOption(ACCOUNT_TAG, value)

  const ensureDocumentTypeOption = value =>
    ensureOption(DOCUMENT_TYPE, value)

  const ensureBatchDocumentTypeOption = value =>
    ensureOption(BATCH_DOCUMENT_TYPE, value)

  const deleteOption = id =>
    request(deleteOperation, { id })
      .then(() => removeItem(id))

  const optionsMap = useMemo(() =>
    keyBy(items, "id")
  , [ items ])

  const getOptionName = useCallback(id =>
    optionsMap[id]?.name || id
  , [ optionsMap ])

  return {
    segments,
    getOption,
    addOption,
    getOptions,
    optionsMap,
    accountTags,
    campaignTags,
    updateOption,
    deleteOption,
    indexOptions,
    ensureOption,
    createSegment,
    getOptionName,
    documentTypeOptions,
    subscriptionTypeOptions,
    batchDocumentTypeOptions,
    ensureDocumentTypeOption,
    ensureAccountTagTypeOption,
    ensureBatchDocumentTypeOption,
  }
}

const optionsStoreProperties = {
  segments: PropTypes.arrayOf(IndexOptionsOutputShape),
  getOption: PropTypes.func.isRequired,
  addOption: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  optionsMap: PropTypes.shape().isRequired,
  accountTags: PropTypes.arrayOf(IndexOptionsOutputShape),
  campaignTags: PropTypes.arrayOf(IndexOptionsOutputShape),
  updateOption: PropTypes.func.isRequired,
  deleteOption: PropTypes.func.isRequired,
  indexOptions: PropTypes.func.isRequired,
  ensureOption: PropTypes.func.isRequired,
  createSegment: PropTypes.func.isRequired,
  getOptionName: PropTypes.func.isRequired,
  documentTypeOptions: PropTypes.arrayOf(PropTypes.string),
  subscriptionTypeOptions: PropTypes.arrayOf(PropTypes.shape()),
  batchDocumentTypeOptions: PropTypes.arrayOf(PropTypes.string),
  ensureDocumentTypeOption: PropTypes.func.isRequired,
  ensureAccountTagTypeOption: PropTypes.func.isRequired,
  ensureBatchDocumentTypeOption: PropTypes.func.isRequired,
}

export default useOptionsStore

export {
  SEGMENT,
  ACCOUNT_TAG,
  CAMPAIGN_TAG,
  SUBSCRIPTION_TYPE,
  readOperation,
  indexOperation,
  createOperation,
  updateOperation,
  deleteOperation,
  optionsStoreProperties,
}
