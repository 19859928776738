import { useCallback } from "react"
import PropTypes from "prop-types"

import { getConfig } from "@components/Config"
import { hasAccess } from "@components/Authorization"
import { BROKER, ADMIN } from "@api/services/backstage/shapes/RoleEnum"
import { getCustomerSlug } from "@components/Dwolla"
import { updateMyCustomerVerificationStatusOperation } from "@api/services/transactions"

const consoleOrganizationId = getConfig("consoleOrganizationId")


const useIdentityStore = (identity, updateIdentity) => {
  const isSourceController = sourceId => {
    if (!identity) {
      return false
    }

    const { customers = [] } = identity

    const sources = customers
      .reduce((acc, cur) => [...acc, ...(cur.sources || [])], [])

    const sourceIds = sources.map(source => source.id)
    const hasSourceId = sourceIds.includes(sourceId)

    return hasSourceId
  }

  const addIdentityCustomer = newCustomer =>
    updateIdentity({
      ...identity,
      customers: [
        ...identity.customers,
        newCustomer
      ]
    })

  const updateIdentityCustomer = useCallback(updatedCustomer =>
    updateIdentity({
      ...identity,
      customers: [
        ...identity.customers
          .map(customer => ({
            ...customer,
            ...(customer.id === updatedCustomer?.id ? updatedCustomer : {})
          }))
      ]
    })
  , [ identity, updateIdentity ])

  const updateIdentityCustomerSource = useCallback(
    (customerId, updatedSource) => {
      const updatedCustomer = identity.customers.find(c => c.id === customerId) || {}

      const updatedSources = updatedCustomer.sources.map(source => {
        const isUpdatedSource = source.id === updatedSource?.id
        return isUpdatedSource ? updatedSource : source
      })

      updatedCustomer.sources = updatedSources

      updateIdentityCustomer(updatedCustomer)
    }
  , [ identity, updateIdentityCustomer ])

  const addIdentityCustomerSource = (customerId, newOrExistingSource) => {
    const { customers } = identity
    const customerIndex = customers.findIndex(({ id }) => customerId === id)

    const { sources = [] } = customers[customerIndex]

    const isNewSource = sources
      .filter(source => source.id === newOrExistingSource.id)
      .length === 0

    if (isNewSource) {
      customers[customerIndex].sources = [...sources, newOrExistingSource]
    }

    updateIdentity({ ...identity, customers: [ ...customers ] })
  }

  const updateIdentityMobile = (mobile) =>
    updateIdentity({ ...identity, mobile })

  const updateIdentityIs2FaEnabled = (is2FaEnabled) =>
    updateIdentity({ ...identity, is2FaEnabled })

  const getOrganization = () => {
    if (!identity) {
      return
    }

    return identity.organization
  }

  const getOrganizationId = () => {
    const organization = getOrganization()

    if (!organization) {
      return
    }

    return organization.id
  }

  const getOrganizationEmail = () => {
    const organization = getOrganization()

    if (!organization) {
      return
    }

    return organization.email
  }

  const getOrganizationName = () => {
    const organization = getOrganization()

    if (!organization) {
      return
    }

    return organization.name
  }

  const getCustomer = (customerName, impersonatedInvestorAccountId = false) => {
    const { customers } = identity

    const customerSlug = getCustomerSlug(customerName)

    if (!impersonatedInvestorAccountId) {
      return customers
        .find(item => getCustomerSlug(item.name) === customerSlug)
    }

    return customers
      .filter(customer => customer.investorAccountId === impersonatedInvestorAccountId)
      .find(item => getCustomerSlug(item.name) === customerSlug)
  }

  const updateMyCustomerVerificationStatus = async (authenticatedRequest, customerId) => {
    const { data } = await authenticatedRequest(updateMyCustomerVerificationStatusOperation, { id: customerId })
    updateIdentityCustomer(data)

    return data
  }

  const isInvalidSource = (sourceId) => {
    const { customers } = identity

    for (const customer of customers) {
      const { sources = [] } = customer

      for (const source of sources) {
        if (source.id === sourceId) {
          const { isInvalid } = source

          return isInvalid ? isInvalid : false
        }
      }
    }

    return false
  }

  const roles = identity?.roles || []

  const isBroker =
    roles.includes(BROKER) &&
    roles.length === 1

  const isAdministrator =
    roles.includes(ADMIN)

  const isInvestor = !!(identity?.investor)

  const isSponsorInvestor = isInvestor && hasAccess([BROKER])

  const isConsoleOrganization = consoleOrganizationId === getOrganizationId()

  const isConsoleAdministrator =
    isConsoleOrganization &&
    isAdministrator

  const organizations = identity?.organizations || []

  const hasConsoleAccess = !!organizations.find(({ id }) => id === consoleOrganizationId)

  return {
    isBroker,
    isInvestor,
    getCustomer,
    isInvalidSource,
    getOrganization,
    hasConsoleAccess,
    isSponsorInvestor,
    getOrganizationId,
    isSourceController,
    addIdentityCustomer,
    getOrganizationName,
    getOrganizationEmail,
    updateIdentityMobile,
    isConsoleOrganization,
    isConsoleAdministrator,
    updateIdentityCustomer,
    addIdentityCustomerSource,
    updateIdentityIs2FaEnabled,
    updateIdentityCustomerSource,
    updateMyCustomerVerificationStatus,
  }
}

const identityStoreProperties = {
  isBroker: PropTypes.bool.isRequired,
  isInvestor: PropTypes.bool.isRequired,
  getCustomer: PropTypes.func.isRequired,
  isInvalidSource: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  hasConsoleAccess: PropTypes.bool.isRequired,
  getOrganizationId: PropTypes.func.isRequired,
  isSponsorInvestor: PropTypes.bool.isRequired,
  isSourceController: PropTypes.func.isRequired,
  addIdentityCustomer: PropTypes.func.isRequired,
  getOrganizationName: PropTypes.func.isRequired,
  getOrganizationEmail: PropTypes.func.isRequired,
  updateIdentityMobile: PropTypes.func.isRequired,
  isConsoleOrganization: PropTypes.bool.isRequired,
  isConsoleAdministrator: PropTypes.bool.isRequired,
  updateIdentityCustomer: PropTypes.func.isRequired,
  addIdentityCustomerSource: PropTypes.func.isRequired,
  updateIdentityIs2FaEnabled: PropTypes.func.isRequired,
  updateIdentityCustomerSource: PropTypes.func.isRequired,
  updateMyCustomerVerificationStatus: PropTypes.func.isRequired,
}

export default useIdentityStore

export {
  identityStoreProperties
}
