import groupBy from "lodash.groupby"


const getInvalidSourceInvestment = (identity, activeInvestments) => {
  const { customers = [] } = identity

  const investmentsMap = groupBy(activeInvestments, "receiverSourceId")

  for (const customer of customers) {
    const { sources = [] } = customer

    const invalidSources = sources
      .filter(({ isInvalid }) => isInvalid)
      .filter(({ id }) => !!investmentsMap[id])

    const hasInvalidSourceInvestments = invalidSources.length > 0

    if (hasInvalidSourceInvestments) {
      const [ invalidSource ] = invalidSources
      const { id: invalidSourceId } = invalidSource
      const [ invalidSourceInvestment ] = investmentsMap[invalidSourceId]

      return [ invalidSource, customer, invalidSourceInvestment ]
    }
  }

  return []
}

export default getInvalidSourceInvestment
