import React from "react"
import PropTypes from "prop-types"
import { Form, InputNumber, Typography, Space } from "antd"

import { W0 } from "@components"
import TierShape from "@api/services/backstage/shapes/TierShape"
import { FormShape } from "@components/Form"
import { getAmountWithoutCents } from "@components/Amount"

const { Text } = Typography

export const KEY_AMOUNT = "amount"
export const KEY_AMOUNTS_MAP ="_amountsMap"

const LABEL_CLASS = "Class"


const InvestmentTierOption = ({
  name,
  form,
  path,
  tier,
  autoFocus,
  selectedTierIds,
}) => {
  const { title, name: id } = tier
  const formatter = value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  const onChange = amount => {
    if (amount) {
      amount = getAmountWithoutCents(amount)
    }

    const investments = form.getFieldValue(path)

    const tierIndex = investments.findIndex(investment => investment.projectTierId === id)
    const isTierFound = tierIndex >= 0

    let investmentsChanged = [...investments]

    if (isTierFound) {
      investmentsChanged[tierIndex].amount = amount

    } else {
      investmentsChanged = [...investmentsChanged, { projectTierId: id, amount }]

    }

    form.setFieldValue(path, investmentsChanged)
  }

  const label = title || `${LABEL_CLASS} ${id}`

  const amountsFieldName = [ ...name.slice(0, -1), KEY_AMOUNTS_MAP, id, KEY_AMOUNT ]

  return (
    <Space
      size={0}
      style={{ lineHeight: 1.2 }}
      direction="vertical"
    >
      <Text className="checkbox-label">
        { label }
      </Text>

      <Text type="secondary">
        {tier.description}
      </Text>

      {
        selectedTierIds.includes(id) && (
          <Form.Item
            name={amountsFieldName}
            style={{ marginBottom: 0, marginTop: W0 / 2 }}
            rules={[{ required: true, message: "Field is required" }]}
            getValueProps={value => {
              if (!value) {
                return { value }
              }

              return { value: value / 100 }
            }}
          >
            <InputNumber
              max={100000000}
              min={0}
              style={{ width: 160 }}
              prefix="$"
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              controls={false}
              onChange={onChange}
              formatter={formatter}
              autoFocus={autoFocus}
            />
          </Form.Item>
        )
      }
    </Space>
  )
}

InvestmentTierOption.propTypes = {
  tier: TierShape.isRequired,
  form: FormShape.isRequired,
  name: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.string), PropTypes.string ]).isRequired,
  path: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.string), PropTypes.string ]).isRequired,
  autoFocus: PropTypes.bool.isRequired,
  selectedTierIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default InvestmentTierOption
