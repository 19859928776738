import PropTypes from "prop-types"

import { authorizationStoreProperties } from "../../Authorization/stores/useAuthorizationStore"


const AppContextShape = PropTypes.exact({
  showErrorMessage: PropTypes.func.isRequired,
  showLoadingMessage: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  ...authorizationStoreProperties
})

export default AppContextShape
