import { PROJECT_STATES, PROJECT_TYPES } from "@components/Domain"


const sortItemsByName = items =>
  items.sort((a, b) => a.name < b.name ? -1 : 1)


const getActiveProjects = (projects = []) => {
  const activeProjects = (projects || [])
    .filter(({ state }) => state !== PROJECT_STATES.ARCHIVED)

  const fundProject = activeProjects.filter(({ type }) => type === PROJECT_TYPES.FUND)
  const sortedFundProject = sortItemsByName(fundProject)

  const nonFundProject = activeProjects.filter(({ type }) => type !== PROJECT_TYPES.FUND)
  const sortedNonFundProject = sortItemsByName(nonFundProject)

  const sortedProjects = [...sortedFundProject, ...sortedNonFundProject]

  return sortedProjects
}

export default getActiveProjects
