import React, { useMemo, useRef } from "react"
import PropTypes from "prop-types"

import Collection from "@components/Collection"

import getTableSummary from "./helpers/getTableSummary"
import getColumnsConfig from "./helpers/getColumnsConfig"
import { InvestmentShape } from "../../../shapes"
import FundingDescriptions from "./FundingDescriptions"
import useTransactionOperations from "./helpers/useTransactionOperations"

const DEFAULT_EDITABLE_TRANSACTION = {
  id: null,
  date: "",
  amount: 0,
  referenceId: null,
  editableRow: true
}


const InvestmentFunding = ({
  investment,
  readInvestment,
  isInvestmentFundedTemplateEnabled
}) => {
  const collection = useRef({})

  const {
    id: investmentId,
    transactions: investmentTransactions = []
  } = investment

  const transactions = useMemo(() =>
    [
      ...(
        investmentTransactions
          .map(transaction => ({ ...transaction, editableRow: false }))
      ),
      { ...DEFAULT_EDITABLE_TRANSACTION }
    ]
  , [ investmentTransactions ])

  const {
    createTransaction,
    deleteTransaction
  } = useTransactionOperations(collection, investmentId, readInvestment)

  const summary = getTableSummary()
  const columnsConfig = getColumnsConfig({
    collection,
    createTransaction,
    deleteTransaction,
    isInvestmentFundedTemplateEnabled
  })

  const tableProps = {
    size: "small",
    rowKey: "id",
    bordered: false,
  }

  const items = transactions || []
  const selectedRowItems = items.map(({ key }) => key)
  const enableSelectItem = false

  const collectionProps = {
    items,
    summary,
    tableProps,
    collection,
    columnsConfig,
    enableSelectItem,
    selectedRowItems,
  }

  return (
    <>
      <Collection
        {...collectionProps}
      />

      <FundingDescriptions
        investment={investment}
        readInvestment={readInvestment}
      />
    </>
  )
}

InvestmentFunding.propTypes = {
  investment: InvestmentShape.isRequired,
  readInvestment: PropTypes.func.isRequired,
  isInvestmentFundedTemplateEnabled: PropTypes.func.isRequired,
}

export default InvestmentFunding
