import sortBy from "lodash.sortby"

const LABEL_NONE = "None"


const getBankAccountOptions = (bankAccounts, shouldDisableUnverified = false) =>
  [
    ...sortBy(bankAccounts, "beneficiaryName")
      .map(bankAccount => {
        const {
          id,
          isInvalid,
          sourceName,
          beneficiaryName,
          isSourceVerified
        } = bankAccount

        let label = `${beneficiaryName} - ${sourceName}`
        let disabled = false

        if (shouldDisableUnverified) {
          disabled = !isSourceVerified
        }

        if (isInvalid) {
          label = `${label} - Invalid`
          disabled = true
        }

        return {
          label,
          disabled,
          value: id,
        }
    }),
    {
      label: LABEL_NONE,
      value: ""
    },
  ]

export default getBankAccountOptions
