import PropTypes from "prop-types"

import CustomerShape from "./shapes/CustomerShape"
import PartnerShape from "./shapes/PartnerShape"
import RoleEnum from "./shapes/RoleEnum"
import UserOrganizationShape from "./shapes/UserOrganizationShape"

const properties = {
  acceptedTermsVersion: PropTypes.number,
  authorizationJwt: PropTypes.string.isRequired,
  correlationId: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  customers: PropTypes.arrayOf(CustomerShape).isRequired,
  disableReason: PropTypes.string,
  email: PropTypes.string.isRequired,
  familyName: PropTypes.string.isRequired,
  givenName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  is2FaEnabled: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
  isLatestTermsAccepted: PropTypes.bool.isRequired,
  lastAuthenticatedAt: PropTypes.string,
  mobile: PropTypes.string,
  organizations: PropTypes.arrayOf(UserOrganizationShape).isRequired,
  receiverSourceId: PropTypes.string,
  receiverSourceName: PropTypes.string,
  referenceUserGroupId: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(RoleEnum))).isRequired,
  sponsorId: PropTypes.string,
  sponsorProjects: PropTypes.arrayOf(PartnerShape).isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  userGroupIds: PropTypes.arrayOf(PropTypes.string),
  verificationRequestCreatedAt: PropTypes.string,
  verificationRequestId: PropTypes.string
}

const AuthorizeOutputShapeShape = PropTypes.exact(properties)

export default AuthorizeOutputShapeShape
export { properties }
