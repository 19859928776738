import { hasAccess } from "@components/Authorization"
import { useConfig } from "@components/Config"

const PATH_BACKSTAGE = "/backstage"

const LABEL_STAFF = "Staff"
const LABEL_SOURCES = "Bank Accounts"
const LABEL_LIAISONS = "Liaisons"
const LABEL_ORGANIZATION = "Organization"
const LABEL_EMAIL_IDENTITY = "Custom Domain"


const useSettingsLinks = () => {
  const hasCampaigns = useConfig("hasCampaigns")

  const canViewSources = hasAccess(['bank-accounts-read', 'org-read'])
  const canViewMembers = hasAccess(['users-read', 'org-read'])
  const canViewSponsors = hasAccess(['sponsors-read'])
  const canViewOrganization = hasAccess(['org-read'])
  const canViewEmailIdentity = hasAccess(['org-read']) && hasCampaigns

  const items = []

  if (canViewOrganization) {
    items.push({
      title: LABEL_ORGANIZATION,
      path: `${PATH_BACKSTAGE}/settings/organization`,
    })
  }

  if (canViewMembers) {
    items.push({
      title: LABEL_STAFF,
      path: `${PATH_BACKSTAGE}/settings/members`,
    })
  }

  if (canViewSponsors) {
    items.push({
      title: LABEL_LIAISONS,
      path: `${PATH_BACKSTAGE}/settings/brokers`,
    })
  }

  if (canViewSources) {
    items.push({
      title: LABEL_SOURCES,
      path: `${PATH_BACKSTAGE}/settings/sources`,
    })
  }

  if (canViewEmailIdentity) {
    items.push({
      title: LABEL_EMAIL_IDENTITY,
      path: `${PATH_BACKSTAGE}/settings/email-identity`,
    })
  }

  return items
}

export default useSettingsLinks
