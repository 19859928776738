import getOperation from "../../helpers/getOperation"

import AuthenticateAsOutputShape from "./AuthenticateAsOutputShape"
import AuthenticateOutputShape from "./AuthenticateOutputShape"

export {
  AuthenticateAsOutputShape,
  AuthenticateOutputShape
}

export const authenticateOperation = getOperation("identity/Authenticate")
export const authenticateAsOperation = getOperation("identity/AuthenticateAs")
export const changeMyIdentityEmailOperation = getOperation("identity/ChangeMyIdentityEmail")
export const confirmMyIdentityEmailOperation = getOperation("identity/ConfirmMyIdentityEmail")
