

const getEnumColumnFilterProps = (hasHeaderFilters, operation, key, enumValues, compute, filterValues) => {
  const onFilter = (selectedValues, item) =>
    selectedValues.includes(compute(item))

  const props = {
    onFilter,
  }

  const computedValues = filterValues
    ? filterValues.map(value => compute({ [key]: value }))
    : enumValues
      .map(value => compute({ [key]: value }))
      .sort((a, b) => a.localeCompare(b))

  if (hasHeaderFilters) {
    props.options = computedValues
      .map(value => ({ label: value, value }))

    return props
  }

  props.filters = computedValues
      .map(value => ({ text: value, value }))

  return props
}

export default getEnumColumnFilterProps
