import { useReducer } from "react"


const getFiltersMap = (prevFiltersMap, dataIndex, value) => {
  const isFilterOff =
    !value ||
    value.length === 0

  if (isFilterOff) {
    delete prevFiltersMap[dataIndex]
    return { ...prevFiltersMap }
  }

  const values = Array.isArray(value)
    ? value
    : [ value ]

  return {
    ...prevFiltersMap,
    [dataIndex]: values
  }
}

const useFilters = (items, columns) => {
  const filterColumns = columns
    .filter(({ onFilter }) => !!onFilter)

  const getActiveFilters = filtersMap =>
    filterColumns
      .filter(({ dataIndex }) => !!filtersMap[dataIndex])

  const filterItems = (filtersMap) => {
    const activeFilters = getActiveFilters(filtersMap)
    const hasActiveFilters = activeFilters.length > 0

    if (!hasActiveFilters) {
      return
    }

    const filterItem = item => {
      for (const { dataIndex, onFilter } of activeFilters) {
        const selectedValues = filtersMap[dataIndex]
        const isMatched = onFilter(selectedValues, item)

        if (!isMatched) {
          return false
        }
      }

      return true
    }

    const updatedFilteredItems = items
      .filter(item => filterItem(item))

    return updatedFilteredItems
  }

  const reducer = (state, { dataIndex, value, filtersMap }) => {
    if (filtersMap) {
      const filteredItems = filterItems(filtersMap)

      return {
        filtersMap,
        filteredItems
      }
    }

    const newFiltersMap = getFiltersMap(state.filtersMap, dataIndex, value)
    const filteredItems = filterItems(newFiltersMap)

    return {
      filtersMap: newFiltersMap,
      filteredItems
    }
  }

  const [ state, dispatch ] = useReducer(reducer, {
    filtersMap: {},
    filteredItems: undefined
  })

  const resetFilters = () =>
    dispatch({ filtersMap: {} })

  const onTableChange = (pagination, tableFiltersMap) =>
    dispatch({ filtersMap: tableFiltersMap })

  const onFilterChange = (dataIndex, value) =>
    dispatch({ dataIndex, value })

  const getFilterValue = key =>
    state.filtersMap[key]
      ? state.filtersMap[key]
      : undefined

  const hasActiveFilters = getActiveFilters(state.filtersMap).length > 0

  const headerFilterProps = {
    columns: filterColumns,
    onChange: onFilterChange,
    resetFilters,
    getFilterValue,
    hasActiveFilters,
  }

  return {
    filteredItems: state.filteredItems || items,
    onTableChange,
    headerFilterProps
  }
}

export default useFilters
