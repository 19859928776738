import React from "react"
import PropTypes from "prop-types"

import { EntityForm, FormShape } from "@components/Form"
import {
  sendContactUsGuestNotificationOperation as guestOperation,
  sendContactUsInvestorNotificationOperation as investorOperation,
} from "@api/services/notifications"

import getGuestInputs from "./helpers/getGuestInputs"
import getInvestorInputs from "./helpers/getInvestorInputs"

const LABEL_ENTITY = "Request"
const LABEL_SUBMIT = "Send"
const LABEL_SUCCESS = "Notification has been sent"


const ContactForm = ({
  isGuest = false,
  projectId = undefined,
  organizationId = undefined,
  form,
  request,
  onSuccess,
}) => {
  const inputs = isGuest
    ? getGuestInputs(organizationId, projectId)
    : getInvestorInputs()

  const schema = isGuest
    ? guestOperation.getSchema(inputs)
    : investorOperation.getSchema(inputs)

  const operation = isGuest
    ? guestOperation
    : investorOperation

  return (
    <EntityForm
      form={form}
      schema={schema}
      entity={LABEL_ENTITY}
      request={request}
      operation={operation}
      onSuccess={onSuccess}
      submitLabel={LABEL_SUBMIT}
      successMessage={LABEL_SUCCESS}
    />
  )
}

ContactForm.propTypes = {
  form: FormShape.isRequired,
  isGuest: PropTypes.bool,
  request: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  organizationId: PropTypes.string,
}

export default ContactForm
