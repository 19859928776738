import React from "react"
import { View, Image, Text } from "@aws-amplify/ui-react"

import { W1, LOGO_HEIGHT } from "@components"
import { getResizedImageUrl } from "@components/Storage"


const Header = (title, logoUrl) => {
  return (
    <View
      style={{ minHeight: LOGO_HEIGHT * 4.5, paddingTop: W1 }}
      textAlign="center"
    >
      {
        logoUrl && (
          <Image
            alt={title}
            src={getResizedImageUrl(logoUrl, null, LOGO_HEIGHT * 4)}
            style={{ marginTop: LOGO_HEIGHT * 1.25 }}
            height={LOGO_HEIGHT * 2}
          />
        )
      }

      {
        !logoUrl && (
          <Text
            style={{ marginTop: LOGO_HEIGHT * 1.5 }}
            fontSize="large"
            fontWeight="semibold"
          >
            {title}
          </Text>
        )
      }
    </View>
  )
}

export default Header
