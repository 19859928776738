import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { Table } from "@components/Table"
import { indexUserGroupsOperation as indexOperation } from "@api/services/backstage"

import getHeaderActions from "./helpers/getHeaderActions"
import getColumnsConfig from "./helpers/getColumnsConfig"


const UserGroupsTable = ({ table, openCreateModal, openUpdateModal }) => {
  const {
    userGroups: items,
    removeUserGroup,
    indexUserGroups: indexItems
  } = useOutletContext()

  const size = "small"
  const pagination = false
  const columnsConfig = getColumnsConfig(removeUserGroup, openUpdateModal)
  const headerActions = getHeaderActions(openCreateModal)

  const tableProps = {
    size,
    table,
    items,
    indexItems,
    pagination,
    headerActions,
    columnsConfig,
    indexOperation,
  }

  return (
    <Table {...tableProps} />
  )
}

UserGroupsTable.propTypes = {
  table: PropTypes.shape().isRequired,
  openCreateModal: PropTypes.func.isRequired,
  openUpdateModal: PropTypes.func.isRequired,
}

export default UserGroupsTable
