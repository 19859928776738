import { InvestmentStatusEnumKeys } from "./shapes/InvestmentStatusEnum"
import { ProfileTypeEnumKeys } from "./shapes/ProfileTypeEnum"
import { RejectionReasonEnumKeys } from "./shapes/RejectionReasonEnum"

const IndexAccountInvestmentsItemSchema = {
  status: { enum: InvestmentStatusEnumKeys },
  rejectionReason: { enum: RejectionReasonEnumKeys },
  isImmutable: { type: 'boolean' },
  isFundingUnlocked: { type: 'boolean' },
  isPartner: { type: 'boolean' },
  isPostponed: { type: 'boolean' },
  projectClosedNotificationCreatedAt: { format: 'date-time' },
  profileType: { enum: ProfileTypeEnumKeys },
  isInvestorComplete: { type: 'boolean' },
  isProfileComplete: { type: 'boolean' },
  isProfileValid: { type: 'boolean' },
  isSigned: { type: 'boolean' },
  isCountersigned: { type: 'boolean' },
  isPartiallySigned: { type: 'boolean' },
  totalAmount: { type: 'integer' },
  receivedAmount: { type: 'integer' },
  isPartiallyFunded: { type: 'boolean' },
  isFunded: { type: 'boolean' },
  isOverfunded: { type: 'boolean' },
  hasSentFunds: { type: 'boolean' },
  fundsSentAt: { format: 'date-time' },
  noteUpdatedAt: { format: 'date-time' },
  fundedAt: { format: 'date-time' },
  signedAt: { format: 'date-time' },
  completedAt: { format: 'date-time' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexAccountInvestmentsItemSchema
