import { App } from "antd"

import confirmAction from "@components/Page/helpers/confirmAction"
import { useAppContext } from "@components/AppContext"
import { deleteFileOperation as deleteOperation } from "@api/services/backstage"

const ACTION_MAP = {
  type: "danger",
  title: "Delete File",
  action: "Delete",
  success: "INVESTMENT_NAME file deleted",
  content: "Are you sure you want to delete this file?",
}


const useDeleteFileAction = afterAction => {
  const { modal } = App.useApp()
  const { request, showSuccessMessage } = useAppContext()

  return (id, investmentName) => confirmAction(
    modal,
    ACTION_MAP.action,
    ACTION_MAP.title,
    ACTION_MAP.content,
    () => {
      return request(deleteOperation, { id })
        .then(() => {
          const message = ACTION_MAP.success
            .replace('INVESTMENT_NAME', investmentName)

          showSuccessMessage(message)
          return afterAction(id)
        })
    },
    ACTION_MAP.type,
  )
}

export default useDeleteFileAction
