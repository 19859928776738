import getAddress from "./getAddress"
import getAddressSchema from "./getAddressSchema"
import getUsAddressSchema from "./getUsAddressSchema"
import getRegionsByCountry from "./getRegionsByCountry"
import getDwollaAddressSchema from "./getDwollaAddressSchema"
import getRegionNameByCountryAndRegionCode from "./getRegionNameByCountryAndRegionCode"

const COUNTRY_US = "US"
const US_STATE_OPTIONS = getRegionsByCountry("US")
const LABEL_STATE_PLACEHOLDER = "Select state"

export {
  COUNTRY_US,
  US_STATE_OPTIONS,
  LABEL_STATE_PLACEHOLDER,
  getAddress,
  getAddressSchema,
  getUsAddressSchema,
  getDwollaAddressSchema,
  getRegionNameByCountryAndRegionCode
}
