import * as Sentry from "@sentry/react"

import apiRequest from "./apiRequest"


const operationRequest = async ({
  headers = {},
  parameters = {},
  expectedErrors = [],
  onUnexpectedError = () => {},
  operation,
}) => {
  let result

  try {
    result = await apiRequest(operation, parameters, headers)

  } catch (error) {
    error.isExpected = expectedErrors.includes(error.code)

    if (!error.isExpected) {
      const { sentryExtra } = error

      if (sentryExtra) {
        Sentry.setExtras(sentryExtra)
      }

      onUnexpectedError(error)
    }

    throw error
  }

  return result
}

export default operationRequest
