import {
  updateFundPartnerOperation,
  updateSponsorPartnerOperation,
} from "@api/services/backstage"


const getUpdatePartner = (request, updatePartners) => {
  const updatePartner = row => {
    if (!row.connected) {
      return
    }

    const { id, projectPartnerId, type, goal, limit } = row
    let updatedPartnerMap = {
      [id]: { disabled: true }
    }
    updatePartners(updatedPartnerMap)

    const isSponsor = type === "sponsor"

    const operationId = isSponsor
      ? updateSponsorPartnerOperation
      : updateFundPartnerOperation

    const parameters = isSponsor
      ? { id: projectPartnerId, sponsorId: id, mutation: { targetAmount: goal, nonAccreditedInvestorsMax: limit } }
      : { id: projectPartnerId, fundId: id, mutation: { targetAmount: goal } }

    return request(operationId, parameters)
      .then(() => {
        updatedPartnerMap = {
          [id]: { disabled: false, goal, limit }
        }
        updatePartners(updatedPartnerMap, true)
      })
  }

  return updatePartner
}

export default getUpdatePartner
