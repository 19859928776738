import getInvalidSourceInvestment from "./getInvalidSourceInvestment"

const STATUS_VERIFIED = "VERIFIED"


const getInvalidSourceParams = (identity, activeInvestments) => {
  const hasInvestments = activeInvestments.length > 0

  if (!hasInvestments) {
    return [ false ]
  }

  const [
    invalidSource,
    invalidSourceCustomer,
    invalidSourceInvestment
  ] = getInvalidSourceInvestment(identity, activeInvestments)

  if (!invalidSource) {
    return [ false ]
  }

  const { name: sourceName } = invalidSource
  const { verificationStatus, name: customerName } = invalidSourceCustomer

  const isVerifiedCustomer = verificationStatus === STATUS_VERIFIED
  const { projectId } = invalidSourceInvestment

  return [ true, sourceName, customerName, isVerifiedCustomer, projectId ]
}

export default getInvalidSourceParams
