import React, { useRef } from "react"

import { W1 } from "@components"
import { Page } from "@components/Page"
import { InvestmentDrawer } from "@components/Investment"

import TransactionDrawer from "./TransactionDrawer"
import TransactionsTable from "./TransactionsTable"
import TransactionsImportModal from "./TransactionsImportModal"

const LABEL_TITLE = "Transactions"


const TransactionsPage = () => {
  const table = useRef({})
  const investmentDrawer = useRef({})
  const transactionDrawer = useRef({})
  const transactionsImportModal = useRef({})

  const onSearch = value =>
    table.current.search(value)

  const onImportSuccess = () =>
    table.current.indexItems(false)

  const indexTransactions = showSpinner =>
    table.current.indexItems(showSpinner)

  const openImportModal = () =>
    transactionsImportModal.current.open()

  const openInvestmentDrawer = investmentId =>
    investmentDrawer.current.open(investmentId)

  const openTransactionDrawer = transaction =>
    transactionDrawer.current.open(transaction)

  const style = {
    padding: W1,
    paddingBottom: 0,
  }

  return (
    <Page
      style={style}
      title={LABEL_TITLE}
      onSearch={onSearch}
    >
      <TransactionsTable
        table={table}
        openImportModal={openImportModal}
        openInvestmentDrawer={openInvestmentDrawer}
        openTransactionDrawer={openTransactionDrawer}
      />

      <InvestmentDrawer
        drawer={investmentDrawer}
      />

      <TransactionDrawer
        drawer={transactionDrawer}
        indexTransactions={indexTransactions}
      />

      <TransactionsImportModal
        modal={transactionsImportModal}
        onSuccess={onImportSuccess}
      />
    </Page>
  )
}

export default TransactionsPage
