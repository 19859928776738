import React, { useState } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { Modal } from "@components/Page"
import { InvestmentShape } from "@components/Investment/shapes"
import { InvestmentProfile } from "@components/Investment"


const ProfileModal = ({
  footer = undefined,
  modal,
  investment,
}) => {
  const { getProject } = useOutletContext()

  const [ isOpen, setIsOpen ] = useState(false)

  modal.current.open = () => setIsOpen(true)

  const onClose = () => setIsOpen(false)

  const { name: title, projectId } = investment

  const { tiers } = getProject(projectId)

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
    >
      <InvestmentProfile
        tiers={tiers}
        footer={footer}
        investment={investment}
      />
    </Modal>
  )
}

ProfileModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  footer: PropTypes.shape(),
  investment: InvestmentShape.isRequired,
}

export default ProfileModal
