import capitalize from "lodash.capitalize"

export const DELAYED = "DELAYED"
export const INSTANT = "INSTANT"
export const RECURRING = "RECURRING"
export const SCHEDULED = "SCHEDULED"

const TemplateTypeEnum = {
  [DELAYED]: DELAYED,
  [INSTANT]: INSTANT,
  [RECURRING]: RECURRING,
  [SCHEDULED]: SCHEDULED
}

const TemplateTypeEnumKeys = Object.keys(TemplateTypeEnum)

const TemplateTypeEnumOptions = TemplateTypeEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  TemplateTypeEnumKeys,
  TemplateTypeEnumOptions
}

export default TemplateTypeEnum
