import React from "react"
import { Tag } from "antd"

import { getConfig } from "@components/Config"
import { useAppContext } from "@components/AppContext"

const ENVIRONMENT_LABEL = getConfig("ENVIRONMENT_LABEL")


const useOrganizationName = () => {
  const { identity } = useAppContext()
  const { organization } = identity

  const { name } = organization

  const isProductionEnvironment = ENVIRONMENT_LABEL === ""

  if (isProductionEnvironment) {
    return name
  }

  return (
    <span>
      <Tag
        color="purple"
        bordered={false}
        style={{ top: -2, left: -2, position: "relative" }}
      >
        {ENVIRONMENT_LABEL}
      </Tag>

      {name}
    </span>
  )
}

export default useOrganizationName
