import React from "react"
import { Outlet } from "react-router-dom"

import ConsoleLayoutContextShape from "./shapes/ConsoleLayoutContextShape"


const ConsoleLayoutOutlet = ({ context }) => {
  return (
    <Outlet context={context} />
  )
}

ConsoleLayoutOutlet.propTypes = {
  context: ConsoleLayoutContextShape.isRequired,
}

export default ConsoleLayoutOutlet
