
const getTextWidth = text => {
  const element = document.createElement("canvas")
  const context = element.getContext("2d")

  context.font = getComputedStyle(document.body).font

  const { width } = context.measureText(text)

  return Number(width)
}

export default getTextWidth
