import React from "react"
import uniq from "lodash.uniq"
import chunk from "lodash.chunk"
import { Button } from "antd"
import { TagsOutlined } from "@ant-design/icons"
import { useOutletContext } from "react-router-dom"

import { wait } from "@api"
import { hasAccess } from "@components/Authorization"
import { EditTagsDropdown } from "@components/Tags"

const CHUNK_SIZE = 10
const SAVE_DELAY = 100

const LABEL_ADD = "Add Tags"


const useSelectableActions = (table, openTagsDrawer) => {
  const {
    getAccount,
    accountTags,
    setAccountTags,
  } = useOutletContext()

  const hasAccountTags = accountTags.length > 0

  const canUpdateAccounts = hasAccess(['accounts-write'])

  if (!canUpdateAccounts) {
    return []
  }

  const onSelectionChange = () => {}

  const onSave = async (newTagIds) => {
    const accountIds = table.current.getSelectedRowKeys()

    const saveConcurrently = ids =>
      Promise.all(ids.map(id => {
        const account = getAccount(id)
        const { tagIds = [] } = account

        const accountTagIds = uniq([
          ...tagIds,
          ...newTagIds
        ])

        return setAccountTags(id, accountTagIds)
      }))

    const chunks = chunk(accountIds, CHUNK_SIZE)

    for (const chunkIds of chunks) {
      await saveConcurrently(chunkIds)
      await wait(SAVE_DELAY)
    }

    table.current.setSelectedRowKeys([])
  }

  const getComponent = () => {
    if (hasAccountTags) {
      return (
        <EditTagsDropdown
          size="small"
          tags={accountTags}
          onSave={onSave}
          isAlwaysShown={true}
        />
      )
    }

    return (
      <Button
        size="small"
        type="text"
        icon={<TagsOutlined />}
        onClick={() => openTagsDrawer()}
      >
        {LABEL_ADD}
      </Button>
    )
  }

  const actionItems = [
    {
      component: getComponent()
    },
  ]

  return [ actionItems, onSelectionChange ]
}

export default useSelectableActions
