import React from "react"
import { Descriptions } from "antd"

const { Item } = Descriptions


const getDocusignItems = (organization) => {
  const {
    docusignAccountId,
    docusignWebhookSecret
  } = organization

  const items = [
    {
      label: "Account ID",
      value: docusignAccountId
    },
    {
      label: "Webhook Secret",
      value: docusignWebhookSecret
    },
  ]

  return items
    .map(({ label, value }, key) =>
      <Item
        key={key}
        label={label}
      >
        {value}
      </Item>
    )
}

export default getDocusignItems
