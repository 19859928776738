import React from "react"
import { useOutletContext } from "react-router-dom"
import { Space, Typography } from "antd"

import Identity from "@components/Identity"
import { Value } from "@components/Text"

import ImpersonatedSourceConnect from "../ImpersonatedSourceConnect"

const { Text } = Typography

const LABEL_ON = "on"
const LABEL_PROJECT = "Project"
const LABEL_LIAISON = "Liaison"
const LABEL_UPDATED = "Last Updated"
const LABEL_CREATED = "Created"
const LABEL_BANK_ACCOUNT = "Bank Account"


const useItems = (investment, updateInvestment) => {
  const {
    updatedAt,
    updatedBy,
    sponsorId,
    projectId,
    createdAt,
    brokerUserId,
  } = investment

  const { hasMultipleSponsors, getProjectName, getSponsorName } = useOutletContext()
  const sponsorName = getSponsorName(sponsorId)

  const items = [
    {
      label: LABEL_PROJECT,
      children: getProjectName(projectId),
    },
    {
      label: LABEL_LIAISON,
      children: (
        <Space>
          <Identity id={brokerUserId} />
          { hasMultipleSponsors && sponsorName}
        </Space>
      )
    },
    {
      label: LABEL_CREATED,
      children: (
        <Value value={createdAt} />
      )
    },
    {
      label: LABEL_BANK_ACCOUNT,
      children: (
        <ImpersonatedSourceConnect
          investment={investment}
          updateInvestment={updateInvestment}
        />
      ),
    },
  ]

  if (updatedBy) {
    items.push(
      {
        label: LABEL_UPDATED,
        children: (
          <Space size={6}>
            <Identity id={updatedBy} />
            {updatedAt && <Text type="secondary">{LABEL_ON}</Text> }
            {updatedAt && <Value value={updatedAt} />}
          </Space>
        )
      }
    )
  }

  return items
}

export default useItems
