import React from "react"
import PropTypes from "prop-types"
import { Layout, Typography, theme } from "antd"

const { Text } = Typography

const LABEL_RIGHTS_RESERVED = "All rights reserved."


const Footer = ({
  style = {},
  organizationName = undefined,
}) => {
  const { token } = theme.useToken()
  const { colorBgContainer } = token

  const yearDate = new Date().getFullYear()

  if (!organizationName) {
    return null
  }

  return (
    <Layout.Footer
      style={{
        textAlign: "center",
        backgroundColor: colorBgContainer,
        ...style
      }}
    >
      <Text type="secondary">
        {`© ${yearDate} ${organizationName}`}

        <br/>

        {LABEL_RIGHTS_RESERVED}
      </Text>
    </Layout.Footer>
  )
}

Footer.propTypes = {
  style: PropTypes.shape(),
  organizationName: PropTypes.string,
}

export default Footer
