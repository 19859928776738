import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Auth } from "aws-amplify"
import { IdcardOutlined } from "@ant-design/icons"
import { Typography, Space } from "antd"

import { useAppContext } from "@components/AppContext"

import ChangeEmailModal from "./ChangeEmailModal"
import ConfirmEmailModal from "./ConfirmEmailModal"

const LABEL_CHANGE = "Change"


const UpdateIdentityEmailLink = ({ title = LABEL_CHANGE }) => {
  const changeEmailModal = useRef({})
  const confirmEmailModal = useRef({})

  const { identity, isImpersonated } = useAppContext()

  const onClick = async () =>
    changeEmailModal.current.openItem(identity)

  const onSuccess = async (email) => {
    const user = await Auth.currentAuthenticatedUser()

    const { signInUserSession } = user
    const { jwtToken: accessToken } = signInUserSession.accessToken

    confirmEmailModal.current.openItem({
      email,
      accessToken,
    })
  }

  const disabled = isImpersonated

  return (
    <>
      <Typography.Link
        strong
        onClick={onClick}
        disabled={disabled}
      >
        <Space>
          <IdcardOutlined />
          {title}
        </Space>
      </Typography.Link>

      <ChangeEmailModal
        modal={changeEmailModal}
        onSuccess={onSuccess}
      />

      <ConfirmEmailModal
        modal={confirmEmailModal}
      />
    </>
  )
}

UpdateIdentityEmailLink.propTypes = {
  title: PropTypes.string,
}

export default UpdateIdentityEmailLink
