import { usePapaParse } from "react-papaparse"

import { downloadContent } from "@components/Import"

import getExportData from "./getExportData"


const useExportAction = (getFilteredItems, fileName, columns) => {
  const { jsonToCSV } = usePapaParse()

  return () => {
    const items = getFilteredItems()
    const data = getExportData(columns, items)
    const content = jsonToCSV(data)

    downloadContent(`${fileName}.csv`, content, 'text/csv', true)
  }
}

export default useExportAction
