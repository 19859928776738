import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { hasAccess } from "@components/Authorization"
import {
  ProjectPropertyCreateModal,
  ProjectPropertyUpdateModal,
  ConnectFundProjectPropertyModal
} from "@modules/backstage/properties"
import {
  ProjectDetails,
  ProjectDetailsContext,
  ProjectDetailsContextShape,
} from "@components/Project"

import ProjectUpdateModal from "./ProjectUpdateModal"


const ProjectDetailsPageContext = ({
  context,
  projectUpdateModal,
  propertyUpdateModal,
  propertyCreateModal,
  propertyConnectModal,
}) => {
  const { project } = useOutletContext()

  const isEditable = hasAccess(['projects-write'])

  return (
    <ProjectDetailsContext.Provider value={context}>
      <ProjectDetails
        project={project}
        isEditable={isEditable}
      />

      <ProjectUpdateModal
        modal={projectUpdateModal}
        project={project}
      />

      <ProjectPropertyUpdateModal
        modal={propertyUpdateModal}
      />

      <ProjectPropertyCreateModal
        modal={propertyCreateModal}
      />

      <ConnectFundProjectPropertyModal
        modal={propertyConnectModal}
      />
    </ProjectDetailsContext.Provider>
  )
}

ProjectDetailsPageContext.propTypes = {
  context: ProjectDetailsContextShape.isRequired,
  projectUpdateModal: PropTypes.shape().isRequired,
  propertyUpdateModal: PropTypes.shape().isRequired,
  propertyCreateModal: PropTypes.shape().isRequired,
  propertyConnectModal: PropTypes.shape().isRequired,
}

export default ProjectDetailsPageContext
