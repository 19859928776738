import React, { useState } from "react"
import PropTypes from "prop-types"

import { EntityForm, useForm } from "@components/Form"

import Modal from "./Modal"


const CreateModal = ({
  form: customForm = undefined,
  onSuccess: onSuccessCustom = undefined,
  table = undefined,
  width = undefined,
  footer = undefined,
  onExit = undefined,
  modal,
  title,
  ...otherEntityFormProps
}) => {
  const form = useForm(customForm)

  const [ isOpen, setIsOpen ] = useState(false)

  const onClose = () => {
    if (onExit) {
      onExit()
    }

    setIsOpen(false)
    form.resetFields()
  }

  const onSuccess = async (createdItem) => {
    if (onSuccessCustom) {
      await onSuccessCustom(createdItem)
    }

    if (table) {
      table.current.addItem(createdItem)
    }

    form.resetFields()
    return onClose()
  }

  modal.current.open = () =>
    setIsOpen(true)

  const modalProps = {
    forceRender: true,
    destroyOnClose: true,
    width,
    title,
    isOpen,
    onClose,
  }

  return (
    <Modal {...modalProps}>
      <EntityForm
        form={form}
        onSuccess={onSuccess}
        {...otherEntityFormProps}
      />

      {footer && footer()}
    </Modal>
  )
}

CreateModal.propTypes = {
  form: PropTypes.shape(),
  modal: PropTypes.shape().isRequired,
  table: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
  footer: PropTypes.func,
  onExit: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default CreateModal
