import operations from "../operations.json"
import httpMethods from "../httpMethods"
import servicesMap from "../services"
import extendSchema from "./extendSchema"


const getOperation = (operationId) => {
  const isSupported = operations.includes(operationId)

  if (!isSupported) {
    const message = `Operation "${operationId}" is missing. ` +
      `Add "${operationId}" to "src/api/operations.json" and run "npm run update".`

    throw new Error(message)
  }

  const isCreate =
    operationId.includes("/Send") ||
    operationId.includes("/Create") ||
    operationId.includes("/Import") ||
    operationId.includes("/Connect") ||
    operationId.includes("/ChangeMyIdentityEmail") ||
    operationId.includes("/ConfirmMyIdentityEmail")

  const isUpdate = !isCreate

  const method = httpMethods[operationId]

  const operationKey = operationId.replace('/', '_').toUpperCase()
  const formInputsKey = `${operationKey}_FORM_INPUTS`
  const tableColumnsKey = `${operationKey}_ITEM_SCHEMA`

  const formInputs = servicesMap[formInputsKey]

  const getSchema = (inputs = []) =>
    extendSchema(formInputs, inputs)

  const getIndexItemSchema = () => {
    const tableColumns = servicesMap[tableColumnsKey]

    if (!tableColumns) {
      const message = `Operation "${operationId}" has no index item schema.`

      throw new Error(message)
    }

    return tableColumns
  }

  const operation = {
    id: operationId,
    method,
    isCreate,
    isUpdate,
    getIndexItemSchema
  }

  if (formInputs) {
    operation.schema = formInputs
    operation.getSchema = getSchema
  }

  return operation
}

export default getOperation
