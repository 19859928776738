import { PROJECT_STATUSES } from "@components/Domain"


const getProjectPagePath = (status) => {
  const path = window.location.pathname

  const isDraft = status === PROJECT_STATUSES.DRAFT
  const isProjectPath = path.startsWith('/backstage/project')
  const isArchivedPath = path.endsWith('/archived')
  const isProjectInvestmentPath = isProjectPath && path.includes("/investments")

  const isDetailsPath = !isProjectPath || isArchivedPath || isDraft

  if (isDetailsPath) {
    return 'details'
  }

  if (isProjectInvestmentPath) {
    return "investments"
  }

  const [ projectPagePath ] = path.split('/').reverse()

  return projectPagePath
}

export default getProjectPagePath
