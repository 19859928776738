import React from "react"
import PropTypes from "prop-types"

import { useForm } from "@components/Form"
import { UpdateModal } from "@components/Page"
import { MODAL_WIDTH2 } from "@components"
import { resetAccountIdentityOperation as updateOperation } from "@api/services/investments"

import getInputs from "./helpers/getInputs"
import { AccountShape } from "../../shapes"

const LABEL_TITLE = "Reset User Password"
const LABEL_SUBMIT = "Reset Password"
const LABEL_ENTITY = "User"
const LABEL_SUCCESS = "User password has been reset"


const ResetIdentityModal = ({ modal, account, readAccount }) => {
  const form = useForm()

  const inputs = getInputs(account)
  const schema = updateOperation.getSchema(inputs)

  const onSubmit = parameters => {
    parameters.id = account.id
    return parameters
  }

  const onSuccess = () =>
    readAccount(account.id)

  const updateModalProps = {
    width: MODAL_WIDTH2,
    title: LABEL_TITLE,
    entity: LABEL_ENTITY,
    operation: updateOperation,
    submitLabel: LABEL_SUBMIT,
    successMessage: LABEL_SUCCESS,
    form,
    modal,
    schema,
    onSubmit,
    onSuccess,
  }

  return (
    <UpdateModal {...updateModalProps} />
  )
}

ResetIdentityModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  account: AccountShape.isRequired,
  readAccount: PropTypes.func.isRequired,
}

export default ResetIdentityModal
