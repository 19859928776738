import React from "react"
import { Outlet } from "react-router-dom"

import BackstageLayoutContextShape from "./shapes/BackstageLayoutContextShape"


const BackstageLayoutOutlet = ({ context }) => {
  return (
    <Outlet context={context} />
  )
}

BackstageLayoutOutlet.propTypes = {
  context: BackstageLayoutContextShape.isRequired,
}

export default BackstageLayoutOutlet
