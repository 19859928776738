

const openNewTab = url => {
  const aElem = document.createElement("a")
  aElem.href = url
  aElem.target = "_blank"

  document.body.append(aElem)

  aElem.click()
  aElem.remove()
}

export default openNewTab
