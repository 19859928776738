import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { Drawer } from "@components/Page"
import { EntityForm, useForm } from "@components/Form"
import { ReadAccountOutputShape } from "@api/services/investments"
import { getIsoDateFromStringDate } from "@components/Date"

import useSchema, { operation } from "./helpers/useSchema"

const LABEL_ENTITY = "Investor"
const LABEL_TITLE = `Edit ${LABEL_ENTITY}`
const LABEL_SUBMIT = "Save"


function AccountUpdateDrawer({
  onSuccess: onSuccessCustom,
  drawer,
  account,
}) {
  const form = useForm()

  const { updateAccount } = useOutletContext()

  const schema = useSchema(form, account)

  const onOpen = () => {
    form.resetFields()
    form.setFieldsValue(account)
  }

  const onSubmit = parameters => {
    const { mutation } = parameters
    const { investor: { accreditationExpiresAt }} = mutation

    if (accreditationExpiresAt) {
      const isoDate = getIsoDateFromStringDate(accreditationExpiresAt)
      parameters.mutation.investor.accreditationExpiresAt = `${isoDate}T00:00:00Z`
    }

    return parameters
  }

  const onSuccess = async (updatedAccount) => {
    updateAccount(updatedAccount)
    await onSuccessCustom(updatedAccount)

    drawer.current.close()
  }

  const entityFormProps = {
    item: account,
    entity: LABEL_ENTITY,
    submitLabel: LABEL_SUBMIT,
    form,
    schema,
    onSubmit,
    onSuccess,
    operation,
  }

  return (
    <Drawer
      title={LABEL_TITLE}
      drawer={drawer}
      onOpen={onOpen}
      forceRender={true}
    >
      <EntityForm {...entityFormProps} />
    </Drawer>
  )
}

AccountUpdateDrawer.propTypes = {
  drawer: PropTypes.shape().isRequired,
  account: ReadAccountOutputShape.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default AccountUpdateDrawer
