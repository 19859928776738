import React, { useRef } from "react"
import PropTypes from "prop-types"
import { EditOutlined } from "@ant-design/icons"
import { Space, Card, Typography, Descriptions } from "antd"

import { useForm } from "@components/Form"
import { isMobile } from "@components"
import { UpdateModal } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { InvestmentErrors } from "@components/Investment"

import getItems from "./helpers/getItems"
import useUpdateSchema from "./helpers/useUpdateSchema"

import "./AccountCard.css"

const LABEL_EDIT = "Edit"
const LABEL_SAVE = "Save"
const LABEL_TITLE = "My Information"
const LABEL_ENTITY = "Account"
const LABEL_UPDATE_TITLE = `Edit ${LABEL_ENTITY}`


const AccountCard = ({
  cardProps: otherCardProps = {},
  title = LABEL_TITLE,
  isReadOnly = false,
  onAfterUpdate = () => {},
  investorErrors = [],
  isEditDisabled = false,
  shouldShow2FASwitch = false
}) => {
  const updateForm = useForm()
  const updateModal = useRef({})

  const { identity, updateIdentity, authorizeOrganizationIdentity } = useAppContext()

  const [ updateSchema, operation ] = useUpdateSchema(updateForm)

  const { isImmutable } = identity

  const items = getItems(identity, shouldShow2FASwitch, authorizeOrganizationIdentity)

  const openUpdateModal = () =>
    updateModal.current.openItem(isImmutable ? identity.investor : identity)

  const onUpdateSuccess = ({ investor, is2FaEnabled }) => {
    updateIdentity({ investor, is2FaEnabled })
    return onAfterUpdate()
  }

  const extra = isReadOnly
    ? false
    : (
        <Typography.Link
          strong
          onClick={openUpdateModal}
          disabled={isEditDisabled}
        >
          <Space>
            <EditOutlined />
            {LABEL_EDIT}
          </Space>
        </Typography.Link>
      )

  const updateModalProps = {
    form: updateForm,
    modal: updateModal,
    title: LABEL_UPDATE_TITLE,
    entity: LABEL_ENTITY,
    schema: updateSchema,
    onSuccess: onUpdateSuccess,
    submitLabel: LABEL_SAVE,
    operation,
  }

  const cardProps = {
    type: "inner",
    size: isMobile ? "small": "medium",
    title,
    extra,
    ...otherCardProps
  }

  return (
    <Card {...cardProps}>
      <Space
        size={0}
        direction="vertical"
      >
        <Descriptions
          size="small"
          column={2}
          layout="vertical"
          className="summary"
        >
          {items}
        </Descriptions>

        <InvestmentErrors
          errors={investorErrors}
          isFunded={false}
        />
      </Space>

      <UpdateModal {...updateModalProps} />
    </Card>
  )
}

AccountCard.propTypes = {
  title: PropTypes.string,
  cardProps: PropTypes.shape(),
  isReadOnly: PropTypes.bool,
  onAfterUpdate: PropTypes.func,
  isEditDisabled: PropTypes.bool,
  investorErrors: PropTypes.arrayOf(PropTypes.string),
  shouldShow2FASwitch: PropTypes.bool,
}

export default AccountCard
