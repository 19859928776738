import { App } from "antd"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { confirmAction } from "@components/Page"
import { FAILED, ERROR } from "@api/services/transactions/shapes/TransactionStatusEnum"

const ACTION = {
  title: "Bank Account Change Confirmation",
  action: "Continue",
  content: `Changing your bank account will immediately trigger a retry of
pending transactions to the new bank account.`,
}

const RETRY_STATUSES = [ FAILED, ERROR ]


const ConfirmRetryFailedTransactionsModal = ({ modal }) => {
  const { modal: antModal } = App.useApp()

  const { transactions } = useOutletContext()

  modal.current.open = (previousSource, investmentIds, callback) => {
    if (!previousSource) {
      return callback()
    }

    const { isInvalid: isPreviousSourceInvalid } = previousSource

    if (!isPreviousSourceInvalid) {
      return callback()
    }

    const retryTransactions = transactions
      .filter(({ investmentId }) => investmentIds.includes(investmentId))
      .filter(({ status }) => RETRY_STATUSES.includes(status))

    const hasRetryTransactions = retryTransactions.length > 0

    if (!hasRetryTransactions) {
      return callback()
    }

    return confirmAction(
      antModal,
      ACTION.action,
      ACTION.title,
      ACTION.content,
      callback
    )
  }

  return null
}

ConfirmRetryFailedTransactionsModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default ConfirmRetryFailedTransactionsModal
