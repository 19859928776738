import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import Collection from "@components/Collection"
import { useAppContext } from "@components/AppContext"

import getSummary from "./helpers/getSummary"
import getTableColumns from "./helpers/getTableColumns"
import getUpdatePartner from "./helpers/getUpdatePartner"
import getSelectionChange from "./helpers/getSelectionChange"

import "./ProjectPartners.css"


const ProjectPartners = ({ items, setItems }) => {
  const { request } = useAppContext()
  const { readProject, project } = useOutletContext()

  const { id: projectId } = project

  const updatePartners = (newItemsMap, shouldReadProject = false) => {
    const updatedItems = items
      .map(item => ({
          ...item,
          ...(
            newItemsMap[item.id]
              ? newItemsMap[item.id]
              : {}
          )
        })
      )

    setItems(updatedItems)

    if (!shouldReadProject) {
      return
    }

    return readProject(projectId)
  }

  const summary = getSummary()
  const columns = getTableColumns()
  const onChange = getSelectionChange(request, updatePartners, items)
  const updatePartner = getUpdatePartner(request, updatePartners)
  const selectedRowItems = items.filter(item => item.connected).map(({ id }) => id)

  return (
    <div className="project-partners-collection">
      <Collection
        enableSelectItem
        items={items}
        onSave={updatePartner}
        summary={summary}
        onChange={onChange}
        columnsConfig={columns}
        selectedRowItems={selectedRowItems}
      />
    </div>
  )
}

ProjectPartners.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setItems: PropTypes.func.isRequired,
}

export default ProjectPartners
