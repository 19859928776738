import { PropertyStatusEnumKeys } from "./shapes/PropertyStatusEnum"

const IndexPropertiesItemSchema = {
  status: { enum: PropertyStatusEnumKeys },
  investmentDate: { format: 'date' },
  equityDeployedAmount: { type: 'integer' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexPropertiesItemSchema
