import React, { useState } from "react"
import PropTypes from "prop-types"
import { Row, Col, Menu, Button, Dropdown } from "antd"
import { MenuOutlined } from "@ant-design/icons"

import { useAppContext } from "@components/AppContext"
import { W0, W1, WIDTH_MAX, isMobile } from "@components"

import HomeLink from "./HomeLink"
import useMenuItems from "./helpers/useMenuItems"

import "./Header.css"


const Header = ({
  children = undefined
}) => {
  const [ selectedKeys, setSelectedKeys ] = useState([])

  const { identity } = useAppContext()

  const { organization } = identity
  const { logoUrl, name: organizationName } = organization

  const items = useMenuItems(setSelectedKeys)

  const containerStyle = {
    width: "100%",
    margin: "0 auto",
    maxWidth: WIDTH_MAX,
  }

  const headerStyle = {
    margin: isMobile
      ? "0 16px"
      : `0 ${W0}px 0 ${W1 + W0}px`
  }

  const menuStyle = {
    backgroundColor: "transparent"
  }

  const logoColProps = isMobile
    ? { flex: "auto" }
    : {}

  const logo =
    <Col {...logoColProps}>
      <HomeLink
        title={organizationName}
        logoUrl={logoUrl}
      />
    </Col>

  let menu

  if (children) {
    menu =
      <Col flex="auto" style={{ textAlign: "right" }}>
        {children}
      </Col>
  }

  if (!menu) {
    menu = isMobile
      ? (
          <Col>
            <Dropdown
              menu={{ items }}
              trigger={[ 'click' ]}
              overlayClassName="navigation-mobile"
            >
              <Button
                type="text"
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Col>
        )
      : (
          <Col flex="auto">
            <Menu
              mode="horizontal"
              items={items}
              style={menuStyle}
              selectedKeys={selectedKeys}
            />
          </Col>
        )
  }

  return (
    <div style={containerStyle}>
      <Row
        align="middle"
        style={headerStyle}
        className="navigation"
      >
        {logo}
        {menu}
      </Row>
    </div>
  )
}

Header.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
}

export default Header
