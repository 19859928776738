import React from "react"
import { useOutletContext } from "react-router-dom"

import { hasAccess } from "@components/Authorization"
import { COLOR_MAP } from "@components"
import { Value } from "@components/Text"
import {
  useBrokerColumnProps,
  useSponsorsColumnProps
} from "@modules/backstage/sponsors"
import { getAccreditation } from "@components/Export"
import { computeInvestmentAmount } from "@components/Investment"
import { PROFILE_TYPES_LABELS, PROJECT_TYPES } from "@components/Domain"

import renderTag from "./renderTag"
import renderFunded from "./renderFunded"
import getFundedColor from "./getFundedColor"
import InvestorColumn from "../InvestorColumn"
import TableRowActions from "../TableRowActions"
import renderSignStatus from "./renderSignStatus"
import computeSignStatus from "./computeSignStatus"
import getSignStatusColor from "./getSignStatusColor"
import computeInvestmentName from "./computeInvestmentName"
import { computeInvestmentFundingStatus, LABELS_MAP } from "./computeInvestmentFundingStatus"


const useColumnsConfig = (isBroker, openInvestmentDrawer) => {
  const { project, hasMultipleSponsors, computeAccountFullname } = useOutletContext()

  const {
    closeDate: projectCloseDate,
    type,
  } = project

  const isFund = type === PROJECT_TYPES.FUND

  const investorColumnsProps = {
    key: "investor",
    fixed: "left",
    title: "Investor",
    compute: (investment) => computeInvestmentName(computeAccountFullname, investment),
    render: (value, investment) => (
      <InvestorColumn
        name={value}
        investment={investment}
        openInvestmentDrawer={openInvestmentDrawer}
      />
    ),
    isSearchable: true,
  }

  const brokerColumnsProps = useBrokerColumnProps()
  const sponsorColumnsProps = useSponsorsColumnProps()

  const accreditationColumnsProps = {
    key: "_accredited",
    type: "boolean",
    title: "Accredited",
    align: "center",
    isFilterEnabled: true,
    compute: ({ isFunded, investor: { hasAccreditation } }) => {
      if (isFunded) {
        return "true"
      }

      return hasAccreditation === true ? "true" : "false"
    },
    filterText: value =>
      value === 'true' ? "Accredited" : "Non-Accredited",
    render: (_, { isFunded, investor: { hasAccreditation, accreditationExpiresAt } }) => {
      const { value: accreditationValue, color } =
        getAccreditation(hasAccreditation, accreditationExpiresAt, projectCloseDate, isFunded, !isFund)

      return renderTag(accreditationValue, color)
    },
    onCell: ({ isFunded, investor: { hasAccreditation, accreditationExpiresAt } }) => {
      const { color } =
        getAccreditation(hasAccreditation, accreditationExpiresAt, projectCloseDate, isFunded, !isFund)

      const props = { style: { background: COLOR_MAP[color] } }

      return props
    },
  }

  const signColumnsProps = {
    key: "isSigned",
    title: "Signed",
    align: "center",
    compute: investment => computeSignStatus(investment),
    render: value => renderSignStatus(value),
    onCell: investment => {
      const value = computeSignStatus(investment)

      return ({
        style: { background: COLOR_MAP[getSignStatusColor(value)] }
      })
    },
    isFilterEnabled: true,
    filterText: value => ({
      signed: "Signed",
      notSigned: "Not Signed",
      partiallySigned: "Partially Signed"
    }[value]),
  }

  const fundColumnsProps = {
    key: "isFunded",
    title: "Funded",
    align: "center",
    compute: investment => computeInvestmentFundingStatus(investment),
    render: value => renderFunded(value),
    onCell: investment => {
      const value = computeInvestmentFundingStatus(investment)

      return ({
        style: { background: COLOR_MAP[getFundedColor(value)] }
      })
    },
    filterText: value => LABELS_MAP[value],
    isFilterEnabled: true,
  }

  const bankAccountColumnsProps = {
    key: "_hasSource",
    type: "boolean",
    title: "Bank Account",
    align: "center",
    compute: ({ receiverSourceName }) => `${!!receiverSourceName}`,
    render: hasReceiverSource =>
      hasReceiverSource === 'true' ? renderTag("Yes", "green") : renderTag("No", "red"),
    onCell: ({ receiverSourceName }) => {
      const value = !!receiverSourceName ? "green" : "red"

      return ({
        style: { background: COLOR_MAP[value] }
      })
    },
    isFilterEnabled: true,
    filterText: value => ({ "true": "Connected", "false": "Not Connected" }[value]),
  }

  const profileTypeColumnsProps = {
    key: "profileType",
    isFilterEnabled: true,
    compute: ({ profileType }) => PROFILE_TYPES_LABELS[profileType],
  }

  const receivedColumnsProps = {
    key: "investments",
    title: "Received",
    align: "right",
    render: (amount, {receivedAmount}) => <Value value={receivedAmount} />,
    compute: investment => computeInvestmentAmount(investment),
    isSortingEnabled: true,
  }

  const committedColumnsProps = {
    key: "investments",
    title: "Committed",
    align: "right",
    render: amount => <Value value={amount} />,
    compute: investment => computeInvestmentAmount(investment),
    isSortingEnabled: true,
  }

  const columns = [
    { ...investorColumnsProps, width: 380, hasTruncation: true },
    { ...profileTypeColumnsProps, width: 130 },
    ...(
      isBroker || !hasMultipleSponsors
        ? []
        : [{ ...sponsorColumnsProps, width: "auto", widthAutoPadding: 30 } ]
    ),
    { ...brokerColumnsProps, width: "auto", widthAutoPadding: 40 },
    { ...accreditationColumnsProps, width: 130, },
    { ...signColumnsProps, width: 130, },
    { ...fundColumnsProps, width: 130, },
    { ...bankAccountColumnsProps, width: 130, },
    { ...receivedColumnsProps, width: 130, },
    { ...committedColumnsProps, width: 130, },
  ]

  const canUpdateInvestmentStatus = hasAccess(['investments-write'])

  if (!canUpdateInvestmentStatus) {
    return columns
  }

  return  [
    ...columns,
    {
      key: "actions",
      title: false,
      width: 48,
      fixed: "right",
      render: (_, investment) =>
        <TableRowActions investment={investment} />
    }
  ]
}

export default useColumnsConfig
