import React from "react"
import PropTypes from "prop-types"
import {Tag, Tooltip} from "antd"
import {ExclamationCircleOutlined} from "@ant-design/icons"

import {
  BENEFICIAL_OWNER_STATUS_DOCUMENT,
  BENEFICIAL_OWNER_STATUS_INCOMPLETE,
  BENEFICIAL_OWNER_STATUS_VERIFIED
} from "../../../../Constants"

const LABEL_DOCUMENT = "Document Required"
const LABEL_VERIFIED = "Verified"
const LABEL_INCOMPLETE = "Incomplete"
const LABEL_VERIFICATION_PENDING = "Pending Verification"
const LABEL_VERIFICATION_PENDING_TOOLTIP = `Dwolla is verifying the information you provided. 
This verification process may take up to 2 business days. Please check back for updates.`


const VerificationStatus = ({ status }) => {
  const isDocument = status === BENEFICIAL_OWNER_STATUS_DOCUMENT
  const isVerified = status === BENEFICIAL_OWNER_STATUS_VERIFIED
  const isIncomplete = status === BENEFICIAL_OWNER_STATUS_INCOMPLETE

  if (isVerified) {
    return (
      <Tag color="green">
        {LABEL_VERIFIED}
      </Tag>
    )
  }

  if (isIncomplete) {
    return (
      <Tag color="yellow">
        {LABEL_INCOMPLETE}
      </Tag>
    )
  }

  if (isDocument) {
    return (
      <Tag color="yellow">
        {LABEL_DOCUMENT}
      </Tag>
    )
  }

  return (
    <Tooltip
      title={LABEL_VERIFICATION_PENDING_TOOLTIP}
      placement="top"
    >
      <Tag
        color="blue"
        style={{ cursor: "pointer" }}
        icon={<ExclamationCircleOutlined />}
      >
        {LABEL_VERIFICATION_PENDING}
      </Tag>
    </Tooltip>
  )
}

VerificationStatus.propTypes = {
  status: PropTypes.string.isRequired,
}

export default VerificationStatus
