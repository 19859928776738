import { TRANSACTION_TYPES } from "@components/Domain"


const getAmountType = (
  profitOnSale,
  returnOfCapital,
  returnOnInvestment,
  sponsorPromote
) => {
  if (returnOfCapital.length !== 0) {
    return TRANSACTION_TYPES.CAPITAL_RETURN
  }

  if (returnOnInvestment.length !== 0) {
    return TRANSACTION_TYPES.INVESTMENT_RETURN
  }

  if (sponsorPromote.length !== 0) {
    return TRANSACTION_TYPES.SPONSOR_PROMOTE
  }

  return TRANSACTION_TYPES.SALE_PROFIT
}

export default getAmountType
