import keyBy from "lodash.keyby"

import { TYPE_RADIO, TYPE_HIDDEN } from "@components/Form"
import {
  INVESTMENT_PROFILE_FIELD_MAP,
  INVESTMENT_PROFILE_TYPE_JOINT,
  INVESTMENT_PROFILE_TYPE_SDIRA,
  INVESTMENT_PROFILE_TYPE_ENTITY,
} from "@components/Domain"

const LABEL_PROFILE = "Profile"
const LABEL_PLACEHOLDER = "Select existing or create a new one"

const NA_PROFILE = "NA"

const PREFIX_MAP = {
  [INVESTMENT_PROFILE_TYPE_SDIRA]: "SDIRA",
  [INVESTMENT_PROFILE_TYPE_JOINT]: "Joint",
  [INVESTMENT_PROFILE_TYPE_ENTITY]: "Entity"
}


const computeProfileInput = (profileType, updateSchema, profiles, profileExtra = () => ({})) => {
  const hasProfiles = profiles.length > 0

  if (!hasProfiles) {
    return [{
      name: "profileName",
      type: TYPE_HIDDEN,
      initialValue: "new_profile"
    }]
  }

  const profileOptions = profiles
    .filter(({ disabled }) => !disabled)
    .filter(({ name }) => name !== NA_PROFILE && !name.endsWith(`& ${NA_PROFILE}`))
    .map(({ name: label }) => ({ value: label, label }))

  const hasProfileOptions = profileOptions.length > 0

  if (!hasProfileOptions) {
    return [{
      name: "profileName",
      type: TYPE_HIDDEN,
      initialValue: "new_profile"
    }]
  }


  const profileField = INVESTMENT_PROFILE_FIELD_MAP[profileType]
  const prefix = PREFIX_MAP[profileType]

  const options = [
    ...profileOptions,
    {
      value: "new_profile",
      label: "New Profile"
    }
  ]

  const profileMapByName = keyBy(profiles, "name")

  return [{
    name: "profileName",
    type: TYPE_RADIO,
    label: `${prefix} ${LABEL_PROFILE}`,
    required: true,
    placeholder: LABEL_PLACEHOLDER,
    inputProps: {
      onChange: ({target}, form) => {
        const { value: name } = target

        const isProfileChosen = name !== "new_profile"

        if (isProfileChosen) {
          const selectedProfile = profileMapByName[name][profileField]
          const extraProfileAttributes = profileExtra(selectedProfile)
          const profile = {
            ...selectedProfile,
            ...extraProfileAttributes,
            profileName: name
          }

          const profileValue = { [profileField]: profile }

          form.setFieldsValue(profileValue)
          updateSchema()
          return
        }

        form.resetFields([ profileField ])
        form.setFieldsValue({ [profileField]: { "profileName": "new_profile" }})

        updateSchema()
      }
    },
    options,
  }]
}

export default computeProfileInput
