import { TYPE_STRING, TYPE_HIDDEN, TYPE_PHONE } from "@components/Form"

const LABEL_CONTACT_EMAIL = "Email Address"
const LABEL_CONTACT_NUMBER = "Contact Phone Number"
const LABEL_SUBJECT_PLACEHOLDER = "Subject of your request"


const getGuestInputs = (organizationId, projectId) => {
  return [
    {
      name: "contactEmail",
      label: LABEL_CONTACT_EMAIL,
      placeholder: "user@host.com",
    },
    {
      name: "contactNumber",
      type: TYPE_PHONE,
      label: LABEL_CONTACT_NUMBER,
      placeholder: "",
    },
    {
      name: "subject",
      type: TYPE_STRING,
      placeholder: LABEL_SUBJECT_PLACEHOLDER
    },
    {
      name: "message",
      placeholder: "",
      inputProps: {
        rows: 5
      }
    },
    {
      name: "organizationId",
      type: TYPE_HIDDEN,
      initialValue: organizationId,
    },
    {
      name: "projectId",
      type: TYPE_HIDDEN,
      initialValue: projectId,
    }
  ]
}

export default getGuestInputs
