import React, { useEffect } from "react"
import { Tabs } from "antd"
import { useOutletContext, useParams, useNavigate } from "react-router-dom"

import { W1, isMobile } from "@components"

import getItems, { CREATE_KEY } from "./helpers/getItems"

import "./InvestmentProfilePage.css"


const InvestmentProfilesPage = () => {
  const navigate = useNavigate()

  const {
    projectId,
    investmentId,
  } = useParams()

  const {
    project,
    currentForm,
    getInvestment,
    deleteInvestment,
    getProjectInvestments,
  } = useOutletContext()

  const projectInvestments = getProjectInvestments(projectId)

  const isCreateAction = window.location.pathname.includes(CREATE_KEY)

  const activeKey = investmentId || (isCreateAction ? CREATE_KEY : undefined)

  useEffect(() => {
    if (isCreateAction) {
      return
    }

    const hasInvestments = projectInvestments.length > 0

    if (!hasInvestments) {
      navigate(`/investments/${projectId}`)
      return
    }

    if (investmentId) {
      const investment = getInvestment(investmentId)

      if (!investment) {
        navigate(`/investments/${projectId}/update`)
      }
    }
  }, [
    navigate,
    projectId,
    investmentId,
    getInvestment,
    isCreateAction,
    projectInvestments
  ])

  const tabBarStyle = {
    marginBottom: isMobile ? 0 : W1,
  }

  const items = getItems({
    investments: projectInvestments,
    project,
    navigate,
    activeKey,
    currentForm,
    isCreateAction,
    deleteInvestment,
  })

  const onChange = key => {
    currentForm.current.submit()
    navigate(`/investments/${projectId}/update/${key}`)
  }

  return (
    <Tabs
      size={isMobile ? "small" : "medium"}
      items={items}
      onChange={onChange}
      activeKey={activeKey}
      className={isMobile ? "tabs-mobile" : ""}
      tabBarStyle={tabBarStyle}
      destroyInactiveTabPane
    />
  )
}

export default InvestmentProfilesPage
