import React from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons"

import useInvestmentTransactionActions from "./helpers/useInvestmentTransactionActions"


const RowActions = ({
  record,
  collection,
  isDeletable,
  addTransactionAction,
  deleteTransactionAction,
  isInvestmentFundedTemplateEnabled
}) => {
  const { editableRow } = record

  const {
    addTransaction,
    deleteTransaction
  } = useInvestmentTransactionActions({
    record,
    addTransactionAction,
    deleteTransactionAction,
    isInvestmentFundedTemplateEnabled
  })

  const onDelete = () =>
    deleteTransaction()

  const onSave = async () => {
    const isValid = await collection.current.hasFormError()

    if (!isValid) {
      return
    }

    return addTransaction()
  }

  if (editableRow) {
    return (
      <Button
        type="link"
        icon={<SaveOutlined />}
        onClick={onSave}
      />
    )
  }

  if (isDeletable) {
    return (
      <Button
        type="link"
        icon={<DeleteOutlined />}
        danger={true}
        onClick={onDelete}
      />
    )
  }
}

RowActions.propTypes = {
  record: PropTypes.shape().isRequired,
  collection: PropTypes.shape().isRequired,
  isDeletable: PropTypes.bool.isRequired,
  addTransactionAction: PropTypes.func.isRequired,
  deleteTransactionAction: PropTypes.func.isRequired,
  isInvestmentFundedTemplateEnabled: PropTypes.func.isRequired,
}

export default RowActions
