import operationRequest from "@components/Authorization/request/operationRequest"
import { readTransactionReportOperation } from "@api/services/transactions"


const getTransactionReport = async (authorization) => {
  const headers = { authorization }

  const { data: transactionReport } = await operationRequest({
    operation: readTransactionReportOperation,
    headers,
  })

  return transactionReport
}

export default getTransactionReport
