import React from "react"
import { Statistic, Table, Typography } from "antd"

import { getFormattedAmountValue } from "@components/Amount"

const { Summary } = Table
const { Row, Cell } = Summary
const { Text } = Typography

const FUND_TYPE = "fund"


const getSummary = () => {
  const renderSummary = items => {
    let limitTotal = 0
    let goalTotalAmount = 0

    items
      .filter(item => item.connected)
      .forEach(({ goal, limit, type }) => {
        const isNotFund = type !== FUND_TYPE

        if (isNotFund) {
          limitTotal += +limit
        }

        goalTotalAmount += +goal
      })

    const total = getFormattedAmountValue(goalTotalAmount)

    return (
      <Row>
        <Cell
          align="right"
          index={1}
          colSpan={2}
        >
          <Text strong>Total:</Text>
        </Cell>

        <Cell index={1}>
          <Statistic
            value={total}
            prefix="$"
            valueStyle={{ fontSize: 14 }}
          />
        </Cell>

        <Cell index={2}>
          <Text>
            {limitTotal}
          </Text>
        </Cell>
      </Row>
    )
  }

  return renderSummary
}

export default getSummary
