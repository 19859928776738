import PropTypes from "prop-types"


const properties = {
  amount: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string,
  details: PropTypes.string,
  id: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
  referenceId: PropTypes.string,
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

const TransactionShape = PropTypes.exact(properties)

export default TransactionShape
export { properties }
