import React from "react"
import { Row, Col, Steps as AntSteps } from "antd"
import { useLocation } from "react-router-dom"

import { W2, isMobile } from "@components"
import { useAppContext } from "@components/AppContext"

import getStepsMetadata from "./helpers/getStepsMetadata"


const Steps = () => {
  const { pathname } = useLocation()
  const { identity } = useAppContext()

  const { stepsItems, currentStep } = getStepsMetadata(identity, pathname)

  const stepsSize = "default"

  const stepsProps = {
    size: stepsSize,
    items: stepsItems,
    current: currentStep,
  }

  if (isMobile) {
    return null
  }

  return (
    <Row justify="center" style={{ marginBottom: W2 }}>
      <Col style={{ maxWidth: 640, width: "100%" }}>
        <AntSteps {...stepsProps} />
      </Col>
    </Row>
  )
}

export default Steps
