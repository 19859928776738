import { useMemo, useEffect } from "react"
import keyBy from "lodash.keyby"

import { useStore } from "@components/Store"
import { runRequest, useAppContext } from "@components/AppContext"
import { listRecipientOptOutsOperation as indexOperation } from "@api/services/campaigns"


const useRecipientOptOutsStore = (subscriptionTypeId) => {
  const { request } = useAppContext()

  const shouldAutoLoad = false

  const operationParameters = useMemo(() => ({
    subscriptionTypeId
  }), [ subscriptionTypeId ])

  const {
    items: optOuts,
    indexItems: indexOptOuts,
  } = useStore(indexOperation, request, shouldAutoLoad, operationParameters)

  const optOutsMap = useMemo(() =>
    keyBy(optOuts, "email")
  , [ optOuts ])

  useEffect(() => {
    if (!subscriptionTypeId) {
      return
    }

    return runRequest(() => indexOptOuts())
  }, [
    indexOptOuts,
    subscriptionTypeId
  ])

  const isInvestorOptedOut = ({ email, alternativeEmail, campaignEmails = [] }) =>
    [ email, alternativeEmail, ...campaignEmails ]
      .filter(value => !!value)
      .filter(value => !optOutsMap[value])
      .length === 0

  return {
    optOutsMap,
    isInvestorOptedOut,
  }
}

export default useRecipientOptOutsStore
export { indexOperation }
