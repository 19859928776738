import capitalize from "lodash.capitalize"

export const CORPORATION = "CORPORATION"
export const LLC = "LLC"
export const PARTNERSHIP = "PARTNERSHIP"

const BusinessTypeEnum = {
  [CORPORATION]: CORPORATION,
  [LLC]: LLC,
  [PARTNERSHIP]: PARTNERSHIP
}

const BusinessTypeEnumKeys = Object.keys(BusinessTypeEnum)

const BusinessTypeEnumOptions = BusinessTypeEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  BusinessTypeEnumKeys,
  BusinessTypeEnumOptions
}

export default BusinessTypeEnum
