import PropTypes from "prop-types"

import SourceShape from "@api/services/investments/shapes/SourceShape"
import { properties as backstageCustomerProperties } from "@api/services/backstage/shapes/CustomerShape"
import { properties as investmentsCustomerProperties } from "@api/services/investments/shapes/CustomerShape"
import {
  ConnectSourceOutputShape,
  UpdateMyCustomerVerificationStatusOutputShape,
  CreateImpersonatedUnverifiedPersonalCustomerOutputShape
} from "@api/services/transactions"


const BackstageCustomerShape = PropTypes.exact({
  ...backstageCustomerProperties,
  sources: PropTypes.arrayOf(
    PropTypes.oneOfType([
      SourceShape,
      ConnectSourceOutputShape,
    ])
  ),
})

const InvestmentsCustomerShape = PropTypes.exact({
  ...investmentsCustomerProperties,
  sources: PropTypes.arrayOf(
    PropTypes.oneOfType([
      SourceShape,
      ConnectSourceOutputShape,
    ])
  ),
})

// eslint-disable-next-line no-warning-comments
// TODO: Revise mutations output to match mini cusomer and mini source:
const CustomerShape = PropTypes.oneOfType([
  CreateImpersonatedUnverifiedPersonalCustomerOutputShape,
  UpdateMyCustomerVerificationStatusOutputShape,
  InvestmentsCustomerShape,
  BackstageCustomerShape,
])

export default CustomerShape
