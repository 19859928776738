import keyBy from "lodash.keyby"

import { getUsDateFromIsoDate } from "@components/Date"
import { TRANSACTION_AMOUNT_MAX } from "@components/Domain"
import { getTransactionTypeLabel } from "@components/Transaction"


const validateTransactions = async (request, projectId, transactions, investments, isRegisterOperation) => {
  const errors = []
  const hasTransactions = transactions.length > 0

  if (!hasTransactions) {
    return errors
  }

  const investmentsMap = keyBy(investments, "id")

  for (const [i, transaction] of transactions.entries()) {
    const {
      date,
      type,
      amount,
      investmentId,
      _rowIndex = i + 1
    } = transaction

    const usDate = getUsDateFromIsoDate(date)
    const typeLabel = getTransactionTypeLabel(type)

    const { profileName } = transaction
    const investment = investmentsMap[investmentId]
    const isUnsigned =  !investment.isSigned

    const hasNoReceiverSourceId = isRegisterOperation
      ? false
      : !investment.receiverSourceId

    const errorPrefix = `Distribution ${usDate} for ${profileName}:`

    const isMaxAmount = amount > TRANSACTION_AMOUNT_MAX * 100

    if (hasNoReceiverSourceId) {
      errors.push({
        index: _rowIndex,
        reason: `${errorPrefix} receiving account is not defined`
      })

    } else if (isMaxAmount) {
      errors.push({
        index: _rowIndex,
        reason: `${errorPrefix} "${typeLabel}" maximum amount is 500k`
      })

    } else if (isUnsigned) {
       errors.push({
        index: _rowIndex,
        reason: `${errorPrefix} investment is not signed`
      })
    }
  }

  return errors
}

export default validateTransactions
