import React, { useMemo } from "react"
import { Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import { Value } from "@components/Text"
import { useAppContext } from "@components/AppContext"
import { TRANSACTION_TYPES, TRANSACTION_STATUS } from "@components/Domain"
import { DistributionStatusTag, getTransactionStatusLabel } from "@components/Transaction"

import getAmountColumnProps from "./getAmountColumnProps"

const { Text, Link: TextLink } = Typography


const renderRejected = (status, value) => {
  const isDeleted = status === TRANSACTION_STATUS.REJECTED

  return (
    <Text delete={isDeleted}>
      {value}
    </Text>
  )
}


const useColumnsConfig = (openInvestmentDrawer, openTransactionDrawer) => {
  const { getOrganizationName } = useAppContext()
  const { isInvalidBankAccountTransaction } = useOutletContext()

  const organizationName = getOrganizationName()

  return useMemo(() => {
    const amountColumnProps = {
      organizationName,
      openTransactionDrawer,
      isInvalidBankAccountTransaction
    }

    return [
      {
        key: "date",
        width: "11%",
        render: (value, { status }) =>
          renderRejected(status, <Value value={value} />),
      },
      {
        key: "investmentId",
        title: "Beneficiary",
        isSearchable: true,
        compute: ({ investmentName }) => investmentName,
        render: (beneficiary, distribution) =>
          renderRejected(distribution.status,
            <TextLink strong onClick={() => openInvestmentDrawer(distribution.investmentId)}>
              {beneficiary}
            </TextLink>
          )
      },
      {
        width: "11%",
        align: "right",
        ...getAmountColumnProps({
          ...amountColumnProps,
          type: TRANSACTION_TYPES.INVESTMENT_RETURN,
        }),
      },
      {
        width: "11%",
        align: "right",
        ...getAmountColumnProps({
          ...amountColumnProps,
          type: TRANSACTION_TYPES.SALE_PROFIT,
        }),
      },
      {
        width: "11%",
        align: "right",
        ...getAmountColumnProps({
          ...amountColumnProps,
          type: TRANSACTION_TYPES.CAPITAL_RETURN,
        }),
      },
      {
        width: "11%",
        align: "right",
        ...getAmountColumnProps({
          ...amountColumnProps,
          type: TRANSACTION_TYPES.SPONSOR_PROMOTE,
        }),
      },
      {
        key: "total",
        title: "Sum",
        width: "11%",
        align: "right",
        render: (value, { status }) =>
          renderRejected(status, <Value value={value} />),
      },
      {
        key: "status",
        title: "Status",
        width: "11%",
        align: "right",
        isFilterEnabled: true,
        compute: ({ status }) => getTransactionStatusLabel(status),
        render: (label, { status }) => (
          <DistributionStatusTag status={status} />
        ),
        filterValues: [
          TRANSACTION_STATUS.ERROR,
          TRANSACTION_STATUS.FAILED,
          TRANSACTION_STATUS.REJECTED,
          TRANSACTION_STATUS.PENDING_APPROVAL,
          TRANSACTION_STATUS.PENDING,
          TRANSACTION_STATUS.PROCESSED,
          TRANSACTION_STATUS.CANCELLED,
        ],
      }
    ]
  }, [
    organizationName,
    openInvestmentDrawer,
    openTransactionDrawer,
    isInvalidBankAccountTransaction,
  ])
}

export default useColumnsConfig
