import React, { useCallback, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Spin } from "antd"

import { wait } from "@api"
import { Drawer } from "@components/Page"
import { Markdown } from "@components/Text"
import { HEADER_HEIGHT, DRAWER_WIDTH3 } from "@components"

import Title from "./Title"
import SignInAsButton from "./SignInAsButton"
import useAccountStore from "./stores/useAccountStore"
import InvestorDrawerTabs from "./InvestorDrawerTabs"
import AccountUpdateDrawer from "../AccountUpdateDrawer"

const LABEL_ERROR_NOT_FOUND = `Investor **{ID}** is not found. URL is not valid
or account was deleted.`


const InvestorDrawer = ({
  onClose: onCloseCustom = undefined,
  onAfterUpdate = undefined,
  drawer,
}) => {
  const updateDrawer = useRef({})

  const [ accountId, setAccountId ] = useState()
  const [ error, setError ] = useState()

  const onAccountNotFound = useCallback(id =>
    setError(LABEL_ERROR_NOT_FOUND.replace('{ID}', id))
  , [])

  const {
    account,
    isLoading,
    investments,
    readAccount,
    transactions,
    updateAccount,
    setAccountTags,
    indexInvestments,
    indexTransactions,
  } = useAccountStore(accountId, onAccountNotFound)

  const onOpen = id =>
    setAccountId(id)

  const onClose = () => {
    setError()
    wait(300).then(() => setAccountId())

    if (onCloseCustom) {
      onCloseCustom()
    }
  }

  const openAccountUpdateDrawer = () =>
    updateDrawer.current.open()

  const title = <Title
    account={account}
    readAccount={readAccount}
    updateAccount={updateAccount}
  />

  const extra = account
    ? <SignInAsButton account={account} />
    : null

  const isReady = account !== undefined && !isLoading

  const styles = isReady
    ? {
        body: { paddingTop: 0, paddingBottom: 0 },
        header: { minHeight: HEADER_HEIGHT + 1 }
      }
    : {}

  const drawerProps = {
    width: DRAWER_WIDTH3,
    destroyOnClose: true,
    extra,
    title,
    onOpen,
    drawer,
    styles,
    onClose,
  }

  const investorDrawerTabsProps = {
    account,
    investments,
    transactions,
    updateAccount,
    setAccountTags,
    indexInvestments,
    indexTransactions,
    openAccountUpdateDrawer,
  }

  const onSuccess = updatedAccount => {
    updateAccount(updatedAccount)

    if (onAfterUpdate) {
      return onAfterUpdate(updatedAccount)
    }
  }

  const accountUpdateDrawerProps = {
    drawer: updateDrawer,
    account,
    onSuccess,
  }

  const renderChildren = () => {
    if (error) {
      return (
        <center>
          <Markdown text={error} />
        </center>
      )
    }

    if (!isReady) {
      return <center><Spin /></center>
    }

    return (
      <>
        <InvestorDrawerTabs
          {...investorDrawerTabsProps}
        />

        <AccountUpdateDrawer
          {...accountUpdateDrawerProps}
        />
      </>
    )
  }

  return (
    <Drawer {...drawerProps}>
      {renderChildren()}
    </Drawer>
  )
}

InvestorDrawer.propTypes = {
  drawer: PropTypes.shape().isRequired,
  onClose: PropTypes.func,
  onAfterUpdate: PropTypes.func,
}

export default InvestorDrawer
