import React, { useState } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { App, Typography, Spin } from "antd"
import { ExclamationCircleFilled } from "@ant-design/icons"

import { useAppContext } from "@components/AppContext"

import TermsCheckbox from "../TermsCheckbox"
import getCustomerMutation from "./helpers/getCustomerMutation"

const LABEL_CONTINUE = "Continue"
const LABEL_CREATE_CUSTOMER = "Connect"
const LABEL_CONFIRM_TITLE = "Connect Bank Account"
const LABEL_CONFIRM_TEXT = `You're about to link a bank account to a shadow
investor account. This should only be done if the investor doesn't intend to
use the investor portal or manage their bank accounts on their own.`


const CustomerCreateButton = ({
  businessName = undefined,
  isReceiveOnly = false,
  impersonatedInvestorAccountId = undefined,
  onSuccess,
}) => {
  const { modal } = App.useApp()

  let { identity } = useAppContext()

  const {
    getOrganizationId,
    addIdentityCustomer,
    authenticatedRequest
  } = useAppContext()

  const { getAccount } = useOutletContext()

  const [ isLoading, setIsLoading ] = useState(false)

  if (impersonatedInvestorAccountId) {
    identity = getAccount(impersonatedInvestorAccountId)
  }

  const [ operation, mutation ] = getCustomerMutation(
    identity,
    businessName,
    isReceiveOnly,
    impersonatedInvestorAccountId,
    getOrganizationId
  )

  const createCustomer = () => {
    setIsLoading(true)

    return authenticatedRequest(operation, { mutation })
      .then(({ data: newCustomer }) => {
        addIdentityCustomer(newCustomer)
        return newCustomer
      })
      .then((newCustomer) => onSuccess(newCustomer))
      .then(() => setIsLoading(false))
  }

  const onClick = () => {
    if (!impersonatedInvestorAccountId) {
      return createCustomer()
    }

    return modal.confirm({
      icon: <ExclamationCircleFilled />,
      title: LABEL_CONFIRM_TITLE,
      okText: LABEL_CONTINUE,
      content: LABEL_CONFIRM_TEXT,
      onOk: () => createCustomer(),
      onCancel: () => {},
    })
  }

  const renderButton = isEnabled =>
    <Typography.Link
      onClick={onClick}
      disabled={!isEnabled}
    >
      {LABEL_CREATE_CUSTOMER}
    </Typography.Link>

  return (
    <Spin spinning={isLoading}>
      <TermsCheckbox
        renderButton={renderButton}
      />
    </Spin>
  )
}

CustomerCreateButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  businessName: PropTypes.string,
  isReceiveOnly: PropTypes.bool,
  impersonatedInvestorAccountId: PropTypes.string,
}

export default CustomerCreateButton
