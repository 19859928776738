
const AFTER_DOWNLOAD_CLEANUP_DELAY = 4e4 // 40s


const downloadContent = (fileName, content, type, shouldTimestamp = false) => {
  const blob = new Blob([ content ], { type })

  const url = window.URL.createObjectURL(blob)

  if (shouldTimestamp) {
    const timestamp = new Date().getTime()
    const [ extension ] = fileName.split('.').reverse()
    fileName = fileName.replace(`.${extension}`, `_${timestamp}.${extension}`)
  }

  const element = document.createElement('a')
  element.setAttribute('hidden', '')
  element.setAttribute('href', url)
  element.setAttribute('download', fileName)

  document.body.appendChild(element)
  element.click()
  element.remove()

  setTimeout(() => {
    window.URL.revokeObjectURL(url)
  }, AFTER_DOWNLOAD_CLEANUP_DELAY)
}

export default downloadContent
