import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Modal, Row, Col, Image } from "antd"
import { PlusOutlined } from "@ant-design/icons"

import { W1, W3, isMobile } from "@components"
import { getResizedImageUrl } from "@components/Storage"

import "./Photos.css"

const LABEL_VIEW = "View"
const LABEL_PHOTOS = "Photos"

const MAX_WIDTH = 1240


const Photos = ({ photos }) => {
  const [ isOpen, setIsOpen ] = useState(false)

  const count = photos.length
  const hasPhotos = count > 0

  if (!hasPhotos) {
    return null
  }

  const containerStyle = {
    position: "absolute",
    right: W1 + (isMobile ? 10 : 20),
    marginTop: -32 - (isMobile ? 10 : 20),
  }

  const buttonStyle = {
    color: "#FFFFFF",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  }

  const modalStyle = {
    top: 0,
    transform: "translateZ(0)"
  }

  const modalContentStyle = {
    width: "100%",
    margin: "0 auto",
    maxWidth: MAX_WIDTH,
    marginTop: W3,
    paddingLeft: isMobile ? 16 : 0,
    marginBottom: W3,
  }

  const maskStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.8)"
  }

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const width = "100%"

  return (
    <div style={containerStyle}>
      <Button
        type="text"
        icon={<PlusOutlined />}
        style={buttonStyle}
        onClick={open}
        className="view-more-button"
      >
        {LABEL_VIEW} {count} {LABEL_PHOTOS}
      </Button>

      <Modal
        open={isOpen}
        width={width}
        style={modalStyle}
        header={false}
        footer={false}
        onCancel={close}
        className={"photos-modal"}
        styles={{
          mask: maskStyle
        }}
      >
        <Row
          style={modalContentStyle}
          gutter={[ W3, W3 ]}
        >
          {
            photos.map(({ url, name }, key) =>
              <Col key={key} span={24}>
                <Image
                  src={getResizedImageUrl(url, MAX_WIDTH * 2)}
                  alt={name}
                  width="100%"
                  preview={false}
                />
              </Col>
            )
          }
        </Row>
      </Modal>
    </div>
  )
}

Photos.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export default Photos
