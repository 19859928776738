import React from "react"
import { Alert, Typography, Spin, Space, Button } from "antd"
import { DownloadOutlined } from "@ant-design/icons"

import { W0, W1 } from "@components"
import { ACCOUNT_TAG } from "@api/services/backstage/shapes/OptionGroupEnum"
import { TYPE_UPLOAD, TYPE_HIDDEN, TYPE_TAGS } from "@components/Form"

import downloadFile from "./downloadFile"
import downloadContent from "./downloadContent"

const LABEL_TAGS = "Tags"
const LABEL_FILE = "Import File"
const LABEL_ERRORS = "Validation has failed. Please review "
const LABEL_REPORT = "validation report"
const LABEL_VALIDATION = "Validating import file..."
const LABEL_DOWNLOAD_TEMPLATE = "Download Import Template"


const getInputs = (templateUrl, isValidating, errorsJson, projectId, extraInputs = []) => {
  const downloadTemplate = () =>
    downloadFile(templateUrl)

  let inputs = [
    {
      name: "_template",
      component: () => (
        <div style={{ marginBottom: W1 }}>
          <Button
            icon={<DownloadOutlined />}
            type="dashed"
            onClick={downloadTemplate}
          >
            {LABEL_DOWNLOAD_TEMPLATE}
          </Button>
        </div>
      )
    },
    {
      name: "shouldValidateOnly",
      type: TYPE_HIDDEN,
      initialValue: true,
    },
    {
      name: "importFileUrl",
      type: TYPE_UPLOAD,
      label: LABEL_FILE,
    },
    {
      name: "tagIds",
      type: TYPE_TAGS,
      label: LABEL_TAGS,
      required: false,
      inputProps: {
        group: ACCOUNT_TAG
      },
    },
  ]

  if (projectId) {
    inputs.push(
      {
        name: "projectId",
        type: TYPE_HIDDEN,
        initialValue: projectId,
      }
    )
  }

  const alertStyle = {
    marginTop: -10
  }

  if (isValidating) {
    inputs.push(
      {
        name: "_validationAlert",
        component: () => (
          <Alert
            type="info"
            style={alertStyle}
            message={
              <Space size={W0}>
                <Spin size="small" />
                {LABEL_VALIDATION}
              </Space>}
          />
        )
      }
    )
  }

  if (errorsJson) {
    const type = "applidation/json"
    const fileName = 'import_validation_report.json'

    const renderMessage = () =>
      <>
        {LABEL_ERRORS}
        <Typography.Link onClick={() => downloadContent(fileName, errorsJson, type)}>
          {LABEL_REPORT}
        </Typography.Link>.
      </>

    inputs.push(
      {
        name: "_errorsJson",
        component: () => (
          <div style={{}}>
            <Alert
              type="error"
              style={alertStyle}
              message={renderMessage()}
            />
          </div>
        )
      }
    )
  }

  inputs = [
    ...inputs,
    ...extraInputs
  ]

  return inputs
}

export default getInputs
