import PropTypes from "prop-types"

import AddressShape from "./AddressShape"

const properties = {
  accreditationExpiresAt: PropTypes.string,
  additionalName: PropTypes.string,
  address: AddressShape,
  alternativeEmail: PropTypes.string,
  alternativeName: PropTypes.string,
  campaignEmails: PropTypes.arrayOf(PropTypes.string),
  dateOfBirth: PropTypes.string,
  email: PropTypes.string,
  familyName: PropTypes.string,
  givenName: PropTypes.string,
  hasAccreditation: PropTypes.bool,
  landNumber: PropTypes.string,
  mobileNumber: PropTypes.string,
  timeZoneCode: PropTypes.string
}

const InvestorShape = PropTypes.exact(properties)

export default InvestorShape
export { properties }
