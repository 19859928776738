

const getSegmentOptions = (columns) => {
  const options = columns
    .map(column => ({
      label: column.title,
      options: (column.options || [])
        .filter(({ value }) => !!value)
        .map(option => ({ ...option, value: `${column.dataIndex}:${option.value}`}))
    }))

  return options
}

export default getSegmentOptions
