import { useEffect, useState } from "react"

import { runRequest } from "@components/AppContext"

import useUsersStore, { usersStoreProperties } from "../BackstageStore/useUsersStore"
import useOrganizationStore, { organizationStoreProperties } from "../BackstageStore/useOrganizationStore"

import useUserGroupsStore, { userGroupsStoreProperties } from "./useUserGroupsStore"
import useOrganizationsStore, { organizationsStoreProperties } from "./useOrganizationsStore"


const useConsoleStore = () => {
  const [ isLoading, setIsLoading ] = useState()

  const usersStore = useUsersStore()
  const userGroupsStore = useUserGroupsStore()
  const organizationStore = useOrganizationStore()
  const organizationsStore = useOrganizationsStore()

  const { indexUsers } = usersStore
  const { indexUserGroups } = userGroupsStore
  const { readOrganization } = organizationStore
  const { indexOrganizations } = organizationsStore

  const isReady = isLoading !== undefined && !isLoading

  useEffect(() => {
    if (isReady) {
      return
    }

    if (isLoading) {
      return
    }

    return runRequest(() => {
      setIsLoading(true)
      Promise.all([
        indexUsers(),
        indexOrganizations(),
        readOrganization(),
        indexUserGroups(),
      ]).then(() => setIsLoading(false))
    })
  }, [
    isReady,
    isLoading,
    indexUsers,
    indexUserGroups,
    readOrganization,
    indexOrganizations
  ])

  const context = {
    ...usersStore,
    ...userGroupsStore,
    ...organizationStore,
    ...organizationsStore,
  }

  return { isReady, context }
}

const consoleStoreProperties = {
  ...usersStoreProperties,
  ...userGroupsStoreProperties,
  ...organizationStoreProperties,
  ...organizationsStoreProperties,
}

export default useConsoleStore

export {
  consoleStoreProperties
}
