

const getBeneficiary = (bankAccountsMap, project) => {
  const { bankAccountId } = project

  if (!bankAccountId) {
    return ""
  }

  const bankAccount = bankAccountsMap[bankAccountId]

  if (!bankAccount) {
    return ""
  }

  const { beneficiaryName } = bankAccount

  return beneficiaryName
}

export default getBeneficiary
