import React, { useState } from "react"
import { useOutletContext } from "react-router-dom"
import { Space, Tooltip } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

import { wait } from "@api"
import {
  useCustomer,
  SourceVerificationButton,
  CustomerVerificationButton,
  BusinessCustomerUpgradeButton,
  CUSTOMER_STATUS_VERIFIED,
  CUSTOMER_STATUS_UNVERIFIED,
} from "@components/Dwolla"
import { IndexBankAccountsOutputShape } from "@api/services/transactions"

import PendingStatus from "./PendingStatus"
import VerifiedStatus from "./VerifiedStatus"

const LABEL_VERIFY_SOURCE = "Verify micro deposits"
const LABEL_VERIFY_CUSTOMER = "Complete verification"
const LABEL_UPGRADE_CUSTOMER = "Enable"

const LABEL_VERIFY_TOOLTIP = `To proceed with enable distributions for this
account, please finalize the entity verification process.`


const TITLE_VERIFY_CUSTOMER = (
  <Tooltip
    title={LABEL_VERIFY_TOOLTIP}
    placement="top"
  >
    <Space>
      <ExclamationCircleOutlined />
      {LABEL_VERIFY_CUSTOMER}
    </Space>
  </Tooltip>
)


const BankAccountVerificationButton = ({ bankAccount }) => {
  const [ isVerifyCustomerModalOpen, setIsVerifyCustomerModalOpen ] = useState(false)

  const { organization } = useOutletContext()
  const { id: organizationId } = organization

  const {
    sourceId,
    beneficiaryName,
    isSourceVerified,
  } = bankAccount

  const businessName = beneficiaryName
  const customer = useCustomer(businessName)

  if (isSourceVerified) {
    return <VerifiedStatus />
  }

  if (!customer) {
    return <PendingStatus />
  }

  const isVerified = customer.verificationStatus === CUSTOMER_STATUS_VERIFIED
  const isUnverified = customer.verificationStatus === CUSTOMER_STATUS_UNVERIFIED

  if (!isVerified) {
    const onSuccess = async () => {
      await wait(250)
      setIsVerifyCustomerModalOpen(true)
    }

    if (isUnverified) {
      // eslint-disable-next-line no-warning-comments
      // TODO: There could be a scenario when upgrade has been already submitted
      //       while status is still unverified.
      return (
        <BusinessCustomerUpgradeButton
          title={LABEL_UPGRADE_CUSTOMER}
          customer={customer}
          onSuccess={onSuccess}
          bankAccount={bankAccount}
        />
      )
    }

    return (
      <CustomerVerificationButton
        title={TITLE_VERIFY_CUSTOMER}
        customer={customer}
        isModalOpen={isVerifyCustomerModalOpen}
      />
    )
  }

  const source = customer.sources?.find(s => s.id === sourceId)

  if (!source) {
    return <PendingStatus />
  }

  const sourceVerificationButtonProps = {
    title: (
      <Space>
        <ExclamationCircleOutlined />
        <span>{LABEL_VERIFY_SOURCE}</span>
      </Space>
    ),
    source,
    customer,
    isModalOpen: false,
    renderVerified: () => <VerifiedStatus />,
    updateSourceVerificationStatusParameters: { organizationId },
  }

  return (
    <SourceVerificationButton {...sourceVerificationButtonProps} />
  )
}

BankAccountVerificationButton.propTypes = {
  bankAccount: IndexBankAccountsOutputShape.isRequired,
}

export default BankAccountVerificationButton
