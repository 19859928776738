import React from "react"
import { Space, Tag } from "antd"

import { Bold } from "@components/Text"
import { ROLES_MAP } from "@components/Domain"

import TableRowActions from "../TableRowActions"


const getRoleColor = roleId => {
  const role = ROLES_MAP[roleId]

  if (!role) {
    return []
  }

  const { tagColor } = role

  return tagColor
}


const getRoleTitle = roleId => {
  const role = ROLES_MAP[roleId]

  if (!role) {
    return ""
  }

  const { title } = role

  return title
}


const getColumnsConfig = (removeUserGroup, openUpdateModal) => {
  return [
    {
      key: "name",
      isSearchable: true,
      render: (value, { role }) => <Bold>{value}</Bold>,
    },
    {
      key: "email",
      isSearchable: true
    },
    {
      key: "role",
      title: "Role",
      compute: ({ role }) => getRoleTitle(role),
      isSortingEnabled: true,
      render: (value, { role }) =>
        <Tag
          color={getRoleColor(role)}
          bordered={false}
        >
          <Space>
            {value}
          </Space>
        </Tag>
    },
    {
      key: "actions",
      title: false,
      width: 48,
      render: (_, userGroup) =>
        <TableRowActions
          userGroup={userGroup}
          openUpdateModal={openUpdateModal}
          removeUserGroup={removeUserGroup}
        />
    }
  ]
}

export default getColumnsConfig
