import React from "react"
import { Space, Typography } from 'antd'

import {
  INVESTMENT_PROFILE_TYPE_JOINT,
  INVESTMENT_PROFILE_TYPE_SDIRA,
  INVESTMENT_PROFILE_TYPE_ENTITY,
  INVESTMENT_PROFILE_TYPE_INDIVIDUAL
} from "@components/Domain"
import { Small } from "@components/Text"

const { Text } = Typography

const EXTRA_ENTITY = `
  Including LLCs, corporations, self-directed 401k, trusts, and checkbook
  control SDIRA
`

const EXTRA_SDIRA = `
  Without checkbook control and including a corporation, LLC, partnership,
  employee benefit plan, Keogh plan, or other entity
`


const renderProfileTypeOptions = (isIndividualDisabled = false) => {
  return [
    {
      value: INVESTMENT_PROFILE_TYPE_INDIVIDUAL,
      label: `Yourself as an individual${isIndividualDisabled ? " (already exists)" : ""}`,
      disabled: isIndividualDisabled,
    },
    {
      value: INVESTMENT_PROFILE_TYPE_JOINT,
      label: "Yourself and your spouse or tenancy",
    },
    {
      value: INVESTMENT_PROFILE_TYPE_ENTITY,
      label:
        <Space direction="vertical" size={0} style={{ lineHeight: 1.2 }}>
          <Text>Entity / Trust</Text>
          <Small>{EXTRA_ENTITY}</Small>
        </Space>
    },
    {
      value: INVESTMENT_PROFILE_TYPE_SDIRA,
      label:
        <Space direction="vertical" size={0} style={{ lineHeight: 1.2 }}>
          <Text>SDIRA</Text>
          <Small>{EXTRA_SDIRA}</Small>
        </Space>,
    }
  ]
}

export default renderProfileTypeOptions
