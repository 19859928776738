import React, { useState, useEffect, useCallback } from "react"
import { useOutletContext } from "react-router-dom"
import { Row, Col, Statistic, Collapse, Typography } from "antd"

import { INVESTMENT_STATUS } from "@components/Domain"
import { getProjectStatistics } from "@components/Project"
import { getFormattedAmountValue } from "@components/Amount"

import useCalculatedTargets from "./helpers/useCalculatedTargets"
import InvestmentsExtendedSummary from "./InvestmentsExtendedSummary"

const { Text } = Typography

const LABEL_NA = "Non-Accredited"
const LABEL_BACKUP = "Backup"
const LABEL_DROPOUT = "Dropout"
const LABEL_FUNDING_RAISED = "Funding Raised"


const InvestmentsSummary = () => {
  const { project, investments = [] } = useOutletContext()

  const { targetAmount, nonAccreditedInvestorsMax } = useCalculatedTargets()

  const [ backupCount, setBackupCount ] = useState(0)
  const [ raisedAmount, setRaisedAmount ] = useState(0)
  const [ rejectedCount, setRejectedCount ] = useState(0)
  const [ nonAccreditedCount, setNonAccreditedCount ] = useState(0)

  const updateAmounts = useCallback(() => {
    const {
      raisedFundingAmount,
      totalByInvestmentStatus,
      nonAccreditedInvestorsCount,
    } = getProjectStatistics(project, investments)

    const { count: backupInvestmentsCount } = totalByInvestmentStatus[INVESTMENT_STATUS.BACKUP]
    const { count: rejectedInvestmentsCount } = totalByInvestmentStatus[INVESTMENT_STATUS.REJECTED]

    setRaisedAmount(raisedFundingAmount)
    setNonAccreditedCount(nonAccreditedInvestorsCount)
    setBackupCount(backupInvestmentsCount)
    setRejectedCount(rejectedInvestmentsCount)
  }, [ project, investments ])

  useEffect(() => {
    updateAmounts()
  }, [ updateAmounts, investments ])

  const formatter = new Intl.NumberFormat()
  const targetAmountFormatted = formatter.format(targetAmount)
  const fundingRaisedFormatted = formatter.format(getFormattedAmountValue(raisedAmount))

  const items = [
    {
      title: LABEL_FUNDING_RAISED,
      value: fundingRaisedFormatted,
      prefix: "$",
      formatter: value =>
        <>{value}<Text type="secondary"> of {targetAmountFormatted}</Text></>
    },
    {
      title: LABEL_NA,
      value: nonAccreditedCount,
      formatter: value =>
        <>{value}<Text type="secondary"> of {nonAccreditedInvestorsMax}</Text></>
    },
    {
      title: LABEL_BACKUP,
      value: backupCount,
    },
    {
      title: LABEL_DROPOUT,
      value: rejectedCount,
    },
  ]

  const header = (
    <Row>
      {
        items.map(({ ...props }, key) =>
          <Col key={key} span={6}>
            <Statistic {...props} />
          </Col>
        )
      }
    </Row>
  )

  const panels = [{
    key: "panel",
    label: header,
    forceRender: false,
    children: (
      <InvestmentsExtendedSummary />
    ),
  }]

  return (
    <Collapse
      size="small"
      items={panels}
      bordered={false}
    />
  )
}

export default InvestmentsSummary
