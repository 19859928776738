import React from "react"
import { useNavigate } from "react-router-dom"
import { Card, Layout, theme, Button } from "antd"
import { LogoutOutlined } from "@ant-design/icons"

import { Footer } from "@components/Page"
import { getConfig } from "@components/Config"
import { W1, W3, WIDTH_MAX } from "@components"
import { OrganizationsList } from "@components/Organization"

const PLATFORM_NAME = getConfig("PLATFORM_NAME")

const LABEL_TITLE = "Console"
const LABEL_SIGNOUT = "Sign Out"

const LOGOUT_PATH = "/logout/backstage"


const OrganizationsListPage = () => {
  const navigate = useNavigate()
  const { token } = theme.useToken()

  const { colorBgContainer } = token

  const layoutStyle = {
    backgroundColor: colorBgContainer
  }

  const layoutContentStyle = {
    width: "100%",
    margin: "0 auto",
    padding: `${W3}px ${W1}px`,
    maxWidth: WIDTH_MAX / 3,
  }

  const extra =
    <Button
      type="text"
      size="small"
      icon={<LogoutOutlined />}
      onClick={() => navigate(LOGOUT_PATH)}
    >
      {LABEL_SIGNOUT}
    </Button>

  const cardProps = {
    title: LABEL_TITLE,
    extra,
  }

  return (
    <Layout style={layoutStyle}>
      <Layout.Content style={layoutContentStyle}>
        <Card {...cardProps}>
          <OrganizationsList />
        </Card>

        <Footer organizationName={PLATFORM_NAME} />
      </Layout.Content>
    </Layout>
  )
}

export default OrganizationsListPage
