import PropTypes from "prop-types"

import { TransactionTypeEnumKeys } from "./shapes/TransactionTypeEnum"

const properties = {
  amount: PropTypes.number.isRequired,
  investorAccountId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(TransactionTypeEnumKeys).isRequired
}

const FundInvestmentOutputShapeShape = PropTypes.exact(properties)

export default FundInvestmentOutputShapeShape
export { properties }
