import { getSdiraBusinessName } from "@components/Dwolla"

import isSdiraInvestment from "../../../../../helpers/isSdiraInvestment"
import isEntityInvestment from "../../../../../helpers/isEntityInvestment"


const getConnectSourceProps = (investment, onSelect) => {
  const {
    name: businessName,
    receiverSourceId: sourceId,
    investorAccountId
  } = investment

  const props = {
    dropdownProps: { isPlaidDisabled: true },
    isReceiveOnly: false,
    isVerificationRequired: false,
    impersonatedInvestorAccountId: investorAccountId,
    onSelect,
    sourceId,
    investment
  }

  const isEntity = isEntityInvestment(investment)

  if (isEntity) {
    props.businessName = businessName
  }

  const isSdira = isSdiraInvestment(investment)

  if (isSdira) {
    props.businessName = getSdiraBusinessName(businessName)
    props.isReceiveOnly = true
  }

  return props
}

export default getConnectSourceProps
