import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { CreateModal } from "@components/Page"
import { connectFundProjectPropertyOperation as operation } from "@api/services/backstage"

import useInputs from "./helpers/useInputs"

const LABEL_ADD = "Connect"
const LABEL_TITLE = "Connect Property"
const LABEL_ENTITY = "Property"
const LABEL_SUCCESS_MESSAGE = "Property is connected to the fund"


const ConnectFundProjectPropertyModal = ({ modal }) => {
  const {
    project,
    readProject,
    indexProjects,
    indexProperties,
  } = useOutletContext()

  const {
    id: projectId,
    investmentSubjectIds
  } = project

  const onSubmit = parameters =>
    ({ ...parameters, id: projectId })

  const onSuccess = () =>
    Promise.all([
      readProject(),
      indexProjects(),
      indexProperties(),
    ])

  const inputs = useInputs(investmentSubjectIds)
  const schema = operation.getSchema(inputs)

  const createModalProps = {
    title: LABEL_TITLE,
    entity: LABEL_ENTITY,
    submitLabel: LABEL_ADD,
    successMessage: LABEL_SUCCESS_MESSAGE,
    modal,
    schema,
    onSubmit,
    onSuccess,
    operation,
  }

  return (
    <CreateModal {...createModalProps} />
  )
}

ConnectFundProjectPropertyModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default ConnectFundProjectPropertyModal
