import PropTypes from "prop-types"

import { investorStoreProperties } from "@components/Store/InvestorStore/useInvestorStore"
import { IndexPublishedProjectsOutputShape } from "@api/services/backstage"


const InvestorProjectLayoutContextShape = PropTypes.exact({
  project: IndexPublishedProjectsOutputShape.isRequired,
  currentForm: PropTypes.shape().isRequired,
  getInvestorSsnNumber: PropTypes.func.isRequired,
  ...investorStoreProperties,
})

export default InvestorProjectLayoutContextShape
