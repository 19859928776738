import PropTypes from "prop-types"


const properties = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

const DnsRecordShape = PropTypes.exact(properties)

export default DnsRecordShape
export { properties }
