import { useCallback, useState } from "react"
import PropTypes from "prop-types"

import ProfileShape from "@api/services/investments/shapes/ProfileShape"
import { useAppContext } from "@components/AppContext"
import { readMyAccountOperation } from "@api/services/investments"


const useProfilesStore = () => {
  const [ profiles, setProfiles ] = useState([])

  const { request } = useAppContext()

  const indexProfiles = useCallback(() => {
    return request(readMyAccountOperation, {})
      .then(({ data }) => setProfiles(data.profiles))
  }, [ request ])

  return {
    profiles,
    indexProfiles
  }
}

const profilesStoreProperties = {
  profiles: PropTypes.arrayOf(ProfileShape),
  indexProfiles: PropTypes.func.isRequired,
}

export default useProfilesStore

export {
  profilesStoreProperties
}
