import capitalize from "lodash.capitalize"

export const CERTIFICATE_PENDING = "CERTIFICATE_PENDING"
export const CERTIFICATE_RECORD = "CERTIFICATE_RECORD"
export const CERTIFICATE_VERIFICATION = "CERTIFICATE_VERIFICATION"
export const COMPLETE = "COMPLETE"
export const DOMAIN_RECORD = "DOMAIN_RECORD"

const DomainStatusEnum = {
  [CERTIFICATE_PENDING]: CERTIFICATE_PENDING,
  [CERTIFICATE_RECORD]: CERTIFICATE_RECORD,
  [CERTIFICATE_VERIFICATION]: CERTIFICATE_VERIFICATION,
  [COMPLETE]: COMPLETE,
  [DOMAIN_RECORD]: DOMAIN_RECORD
}

const DomainStatusEnumKeys = Object.keys(DomainStatusEnum)

const DomainStatusEnumOptions = DomainStatusEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  DomainStatusEnumKeys,
  DomainStatusEnumOptions
}

export default DomainStatusEnum
