import React, { useMemo } from "react"
import { Form } from "antd"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { ItemPropsShape, InputPropsShape } from "@api"

import TagsList from "../../Tags/TagsList"
import FormShape from "../shapes/FormShape"

const { Item } = Form


const TagsInput = ({
  form,
  path,
  itemProps,
  inputProps
}) => {
  const {
    group,
    ...restInputProps
  } = inputProps

  const { getOptions } = useOutletContext()

  const tagOptions = useMemo(() =>
    getOptions(group)
  , [ group, getOptions ])

  const tagIds = Form.useWatch(path, form)

  const onChange = (tagId, isAdd = true) => {
    const newTagIds = isAdd
      ? [...(tagIds || []), tagId]
      : [...(tagIds || []).filter(item => item !== tagId)]
    form.setFieldValue(path, newTagIds)
  }

  const tagsListProps = {
    tagIds,
    onChange,
    tagOptions,
    ...restInputProps
  }

  return (
    <Item {...itemProps}>
      <TagsList {...tagsListProps} />
    </Item>
  )
}

TagsInput.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemProps: ItemPropsShape.isRequired,
  inputProps: InputPropsShape.isRequired,
}

export default TagsInput
