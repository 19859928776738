import { TYPE_AMOUNT } from "@components/Form"
import { TRANSACTION_AMOUNT_MAX } from "@components/Domain"

const LABEL_AMOUNT = "Amount"


const getUpdateInputs = () => {
  const inputs = [
    {
      name: "amount",
      type: TYPE_AMOUNT,
      label: LABEL_AMOUNT,
      minimum: 1,
      maximum: TRANSACTION_AMOUNT_MAX,
      placeholder: "1",
      inputProps: {
        style: {
          width: 130
        },
        controls: false
      }
    },
  ]

  return inputs
}

export default getUpdateInputs
