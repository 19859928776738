import { useMemo } from "react"
import { useParams } from "react-router-dom"


const useProjectItems = items => {
  const { projectId } = useParams()

  const result = useMemo(() => {
    if (projectId && items) {

      return items.filter(item => item.projectId === projectId)
    }

    return items
   } , [ projectId, items ])

  return result
}

export default useProjectItems
