import React, { useMemo } from "react"
import sortBy from "lodash.sortby"
import { Tag, Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import Identity from "@components/Identity"
import { Value } from "@components/Text"
import { useAppContext } from "@components/AppContext"
import { useTagsColumnProps } from "@components/Tags"
import {
  useBrokerColumnProps,
  useSponsorsColumnProps
} from "@modules/backstage/sponsors"
import {
  INVESTOR_STATUSES,
  INVESTOR_STATUS_COLORS_MAP,
  INVESTOR_STATUS_LABELS_MAP,
} from "@components/Domain"

import computeActivityFilterValue, { ACTIVITY_DAYS } from "./computeActivityFilterValue"

const LABEL_PROJECTS = "Projects"
const LABEL_REFERRED = "Referred From"


const useColumnsConfig = (openInvestorDrawer) => {
  const { isBroker } = useAppContext()

  const {
    projects,
    accountTags,
    setAccountTags,
    hasMultipleSponsors
  } = useOutletContext()

  const projectFilters = useMemo(() =>
      sortBy(projects, "name")
        .map(({ id: value, name: label }) => ({ value, label }))
    , [ projects ])

  const tagsColumnProps = useTagsColumnProps(accountTags, setAccountTags)

  const referralSourceColumnProps = {
    key: "referralSource",
    title: LABEL_REFERRED,
  }

  const nameColumnProps = {
    key: "name",
    title: "Name",
    render: (name, account) => (
      !openInvestorDrawer
        ? <Identity id={account.id} strong={true}>{name}</Identity>
        : <Identity
            id={account.id}
            strong={true}
            onClick={() => openInvestorDrawer(account.id)}
          >
            {name}
          </Identity>
    ),
    compute: ({ investor }) => `${investor.givenName} ${investor.familyName}`,
    isSearchable: true
  }

  const emailColumnProps = {
    key: "email",
    compute: ({ investor }) => investor.email,
    render: (value, { investor }) =>
      <Typography.Link href={`mailto:${investor.email}`}>
        {value}
      </Typography.Link>
    ,
    isSearchable: true
  }

  const statusColumnProps = {
    key: "status",
    compute: ({ status }) => INVESTOR_STATUS_LABELS_MAP[status],
    render: (value, { status }) => (
      <Tag
        color={INVESTOR_STATUS_COLORS_MAP[status]}
        bordered={false}
      >
        {value}
      </Tag>
    ),
    filterValues: [
      INVESTOR_STATUSES.PROSPECT,
      INVESTOR_STATUSES.ACTIVE,
      INVESTOR_STATUSES.HOLD
    ],
    filterMultiple: true,
    isFilterEnabled: true,
  }

  const lastActivityColumnProps = {
    key: "lastAuthenticatedAt",
    title: "Last Activity",
    compute: ({ lastAuthenticatedAt }) => computeActivityFilterValue(lastAuthenticatedAt),
    filterValues: [
      ACTIVITY_DAYS.DAYS_30,
      ACTIVITY_DAYS.DAYS_60,
      ACTIVITY_DAYS.DAYS_90,
      ACTIVITY_DAYS.DAYS_365,
    ],
    isHidden: true,
    filterMultiple: true,
    isFilterEnabled: true,
  }

  const lastInvestedColumnProps = {
    key: "lastFundedAt",
    title: "Last Invested Date",
    compute: ({ lastFundedAt }) => computeActivityFilterValue(lastFundedAt),
    filterValues: [
      ACTIVITY_DAYS.DAYS_30,
      ACTIVITY_DAYS.DAYS_60,
      ACTIVITY_DAYS.DAYS_90,
      ACTIVITY_DAYS.DAYS_365,
    ],
    isHidden: true,
    filterMultiple: true,
    isFilterEnabled: true,
  }

  const investedAmountColumnProps = {
    key: "totalInvestedAmount",
    title: "Invested",
    render: value => <Value value={value} />
  }

  const projectsColumnProps = {
    key: "projectIds",
    title: LABEL_PROJECTS,
    isHidden: true,
    filters: projectFilters,
    onFilter: (selectedValues, { projectIds = [] }) => projectIds.includes(selectedValues[0]),
    filterMultiple: true,
    isFilterEnabled: true,
  }

  const brokerColumnProps = useBrokerColumnProps()
  const sponsorColumnProps = useSponsorsColumnProps()

  const createdAtColumnProps = {
    key: "createdAt",
    render: value => <Value value={value} />
  }

  const MAX_COLUMN_WIDTH = 240

  const columns = [
    { ...nameColumnProps, fixed: "left", width: MAX_COLUMN_WIDTH, hasTruncation: true },
    { ...emailColumnProps, width: MAX_COLUMN_WIDTH, hasTruncation: true },
    { ...statusColumnProps, align: "center", width: 100 },
    { ...investedAmountColumnProps, align: "right", width: 120 },
  ]

  if (!isBroker) {
    if (hasMultipleSponsors) {
      columns.push({ ...sponsorColumnProps, width: MAX_COLUMN_WIDTH, hasTruncation: true })
    }

    columns.push({ ...brokerColumnProps, width: MAX_COLUMN_WIDTH, hasTruncation: true })
  }

  columns.push(
    { ...lastActivityColumnProps },
    { ...lastInvestedColumnProps },
    { ...createdAtColumnProps, width: 180 },
    { ...referralSourceColumnProps, width: 200 },
    { ...tagsColumnProps, width: 400 },
    { ...projectsColumnProps },
  )

  return columns
}

export default useColumnsConfig
