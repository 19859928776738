import React from "react"
import PropTypes from "prop-types"
import { Card, Result } from "antd"

import { isMobile } from "@components"


const Error = ({ title, message }) => {
  const children = (
    <Result
      icon={false}
      title={title}
      status="error"
      subTitle={message}
    />
  )

  if (isMobile) {
    return children
  }

  return (
    <Card>
      {children}
    </Card>
  )
}

Error.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default Error
