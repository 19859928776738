import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { Typography, Spin, Tag, Space } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

import { Modal } from "@components/Page"
import SourceShape from "@api/services/investments/shapes/SourceShape"
import { MODAL_WIDTH2 } from "@components"
import Form, { useForm } from "@components/Form"
import { runRequest, useAppContext } from "@components/AppContext"
import {
  verifyMyCustomSourceOperation,
  submitMySourceMicroDepositsOperation,
  updateMySourceVerificationStatusOperation,
} from "@api/services/transactions"

import getInputs from "./helpers/getInputs"
import PendingStatus from "./PendingStatus"
import CustomerShape from "../shapes/CustomerShape"
import VerificationFailedStatus from "./VerificationFailedStatus"

const { Paragraph } = Typography

const LABEL_TITLE = "Complete Verification"
const LABEL_SUBMIT = "Submit"
const LABEL_VERIFIED = "Verified"

const LABEL_HEADER = `To verify your account, enter the two micro-deposits 
below. Please check your bank account for two, less than $0.20, micro-deposit 
amounts and enter the amounts below.`

const DEFAULT_EXPECTED_ERRORS = ["InvalidParametersError"]

const inputs = getInputs()
const schema = submitMySourceMicroDepositsOperation.getSchema(inputs)


const SourceVerificationButton = ({
  onSuccess: onSuccessCustom = () => {},
  title = undefined,
  isModalOpen = false,
  renderVerified = () => <Tag color="green">{LABEL_VERIFIED}</Tag>,
  updateSourceVerificationStatusParameters = {},
  source,
  customer,
}) => {
  const modal = useRef({})

  const form = useForm()

  const {
    authenticatedRequest,
    updateIdentityCustomerSource
  } = useAppContext()

  const [ isOpen, setIsOpen ] = useState(isModalOpen)
  const [ isLoading, setIsLoading ] = useState(false)

  useEffect(() => {
    if (!source) {
      return
    }

    const {
      id,
      isVerified,
      isMicroDepositsRequested
    } = source

    if (isVerified) {
      return
    }

    if (isMicroDepositsRequested) {
      return
    }

    return runRequest(() => {
      setIsLoading(true)
      authenticatedRequest(verifyMyCustomSourceOperation, { id })
        .then(({ data }) => {
          updateIdentityCustomerSource(customer.id, data)
          setIsLoading(false)
        })
    })
  }, [
    source,
    customer,
    authenticatedRequest,
    updateIdentityCustomerSource,
  ])

  const {
    isInvalid,
    isVerified,
    isVerificationFailed,
    isMicroDepositsVerifiable,
  } = source

  if (isVerified) {
    return renderVerified()
  }

  if (isVerificationFailed) {
    return <VerificationFailedStatus isInvalid={isInvalid}/>
  }

  if (!isMicroDepositsVerifiable) {
    return <PendingStatus />
  }

  const onClose = () =>
    setIsOpen(false)

  modal.current.open = () =>
    setIsOpen(true)

  const onClick = () =>
    modal.current.open()

  title = title || (
    <Space>
      <ExclamationCircleOutlined />
      <span>{LABEL_TITLE}</span>
    </Space>
  )

  const onSuccess = () => {
    const parameters = { id: source.id, ...updateSourceVerificationStatusParameters }

    return authenticatedRequest(updateMySourceVerificationStatusOperation, parameters)
      .then(({ data: updatedSource }) => {
        updateIdentityCustomerSource(customer.id, updatedSource)
        return updatedSource
      })
      .then(updatedSource => onSuccessCustom(customer, updatedSource))
  }

  const formOptions = {
    item: source,
    request: authenticatedRequest,
    operation: submitMySourceMicroDepositsOperation,
    submitLabel: LABEL_SUBMIT,
    shouldOptimizeUpdateMutation: false,
    form,
    schema,
    onSuccess,
    expectedErrors: DEFAULT_EXPECTED_ERRORS
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <Typography.Link onClick={onClick}>
          {title}
        </Typography.Link>
      </Spin>

      <Modal
        title={LABEL_TITLE}
        width={MODAL_WIDTH2}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Paragraph>
          {LABEL_HEADER}
        </Paragraph>

        <Form {...formOptions} />
      </Modal>
    </>
  )
}

SourceVerificationButton.propTypes = {
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.shape() ]),
  source: SourceShape.isRequired,
  customer: CustomerShape.isRequired,
  onSuccess: PropTypes.func,
  isModalOpen: PropTypes.bool,
  renderVerified: PropTypes.func,
  updateSourceVerificationStatusParameters: PropTypes.shape(),
}

export default SourceVerificationButton
