import getOperation from "../../helpers/getOperation"

import CloneCampaignOutputShape from "./CloneCampaignOutputShape"
import CreateCampaignOutputShape from "./CreateCampaignOutputShape"
import IndexCampaignsOutputShape from "./IndexCampaignsOutputShape"
import IndexRecipientReportsOutputShape from "./IndexRecipientReportsOutputShape"
import ListRecipientOptOutsOutputShape from "./ListRecipientOptOutsOutputShape"
import PublishCampaignOutputShape from "./PublishCampaignOutputShape"
import ReadCampaignMetricsOutputShape from "./ReadCampaignMetricsOutputShape"
import ReadCampaignOutputShape from "./ReadCampaignOutputShape"
import UnpublishCampaignOutputShape from "./UnpublishCampaignOutputShape"
import UpdateCampaignOutputShape from "./UpdateCampaignOutputShape"
import UpdateCampaignTagsOutputShape from "./UpdateCampaignTagsOutputShape"

export {
  CloneCampaignOutputShape,
  CreateCampaignOutputShape,
  IndexCampaignsOutputShape,
  IndexRecipientReportsOutputShape,
  ListRecipientOptOutsOutputShape,
  PublishCampaignOutputShape,
  ReadCampaignMetricsOutputShape,
  ReadCampaignOutputShape,
  UnpublishCampaignOutputShape,
  UpdateCampaignOutputShape,
  UpdateCampaignTagsOutputShape
}

export const cloneCampaignOperation = getOperation("campaigns/CloneCampaign")
export const createCampaignOperation = getOperation("campaigns/CreateCampaign")
export const deleteCampaignOperation = getOperation("campaigns/DeleteCampaign")
export const indexCampaignsOperation = getOperation("campaigns/IndexCampaigns")
export const indexRecipientReportsOperation = getOperation("campaigns/IndexRecipientReports")
export const listRecipientOptOutsOperation = getOperation("campaigns/ListRecipientOptOuts")
export const publishCampaignOperation = getOperation("campaigns/PublishCampaign")
export const readCampaignOperation = getOperation("campaigns/ReadCampaign")
export const readCampaignMetricsOperation = getOperation("campaigns/ReadCampaignMetrics")
export const unpublishCampaignOperation = getOperation("campaigns/UnpublishCampaign")
export const updateCampaignOperation = getOperation("campaigns/UpdateCampaign")
export const updateCampaignTagsOperation = getOperation("campaigns/UpdateCampaignTags")
