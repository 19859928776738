import React from "react"
import { Row, Col, Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import { Value } from "@components/Text"

const { Text } = Typography


const useRenderItem = () => {
  const { getInvestmentProjectName } = useOutletContext()

  const render = (id, { documentType, investmentId, date }) =>
    <Row>
      <Col span={12}>
        <Text strong>
          {getInvestmentProjectName(investmentId)}
        </Text>
      </Col>

      <Col span={12} align="right">
        <Text type="secondary">
          <Value value={date} format={"L"} />
        </Text>
      </Col>

      <Col span={12}>
        <Text>{documentType}</Text>
      </Col>
    </Row>

  return render
}

export default useRenderItem
