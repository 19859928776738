import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Typography } from "antd"

import { Value } from "@components/Text"
import { CustomerBalance } from "@components/Dwolla"
import { InvestmentShape } from "@components/Investment/shapes"
import { runRequest, useAppContext } from "@components/AppContext"

import getFailedTransactionsAmount from "./helpers/getFailedTransactionsAmount"

const { Text } = Typography

const ACCOUNT_PATH = '/account'


const InvalidSourcesAlertMessage = ({
  sourceName,
  investments,
  customerName,
  isVerifiedCustomer,
}) => {
  const { request } = useAppContext()
  const [ amount, setAmount ] = useState()

  useEffect(() => {
    if (isVerifiedCustomer) {
      return
    }

    return runRequest(() =>
      getFailedTransactionsAmount(request)
        .then(newAmount => setAmount(newAmount))
    )
  }, [ request, isVerifiedCustomer, investments ])

  const style = { color: "rgba(0, 0, 0, 0.88)" }

  return (
    <Text style={style}>
      <Text style={{ marginRight: 4 }}>
        We were unable to transfer
      </Text>

      {
        !isVerifiedCustomer && (
          <Value value={amount} />
        )
      }

      {
        isVerifiedCustomer && (
          <CustomerBalance customerName={customerName} />
        )
      }

      <Text style={{ marginLeft: 4 }}>
        to <Text strong>{sourceName}</Text> account as it is no longer valid.
        Please <Link to={ACCOUNT_PATH}>click here</Link> to link another bank account.
      </Text>
    </Text>
  )
}

InvalidSourcesAlertMessage.propTypes = {
  sourceName: PropTypes.string.isRequired,
  investments: PropTypes.arrayOf(InvestmentShape).isRequired,
  customerName: PropTypes.string.isRequired,
  isVerifiedCustomer: PropTypes.bool.isRequired,
}

export default InvalidSourcesAlertMessage
