import React, { useMemo } from "react"
import { App, Modal } from "antd"
import { ExclamationCircleFilled } from "@ant-design/icons"
import { useOutletContext } from "react-router-dom"

import { useAppContext } from "@components/AppContext"
import { Markdown } from "@components/Text"

import { deleteBankAccountOperation as deleteOperation } from "@api/services/transactions"

const LABEL_YES = "Yes"
const LABEL_TITLE = "Delete Bank Account"
const LABEL_CONTENT = `Are you sure you want to delete this bank account? 
This action cannot be undone.`
const LABEL_NOT_DELETE_CONTENT = `This bank account cannot be deleted because it is connected to project(s):
{LIST}

Unlink the bank account first in order to delete it.`
const LABEL_SUCCESS_MESSAGE = "Bank Account $ is deleted"


const useAction = (bankAccount, removeBankAccount) => {
  const { modal: deleteModal } = App.useApp()
  const { request, showSuccessMessage } = useAppContext()
  const { projects } = useOutletContext()

  const bankAccountConnectedToProjects = useMemo(() => {
    const bankAccountProjects = projects
      .filter(({ bankAccountId, distributionBankAccountId }) =>
        [ bankAccountId, distributionBankAccountId ].includes(bankAccount.id))
      .map(({ name }) => name)

    return bankAccountProjects
  }, [projects, bankAccount])

  const isBankAccountConnectedToProjects = bankAccountConnectedToProjects.length > 0

  if (isBankAccountConnectedToProjects) {
    return () => {
      const projectList = bankAccountConnectedToProjects.map(name => `* ${name}`)
      const content = LABEL_NOT_DELETE_CONTENT.replace("{LIST}", projectList.join("\n"))

      return  Modal.info({
        title: LABEL_TITLE,
        content: <Markdown text={content} />,
        onOk() {},
      })
    }
  }

  return id => deleteModal.confirm({
    icon: <ExclamationCircleFilled />,
    title: LABEL_TITLE,
    content: LABEL_CONTENT,
    okText: LABEL_YES,
    okType: "danger",
    onOk() {
      return request(deleteOperation, { id })
        .then(() => {
          const { bankName } = bankAccount
          showSuccessMessage(LABEL_SUCCESS_MESSAGE.replace("$", `"${bankName}"`))
        })
        .then(() => removeBankAccount(id))
    },
    onCancel() {}
  })
}

export default useAction
