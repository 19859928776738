import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import { StopOutlined } from "@ant-design/icons"

import { W1 } from "@components"

const LABEL_DELETE = "Delete"


const DeleteButton = ({
  title = LABEL_DELETE,
  onDelete,
  ...buttonProps
}) => {
  const [ isLoading, setIsLoading ] = useState()

  const onClick = () => {
    setIsLoading(true)
    onDelete()
      .then(() => setIsLoading(false))
  }

  return (
    <div style={{ textAlign: "center", marginTop: W1 - 6 }}>
      <Button
        danger
        type="text"
        icon={<StopOutlined />}
        onClick={onClick}
        loading={isLoading}
        {...buttonProps}
      >
        {title}
      </Button>
    </div>
  )
}

DeleteButton.propTypes = {
  title: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
}

export default DeleteButton
