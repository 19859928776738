import PropTypes from "prop-types"


const properties = {
  domain: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string
}

const OrganizationShape = PropTypes.exact(properties)

export default OrganizationShape
export { properties }
