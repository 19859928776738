import React from "react"
import { PlusOutlined } from "@ant-design/icons"

const LABEL_CREATE = "Add Group"


const getHeaderActions = (openCreateModal) => {
  return [
    {
      icon: <PlusOutlined />,
      title: LABEL_CREATE,
      action: openCreateModal,
    },
  ]
}

export default getHeaderActions
