import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { usePlaidLink } from "react-plaid-link"


const PlaidConnect = ({ token, onSuccess, onExit }) => {
  const { open, ready } = usePlaidLink({
    token,
    onExit,
    onSuccess: publicToken => onSuccess(publicToken),
  })

  useEffect(() => {
    if (!ready) { return }

    open()
  }, [ ready, open ])

  return (
    <></>
  )
}

PlaidConnect.propTypes = {
  token: PropTypes.string.isRequired,
  onExit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default PlaidConnect
