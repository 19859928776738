import React from "react"
import capitalize from "lodash.capitalize"
import { Typography } from "antd"
import { useNavigate } from "react-router-dom"

import { ago } from "@components/Date"


const useColumnsConfig = () => {
  const navigate = useNavigate()

  const columns = [
    {
      key: "name",
      compute: ({ name }) => name,
      render: (name, { id }) =>
        <Typography.Link strong onClick={() => navigate(`/backstage/project/${id}/details`)}>
          {name}
        </Typography.Link>,
      isSearchable: true,
    },
    {
      key: "type",
      compute: ({ type }) => capitalize(type),
      width: 180,
    },
    {
      key: "updatedAt",
      width: 180,
      render: value => ago(value),
    }
  ]

  return columns
}

export default useColumnsConfig
