import Big from "big.js"


const getAmountWithoutCents = value => {
  const isEmpty = value.length === 0

  if (isEmpty) {
    return 0
  }

  const big = new Big(value).times(100)
  const amount = big.toNumber()

  return amount
}

export default getAmountWithoutCents
