import { blue, gray } from "@ant-design/colors"
import { ROLES, INVESTOR_STATUSES } from "@components/Domain"


const getColors = (roleId, role, status, colorBgLayout) => {
  const { color: colorOptions } = role

  let color = colorOptions[5]
  let backgroundColor = colorOptions[0]

  const isInvestor = roleId === ROLES.INVESTOR

  if (!isInvestor) {
    return { color, backgroundColor }
  }

  const isActive = status === INVESTOR_STATUSES.ACTIVE

  if (isActive) {
    return { color, backgroundColor }
  }

  const isProspect = status === INVESTOR_STATUSES.PROSPECT

  if (isProspect) {
    // eslint-disable-next-line prefer-destructuring
    color = gray[5]
    // eslint-disable-next-line prefer-destructuring
    backgroundColor = colorBgLayout

    return { color, backgroundColor }
  }

  // eslint-disable-next-line prefer-destructuring
  color = blue[5]
  // eslint-disable-next-line prefer-destructuring
  backgroundColor = blue[0]

  return { color, backgroundColor }
}

export default getColors
