import { useState, useCallback } from "react"

import { getConfig } from "@components/Config"
import { useAppContext } from "@components/AppContext"
import {
  uploadPersonalCustomerDocumentOperation,
  uploadBusinessCustomerDocumentsOperation
} from "@api/services/transactions"

import getStepInputs from "./getStepInputs"
import getPersonalInputs from "./getPersonalInputs"
import getBusinessInputs from "./getBusinessInputs"
import getControllerInputs from "./getControllerInputs"
import getBusinessTestScenarioInput from "./getBusinessTestScenarioInput"

const ENVIRONMENT_LABEL = getConfig('ENVIRONMENT_LABEL')
const isDevelopment = ENVIRONMENT_LABEL === "dev"


const useInputs = (isPersonalCustomer, customer, form) => {
  const [ step, setStep ] = useState(0)
  const [ schema, setSchema ] = useState([])

  const { showErrorMessage } = useAppContext()

  const updateSchema = useCallback(
    (currentStep = step) => {
      if (isPersonalCustomer) {
        const updatedSchema = getPersonalInputs(customer, form, showErrorMessage)

        setSchema(updatedSchema)
        return
      }

      const controllerInputs = getControllerInputs(customer, form, updateSchema, showErrorMessage)
      const businessInputs = getBusinessInputs(customer, form, updateSchema, showErrorMessage)

      const goStep = toStep => {
        setStep(toStep)
        updateSchema(toStep)
      }

      const submitExtraInputs = isDevelopment
        ? [ getBusinessTestScenarioInput() ]
        : []

      const stepInputs = getStepInputs(submitExtraInputs, goStep, currentStep, controllerInputs, businessInputs)

      setSchema(stepInputs)
    }
  , [
    form,
    step,
    customer,
    showErrorMessage,
    isPersonalCustomer,
  ])

  const operation = isPersonalCustomer
    ? uploadPersonalCustomerDocumentOperation
    : uploadBusinessCustomerDocumentsOperation

  return [ schema, operation, updateSchema ]
}

export default useInputs
