import PropTypes from "prop-types"

import AddressShape from "./AddressShape"

const properties = {
  address: AddressShape.isRequired,
  dateOfBirth: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  passport: PropTypes.string,
  ssn: PropTypes.string,
  title: PropTypes.string.isRequired
}

const BusinessControllerShape = PropTypes.exact(properties)

export default BusinessControllerShape
export { properties }
