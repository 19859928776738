import React from "react"
import keyBy from "lodash.keyby"
import { Space, Typography } from "antd"

import { Value } from "@components/Text"

import {
  INVESTMENT_PROFILE_TYPE_JOINT,
  INVESTMENT_PROFILE_TYPE_SDIRA,
  INVESTMENT_PROFILE_TYPE_ENTITY,
  INVESTMENT_PROFILE_TYPE_INDIVIDUAL,
} from "@components/Domain"

import renderProfileSdira from "./renderProfileSdira"
import renderProfileJoint from "./renderProfileJoint"
import renderProfileEntity from "./renderProfileEntity"
import renderProfileIndividual from "./renderProfileIndividual"

const { Text } = Typography

const LABEL_CLASS = "Class"


const renderInvestmentItems = (tiers, investment) => {
  const tiersMap = keyBy(tiers, "name")

  const {
    investor,
    investments,
    profileType,
    profileJoint,
    profileSdira,
    profileEntity,
    profileIndividual,
  } = investment

  const getTierLabel = projectTierId => {
    const tier = tiersMap[projectTierId]
    const { title, name } = tier

    return title || `${LABEL_CLASS} ${name}`
  }

  const formattedInvestments = (
    <Space
      size={0}
      direction="vertical"
    >
      {investments.map(({ projectTierId, amount }, key) =>
        <Text key={key}>
          {getTierLabel(projectTierId)} - <Value value={amount} />
        </Text>
      )}
    </Space>
  )

  const isIndividual =
    profileType === INVESTMENT_PROFILE_TYPE_INDIVIDUAL && !!profileIndividual

  if (isIndividual) {
    const items = renderProfileIndividual(profileIndividual, formattedInvestments, investor)
    return items
  }

  const isJoint =
    profileType === INVESTMENT_PROFILE_TYPE_JOINT && !!profileJoint

  if (isJoint) {
    const items = renderProfileJoint(profileJoint, formattedInvestments, investor)
    return items
  }

  const isSdira =
    profileType === INVESTMENT_PROFILE_TYPE_SDIRA && !!profileSdira

  if (isSdira) {
    const items = renderProfileSdira(profileSdira, formattedInvestments, investor)
    return items
  }

  const isEntity =
    profileType === INVESTMENT_PROFILE_TYPE_ENTITY && !!profileEntity

  if (isEntity) {
    const items = renderProfileEntity(profileEntity, formattedInvestments)
    return items
  }

  return [
    { label: "Profile Type", value: profileType },
    { label: "Investments", value: formattedInvestments }
  ]
}

export default renderInvestmentItems
