
const computeProjectInvestmentsFlags = investments => {
  if (!investments) return {}

  const isInvestorComplete = investments
    .filter(investment => !investment.isInvestorComplete)
    .length === 0

  const isProfileComplete = investments
    .filter(investment => !investment.isProfileComplete)
    .length === 0

  const isSigned = investments
    .filter(investment => !investment.isSigned)
    .length === 0

  const isFunded = investments
    .filter(investment => !investment.isFunded)
    .length === 0

  return {
    isFunded,
    isSigned,
    isProfileComplete,
    isInvestorComplete,
  }
}

export default computeProjectInvestmentsFlags
