import React from "react"
import PropTypes from "prop-types"
import { Dropdown, Button } from "antd"

const LABEL_TITLE = "(x)"

const isSubjectVariable = variable =>
  !variable.includes('EMAIL') &&
  !variable.includes('URL')


const TemplateVariablesDropdown = ({
  variables,
  appendVariable,
}) => {
  const items = variables
    .filter(isSubjectVariable)
    .map((variable, index) => ({
      key: index,
      label: variable.split(":")[1],
      onClick: () => appendVariable(`%${variable.split(":")[0]}%`),
    }))

  return (
    <Dropdown
      menu={{ items }}
      arrow={true}
      placement="bottomRight"
    >
      <Button
        type="text"
        size="small"
      >
        {LABEL_TITLE}
      </Button>
    </Dropdown>
  )
}

TemplateVariablesDropdown.propTypes = {
  variables: PropTypes.arrayOf(PropTypes.string).isRequired,
  appendVariable: PropTypes.func.isRequired,
}

export default TemplateVariablesDropdown
