import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Form, Select, AutoComplete } from "antd"

import {
  OperationShape,
  ItemPropsShape,
  InputPropsShape,
  SelectOptionShape,
} from "@api"
import { useRequest as useEffect } from "@components/AppContext"

import FormShape from "../shapes/FormShape"
import { SelectExtraOptionsShape } from "../FormItem/shapes"

const LABEL_NONE = "None"
const DEFAULT_SELECT_PLACEHOLDER = "Select an option"
const DEFAULT_SELECT_MULTIPLE_PLACEHOLDER = "Select one or multiple options"


const SelectInput = ({
  options: initialOptions = undefined,
  operation = undefined,
  extraOptions = undefined,
  form,
  itemProps,
  isMultiple,
  inputProps,
}) => {
  const [ options, setOptions ] = useState(initialOptions)
  const [ isLoading, setIsLoading ] = useState(false)

  const {
    onChange: onSelectChange,
    hasNoneOption = false,
    hasAutoComplete = false,
    minLength,
    maxLength,
    operationParameters,
    ...otherInputProps
  } = inputProps

  const additionalOptions = useMemo(() => {
    const result = []

    if (extraOptions) {
      result.push(...extraOptions)
    }

    if (hasNoneOption) {
      result.push({ name: LABEL_NONE, id: "" })
    }

    return result
  }, [
    extraOptions,
    hasNoneOption
  ])

  useEffect(request => {

    if (!operation) {
      return
    }

    setIsLoading(true)
    request(operation, operationParameters)
      .then(({ data }) => setOptions([ ...data, ...additionalOptions ]))
      .then(() => setIsLoading(false))
  }, [
    operation,
    additionalOptions,
    operationParameters
  ])

  useEffect(() => {
    setOptions(initialOptions)
  }, [ initialOptions ])

  const extraInputProps = {
    allowClear: true,
    placeholder: DEFAULT_SELECT_PLACEHOLDER,
  }

  if (isMultiple) {
    extraInputProps.mode = "multiple"
    extraInputProps.placeholder = DEFAULT_SELECT_MULTIPLE_PLACEHOLDER
  }

  if (operation) {
    extraInputProps.fieldNames = {
      value: 'id',
      label: 'name',
    }
  }

  const validateTrigger = hasAutoComplete
    ? "onChange"
    : "onClick"

  const filterOption = (input, option) =>
    (option?.label ?? '')
      .toLowerCase()
      .includes(input.toLowerCase())

  const onChange = value =>
    onSelectChange
      ? onSelectChange(value, form, options)
      : {}

  const autoCompleteFilterOptions = (input, option) =>
    (option?.value ?? '')
      .toLowerCase()
      .includes(input.toLowerCase())

  return (
    <Form.Item
      {...itemProps}
      validateTrigger={validateTrigger}
    >
      {
        hasAutoComplete && (
          <AutoComplete
            {...extraInputProps}
            minLength={minLength}
            maxLength={maxLength}
            loading={isLoading}
            options={options}
            disabled={isLoading}
            onChange={onChange}
            showSearch={true}
            filterOption={autoCompleteFilterOptions}
            {...otherInputProps}
          />
        )
      }

      {
        !hasAutoComplete && (
          <Select
            {...extraInputProps}
            loading={isLoading}
            options={options}
            disabled={isLoading}
            onChange={onChange}
            showSearch={true}
            filterOption={filterOption}
            {...otherInputProps}
          />
        )
      }
    </Form.Item>
  )
}

SelectInput.propTypes = {
  form: FormShape.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(SelectOptionShape),
    PropTypes.arrayOf(PropTypes.string)
  ]),
  operation: OperationShape,
  itemProps: ItemPropsShape.isRequired,
  inputProps: InputPropsShape.isRequired,
  isMultiple: PropTypes.bool.isRequired,
  extraOptions: PropTypes.arrayOf(SelectExtraOptionsShape),
}

export default SelectInput
