import React from "react"
import PropTypes from "prop-types"

import { ProgressModal } from "@components/Page"

import useDistributionsReport from "../DistributionsReportModal/helpers/useDistributionsReport"

const LABEL_TITLE = "Failed Distributions Report"


const FailedDistributionsReportModal = ({ modal }) => {
  const onReady = () => modal.current.close()

  const [ percent, start, stop ] = useDistributionsReport(onReady, true)

  const modalProps = {
    title: LABEL_TITLE,
    stop,
    modal,
    start,
    percent,
  }

  return (
    <ProgressModal {...modalProps} />
  )
}

FailedDistributionsReportModal.propTypes = {
  modal: PropTypes.shape().isRequired
}

export default FailedDistributionsReportModal
