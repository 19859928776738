import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import List from "@components/List"
import { indexOperation } from "@components/Store/InvestorStore/useTransactionsStore"
import { useProjectItems } from "@components/Project"
import InvestmentsStatistics from "@modules/investor/InvestorDashboardPage/InvestmentsStatistics"

import useRenderItem from "./helpers/useRenderItem"
import TransactionsStatistics from "./TransactionsStatistics"


const TransactionsList = ({ title }) => {
  const {
    project,
    transactions,
    indexTransactions,
  } = useOutletContext()

  const items = useProjectItems(transactions)
  const render = useRenderItem()

  const banner = project
    ? <InvestmentsStatistics hasContainer={false} />
    : <TransactionsStatistics />

  const sortedItems =  useMemo(() =>
    items.sort((a, b) => a.date > b.date ? -1 : 1)
  , [ items ])

  return (
    <List
      title={title}
      items={sortedItems}
      render={render}
      banner={banner}
      indexItems={indexTransactions}
      indexOperation={indexOperation}
    />
  )
}

TransactionsList.propTypes = {
  title: PropTypes.string.isRequired,
}

export default TransactionsList
