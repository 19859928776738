import PropTypes from "prop-types"


const properties = {
  body: PropTypes.string,
  ccAddress: PropTypes.string,
  fromAddress: PropTypes.string,
  fromName: PropTypes.string,
  html: PropTypes.string,
  replyToAddress: PropTypes.string,
  subject: PropTypes.string.isRequired,
  toAddress: PropTypes.string.isRequired
}

const EmailMessageShape = PropTypes.exact(properties)

export default EmailMessageShape
export { properties }
