import React from "react"

import { W0, W1 } from "@components"
import { SubmitButton } from "@components/Form"
import { TYPE_UPLOAD, TYPE_RADIO } from "@components/Form/FormItem/types"
import { Markdown, MarkdownAlert, Disclaimer } from "@components/Text"

import { getConfig } from "@components/Config"
import validateFileSize from "./validateFileSize"
import getPersonalTestScenarioInput from "./getPersonalTestScenarioInput"
import { FILE_TYPES, DOCUMENT_TYPES } from "./Constants"
import getFailedReasons, { LABEL_SUPPORTED_DOCUMENTS_INDIVIDUAL } from "./getFailedReasons"

const LABEL_PERSONAL_SUBTITLE = `Your identity must be confirmed before
sending funds.

Please upload the identifying document such as a state-issued driver's license,
or U.S. government-issued photo ID card.

${LABEL_SUPPORTED_DOCUMENTS_INDIVIDUAL}
`

const ENVIRONMENT_LABEL = getConfig('ENVIRONMENT_LABEL')
const isDevelopment = ENVIRONMENT_LABEL === "dev"

const LABEL_TYPE = "Document Type"
const LABEL_FILE = "File"
const LABEL_CONTINUE = "Continue"
const LABEL_FILE_INPUT = "File format: JPEG or PNG, maximum size: 10 megabytes"
const LABEL_DOCUMENT_TYPE = "Must be a color scan of U.S. government-issued identification"


const getPersonalInputs = (customer, form, showErrorMessage) => {
  const { identityVerificationFailedReasons = [] } = customer

  const hasVerificationFailedReasons = identityVerificationFailedReasons.length > 0

  const text = LABEL_PERSONAL_SUBTITLE

  const reasons = getFailedReasons(identityVerificationFailedReasons, 'INDIVIDUAL')

  const scenarioInputs = isDevelopment
    ? [ getPersonalTestScenarioInput() ]
    : []

  const inputs = [
    {
      name: "_identityDocuments",
      component: ({ style }) => (
        <div style={style}>
          {
            hasVerificationFailedReasons && (
              <MarkdownAlert text={reasons} type="warning" />
            )
          }

          {
            !hasVerificationFailedReasons && (
              <Markdown text={text} />
            )
          }
        </div>
      )
    },
    {
      name: "identityDocumentType",
      type: TYPE_RADIO,
      label: LABEL_TYPE,
      options: DOCUMENT_TYPES,
      itemProps: {
        style: { marginBottom: W1, marginTop: W1 },
        extra: <Disclaimer text={LABEL_DOCUMENT_TYPE} style={{ marginTop: W0 }} />
      },
    },
    {
      name: "identityDocumentUrl",
      type: TYPE_UPLOAD,
      label: LABEL_FILE,
      inputProps: {
        isPublic: false,
        beforeUpload: file => validateFileSize(file, showErrorMessage),
        fileTypes: FILE_TYPES,
      },
      itemProps: {
        extra: <Disclaimer text={LABEL_FILE_INPUT} style={{ marginTop: W0 }} />
      }
    },
    ...scenarioInputs,
    {
      name: "_submit",
      component: () => {
        const isLoading = form.isSubmitting()

        return (
          <SubmitButton
            style={{ marginBottom: W0 }}
            isLoading={isLoading}
          >
            {LABEL_CONTINUE}
          </SubmitButton>
        )
      }
    }
  ]

  return inputs
}

export default getPersonalInputs
