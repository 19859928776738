import React from "react"
import { Button } from "antd"
import { UserSwitchOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"
import { useAppContext } from "@components/AppContext"

import { AccountShape } from "../shapes"
import isSignInAsEnabled from "./helpers/isSignInAsEnabled"
import useSignInAsConfirm from "./helpers/useSignInAsConfirm"


const LABEL_TITLE = "Login As"


const SignInAsButton = ({ account }) => {
  const canLoginAsInvestor = hasAccess(['accounts-impersonate'])

  const { identity } = useAppContext()

  const signInAsConfirm = useSignInAsConfirm(account)

  const shouldRender =
    canLoginAsInvestor &&
    !!account &&
    !!account.investor.email

  if (!shouldRender) {
    return null
  }

  const onClick = () =>
    signInAsConfirm()

  const isDisabled = !isSignInAsEnabled(identity)

  return (
    <Button
      type="text"
      icon={<UserSwitchOutlined />}
      onClick={onClick}
      disabled={isDisabled}
    >
      {LABEL_TITLE}
    </Button>
  )
}

SignInAsButton.propTypes = {
  account: AccountShape.isRequired,
}

export default SignInAsButton
