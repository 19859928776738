import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import { Markdown } from "@components/Text"
import { InvestmentShape } from "@components/Investment/shapes"
import { getCurrencyValue } from "@components/Amount"
import { UnderstandConfirmModal } from "@components/Page"

const LABEL_TITLE = "Securely Link Bank Account"

const LABEL_STEP1 = `You will see a prompt from Plaid (a third party banking
service provider that works with most bank institutions in the United States)
that will request you to sign into your bank account.`

const LABEL_STEP2 = `Once you sign in, you will select the bank account that
you want to send your investment amount from. Please note that these funds are
sent electronically through ACH.

This means that the funds are pulled from your account within 1-2 business days
and are received by your Sponsor within an additional 3-5 business days.

The total time to transfer the funds should take 4-7 business days in total
(not including holidays and weekends).

If you send funds today, your funds will most likely arrive by [DATE].`

const LABEL_STEP3 = `Once you connect your account, the Plaid popup window
should disappear. Once you're back to this screen, click on "Send Funds" in
the bottom right hand corner to send your funding amount.

Once you click "Send Funds", a confirmation window will pop up to confirm the
amount you would like to invest. Click the blue "Send [AMOUNT]" to initiate the
transfer.`


const ConnectPlaidConfirmModal = ({ modal, investment }) => {
  const {
    totalAmount,
    receivedAmount,
    isPartiallyFunded,
  } = investment

  const amount = isPartiallyFunded
    ? totalAmount - receivedAmount
    : totalAmount

  const currencyAmount = getCurrencyValue(amount)

  const labelStep2 = LABEL_STEP2
    .replace('[DATE]', dayjs().add(9, 'day').format('MM/DD/YYYY'))

  const labelStep3 = LABEL_STEP3
    .replace('[AMOUNT]', currencyAmount)

  const content = [
    {
      title: 'Step 1',
      content: <Markdown text={LABEL_STEP1} />,
    },
    {
      title: 'Step 2',
      content: <Markdown text={labelStep2} />,
    },
    {
      title: 'Step 3',
      content: <Markdown text={labelStep3} />,
    },
  ]

  const modalProps = {
    modal,
    title: LABEL_TITLE,
    content
  }

  return (
    <UnderstandConfirmModal
      {...modalProps}
    />
  )
}

ConnectPlaidConfirmModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  investment: InvestmentShape.isRequired,
}

export default ConnectPlaidConfirmModal
