import React from "react"
import capitalize from "lodash.capitalize"
import { Typography } from "antd"
import { Link, useOutletContext } from "react-router-dom"

import { hasAccess } from "@components/Authorization"
import { Value } from "@components/Text"
import { TableRowActions } from "@components/Table"

import getRowMenu from "./getRowMenu"
import computePropertyStatus from "./computePropertyStatus"

const { Link: TextLink } = Typography

const PROJECT_PATH = "/backstage/project"

const LABEL_PROJECT = "Project"


const useColumnsConfig = (openPropertyModal, openUpdateModal, onDelete) => {
  const {
    projects,
    getProject,
    getProjectName
  } = useOutletContext()

  const getPropertiesProject = propertyId => {
    const [ project ] = projects
      .filter(({ investmentSubjectIds }) => investmentSubjectIds.includes(propertyId))

    return project
  }

  const getPropertyProjectName = (projectId, propertyId) => {
    if (projectId) {
      return getProjectName(projectId)
    }

    const project = getPropertiesProject(propertyId)

    if (project) {
      return project.name
    }

    return ""
  }

  const getProjectPath = (projectId, propertyId) => {
    if (projectId) {
      return `${PROJECT_PATH}/${projectId}/details`
    }

    const project = getPropertiesProject(propertyId)

    if (project) {
      return `${PROJECT_PATH}/${project.id}/details`
    }

    return ""
  }

  const nameColumnProps = {
    key: "name",
    isSearchable: true,
    render: (name, { id }) =>
      <TextLink strong onClick={() => openPropertyModal(id)}>
        {name}
      </TextLink>
  }

  const projectColumnProps = {
    key: "projectId",
    title: LABEL_PROJECT,
    isSearchable: true,
    compute: ({ projectId, id: propertyId }) => getPropertyProjectName(projectId, propertyId),
    render: (projectName, { projectId, id: propertyId }) =>
      <Link to={getProjectPath(projectId, propertyId)}>
        {projectName}
      </Link>
  }

  const addressColumnProps = {
    key: "address",
    compute: ({ address }) =>
      [
        address.streetAddress,
        address.addressLocality,
        `${address.addressRegion} ${address.postalCode}`
      ].join(', '),
    isSearchable: true,
  }

  const investmentDateColumnProps = {
    key: "investmentDate",
    render: value => <Value value={value} />
  }

  const statusColumnProps = {
    key: "status",
    compute: property =>
      capitalize(computePropertyStatus(property, getProject))
  }

  const columns = [
    { ...nameColumnProps, width: "auto", widthAutoPadding: 30 },
    { ...projectColumnProps, width: "auto", widthAutoPadding: 30 },
    { ...addressColumnProps },
    { ...investmentDateColumnProps, width: 150 },
    { ...statusColumnProps, width: 150 },
  ]

  const canUpdateProperty = hasAccess(['projects-write'])

  if (!canUpdateProperty) {
    return columns
  }

  const actionsColumnProps = {
    key: "actions",
    title: false,
    width: 48,
    render: (_, property) =>
      <TableRowActions
        items={getRowMenu({
          projects,
          property,
          onDelete,
          getProject,
          openUpdateModal,
        })}
      />
  }

  return [
    ...columns,
    { ...actionsColumnProps }
  ]
}

export default useColumnsConfig
