import { useEffect, useState } from "react"
import { useOutletContext, useLocation, useParams } from "react-router-dom"

import { useAppContext, runRequest } from "@components/AppContext"
import { readMyInvestmentOperation as readInvestmentOperation } from "@api/services/investments"

const CHECK_INVESTMENT_INTERVAL = 5000
const SIGNED_EVENT = "signing_complete"


const useInvestmentWatch = () => {
  const location = useLocation()
  const { request } = useAppContext()
  const { investmentId } = useParams()
  const { updateInvestment } = useOutletContext()

  const [ isPendingStatusUpdate, setIsPendingStatusUpdate ] = useState()

  const queryParams = new URLSearchParams(location.search)
  const event = queryParams.get('event')
  const isSignedEvent = event === SIGNED_EVENT

  const shouldStartWatch = isPendingStatusUpdate === undefined

  useEffect(() => {
    if (!investmentId) {
      return
    }

    if (!shouldStartWatch) {
      return
    }

    if (!isSignedEvent) {
      return runRequest(() => {
        request(readInvestmentOperation, { id: investmentId })
          .then(({ data }) => {
            updateInvestment(data)
            setIsPendingStatusUpdate(false)
          })
      })
    }

    return runRequest(() => {
      setIsPendingStatusUpdate(true)
      const timer = setInterval(() => {
        request(readInvestmentOperation, { id: investmentId })
          .then(({ data }) => {
            const shouldStopWatch = data.isPartiallySigned

            if (shouldStopWatch) {
              setIsPendingStatusUpdate(false)
              clearInterval(timer)
            }

            updateInvestment(data)
          })
      }, CHECK_INVESTMENT_INTERVAL)
    })
  }, [
    request,
    investmentId,
    isSignedEvent,
    updateInvestment,
    shouldStartWatch,
  ])

  return isPendingStatusUpdate
}

export default useInvestmentWatch
