import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Space, Spin } from "antd"
import { useOutletContext } from "react-router-dom"
import { PrinterOutlined, CheckOutlined } from "@ant-design/icons"

import { W1 } from "@components"
import { Modal } from "@components/Page"
import { BankAccount } from "@modules/backstage/bankAccounts"
import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"
import { getUsDateFromDate } from "@components/Date"
import { isSdiraInvestment } from "@components/Investment"
import { getFormattedDecimalValue } from "@components/Amount"
import { IndexPublishedProjectsOutputShape } from "@api/services/backstage"
import { Value, Markdown, formatPhoneNumber } from "@components/Text"
import { ReadMyFundingBankAccountOutputShape } from "@api/services/transactions"
import { updateMyInvestmentFundingStatusOperation } from "@api/services/investments"

import SdiraFundingAlert from "./SdiraFundingAlert"

import "./WireModal.css"

const LABEL_PRINT = "Print"
const LABEL_TITLE = "Fund Your Investment"
const LABEL_AMOUNT = "Amount"
const LABEL_FUND_SENT = "I have sent funds"
const LABEL_SUBMIT_WIRE_MODAL = "My custodian has confirmed that investment funds are sent"

const LABEL_INSTRUCTIONS = `
## [TITLE]

* Funding should be made via wire transfer between now through **[FUNDING_DATE]**.
* Please add **[REFERENCE_NOTE]** in the wiring instructions for identification
  purposes.
* Please note that it may take between 24 - 48 hours for us to confirm your
  investment funds as received. Once confirmed as received, we will provide you
  with an email confirmation immediately.
`

const LABEL_INSTRUCTIONS_PHONE_NUBMER = `* To confirm wiring instructions,
please call Investor Relations at **[PHONE_NUMBER]**.
`


const WireModal = ({
  fundingBankAccount = undefined,
  modal,
  project,
  investment,
}) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)

  const { updateInvestment } = useOutletContext()
  const { request, getOrganization } = useAppContext()

  const onClose = () =>
    setIsOpen(false)

  modal.current.open = () =>
    setIsOpen(true)

  const organization = getOrganization()

  const {
    id: organizationId,
    phoneNumber
  } = organization

  const isSdira = isSdiraInvestment(investment)

  const {
    id: investmentId,
    name: investmentName,
    totalAmount,
    hasSentFunds,
    receivedAmount,
    isPartiallyFunded,
  } = investment

  const {
    id: projectId,
    name: projectName,
    fundDeadlineDate,
  } = project

  const confirmFundsSent = () => {
    const parameters = {
      id: investmentId,
      mutation: { hasSentFunds: true }
    }

    setIsLoading(true)
    request(updateMyInvestmentFundingStatusOperation, parameters)
      .then(({ data }) => updateInvestment(data))
      .then(() => onClose())
      .finally(() => setIsLoading(false))
  }

  const referenceNote = `${organizationId}-${projectId}-${investmentId} — ${projectName} — ${investmentName}`

  let instructions = LABEL_INSTRUCTIONS
    .replace(`[TITLE]`, projectName)
    .replace(`[FUNDING_DATE]`, getUsDateFromDate(fundDeadlineDate))
    .replace(`[REFERENCE_NOTE]`, referenceNote)

  if (phoneNumber) {
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber)
    instructions = `${instructions}${LABEL_INSTRUCTIONS_PHONE_NUBMER}`
      .replace(`[PHONE_NUMBER]`, formattedPhoneNumber)
  }

  const openPrintModal = () =>
    window.print()

  const fundingAmount = isPartiallyFunded
    ? getFormattedDecimalValue(totalAmount, receivedAmount, "minus")
    : totalAmount

  const submitLabel = isSdira
    ? LABEL_SUBMIT_WIRE_MODAL
    : LABEL_FUND_SENT

  const extraFooter = isSdira
    ? <SdiraFundingAlert style={{ marginTop: W1 }} />
    : undefined

  const extraItems = [{
    label: LABEL_AMOUNT,
    value: <Value value={fundingAmount} />
  }]

  const isSpinning = !fundingBankAccount

  const footer =
    <Space>
      <Button
        icon={<PrinterOutlined />}
        shape="round"
        onClick={openPrintModal}
      >
        {LABEL_PRINT}
      </Button>

      <Button
        type="primary"
        icon={<CheckOutlined />}
        shape="round"
        onClick={confirmFundsSent}
        loading={isLoading}
        disabled={hasSentFunds}
      >
        {submitLabel}
      </Button>
    </Space>

  return (
    <Modal
      width={640}
      title={LABEL_TITLE}
      footer={footer}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Spin spinning={isSpinning}>
        <Markdown
          text={instructions}
        />

        {
          fundingBankAccount && (
            <BankAccount
              isWire={true}
              extraItems={extraItems}
              bankAccount={fundingBankAccount}
              referenceNote={referenceNote}
            />
          )
        }

        {extraFooter}
      </Spin>
    </Modal>
  )
}

WireModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  project: IndexPublishedProjectsOutputShape.isRequired,
  investment: InvestmentShape.isRequired,
  fundingBankAccount: ReadMyFundingBankAccountOutputShape
}

export default WireModal
