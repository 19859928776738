import PropTypes from "prop-types"

import AssetShape from "./shapes/AssetShape"
import TagShape from "./shapes/TagShape"
import UsAddressShape from "./shapes/UsAddressShape"
import { PropertyStatusEnumKeys } from "./shapes/PropertyStatusEnum"

const properties = {
  address: UsAddressShape.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  equityDeployedAmount: PropTypes.number,
  id: PropTypes.string.isRequired,
  investmentDate: PropTypes.string,
  name: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(AssetShape),
  projectId: PropTypes.string,
  status: PropTypes.oneOf(PropertyStatusEnumKeys),
  tags: PropTypes.arrayOf(TagShape),
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const CreatePropertyOutputShapeShape = PropTypes.exact(properties)

export default CreatePropertyOutputShapeShape
export { properties }
