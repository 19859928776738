import { useOutletContext } from "react-router-dom"

import { today } from "@components/Date"

const TAG_NAME = "Import - [DATE] - [LIST_NAME]"


const useOnSubmit = () => {
  const {
    createSegment,
    ensureAccountTagTypeOption,
  } = useOutletContext()

  return async parameters => {
    const {
      tagIds = [],
      '_listName': listName
    } = parameters.mutation

    const hasListName = listName && listName.length > 0

    if (hasListName) {
      const tagName = TAG_NAME
        .replace("[DATE]", today)
        .replace("[LIST_NAME]", listName)

      const { id: tagId } = await ensureAccountTagTypeOption(tagName)

      const description = `tagIds:${tagId}`
      await createSegment(listName, description)

      parameters.mutation.tagIds = [
        ...tagIds,
        tagId
      ]
    }

    parameters.mutation.shouldValidateOnly = false

    return parameters
  }
}

export default useOnSubmit
