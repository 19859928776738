import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { Spin, Layout, Row, theme } from "antd"

import { getResizedImageUrl } from "@components/Storage"
import { usePublicOrganizationContext } from "@components/AppContext"
import { W1, W3, W5, LOGO_HEIGHT, isMobile } from "@components"
import { ContactUs, PlatformFooter, InvestmentHeader } from "@components/Page"

import Error from "./Error"
import Success from "./Success"
import usePublishedProject from "./helpers/usePublishedProject"
import CreateCommitmentForm from "./CreateCommitmentForm"

const { Content } = Layout

const LABEL_SUBTITLE = "RESERVE A SPOT"


const CreateCommitmentPage = () => {
  const { token } = theme.useToken()
  const { colorBgContainer } = token

  const [ commitment, setCommitment ] = useState()

  const { projectId, sponsorId } = useParams()

  const [ project, error ] = usePublishedProject(projectId, sponsorId)

  const { publicOrganization } = usePublicOrganizationContext()

  const {
    logoUrl,
    id: organizationId,
    name: organizationName
  } = publicOrganization

  const isLoading =
    !error &&
    project === undefined

  const shouldShowForm =
    !error &&
    !commitment &&
    !!project

  const shouldShowSuccess =
    !error &&
    !!commitment

  const onSuccess = setCommitment

  const layoutStyle = {
    minHeight: "100vh",
    background: colorBgContainer,
    paddingTop: W3,
  }

  const contentStyle = {
    marginLeft: W1,
    marginRight: W1,
    marginBottom: isMobile ? W5 * 3 : 0,
  }

  return (
    <Layout style={layoutStyle}>
      <Spin spinning={isLoading}>
        <Content style={contentStyle}>

          <Row style={{ marginBottom: W3 }} justify="center">
            {
              logoUrl && (
                <img
                  src={getResizedImageUrl(logoUrl, null, 2 * LOGO_HEIGHT)}
                  alt={organizationName}
                  height={LOGO_HEIGHT}
                />
              )
            }
          </Row>

          <Row justify="center">
            <div style={{ width: "100%", maxWidth: 520 }}>
              {
                error && (
                  <Error {...error} />
                )
              }

              {
                !error && !isLoading && (
                  <InvestmentHeader
                    title={project.name}
                    style={{ marginBottom: W1 * 1.5 }}
                    subtitle={LABEL_SUBTITLE}
                  />
                )
              }

              {
                shouldShowSuccess && (
                  <Success
                    project={project}
                    commitment={commitment}
                  />
                )
              }

              {
                shouldShowForm && (
                  <CreateCommitmentForm
                    project={project}
                    onSuccess={onSuccess}
                  />
                )
              }
            </div>
          </Row>

          <ContactUs
            isGuest={true}
            projectId={projectId}
            organizationId={organizationId}
          />

          <PlatformFooter />
        </Content>
      </Spin>
    </Layout>
  )
}

export default CreateCommitmentPage
