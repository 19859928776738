import React, { useState, useEffect } from "react"
import { Form } from "antd"
import PropTypes from "prop-types"
import {useOutletContext} from "react-router-dom"

import { DRAWER_WIDTH } from "@components"
import { UpdateDrawer } from "@components/Page"
import { updateBatchOperation as updateOperation } from "@api/services/backstage"

import getInputs from "./helpers/getInputs"

const LABEL_ENTITY = "Batch"
const LABEL_TITLE = `Edit ${LABEL_ENTITY}`
const LABEL_SUBMIT = "Save"


const BatchUpdateDrawer = ({ drawer, table, onSuccess }) => {
  const [ form ] = Form.useForm()

  const { batchDocumentTypeOptions, documentTypeOptions } = useOutletContext()

  const [ schema, setSchema ] = useState([])
  const [ sentAt, setSentAt ] = useState()
  const [ fileSourceFileName, setFileSourceFileName ] = useState()

  const isSent = Form.useWatch("isSent", form)

  useEffect(() => {
    const documentTypes = !!fileSourceFileName
      ? documentTypeOptions
      : batchDocumentTypeOptions

    const typeOptions = documentTypes
      .map(name => ({ label: name, value: name }))

    const inputs = getInputs(sentAt, isSent, typeOptions)
    const newSchema = updateOperation.getSchema(inputs)

    setSchema(newSchema)
  }, [
    isSent,
    sentAt,
    fileSourceFileName,
    documentTypeOptions,
    batchDocumentTypeOptions,
  ])

  const onOpen = batch => {
    setFileSourceFileName(batch.fileSourceFileName)
    setSentAt(batch.sentAt)
  }

  const updateDrawerProps = {
    title: LABEL_TITLE,
    width: DRAWER_WIDTH,
    entity: LABEL_ENTITY,
    operation: updateOperation,
    submitLabel: LABEL_SUBMIT,
    form,
    table,
    drawer,
    schema,
    onOpen,
    onSuccess,
  }

  return (
    <UpdateDrawer {...updateDrawerProps} />
  )
}

BatchUpdateDrawer.propTypes = {
  table: PropTypes.shape().isRequired,
  drawer: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default BatchUpdateDrawer
