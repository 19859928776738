import React from "react"

import { getConfig } from "@components/Config"
import { MarkdownAlert } from "@components/Text"

import getFailedReasons, { LABEL_SUPPORTED_DOCUMENTS_BUSINESS_EXTRA } from "./getFailedReasons"

const PLATFORM_EMAIL = getConfig('PLATFORM_EMAIL')


const getExtraBusinessDocumentsInput = (customer) => {
  const {
    isExtraBusinessDocumentRequested = false,
    businessVerificationFailedReasons = [],
  } = customer

  if (!isExtraBusinessDocumentRequested) {
    return {}
  }

  const reasons = getFailedReasons(businessVerificationFailedReasons, "EXTRA_BUSINESS")
  const hasVerificationFailedReasons = businessVerificationFailedReasons.length > 0
  const text = LABEL_SUPPORTED_DOCUMENTS_BUSINESS_EXTRA.replace("[PLATFORM_EMAIL]", `[${PLATFORM_EMAIL}](mailto:${PLATFORM_EMAIL})`)

  return {
    name: "_businessDocuments",
    component: ({ style }) => (
      <div style={style}>
        {
          hasVerificationFailedReasons && (
            <MarkdownAlert text={reasons} type="warning" />
          )
        }

        {
          !hasVerificationFailedReasons && (
            <MarkdownAlert text={text} type="info" />
          )
        }
      </div>
    )
  }
}

export default getExtraBusinessDocumentsInput
