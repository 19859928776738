import React from "react"
import PropTypes from "prop-types"
import { FloatButton, Modal } from "antd"

const LABEL_CONTACT_US = "Need help? Contact us."


const ContactUsMobile = ({
  icon,
  isOpen,
  onClick,
  children
}) => {
  const type = "default"
  const shape = "square"
  const className = "contact-us-mobile"

  return (
    <>
      <FloatButton
        icon={icon}
        type={type}
        shape={shape}
        onClick={onClick}
        className={className}
        description={LABEL_CONTACT_US}
      />

      <Modal
        open={isOpen}
        footer={null}
        onCancel={onClick}
        maskClosable={false}
      >
        {children}
      </Modal>
    </>
  )
}

ContactUsMobile.propTypes = {
  icon: PropTypes.shape().isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired
}

export default ContactUsMobile
