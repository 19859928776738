import React from "react"
import PropTypes from "prop-types"
import { Table, Typography } from "antd"

import getTableColumns from "./helpers/getTableColumns"
import { ExtendedProjectShape } from "../helpers/useGroupedProjects"

const { Title } = Typography


const ProjectsTable = ({
  title,
  items,
}) => {
  const size = "middle"
  const rowKey = "id"
  const columns = getTableColumns()
  const className = "table-custom-header"
  const pagination = false
  const dataSource = items

  const tableProps = {
    size,
    rowKey,
    columns,
    className,
    pagination,
    dataSource,
  }

  return (
    <>
      <div style={{ padding: "0 8px" }}>
        <Title level={3}>{title}</Title>
      </div>

      <Table {...tableProps} />
    </>
  )
}

ProjectsTable.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(ExtendedProjectShape).isRequired,
}

export default ProjectsTable
