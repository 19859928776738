import React from "react"
import PropTypes from "prop-types"
import { Select } from "antd"


const FilterSelect = ({
  filterMultiple = false,
  ...otherSelectProps
}) => {
  const style = {
    minWidth: 200
  }

  const filterOption = (input, option) =>
    (option?.label ?? '')
      .toLowerCase()
      .includes(input.toLowerCase())

  const selectProps = {
    variant: "filled",
    showSearch: true,
    allowClear: true,
    optionFilterProp: "children",
    style,
    filterOption,
    ...otherSelectProps,
  }

  if (filterMultiple) {
    selectProps.mode = "multiple"
  }

  return (
    <Select {...selectProps} />
  )
}

FilterSelect.propTypes = {
  filterMultiple: PropTypes.bool,
}

export default FilterSelect
