import React, { useEffect, useState, useCallback } from "react"
import { useOutletContext } from "react-router-dom"

import { useWatch } from "@components/Form"
import { createAccountOperation as operation } from "@api/services/investments"

import getInputs from "./getInputs"
import InvestorExistsAlert from "../InvestorExistsAlert"


const useSchema = (form, openInvestorDrawer) => {
  const [ schema, setSchema ] = useState([])

  const {
    activeBrokers,
    referralSourceOptions
  } = useOutletContext()

  const investorEmailValue = useWatch([ "investor", "email" ], form)

  const updateSchema = useCallback(() => {
    let alert

    if (investorEmailValue) {
      alert = <InvestorExistsAlert
        email={investorEmailValue}
        openInvestorDrawer={openInvestorDrawer}
      />
    }

    const inputs = getInputs(activeBrokers, referralSourceOptions, alert)
    const newSchema = operation.getSchema(inputs)

    setSchema(newSchema)
  }, [
    activeBrokers,
    openInvestorDrawer,
    investorEmailValue,
    referralSourceOptions
  ])

  useEffect(() => {
    updateSchema()
  }, [ updateSchema ])

  return schema
}

export default useSchema
export { operation }
