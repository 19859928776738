import { TYPE_BOOLEAN, TYPE_SSN } from "@components/Form"

import InvestorSsnNumber from "../InvestorSsnNumber"
import computeLiabilityInputs from "./computeLiabilityInputs"
import computeResidencyInputs from "./computeResidencyInputs"


const getProfileIndividualSchema = (organization, updateSchema, getInvestorSsnNumber, profile = {}) => {
  const { email: organizationEmail } = organization

  let ssnType = TYPE_SSN
  const { hasSsn, isUsPerson, isUsCitizen } = profile
  const isNotUsPerson = !isUsCitizen && isUsPerson === false

  if (!hasSsn) {
    ssnType = "none"
  }

  const labels = {
    isUsPerson: "Are you considered a US person for federal tax purposes, as defined by the IRS?",
    hasSsn: isNotUsPerson ? "Do you have a US TIN" : "Do you have an SSN?",
  }
  const residencyInputs = computeResidencyInputs(profile, updateSchema, labels, organizationEmail)
  const liabilityInputs = computeLiabilityInputs(updateSchema, organization)

  const inputs = [
    ...liabilityInputs,
    {
      name: "isMarriedAndSeparateProperty",
      type: TYPE_BOOLEAN,
      label: "Title of membership interests",
      required: true,
      inputProps: {
        vertical: "true",
        options: [
          {
            label: "Individually, as a single person",
            value: "false"
          },
          {
            label: "A married person, as my sole and separate property",
            value: "true"
          }
        ]
      }
    },
    {
      name: "isUsCitizen",
      label: "Are you a US citizen?",
      required: true,
      inputProps: {
        onChange: () => updateSchema()
      }
    },
    ...residencyInputs,
    {
      name: "ssnNumber",
      component: InvestorSsnNumber,
      type: ssnType,
      componentProps: {
        required: true,
        itemProps: {
          initialValue: getInvestorSsnNumber(),
          label: isNotUsPerson ? "Your US TIN" : "Your Social Security Number",
        }
      }
    },
  ]

  return inputs
}

export default getProfileIndividualSchema
