
const getFundName = (fundsMap, partnersMap, partnerId) => {
  const partner = partnersMap[partnerId]

  if (!partner) {
    return partnerId
  }

  const { fundId } = partner
  const fund = fundsMap[fundId]

  if (!fund) {
    return partnerId
  }

  const { name } = fund

  return name
}

export default getFundName
