import { TYPE_STRING } from "@components/Form"

const LABEL_FILE = "List Name"
const ERROR_INVALID_LIST_NAME = "List with such name already exists"


const getExtraInputs = segments => {
  return [
    {
      name: "_listName",
      type: TYPE_STRING,
      required: false,
      label: LABEL_FILE,
      itemProps: {
        rules: [{
          message: ERROR_INVALID_LIST_NAME,
          validator: (_, value = "") => {
            const normalizedValue = value.toLowerCase()

            const hasListName = !!segments.find(({ name }) => name.toLowerCase() === normalizedValue)

            if (hasListName) {
              throw new Error(ERROR_INVALID_LIST_NAME)
            }

            return Promise.resolve()
          }
        }]
      }
    },
  ]
}

export default getExtraInputs
