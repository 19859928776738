import React, { useEffect, useCallback, useMemo } from "react"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { TimePicker, Form, Input } from "antd"

import { getIsoDateFromStringDate } from "@components/Date"
import { ItemPropsShape, InputPropsShape } from "@api"

import FormShape from "../shapes/FormShape"

const { Item } = Form
export const TIME_FORMAT = "h:mm a"


const UsTimeInput = ({
  form,
  path,
  itemProps,
  inputProps
}) => {
  const { name, initialValue, ...restItemProps } = itemProps
  const { onChange: onChangeCustom, ...restInputProps } = inputProps

  const inputField = `_${name.slice(-1)}`
  const inputName = [...name.slice(0, -1), inputField]
  const inputPath = useMemo(() => [...path.slice(0, -1), inputField], [path, inputField])

  const sourceValue = Form.useWatch(path, form)
  const inputSourceValue = Form.useWatch(inputName, form)

  const setValue = useCallback(() => {
    const value = form.getFieldValue(path)

    if (!value) {
      return
    }

    const [ hour, minute ] = value.split(':')
    const isoDate = `2024-01-01T${hour}:${minute}:00Z`

    form.setFieldValue(inputPath, isoDate)
  }, [form, inputPath, path ])

  useEffect(() => {
    setValue()
  }, [ sourceValue, inputSourceValue, setValue ])


  const onChange = dateObject => {
    if (!dateObject) {
      return form.setFieldValue(path, undefined)
    }

    const { $d } = dateObject

    const isoDate = getIsoDateFromStringDate($d, true)
    const [ , time ] = isoDate.split('T')
    const [ hour, minute ] = time.split(':')

    const timeString = `${hour}:${minute}`

    form.setFieldValue(path, timeString)

    if (onChangeCustom) {
      onChangeCustom(timeString, form)
    }
  }

  const format = TIME_FORMAT

  return (
    <>
      <Item
        key="hidden"
        name={name}
        hidden={true}
        initialValue={initialValue}
      >
        <Input />
      </Item>

      <Item
        name={inputName}
        getValueProps={value => ({value: value ? dayjs(value) : value})}
        {...restItemProps}
      >
        <TimePicker
          use12Hours
          format={format}
          onChange={onChange}
          {...restInputProps}
        />
      </Item>
    </>
  )
}

UsTimeInput.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemProps: ItemPropsShape.isRequired,
  inputProps: InputPropsShape.isRequired,
}

export default UsTimeInput
