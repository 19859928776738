import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import keyBy from "lodash.keyby"
import { getName } from "country-list"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { Space, Typography } from "antd"

import { parseDateTime } from "@components/Date"
import { US_STATE_OPTIONS } from "@components/Address"
import { getCurrencyValue } from "@components/Amount"

import isIsoDateTime from "./helpers/isIsoDateTime"
import formatPhoneNumber from "./helpers/formatPhoneNumber"

dayjs.extend(localizedFormat)

const { Text } = Typography

const EIN_REGEX = /^(\d{2})(\d{7})$/
const SSN_REGEX = /^(\d{3})(\d{2})(\d{4})$/
const DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})$/
const US_STATES_MAP = keyBy(US_STATE_OPTIONS, "value")


const renderNoWrap = (value, type, onClick) => {
  const noWrapSpan = (
    <span style={{ whiteSpace: "nowrap" }}>
      {value}
    </span>
  )

  if (onClick) {
    return <Typography.Link onClick={onClick}>{noWrapSpan}</Typography.Link>
  }

  return <Typography.Text type={type}>{noWrapSpan}</Typography.Text>
}


const FormattedValue = ({
  type = "",
  value = null,
  isEin = false,
  format = undefined,
  onClick = undefined,
  children = undefined
}) => {
  if (children) {
    return renderNoWrap(children, type, onClick)
  }

  const isNone = value === null || value === undefined

  if (isNone) {
    return <>N/A</>
  }

  const isObject = typeof value === "object"
  const isString = typeof value === "string"

  if (isObject) {
    const isAddress = !!value.addressCountry

    if (!isAddress) {
      return JSON.stringify(value)
    }

    const {
      postalCode,
      streetAddress,
      addressRegion,
      addressCountry,
      addressLocality,
    } = value

    const addressBlock = [addressLocality, addressRegion].filter(item => !!item).join(", ")
    const fullAddressBlock = [addressBlock, postalCode].filter(item => !!item).join(" ")

    const isUs = addressCountry === "US"

    const formattedAddress =
      <Space
        size={0}
        direction="vertical"
      >
        <Text type={type}>{streetAddress}</Text>
        <Text type={type}>{fullAddressBlock}</Text>

        { !isUs && <Text type={type}>{getName(addressCountry)}</Text> }
      </Space>

    return formattedAddress
  }

  if (isEin) {
    const ein = value.replace(EIN_REGEX, "$1-$2")
    return renderNoWrap(ein, type, onClick)
  }

  const isSsn = isString && SSN_REGEX.test(value)

  if (isSsn) {
    const ssn = value.replace(SSN_REGEX, "$1-$2-$3")
    return renderNoWrap(ssn, type, onClick)
  }

  const isState = !!US_STATES_MAP[value]

  if (isState) {
    return US_STATES_MAP[value].label
  }

  const isDateTime = isIsoDateTime(value)

  if (isDateTime) {

    if (format) {
      const formattedDateTime = dayjs(value).format(format)

      return renderNoWrap(formattedDateTime, type, onClick)
    }

    const [ date, time, zone ] = parseDateTime(value)

    return (
      <Space size={6}>
        <Typography.Text>{date}</Typography.Text>
        <Typography.Text type="secondary">{time.toLowerCase()} {zone}</Typography.Text>
      </Space>
    )
  }

  const isDate = isString && DATE_REGEX.test(value)

  if (isDate) {
    const usDate = value.replace(DATE_REGEX, "$2/$3/$1")
    return usDate
  }

  const isNumber = typeof value === "number"

  if (isNumber) {
    const currencyValue = getCurrencyValue(value)
    const formattedAmount = currencyValue.replace(".00", "")

    return renderNoWrap(formattedAmount, type, onClick)
  }

  const phoneNumber = formatPhoneNumber(value)

  if (phoneNumber) {
    return renderNoWrap(phoneNumber, type, onClick)
  }

  return (
    <>{value}</>
  )
}

FormattedValue.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, PropTypes.shape() ]),
  isEin: PropTypes.bool,
  format: PropTypes.string,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
}

export default FormattedValue
