import { computeInvestor } from "@modules/backstage/investments"
import { validateDecimalValue } from "@components/Amount"

const DECIMAL_COLUMNS = [
  'returnOnInvestment',
  'profitOnSale',
  'returnOfCapital',
  'sponsorPromote'
]


const getValidateInvestorTransactions = (investments, setErrors) => {
  return (distribution, rowIndex) => {
    const { profileName, email } = distribution

    const currentInvestment = investments.find(investment => {
      const { investor } = investment
      const isSameProfile = computeInvestor(investment) === profileName
      const isSameInvestorEmail = investor.email === email

      return isSameProfile && isSameInvestorEmail
    })

    if (!currentInvestment) {
      setErrors((prevItems = []) => ([
        ...prevItems,
        {
          index: rowIndex,
          reason: `Investment ${profileName} is not found`
        }
      ]))

      return false
    }

    for (const key of DECIMAL_COLUMNS) {
      const value = distribution[key]
      const isValid = validateDecimalValue(value)

      if (!isValid) {
        setErrors((prevItems = []) => ([
          ...prevItems,
          {
            index: rowIndex,
            reason: `${profileName} transaction amount has invalid format: $${value}`
          }
        ]))

        return false
      }
    }

    return true
  }
}

export default getValidateInvestorTransactions
