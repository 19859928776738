import React from "react"
import { Link, useOutletContext } from "react-router-dom"
import { Row, Col, Button } from "antd"

import { FUND } from "@api/services/backstage/shapes/ProjectTypeEnum"
import { CLOSED } from "@api/services/backstage/shapes/ProjectStateEnum"
import { W0, W1, W2, isMobile } from "@components"
import { computeProjectInvestmentsFlags } from "@components/Project"

const LABEL_CTA = "Add Another Investment"


const AddAnotherInvestmentButton = () =>  {
  const { project, getProjectInvestments } = useOutletContext()

  const { id: projectId, type, state } = project

  const isClosed = state === CLOSED

  const investments = getProjectInvestments(projectId)

  const { isFunded, isSigned } = computeProjectInvestmentsFlags(investments)

  const shouldRender = isFunded && isSigned

  const isNonFund = type !== FUND

  if (isNonFund) {
    return null
  }

  if (isClosed) {
    return null
  }

  if (!shouldRender) {
    return null
  }

  const createInvestmentUrl = `/investments/${projectId}/create`

  const containerStyle = {
    padding: `${W1 * 1.5}px 0`,
    background: "#ECF5F9",
    borderRadius: isMobile ? 0 : 4,
    marginBottom: W2,
  }

  return (
    <Row style={containerStyle} gutter={[ 0, W0 ]}>
      <Col span={24} align="center">
        <Link to={createInvestmentUrl}>
          <Button
            size="large"
            type="primary"
            shape="round"
          >
            {LABEL_CTA}
          </Button>
        </Link>
      </Col>
    </Row>
  )
}

export default AddAnotherInvestmentButton
