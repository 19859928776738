import { useMemo } from "react"
import PropTypes from "prop-types"

import { useAppContext } from "@components/AppContext"
import {
  indexPropertiesOperation as indexOperation,
  IndexPropertiesOutputShape,
} from "@api/services/backstage"

import useStore from "../helpers/useStore"


const usePropertiesStore = () => {
  const { request } = useAppContext()

  const shouldAutoload = false

  const {
    items: properties,
    addItem: addProperty,
    getItem: getProperty,
    updateItem: updateProperty,
    removeItem: deleteProperty,
    indexItems: indexProperties,
  } = useStore(indexOperation, request, shouldAutoload)

  const propertyOptions = useMemo(() =>
    (properties || [])
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ name: label, id: value }) => ({ value, label }))
  , [ properties ])

  return {
    properties,
    addProperty,
    getProperty,
    updateProperty,
    deleteProperty,
    indexProperties,
    propertyOptions
  }
}

const propertiesStoreProperties = {
  properties: PropTypes.arrayOf(IndexPropertiesOutputShape),
  addProperty: PropTypes.func.isRequired,
  getProperty: PropTypes.func.isRequired,
  updateProperty: PropTypes.func.isRequired,
  deleteProperty: PropTypes.func.isRequired,
  indexProperties: PropTypes.func.isRequired,
  propertyOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export default usePropertiesStore

export {
  propertiesStoreProperties
}
