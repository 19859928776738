import React from "react"
import PropTypes from "prop-types"
import { Form } from "antd"

import { CreateModal } from "@components/Page"
import { MODAL_WIDTH2 } from "@components"
import { useAppContext } from "@components/AppContext"
import { connectCustomSourceOperation } from "@api/services/transactions"

import getInputs from "./helpers/getInputs"


const LABEL_TITLE = "Connect Bank Account"
const LABEL_ENTITY = "Bank Account"
const LABEL_SUBMIT = "Connect"


const ConnectSourceModal = ({
  onSuccess: onSuccessCustom,
  title = LABEL_TITLE,
  header = undefined,
  modal,
  onExit,
  customerId,
}) => {
  const [ form ] = Form.useForm()

  const {
    authenticatedRequest,
    addIdentityCustomerSource,
  } = useAppContext()

  const inputs = getInputs(form, customerId, header)
  const schema = connectCustomSourceOperation.getSchema(inputs)

  const onSuccess = async (source) => {
    addIdentityCustomerSource(customerId, source)
    await onSuccessCustom(source.id, source)
    return onExit()
  }

  const createModalProps = {
    title,
    width: MODAL_WIDTH2,
    entity: LABEL_ENTITY,
    request: authenticatedRequest,
    operation: connectCustomSourceOperation,
    submitLabel: LABEL_SUBMIT,
    form,
    modal,
    onExit,
    schema,
    onSuccess,
  }

  return (
    <CreateModal
      {...createModalProps}
    />
  )
}

ConnectSourceModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  title: PropTypes.string,
  header: PropTypes.shape({}),
  onExit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
}

export default ConnectSourceModal
