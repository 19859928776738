import getTextWidth from "./getTextWidth"


const getComputeColumnWidth = (items, column, compute) => {
  const { width, title, widthAutoPadding = 10 } = column

  const shouldCompute = width === "auto"

  if (!shouldCompute) {
    return width
  }

  const hasItems = items.length > 0

  if (!hasItems) {
    return 0
  }

  let maxWidth = 0

  for (const item of items) {
    const computedValue = compute(item)
    const valueWidth = getTextWidth(computedValue) + widthAutoPadding

    maxWidth = maxWidth < valueWidth
      ? valueWidth
      : maxWidth
  }

  const titleWidth = getTextWidth(title) + widthAutoPadding
  maxWidth = maxWidth < titleWidth
    ? titleWidth
    : maxWidth

  return maxWidth
}

export default getComputeColumnWidth
