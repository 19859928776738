import React from "react"
import { UploadOutlined } from "@ant-design/icons"
import { useOutletContext } from "react-router-dom"

import { hasAccess } from "@components/Authorization"

const LABEL_UPLOAD_FILE = "Upload Project File"
const LABEL_UPLOAD_BATCH = "Upload Investor Files"


const useHeaderActions = (openFileUploadModal, openBatchUploadModal) => {
  const { investments = [] } = useOutletContext()

  const hasInvestments = investments.length > 0
  const canUploadDocuments = hasAccess(['documents-write'])

  if (!canUploadDocuments) {
    return []
  }

  const isDisabled = !hasInvestments

  return [
    {
      icon: <UploadOutlined />,
      title: LABEL_UPLOAD_FILE,
      action: openFileUploadModal,
      disabled: isDisabled,
    },
    {
      icon: <UploadOutlined />,
      title: LABEL_UPLOAD_BATCH,
      action: openBatchUploadModal,
      disabled: isDisabled,
    }
  ]
}

export default useHeaderActions
