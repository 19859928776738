

const isInvestmentDeleteable = investment => {
  const { isSigned, isPartiallySigned } = investment
  const { isFunded, isOverfunded, isPartiallyFunded, hasSentFunds } = investment

  if (isSigned) {
    return false
  }

  if (isPartiallySigned) {
    return false
  }

  if (isFunded) {
    return false
  }

  if (isOverfunded) {
    return false
  }

  if (isPartiallyFunded) {
    return false
  }

  if (hasSentFunds) {
    return false
  }

  return true
}

export default isInvestmentDeleteable
