import { getData } from "country-list"
import { getCountryCodeForRegionCode } from "awesome-phonenumber"

const countries = getData()


const getCountryCodesOptions = () => {
  const countryList = countries
    .map(({ code }) => {
      const value = getCountryCodeForRegionCode(code)

      const label = `${code} +${value}`

      return ({
        label,
        value: code
      })
    })

  return countryList
}

export default getCountryCodesOptions
