
const getFailedMessage = (isCreateOperation, entity, item = {}) => {
  let entityName = "a"

  const { name } = item

  if (name ) {
    entityName = `"${name}"`
  }

  entity = `${entity.toLowerCase()}`

  const failedMessage = isCreateOperation ?
    `Failed to create a ${entity}` :
    `Failed to update ${entityName} ${entity}`

  return failedMessage
}

export default getFailedMessage
