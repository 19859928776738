import React from "react"
import PropTypes from "prop-types"

import { MarkdownAlert } from "@components/Text"

const LABEL = `Please provide your custodian with the wire transfer
instructions to complete the funding process.`


const SdiraFundingAlert = ({ style = undefined }) => {
  return (
    <div style={style}>
      <MarkdownAlert
        text={LABEL}
        type="warning"
      />
    </div>
  )
}

SdiraFundingAlert.propTypes = {
  style: PropTypes.shape(),
}

export default SdiraFundingAlert
