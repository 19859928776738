

const getHasOptions = options => {
  const isUrl = typeof options === 'string'

  const isArray = Array.isArray(options)

  const isConfig = typeof options === "object"

  if (isUrl) {
    return true
  }

  if (isArray) {
    return true
  }

  if (isConfig) {
    return true
  }

  return false
}

export default getHasOptions
