import React, { useRef } from "react"
import { Tag, Spin } from "antd"
import { useOutletContext, useParams, useLocation } from "react-router-dom"

import { W1 } from "@components"
import { Page, PageMenu } from "@components/Page"
import { useProjectStore } from "@components/Project"
import {
  PROJECT_STATES,
  STATE_LABELS_MAP,
  PROJECT_STATUSES,
} from "@components/Domain"

import useHeaderMenu from "./helpers/useHeaderMenu"
import getProjectLinks from "./helpers/getProjectLinks"
import BackstageProjectLayoutOutlet from "./BackstageProjectLayoutOutlet"

const MODULES_WITHOUT_SEARCH = [
  "details",
]


const BackstageProjectLayout = () => {
  const table = useRef({})

  const { pathname } = useLocation()
  const { id: projectId } = useParams()

  const { isReady, context: projectStore } = useProjectStore(projectId)
  const outletContext = useOutletContext()

  const menu = useHeaderMenu(projectStore)
  const links = getProjectLinks(projectId)

  if (!isReady) {
    return (
      <center style={{ paddingTop: W1 }}><Spin /></center>
    )
  }

  const { project } = projectStore
  const { name: title, state, status } = project

  const isSold = state === PROJECT_STATES.ARCHIVED
  const isDraft = status === PROJECT_STATUSES.DRAFT
  const hasMenu = !isDraft

  const titleExtra = !isSold
    ? null
    : (
        <Tag bordered={false}>
          {STATE_LABELS_MAP[state]}
        </Tag>
      )

  const onSearch = value =>
    table.current.search(value)

  const style = { padding: 0 }

  const pageProps = {
    menu,
    title,
    style,
    titleExtra,
  }

  const isSearchEnabled = MODULES_WITHOUT_SEARCH
    .filter(item => !pathname.endsWith(item))
    .length > 0

  if (isSearchEnabled) {
    pageProps.onSearch = onSearch
  }

  const context = {
    ...outletContext,
    ...projectStore,
    table,
  }

  return (
    <Page key={projectId} {...pageProps}>
      {
        hasMenu && (
          <PageMenu links={links} />
        )
      }

      <BackstageProjectLayoutOutlet context={context} />
    </Page>
  )
}

export default BackstageProjectLayout
