import React, { useState } from "react"
import PropTypes from "prop-types"
import { Form } from "antd"

import { CreateDrawer } from "@components/Page"
import { ReadBatchOutputShape, createFileOperation as createOperation } from "@api/services/backstage"

import getCreateInputs from "./helpers/getCreateInputs"

const LABEL_ENTITY = "File"
const LABEL_TITLE = `Create ${LABEL_ENTITY}`


const CreateFileDrawer = ({
  batch,
  drawer,
  onSuccess
}) => {
  const [ form ] = Form.useForm()

  const [ schema, setSchema ] = useState()

  const onOpen = ({ investmentId, investorAccountId }) => {
    const {
      id: batchId,
      date,
      projectId,
      documentType,
    } = batch

    form.setFieldsValue({
      date,
      batchId,
      projectId,
      investmentId,
      documentType,
      investorAccountId,
    })

    const inputs = getCreateInputs()
    const newSchema = createOperation.getSchema(inputs)

    setSchema(newSchema)
  }

  const createDrawerProps = {
    title: LABEL_TITLE,
    entity: LABEL_ENTITY,
    operation: createOperation,
    form,
    drawer,
    schema,
    onOpen,
    onSuccess,
  }

  return (
    <CreateDrawer {...createDrawerProps} />
  )
}

CreateFileDrawer.propTypes = {
  batch: ReadBatchOutputShape.isRequired,
  drawer: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default CreateFileDrawer
