import React from "react"
import PropTypes from "prop-types"
import { Typography, Space, Row, Col } from "antd"
import { ExportOutlined, ReloadOutlined } from "@ant-design/icons"

import HeaderButton from "./HeaderButton"
import getHeaderTitle from "./helpers/getHeaderTitle"
import useExportAction from "./helpers/useExportAction"
import ExportColumnShape from "./shapes/ExportColumnShape"

const { Text } = Typography

const LABEL_EXPORT = "Export"
const LABEL_RELOAD = "Reload"


const Header = ({
  style = {},
  items = undefined,
  actions = [],
  renderTitle = title => <Text type="secondary">{title}</Text>,
  exportColumns = [],
  exportFileName = "",
  filteredItemsCount = undefined,
  reload,
  itemsCount,
  getFilteredItems,
}) => {
  const exportMethod = useExportAction(getFilteredItems, exportFileName, exportColumns)

  const actionItems = [...actions]

  const isExportEnabled = !!exportFileName

  if (isExportEnabled) {
    actionItems.push({
      icon: <ExportOutlined />,
      title: LABEL_EXPORT,
      action: exportMethod,
    })
  }

  actionItems.push({
    icon: <ReloadOutlined />,
    title: LABEL_RELOAD,
    action: reload,
  })

  const renderedActions = actionItems.map(({ icon, title, action, component, ...otherProps }, key) =>
    component
      ? <div key={key}>{component}</div>
      : (
        <HeaderButton
          key={key}
          icon={icon}
          title={title}
          action={action}
          otherProps={otherProps}
        />
      )
  )

  const title = getHeaderTitle(itemsCount, filteredItemsCount)
  const renderedTitle = renderTitle(title, items)

  return (
    <Row style={style}>
      <Col flex="auto">
        {renderedTitle}
      </Col>

      <Col>
        <Space>
          {renderedActions}
        </Space>
      </Col>
    </Row>
  )
}

Header.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  style: PropTypes.shape(),
  reload: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape()),
  itemsCount: PropTypes.number.isRequired,
  renderTitle: PropTypes.func,
  exportColumns: PropTypes.arrayOf(ExportColumnShape),
  exportFileName: PropTypes.string,
  getFilteredItems: PropTypes.func.isRequired,
  filteredItemsCount: PropTypes.number,
}

export default Header
