import getOperation from "../../helpers/getOperation"

import AcceptTermsOfServiceOutputShape from "./AcceptTermsOfServiceOutputShape"
import ArchiveProjectOutputShape from "./ArchiveProjectOutputShape"
import AuthorizeAdministratorOutputShape from "./AuthorizeAdministratorOutputShape"
import AuthorizeOutputShape from "./AuthorizeOutputShape"
import CloseProjectOutputShape from "./CloseProjectOutputShape"
import ConnectFundPartnerOutputShape from "./ConnectFundPartnerOutputShape"
import ConnectFundProjectPropertyOutputShape from "./ConnectFundProjectPropertyOutputShape"
import ConnectSponsorPartnerOutputShape from "./ConnectSponsorPartnerOutputShape"
import CreateBatchFilesOutputShape from "./CreateBatchFilesOutputShape"
import CreateBatchInvestorFilesOutputShape from "./CreateBatchInvestorFilesOutputShape"
import CreateBrokerOutputShape from "./CreateBrokerOutputShape"
import CreateFileOutputShape from "./CreateFileOutputShape"
import CreateFundMemberOutputShape from "./CreateFundMemberOutputShape"
import CreateMemberOutputShape from "./CreateMemberOutputShape"
import CreateOptionOutputShape from "./CreateOptionOutputShape"
import CreateProjectOutputShape from "./CreateProjectOutputShape"
import CreatePropertyOutputShape from "./CreatePropertyOutputShape"
import CreateSponsorOutputShape from "./CreateSponsorOutputShape"
import CreateUserGroupOutputShape from "./CreateUserGroupOutputShape"
import DisableUserOutputShape from "./DisableUserOutputShape"
import DisconnectFundProjectPropertyOutputShape from "./DisconnectFundProjectPropertyOutputShape"
import EnableMyUser2FAOutputShape from "./EnableMyUser2FAOutputShape"
import EnableUserOutputShape from "./EnableUserOutputShape"
import IndexBatchFilesOutputShape from "./IndexBatchFilesOutputShape"
import IndexInvestorFilesOutputShape from "./IndexInvestorFilesOutputShape"
import IndexMyFilesOutputShape from "./IndexMyFilesOutputShape"
import IndexOptionsOutputShape from "./IndexOptionsOutputShape"
import IndexProjectBatchesOutputShape from "./IndexProjectBatchesOutputShape"
import IndexPropertiesOutputShape from "./IndexPropertiesOutputShape"
import IndexPublishedProjectsOutputShape from "./IndexPublishedProjectsOutputShape"
import IndexSponsorsOutputShape from "./IndexSponsorsOutputShape"
import IndexUserGroupsOutputShape from "./IndexUserGroupsOutputShape"
import IndexUsersOutputShape from "./IndexUsersOutputShape"
import ListProjectsOutputShape from "./ListProjectsOutputShape"
import MapInvestmentDocumentsOutputShape from "./MapInvestmentDocumentsOutputShape"
import PublishProjectOutputShape from "./PublishProjectOutputShape"
import ReadBatchOutputShape from "./ReadBatchOutputShape"
import ReadOptionOutputShape from "./ReadOptionOutputShape"
import ReadProjectOutputShape from "./ReadProjectOutputShape"
import ReadPropertyOutputShape from "./ReadPropertyOutputShape"
import ReadPublicPublishedProjectOutputShape from "./ReadPublicPublishedProjectOutputShape"
import ReadSponsorOutputShape from "./ReadSponsorOutputShape"
import ReadUserGroupOutputShape from "./ReadUserGroupOutputShape"
import ReadUserOutputShape from "./ReadUserOutputShape"
import UnpublishProjectOutputShape from "./UnpublishProjectOutputShape"
import UpdateBatchOutputShape from "./UpdateBatchOutputShape"
import UpdateBrokerOutputShape from "./UpdateBrokerOutputShape"
import UpdateFundMemberOutputShape from "./UpdateFundMemberOutputShape"
import UpdateFundPartnerOutputShape from "./UpdateFundPartnerOutputShape"
import UpdateMemberOutputShape from "./UpdateMemberOutputShape"
import UpdateMyUserOutputShape from "./UpdateMyUserOutputShape"
import UpdateOptionOutputShape from "./UpdateOptionOutputShape"
import UpdatePartnerProjectOutputShape from "./UpdatePartnerProjectOutputShape"
import UpdateProjectOutputShape from "./UpdateProjectOutputShape"
import UpdatePropertyOutputShape from "./UpdatePropertyOutputShape"
import UpdateSponsorOutputShape from "./UpdateSponsorOutputShape"
import UpdateSponsorPartnerOutputShape from "./UpdateSponsorPartnerOutputShape"
import UpdateUserGroupOutputShape from "./UpdateUserGroupOutputShape"

export {
  AcceptTermsOfServiceOutputShape,
  ArchiveProjectOutputShape,
  AuthorizeAdministratorOutputShape,
  AuthorizeOutputShape,
  CloseProjectOutputShape,
  ConnectFundPartnerOutputShape,
  ConnectFundProjectPropertyOutputShape,
  ConnectSponsorPartnerOutputShape,
  CreateBatchFilesOutputShape,
  CreateBatchInvestorFilesOutputShape,
  CreateBrokerOutputShape,
  CreateFileOutputShape,
  CreateFundMemberOutputShape,
  CreateMemberOutputShape,
  CreateOptionOutputShape,
  CreateProjectOutputShape,
  CreatePropertyOutputShape,
  CreateSponsorOutputShape,
  CreateUserGroupOutputShape,
  DisableUserOutputShape,
  DisconnectFundProjectPropertyOutputShape,
  EnableMyUser2FAOutputShape,
  EnableUserOutputShape,
  IndexBatchFilesOutputShape,
  IndexInvestorFilesOutputShape,
  IndexMyFilesOutputShape,
  IndexOptionsOutputShape,
  IndexProjectBatchesOutputShape,
  IndexPropertiesOutputShape,
  IndexPublishedProjectsOutputShape,
  IndexSponsorsOutputShape,
  IndexUserGroupsOutputShape,
  IndexUsersOutputShape,
  ListProjectsOutputShape,
  MapInvestmentDocumentsOutputShape,
  PublishProjectOutputShape,
  ReadBatchOutputShape,
  ReadOptionOutputShape,
  ReadProjectOutputShape,
  ReadPropertyOutputShape,
  ReadPublicPublishedProjectOutputShape,
  ReadSponsorOutputShape,
  ReadUserGroupOutputShape,
  ReadUserOutputShape,
  UnpublishProjectOutputShape,
  UpdateBatchOutputShape,
  UpdateBrokerOutputShape,
  UpdateFundMemberOutputShape,
  UpdateFundPartnerOutputShape,
  UpdateMemberOutputShape,
  UpdateMyUserOutputShape,
  UpdateOptionOutputShape,
  UpdatePartnerProjectOutputShape,
  UpdateProjectOutputShape,
  UpdatePropertyOutputShape,
  UpdateSponsorOutputShape,
  UpdateSponsorPartnerOutputShape,
  UpdateUserGroupOutputShape
}

export const acceptTermsOfServiceOperation = getOperation("backstage/AcceptTermsOfService")
export const archiveProjectOperation = getOperation("backstage/ArchiveProject")
export const authorizeOperation = getOperation("backstage/Authorize")
export const authorizeAdministratorOperation = getOperation("backstage/AuthorizeAdministrator")
export const closeProjectOperation = getOperation("backstage/CloseProject")
export const connectFundPartnerOperation = getOperation("backstage/ConnectFundPartner")
export const connectFundProjectPropertyOperation = getOperation("backstage/ConnectFundProjectProperty")
export const connectSponsorPartnerOperation = getOperation("backstage/ConnectSponsorPartner")
export const createBatchFilesOperation = getOperation("backstage/CreateBatchFiles")
export const createBatchInvestorFilesOperation = getOperation("backstage/CreateBatchInvestorFiles")
export const createBrokerOperation = getOperation("backstage/CreateBroker")
export const createFileOperation = getOperation("backstage/CreateFile")
export const createFundMemberOperation = getOperation("backstage/CreateFundMember")
export const createMemberOperation = getOperation("backstage/CreateMember")
export const createOptionOperation = getOperation("backstage/CreateOption")
export const createProjectOperation = getOperation("backstage/CreateProject")
export const createPropertyOperation = getOperation("backstage/CreateProperty")
export const createSponsorOperation = getOperation("backstage/CreateSponsor")
export const createUserGroupOperation = getOperation("backstage/CreateUserGroup")
export const deleteBatchOperation = getOperation("backstage/DeleteBatch")
export const deleteFileOperation = getOperation("backstage/DeleteFile")
export const deleteOptionOperation = getOperation("backstage/DeleteOption")
export const deleteProjectOperation = getOperation("backstage/DeleteProject")
export const deletePropertyOperation = getOperation("backstage/DeleteProperty")
export const deleteSponsorOperation = getOperation("backstage/DeleteSponsor")
export const deleteUserGroupOperation = getOperation("backstage/DeleteUserGroup")
export const disableUserOperation = getOperation("backstage/DisableUser")
export const disconnectFundProjectPropertyOperation = getOperation("backstage/DisconnectFundProjectProperty")
export const disconnectPartnerOperation = getOperation("backstage/DisconnectPartner")
export const enableMyUser2FAOperation = getOperation("backstage/EnableMyUser2FA")
export const enableUserOperation = getOperation("backstage/EnableUser")
export const indexBatchFilesOperation = getOperation("backstage/IndexBatchFiles")
export const indexInvestorFilesOperation = getOperation("backstage/IndexInvestorFiles")
export const indexMyFilesOperation = getOperation("backstage/IndexMyFiles")
export const indexOptionsOperation = getOperation("backstage/IndexOptions")
export const indexProjectBatchesOperation = getOperation("backstage/IndexProjectBatches")
export const indexPropertiesOperation = getOperation("backstage/IndexProperties")
export const indexPublishedProjectsOperation = getOperation("backstage/IndexPublishedProjects")
export const indexSponsorsOperation = getOperation("backstage/IndexSponsors")
export const indexUserGroupsOperation = getOperation("backstage/IndexUserGroups")
export const indexUsersOperation = getOperation("backstage/IndexUsers")
export const listProjectsOperation = getOperation("backstage/ListProjects")
export const mapInvestmentDocumentsOperation = getOperation("backstage/MapInvestmentDocuments")
export const publishProjectOperation = getOperation("backstage/PublishProject")
export const readBatchOperation = getOperation("backstage/ReadBatch")
export const readOptionOperation = getOperation("backstage/ReadOption")
export const readProjectOperation = getOperation("backstage/ReadProject")
export const readPropertyOperation = getOperation("backstage/ReadProperty")
export const readPublicPublishedProjectOperation = getOperation("backstage/ReadPublicPublishedProject")
export const readSponsorOperation = getOperation("backstage/ReadSponsor")
export const readUserOperation = getOperation("backstage/ReadUser")
export const readUserGroupOperation = getOperation("backstage/ReadUserGroup")
export const unpublishProjectOperation = getOperation("backstage/UnpublishProject")
export const updateBatchOperation = getOperation("backstage/UpdateBatch")
export const updateBrokerOperation = getOperation("backstage/UpdateBroker")
export const updateFundMemberOperation = getOperation("backstage/UpdateFundMember")
export const updateFundPartnerOperation = getOperation("backstage/UpdateFundPartner")
export const updateMemberOperation = getOperation("backstage/UpdateMember")
export const updateMyUserOperation = getOperation("backstage/UpdateMyUser")
export const updateOptionOperation = getOperation("backstage/UpdateOption")
export const updatePartnerProjectOperation = getOperation("backstage/UpdatePartnerProject")
export const updateProjectOperation = getOperation("backstage/UpdateProject")
export const updatePropertyOperation = getOperation("backstage/UpdateProperty")
export const updateSponsorOperation = getOperation("backstage/UpdateSponsor")
export const updateSponsorPartnerOperation = getOperation("backstage/UpdateSponsorPartner")
export const updateUserGroupOperation = getOperation("backstage/UpdateUserGroup")
