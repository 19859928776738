import React, { useEffect, useState } from "react"
import { Space, Typography, Tooltip } from "antd"
import { CheckCircleOutlined } from "@ant-design/icons"
import { useAuthenticator} from "@aws-amplify/ui-react"

import { W1 } from "@components"

const { Text } = Typography

const LABEL_MIN_LENGTH = "Has length of at least 8 characters"
const LABEL_REQUIREMENTS = "Password Requirements:"
const LABEL_SYMBOL_NUMBER = "Contains at least 1 number"
const LABEL_SYMBOL_LOWERCASE = "Contains at least 1 lowercase letter"
const LABEL_SYMBOL_UPPERCASE = "Contains at least 1 uppercase letter"
const LABEL_SYMBOL_SPECIAL_LIST = "^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + ="
const LABEL_SYMBOL_SPECIAL_PREFIX = "Contains at least 1 "
const LABEL_SYMBOL_SPECIAL_POSTFIX = "special character "


const PasswordRequirements = () => {
  const [ errors, setErrors ]  = useState({})

  const { validationErrors } = useAuthenticator()

  useEffect(() => {
    setErrors(validationErrors)
  }, [ validationErrors ])

  const getIconStyle = key => {
    const hasNoPassword = !!errors.passwordRequired

    if (hasNoPassword) {
      return { color: 'grey', opacity: 0.5, width: 18 }
    }

    const isRuleNotMatched = !!errors[key]

    const style = isRuleNotMatched
      ? { color: 'grey', opacity: 0.5, width: 18 }
      : { color: 'green', width: 18 }

    return style
  }

  const items = [
    {
      key: "passwordLength",
      label: <Text>{LABEL_MIN_LENGTH}</Text>
    },
    {
      key: "passwordNumberSymbol",
      label: <Text>{LABEL_SYMBOL_NUMBER}</Text>
    },
    {
      key: "passwordSpecialSymbol",
      label: (
        <Text>
          {LABEL_SYMBOL_SPECIAL_PREFIX}
          <Tooltip title={LABEL_SYMBOL_SPECIAL_LIST}>
            <Text underline>
              {LABEL_SYMBOL_SPECIAL_POSTFIX}
            </Text>
          </Tooltip>
        </Text>
      )
    },
    {
      key: "passwordUppercaseSymbol",
      label: <Text>{LABEL_SYMBOL_UPPERCASE}</Text>
    },
    {
      key: "passwordLowercaseSymbol",
      label: <Text>{LABEL_SYMBOL_LOWERCASE}</Text>
    }
  ]

  return (
    <Space
      style={{ marginBottom: W1 }}
      direction="vertical"
    >
      <Text>
        {LABEL_REQUIREMENTS}
      </Text>

      {
        items.map(({ key, label }) => (
          <Space key={key}>
            <CheckCircleOutlined
              style={{ ...getIconStyle(key) }}
            />

            <Text>
              {label}
            </Text>
          </Space>
        ))
      }
    </Space>
  )
}

export default PasswordRequirements
