import {
  TYPE_NONE,
  TYPE_HIDDEN,
  TYPE_RADIO,
  TYPE_UPLOAD,
  TYPE_CHECKBOX,
  TYPE_DATETIME
} from "@components/Form"
import { W0, W1 } from "@components"

const LABEL_TYPE = "Type"
const LABEL_DATE = "Report Date"
const LABEL_REPORT = "Report"
const LABEL_SPONSOR = "Sponsor"
const LABEL_SEND_NO = "Do not send"
const LABEL_SEND_YES = "Schedule for"
const LABEL_DATETIME = "Date & Time"
const LABEL_NOTIFICATIONS = "Investor Notifications"
const LABEL_IS_PUBLIC_FILE = "Include the report link in the investor notification"
const LABEL_TYPE_PLACEHOLDER = "Select document type"


const getCreateInputs = (
  projectId,
  typeOptions,
  isSent,
  isPublic,
  mainSponsorId,
  hasMultipleSponsors,
  projectSponsorOptions
) => {
  const sponsorInput = {
    name: "sponsorId",
    type: TYPE_HIDDEN,
    initialValue: mainSponsorId,
  }

  const commonInputProps = {
     size: "medium",
   }

  const shouldSchedule = !isSent

  const scheduledAtProps = !shouldSchedule
    ? { type: TYPE_NONE }
    : {
        type: TYPE_DATETIME,
        label: LABEL_DATETIME,
        required: true,
        itemProps: {
          style: { marginBottom: W1 },
        },
        inputProps: {
          minuteStep: 15,
          showNow: true,
          ...commonInputProps,
        }
      }

  if (hasMultipleSponsors) {
    sponsorInput.type = TYPE_RADIO
    sponsorInput.label = LABEL_SPONSOR
    sponsorInput.options = projectSponsorOptions
  }

  return [
    {
      name: "projectId",
      type: TYPE_HIDDEN,
      initialValue: projectId,
    },
    { ...sponsorInput },
    {
      name: "documentType",
      label: LABEL_TYPE,
      options: typeOptions,
      required: true,
      placeholder: LABEL_TYPE_PLACEHOLDER,
      hasAutoComplete: true,
    },
    {
      name: "name",
      placeholder: "",
    },
    {
      name: "date",
      label: LABEL_DATE,
      inputProps: {
        style: { width: 127 }
      },
    },
    {
      name: "isSent",
      type: TYPE_RADIO,
      label: LABEL_NOTIFICATIONS,
      initialValue: true,
      options: [
        {
          value: true,
          label: LABEL_SEND_NO,
        },
        {
          value: false,
          label: LABEL_SEND_YES,
        },
      ],
      itemProps: {
        style: { marginBottom: W1 },
      },
    },
    {
      name: "scheduledAt",
      label: "",
      ...scheduledAtProps,
    },
    {
      name: "isPublicFile",
      type: TYPE_CHECKBOX,
      label: LABEL_REPORT,
      initialValue: true,
      itemProps: {
        style: { marginBottom: W0 },
      },
      inputProps: {
        label: LABEL_IS_PUBLIC_FILE,
      }
    },
    {
      name: "fileUrl",
      type: TYPE_UPLOAD,
      label: "",
      inputProps: {
        isPublic,
        onChange: (event, _form) => {
          const { name } = event.file
          _form.setFieldValue("fileSourceFileName", name)
        }
      }
    },
    {
      name: "fileSourceFileName",
      type: TYPE_HIDDEN,
    },
  ]
}

export default getCreateInputs
