import React, { useRef, useState } from "react"
import { Switch } from "antd"
import PropTypes from "prop-types"

import { useAppContext } from "@components/AppContext"
import {
  enableAccount2FAOperation as enableOperation,
  disableAccount2FAOperation as disableOperation,
  disableMyAccount2FAOperation as disableMyOperation,
} from "@api/services/investments"
import VerificationRequestModal from "./VerificationRequestModal"

const LABEL_ENABLED = "Enabled"
const LABEL_DISABLED = "Disabled"


const TwoFactorAuthenticationSwitch = ({
  mobile = undefined,
  accountId = undefined,
  isDisabled = false,
  isEnabled,
  onSuccess,
}) => {
  const modal = useRef({})

  const [ isLoading, setIsLoading ] = useState(false)
  const { request, isImpersonated } = useAppContext()

  const enable = () =>
    request(enableOperation, { id: accountId })

  const disable = () =>
    accountId
      ? request(disableOperation, { id: accountId })
      : request(disableMyOperation)

  const onChange = async (shouldEnable) => {
    const shouldOpenModal = shouldEnable && !accountId

    if (shouldOpenModal) {
      modal.current.open()
      return
    }

    setIsLoading(true)

    if (shouldEnable) {
      await enable()

    } else {
      await disable()

    }

    await onSuccess(shouldEnable)
    setIsLoading(false)
  }

  const onVerificationSuccess = async () => {
    setIsLoading(true)
    await onSuccess(true)
    setIsLoading(false)
  }

  return (
    <>
      <Switch
        checked={isEnabled}
        loading={isLoading}
        onChange={onChange}
        disabled={isDisabled || isImpersonated}
        checkedChildren={LABEL_ENABLED}
        unCheckedChildren={LABEL_DISABLED}
      />

      {
        mobile && (
          <VerificationRequestModal
            modal={modal}
            mobile={mobile}
            onSuccess={onVerificationSuccess}
          />
        )
      }
    </>
  )
}

TwoFactorAuthenticationSwitch.propTypes = {
  mobile: PropTypes.string,
  isEnabled: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  accountId: PropTypes.string,
  isDisabled: PropTypes.bool,
}

export default TwoFactorAuthenticationSwitch
