import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { hasAccess } from "@components/Authorization"
import { VirtualTable } from "@components/Table"
import { indexOperation } from "@components/Store/BackstageStore/useAccountsStore"

import useColumnsConfig from "./helpers/useColumnsConfig"
import getHeaderActions from "./helpers/getHeaderActions"
import useExportColumns from "./helpers/useExportColumns"
import AccountsImportModal from "../AccountsImportModal"
import useSelectableActions from "./helpers/useSelectableActions"

const PAGE_SIZE = 100


const InvestorsTable = ({
  openCreateModal = undefined,
  openInvestorDrawer = undefined,
  table,
  openTagsDrawer,
  ...otherProps
}) => {
  const accountsImportModal = useRef({})

  const {
    accounts,
    indexAccounts,
  } = useOutletContext()

  const [ selectableActions, onSelectionChange ] = useSelectableActions(table, openTagsDrawer)

  const canUpdateAccounts = hasAccess(['accounts-write'])

  const openAccountsImportModal = () =>
    accountsImportModal.current.open()

  const pagination = {
    position: ["bottomLeft"],
    pageSize: PAGE_SIZE,
    showSizeChanger: false,
    hideOnSinglePage: true,
  }

  const size = "small"
  const indexItems = indexAccounts
  const isSelectable =  canUpdateAccounts
  const columnsConfig = useColumnsConfig(openInvestorDrawer)
  const headerActions = getHeaderActions(selectableActions, openCreateModal, openAccountsImportModal)
  const exportColumns = useExportColumns()
  const exportFileName = "investors"
  const hasHeaderSegment = true
  const defaultSortColumn = "name"

  const tableProps = {
    items: accounts,
    size,
    table,
    indexItems,
    isSelectable,
    headerActions,
    columnsConfig,
    exportColumns,
    indexOperation,
    exportFileName,
    hasHeaderSegment,
    defaultSortColumn,
    onSelectionChange,
    ...otherProps,
  }

  const hasMultiplePages = (accounts || []).length > PAGE_SIZE

  if (hasMultiplePages) {
    tableProps.pagination = pagination
  }

  return (
    <>
      <VirtualTable {...tableProps} />

      <AccountsImportModal
        modal={accountsImportModal}
      />
    </>
  )
}

InvestorsTable.propTypes = {
  table: PropTypes.shape().isRequired,
  openTagsDrawer: PropTypes.func.isRequired,
  openCreateModal: PropTypes.func,
  openInvestorDrawer: PropTypes.func,
}

export default InvestorsTable
