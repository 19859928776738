import React from "react"
import { useOutletContext } from "react-router-dom"

import ActiveInvestments from "./ActiveInvestments"
import InvestmentProjects from "./InvestmentProjects"
import useGroupedProjects from "./helpers/useGroupedProjects"
import InvestmentsStatistics from "./InvestmentsStatistics"

const LABEL_OPEN = "In Progress"
const LABEL_ACTIVE = "Active Investments"
const LABEL_ARCHIVED = "Closed Investments"


const InvestorDashboardPage = () => {
  const { projects, investments, transactions } = useOutletContext()

  const {
    openProjects,
    closedProjects,
    archivedProjects
  } = useGroupedProjects(projects, investments, transactions)

  return (
    <>
      <InvestmentsStatistics />

      <ActiveInvestments
        key="active"
        title={LABEL_OPEN}
        projects={openProjects}
      />

      <InvestmentProjects
        key="closed"
        title={LABEL_ACTIVE}
        projects={closedProjects}
      />

      <InvestmentProjects
        key="archived"
        title={LABEL_ARCHIVED}
        projects={archivedProjects}
      />
    </>
  )
}

export default InvestorDashboardPage
