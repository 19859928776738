import Templates from "./Templates"
import useTemplatesStore from "./stores/useTemplatesStore"
import TemplatesStoreShape from "./shapes/TemplatesStoreShape"

export default Templates

export {
  useTemplatesStore,
  TemplatesStoreShape,
}
