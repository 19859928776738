import React from "react"
import { Descriptions, Typography } from "antd"

import { CopyValueButton } from "@components/Button"
import {
  IndexOrganizationsOutputShape,
  ReadOrganizationMetricsOutputShape
} from "@api/services/organizations"
import { getConfig } from "@components/Config"
import { getCurrencyValue } from "@components/Amount"

import OrganizationEmail from "./OrganizationEmail"
import OrganizationDomain from "./OrganizationDomain"
import OrganizationStatusTag from "../OrganizationsTable/OrganizationStatusTag"

const ENVIRONMENT_TAG = getConfig('ENVIRONMENT_TAG')
const EMAIL_DASHBOARD_URL =
  "https://us-east-1.console.aws.amazon.com/ses/home?region=us-east-1#/vdm/dashboard/configuration-set"

const LABEL_ID = "ID"
const LABEL_NAME = "Name"
const LABEL_EMAIL = "Email"
const LABEL_STATUS = "Status"
const LABEL_DOMAIN = "Domain"
const LABEL_NOTIFICATIONS = "Notifications"
const LABEL_PROJECTS = "Projects"
const LABEL_DRAFT = "Draft"
const LABEL_OPEN = "Open"
const LABEL_CLOSED = "Closed"
const LABEL_ARCHIVED = "Archived"
const LABEL_MARKETING_LINK = "View Marketing Dashboard"
const LABEL_COUNT_INVESTORS = "Investors"
const LABEL_INVESTMENTS = "Investments"
const LABEL_TRANSACTIONAL_LINK = "View Transactional Dashboard"
const LABEL_AMOUNT_FUND_RAISED = "Raised Funds"
const LABEL_SDIRA = "SDIRA"
const LABEL_JOINT = "Joint"
const LABEL_ENTITY = "Entity"
const LABEL_INDIVIDUAL = "Individual"


const OrganizationDescriptions = ({ organization, metrics = {} }) => {
  const { id } = organization

  const marketingDashboardUrl = `${EMAIL_DASHBOARD_URL}/${ENVIRONMENT_TAG}-marketing-${id}`
  const transactionalDashboardUrl = `${EMAIL_DASHBOARD_URL}/${ENVIRONMENT_TAG}-transactional-${id}`

  const items = [
    {
      label: <Typography.Text>{LABEL_NAME}</Typography.Text>,
      value: (
        <Typography.Text strong>
          {organization.name}
        </Typography.Text>
      ),
    },
    {
      label: <Typography.Text>{LABEL_ID}</Typography.Text>,
      value: <CopyValueButton value={organization.id} />,
    },
    {
      label: <Typography.Text>{LABEL_STATUS}</Typography.Text>,
      value: <OrganizationStatusTag organization={organization} />
    },
    {
      label: <Typography.Text>{LABEL_DOMAIN}</Typography.Text>,
      value: <OrganizationDomain organization={organization} />,
    },
    {
      label: <Typography.Text>{LABEL_EMAIL}</Typography.Text>,
      value: <OrganizationEmail organization={organization} />,
    },
    {
      label: <Typography.Text>{LABEL_NOTIFICATIONS}</Typography.Text>,
      value: (
        <>
          <Typography.Link
            href={transactionalDashboardUrl}
            target="_blank"
          >
            {LABEL_TRANSACTIONAL_LINK}
          </Typography.Link>
          <br/>
          <Typography.Link
            href={marketingDashboardUrl}
            target="_blank"
          >
            {LABEL_MARKETING_LINK}
          </Typography.Link>
        </>
      )
    },
    {
      label: (
        <>
          <Typography.Text>
            {LABEL_PROJECTS}
          </Typography.Text>
          <br/>
          <Typography.Text type="secondary">
            {LABEL_DRAFT} / {LABEL_OPEN} / {LABEL_CLOSED} / {LABEL_ARCHIVED}
          </Typography.Text>
        </>
      ),
      value: (
        <>
          <Typography.Text>
            {metrics.projectsTotalCount}
          </Typography.Text>
          <br/>
          <Typography.Text type="secondary">
            {metrics.projectsDraftCount} / {metrics.projectsOpenCount} / {metrics.projectClosedCount} / {metrics.projectArchivedCount}
          </Typography.Text>
        </>
      )
    },
    {
      label: (
        <>
          <Typography.Text>
            {LABEL_INVESTMENTS}
          </Typography.Text>
          <br/>
          <Typography.Text type="secondary">
            {LABEL_INDIVIDUAL} / {LABEL_JOINT} / {LABEL_ENTITY} / {LABEL_SDIRA}
          </Typography.Text>
        </>
      ),
      value: (
        <>
          <Typography.Text>
            {metrics.investmentsTotalCount}
          </Typography.Text>
          <br/>
          <Typography.Text type="secondary">
            {metrics.investmentsIndividualCount} / {metrics.investmentsJointCount} / {metrics.investmentsEntityCount} / {metrics.investmentsSdiraCount}
          </Typography.Text>
        </>
      )
    },
    {
      label: <Typography.Text>{LABEL_COUNT_INVESTORS}</Typography.Text>,
      value: metrics.investorsTotalCount,
    },
    {
      label: <Typography.Text>{LABEL_AMOUNT_FUND_RAISED}</Typography.Text>,
      value: getCurrencyValue(metrics.fundsRaisedAmount),
    },
  ]

  const descriptionsProps = {
    size: "small",
    column: 1,
    bordered: true,
    labelStyle: { width: "38%" },
  }

  return (
    <Descriptions {...descriptionsProps}>
      {
        items.map(({ label, value }, key) =>
          <Descriptions.Item
            key={key}
            label={label}
          >
            {value}
          </Descriptions.Item>
        )
      }
    </Descriptions>
  )
}

OrganizationDescriptions.propTypes = {
  metrics: ReadOrganizationMetricsOutputShape,
  organization: IndexOrganizationsOutputShape.isRequired,
}

export default OrganizationDescriptions
