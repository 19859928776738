import React from "react"
import PropTypes from "prop-types"
import { Space, Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"

import { W2 } from "@components"
import { PROJECT_TYPES } from "@components/Domain"

import PropertyShape from "../shapes/PropertyShape"
import ProjectPropertyCard from "./ProjectPropertyCard"
import { useProjectDetailsContext } from "./ProjectDetailsContext"

const LABEL_ADD = "Add Property"


const ProjectProperties = ({ projectType, properties = [], isEditable }) => {
  const { openPropertyCreateModal } = useProjectDetailsContext()

  const isPortfolio = projectType === PROJECT_TYPES.PORTFOLIO

  const hasNoProperties = properties.length === 0
  const canAddProperty = isEditable && (hasNoProperties || isPortfolio)

  return (
    <Space
      size={W2}
      style={{ width: "100%" }}
      direction="vertical"
    >
      {
        properties.map(property =>
          <ProjectPropertyCard
            key={property.id}
            property={property}
            isEditable={isEditable}
          />
        )
      }

      {
        canAddProperty && (
          <Button
            block
            icon={<PlusOutlined />}
            type="dashed"
            shape="round"
            onClick={() => openPropertyCreateModal()}
          >
            {LABEL_ADD}
          </Button>
        )
      }
    </Space>
  )
}

ProjectProperties.propTypes = {
  properties: PropTypes.arrayOf(PropertyShape),
  projectType: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
}

export default ProjectProperties
