import Text from "./Text"
import Bold from "./Bold"
import Html from "./Html"
import Small from "./Small"
import Value from "./Value"
import Title from "./Title"
import Disclaimer from "./Disclaimer"
import EmailPreview from "./EmailPreview"

import formatPhoneNumber from "./Value/helpers/formatPhoneNumber"

export * from "./Markdown"

export default Text

export {
  Text,
  Bold,
  Html,
  Small,
  Value,
  Title,
  Disclaimer,
  EmailPreview,
  formatPhoneNumber,
}
