import React from "react"
import { Tag, Tooltip } from "antd"
import { useOutletContext } from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const LABEL_TOOLTIP = "[name] has to complete bank account verification."
const LABEL_PENDING = "Pending Verification"


const PendingStatus = () => {
  const {
    computeUserFullname,
    getOrganizationControllerId
  } = useOutletContext()

  const controllerId = getOrganizationControllerId()
  const accountantName = computeUserFullname(controllerId)

  const title = LABEL_TOOLTIP
    .replace('[name]', `${accountantName} `)

  return (
    <Tooltip
      title={title}
      placement="top"
    >
      <Tag
        icon={<ExclamationCircleOutlined />}
        style={{ cursor: "pointer" }}
        bordered={false}
      >
        {LABEL_PENDING}
      </Tag>
    </Tooltip>
  )
}

export default PendingStatus
