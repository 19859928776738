import PropTypes from "prop-types"

import { properties as readItemProperties } from "@api/services/investments/ReadInvestmentOutputShape"
import { properties as indexItemProperties } from "@api/services/investments/IndexMyInvestmentsOutputShape"
import { properties as entityShapeProperties } from "@api/services/investments/shapes/EntityShape"


const ReadInvestmentItemShape = PropTypes.exact({
  ...readItemProperties,
  profileEntity: PropTypes.exact({
    ...entityShapeProperties,
    _shouldProvideEin: PropTypes.bool,
    _shouldProvideBeneficialOwnerEin: PropTypes.bool,
  })
})

const IndexInvestmentsItemShape = PropTypes.exact({
  ...indexItemProperties,
  profileEntity: PropTypes.exact({
    ...entityShapeProperties,
    _shouldProvideEin: PropTypes.bool,
    _shouldProvideBeneficialOwnerEin: PropTypes.bool,
  })
})

const InvestmentShape = PropTypes.oneOfType([
  ReadInvestmentItemShape,
  IndexInvestmentsItemShape
])

export default InvestmentShape
