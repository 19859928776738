import React, { useMemo } from "react"
import { useOutletContext } from "react-router-dom"

import Identity from "@components/Identity"

const LABEL_LIAISON = "Liaison"


const useBrokerColumnProps = (hasAvatar = true) => {
  const {
    brokers,
    computeUserFullname
  } = useOutletContext()

  const filters = useMemo(() =>
    brokers
      .map(({ id }) => ({
        value: id,
        label: computeUserFullname(id)
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  , [ brokers, computeUserFullname ])

  return {
    key: "brokerUserId",
    title: LABEL_LIAISON,
    render: brokerUserId =>
      brokerUserId && (
        <Identity id={brokerUserId} hasAvatar={hasAvatar}>
          {computeUserFullname(brokerUserId)}
        </Identity>
      )
    ,
    filters,
    filterMultiple: true,
    isFilterEnabled: true,
  }
}

export default useBrokerColumnProps
