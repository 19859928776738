import { isSdiraInvestment, isEntityInvestment } from "@components/Investment"


const computeInvestmentName = (computeAccountFullname, investment) => {
  const isSdira = isSdiraInvestment(investment)
  const isEntity = isEntityInvestment(investment)

  const { name, investorAccountId } = investment
  const investorName = computeAccountFullname(investorAccountId)

  const isNotDefined = name === 'NA'

  if (isNotDefined) {
    return investorName
  }

  const shouldIncludeInvestorName = isSdira || isEntity

  if (shouldIncludeInvestorName) {
    return `${investorName} - ${name}`
  }

  return name
}

export default computeInvestmentName
