import { ROLES } from "@components/Domain"


const getUser = (userId, membersMap, accountsMap) => {
  const isInvestor = userId.startsWith("ACC")

  if (isInvestor) {
    const account = accountsMap[userId]

    if (!account) {
      return undefined
    }

    const { investor } = account

    return { ...investor, roles: [ ROLES.INVESTOR ] }
  }

  const member = membersMap[userId]

  if (!member) {
    return undefined
  }

  return member
}

export default getUser
