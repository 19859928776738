import { App } from "antd"
import { useOutletContext } from "react-router-dom"

import { confirmAction } from "@components/Page"
import { getFormattedDateTimeWithTimezone } from "@components/Date"

const SILENT = "SILENT"
const DEFAULT = "DEFAULT"

const ACTIONS_MAP = {
  [DEFAULT]: {
    type: "primary",
    title: "Upload File",
    action: "Upload",
    content: `By clicking "upload" below, file will be uploaded to the investor
portal. Investors WILL be notified via email at the scheduled time [SCHEDULED_AT].`,
    templateDisabledContent: `By clicking 'Upload' below, the file will be
uploaded to the investor portal. Investors will NOT be notified via email as
email notifications are currently turned off.`
  },
  [SILENT]: {
    type: "primary",
    title: "Upload File",
    action: "Upload",
    content: `By clicking "upload" below, file will be uploaded to the portal. Investors WILL NOT be notified.`,
  },
}


const useConfirmSubmit = () => {
  const { modal } = App.useApp()
  const { projectTemplatesStore } = useOutletContext()

  const { isInvestorFileCreatedTemplateEnabled } = projectTemplatesStore

  const confirm = (onConfirm, actionName, formattedScheduledAt) => {
    const {
      templateDisabledContent,
      content: templateEnabledContent
    } = ACTIONS_MAP[actionName]

    let content = templateEnabledContent.replace('[SCHEDULED_AT]', formattedScheduledAt)

    const isDefaultAction = actionName === DEFAULT

    if (isDefaultAction) {
      content =  isInvestorFileCreatedTemplateEnabled()
        ? content
        : templateDisabledContent
    }

    return confirmAction(
      modal,
      ACTIONS_MAP[actionName].action,
      ACTIONS_MAP[actionName].title,
      content,
      () => { onConfirm() },
      ACTIONS_MAP[actionName].type,
    )
  }

  const confirmSubmit = (parameters, onConfirm) => {
    const { isSent, scheduledAt } = parameters.mutation
    const formattedScheduledAt = getFormattedDateTimeWithTimezone(scheduledAt)

    return isSent
      ? confirm(onConfirm, SILENT)
      : confirm(onConfirm, DEFAULT, formattedScheduledAt)
  }

  return confirmSubmit
}

export default useConfirmSubmit
