import React from "react"
import { PlusOutlined, ImportOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"

const LABEL_CREATE = "Create"
const LABEL_IMPORT = "Import"


const getHeaderActions = ({
  openCreateModal,
  openImportModal,
  selectableActions = [],
  isInvalidBankAccount = false,
  hasDistributionsAccount = false,
}) => {
  const canCreateTransactions = hasAccess(['transactions-write'])

  if (!canCreateTransactions) {
    return selectableActions
  }

  const isCreateButtonDisabled =
    !hasDistributionsAccount ||
    isInvalidBankAccount

  const isImportButtonDisabled =
    hasDistributionsAccount &&
    isInvalidBankAccount

  const items = []

  items.push({
    icon: <PlusOutlined />,
    title: LABEL_CREATE,
    action: openCreateModal,
    disabled: isCreateButtonDisabled,
  })

  items.push({
    icon: <ImportOutlined />,
    title: LABEL_IMPORT,
    action: openImportModal,
    disabled: isImportButtonDisabled
  })

  return [
    ...selectableActions,
    ...items,
  ]
}

export default getHeaderActions
