import { HOST } from "@components"

import jsonRequest from "./jsonRequest"

const MAX_PER_PAGE_LIMIT = 300


const apiRequest = async (operation, parameters = {}, headers = {}) => {
  const isString = typeof operation === "string"

  if (!isString) {
    const { id } = operation
    operation = id
  }

  const isIndexOperation = operation.includes('/Index')
  const operationUrl = `${HOST}/api/${operation}`

  if (!isIndexOperation) {
    const result = await jsonRequest(operationUrl, parameters, headers)

    return result
  }

  const { limit: customLimit } = parameters

  let data = []
  let isLastPage = false
  let exclusiveStartKey = null

  do {
    const newParams = {
      limit: MAX_PER_PAGE_LIMIT,
      ...parameters,
    }

    if (exclusiveStartKey) {
      newParams.exclusiveStartKey = exclusiveStartKey
    }

    const response = await jsonRequest(operationUrl, newParams, headers)
    const { data: responseData, pageInfo: responsePageInfo } = response

    data = [...data, ...responseData]

    const { lastEvaluatedKey: nextExclusiveStartKey } = responsePageInfo

    exclusiveStartKey = nextExclusiveStartKey
    isLastPage = !exclusiveStartKey

    if (customLimit) {
      isLastPage = true
    }
  } while (!isLastPage)

  return { data }
}

export default apiRequest
