import React, { useRef } from "react"

import { W1 } from "@components"
import { Page } from "@components/Page"

import PropertyModal from "./PropertyModal"
import PropertiesTable from "./PropertiesTable"
import PropertyUpdateModal from "./PropertyUpdateModal"
import ProjectPropertyCreateModal from "./ProjectPropertyCreateModal"

const LABEL_TITLE = "Properties"


const PropertiesPage = () => {
  const table = useRef({})
  const modal = useRef({})
  const createModal = useRef({})
  const updateModal = useRef({})

  const onOpen = id =>
    modal.current.open(id)

  const onUpdate = id =>
    updateModal.current.openProperty(id)

  const onCreate = () =>
    createModal.current.openWithProperties()

  const onSearch = value =>
    table.current.search(value)

  const style = {
    padding: W1,
    paddingBottom: 0,
  }

  return (
    <Page
      style={style}
      title={LABEL_TITLE}
      onSearch={onSearch}
    >
      <PropertiesTable
        table={table}
        onOpen={onOpen}
        onCreate={onCreate}
        onUpdate={onUpdate}
      />

      <PropertyModal
        modal={modal}
      />

      <PropertyUpdateModal
        table={table}
        modal={updateModal}
      />

      <ProjectPropertyCreateModal
        table={table}
        modal={createModal}
      />
    </Page>
  )
}

export default PropertiesPage
