import React, { useCallback } from "react"
import { message, notification, Typography } from "antd"

const ERROR_TYPE = "error"
const SUCCESS_TYPE = "success"
const LOADING_TYPE = "loading"
const NOTIFICATION_PLACEMENT = "bottomRight"


const useAppMessage = () => {
  const [ messageApi, messageContextHolder ] = message.useMessage()
  const [ notificationApi, notificationContextHolder ] = notification.useNotification()

  const showErrorMessage = useCallback(
    (content, errorResponse) => {
      if (!errorResponse) {
        return messageApi.open({ type: ERROR_TYPE, content })
      }

      const descriptionContent = JSON.stringify(errorResponse, null, 2)

      const description = (
        <Typography style={{ fontSize: 12, height: 280, overflow: "auto" }}>
          <pre>{descriptionContent}</pre>
        </Typography>
      )

      const errorProps = {
        style: { width: 600 },
        message: content,
        placement: NOTIFICATION_PLACEMENT,
        description,
      }

      notificationApi.error(errorProps)
    }
  , [ messageApi, notificationApi ])

  const showSuccessMessage = useCallback(
    (content) => messageApi.open({ type: SUCCESS_TYPE, content })
  , [ messageApi ])

  const showLoadingMessage = useCallback(
    (content, methodAsync) => {
      messageApi.open({ type: LOADING_TYPE, content })

      methodAsync()
        .then(() => messageApi.destroy())
    }
  , [ messageApi ])

  const contextHolder = [
    messageContextHolder,
    notificationContextHolder
  ]

  return [
    contextHolder,
    showErrorMessage,
    showSuccessMessage,
    showLoadingMessage
  ]
}

export default useAppMessage
