import {
  ENTITY_TYPE_LLC,
  ENTITY_TYPE_S_CORP,
  ENTITY_TYPE_C_CORP,
  ENTITY_TYPE_CORPORATION,
  ENTITY_TYPE_PARTNERSHIP,
  ENTITY_MEMBERSHIP_INTEREST_TRUSTEE,
} from "@components/Domain"

const BUSINESS_TYPES_MAP = {
  [ENTITY_TYPE_LLC]: "LLC",
  [ENTITY_TYPE_S_CORP]: "CORPORATION",
  [ENTITY_TYPE_C_CORP]: "CORPORATION",
  [ENTITY_TYPE_CORPORATION]: "CORPORATION",
  [ENTITY_TYPE_PARTNERSHIP]: "PARTNERSHIP",
}


const getInvestmentCustomer = (customerId, investment, investorSsn) => {
  const { profileEntity } = investment

  const { membershipInterestsTitle } = profileEntity

  const isTrusteeMembershipInterest = membershipInterestsTitle === ENTITY_MEMBERSHIP_INTEREST_TRUSTEE

  const {
    title,
    address,
    entityType,
    name: businessName,
    einNumber: ein,
  } = profileEntity

  const { investor = {} } = investment
  const {
    address: investorAddress,
    givenName: firstName,
    familyName: lastName,
    dateOfBirth
  } = investor

  if (isTrusteeMembershipInterest) {
    const soleProprietorshipCustomer = {
      ssn: investorSsn,
      ein,
      lastName,
      firstName,
      dateOfBirth,
      businessName,
    }

    return { id: customerId, soleProprietorshipCustomer }
  }

  const businessType = BUSINESS_TYPES_MAP[entityType]

  const controller = {
    ssn: investorSsn,
    address: investorAddress,
    title,
    lastName,
    firstName,
    dateOfBirth,
  }

  const businessCustomer = {
    ein,
    address,
    lastName,
    firstName,
    businessName,
    businessType,
    controller,
  }

  return { id: customerId, businessCustomer }
}

export default getInvestmentCustomer
