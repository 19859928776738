import { useEffect, useState, useCallback } from "react"

import getInputs from "./getInputs"


const useInputs = (form, bankAccounts) => {
  const [ step, setStep ] = useState(0)
  const [ inputs, setInputs ] = useState([])

  const updateSchema = useCallback((currentStep) => {
    const goStep = toStep => {
      setStep(toStep)
      updateSchema(toStep)
    }

    const newInputs = getInputs(form, currentStep, goStep, bankAccounts)

    setInputs(newInputs)
  }, [
    form,
    bankAccounts,
  ])

  useEffect(() => {
    updateSchema(step)
  }, [
    step,
    updateSchema,
  ])

  return [ inputs, setStep ]
}

export default useInputs
