import { useOutletContext } from "react-router-dom"

import { getAddress } from "@components/Address"

import getPropertyTagLabelOptions from "../../ProjectPropertyCreateModal/helpers/getPropertyTagLabelOptions"


const getValueByTag = (tags, tagName) => {
  const tag = tags.find(({ label }) => label === tagName)

  return tag?.value
}

const useExportColumns = () => {
  const { properties, getProjectName } = useOutletContext()

  const propertyTagLabelOptions = getPropertyTagLabelOptions(properties)

  const items = [
    {
      title: "Name",
      value: ({name}) => name
    },
    {
      title: "Address",
      value: ({address}) => getAddress(address)
    },
  ]

  for (const { label } of propertyTagLabelOptions) {
    items.push({
      title: label,
      value: ({tags}) => getValueByTag(tags, label)
    })
  }

  items.push({
    title: "Project",
    value: ({projectId}) => getProjectName(projectId)
  })

  return items
}

export default useExportColumns
