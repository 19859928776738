import React from "react"

import FormHeader from "./FormHeader"


const ResetPasswordConfirmHeader = () => {
  return (
    <FormHeader
      heading="Set new password"
      text="If your email is registered, you should receive a verification code. Please enter it below to set your new password."
    />
  )
}

export default ResetPasswordConfirmHeader
