import React from "react"
import { NavLink } from "react-router-dom"
import { DeleteOutlined } from "@ant-design/icons"

const LABEL_DELETE = "Delete"

const getRowMenu = ({ id: batchId }, openDeleteConfirm) => {
  const items = [
    {
      key: "delete",
      icon: <DeleteOutlined />,
      label: (
        <NavLink onClick={() => openDeleteConfirm(batchId)}>
          {LABEL_DELETE}
        </NavLink>
      ),
      danger: true,
    }
  ]

  return items
}

export default getRowMenu
