import React from "react"

import { InvestmentShape } from "@components/Investment/shapes"

import InvestmentProfileCardItem from "../InvestmentProfileCardItem"
import {
  NOT_SIGNED_KEY,
  DANGER_STYLE_TYPE,
  INCOMPLETE_STATUS,
  BANK_ACCOUNT_NOT_READY_KEY,
} from "./helpers/Constants"

const LABELS_MAP = {
  [NOT_SIGNED_KEY]: {
    status: INCOMPLETE_STATUS,
    subtitle: `You can't proceed with funding until the subscription documents
are signed.`,
    styleType: DANGER_STYLE_TYPE,
  },
  [BANK_ACCOUNT_NOT_READY_KEY]: {
    status: INCOMPLETE_STATUS,
    subtitle: `Bank account details are not ready at the moment. Please check
back later or contact your sponsor if you have any questions.`,
    styleType: DANGER_STYLE_TYPE
  },
}


const FundNoTinInvestmentItem = ({
  investment,
  ...otherProps
}) => {
  const { isSigned, isPartiallySigned } = investment

  const isDocumentSigned = isSigned || isPartiallySigned

  let key = BANK_ACCOUNT_NOT_READY_KEY

  if (!isDocumentSigned) {
    key = NOT_SIGNED_KEY
  }

  const labels = LABELS_MAP[key]

  const itemProps = {
    ...labels,
    ...otherProps
  }

  return (
    <InvestmentProfileCardItem {...itemProps} />
  )
}

FundNoTinInvestmentItem.propTypes = {
  investment: InvestmentShape.isRequired,
}

export default FundNoTinInvestmentItem
