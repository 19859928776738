import PropTypes from "prop-types"


const properties = {
  accountMask: PropTypes.string.isRequired,
  accountOfficialName: PropTypes.string,
  accountSubtype: PropTypes.string,
  accountType: PropTypes.string.isRequired,
  bankId: PropTypes.string,
  bankName: PropTypes.string.isRequired,
  controllerEmail: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  customerId: PropTypes.string.isRequired,
  customerUrl: PropTypes.string.isRequired,
  fundingSourceUrl: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  isMicroDepositsRequested: PropTypes.bool,
  isMicroDepositsSubmitted: PropTypes.bool,
  isMicroDepositsVerifiable: PropTypes.bool,
  isVerificationFailed: PropTypes.bool,
  isVerified: PropTypes.bool,
  microDepositsRequestedAt: PropTypes.string,
  microDepositsSentAt: PropTypes.string,
  microDepositsSubmittedAt: PropTypes.string,
  name: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const SubmitMySourceMicroDepositsOutputShapeShape = PropTypes.exact(properties)

export default SubmitMySourceMicroDepositsOutputShapeShape
export { properties }
