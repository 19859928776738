import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { Button } from "antd"

import { ImportModal } from "@components/Page"

import SelectDocumentButton from "./SelectDocumentButton"
import TransactionsCollection from "./TransactionsCollection"
import useOnSubmitTransactions from "./helpers/useOnSubmitTransactions"

const LABEL_TITLE = "Import Transactions"
const LABEL_SUBMIT = "Create Transactions"


const TransactionsImportModal = ({ modal, onSuccess }) => {
  const collectionRef = useRef({})

  const [ errors, setErrors ] = useState()
  const [ progress, setProgress ] = useState()
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [ isSubmitDisabled, setIsSubmitDisabled ] = useState(false)

  const onClose = () => {
    setErrors()
    setProgress()

    collectionRef.current.setNewTransactions([])
  }

  const onSubmit = useOnSubmitTransactions(collectionRef, setIsSubmitting, setProgress, onSuccess, modal.current.close)

  const onUpdateCollection = items =>
    setIsSubmitDisabled(items.length > 0)

  const header = (
    <>
      <SelectDocumentButton
        onSuccess={(items) => collectionRef.current.setNewTransactions(items)}
        setErrors={setErrors}
      />
      <Button
        type="primary"
        style={{ float: "right" }}
        onClick={onSubmit}
        loading={isSubmitting}
        disabled={!isSubmitDisabled}
      >
        {LABEL_SUBMIT}
      </Button>
    </>
  )

  return (
    <ImportModal
      modal={modal}
      title={LABEL_TITLE}
      header={header}
      errors={errors}
      progress={progress}
      onClose={onClose}
    >
      <TransactionsCollection
        collection={collectionRef}
        onUpdateCollection={onUpdateCollection}
      />
    </ImportModal>
  )
}

TransactionsImportModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default TransactionsImportModal
