import PropTypes from "prop-types"

import { investorStoreProperties } from "@components/Store/InvestorStore/useInvestorStore"
import { ReadPublicPublishedProjectOutputShape } from "@api/services/backstage"


const InvestorProjectLayoutContextShape = PropTypes.exact({
  project: ReadPublicPublishedProjectOutputShape.isRequired,
  getProperty: PropTypes.func.isRequired,
  getInvestorSsnNumber: PropTypes.func.isRequired,
  ...investorStoreProperties,
})

export default InvestorProjectLayoutContextShape
