import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import Table from "@components/Table"
import { W1, W2 } from "@components"
import { indexOperation } from "@components/Store/InvestorStore/useTransactionsStore"
import { useProjectItems } from "@components/Project"
import InvestmentsStatistics from "@modules/investor/InvestorDashboardPage/InvestmentsStatistics"

import useTableColumns from "./helpers/useTableColumns"
import useExportColumns from "./helpers/useExportColumns"
import TransactionsStatistics from "./TransactionsStatistics"

const { Title } = Typography

const DEFAULT_SORT_COLUMN = "date"
const DEFAULT_SORT_COLUMN_DIRECTION = "descend"


const TransactionsTable = ({ title }) => {
  const table = useRef({})

  const {
    project,
    transactions,
    indexTransactions: indexItems,
  } = useOutletContext()

  const items = useProjectItems(transactions)
  const columnsConfig = useTableColumns()
  const exportColumns = useExportColumns()

  const size = "middle"
  const scroll = { x: 800 }
  const className = "table-custom-header"
  const exportFileName = "transactions"
  const hasHeaderFilters = true
  const defaultSortColumn = DEFAULT_SORT_COLUMN
  const defaultSortColumnDirection = DEFAULT_SORT_COLUMN_DIRECTION

  const tableProps = {
    size,
    items,
    table,
    scroll,
    className,
    indexItems,
    columnsConfig,
    exportColumns,
    exportFileName,
    indexOperation,
    hasHeaderFilters,
    defaultSortColumn,
    defaultSortColumnDirection
  }

  const titleStyle = {
    padding: "0 8px",
  }

  const statisticsStyle = {
    padding: "0 8px",
    marginTop: W1,
    marginBottom: W2,
  }

  const hasTitle = !project

  const statistics = project
    ? <InvestmentsStatistics />
    : <TransactionsStatistics style={statisticsStyle} />

  return (
    <>
      {statistics}

      {
        hasTitle && (
          <div style={titleStyle}>
            <Title level={3}>
              {title}
            </Title>
          </div>
        )
      }

      <Table {...tableProps} />
    </>
  )
}

TransactionsTable.propTypes = {
  title: PropTypes.string.isRequired,
}

export default TransactionsTable
