import React, { useRef, useMemo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Col, Row, Spin, Space } from "antd"

import { W1 } from "@components"
import { Title } from "@components/Text"
import { VirtualTable } from "@components/Table"
import { runRequest, useAppContext } from "@components/AppContext"
import {
  indexRecipientReportsOperation as indexOperation,
  readCampaignMetricsOperation,
} from "@api/services/campaigns"

import SearchInput from "./SearchInput"
import useExportColumns from "./helpers/useExportColumns"
import useColumnsConfig from "./helpers/useColumnsConfig"
import CampaignMetricsSummary from "./CampaignMetricsSummary"

const LABEL_TITLE = "Recipients"


const RecipientReportsTable = ({ campaignId }) => {
  const table = useRef({})

  const [ metrics, setMetrics ] = useState()

  const { request } = useAppContext()

  useEffect(() => {
    if (!campaignId) {
      return
    }

    return runRequest(() => {
      request(readCampaignMetricsOperation, { campaignId })
        .then(({ data }) => setMetrics(data))
    })
  }, [
    request,
    campaignId
  ])

  const onSearch = value =>
    table.current.search(value)

  const size = "small"
  const columnsConfig = useColumnsConfig()
  const exportColumns = useExportColumns()
  const exportFileName = "recipient_reports"
  const defaultSortColumn = "investorAccountId"
  const operationParameters = useMemo(() => ({ campaignId }), [ campaignId ])

  const pagination = {
    position: ["bottomLeft"],
    pageSize: 100,
    showSizeChanger: false,
    hideOnSinglePage: true,
  }

  const tableProps = {
    size,
    table,
    pagination,
    exportColumns,
    columnsConfig,
    exportFileName,
    indexOperation,
    defaultSortColumn,
    operationParameters,
  }

  const isReady = !!metrics

  if (!isReady) {
    return (
      <center><Spin /></center>
    )
  }

  return (
    <div style={{ padding: 16 }}>
      <Space
        size={W1}
        style={{ width: "100%" }}
        direction="vertical"
      >
        <CampaignMetricsSummary
          metrics={metrics}
        />

        <Row>
          <Col flex="auto">
            <Title level={4}>
              {LABEL_TITLE}
            </Title>
          </Col>

          <Col>
            <div style={{ marginTop: -4 }}>
              <SearchInput onSearch={onSearch} />
            </div>
          </Col>
        </Row>
      </Space>

      <VirtualTable {...tableProps} />
    </div>
  )
}

RecipientReportsTable.propTypes = {
  campaignId: PropTypes.string.isRequired,
}

export default RecipientReportsTable
