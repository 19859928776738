import React from "react"
import PropTypes from "prop-types"
import { Button } from "antd"

const LABEL_WIRE = "View and Download Wiring Instructions"


const OpenWireModalButton = ({
  isDisabled = false,
  onClick,
}) => {
  return (
    <Button
      shape="round"
      onClick={onClick}
      disabled={isDisabled}
    >
      {LABEL_WIRE}
    </Button>
  )
}

OpenWireModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
}

export default OpenWireModalButton
