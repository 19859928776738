import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Typography, Tag, Space } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"

import CustomerShape from "../../shapes/CustomerShape"
import UploadDocumentsModal from "../UploadDocumentsModal"
import DocumentsReviewStatus from "../DocumentsReviewStatus"
import { PersonalCustomerUpgradeModal } from "../../CustomerUpgradeButton"
import {
  CUSTOMER_STATUS_RETRY,
  CUSTOMER_STATUS_DOCUMENT,
  CUSTOMER_STATUS_VERIFIED,
  CUSTOMER_STATUS_SUSPENDED,
  CUSTOMER_STATUS_UNVERIFIED,
  CUSTOMER_STATUS_PENDING_DOCUMENT_VERIFICATION,
} from "../../Constants"

const LABEL_VERIFY = "Verification Required"
const LABEL_VERIFIED = "Verified"
const LABEL_SUSPENDED = "Suspended"
const LABEL_UNEXPECTED = "Unexpected Status"
const LABEL_VERIFICATION = "Identity Verification"


const PersonalCustomerVerificationButton = ({
  title = undefined,
  onSuccess = () => {},
  investment = undefined,
  isModalOpen = false,
  customer,
}) => {
  const modal = useRef({})

  const { authenticatedRequest, updateMyCustomerVerificationStatus } = useAppContext()

  if (!customer) {
    return null
  }

  const { verificationStatus: status } = customer

  const isPendingDocumentVerification = status === CUSTOMER_STATUS_PENDING_DOCUMENT_VERIFICATION

  if (isPendingDocumentVerification) {
    return (
      <DocumentsReviewStatus />
    )
  }

  const isVerified = status === CUSTOMER_STATUS_VERIFIED

  if (isVerified) {
    return (
      <Tag color="green">
        {LABEL_VERIFIED}
      </Tag>
    )
  }

  const isSuspended = status === CUSTOMER_STATUS_SUSPENDED

  if (isSuspended) {
    return (
      <Tag color="red">
        {LABEL_SUSPENDED}
      </Tag>
    )
  }

  const onClick = () =>
    modal.current.open()

  title = title || (
    <Space>
      <ExclamationCircleOutlined />
      <span>{LABEL_VERIFY}</span>
    </Space>
  )

  const updateCustomerVerificationStatus = async () => {
    const updatedCustomer = await updateMyCustomerVerificationStatus(authenticatedRequest, customer.id)

    const isCustomerVerified = updatedCustomer.verificationStatus === CUSTOMER_STATUS_VERIFIED

    if (isCustomerVerified) {
      await onSuccess(updatedCustomer)
    }

    modal.current.close()
  }

  const isRetry = status === CUSTOMER_STATUS_RETRY
  const isDocument = status === CUSTOMER_STATUS_DOCUMENT
  const isUnverified = status === CUSTOMER_STATUS_UNVERIFIED

  const isUnexpectedStatus =
    !isRetry &&
    !isDocument &&
    !isUnverified

  if (isUnexpectedStatus) {
    return (
      <Tag>
        {LABEL_UNEXPECTED}
      </Tag>
    )
  }

  return (
    <>
      <Typography.Link onClick={onClick}>
        <span style={{ whiteSpace: "nowrap" }}>
          {title}
        </span>
      </Typography.Link>

      {
        isDocument && (
          <UploadDocumentsModal
            modal={modal}
            customer={customer}
            onSuccess={updateCustomerVerificationStatus}
            isModalOpen={isModalOpen}
          />
        )
      }

      {
        isRetry && investment && (
          <PersonalCustomerUpgradeModal
            modal={modal}
            title={LABEL_VERIFICATION}
            isRetry={true}
            onSuccess={updateCustomerVerificationStatus}
            investment={investment}
            customerId={customer.id}
          />
        )
      }
    </>
  )
}

PersonalCustomerVerificationButton.propTypes = {
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.shape() ]),
  customer: CustomerShape.isRequired,
  onSuccess: PropTypes.func,
  investment: InvestmentShape,
  isModalOpen: PropTypes.bool,
}

export default PersonalCustomerVerificationButton
