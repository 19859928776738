import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Alert, Button, Typography } from "antd"
import keyBy from "lodash.keyby"
import { useOutletContext } from "react-router-dom"

import { W0 } from "@components"
import { Markdown } from "@components/Text"

const LABEL_DESCRIPTION = "Email {EMAIL} already taken by [{NAME}]({ACCOUNT_LINK})"


const InvestorExistsAlert = ({
  email = undefined,
  openInvestorDrawer
}) => {
  const {
    accounts,
  } = useOutletContext()

  const accountsMapByEmail = useMemo(() =>
    keyBy(accounts, 'investor.email')
  , [ accounts ])


  if (!email) {
    return null
  }

  const normalizedEmail = email.toLowerCase().trim()
  const account = accountsMapByEmail[normalizedEmail]

  if (!account) {
    return null
  }

  const { id, investor = {} } = account

  const { givenName = "", familyName = "" } = investor
  const fullName = `${givenName} ${familyName}`

  const description = LABEL_DESCRIPTION
    .replace("{EMAIL}", normalizedEmail)
    .replace("{NAME}", fullName)
    .replace("{ACCOUNT_LINK}", id)

  const components = {
    p(props) {
      // eslint-disable-next-line react/prop-types
      const { children } = props
      return (
        <Typography.Text>
          {children}
        </Typography.Text>
      )
    },
    a(props) {
      // eslint-disable-next-line react/prop-types
      const { children, href } = props
      return (
        <Button
          type="link"
          onClick={() => openInvestorDrawer(href)}
        >
          {children}
        </Button>
      )
    }
  }

  return (
    <Alert
      type="warning"
      style={{ marginTop: W0 }}
      message={<Markdown text={description} components={components} />}
    />
  )
}

InvestorExistsAlert.propTypes = {
  email: PropTypes.string,
  openInvestorDrawer: PropTypes.func.isRequired,
}

export default InvestorExistsAlert
