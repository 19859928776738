import { TRANSACTION_STATUS } from "@components/Domain"


const computeDistributionStatus = transactions => {
  const isError = transactions
    .filter(({ status }) => status === TRANSACTION_STATUS.ERROR)
    .length > 0

  const isFailed = transactions
    .filter(({ status }) => status === TRANSACTION_STATUS.FAILED)
    .length > 0

  const isRejected = transactions
    .filter(({ status }) => status === TRANSACTION_STATUS.REJECTED)
    .length > 0

  const isCancelled = transactions
    .filter(({ status }) => status === TRANSACTION_STATUS.CANCELLED)
    .length > 0

  const isPendingApproval = transactions
    .filter(({ status, isApproved }) => status === TRANSACTION_STATUS.PENDING && !isApproved)
    .length > 0

  const isPending = transactions
    .filter(({ status, isApproved }) => status === TRANSACTION_STATUS.PENDING && isApproved)
    .length > 0

  if (isError) {
    return TRANSACTION_STATUS.ERROR
  }

  if (isFailed) {
    return TRANSACTION_STATUS.FAILED
  }

  if (isRejected) {
    return TRANSACTION_STATUS.REJECTED
  }

  if (isPendingApproval) {
    return TRANSACTION_STATUS.PENDING_APPROVAL
  }

  if (isPending) {
    return TRANSACTION_STATUS.PENDING
  }

  if (isCancelled) {
    return TRANSACTION_STATUS.CANCELLED
  }

  return TRANSACTION_STATUS.PROCESSED
}

export default computeDistributionStatus
