
import createUploadUrl from "./createUploadUrl"


const upload = async (request, isPublic, contentType, body) => {
  const uploadUrl = await createUploadUrl(request, isPublic, contentType)

  const headers = { 'Content-Type': contentType }
  const options = { method: "PUT", body, headers }

  await fetch(uploadUrl, options)

  const [ url ] = uploadUrl.split('?')
  const [ path ] = uploadUrl.split('.com/')[1].split('?')

  const response = {
    url,
    path,
    isPublic
  }

  return response
}

export default upload
