import React from "react"
import { Form, Input } from "antd"

import { TYPE_ARRAY } from "../types"

const { Item } = Form

const VALIDATION_ERRORS = {
  email: "Invalid email address",
  upload: "File upload is required",
  default: "Field is required",
}


const renderInput = (form, itemProps, inputProps) => {
  const { type } = itemProps
  const { disabled } = inputProps

  const isDisabledFunction = disabled && typeof disabled === 'function'

  if (isDisabledFunction) {
    inputProps.disabled = inputProps.disabled(form)
  }

  if (type) {
    const message = VALIDATION_ERRORS[type] || VALIDATION_ERRORS.default
    itemProps.rules.push({ type, message })
  }

  return (
    <Item {...itemProps}>
      <Input
        /* td: figure out processing for array of objects or strings */
        disabled={type === TYPE_ARRAY}
        {...inputProps}
      />
    </Item>
  )
}

export default renderInput
