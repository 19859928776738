import React from "react"
import PropTypes from "prop-types"

import List from "@components/List"
import { indexPropertiesOperation as indexOperation } from "@api/services/backstage"

import PropertyShape from "../../../shapes/PropertyShape"
import useRenderItem from "./helpers/useRenderItem"

const LABEL_TITLE = "Properties"


const FundPropertiesList = ({ properties: items, openPropertyModal }) => {
  const render = useRenderItem()

  const indexItems = () =>
    items

  const onClick = ({ id }) =>
    openPropertyModal(id)

  const listProps = {
    title: LABEL_TITLE,
    items,
    render,
    onClick,
    indexItems,
    indexOperation,
  }

  return (
    <List {...listProps} />
  )
}

FundPropertiesList.propTypes = {
  properties: PropTypes.arrayOf(PropertyShape).isRequired,
  openPropertyModal: PropTypes.func.isRequired,
}

export default FundPropertiesList
