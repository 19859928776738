import PropTypes from "prop-types"

import { UsStateEnumKeys } from "./UsStateEnum"

const properties = {
  hasOtherUsResidenceState: PropTypes.bool,
  hasPurchasedExemptSecurities: PropTypes.bool,
  hasSsn: PropTypes.bool,
  isLiabilityAccepted: PropTypes.bool,
  isMarriedAndSeparateProperty: PropTypes.bool,
  isUsCitizen: PropTypes.bool,
  isUsPerson: PropTypes.bool,
  residenceState: PropTypes.oneOf(UsStateEnumKeys),
  ssnNumber: PropTypes.string
}

const IndividualShape = PropTypes.exact(properties)

export default IndividualShape
export { properties }
