import React, { useEffect, useCallback } from "react"
import { Spin, Layout, theme } from "antd"

import { useInvestorStore } from "@components/Store"
import { W1, W2, W5, WIDTH_MAX, isMobile } from "@components"
import { ContactUs, PlatformFooter, ReleaseAlert } from "@components/Page"

import Header from "./Header"
import SecondFactorAlert from "./SecondFactorAlert"
import InvalidSourcesAlert from "./InvalidSourcesAlert"
import InvestorLayoutOutlet from "./InvestorLayoutOutlet"
import ImpersonatedAccountAlert from "./ImpersonatedAccountAlert"

import "./InvestorLayout.css"

const INVESTMENTS_PATH = "/investments"


const InvestorLayout = () => {
  const { token } = theme.useToken()
  const { colorBgLayout, colorBgContainer } = token

  const { isReady, context: investorContext } = useInvestorStore()

  const {
    profiles,
    getProject,
    investments,
  } = investorContext

  useEffect(() => {
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1")
  }, [])

  const getInvestorSsnNumber = useCallback(
    () => {
      const entities = [ ...profiles, ...investments ]

      for (const entity of entities) {
        const { profileIndividual, profileJoint } = entity

        if (profileIndividual) {
          return profileIndividual.ssnNumber
        }

        if (profileJoint) {
          return profileJoint.ssnNumber
        }
      }
    }
  , [ investments, profiles ])

  const isInvestmentsPage = window.location.pathname.startsWith(INVESTMENTS_PATH)

  const layoutStyle = isInvestmentsPage
    ? {
        marginBottom: isMobile ? W5 * 3 : W2,
        backgroundColor: colorBgContainer
      }
    : {
        minHeight: 100,
        marginBottom: isMobile ? W5 * 3 : 0,
        backgroundColor: colorBgContainer
      }

  const headerStyle = {
    top: 0,
    zIndex: 1,
    padding: 0,
    position: "sticky",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    backgroundColor: colorBgLayout,
  }

  const layoutContentStyle = isInvestmentsPage
    ? {}
    : {
        width: "100%",
        margin: "0 auto",
        padding: isMobile ? 0 : `${W1}px ${W1}px`,
        maxWidth: WIDTH_MAX,
      }

  if (!isMobile) {
    layoutContentStyle.minHeight = "60vh"
  }

  const renderAlerts = () => {
    if (isInvestmentsPage) {
      return <ImpersonatedAccountAlert />
    }

    return (
      <>
        <ReleaseAlert />

        <ImpersonatedAccountAlert />

        <SecondFactorAlert />

        <InvalidSourcesAlert
          getProject={getProject}
          investments={investments}
        />
      </>
    )
  }

  const renderHeader = () => {
    if (isInvestmentsPage) {
      return null
    }

    return (
      <Layout.Header style={headerStyle}>
        <Header />
      </Layout.Header>
    )
  }

  const context = {
    ...investorContext,
    getInvestorSsnNumber,
  }

  return (
    <Spin spinning={!isReady}>
      <Layout style={layoutStyle}>
        {
          isReady && (
            <>
              <div className="header-alerts">
                {renderAlerts()}
              </div>

              {renderHeader()}

              <Layout.Content style={layoutContentStyle}>
                <InvestorLayoutOutlet context={context}/>
              </Layout.Content>

              <ContactUs />

              <PlatformFooter />
            </>
          )
        }
      </Layout>
    </Spin>
  )
}

export default InvestorLayout
