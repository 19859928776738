import React, { useEffect } from "react"
import { Space, Button } from "antd"
import { useOutletContext, useNavigate, useParams } from "react-router-dom"

import { W1 } from "@components"
import { wait } from "@api"
import { AccountCard } from "@modules/investor/InvestorAccountPage"
import { BlockingModal } from "@components/Page"
import { PROJECT_STATES } from "@components/Domain"

import useInvestmentWatch from "./helpers/useInvestmentWatch"
import scrollToInvestment from "./helpers/scrollToInvestment"
import InvestmentProfileCard from "./InvestmentProfileCard"

const LABEL_PENDING = `Please keep this window open. We're currently updating
the status of your subscription documents.`
const LABEL_DASHBOARD = "Back to Dashboard"

const HOME_PATH = "/dashboard"


const InvestmentFinalizePage = () => {
  const navigate = useNavigate()
  const { projectId, investmentId } = useParams()

  const isPendingStatusUpdate = useInvestmentWatch()

  const {
    project,
    currentForm,
    indexInvestments,
    getProjectInvestments,
  } = useOutletContext()

  const projectInvestments = getProjectInvestments(projectId)

  currentForm.current.submit = () => {}

  useEffect(() => {
    const hasInvestments = projectInvestments.length > 0

    if (!hasInvestments) {
      navigate(HOME_PATH)
      return
    }

    if (!investmentId) {
      return
    }

    if (isPendingStatusUpdate) {
      scrollToInvestment(investmentId)
      return
    }

    const unsignedInvestment = projectInvestments
      .find(({ isPartiallySigned }) => !isPartiallySigned)

    if (!unsignedInvestment) {
      return
    }

    scrollToInvestment(unsignedInvestment.id)
  }, [
    navigate,
    projectId,
    investmentId,
    projectInvestments,
    isPendingStatusUpdate,
  ])

  const backButtonStyle = {
    width: "100%",
    height: 50,
    fontWeight: 600
  }

  const isReadOnly = project.state === PROJECT_STATES.CLOSED

  const { investorErrors = [] } = projectInvestments[0] || {}

  const onAfterInvestorUpdate = () =>
    indexInvestments()
      .then(() => wait(250))

  return (
    <Space
      size={W1}
      style={{ width: "100%" }}
      direction="vertical"
    >
      <AccountCard
        isReadOnly={isReadOnly}
        onAfterUpdate={onAfterInvestorUpdate}
        investorErrors={investorErrors}
      />

      {
        projectInvestments.map((investment) =>
          <InvestmentProfileCard
            key={investment.id}
            investment={investment}
          />
        )
      }

      <Button
        shape="round"
        style={backButtonStyle}
        onClick={() => navigate(HOME_PATH)}
      >
        {LABEL_DASHBOARD}
      </Button>

      <BlockingModal
        text={LABEL_PENDING}
        isOpen={isPendingStatusUpdate}
      />
    </Space>
  )
}

export default InvestmentFinalizePage
