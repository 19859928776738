import React from "react"

import { UsTimeInput } from "../../Input"


const renderTime = (itemProps, inputProps, form, path) => {
  return (
    <UsTimeInput
      path={path}
      form={form}
      itemProps={itemProps}
      inputProps={inputProps}
    />
  )
}

export default renderTime
