import React from "react"
import PropTypes from "prop-types"

import { getTimeZoneOptions } from "@components/Date"
import { ItemPropsShape, InputPropsShape } from "@api"

import FormShape from "../shapes/FormShape"
import SelectInput from "./SelectInput"

const LABEL_PLACEHOLDER = "Select time zone"


const TimeZoneInput = ({
  form,
  request,
  itemProps,
  inputProps,
}) => {
  const options = getTimeZoneOptions()

  const extendedInputProps = {
    ...inputProps,
    placeholder: LABEL_PLACEHOLDER
  }

  return <SelectInput
    form={form}
    request={request}
    itemProps={itemProps}
    inputProps={extendedInputProps}
    isMultiple={false}
    options={options}
  />
}

TimeZoneInput.propTypes = {
  form: FormShape.isRequired,
  request: PropTypes.func.isRequired,
  itemProps: ItemPropsShape.isRequired,
  inputProps: InputPropsShape.isRequired,
}

export default TimeZoneInput
