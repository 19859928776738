import React, { useRef, useMemo } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { Typography, Space, Tooltip } from "antd"
import { WarningOutlined } from "@ant-design/icons"

import ProjectUpdateModal from "../ProjectUpdateModal"

const LABEL_CHANGE = "Change"
const LABEL_ACCOUNT = "Account"
const LABEL_INVALID = "Invalid Bank Account"
const LABEL_MISSING = "The distribution account is missing."
const LABEL_BACKFILL = "to link one. Distributions can be imported in backfill mode."
const LABEL_CLICK_HERE = "Click here"


const DistributionBankAccount = () => {
  const modal = useRef({})

  const { id: projectId } = useParams()

  const {
    getProject,
    getBankAccountName,
    isInvalidBankAccount
  } = useOutletContext()

  const project = getProject(projectId)

  const distributionBankAccountId = useMemo(() =>
    project.distributionBankAccountId
  , [ project ])

  if (!project) {
    return null
  }

  const onClick = () =>
    modal.current.open([ "distributionBankAccountId" ])

  const messsage = distributionBankAccountId
    ? (
        <Space>
          <Typography.Text strong>{LABEL_ACCOUNT}:</Typography.Text>
          {
            isInvalidBankAccount(distributionBankAccountId) && (
              <Tooltip
                placement="top"
                title={LABEL_INVALID}
              >
                <WarningOutlined style={{ color: "red" }} />
              </Tooltip>
            )
          }
          {getBankAccountName(distributionBankAccountId)}
          <Typography.Link onClick={onClick}>{LABEL_CHANGE}</Typography.Link>
        </Space>
      )
    : (
        <Typography.Text>
          {LABEL_MISSING}
          {" "}
          <Typography.Link onClick={onClick}>{LABEL_CLICK_HERE}</Typography.Link>
          {" "}
          {LABEL_BACKFILL}
        </Typography.Text>
      )

  return (
    <>
      <Typography.Paragraph>
        {messsage}
      </Typography.Paragraph>

      <ProjectUpdateModal
        modal={modal}
        project={project}
      />
    </>
  )
}

export default DistributionBankAccount
