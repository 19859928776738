import React from "react"
import PropTypes from "prop-types"
import { Image } from "antd"

import { getConfig } from "@components/Config"

import LOGO_POWERED_BY_PATH from "./powered-by.png"

const PLATFORM_NAME = getConfig("PLATFORM_NAME")


const PoweredByLogo = ({
  width = "100%",
  style = {},
}) => {
  return (
    <Image
      alt={`Powered by ${PLATFORM_NAME}`}
      src={LOGO_POWERED_BY_PATH}
      width={width}
      style={style}
      preview={false}
    />
  )
}

PoweredByLogo.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  style: PropTypes.shape(),
}

export default PoweredByLogo
