import { getTransactionStatusLabel } from "@components/Transaction"
import { TRANSACTION_STATUS_PENDING, TRANSACTION_STATUS_PENDING_APPROVAL } from "@components/Domain"


const computeState = (status, isApproved) => {
  const isPendingApproval = status === TRANSACTION_STATUS_PENDING && !isApproved

  if (isPendingApproval) {
    status = TRANSACTION_STATUS_PENDING_APPROVAL
  }

  return getTransactionStatusLabel(status)
}

export default computeState
