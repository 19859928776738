import React from "react"
import { ExclamationCircleFilled } from '@ant-design/icons'

const LABEL_YES = "Yes"


const confirmAction = (modal, okText, title, content, onOk, okType) => {
  const onCancel = () => {}

  modal.confirm({
    icon: <ExclamationCircleFilled />,
    okText: okText || LABEL_YES,
    okType,
    onOk,
    title,
    content,
    onCancel
  })
}

export default confirmAction
