import React, { useState } from "react"
import PropTypes from "prop-types"

import { Modal } from "@components/Page"
import Form, { useForm } from "@components/Form"
import { useAppContext } from "@components/AppContext"
import { createMyCustomerBeneficialOwnerOperation } from "@api/services/transactions"

import useInputs from "./helpers/useInputs"

const LABEL_TITLE = "Add Owner"
const LABEL_CONTINUE = "Continue"


const BeneficialOwnerCreateModal = ({
  onSuccess: onSuccessCustom,
  modal,
  customerId,
}) => {
  const form = useForm()
  const [ isOpen, setIsOpen ] = useState(false)

  const { authenticatedRequest } = useAppContext()
  const [ schema, updateSchema ] = useInputs(createMyCustomerBeneficialOwnerOperation, form)

  const onClose = () => {
    form.resetFields()
    setIsOpen(false)
  }

  modal.current.open = () => {
    updateSchema()
    setIsOpen(true)
  }

  const onSuccess = async () => {
    await onSuccessCustom()
    onClose()
  }

  const onSubmit = parameters => {
    parameters.customerId = customerId

    return parameters
  }

  const formOptions = {
    request: authenticatedRequest,
    operation: createMyCustomerBeneficialOwnerOperation,
    submitLabel: LABEL_CONTINUE,
    shouldOptimizeUpdateMutation: false,
    form,
    schema,
    onSubmit,
    onSuccess,
  }

  return (
    <Modal
      title={LABEL_TITLE}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Form
        {...formOptions}
      />
    </Modal>
  )
}

BeneficialOwnerCreateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
}

export default BeneficialOwnerCreateModal
