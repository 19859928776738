import React from "react"
import PropTypes from "prop-types"

import { useAppContext } from "@components/AppContext"
import Form, { FormShape } from "@components/Form"
import { useUpdateSchema } from "@modules/investor/InvestorAccountPage"

const LABEL_FAILED = "Failed to update investor information"


const UpdateMyAccountForm = ({
  onSuccess: onSuccessCustom,
  form,
  ...otherFormProps
}) => {
  const {
    request,
    identity,
    updateIdentity,
    showErrorMessage
  } = useAppContext()

  const shouldIncludeTimeZone = false
  const shouldIncludeAccreditationNote = true

  const [ schema, operation ] = useUpdateSchema(form, shouldIncludeAccreditationNote, shouldIncludeTimeZone)

  const onSuccess = updatedIdentity => {
    updateIdentity(updatedIdentity)
    return onSuccessCustom()
  }

  const { isImmutable } = identity

  const item = isImmutable ? identity.investor : identity

  const formProps = {
    onFailure: () => showErrorMessage(LABEL_FAILED),
    form,
    item,
    schema,
    request,
    onSuccess,
    operation,
    ...otherFormProps
  }

  return (
    <Form {...formProps} />
  )
}

UpdateMyAccountForm.propTypes = {
  form: FormShape.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default UpdateMyAccountForm
