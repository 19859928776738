import React, { useRef, useMemo } from "react"
import PropTypes from "prop-types"

import { isMobile } from "@components"
import PropertyShape from "@api/services/backstage/shapes/PropertyShape"
import { PropertyModal } from "@modules/backstage/properties"

import FundPropertiesList from "./FundPropertiesList"
import FundPropertiesTable from "./FundPropertiesTable"
import getSortedProperties from "./helpers/getSortedProperties"


const FundProperties = ({ properties, isEditable }) => {
  const modal = useRef({})

  const sortedProperties = useMemo(() =>
    getSortedProperties(properties)
  , [ properties ])

  const openPropertyModal = propertyId =>
    modal.current.open(propertyId)

  const content = isMobile
    ? <FundPropertiesList
        properties={sortedProperties}
        openPropertyModal={openPropertyModal}
      />
    : <FundPropertiesTable
        isEditable={isEditable}
        properties={sortedProperties}
        openPropertyModal={openPropertyModal}
      />

  return (
    <>
      {content}

      <PropertyModal
        modal={modal}
      />
    </>
  )
}

FundProperties.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  properties: PropTypes.arrayOf(PropertyShape).isRequired,
}

export default FundProperties
