import React from "react"
import PropTypes from "prop-types"
import { EditableGroup } from "@components/Button"

import { useProjectDetailsContext } from "./ProjectDetailsContext"


const ProjectEditableGroup = ({
  title,
  fieldNames,
  modalWidth = undefined,
  extraProps = {},
  children
}) => {
  const { openProjectUpdateModal } = useProjectDetailsContext()

  return (
    <EditableGroup
      title={title}
      onClick={() => openProjectUpdateModal(fieldNames, modalWidth)}
      {...extraProps}
    >
      {children}
    </EditableGroup>
  )
}

ProjectEditableGroup.propTypes = {
  title: PropTypes.string.isRequired,
  fieldNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  extraProps: PropTypes.shape(),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  modalWidth: PropTypes.number,
}

export default ProjectEditableGroup
