import React from "react"

import { W0, W1 } from "@components"
import { Markdown, MarkdownAlert, Disclaimer } from "@components/Text"
// eslint-disable-next-line no-unused-vars
import { TYPE_BOOLEAN, TYPE_RADIO, TYPE_UPLOAD, TYPE_HIDDEN } from "@components/Form"

import validateFileSize from "./validateFileSize"
import getFailedReasons from "./getFailedReasons"
import {
  FILE_TYPES,
  // eslint-disable-next-line no-unused-vars
  PASSPORT_TYPE,
  DOCUMENT_TYPES,
  NON_US_DOCUMENT_TYPES,
  CONTROLLER_VERIFICATION_STEPS
} from "./Constants"

const LABEL_FILE = "[CONTROLLER]'s Document"
const LABEL_TYPE = "[CONTROLLER]'s Document Type"
const LABEL_PASSPORT = "[CONTROLLER]'s Foreign Passport"
// eslint-disable-next-line no-unused-vars
const LABEL_US_PERSON = "Is [CONTROLLER] a US person?"
const LABEL_DOCUMENT_TYPE = "Must be a color scan of U.S. government-issued identification"
const LABEL_CONTROLLER_FILE_INPUT = "File format: JPEG or PNG, maximum size: 10 megabytes"

const LABEL_CONTROLLER_DOCUMENT_US = `Please upload the [CONTROLLER]'s identifying
document such as a state-issued driver's license, or U.S. government-issued
photo ID card.

Supported Documents:

* Non-expired State Issued Driver’s License / Identification Card
* Non-expired US Passport
* Federal Employment Authorization Card
* US Visa

Unsupported Documents:

* Military IDs
* Expired government-issued IDs`

const LABEL_CONTROLLER_DOCUMENT_NON_US = `Please upload the [CONTROLLER]'s
non-expired Foreign Passport (Note: Foreign Passports are only accepted if the
individual does not have an ITIN or SSN. Enter the Passport number.)`

const getLabel = (value, isSoleProprietorship = false) =>
  isSoleProprietorship
    ? value.replace('[CONTROLLER]', 'business owner')
    : value.replace('[CONTROLLER]', 'controller')

const getLabel2 = (value, isSoleProprietorship = false) =>
  isSoleProprietorship
    ? value.replace('[CONTROLLER]', 'Business owner')
    : value.replace('[CONTROLLER]', 'Controller')


const getControllerInputs = (customer, form, updateSchema, showErrorMessage) => {
  const {
    verificationStep,
    isSoleProprietorship,
    identityVerificationFailedReasons = []
  } = customer

  const shouldRender =
    !verificationStep ||
    CONTROLLER_VERIFICATION_STEPS.includes(verificationStep)

  if (!shouldRender) {
    return []
  }

  // NOTE: For now, do not support non-US persons, and when we decide to
  //       support those, we can identify isUsPerson during customer upgrade.
  const isUsPerson = true
  // const { _isUsPerson: isUsPerson } = form.getFieldsValue()

  const documentTypes = isUsPerson
    ? DOCUMENT_TYPES
    : NON_US_DOCUMENT_TYPES

  const isUsPersonDefined = isUsPerson !== undefined

  // inputs.push(
  //   {
  //     name: "_isUsPerson",
  //     type: TYPE_BOOLEAN,
  //     label: LABEL_US_PERSON,
  //     required: true,
  //     inputProps: {
  //       onChange: (event) => {
  //         const hasSetUsPerson = event.target.value

  //         if (hasSetUsPerson) {
  //           form.setFieldValue('identityDocumentType', undefined)

  //         } else {
  //           form.setFieldValue('identityDocumentType', PASSPORT_TYPE)

  //         }

  //         updateSchema()
  //       }
  //     },
  //     itemProps: {
  //       style: { marginBottom: 0 },
  //     }
  //   },
  // )

  if (!isUsPersonDefined) {
    return []
  }

  const extra = isUsPerson
    ? <Disclaimer text={LABEL_DOCUMENT_TYPE} style={{ marginTop: W0 }} />
    : null

  const text = isUsPerson
    ? getLabel(LABEL_CONTROLLER_DOCUMENT_US, isSoleProprietorship)
    : getLabel(LABEL_CONTROLLER_DOCUMENT_NON_US, isSoleProprietorship)

  const supportedDocumentsKey = 'INDIVIDUAL'
  const reasons = getFailedReasons(identityVerificationFailedReasons, supportedDocumentsKey)

  const hasVerificationFailedReasons = identityVerificationFailedReasons.length > 0

  const inputs = [
    {
      name: "_controllerDocument",
      component: ({ style }) => (
        <div style={style}>
          {
            hasVerificationFailedReasons && (
              <MarkdownAlert text={reasons} type="warning" />
            )
          }

          {
            !hasVerificationFailedReasons && (
              <Markdown text={text} />
            )
          }
        </div>
      )
    }
  ]

  if (isUsPerson) {
    inputs.push(
      {
        name: "identityDocumentType",
        type: TYPE_RADIO,
        label: getLabel2(LABEL_TYPE, isSoleProprietorship),
        options: documentTypes,
        required: true,
        itemProps: {
          style: { marginTop: W1 },
          extra,
        },
      }
    )

  } else {
    inputs.push(
      {
        name: "identityDocumentType",
        type: TYPE_HIDDEN,
      }
    )

  }

  const label = isUsPerson
    ? getLabel2(LABEL_FILE, isSoleProprietorship)
    : getLabel2(LABEL_PASSPORT, isSoleProprietorship)

  inputs.push(
    {
      name: "identityDocumentUrl",
      type: TYPE_UPLOAD,
      label,
      required: true,
      inputProps: {
        isPublic: false,
        fileTypes: FILE_TYPES,
        beforeUpload: file => validateFileSize(file, showErrorMessage),
      },
      itemProps: {
        extra: <Disclaimer text={LABEL_CONTROLLER_FILE_INPUT} style={{ marginTop: W0 }} />
      }
    },
  )

  return inputs
}

export default getControllerInputs
