import React from "react"
import { Card } from "antd"

import { TYPE_NONE } from "@components/Form"
import { W1, isMobile } from "@components"

import ProfilesListInput from "../ProfilesListInput"
import AccreditationNote from "../AccreditationNote"

const LABEL_ACCOUNT_TITLE = "My Information"
const LABEL_EMAIL = "Email Address"
const LABEL_MOBILE = "Contact Phone Number"
const LABEL_LAST_NAME = "Legal Last Name"
const LABEL_FIRST_NAME = "Legal First Name"
const LABEL_ACCREDITATION = "Are you an accredited investor?"


const getInputs = (tiers, brokers) => {
  return [
    {
      name: "projectId",
      type: TYPE_NONE,
    },
    {
      name: "brokerUserId",
      type: TYPE_NONE,
    },
    {
      name: "investor",
      label: "",
      schema: [
        {
          name: "email",
          label: LABEL_EMAIL,
        },
        {
          name: "givenName",
          label: LABEL_FIRST_NAME
        },
        {
          name: "familyName",
          label: LABEL_LAST_NAME
        },
        {
          name: "mobileNumber",
          label: LABEL_MOBILE,
          required: true
        },
        {
          name: "hasAccreditation",
          label: LABEL_ACCREDITATION,
          itemProps: {
            style: { marginBottom: 0 },
            extra: <AccreditationNote />,
          },
          initialValue: null,
        }
      ],
      containerComponent: ({ children }) => (
        <Card
          type="inner"
          size={ isMobile ? "small" : "medium" }
          style={{ marginBottom: W1 }}
          title={LABEL_ACCOUNT_TITLE}
        >
          {children}
        </Card>
      ),
    },
    {
      name: "profiles",
      component: ProfilesListInput,
      componentProps: {
        tiers,
        brokers,
        initialValue: [{ investments: [] }]
      }
    }
  ]
}

export default getInputs
