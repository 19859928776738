import PropTypes from "prop-types"


const properties = {
  group: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string
}

const TagShape = PropTypes.exact(properties)

export default TagShape
export { properties }
