import PropTypes from "prop-types"

import { properties } from "@api/services/investments/shapes/CommitmentProfileShape"
import { ProfileTypeEnumKeys } from "@api/services/investments/shapes/ProfileTypeEnum"


const CommitmentProfileShape = PropTypes.exact({
  ...properties,
  type: PropTypes.oneOf(ProfileTypeEnumKeys),
})

export default CommitmentProfileShape
