import React from "react"
import PropTypes from "prop-types"

import TableRowActions from "@components/Table/TableRowActions"

import getRowMenu from "./helpers/getRowMenu"
import useInvestmentActions from "./helpers/useInvestmentActions"


const RowActions = ({ investment }) => {
  const actions = useInvestmentActions(investment)
  const items = getRowMenu(investment, actions)

  return (
    <TableRowActions items={items} />
  )
}

RowActions.propTypes = {
  investment: PropTypes.shape().isRequired,
}

export default RowActions
