import React, { useRef, useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { Typography, Checkbox } from "antd"
import uniq from "lodash.uniq"

import { W1 } from "@components"
import { Bold } from "@components/Text"
import { SelectInput } from "@components/Form/Input"
import { FormShape, useWatch } from "@components/Form"

import useRecipientOptOutsStore from "./stores/useRecipientOptOutsStore"

import useFilter from "./helpers/useFilter"
import useOptions from "./helpers/useOptions"
import RecipientsModal from "./RecipientsModal"
import computeRecipientAccountIds from "./helpers/computeRecipientAccountIds"

const LABEL_ALL = "Send to all investors"
const LABEL_FROM = "To"
const LABEL_REVIEW = "Review "
const LABEL_INVESTORS = "[X] investors"
const LABEL_UNSUBSCRIBED = " out of [Y] who would receive the campaign"

const FIELD_RECIPIENT_ACCOUNT_IDS = "recipientAccountIds"


const CampaignToInput = ({
  form,
  path,
  name,
  isDisabled
}) => {
  const recipientsModal = useRef({})

  const [ isAll, setIsAll ] = useState(false)
  const [ unsubscribedCount, setUnsubscribedCount ] = useState(0)

  const { segments, accounts, getAccount } = useOutletContext()

  const segmentIds = useWatch(path, form)
  const subscriptionTypeId = useWatch("subscriptionTypeId", form)
  const recipientAccountIds = useWatch(FIELD_RECIPIENT_ACCOUNT_IDS, form)

  const { isInvestorOptedOut, optOutsMap } = useRecipientOptOutsStore(subscriptionTypeId)
  const options = useOptions(subscriptionTypeId, isInvestorOptedOut)

  const filter = useFilter(accounts)

  const isRecipient = useCallback(account => {
    const hasEmails = ({ email, alternativeEmail, campaignEmails = [] }) =>
      [ email, alternativeEmail, ...campaignEmails ]
        .filter(value => !!value)
        .length > 0

    const isAccountActive = account.isDisabled !== true
    const isAccountSubscribed = account.isSubscribedForCampaigns === true
    const hasEmail = hasEmails(account.investor)
    const hasNotOptedOut = !isInvestorOptedOut(account.investor)

    const shouldRecieve =
      isAccountActive &&
      isAccountSubscribed &&
      hasEmail &&
      hasNotOptedOut

    return shouldRecieve
  }, [
    isInvestorOptedOut
  ])

  useEffect(() => {
    if (isDisabled) {
      return
    }

    const filteredSegmentIds = segmentIds || []

    const optionsIds = filteredSegmentIds.filter(segmentId => segmentId.startsWith("OPT_"))
    const accountsIds = filteredSegmentIds.filter(segmentId => segmentId.startsWith("ACC_"))

    const optionsAccountIds = computeRecipientAccountIds(filter, segments, optionsIds)
    const filteredAccountIds = uniq([...accountsIds, ...optionsAccountIds])

    const newRecipientAccountIds = filteredAccountIds
      .filter(id => isRecipient(getAccount(id)))

    const newUnsubscribedCount = filteredAccountIds.length - newRecipientAccountIds.length
    setUnsubscribedCount(newUnsubscribedCount)

    form.setFieldValue(FIELD_RECIPIENT_ACCOUNT_IDS, newRecipientAccountIds)
  }, [
    form,
    filter,
    segments,
    segmentIds,
    optOutsMap,
    getAccount,
    isDisabled,
    isRecipient,
  ])

  useEffect(() => {
    if (!isAll) {
      return
    }

    const ids = accounts
      .filter(account => isRecipient(account))
      .map(({ id }) => id)

    form.setFieldValue(FIELD_RECIPIENT_ACCOUNT_IDS, ids)
  }, [
    form,
    isAll,
    accounts,
    optOutsMap,
    isRecipient,
  ])

  const openRecipientsModal = () =>
    recipientsModal.current.open()

  const itemProps = {
    label: <Bold>{LABEL_FROM}</Bold>,
    name,
  }

  const inputProps = {
    size: "large",
    variant: "filled",
    disabled: isDisabled || isAll,
  }

  const selectInputProps = {
    isMultiple: true,
    form,
    options,
    itemProps,
    inputProps,
  }

  const hasSelectedSegments = segmentIds && segmentIds.length > 0

  const style = { marginTop: -1 * W1, marginBottom: W1 }
  const recipientsCount = (recipientAccountIds || []).length

  return (
    <>
      <SelectInput {...selectInputProps} />

      {
        hasSelectedSegments && (
          <div style={style}>
            {LABEL_REVIEW}
            <Typography.Link onClick={openRecipientsModal}>
              {LABEL_INVESTORS.replace("[X]", recipientsCount)}
            </Typography.Link>{
              unsubscribedCount > 0 && (
                LABEL_UNSUBSCRIBED.replace("[Y]", unsubscribedCount)
              )
            }
          </div>
        )
      }

      {
        !hasSelectedSegments && (
          <div style={style}>
            <Checkbox
              checked={isAll}
              onChange={e => setIsAll(e.target.checked)}
            >
              {LABEL_ALL}
            </Checkbox>
          </div>
        )
      }

      <RecipientsModal
        modal={recipientsModal}
        accountIds={recipientAccountIds}
      />
    </>
  )
}

CampaignToInput.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default CampaignToInput
