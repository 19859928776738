import React from "react"
import { PlusOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"

const LABEL_TITLE = "Add [ENTITY]"
const LABEL_STAFF = "Staff"
const LABEL_USER = "User"


const getHeaderActions = (isConsoleOrganization, openCreateModal) => {
  const canCreateMembers =
    hasAccess(['users-write']) ||
    hasAccess(['fund-users-write'])

  if (!canCreateMembers) {
    return []
  }

  const entity = isConsoleOrganization
    ? LABEL_USER
    : LABEL_STAFF

  const title = LABEL_TITLE
    .replace('[ENTITY]', entity)

  return [
    {
      icon: <PlusOutlined />,
      action: openCreateModal,
      title,
    },
  ]
}

export default getHeaderActions
