import React from "react"
import PropTypes from "prop-types"
import { getData } from "country-list"

import { ItemPropsShape, InputPropsShape } from "@api"

import FormShape from "../shapes/FormShape"
import SelectInput from "./SelectInput"

const countries = getData()

const DEFAULT_OPTIONS = countries
  .map(({ code: value, name: label }) => ({ label, value }))


const CountryInput = ({ form, request, itemProps, inputProps }) => {
  return (
    <SelectInput
      form={form}
      request={request}
      options={DEFAULT_OPTIONS}
      itemProps={itemProps}
      isMultiple={false}
      inputProps={inputProps}
    />
  )
}

CountryInput.propTypes = {
  form: FormShape.isRequired,
  request: PropTypes.func.isRequired,
  itemProps: ItemPropsShape.isRequired,
  inputProps: InputPropsShape.isRequired,
}

export default CountryInput
