

const getFilterValues = (columns, getFilterValue) => {
  const formatValue = (dataIndex, value) => `${dataIndex}:${value}`

  return columns
    .reduce((acc, column) => [
      ...acc,
      ...(getFilterValue(column.dataIndex) || []).map(value => formatValue(column.dataIndex, value)),
    ], [])
}

export default getFilterValues
