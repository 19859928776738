import * as Sentry from "@sentry/react"
import { useAppContext } from "@components/AppContext"

import optimizeMutation from "./optimizeMutation"

const LOG_SUBMIT_FAILED = "Failed to submit data"

const EXPECTED_ERRORS = [
  'UnprocessibleConditionError',
]


const useFinishProps = ({
  item,
  request,
  onSubmit,
  onSuccess,
  onFailure,
  operation,
  isSubmitting,
  expectedErrors,
  onSubmitFailed,
  onSubmitConfirm,
  setIsSubmitting,
  shouldOptimizeUpdateMutation,
}) => {
  const { showErrorMessage } = useAppContext()

  const { isUpdate } = operation

  const shouldIncludeId = operation.isUpdate
    && item
    && item.id

  const parameters = shouldIncludeId
    ? { id: item.id }
    : {}

  const onOperationSuccess = async ({ data }) => {
    await onSuccess(data)
    setIsSubmitting(false)
  }

  const onOperationError = error => {
    const { isExpected, message, originalError } = error

    if (!isExpected) {
      console.error(error)
      Sentry.captureException(error)
    }

    if (isExpected) {
      // NOTE: When submit fails with expected error, we show friendly error
      //       message on the top of the page, e.g. invalid parameters error.
      showErrorMessage(originalError?.message || message)
    }

    setIsSubmitting(false)
    onSubmitFailed()
  }

  const onFinish = async formItem => {
    const shouldOptimizeMutation = isUpdate && shouldOptimizeUpdateMutation

    const mutation = shouldOptimizeMutation
      ? optimizeMutation(formItem, item)
      : formItem

    const shouldSubmit = Object.keys(mutation).length > 0

    if (!shouldSubmit) {
      return onSuccess(formItem)
    }

    const onSubmitAsync = async () =>
      onSubmit({ ...parameters, mutation }, item)

    return onSubmitConfirm({ ...parameters, mutation }, () => {
      if (isSubmitting) {
        return
      }

      setIsSubmitting(true)

      return onSubmitAsync()
        .then(operationParameters => request(operation, operationParameters, [...expectedErrors, ...EXPECTED_ERRORS]))
        .then(onOperationSuccess)
        .catch(onOperationError)
    })
  }

  const onFinishFailed = error => {
    console.error(LOG_SUBMIT_FAILED, error)
    return onFailure()
  }

  if (!request) {
    return {}
  }

  const formProps = !request
    ? {}
    : {
      onFinish,
      onFinishFailed,
    }

  return formProps
}

export default useFinishProps
