import React from "react"
import { Typography } from "antd"
import { Link } from "react-router-dom"

import Identity from "@components/Identity"

import getOrganizationStatus from "./getOrganizationStatus"

const { Text } = Typography


const getTableColumns = (membersMap) => {
  const nameColumnProps = {
    key: "name",
    title: "Name",
    render: value => <Text strong>{value}</Text>,
    isSearchable: true
  }

  const domainColumnProps = {
    key: "domain",
    render: (value, {domain}) => {
      const url = `https://${domain}`
      return <Link to={url} target="_blank">{value}</Link>
    },
    isSearchable: true,
  }

  const domainStatusColumnProps = {
    key: "domainStatus",
    title: "Status",
    render: (_, organization) => getOrganizationStatus(organization),
  }

  const createdByColumnProps = {
    key: "createdBy",
    title: "Operated By",
    render: userId => membersMap[userId] ?
      <Identity
        size="small"
        identity={membersMap[userId]}
      /> : userId
  }

  return [
    { ...nameColumnProps },
    { ...domainColumnProps },
    { ...domainStatusColumnProps },
    { ...createdByColumnProps },
  ]
}

export default getTableColumns
