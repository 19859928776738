import { getUsDateFromDate } from "@components/Date"


const useInvestorAccreditation = (hasAccreditation, accreditationExpiresAt) => {
  if (hasAccreditation === undefined) {
    return
  }

  if (!hasAccreditation) {
    return "No"
  }

  if (!accreditationExpiresAt) {
    return "Unverified"
  }

  const todayTime = new Date().getTime()
  const accreditationExpiresTime = Date.parse(accreditationExpiresAt)
  const accreditationExpiresUsDate = getUsDateFromDate(accreditationExpiresAt)

  if (todayTime > accreditationExpiresTime) {
    return `Unverified, expired on ${accreditationExpiresUsDate}`
  }

  return `Yes, expires on ${accreditationExpiresUsDate}`
}

export default useInvestorAccreditation
