import PropTypes from "prop-types"

import { useAppContext } from "@components/AppContext"
import {
  upload,
  getImgixUrl,
  readFileContent,
  getResizedImageUrl
} from "@components/Storage"
import {
  readCampaignOperation as readOperation,
  cloneCampaignOperation as cloneOperation,
  indexCampaignsOperation as indexOperation,
  deleteCampaignOperation as deleteOperation,
  createCampaignOperation as createOperation,
  updateCampaignOperation as updateOperation,
  publishCampaignOperation as publishOperation,
  unpublishCampaignOperation as unpublishOperation,
  updateCampaignTagsOperation as updateTagsOperation,
  IndexCampaignsOutputShape
} from "@api/services/campaigns"

import useStore from "../helpers/useStore"

const HEIC_MIMETYPE = "image/heic"


const useCampaignsStore = () => {
  const { request } = useAppContext()

  const shouldAutoLoad = false

  const {
    items: campaigns,
    getItem: getCampaign,
    addItem: addCampaign,
    removeItem: removeCampaign,
    updateItem: updateCampaign,
    indexItems: indexCampaigns,
  } = useStore(indexOperation, request, shouldAutoLoad)

  const deleteCampaign = id =>
    request(deleteOperation, { id })
      .then(() => removeCampaign(id))

  const tagCampaign = (id, tagId) => {
    const campaign = getCampaign(id)
    const { tagIds = [] } = campaign
    const mutation = { tagIds: [ ...tagIds, tagId ] }

    return request(updateTagsOperation, { id, mutation })
      .then(({ data }) => updateCampaign({ ...campaign, ...data }))
  }

  const untagCampaign = (id, droppedTagId) => {
    const campaign = getCampaign(id)
    const { tagIds = [] } = campaign
    const mutation = { tagIds: [ ...tagIds.filter(tagId => tagId !== droppedTagId) ] }

    return request(updateTagsOperation, { id, mutation })
      .then(({ data }) => updateCampaign({ ...campaign, ...data }))
  }

  const uploadCampaignAssets = async (campaign, files, getRandomId, title, type) => {
    const isImage = type === "IMAGE"

    const newAssets = []

    for (const file of files) {
      const assetId = getRandomId()
      const content = await readFileContent(file)

      const {
        type: mimeType,
        name,
        size,
        lastModified
      } = file

      const isPublic = true

      const { url } = await upload(request, isPublic, mimeType, content)

      const isHeicImage = mimeType === HEIC_MIMETYPE
      const imageUrl = isHeicImage ? getImgixUrl(url, { q: 30 }) : url

      const asset = {
        id: assetId,
        type,
        url: imageUrl,
        size,
        name,
        mimeType,
        lastModified,
      }

      if (title) {
        asset.title = title
      }

      if (isImage) {
        asset.thumb = getResizedImageUrl(url, 200, 120)
      }

      newAssets.push(asset)
    }

    const { id, assets = [] } = campaign
    const mutation = { assets: [ ...newAssets, ...assets ] }
    const parameters = { id, mutation }

    const updatedCampaign = await request(updateOperation, parameters)
      .then(({ data }) => {
        updateCampaign(data)
        return data
      })

    return [ newAssets, updatedCampaign ]
  }

  return {
    campaigns,
    addCampaign,
    getCampaign,
    tagCampaign,
    untagCampaign,
    indexCampaigns,
    updateCampaign,
    deleteCampaign,
    uploadCampaignAssets,
  }
}

const campaignsStoreProperties = {
  campaigns: PropTypes.arrayOf(IndexCampaignsOutputShape),
  addCampaign: PropTypes.func.isRequired,
  getCampaign: PropTypes.func.isRequired,
  tagCampaign: PropTypes.func.isRequired,
  untagCampaign: PropTypes.func.isRequired,
  indexCampaigns: PropTypes.func.isRequired,
  updateCampaign: PropTypes.func.isRequired,
  deleteCampaign: PropTypes.func.isRequired,
  uploadCampaignAssets: PropTypes.func.isRequired,
}

export default useCampaignsStore

export {
  readOperation,
  indexOperation,
  cloneOperation,
  deleteOperation,
  createOperation,
  updateOperation,
  publishOperation,
  unpublishOperation,
  updateTagsOperation,
  campaignsStoreProperties,
}
