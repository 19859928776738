import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { getAuthentication } from "@components/Authorization"
import { PROJECT_STATE_OPEN } from "@components/Domain"
import { DOCUMENT_NOT_FOUND_ERROR } from "@api"
import { readPublicPublishedProjectOperation } from "@api/services/backstage"
import { useRequest as useEffect, useAppContext } from "@components/AppContext"

const LABEL_UNEXPECTED_ERROR = "Unexpected Error"
const LABEL_UNEXPECTED_ERROR_DESCRIPTION = `An unexpected error happend. Please
make sure the URL is correct. Reach out to us if the issue persists.`

const LABEL_NOT_FOUND = "Project Not Found"
const LABEL_NOT_FOUND_ERROR_DESCRIPTION = `
  Soft commit form link is not valid or expired. If you think that's a mistake,
  please contact us to get a new one.
`


const usePublishedProject = (projectId, sponsorId) => {
  const navigate = useNavigate()

  const { updateAuthentication } = useAppContext()

  const [ error, setError ] = useState()
  const [ project, setProject ] = useState()


  useEffect(request => {
    const isBackstage = false
    const investorAuthenticationJwt = getAuthentication(isBackstage)

    if (investorAuthenticationJwt) {
      navigate(`/investments/${projectId}`)
      updateAuthentication(investorAuthenticationJwt)

      return
    }

    const parameters = { id: projectId, sponsorId }
    const expectedErrors = [ DOCUMENT_NOT_FOUND_ERROR ]

    request(readPublicPublishedProjectOperation, parameters, expectedErrors)
      .then(({ data }) => {
        const { state } = data

        const isOpen = state === PROJECT_STATE_OPEN

        if (!isOpen) {
          setError({
            title: LABEL_NOT_FOUND,
            message: LABEL_NOT_FOUND_ERROR_DESCRIPTION,
          })

          return
        }

        setProject(data)
      })
      .catch(exception => {
        const { isExpected: isProjectNotFound } = exception

        if (!isProjectNotFound) {
          setError({
            title: LABEL_UNEXPECTED_ERROR,
            message: LABEL_UNEXPECTED_ERROR_DESCRIPTION
          })

          return
        }

        setError({
          title: LABEL_NOT_FOUND,
          message: LABEL_NOT_FOUND_ERROR_DESCRIPTION,
        })
      })
  }, [
    navigate,
    sponsorId,
    projectId,
    updateAuthentication,
  ])

  return [ project, error ]
}

export default usePublishedProject
