import React from "react"
import { NavLink } from "react-router-dom"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"

const LABEL_EDIT = "Edit Details"
const LABEL_DELETE = "Remove"


const getRowMenu = ({ id: propertyId }, onUpdate, onRemove) => {
  const items = [
    {
      key: "update",
      icon: <EditOutlined />,
      label: (
        <NavLink onClick={() => onUpdate(propertyId)}>
          {LABEL_EDIT}
        </NavLink>
      )
    },
    {
      type: "divider"
    },
    {
      key: "remove",
      icon: <DeleteOutlined />,
      danger: true,
      label: (
        <NavLink onClick={() => onRemove(propertyId)}>
          {LABEL_DELETE}
        </NavLink>
      )
    }
  ]

  return items
}

export default getRowMenu
