import React from "react"
import { Outlet } from "react-router-dom"

import BackstageProjectLayoutContextShape from "./shapes/BackstageProjectLayoutContextShape"


const BackstageProjectLayoutOutlet = ({ context }) => {
  return (
    <Outlet context={context} />
  )
}

BackstageProjectLayoutOutlet.propTypes = {
  context: BackstageProjectLayoutContextShape.isRequired,
}

export default BackstageProjectLayoutOutlet
