import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button } from "antd"


const HeaderButton = ({
  otherProps = {},
  icon,
  title,
  action,
}) => {
  const [ isLoading, setIsLoading ] = useState(false)

  const onClick = async () => {
    setIsLoading(true)
    await action()
    setIsLoading(false)
  }

  return (
    <Button
      icon={icon}
      type="text"
      size="small"
      loading={isLoading}
      onClick={onClick}
      {...otherProps}
    >
      {title}
    </Button>
  )
}

HeaderButton.propTypes = {
  icon: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  otherProps: PropTypes.shape(),
}

export default HeaderButton
