import {
  createUnverifiedPersonalCustomerOperation,
  createUnverifiedBusinessCustomerOperation,
  createReceiveOnlyBusinessCustomerOperation,
  createImpersonatedUnverifiedPersonalCustomerOperation,
  createImpersonatedUnverifiedBusinessCustomerOperation,
  createImpersonatedReceiveOnlyBusinessCustomerOperation
} from "@api/services/transactions"

import normalizeCustomerName from "./normalizeCustomerName"


const getCustomerMutation = (
  identity,
  businessName,
  isReceiveOnly,
  impersonatedInvestorAccountId,
  getOrganizationId
) => {
  const { investor } = identity
  const { givenName: firstName, familyName: lastName } = investor || identity

  const mutation = {
    firstName,
    lastName,
  }

  const isImpersonated = !!impersonatedInvestorAccountId

  if (isImpersonated) {
    mutation.organizationId = getOrganizationId()
    mutation.investorAccountId = impersonatedInvestorAccountId
  }

  let operation

  if (!businessName) {
    operation = isImpersonated
      ? createImpersonatedUnverifiedPersonalCustomerOperation
      : createUnverifiedPersonalCustomerOperation

    return [ operation, mutation ]
  }

  mutation.businessName = normalizeCustomerName(businessName)

  operation = isImpersonated
    ? createImpersonatedUnverifiedBusinessCustomerOperation
    : createUnverifiedBusinessCustomerOperation

  if (isReceiveOnly) {
    operation = isImpersonated
     ? createImpersonatedReceiveOnlyBusinessCustomerOperation
     : createReceiveOnlyBusinessCustomerOperation
  }

  return [ operation, mutation ]
}

export default getCustomerMutation
