import React from "react"
import { App } from "antd"
import { useOutletContext } from "react-router-dom"
import { ExclamationCircleFilled } from '@ant-design/icons'

import { useAppContext } from "@components/AppContext"
import { deletePropertyOperation as deleteOperation } from "@api/services/backstage"

const LABEL_YES = "Yes"
const LABEL_DELETE = "Delete Property"
const LABEL_DELETE_MESSAGE = "Property $ is deleted"
const LABEL_DELETE_DESCRIPTION = `
  Property is going to be deleted. You won't be able to restore it.
`


const usePropertyDeleteConfirm = () => {
  const { modal } = App.useApp()

  const {
    indexProjects,
    deleteProperty,
    removeProjectProperty,
  } = useOutletContext()

  const {
    request,
    showSuccessMessage
  } = useAppContext()

  return ({ id, name, projectId }) => {
    const onOk = () =>
      request(deleteOperation, { id })
        .then(() => removeProjectProperty(projectId, id))
        .then(() => indexProjects())
        .then(() => deleteProperty(id))
        .then(() => showSuccessMessage(LABEL_DELETE_MESSAGE.replace("$", `"${name}"`)))

    modal.confirm({
      icon: <ExclamationCircleFilled />,
      title: LABEL_DELETE,
      okText: LABEL_YES,
      okType: "danger",
      content: LABEL_DELETE_DESCRIPTION,
      onOk,
    })
  }
}

export default usePropertyDeleteConfirm
