import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import Table from "@components/Table"
import { useAppContext } from "@components/AppContext"
import { indexUsersOperation as indexOperation } from "@api/services/backstage"

import { UserShape } from "../shapes"
import useColumnsConfig from "./helpers/useColumnsConfig"
import getHeaderActions from "./helpers/getHeaderActions"
import useFilterItemsCallback from "./helpers/useFilterItemsCallback"


const UsersTable = ({
  items,
  table,
  openCreateModal,
  openUpdateModal,
}) => {
  const { isConsoleOrganization } = useAppContext()
  const { indexUsers: indexItems } = useOutletContext()

  const columnsConfig = useColumnsConfig(openUpdateModal)
  const filterItemsCallback = useFilterItemsCallback()

  const size = "small"
  const className = "last-column-action"
  const headerActions = getHeaderActions(isConsoleOrganization, openCreateModal)
  const defaultSortColumn = "name"

  const tableProps = {
    size,
    items,
    table,
    className,
    indexItems,
    headerActions,
    columnsConfig,
    indexOperation,
    defaultSortColumn,
    filterItemsCallback,
  }

  return (
    <Table {...tableProps} />
  )
}

UsersTable.propTypes = {
  items: PropTypes.arrayOf(UserShape).isRequired,
  table: PropTypes.shape().isRequired,
  openCreateModal: PropTypes.func.isRequired,
  openUpdateModal: PropTypes.func.isRequired,
}

export default UsersTable
