import React, { useRef } from "react"
import { useOutletContext } from "react-router-dom"

import { W0, W1 } from "@components"
import { Organization, OrganizationUpdateModal } from "@modules/backstage/organizations"

const LABEL_UPDATE_DOCUSIGN_TITLE = "Edit DocuSign Settings"


const SettingsOrganizationPage = () => {
  const updateModal = useRef({})
  const updateDocusignModal = useRef({})

  const { organization } = useOutletContext()

  const style = {
    padding: `${W1}px ${W1 + W0}px`,
    maxWidth: 650,
  }

  const openUpdateDocusign = () =>
    updateDocusignModal.current.openItem(organization)

  const openUpdateOrganization = () =>
    updateModal.current.openItem(organization)

  return (
    <div style={style}>
      <Organization
        organization={organization}
        openUpdateDocusign={openUpdateDocusign}
        openUpdateOrganization={openUpdateOrganization}
      />

      <OrganizationUpdateModal
        key="updateOrganization"
        modal={updateModal}
        organization={organization}
      />

      <OrganizationUpdateModal
        key="updateDocusign"
        modal={updateDocusignModal}
        title={LABEL_UPDATE_DOCUSIGN_TITLE}
        organization={organization}
        onlyFieldNames={[
          "docusignAccountId",
          "docusignWebhookSecret"
        ]}
      />
    </div>
  )
}

export default SettingsOrganizationPage
