import { wait } from "@api"


const getDummyRequest = callback => {
  return ({ file, onSuccess }) => {
    setTimeout(async () => {
      callback(file)

      await wait(25)
      onSuccess("ok")
    }, 0)
  }
}

export default getDummyRequest
