import React, { useRef } from "react"
import PropTypes from "prop-types"
import { EyeOutlined } from "@ant-design/icons"
import { useAppContext } from "@components/AppContext"
import { useOutletContext } from "react-router-dom"
import { Alert, Space, Typography } from "antd"

import { W2, isMobile } from "@components"
import { InvestmentShape } from "@components/Investment/shapes"
import { getCurrencyValue } from "@components/Amount"
import { getInvestmentSourceSelectProps } from "@components/Investment"
import { IndexPublishedProjectsOutputShape } from "@api/services/backstage"
import { ConnectSourceButton, CustomerShape } from "@components/Dwolla"

import SendFundsButton from "../SendFundsButton"
import SendFundsConfirmModal from "./SendFundsConfirmModal"
import ConnectPlaidConfirmModal from "./ConnectPlaidConfirmModal"
import ConnectBankAccountManuallyConfirmModal from "./ConnectBankAccountManuallyConfirmModal"

const LABEL_WIRE = "View and Download Wiring Instructions"
const LABEL_IMPERSONATED = "Impersonated investor is not able to fund investment."


const FundInvestment = ({
  customer = undefined,
  project,
  investment,
  onOpenFundingModal
}) => {
  const sendFundsConfirmModal = useRef({})
  const connectPlaidConfirmModal = useRef({})
  const connectBankAccountManuallyConfirmModal = useRef({})

  const { updateInvestment } = useOutletContext()
  const { request, isImpersonated } = useAppContext()

  const canSendFunds = isImpersonated
  const hasCustomer = !!customer

  const {
    totalAmount,
    receivedAmount,
    isPartiallyFunded
  } = investment

  const fundingAmount = isPartiallyFunded
    ? `${getCurrencyValue(receivedAmount)} of ${getCurrencyValue(totalAmount)}`
    : getCurrencyValue(totalAmount)

  if (canSendFunds) {
    return (
      <Alert
        showIcon
        type="warning"
        message={LABEL_IMPERSONATED}
      />
    )
  }

  const isSender = true

  const onInvestmentSourceChanged = (data, isNewSource) => {
    updateInvestment(data)

    // NOTE: Do not show send funds confirm modal, as we've shown
    // one prior to show create source modal.
    if (isNewSource) {
      return
    }

    const { senderSourceId } = investment
    const isInvestmentSourceSetFirstTime = !senderSourceId

    if (isInvestmentSourceSetFirstTime) {
      return sendFundsConfirmModal.current.open()
    }
  }

  const connectSenderSourceProps =
    getInvestmentSourceSelectProps({
      request,
      isSender,
      investment,
      onInvestmentSourceChanged,
      connectBankAccountPlaidConfirmModal: connectPlaidConfirmModal,
      connectBankAccountManuallyConfirmModal
    })

  const extraItems = [
    {
      type: "divider"
    },
    {
      key: "wire-manual",
      icon: <EyeOutlined />,
      label:
        <Typography.Link onClick={() => onOpenFundingModal()}>
          {LABEL_WIRE}
        </Typography.Link>
    }
  ]

  const dropdownProps = {
    isManualDisabled: false,
    isVerificationRequired: true,
    extraItems,
  }

  connectSenderSourceProps.investment = investment
  connectSenderSourceProps.dropdownProps = {
    ...connectSenderSourceProps.dropdownProps,
    ...dropdownProps
  }

  const style = {
    display: "flex",
    justifyContent: "space-between"
  }

  const layoutProps = {
    style,
  }

  if (isMobile) {
    layoutProps.size = W2
    layoutProps.direction = "vertical"
  }

  const connectPlaidConfirmModalProps = {
    modal: connectPlaidConfirmModal,
    investment,
  }

  return (
    <Space {...layoutProps}>
      <ConnectSourceButton
        {...connectSenderSourceProps}
      />

      <ConnectPlaidConfirmModal
        {...connectPlaidConfirmModalProps}
      />

      {
        hasCustomer && (
          <SendFundsButton
            project={project}
            customer={customer}
            investment={investment}
          />
        )
      }

      <SendFundsConfirmModal
        modal={sendFundsConfirmModal}
        investment={investment}
      />

      <ConnectBankAccountManuallyConfirmModal
        modal={connectBankAccountManuallyConfirmModal}
        amount={fundingAmount}
      />
    </Space>
  )
}

FundInvestment.propTypes = {
  project: IndexPublishedProjectsOutputShape.isRequired,
  customer: CustomerShape,
  investment: InvestmentShape.isRequired,
  onOpenFundingModal: PropTypes.func.isRequired,
}

export default FundInvestment
