import React, { useRef } from "react"
import { PlusOutlined } from "@ant-design/icons"
import { Button, Typography, Space } from "antd"

import { W0 } from "@components"
import { getConfig } from "@components/Config"

import OrganizationEmailIdentityCreateModal from "../OrganizationEmailIdentityCreateModal"

const PLATFORM_EMAIL = getConfig('PLATFORM_EMAIL')
const [ , PLATFORM_HOST ] = PLATFORM_EMAIL.split('@')
const DEFAULT_CAMPAIGN_FROM_USER = 'marketing'

const LABEL_TITLE = "Custom Domain"
const LABEL_CREATE = "Connect Domain"

const MAX_WIDTH = 540


const OrganizationEmailIdentityConnect = () => {
  const createModal = useRef({})

  const openCreateEmailIdentity = () =>
    createModal.current.open()

  return (
    <div style={{ maxWidth: MAX_WIDTH }}>
      <Space
        direction="vertical"
        style={{ width: "100%" }}
      >
        <Typography.Title
          level={5}
          style={{ marginTop: 0, marginBottom: W0 / 2 }}
        >
          {LABEL_TITLE}
        </Typography.Title>

        <Typography.Paragraph>
          {`You can use a custom domain as the FROM address for your email
            campaigns. This will allow you to send an email from a custom email
            address (such as ORG EMAIL) instead of sending campaigns from
            ${DEFAULT_CAMPAIGN_FROM_USER}@${PLATFORM_HOST}. Click on
            "+ Connect Domain" below to get started.`}
        </Typography.Paragraph>

        <Button
          block
          type="dashed"
          shape="round"
          icon={<PlusOutlined />}
          onClick={openCreateEmailIdentity}
        >
          {LABEL_CREATE}
        </Button>

        <OrganizationEmailIdentityCreateModal
          modal={createModal}
        />
      </Space>
    </div>
  )
}

export default OrganizationEmailIdentityConnect
