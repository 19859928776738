import React from "react"
import { Outlet } from "react-router-dom"

import InvestorLayoutContextShape from "./shapes/InvestorLayoutContextShape"


const InvestorLayoutOutlet = ({ context }) => {
  return (
    <Outlet context={context} />
  )
}

InvestorLayoutOutlet.propTypes = {
  context: InvestorLayoutContextShape.isRequired,
}

export default InvestorLayoutOutlet
