import PropTypes from "prop-types"

import AssetShape from "./shapes/AssetShape"
import { CampaignStatusEnumKeys } from "./shapes/CampaignStatusEnum"

const properties = {
  applicationId: PropTypes.string,
  assets: PropTypes.arrayOf(AssetShape),
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  fromAddress: PropTypes.string,
  fromName: PropTypes.string,
  html: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  pinpointCampaignId: PropTypes.string,
  recipientAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  replyToAddress: PropTypes.string,
  scheduledAt: PropTypes.string,
  segmentId: PropTypes.string,
  segmentIds: PropTypes.arrayOf(PropTypes.string),
  shouldSendImmediately: PropTypes.bool.isRequired,
  shouldUseRecipientTimeZone: PropTypes.bool,
  source: PropTypes.string,
  status: PropTypes.oneOf(CampaignStatusEnumKeys).isRequired,
  subject: PropTypes.string,
  subscriptionTypeId: PropTypes.string,
  tagIds: PropTypes.arrayOf(PropTypes.string),
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const PublishCampaignOutputShapeShape = PropTypes.exact(properties)

export default PublishCampaignOutputShapeShape
export { properties }
