import React from "react"

import { W0 } from "@components"
import { BackButton, SubmitButton, FormNavigationFooter } from "@components/Form"

const LABEL_SUBMIT = "Submit"
const LABEL_CONTINUE = "Continue"


const hideInput = input => {
  const { itemProps = {} } = input
  const { style: itemStyle = {} } = itemProps

  const { componentProps = {} } = input
  const { style: componentStyle = {} } = componentProps

  return {
    ...input,
    itemProps: {
      ...itemProps,
      style: {
        ...itemStyle,
        display: "none",
      }
    },
    componentProps: {
      ...componentProps,
      style: {
        ...componentStyle,
        display: "none",
      }
    }
  }
}

const getStepInputs = (submitExtraInputs, goStep, currentStep, controllerInputs, businessInputs) => {
  const hasSteps =
    controllerInputs.length > 0 &&
    businessInputs.length > 0

  if (!hasSteps) {
    const updatedSchema = [
      ...controllerInputs,
      ...businessInputs
    ]

    updatedSchema.push(
      ...submitExtraInputs,
      {
        name: "_submit",
        component: ({ form }) => {
          const isLoading = form.isSubmitting()

          return (
            <SubmitButton
              style={{ marginBottom: W0 }}
              isLoading={isLoading}
            >
              {LABEL_CONTINUE}
            </SubmitButton>
          )
        }
      }
    )

    return updatedSchema
  }

  const isFirstStep = currentStep === 0

  if (isFirstStep) {
    return [
      ...controllerInputs,
      {
        name: "_continue",
        component: ({ form }) => {
          const onClick = () =>
            form
              .validateFields()
              .then(() => goStep(currentStep + 1))
              .catch(() => false)

          return (
            <SubmitButton
              style={{ marginBottom: W0 }}
              onClick={onClick}
            >
              {LABEL_CONTINUE}
            </SubmitButton>
          )
        }
      }
    ]
  }

  const onBack = () => goStep(0)

  const hiddenInputs = controllerInputs.map(hideInput)

  return [
    ...hiddenInputs,
    ...businessInputs,
    ...submitExtraInputs,
    {
      name: "_submit",
      component: ({ form }) => {
        const isLoading = form.isSubmitting()

        return (
          <FormNavigationFooter>
            <BackButton onClick={onBack} />

            <SubmitButton
              isLoading={isLoading}
            >
              {LABEL_SUBMIT}
            </SubmitButton>
          </FormNavigationFooter>
        )
      }
    }
  ]
}

export default getStepInputs
