import React, { useState } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { useForm } from "@components/Form"
import { UpdateDrawer } from "@components/Page"
import { EMAIL_DRAWER_WIDTH } from "@components"

import getInputs from "./helpers/getInputs"
import { updateOperation } from "../../stores/useTemplatesStore"

const LABEL_ENTITY = "Template"
const LABEL_TITLE = `Edit ${LABEL_ENTITY}`
const LABEL_RESET = "Reset to default"
const LABEL_SUBMIT = "Save"


const TemplateUpdateDrawer = ({
  projectId = undefined,
  drawer,
  resetTemplate,
  updatedTemplateByKey
}) => {
  const form = useForm()

  const [ id, setId ] = useState()
  const [ key, setKey ] = useState()
  const [ type, setType ] = useState()
  const [ variables, setVariables ] = useState([])
  const [ isDeleteDisabled, setIsDeleteDisabled ] = useState(true)

  const { organization } = useOutletContext()

  const { email: organizationEmail } = organization

  const appendVariable = variable => {
    const subject = form.getFieldValue('subject')
    const value = `${subject} ${variable}`

    form.setFieldValue('subject', value)
  }

  const inputs = getInputs(type, variables, appendVariable, organizationEmail, key)
  const schema = updateOperation.getSchema(inputs)

  const onOpen = template => {
    const { isDefault } = template

    setIsDeleteDisabled(isDefault)

    setId(template.id)
    setKey(template.key)
    setType(template.type)
    setVariables(template.variables)
  }

  const onSubmit = parameters => {
    if (projectId) {
      parameters.projectId = projectId
    }

    return parameters
  }

  const onSuccess = updatedTemplate =>
    updatedTemplateByKey(updatedTemplate)

  const onDelete = async () => {
    await resetTemplate(id)
    drawer.current.close()
  }

  const deleteButtonProps = {
    title: LABEL_RESET,
    disabled: isDeleteDisabled,
  }

  const updateDrawerProps = {
    width: EMAIL_DRAWER_WIDTH,
    title: LABEL_TITLE,
    entity: LABEL_ENTITY,
    operation: updateOperation,
    submitLabel: LABEL_SUBMIT,
    form,
    drawer,
    schema,
    onOpen,
    onDelete,
    onSubmit,
    onSuccess,
    deleteButtonProps,
  }

  return (
    <UpdateDrawer
      {...updateDrawerProps}
    />
  )
}

TemplateUpdateDrawer.propTypes = {
  drawer: PropTypes.shape().isRequired,
  projectId: PropTypes.string,
  resetTemplate: PropTypes.func.isRequired,
  updatedTemplateByKey: PropTypes.func.isRequired,
}

export default TemplateUpdateDrawer
