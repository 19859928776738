import React from "react"
import { Form, Radio, Space } from "antd"

const DEFAULT_BOOLEAN_OPTIONS = [{ value: "true", label: 'Yes' }, { value: "false", label: 'No' }]
const DEFAULT_BOOLEAN_OPTIONS_INVERTED = [{ value: "false", label: 'Yes' }, { value: "true", label: 'No' }]


const renderBoolean = (form, itemProps, inputProps) => {
  const {
    hasInvertedValues = false,
    hasInvertedItems = false,
  } = inputProps

  const {
    options = hasInvertedValues ? DEFAULT_BOOLEAN_OPTIONS_INVERTED : DEFAULT_BOOLEAN_OPTIONS,
    onChange: onChangeCustom,
    vertical = false,
  } = inputProps

  let radios = options.map(({ value, label }) => (
    <Radio key={value} value={value === "true"}>{label}</Radio>
  ))

  if (hasInvertedItems) {
    radios = radios.reverse()
  }

  if (vertical) {
    radios = (<Space direction="vertical">{radios}</Space>)
  }

  const onChange = value => onChangeCustom ? onChangeCustom(value, form) : {}

  return (
    <Form.Item {...itemProps} className={vertical ? "" : "input-boolean"}>
      <Radio.Group onChange={onChange}>
        {radios}
      </Radio.Group>
    </Form.Item>
  )
}

export default renderBoolean
