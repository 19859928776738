import React from "react"
import { Space, Button } from "antd"
import { CloseOutlined, EditOutlined, ExclamationCircleFilled } from "@ant-design/icons"

const LABEL_DELETE = "Delete"
const LABEL_DELETE_CONTENT = "The operation is not recoverable."


const withActions = (modal, renderItem, onUpdate, deleteItem, entity, deleteContent) => {
  const onEdit = item => onUpdate(item)

  const onDelete = id => {
    const onOk = () => deleteItem(id)

    modal.confirm({
      icon: <ExclamationCircleFilled />,
      title: `${LABEL_DELETE} ${entity}`,
      okText: LABEL_DELETE,
      okType: "danger",
      content: deleteContent
        ? deleteContent(id)
        : LABEL_DELETE_CONTENT,
      onOk,
    })
  }

  const renderItemWithActions = (id, item) =>  (
    <div>
      <span style={{ float: "right" }}>
        <Space>
          <Button
            size="small"
            type="text"
            icon={<EditOutlined />}
            onClick={() => onEdit(item)}
          />

          {
            deleteItem && (
              <Button
                size="small"
                type="text"
                icon={<CloseOutlined />}
                onClick={() => onDelete(id)}
              />
            )
          }
        </Space>
      </span>

      {renderItem(id, item)}
    </div>
  )

  return renderItemWithActions
}

export default withActions
