import React, {useEffect} from "react"
import { useNavigate } from "react-router-dom"

import { isBackstage } from "@components"
import { useAppContext } from "@components/AppContext"

const ERROR_MESSAGE = "Requested resource not found"
const ResourceNotFoundRedirect = () => {
  const navigate = useNavigate()
  const { showErrorMessage } = useAppContext()

  useEffect(() => {
    const path = isBackstage
      ? "/backstage/investors"
      : "/dashboard"

    navigate(path)
    showErrorMessage(ERROR_MESSAGE)
  }, [ navigate, showErrorMessage ])

  return (<></>)
}

export default ResourceNotFoundRedirect