import PropTypes from "prop-types"

import RoleEnum from "./shapes/RoleEnum"

const properties = {
  acceptedTermsVersion: PropTypes.number,
  correlationId: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  disableReason: PropTypes.string,
  email: PropTypes.string.isRequired,
  familyName: PropTypes.string.isRequired,
  givenName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  is2FaEnabled: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
  lastAuthenticatedAt: PropTypes.string,
  mobile: PropTypes.string,
  receiverSourceId: PropTypes.string,
  receiverSourceName: PropTypes.string,
  referenceUserGroupId: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(RoleEnum))).isRequired,
  sponsorId: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  userGroupIds: PropTypes.arrayOf(PropTypes.string),
  verificationRequestCreatedAt: PropTypes.string,
  verificationRequestId: PropTypes.string
}

const EnableUserOutputShapeShape = PropTypes.exact(properties)

export default EnableUserOutputShapeShape
export { properties }
