import { TYPE_PHONE, TYPE_HIDDEN } from "@components/Form"


const getCreateInputs = (updateSchema, sponsors = [], customInputs = []) => {
  const [ mainSponsor ] = sponsors
  const { id: mainSponsorId } = mainSponsor

  const inputs = [
    {
      type: TYPE_HIDDEN,
      name: "sponsorId",
      required: true,
      initialValue: mainSponsorId,
    },
    ...customInputs,
    {
      name: "givenName",
      label: "First Name",
      itemProps: { style: { width: 270 } },
    },
    {
      name: "familyName",
      label: "Last Name",
      itemProps: { style: { width: 270 } },
    },
    {
      name: "email",
      itemProps: { style: { width: 270 } },
    },
    {
      name: "mobile",
      type: TYPE_PHONE,
    },
  ]

  return inputs
}

export default getCreateInputs
