import React from "react"
import { Typography } from "antd"

import { W0 } from "@components"
import { TYPE_CHECKBOX } from "@components/Form"


const getAdditionalPermissionInputs = (
  organizationName,
  isBrokerCheckboxDisabled = false,
  isAccountantCheckboxDisabled = false
) => {
  return [
    {
      name: "_additionalPermissions",
      component: () => (
        <div style={{ marginBottom: W0 / 2 }}>
          <Typography.Text strong>
            Additional Permissions
          </Typography.Text>
        </div>
      )
    },
    {
      name: "_isAccountant",
      type: TYPE_CHECKBOX,
      label: '',
      required: false,
      itemProps: {
        style: { marginBottom: 0 },
      },
      inputProps: {
        label: `Staff is an accountant of ${organizationName}`,
        disabled: isAccountantCheckboxDisabled,
      }
    },
    {
      name: "_isLiaison",
      type: TYPE_CHECKBOX,
      label: '',
      required: false,
      inputProps: {
        label: `Staff is a liaison of ${organizationName}`,
        disabled: isBrokerCheckboxDisabled,
      }
    },
  ]
}

export default getAdditionalPermissionInputs
