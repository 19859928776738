import { useEffect } from "react"

import { wait } from "@api"
import { isLocal } from "@components"
import { useAppContext } from "@components/AppContext"

const RERENDER_DELAY = isLocal
  ? 25
  : 0

const runRequest = (effect) => {
  let isCancelled = false

  wait(RERENDER_DELAY)
    .then(() => {
      if (isCancelled) {
        return
      }

      return effect()
    })

  return () => { isCancelled = true }
}

const useRequest = (effect, dependencies = []) => {
  const { request } = useAppContext()

  useEffect(() => {
    let isCancelled = false

    wait(RERENDER_DELAY)
      .then(() => {
        if (isCancelled) {
          return
        }

        return effect(request)
      })

    return () => { isCancelled = true }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ request, ...dependencies ])
}

export default useRequest

export { runRequest }
