import React from "react"
import { ExclamationCircleFilled } from '@ant-design/icons'

const LABEL_YES = "Yes"
const LABEL_REMOVE = "Remove Property"
const LABEL_REMOVE_DESCRIPTION = `Property is going to be removed from the
project, and be available in "Properties" section.`


const confirmPropertyRemove = (modal, onConfirmed) => {
  modal.confirm({
    icon: <ExclamationCircleFilled />,
    title: LABEL_REMOVE,
    content: LABEL_REMOVE_DESCRIPTION,
    okText: LABEL_YES,
    okType: "danger",
    onOk() {
      return onConfirmed()
    },
    onCancel() {}
  })
}

export default confirmPropertyRemove
