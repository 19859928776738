import React from "react"
import PropTypes from "prop-types"
import { List, Alert, Typography } from "antd"

const { Text } = Typography

const LABEL_TITLE = "Issues with the following documents:"
const LABEL_EXTENTION_ERROR = "extension is not supported"


const FileErrorsAlert = ({ errors, onClose }) => {
  const hasErrors = errors.length > 0

  if (!hasErrors) {
    return null
  }

  const renderItem = ({ name, reason = LABEL_EXTENTION_ERROR }) =>
    <List.Item>
      <Text strong>{name}</Text> {reason}
    </List.Item>

  return (
    <Alert
      type="error"
      onClose={onClose}
      message={LABEL_TITLE}
      closable={true}
      description={
        <List
          size="small"
          dataSource={errors}
          itemLayout="vertical"
          renderItem={renderItem}
        />
      }
    />
  )
}

FileErrorsAlert.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      reason: PropTypes.string,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default FileErrorsAlert
