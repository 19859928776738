import { Table, Typography } from "antd"
import React from "react"

import { Value } from "@components/Text"
import { getFormattedDecimalValue } from "@components/Amount"

const { Text } = Typography
const { Row, Cell } = Table.Summary


const getTotalByTransactionType = (transactions, type) =>
  transactions
    .map(transaction => transaction[type])
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)


const getCollectionSummary = transactions => {
  const totalSaleProfit = getTotalByTransactionType(transactions, "profitOnSale")
  const totalCapitalReturn = getTotalByTransactionType(transactions, "returnOfCapital")
  const totalSponsorPromote = getTotalByTransactionType(transactions, "sponsorPromote")
  const totalInvestmentReturn = getTotalByTransactionType(transactions, "returnOnInvestment")
  const total = totalSaleProfit + totalCapitalReturn + totalSponsorPromote + totalInvestmentReturn

  return (
    <Row>
      <Cell
        align="right"
        index={1}
        colSpan={2}
      >
        <Text strong>Total:</Text>
      </Cell>

      <Cell
        index={1}
        align="right"
      >
        <Value value={totalInvestmentReturn} />
      </Cell>

      <Cell
        align="right"
        index={1}
      >
        <Value value={totalSaleProfit} />
      </Cell>

      <Cell
        align="right"
        index={1}
      >
        <Value value={totalCapitalReturn} />
      </Cell>

      <Cell
        align="right"
        index={1}
      >
        <Value value={totalSponsorPromote} />
      </Cell>

      <Cell
        align="right"
        index={1}
      >
        <Value value={total} />
      </Cell>
      <Cell
        colSpan={2}
      >
        {' '}
      </Cell>
    </Row>
  )
}

export default getCollectionSummary
