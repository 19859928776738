import React from "react"

import { W0, W1 } from "@components"
import { TYPE_UPLOAD } from "@components/Form"
import { Markdown, MarkdownAlert, Disclaimer } from "@components/Text"

import validateFileSize from "./validateFileSize"
import getExtraBusinessDocumentsInput from "./getExtraBusinessDocumentsInput"
import { FILE_TYPES, PDF_FILE_TYPE, BUSINESS_VERIFICATION_STEPS } from "./Constants"
import getFailedReasons, { LABEL_SUPPORTED_DOCUMENTS_SOLO, LABEL_SUPPORTED_DOCUMENTS_BUSINESS }  from "./getFailedReasons"

const LABEL_FILE = "Business Document"
const LABEL_DOCUMENTS = `Please upload business documents for **[ENTITY]**.`
const LABEL_BUSINESS_FILE_INPUT = "File format: JPEG, PNG or PDF, maximum size: 10 megabytes"


const getBusinessInputs = (customer, form, updateSchema, showErrorMessage) => {
  const { name, isSoleProprietorship, verificationStep, businessVerificationFailedReasons = [] } = customer

  const shouldRender = BUSINESS_VERIFICATION_STEPS.includes(verificationStep)

  if (!shouldRender) {
    return []
  }

  const fileTypes = [ ...FILE_TYPES, PDF_FILE_TYPE ]
  const labelDocuments = LABEL_DOCUMENTS.replace('[ENTITY]', name)

  const text = isSoleProprietorship
    ? [ labelDocuments, LABEL_SUPPORTED_DOCUMENTS_SOLO ].join('\n\n')
    : [ labelDocuments, LABEL_SUPPORTED_DOCUMENTS_BUSINESS ].join('\n\n')

  const supportedDocumentsKey = isSoleProprietorship ? 'SOLO' : 'BUSINESS'
  const reasons = getFailedReasons(businessVerificationFailedReasons, supportedDocumentsKey)

  const hasVerificationFailedReasons = businessVerificationFailedReasons.length > 0
  const extraBusinessDocumentsInput = getExtraBusinessDocumentsInput(customer)
  const hasExtraBusinessDocumentsInput = Object.values(extraBusinessDocumentsInput).length > 0
  const shouldRenderSupportedDocumentMessage = !hasVerificationFailedReasons

  const documentInput = hasExtraBusinessDocumentsInput
    ? extraBusinessDocumentsInput
    : {
        name: "_businessDocuments",
        component: ({ style }) => (
          <div style={style}>
            {
              hasVerificationFailedReasons && (
                <MarkdownAlert text={reasons} type="warning" />
              )
            }

            {
              shouldRenderSupportedDocumentMessage && (
                <Markdown text={text} />
              )
            }
          </div>
        )
      }

  const inputs = [
    documentInput,
    {
      name: "businessDocumentUrl",
      type: TYPE_UPLOAD,
      label: LABEL_FILE,
      required: true,
      inputProps: {
        isPublic: false,
        beforeUpload: file => validateFileSize(file, showErrorMessage),
        fileTypes,
      },
      itemProps: {
        style: { marginTop: W1 },
        extra: <Disclaimer text={LABEL_BUSINESS_FILE_INPUT} style={{ marginTop: W0 }} />
      }
    },
  ]

  return inputs
}

export default getBusinessInputs
