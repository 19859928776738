import React from "react"
import PropTypes from "prop-types"

import { InputShape } from "@api"

import ListInput from "../ListInput"

import "./AssetsListInput.css"


const AssetsListInput = ({
  form,
  name,
  path,
  title,
  schema,
  addTitle,
}) => {
  return (
    <ListInput
      form={form}
      name={name}
      path={path}
      title={title}
      schema={schema}
      addTitle={addTitle}
      className="assets-list-input horizontal-oriented"
    />
  )
}

AssetsListInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  name: PropTypes.arrayOf(PropTypes.string).isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  /* note: custom component props */
  title: PropTypes.string.isRequired,
  schema: PropTypes.arrayOf(InputShape).isRequired,
  addTitle: PropTypes.string.isRequired,
}

export default AssetsListInput
