import React from "react"
import { useOutletContext } from "react-router-dom"

import { ProjectDetails } from "@components/Project"
import { W1, W2, W5, isMobile } from "@components"

import ContinueButton from "./ContinueButton"
import AddAnotherInvestmentButton from "./AddAnotherInvestmentButton"


const InvestorProjectDetailsPage = () => {
  const { project } = useOutletContext()

  const isEditable = false
  const containerStyle = {
    marginTop: isMobile ? W1 : 0,
    marginBottom: isMobile ? W5 * 3 : W2,
  }

  return (
    <div style={containerStyle}>
      <ContinueButton />

      <AddAnotherInvestmentButton />

      <div style={{ padding: "0 8px" }}>
        <ProjectDetails
          project={project}
          isEditable={isEditable}
        />
      </div>
    </div>
  )
}

export default InvestorProjectDetailsPage
