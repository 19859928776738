import React from "react"
import PropTypes from "prop-types"

import { VirtualTable } from "@components/Table"
import { useAppContext } from "@components/AppContext"
import { indexCustomersOperation as indexOperation } from "@api/services/transactions"

import getColumnsConfig from "./helpers/getColumnsConfig"

const PAGE_SIZE = 100
const DEFAULT_SORT_COLUMN = "date"


const CustomersTable = ({
  table,
  openCustomerDrawer,
}) => {
  const { authenticatedRequest } = useAppContext()

  const size = "small"
  const columnsConfig = getColumnsConfig(openCustomerDrawer)
  const hasHeaderFilters = true

  const pagination = {
    position: ["bottomLeft"],
    pageSize: PAGE_SIZE,
    showSizeChanger: false,
    hideOnSinglePage: true,
  }

  const defaultSortColumn = DEFAULT_SORT_COLUMN

  const tableProps = {
    request: authenticatedRequest,
    size,
    table,
    pagination,
    columnsConfig,
    indexOperation,
    hasHeaderFilters,
    defaultSortColumn,
  }

  return (
    <VirtualTable {...tableProps} />
  )
}

CustomersTable.propTypes = {
  table: PropTypes.shape().isRequired,
  openCustomerDrawer: PropTypes.func.isRequired,
}

export default CustomersTable
