import React from "react"
import { useOutletContext } from "react-router-dom"
import { Row, Col } from "antd"

import { W1, W2, isMobile } from "@components"
import { INVESTMENT_STATUS } from "@components/Domain"

import ProfileCard from './ProfileCard'


const InvestorProjectInvestmentsPage = () => {
  const { project, getProjectInvestments } = useOutletContext()

  const { id: projectId } = project

  const activeInvestments = getProjectInvestments(projectId)
    .filter(({ status }) => status === INVESTMENT_STATUS.ACTIVE)

  const containerStyle = isMobile
    ? { padding: W1 }
    : { marginTop: W2 }

  return (
    <Row style={containerStyle} gutter={isMobile ? [W1, W1] : [W2, W2]}>
      {
        activeInvestments.map(investment =>
          <Col key={investment.id} span={isMobile ? 24 : 8}>
            <ProfileCard
              key={investment.id}
              investment={investment}
            />
          </Col>
        )
      }
    </Row>
  )
}

export default InvestorProjectInvestmentsPage
