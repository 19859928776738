import capitalize from "lodash.capitalize"

export const CANCELLED = "CANCELLED"
export const ERROR = "ERROR"
export const FAILED = "FAILED"
export const PENDING = "PENDING"
export const PROCESSED = "PROCESSED"
export const REJECTED = "REJECTED"

const TransactionStatusEnum = {
  [CANCELLED]: CANCELLED,
  [ERROR]: ERROR,
  [FAILED]: FAILED,
  [PENDING]: PENDING,
  [PROCESSED]: PROCESSED,
  [REJECTED]: REJECTED
}

const TransactionStatusEnumKeys = Object.keys(TransactionStatusEnum)

const TransactionStatusEnumOptions = TransactionStatusEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  TransactionStatusEnumKeys,
  TransactionStatusEnumOptions
}

export default TransactionStatusEnum
