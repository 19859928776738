import React from "react"

import { Form, FormShape } from "@components/Form"
import { createDistributionTransactionOperation as createOperation } from "@api/services/transactions"

import getImportInputs from "./helpers/getImportInputs"


export const DistributionImportForm = ({ form }) => {
  const inputs = getImportInputs()

  return (
    <Form
      form={form}
      schema={inputs}
      operation={createOperation}
      formProps={{
        layout: "inline",
      }}
    />
  )
}

DistributionImportForm.propTypes = {
  form: FormShape.isRequired,
}

export default DistributionImportForm
