import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { TagsDrawer } from "@components/Tags"
import { VirtualTable } from "@components/Table"
import { CAMPAIGN_TAG } from "@components/Store/BackstageStore/useOptionsStore"
import { indexOperation } from "@components/Store/BackstageStore/useCampaignsStore"
import { IndexCampaignsOutputShape } from "@api/services/campaigns"

import useColumnsConfig from "./helpers/useColumnsConfig"
import getHeaderActions from "./helpers/getHeaderActions"

const DEFAULT_SORT_COLUMN = "updatedAt"


const CampaignsTable = ({
  items = undefined,
  table,
  indexItems,
  openCampaignCloneModal,
  openCampaignCreateModal
}) => {
  const tagsDrawer = useRef({})

  const { campaignTags } = useOutletContext()

  const openTagsDrawer = () =>
    tagsDrawer.current.open()

  const pagination = {
    position: ["bottomLeft"],
    pageSize: 100,
    showSizeChanger: false,
    hideOnSinglePage: true,
  }

  const size = "small"
  const sticky = true
  const bordered = false
  const className = "last-column-action"
  const headerActions = getHeaderActions(openCampaignCreateModal)
  const columnsConfig = useColumnsConfig(openTagsDrawer, openCampaignCloneModal)
  const hasHeaderFilters = true
  const defaultSortColumn = DEFAULT_SORT_COLUMN
  const defaultSortColumnDirection = "descend"

  const tableProps = {
    size,
    table,
    items,
    sticky,
    bordered,
    className,
    indexItems,
    pagination,
    columnsConfig,
    headerActions,
    indexOperation,
    hasHeaderFilters,
    defaultSortColumn,
    defaultSortColumnDirection,
  }

  return (
    <>
      <VirtualTable {...tableProps} />

      <TagsDrawer
        group={CAMPAIGN_TAG}
        drawer={tagsDrawer}
        tagOptions={campaignTags}
      />
    </>
  )
}

CampaignsTable.propTypes = {
  table: PropTypes.shape().isRequired,
  items: PropTypes.arrayOf(IndexCampaignsOutputShape),
  indexItems: PropTypes.func.isRequired,
  openCampaignCloneModal: PropTypes.func.isRequired,
  openCampaignCreateModal: PropTypes.func.isRequired,
}

export default CampaignsTable
