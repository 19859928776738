import React from "react"
import startCase from "lodash.startcase"
import { Typography } from "antd"

import FormItem from "./FormItem"
import { TYPE_NONE, TYPE_OBJECT } from "./types"


const defaultContainer = ({ children, style }) => (
  <div style={style}>
    {children}
  </div>
)


const renderObjectTitle = input => {
  const isEmptyLabel = input.label === ""

  if (isEmptyLabel) { return "" }

  return (
    <Typography.Paragraph strong key={input.name}>
      {input.label || startCase(input.name)}
    </Typography.Paragraph>
  )
}


const renderFormObject = (form, input, relatedPath, formPath) => {
  const { name, schema: objectSchema, itemProps = {} } = input
  const { containerComponent: Container = defaultContainer } = input

  const title = renderObjectTitle(input)
  const inputs = renderFormItems(form, objectSchema, relatedPath, formPath)

  return (
    <Container key={name} {...itemProps}>
      {[ title, ...inputs ]}
    </Container>
  )
}


const renderFormItems = (form, schema, relatedPath = [], formPath = undefined) => {
  formPath = formPath
    ? formPath
    : relatedPath

  const schemaWithoutNoneType = schema
    .filter(input => input.type !== TYPE_NONE)

  const inputs = schemaWithoutNoneType
    .map(input => {
      const {
        name,
        type,
        component: CustomFormItem
      } = input

      const inputName = [ ...relatedPath, name ].map(key => `${key}`)
      const inputPath = [ ...formPath, name ].map(key => `${key}`)
      const inputKey = inputName.join(".")

      const isObject = type === TYPE_OBJECT

      if (isObject) {
        return renderFormObject(form, input, inputName, inputPath)
      }

      if (CustomFormItem) {
        const { componentProps = {} } = input

        const customFormItemProps = {
          name: inputName,
          path: inputPath,
          form,
          ...componentProps
        }

        return (
          <CustomFormItem key={inputKey} {...customFormItemProps} />
        )
      }

      const {
        label,
        options,
        extraOptions,
        required,
        itemProps,
        inputProps,
        placeholder,
        initialValue,
        maxLength,
        minLength,
        minimum,
        maximum,
        operation,
        hasAutoComplete,
      } = input

      const formItemProps = {
        name: inputName,
        path: inputPath,
        type,
        form,
        label,
        minimum,
        maximum,
        options,
        required,
        maxLength,
        minLength,
        itemProps,
        operation,
        inputProps,
        placeholder,
        initialValue,
        extraOptions,
        hasAutoComplete,
      }

      return (
        <FormItem key={inputKey} {...formItemProps} />
      )
    })

  return inputs
}

export default renderFormItems
