import React from "react"
import { Typography, Space } from "antd"

import {
  TYPE_NONE,
  TYPE_PHONE,
  // TYPE_DOMAIN,
  TYPE_UPLOAD,
  TYPE_HIDDEN,
  TYPE_REDACTOR
} from "@components/Form"

const { Text } = Typography

const LABEL_LOGO = "Logo"
const LABEL_PHONE = "Phone"
const LABEL_UPLOAD = "Upload Logo"
const LABEL_FORMATS = "SVG and PNG formats supported"
const LABEL_SIGNATURE = "Email Signature"


const getUpdateInputs = (organization, onlyFieldNames = []) => {
  // const disabledDomainValue = organization?.domainStatus === "COMPLETE"

  const inputs = [
    {
      name: "senderSourceId",
      type: TYPE_NONE,
    },
    {
      name: "docusignAccountId",
      type: TYPE_NONE,
    },
    {
      name: "docusignWebhookSecret",
      type: TYPE_NONE,
    },
    {
      name: "name",
    },
    {
      name: "domain",
      type: TYPE_NONE,
      // type: TYPE_DOMAIN,
      // inputProps: {
      //   disabled: disabledDomainValue,
      // }
    },
    {
      name: "email",
      inputProps: {
        disabled: organization.isEmailVerified
      }
    },
    {
      name: "phoneNumber",
      type: TYPE_PHONE,
      label: LABEL_PHONE,
    },
    {
      name: "logoUrl",
      type: TYPE_UPLOAD,
      label: (
        <Space>
          <Text strong>{LABEL_LOGO}</Text>
          <Text type="secondary">({LABEL_FORMATS})</Text>
        </Space>
      ),
      inputProps: {
        isPublic: true,
        fileTypes: [".svg", ".png"],
        actionTitle: LABEL_UPLOAD,
      }
    },
    {
      name: "emailFooterSource",
      label: LABEL_SIGNATURE,
      type: TYPE_REDACTOR,
      inputProps: {
        emailTargetPath: "emailFooterHtml",
      }
    },
    {
      name: "emailFooterHtml",
      type: TYPE_HIDDEN,
    },
  ]

  const hasOnlyFieldNames = onlyFieldNames.length > 0

  if (!hasOnlyFieldNames) {
    return inputs
  }

  const onlyInputs = inputs
    .map(input => {
      const { name, type, ...otherProps } = input
      const isNone = !onlyFieldNames.includes(name)

      if (isNone) {
        return {
          name,
          type: TYPE_NONE
        }
      }

      return { name, ...otherProps }
    })

  return onlyInputs
}

export default getUpdateInputs
