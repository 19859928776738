import PropTypes from "prop-types"

import { UsCountryEnumKeys } from "./UsCountryEnum"
import { UsStateEnumKeys } from "./UsStateEnum"

const properties = {
  addressCountry: PropTypes.oneOf(UsCountryEnumKeys).isRequired,
  addressLocality: PropTypes.string.isRequired,
  addressRegion: PropTypes.oneOf(UsStateEnumKeys).isRequired,
  postalCode: PropTypes.string.isRequired,
  streetAddress: PropTypes.string.isRequired
}

const UsAddressShape = PropTypes.exact(properties)

export default UsAddressShape
export { properties }
