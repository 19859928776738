import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import { Logo } from "@components/Page"


const HomeLink = ({
  path = "/dashboard",
  logoUrl = undefined,
  title,
}) => {
  return (
    <Link to={path}>
      <Logo
        src={logoUrl}
        alt={title}
      />
    </Link>
  )
}

HomeLink.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
}

export default HomeLink
