import React from "react"
import { ulid } from 'ulid'
import { App, Input } from "antd"
import { useOutletContext } from "react-router-dom"

import { confirmAction } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import {
  rejectTransactionOperation,
  approveTransactionOperation,
  deleteExternalDistributionTransactionOperation,
} from "@api/services/transactions"

import {
  createMultipleTransactionsCreatedNotificationOperation
} from "@api/services/notifications"

const { TextArea } = Input

const REJECT = "REJECT"
const DELETE = "DELETE"
const APPROVE = "APPROVE"

let rejectedReason = ""

const onChange = ({ currentTarget }) => {
  rejectedReason = currentTarget.value
}

const ACTIONS_MAP = {
  [APPROVE]: {
    type: "primary",
    title:  "Approve Distribution",
    action: "Approve",
    content: `By clicking "Approve" below, distributions will be sent to Dwolla
for processing. Funds will be debited from the distribution account. In
addition, investors will be notified via email that distributions are on their
way to their linked bank accounts.`,
    success: 'Distribution is approved',
    operation: approveTransactionOperation,
  },
  [REJECT]: {
    type: "danger",
    title: "Reject Distribution",
    action: "Reject",
    success: 'Distribution is rejected',
    content: <TextArea rows={4} placeholder="Rejected Reason" onChange={onChange}/>,
    operation: rejectTransactionOperation,
  },
  [DELETE]: {
    type: "danger",
    title: "Delete Backfill Distribution",
    action: "Delete",
    success: 'Backfill distribution is deleted',
    content: `You are about to delete the selected backfilled distributions.
This action cannot be undone. A total of [X] backfilled distribution(s) will be permanently removed. Note that investors will not be informed of this deletion.
Are you sure you want to proceed with the deletion?`,
    operation: deleteExternalDistributionTransactionOperation.id,
  },
}


const useDistributionActions = (getSelectedDistributions, afterAction) => {
  const { modal } = App.useApp()

  const {
    request,
    showSuccessMessage
  } = useAppContext()

  const {
    project,
    membersMap,
    getOrganizationControllerId,
  } = useOutletContext()

  const createMultipleTransactionsCreatedNotification = async (distributions) => {
    const { id: projectId } = project

    const recipientsCount = distributions.length
    const totalAmount = distributions.reduce((acc, obj) => acc + obj.total, 0)

    const controllerId = getOrganizationControllerId()
    const controller = membersMap[controllerId]

    const { senderSourceId } = distributions[0].transactions[0]

    const {
      email: controllerEmail,
      fullName: controllerName,
    } = controller

    const mutation = {
      projectId,
      totalAmount,
      controllerId,
      senderSourceId,
      controllerName,
      controllerEmail,
      recipientsCount,
    }

    await request(createMultipleTransactionsCreatedNotificationOperation, { mutation })
  }

  const action = actionName => {
    const isDelete = actionName === DELETE
    let selectedDistributions = getSelectedDistributions()

    let { content } = ACTIONS_MAP[actionName]

    if (isDelete) {
      selectedDistributions = selectedDistributions.filter(({ isExternal }) => isExternal)

      const selectedDistributionCount = selectedDistributions.length
      content = content.replace("[X]", selectedDistributionCount)
    }

    return confirmAction(
      modal,
      ACTIONS_MAP[actionName].action,
      ACTIONS_MAP[actionName].title,
      content,
      async () => {
        const message = ACTIONS_MAP[actionName].success
        const { operation } = ACTIONS_MAP[actionName]

        const parameters = {
          mutation: {}
        }

        const isReject = actionName === REJECT
        const isApprove = actionName === APPROVE
        const isApproveOrReject = isApprove || isReject

        if (isReject) {
          parameters.mutation = { statusReason: rejectedReason }
        }

        if (isApproveOrReject) {
          selectedDistributions = selectedDistributions.filter(({ isExternal }) => !isExternal)
        }

        const batchApprovalId = ulid()

        const hasMultipleDistributions = selectedDistributions.length > 1

        const shouldCreateNotification =
          isApprove &&
          hasMultipleDistributions

        if (shouldCreateNotification) {
          parameters.mutation = { batchApprovalId }
        }

        for (const distribution of selectedDistributions) {
          const { transactionIds } = distribution

          for (const id of transactionIds) {
            await request(operation, { id, ...parameters })
          }
        }

        if (shouldCreateNotification) {
          await createMultipleTransactionsCreatedNotification(selectedDistributions)
        }

        showSuccessMessage(message)
        rejectedReason = ""

        return afterAction()
      }
    )
  }

  const approveDistribution = () => action(APPROVE)

  const rejectDistribution = () => action(REJECT)

  const deleteBackfillDistributions = () => action(DELETE)

  return {
    approveDistribution,
    rejectDistribution,
    deleteBackfillDistributions
  }
}

export default useDistributionActions
