import PropTypes from "prop-types"


const properties = {
  fileName: PropTypes.string,
  investmentId: PropTypes.string.isRequired,
  investmentName: PropTypes.string.isRequired,
  score: PropTypes.number
}

const InvestmentDocumentShape = PropTypes.exact(properties)

export default InvestmentDocumentShape
export { properties }
