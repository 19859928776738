import { useMemo } from "react"

import { TRANSACTION_TYPES } from "@components/Domain"
import { getTransactionTypeLabel } from "@components/Transaction"
import { getFormattedDecimalValue } from "@components/Amount"

import getStatistics, {
  OUTGOING_TOTAL_KEY,
  TITLE_DISTRIBUTIONS,
  STATISTIC_LABELS_MAP,
} from "../../../../helpers/getStatistics"

const DISTRIBUTIONS_KEY = "distributions"

const TOOLTIP_MAP = {
  [DISTRIBUTIONS_KEY]: "Return on capital; return on investment",
  [OUTGOING_TOTAL_KEY]: "Total amount invested",
  [TRANSACTION_TYPES.SALE_PROFIT]: "Total profit amount upon sale",
  [TRANSACTION_TYPES.CAPITAL_RETURN]: "Total amount returned upon sale or refinance; return of capital",
}

const useStatisticItems = (transactions) => {
  const statistics = useMemo(() =>
    getStatistics(transactions)
  , [ transactions ])

  const investedAmount = statistics[OUTGOING_TOTAL_KEY] || 0
  const profitOnSaleAmount = statistics[TRANSACTION_TYPES.SALE_PROFIT] || 0
  const returnedCapitalAmount = statistics[TRANSACTION_TYPES.CAPITAL_RETURN] || 0

  const profitAmount = statistics[TRANSACTION_TYPES.SALE_PROFIT] || 0
  const amountReturn = statistics[TRANSACTION_TYPES.INVESTMENT_RETURN] || 0

  const returnedAmount = getFormattedDecimalValue(returnedCapitalAmount, profitOnSaleAmount, "plus")
  const totalDistributions = getFormattedDecimalValue(amountReturn, returnedCapitalAmount, "plus")

  const items = [
    {
      title: STATISTIC_LABELS_MAP[OUTGOING_TOTAL_KEY],
      value: investedAmount,
      tooltip: TOOLTIP_MAP[OUTGOING_TOTAL_KEY]
    },
    {
      title: STATISTIC_LABELS_MAP[TRANSACTION_TYPES.CAPITAL_RETURN],
      value: returnedAmount,
      tooltip: TOOLTIP_MAP[TRANSACTION_TYPES.CAPITAL_RETURN]
    },
    {
      title: TITLE_DISTRIBUTIONS,
      value: totalDistributions,
      tooltip: TOOLTIP_MAP[DISTRIBUTIONS_KEY]
    },
    {
      title: getTransactionTypeLabel(TRANSACTION_TYPES.SALE_PROFIT),
      value: profitAmount,
      tooltip: TOOLTIP_MAP[TRANSACTION_TYPES.SALE_PROFIT]
    }
  ]

  return items
}

export default useStatisticItems
