import React from "react"
import { Tag } from "antd"
import { useOutletContext } from "react-router-dom"

import { PROJECT_STATES } from "@components/Domain"
import InvestmentStatusTag from "@modules/investor/InvestorDashboardPage/ActiveInvestments/InvestmentStatusTag"
import { computeProjectInvestmentsFlags } from "@components/Project"
import { ReadPublicPublishedProjectOutputShape } from "@api/services/backstage"

const LABEL_HOLD = "Hold"
const LABEL_SOLD = "Sold"
const LABEL_ACTIVE = "Active"

const TAG_PROPS_MAP = {
  [PROJECT_STATES.HOLD]: {
    text: LABEL_HOLD,
    color: 'yellow'
  },
  [PROJECT_STATES.CLOSED]: {
    text: LABEL_ACTIVE,
    color: 'orange'
  },
  [PROJECT_STATES.ARCHIVED]: {
    text: LABEL_SOLD,
    color: 'green'
  }
}


const StatusTag = ({ project }) => {
  const { getProjectInvestments } = useOutletContext()

  const { state } = project
  const isOpen = state === PROJECT_STATES.OPEN

  if (!isOpen) {
    const tagText = TAG_PROPS_MAP[state].text
    const tagColor = TAG_PROPS_MAP[state].color

    return (
      <Tag color={tagColor}>
        {tagText}
      </Tag>
    )
  }

  const { id } = project
  const investments = getProjectInvestments(id)
  const { isSigned, isFunded } = computeProjectInvestmentsFlags(investments)

  return (
    <InvestmentStatusTag
      isSigned={isSigned}
      isFunded={isFunded}
    />
  )
}

StatusTag.propTypes = {
  project: ReadPublicPublishedProjectOutputShape.isRequired,
}

export default StatusTag
