
export const LABEL_MICRO_DEPOSIT = `Dwolla has started the micro-deposit 
verification process. It may take 1-2 business days for two small deposits 
(each less than $0.20) to show up in your bank account. You’ll get an email 
once the micro-deposits are processed. After the deposits appear in your account, 
return here to enter the amounts and verify your account. Please check back 
later to complete the confirmation process.`

export const CUSTOMER_STATUS_RETRY = "RETRY"
export const CUSTOMER_STATUS_DOCUMENT = "DOCUMENT"
export const CUSTOMER_STATUS_VERIFIED = "VERIFIED"
export const CUSTOMER_STATUS_SUSPENDED = "SUSPENDED"
export const CUSTOMER_STATUS_UNVERIFIED = "UNVERIFIED"
export const CUSTOMER_STATUS_UNCERTIFIED = "UNCERTIFIED"
export const CUSTOMER_STATUS_PENDING_DOCUMENT_VERIFICATION = "DOCUMENT_VERIFICATION_PENDING"

export const BENEFICIAL_OWNER_STATUS_VERIFIED = "VERIFIED"
export const BENEFICIAL_OWNER_STATUS_DOCUMENT = "DOCUMENT"
export const BENEFICIAL_OWNER_STATUS_INCOMPLETE = "INCOMPLETE"
export const BENEFICIAL_OWNER_STATUS_PENDING_DOCUMENT_VERIFICATION = "DOCUMENT_VERIFICATION_PENDING"

export const INVALID_ACCOUNT_DWOLLA_ERROR_CODES = [
  'R02', 'R03', 'R04', 'R05', 'R06', 'R07', 'R08', 'R10', 'R11', 'R12', 'R13',
  'R14', 'R15', 'R16', 'R17', 'R20', 'R23', 'R29', 'R31', 'R37', 'R38', 'R51'
]

export const INSUFFICIENT_FUNDS_DWOLLA_ERROR_CODES = ['R01', 'R09']
