

const getMaskedValue = (value, inputMask) => {
  const maskedValue = []
  let maskedSymbolIndex = 0

  for (let i = 0; i <= inputMask.length; i++) {
    const isMaskSymbol = ["(", ")", "-"].includes(inputMask[i])

    if (isMaskSymbol === undefined) {
      maskedValue.push(inputMask[i])

    } else {
      maskedValue.push(value[maskedSymbolIndex])
      maskedSymbolIndex++

    }
  }

  return maskedValue.join("")
}

export default getMaskedValue
