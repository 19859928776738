import React from "react"
import { PlusOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"

const LABEL_CREATE = "Create"


const getHeaderActions = openCreateModal => {
  const canCreateProperties = hasAccess(['projects-write'])

  if (!canCreateProperties) {
    return []
  }
  const items = [
    {
      icon: <PlusOutlined/>,
      title: LABEL_CREATE,
      action: openCreateModal,
    },
  ]

  return items
}

export default getHeaderActions
