import React, { useRef, useState } from "react"
import PropTypes from "prop-types"

import { Drawer } from "@components/Page"
import { EditButton } from "@components/Button"
import { useAppContext } from "@components/AppContext"
import { DRAWER_WIDTH2 } from "@components"
import { readBatchOperation as readOperation } from "@api/services/backstage"

import BatchFilesList from "./BatchFilesList"
import BatchDescription from "./BatchDescription"
import BatchUpdateDrawer from "./BatchUpdateDrawer/index"


const BatchDrawer = ({ table, drawer }) => {
  const updateDrawer = useRef({})

  const [ batch, setBatch ] = useState()
  const [ isRefreshed, setIsRefreshed ] = useState(false)

  const { request } = useAppContext()

  const refreshBatch = id =>
    request(readOperation, { id })
      .then(({ data }) => setBatch(data))
      .then(() => setIsRefreshed(true))

  const onOpen = targetBatch => {
    setBatch(targetBatch)
    refreshBatch(targetBatch.id)
  }

  const onSuccess = async (updatedBatch) => {
    setBatch(updatedBatch)
    refreshBatch(updatedBatch.id)
  }

  const openBatchUpdateDrawer = () =>
    updateDrawer.current.open(batch)

  const {
    name: title = "",
    fileUrl,
  } = batch || {}

  const shouldRenderFiles = batch && !fileUrl

  const extra = <EditButton onClick={openBatchUpdateDrawer} />

  const drawerProps = {
    width: DRAWER_WIDTH2,
    destroyOnClose: true,
    extra,
    title,
    drawer,
    onOpen,
  }

  return (
    <Drawer {...drawerProps}>
      {
        batch && (
          <BatchDescription
            batch={batch}
            isUrlSigned={isRefreshed}
          />
        )
      }

      {
        shouldRenderFiles && (
          <BatchFilesList batch={batch} />
        )
      }

      <BatchUpdateDrawer
        table={table}
        drawer={updateDrawer}
        onSuccess={onSuccess}
      />
    </Drawer>
  )
}

BatchDrawer.propTypes = {
  table: PropTypes.shape().isRequired,
  drawer: PropTypes.shape().isRequired,
}

export default BatchDrawer
