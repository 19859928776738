import capitalize from "lodash.capitalize"

export const C_CORP = "C_CORP"
export const EXEMPT_ORGANIZATION = "EXEMPT_ORGANIZATION"
export const LLC = "LLC"
export const OTHER_RETIREMENT_PLAN = "OTHER_RETIREMENT_PLAN"
export const PARTNERSHIP = "PARTNERSHIP"
export const S_CORP = "S_CORP"

const EntityTypeEnum = {
  [C_CORP]: C_CORP,
  [EXEMPT_ORGANIZATION]: EXEMPT_ORGANIZATION,
  [LLC]: LLC,
  [OTHER_RETIREMENT_PLAN]: OTHER_RETIREMENT_PLAN,
  [PARTNERSHIP]: PARTNERSHIP,
  [S_CORP]: S_CORP
}

const EntityTypeEnumKeys = Object.keys(EntityTypeEnum)

const EntityTypeEnumOptions = EntityTypeEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  EntityTypeEnumKeys,
  EntityTypeEnumOptions
}

export default EntityTypeEnum
