import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(relativeTime)

const LABEL_NEVER = "Never"


const ago = (isoDate, shouldReturnNever = false) => {
  if (isoDate) {
    return dayjs(isoDate).fromNow()
  }

  return shouldReturnNever ? LABEL_NEVER : ""
}

export default ago
