import React from "react"
import sortBy from "lodash.sortby"
import { Space, Typography } from "antd"

const LABEL_NO_LIAISON = "(no liaison assigned)"
const LABEL_NO_EMAIL = "(no email)"


const getInvestorOptions = (accounts = []) => {
  const getLabel = (investor, brokerUserId) => {
    const isDisabled = !brokerUserId
    const fullName = `${investor.givenName} ${investor.familyName}`

    if (isDisabled) {
      return (
        <Space>
          <Typography.Text type="secondary">{fullName}</Typography.Text>
          {LABEL_NO_LIAISON}
        </Space>
      )
    }

    if (!investor.email) {
      return (
        <Space>
          <Typography.Text type="secondary">{fullName}</Typography.Text>
          {LABEL_NO_EMAIL}
        </Space>
      )
    }

    return (
      <Space>
        {fullName}
        <Typography.Text type="secondary">{investor.email}</Typography.Text>
      </Space>
    )
  }

  const getText = investor => {
    const fullName = `${investor.givenName} ${investor.familyName}`
    return `${fullName} ${investor.email}`
  }

  const getDisabled = (brokerUserId, { email }) =>
    !brokerUserId || !email

  const options = sortBy(accounts, account => account.investor.givenName)
    .map(({ id, brokerUserId, investor }) => (
      {
        text: getText(investor),
        value: id,
        label: getLabel(investor, brokerUserId),
        disabled: getDisabled(brokerUserId, investor),
      }
    ))

  return options
}

export default getInvestorOptions
