import React from "react"
import { Typography } from "antd"

import { W0 } from "@components"
import { SubmitButton, TYPE_RADIO } from "@components/Form"
import { Markdown } from "@components/Text"

export const OWNERS_STEP = 'OWNERS'
export const INITIAL_STEP = 'INITIAL'
export const NO_OWNERS_STEP = 'NO_OWNERS'

const OWNERS_OPTIONS = [
  {
    value: OWNERS_STEP,
    label: "**[ORGANIZATION]** has at least one individual who owns 25% or more of the business."
  },
  {
    value: NO_OWNERS_STEP,
    label: "**[ORGANIZATION]** does not have at least one individual who owns 25% or more of the business."
  }
]

const LABEL_CONTINUE = "Continue"


const getStepInputs = ({
  form,
  setStep,
  customerName,
}) => {
  const nextStep = form.getFieldValue("_step")

  const isDisabled = !nextStep

  const components = {
    p(props) {
      // eslint-disable-next-line react/prop-types
      const { children } = props
      return (
        <Typography.Text>
          {children}
        </Typography.Text>
      )
    }
  }

  const options = OWNERS_OPTIONS
    .map(({ value, label }) => {
      const formattedLabel = label
        .replace("[ORGANIZATION]", customerName)

      return { value, label: <Markdown text={formattedLabel} components={components} /> }
    })

  const inputs = [
    {
      name: "_step",
      type: TYPE_RADIO,
      label: "",
      required: true,
      options,
    },
    {
      name: "_continue",
      component: () => (
        <SubmitButton
          style={{ marginBottom: W0 }}
          onClick={() => setStep(nextStep)}
          isDisabled={isDisabled}
        >
          {LABEL_CONTINUE}
        </SubmitButton>
      )
    },
  ]

  return inputs
}

export default getStepInputs
