import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { Space, Typography } from "antd"

const { Text } = Typography


const BrokerOptionLabel = ({ name, sponsorId }) => {
  const {
    getSponsorName,
  } = useOutletContext()

  const sponsorName = getSponsorName(sponsorId)

  return (
    <Space>
      {name}

      <Text type="secondary">
        ({sponsorName})
      </Text>
    </Space>
  )
}

BrokerOptionLabel.propTypes = {
  name: PropTypes.string.isRequired,
  sponsorId: PropTypes.string.isRequired,
}

export default BrokerOptionLabel
