

const IndexUsersItemSchema = {
  acceptedTermsVersion: { type: 'integer' },
  email: { format: 'email' },
  lastAuthenticatedAt: { format: 'date-time' },
  isDisabled: { type: 'boolean' },
  is2FaEnabled: { type: 'boolean' },
  verificationRequestCreatedAt: { format: 'date-time' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexUsersItemSchema
