import PropTypes from "prop-types"


const properties = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  date: PropTypes.string.isRequired,
  documentType: PropTypes.string,
  fileSourceFileName: PropTypes.string,
  fileUrl: PropTypes.string,
  id: PropTypes.string.isRequired,
  isPublicFile: PropTypes.bool,
  isSent: PropTypes.bool,
  name: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  scheduledAt: PropTypes.string,
  sentAt: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const UpdateBatchOutputShapeShape = PropTypes.exact(properties)

export default UpdateBatchOutputShapeShape
export { properties }
