import PropTypes from "prop-types"

import { TierNameEnumKeys } from "./TierNameEnum"

const properties = {
  description: PropTypes.string,
  isPrivate: PropTypes.bool,
  minimumAmount: PropTypes.number.isRequired,
  name: PropTypes.oneOf(TierNameEnumKeys).isRequired,
  returnPercentage: PropTypes.number.isRequired,
  title: PropTypes.string,
  upsideBrokerPercentage: PropTypes.number
}

const TierShape = PropTypes.exact(properties)

export default TierShape
export { properties }
