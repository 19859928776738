import React from "react"
import { Tag } from "antd"
import PropTypes from "prop-types"

import {
  getTransactionStatusLabel,
  getTransactionStatusColor
} from "./TransactionStatusTag"


const DistributionStatusTag = ({ status }) => {
  const label = getTransactionStatusLabel(status)
  const color = getTransactionStatusColor(status)

  return (
    <Tag
      color={color}
      bordered={false}
    >
      {label}
    </Tag>
  )
}

DistributionStatusTag.propTypes = {
  status: PropTypes.string.isRequired,
}

export default DistributionStatusTag
