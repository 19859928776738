import { TYPE_NONE } from "@components/Form"


const getProfileEntityInputsMap = () => ({
  isLiabilityAccepted: {
    name: "isLiabilityAccepted",
    type: TYPE_NONE
  },
  hasPurchasedExemptSecurities: {
    name: "hasPurchasedExemptSecurities",
    type: TYPE_NONE
  },
  membershipInterestsTitle: {
    name: "membershipInterestsTitle",
    type: TYPE_NONE
  },
  name: {
    name: "name",
    type: TYPE_NONE
  },
  entityType: {
    name: "entityType",
    type: TYPE_NONE
  },
  title: {
    name: "title",
    type: TYPE_NONE
  },
  address: {
    name: "address",
    type: TYPE_NONE
  },
  isDisregardedForTaxPurposes: {
    name: "isDisregardedForTaxPurposes",
    type: TYPE_NONE
  },
  taxFilingStatus: {
    name: "taxFilingStatus",
    type: TYPE_NONE
  },
  hasEinNumber: {
    name: "hasEinNumber",
    type: TYPE_NONE
  },
  einNumber: {
    name: "einNumber",
    type: TYPE_NONE
  },
  ssnNumber: {
    name: "ssnNumber",
    type: TYPE_NONE
  },
  isRetirementPlan: {
    name: "isRetirementPlan",
    type: TYPE_NONE
  },
  beneficialOwnerName: {
    name: "beneficialOwnerName",
    type: TYPE_NONE
  },
  beneficialOwnerAddress: {
    name: "beneficialOwnerAddress",
    type: TYPE_NONE
  },
  beneficialOwnerEinNumber: {
    name: "beneficialOwnerEinNumber",
    type: TYPE_NONE
  },
  beneficialOwnerSsnNumber: {
    name: "beneficialOwnerSsnNumber",
    type: TYPE_NONE
  },
  beneficialOwnerEntityType: {
    name: "beneficialOwnerEntityType",
    type: TYPE_NONE
  },
  beneficialOwnerTaxFilingStatus: {
    name: "beneficialOwnerTaxFilingStatus",
    type: TYPE_NONE
  },
  beneficialOwnerCustodianEinNumber: {
    name: "beneficialOwnerCustodianEinNumber",
    type: TYPE_NONE
  },
  isUsCitizen: {
    name: "isUsCitizen",
    type: TYPE_NONE
  },
  isUsPerson: {
    name: "isUsPerson",
    type: TYPE_NONE
  },
  hasOtherUsResidenceState: {
    name: "hasOtherUsResidenceState",
    type: TYPE_NONE
  },
  residenceState: {
    name: "residenceState",
    type: TYPE_NONE
  },
  hasSsn: {
    name: "hasSsn",
    type: TYPE_NONE
  },
})

export default getProfileEntityInputsMap
