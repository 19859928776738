import capitalize from "lodash.capitalize"

export const C_CORP = "C_CORP"
export const EXEMPT_ORGANIZATION = "EXEMPT_ORGANIZATION"
export const INDIVIDUAL = "INDIVIDUAL"
export const IRA = "IRA"
export const LLC = "LLC"
export const OTHER_RETIREMENT_PLAN = "OTHER_RETIREMENT_PLAN"
export const PARTNERSHIP = "PARTNERSHIP"
export const S_CORP = "S_CORP"
export const TRUST = "TRUST"

const BeneficialOwnerEntityTypeEnum = {
  [C_CORP]: C_CORP,
  [EXEMPT_ORGANIZATION]: EXEMPT_ORGANIZATION,
  [INDIVIDUAL]: INDIVIDUAL,
  [IRA]: IRA,
  [LLC]: LLC,
  [OTHER_RETIREMENT_PLAN]: OTHER_RETIREMENT_PLAN,
  [PARTNERSHIP]: PARTNERSHIP,
  [S_CORP]: S_CORP,
  [TRUST]: TRUST
}

const BeneficialOwnerEntityTypeEnumKeys = Object.keys(BeneficialOwnerEntityTypeEnum)

const BeneficialOwnerEntityTypeEnumOptions = BeneficialOwnerEntityTypeEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  BeneficialOwnerEntityTypeEnumKeys,
  BeneficialOwnerEntityTypeEnumOptions
}

export default BeneficialOwnerEntityTypeEnum
