
const getFundsData = (partners, fundsMap) => {
  const fundsPartners = partners.filter(({ fundId }) => !!fundId)

const data = fundsPartners.map(({ fundId, targetAmount, fundRaisedAmount }) => {
    const amount = fundRaisedAmount
    let { name: title } = fundsMap[fundId] ?? {}

    if (!title) {
      // eslint-disable-next-line no-console
      console.warn(`Fund for ID ${fundId} not found`)
      title = "Undefined"
    }

    return {
      title,
      amount,
      target: targetAmount,
      percentage: Math.ceil(amount / targetAmount * 100),
      rangePercentage: 150,
      targetPercentage: 100,
    }
  })

  return data
}

export default getFundsData
