import { STAGING_HOSTS, PRODUCTION_DOMAINS, CONFIG } from "../../config"

export const PRD_ENV = "prd"
export const DEV_ENV = "dev"
const STG_ENV = "stg"


export const getEnvironment = () => {
  const { hostname } = window.location

  const isStaging = STAGING_HOSTS.includes(hostname)

  if (isStaging) {
    return STG_ENV
  }

  const isProduction = PRODUCTION_DOMAINS
    .filter(domain => hostname.includes(domain))
    .length > 0

  if (isProduction) {
    return PRD_ENV
  }

  return DEV_ENV
}


const getConfig = (name) => {
  const environment = getEnvironment()
  const value = CONFIG[environment][name]

  const isUndefined = value === undefined

  if (isUndefined) {
    throw new Error(`Configuration variable ${name} is undefined in ${environment} config`)
  }

  return value
}

export default getConfig
