import React from "react"
import PropTypes from "prop-types"
import { Form as AntForm } from "antd"

import { EntityForm } from "@components/Form"

import Drawer from "./Drawer"


const CreateDrawer = ({
  onSuccess: onSuccessCustom = () => {},
  form = undefined,
  width = undefined,
  table = undefined,
  onOpen = () => {},
  title,
  drawer,
  ...otherEntityFormProps
}) => {
  const newForm = AntForm.useForm()

  if (!form) {
    form = newForm
  }

  const onClose = () =>
    form.resetFields()

  const onSuccess = async (createdItem) => {
    if (onSuccessCustom) {
      await onSuccessCustom(createdItem)
    }

    if (table) {
      table.current.addItem(createdItem)
    }

    drawer.current.close()
  }

  const drawerProps = {
    forceRender: true,
    destroyOnClose: true,
    width,
    title,
    drawer,
    onOpen,
    onClose,
  }

  const entityFormProps = {
    form,
    onSuccess,
    ...otherEntityFormProps
  }

  return (
    <Drawer {...drawerProps}>
      <EntityForm {...entityFormProps} />
    </Drawer>
  )
}

CreateDrawer.propTypes = {
  form: PropTypes.shape(),
  table: PropTypes.shape(),
  width: PropTypes.number,
  title: PropTypes.string.isRequired,
  drawer: PropTypes.shape().isRequired,
  onOpen: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default CreateDrawer
