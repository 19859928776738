import React from "react"
import PropTypes from "prop-types"
import { Typography, Space } from "antd"

import { isMobile } from "@components"

const { Title, Text } = Typography


const InvestmentHeader = ({
  text = "",
  style = {},
  title = "",
  subtitle = "",
  titleLevel = isMobile ? 3 : 1,
}) => {
  return (
    <Space
      size={4}
      style={{ width: "100%", ...style }}
      direction="vertical"
    >
      <center>
        <Text type="secondary" strong>
          {subtitle}
        </Text>
      </center>

      <Title
        level={titleLevel}
        align="center"
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        {title}
      </Title>

      {
        text && (
          <center style={{ marginTop: 2 }}>
            <Text>
              {text}
            </Text>
          </center>
        )
      }
    </Space>
  )
}

InvestmentHeader.propTypes = {
  text: PropTypes.string,
  style: PropTypes.shape(),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  titleLevel: PropTypes.number,
}

export default InvestmentHeader
