import { TYPE_NONE } from "@components/Form"


const getProfileSdiraInputsMap = () => ({
  isLiabilityAccepted: {
    name: "isLiabilityAccepted",
    type: TYPE_NONE
  },
  hasPurchasedExemptSecurities: {
    name: "hasPurchasedExemptSecurities",
    type: TYPE_NONE
  },
  membershipInterestsTitle: {
    name: "membershipInterestsTitle",
    type: TYPE_NONE
  },
  shouldCustodianSign: {
    name: "shouldCustodianSign",
    type: TYPE_NONE
  },
  custodianId: {
    name: "custodianId",
    type: TYPE_NONE
  },
  accountName: {
    name: "accountName",
    type: TYPE_NONE
  },
  custodianName: {
    name: "custodianName",
    type: TYPE_NONE
  },
  custodianAddress: {
    name: "custodianAddress",
    type: TYPE_NONE
  },
  custodianEinNumber: {
    name: "custodianEinNumber",
    type: TYPE_NONE
  }
})

export default getProfileSdiraInputsMap
