import React from "react"

import { isLocal } from "@components"
import { getConfig } from "@components/Config"

import {
  TYPE_TIME,
  TYPE_NONE,
  TYPE_ENUM,
  TYPE_RADIO,
  TYPE_HIDDEN,
  TYPE_REDACTOR,
  TYPE_CHECKBOX,
} from "@components/Form"

import TemplateVariablesDropdown from "../TemplateVariablesDropdown"

const LABEL_COPY = "Send copy to"
const LABEL_MESSAGE = "Message"
const LABEL_SEND_AFTER = "Sending Options"

const DELAYED_TYPE = "DELAYED"
const RECURRING_TYPE = "RECURRING"

const ENVIRONMENT_LABEL = getConfig('ENVIRONMENT_LABEL')
const isDevelopmentEnvironment = ENVIRONMENT_LABEL === "dev"

const CC_TEMPLATE_KEYS = [
  'DWOLLA_INVALID_SOURCE',
  'INVESTMENT_COMPLETE',
  'INVESTMENT_CREATED',
  'INVESTMENT_FUNDED',
  'INVESTMENT_OVERFUNDED',
  'INVESTMENT_PARTIALLY_FUNDED',
  'INVESTMENT_READY_TO_SIGN',
  'INVESTMENT_SIGNED',
]


const getInputs = (type, variables, appendVariable, organizationEmail, templateKey) => {
  const isDelayed = type === DELAYED_TYPE
  const isRecurring = type === RECURRING_TYPE

  let sendTimeProps = {
    type: TYPE_NONE,
  }

  let sendAfterHoursCountProps = !isDelayed
    ? { type: TYPE_NONE }
    : {
        type: TYPE_RADIO,
        options: [
          {
            label: "Send in an hour (default)",
            value: 1,
          },
          {
            label: "Send in a day",
            value: 24,
          },
        ],
        inputProps: {
          // direction: "horizontal",
        },
        //   style: { width: 120 },
        //   addonAfter: LABEL_HOURS,
        // }
      }

  if (isRecurring) {
    sendTimeProps = {
      type: TYPE_TIME,
      inputProps: {
        showNow: false
      }
    }

    sendAfterHoursCountProps = {
      type: TYPE_ENUM,
      options: [
        {
          label: "Every day",
          value: 24,
        },
        {
          label: "Every 2 days",
          value: 48,
        },
        {
          label: "Every 3 days",
          value: 72,
        },
        {
          label: "Every 4 days",
          value: 96,
        },
        {
          label: "Every 5 days",
          value: 120,
        }
      ],
      inputProps: {
        style: { width: 160 },
      },
    }

    const hasTestingOption = isLocal || isDevelopmentEnvironment

    if (hasTestingOption) {
      sendAfterHoursCountProps.options.push(
        {
          label: "(dev) Every hour",
          value: 1,
        }
      )
    }
  }

  let shouldCCOrganizationProps = {
    type: TYPE_NONE
  }

  const isCCEmailTemplate = CC_TEMPLATE_KEYS.includes(templateKey)

  if (isCCEmailTemplate) {
    shouldCCOrganizationProps = {
      type: TYPE_CHECKBOX,
      inputProps: {
        label: `${LABEL_COPY} ${organizationEmail}`,
      },
      required: false
    }
  }

  const tags = variables
    .map(variable => variable.split(":")[1])

  const inputs = [
    {
      name: "subject",
      inputProps: {
        size: "large",
        suffix: (
          <TemplateVariablesDropdown
            variables={variables}
            appendVariable={appendVariable}
          />
        )
      }
    },
    {
      name: "source",
      type: TYPE_REDACTOR,
      label: LABEL_MESSAGE,
      inputProps: {
        emailTargetPath: 'html',
        options: {
          plugins: [
            'mergetag'
          ],
          mergetag: {
            items: tags
          }
        }
      }
    },
    {
      name: "html",
      type: TYPE_HIDDEN,
    },
    {
      name: "sendAfterHoursCount",
      label: LABEL_SEND_AFTER,
      ...sendAfterHoursCountProps,
    },
    {
      name: "sendTime",
      ...sendTimeProps,
    },
    {
      name: "shouldCCOrganization",
      label: "",
      ...shouldCCOrganizationProps,
    }
  ]

  return inputs
}

export default getInputs
