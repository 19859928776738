import React from "react"
import PropTypes from "prop-types"
import { Divider, Row, Col, theme } from "antd"

import { IdentityCard } from "@components/Identity"
import { useAppContext } from "@components/AppContext"
import { DropdownButton } from "@components/Button"
import { W0, W1, HEADER_HEIGHT } from "@components"

import useOrganizationName from "./helpers/useOrganizationName"


const SidebarDropdown = ({ items }) => {
  const { token } = theme.useToken()

  const name = useOrganizationName()
  const { identity } = useAppContext()

  const contentStyle = {
    minWidth: 240,
    boxShadow: token.boxShadowSecondary,
    borderRadius: token.borderRadiusLG,
    backgroundColor: token.colorBgElevated,
  }

  return (
    <Row
      align="middle"
      style={{
        height: HEADER_HEIGHT,
        paddingRight: W1 + 4,
        paddingLeft: W0 + 4,
      }}
    >
      <Col>
        <DropdownButton
          name={name}
          items={items}
          dropdownRender={menu => (
            <div style={contentStyle}>
              <IdentityCard identity={identity} />

              <Divider style={{ margin: 0 }} />

              {React.cloneElement(menu, { style: { boxShadow: 'none' } })}
            </div>
          )}
        />
      </Col>
    </Row>
  )
}

SidebarDropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export default SidebarDropdown
