import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { DownOutlined, TagsOutlined, EditOutlined } from "@ant-design/icons"
import { Dropdown, Space, Button, theme, Checkbox, Tag } from "antd"

import { useAppContext } from "@components/AppContext"
import { IndexOptionsOutputShape } from "@api/services/backstage"

const LABEL_TAGS = "Tags"
const LABEL_EDIT = "Add More..."
const LABEL_SAVE = "Save"
const LABEL_SUCCESS = "Tags are saved"

const { useToken } = theme


const EditTagsDropdown = ({
  tagIds: initialTagIds = undefined,
  size = undefined,
  isDisabled = false,
  isAlwaysShown = false,
  openTagsDrawer = undefined,
  tags,
  onSave,
}) => {
  const { token } = useToken()

  const { optionsMap } = useOutletContext()
  const { showSuccessMessage } = useAppContext()

  const [ tagIds, setTagIds ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)

  const contentStyle = {
    boxShadow: token.boxShadowSecondary,
    borderRadius: token.borderRadiusLG,
    backgroundColor: token.colorBgElevated,
  }

  const onChange = (tagId, isChecked) =>
    isChecked
      ? setTagIds(prevTagIds => [ ...prevTagIds, tagId ])
      : setTagIds(prevTagIds => [ ...prevTagIds.filter(id => id !== tagId) ])

  const onSaveClick = async () => {
    setIsLoading(true)

    await onSave(tagIds)

    setIsLoading(false)
    showSuccessMessage(LABEL_SUCCESS)
  }

  const onEditClick = () =>
    openTagsDrawer()

  const dropdownRender = () => (
    <div style={contentStyle}>
      <Space style={{ padding: 8 }} direction="vertical">
        <div style={{ maxHeight: 400, overflow: "scroll" }}>
          <Space style={{ padding: 8 }} direction="vertical">
            {
              tags.map(tag => (
                <Checkbox
                  key={tag.id}
                  checked={tagIds.includes(tag.id)}
                  onChange={e => onChange(tag.id, e.target.checked)}
                >
                  <Tag
                    key={tag.id}
                    color={tag.color?.toLowerCase()}
                    bordered={false}
                  >
                    {tag.name}
                  </Tag>
                </Checkbox>
              ))
            }
          </Space>
        </div>

        {
          openTagsDrawer && (
            <Button
              size="small"
              type="text"
              icon={<EditOutlined />}
              style={{ width: "100%" }}
              onClick={onEditClick}
            >
              {LABEL_EDIT}
            </Button>
          )
        }

        <Button
          size="small"
          type="primary"
          style={{ width: "100%" }}
          onClick={onSaveClick}
          loading={isLoading}
        >
          {LABEL_SAVE}
        </Button>
      </Space>
    </div>
  )

  const onOpenChange = () =>
    setTagIds(initialTagIds || [])

  const label = (
    <Space>
      {LABEL_TAGS}
      <DownOutlined />
    </Space>
  )

  const showList = !!initialTagIds

  const initialTags = useMemo(() =>
    (initialTagIds || [])
      .filter(tagId => !!optionsMap[tagId])
      .map(tagId => optionsMap[tagId])
  , [ initialTagIds, optionsMap ])

  const hasTags = initialTags.length > 0

  let buttonIcon = null

  const shouldRenderButton = !showList || (!hasTags && isAlwaysShown)

  if (shouldRenderButton) {
    buttonIcon = <TagsOutlined />
  }

  const renderInitialTags = () => {
    if (hasTags) {
      return (
        <Space size={4}>
          {
            initialTags.map(({ id, name, color}) => (
              <Tag
                key={id}
                color={color?.toLowerCase()}
                style={{ marginRight: 0 }}
                bordered={false}
              >
                {name}
              </Tag>
            ))
          }
        </Space>
      )
    }

    if (isAlwaysShown) {
      return label
    }

    return null
  }

  const content = showList
    ? renderInitialTags()
    : label

  if (!content) {
    return null
  }

  const buttonStyle = {}

  if (showList) {
    buttonStyle.position = "absolute"
    buttonStyle.marginTop = -1
    buttonStyle.paddingLeft = initialTagIds.length > 0 ? 1 : 7
    buttonStyle.paddingRight = 1
  }

  return (
    <Dropdown
      disabled={isDisabled}
      onOpenChange={onOpenChange}
      dropdownRender={dropdownRender}
    >
      <Button
        type="text"
        icon={buttonIcon}
        size={size}
        style={buttonStyle}
      >
        {content}
      </Button>
    </Dropdown>
  )
}

EditTagsDropdown.propTypes = {
  size: PropTypes.string,
  tags: PropTypes.arrayOf(IndexOptionsOutputShape).isRequired,
  tagIds: PropTypes.arrayOf(PropTypes.string),
  onSave: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isAlwaysShown: PropTypes.bool,
  openTagsDrawer: PropTypes.func,
}

export default EditTagsDropdown
