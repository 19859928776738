import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { CreateModal } from "@components/Page"
import { createMyOrganizationEmailIdentityOperation as operation } from "@api/services/organizations"

import getInputs from "./helpers/getInputs"

const LABEL_TITLE = "Connect Domain"
const LABEL_SUBMIT = "Connect"
const LABEL_ENTITY = "Email Identity"


const OrganizationEmailIdentityCreateModal = ({ modal }) => {
  const { updateOrganization } = useOutletContext()

  const inputs = getInputs()
  const schema = operation.getSchema(inputs)

  const onSuccess = newOrganization =>
    updateOrganization(newOrganization)

  const createModalProps = {
    title: LABEL_TITLE,
    entity: LABEL_ENTITY,
    submitLabel: LABEL_SUBMIT,
    failedMessage: false,
    successMessage: false,
    modal,
    schema,
    onSuccess,
    operation,
  }

  return (
    <CreateModal {...createModalProps} />
  )
}

OrganizationEmailIdentityCreateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default OrganizationEmailIdentityCreateModal
