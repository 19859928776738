import React from "react"

import { W0 } from "@components"
import {
  BackButton,
  SubmitButton,
  TYPE_CHECKBOX,
  FormNavigationFooter,
} from "@components/Form"

import { INITIAL_STEP } from "./getStepInputs"

const LABEL_SUBMIT = "Certify"

const LABEL_CONTROLLER = `I hereby certify that the information provided for
[ORGANIZATION] controller is complete and correct.`

const LABEL_BENEFICIAL_OWNERS = `I hereby certify that [ORGANIZATION] has no
beneficial owners.`


const getNoOwnersInputs = ({
  form,
  setStep,
  customerName,
}) => {
  const labelController = LABEL_CONTROLLER
    .replace("[ORGANIZATION]", customerName)

  const labelBeneficialOwners = LABEL_BENEFICIAL_OWNERS
    .replace("[ORGANIZATION]", customerName)

  const isControllerConfirmed = form.getFieldValue("_isControllerConfirmed")
  const hasNoBeneficialOwnersConfirmed = form.getFieldValue("_hasNoBeneficialOwnersConfirmed")

  const isSubmitEnabled =
    isControllerConfirmed &&
    hasNoBeneficialOwnersConfirmed

  const inputs = [
    {
      name: "_isControllerConfirmed",
      type: TYPE_CHECKBOX,
      label: "",
      required: true,
      itemProps: {
        style: { marginBottom: W0 }
      },
      inputProps: {
        label: labelController
      }
    },
    {
      name: "_hasNoBeneficialOwnersConfirmed",
      type: TYPE_CHECKBOX,
      label: "",
      required: true,
      inputProps: {
        label: labelBeneficialOwners
      }
    },
    {
      name: "_submit",
      component: () => {
        const isLoading = form.isSubmitting()

        return (
          <FormNavigationFooter>
            <BackButton onClick={() => setStep(INITIAL_STEP)} />

            <SubmitButton
              isLoading={isLoading}
              isDisabled={!isSubmitEnabled}
            >
              {LABEL_SUBMIT}
            </SubmitButton>
          </FormNavigationFooter>
        )
      }
    },
  ]

  return inputs
}

export default getNoOwnersInputs
