import keyBy from "lodash.keyby"

import { mapInvestmentDocumentsOperation } from "@api/services/backstage"


const getInvestmentsMatch = async (request, investments, fileNames) => {
  const investmentsMap = keyBy(investments, "id")

  const investmentDocuments = investments
    .map(({ id: investmentId, name: investmentName }) => ({
      investmentId,
      investmentName
    }))

  const mutation = {
    fileNames,
    investmentDocuments,
  }

  const { data: { investmentDocuments: results } } =
    await request(mapInvestmentDocumentsOperation, { mutation })

  const match = fileName => {
    const result = results.find(item => item.fileName === fileName)

    if (!result) {
      return { investorAccountId: null, investmentId: null }
    }

    const { investmentId, score } = result
    const { investorAccountId } = investmentsMap[investmentId]

    return { investorAccountId, investmentId, score }
  }

  return match
}

export default getInvestmentsMatch
