import { useOutletContext } from "react-router-dom"

import { getFormattedAmountValue } from "@components/Amount"


const useExportColumns = () => {
  const { investmentsMap } = useOutletContext()

  const columns = [
    {
      title: "Investment",
      value: ({ investmentId }) =>
        investmentsMap[investmentId]?.projectName || investmentId
    }, {
      title: "Profile",
      value: ({ investmentId, investorAccountId }) =>
        investmentsMap[investmentId]?.name || investorAccountId,
    },
    {
      title: "Amount",
      value: ({ amount }) => getFormattedAmountValue(amount)
    },
    {
      title: "Type",
      value: "type"
    }, {
      title: "Status",
      value: "status"
    }, {
      title: "Date",
      value: "date"
    },
  ]

  return columns
}

export default useExportColumns
