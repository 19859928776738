import React, { useRef } from "react"
import PropTypes from "prop-types"
import { App, Typography } from "antd"

import Table from "@components/Table"
import { UpdateModal } from "@components/Page"
import { InputShape, OperationShape } from "@api"

import withActions from "./helpers/withActions"

const { Title } = Typography

const LABEL_UPDATE = "Edit"
const LABEL_SUBMIT = "Save"

const MODAL_WIDTH = 420


const Collection = ({
  title = undefined,
  items = [],
  footer = undefined,
  indexItems = undefined,
  deleteItem = undefined,
  isEditable = false,
  deleteContent = undefined,
  entity,
  renderItem,
  updateSchema,
  onItemUpdated,
  readOperation,
  indexOperation,
  updateOperation,
}) => {
  const { modal } = App.useApp()

  const updateModal = useRef({})
  const updateTitle = `${LABEL_UPDATE} ${entity}`

  const style = { width: "100%" }
  const titleStyle = { marginTop: 0 }

  const onEdit = item =>
    updateModal.current.open(item.id)

  const render = isEditable
    ? withActions(modal, renderItem, onEdit, deleteItem, entity, deleteContent)
    : (id, item) => renderItem(id, item)

  const columnsConfig = [
    {
      key: "id",
      render
    },
  ]

  const tableProps = {
    items,
    style,
    indexItems,
    columnsConfig,
    indexOperation,
    bordered: false,
    showHeader: false,
    shouldRenderHeader: false,
  }

  if (footer) {
    tableProps.footer = footer
  }

  const updateModalProps = {
    width: MODAL_WIDTH,
    modal: updateModal,
    title: updateTitle,
    schema: updateSchema,
    operation: updateOperation,
    onSuccess: onItemUpdated,
    submitLabel: LABEL_SUBMIT,
    entity,
    readOperation,
  }

  return (
    <>
      {
        title && (
          <Title
            level={5}
            style={titleStyle}
          >
            {title}
          </Title>
        )
      }

      <Table {...tableProps} />

      <UpdateModal {...updateModalProps} />
    </>
  )
}

Collection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string,
  entity: PropTypes.string.isRequired,
  footer: PropTypes.func,
  indexItems: PropTypes.func,
  isEditable: PropTypes.bool,
  deleteItem: PropTypes.func,
  renderItem: PropTypes.func.isRequired,
  updateSchema: PropTypes.arrayOf(InputShape).isRequired,
  deleteContent: PropTypes.func,
  onItemUpdated: PropTypes.func.isRequired,
  readOperation: OperationShape.isRequired,
  indexOperation: OperationShape.isRequired,
  updateOperation: OperationShape.isRequired,
}

export default Collection
