import React from "react"
import { Form } from "antd"

import { UploadInput } from "../../Input"

const LABEL_REQUIRED = "File upload is required"


const renderUpload = (itemProps, inputProps, form, path, request) => {
  const { rules } = itemProps
  const isRequired = rules.length > 0

  if (isRequired) {
    itemProps.rules = [
      {
        required: true,
        message: LABEL_REQUIRED,
      }
    ]
  }

  return (
    /* td: item is not focused if required rule not satisfied */
    <Form.Item {...itemProps}>
      <UploadInput
        form={form}
        path={path}
        request={request}
        {...inputProps}
      />
    </Form.Item>
  )
}

export default renderUpload
