import React, { useReducer } from "react"
import { PlusOutlined, MinusOutlined, DeleteOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"

import useDistributionActions from "./useDistributionActions"

const LABEL_REJECT = "Reject"
const LABEL_DELETE = "Delete"
const LABEL_APPROVE = "Approve"

const reducer = (state, { isDeleteDisabled = true, isApproveDisabled = true, isRejectDisabled = true }) => {
  return { ...state, isDeleteDisabled, isApproveDisabled, isRejectDisabled }
}

const useSelectableActions = (table) => {
  const [ state, dispatch ] = useReducer(reducer, {
    isDeleteDisabled: true,
    isRejectDisabled: true,
    isApproveDisabled: true,
  });

  const {
    isDeleteDisabled,
    isRejectDisabled,
    isApproveDisabled,
  } = state

  const getSelectedDistributions = () => {
    const ids = table.current.getSelectedRowKeys()
    const distributions = table.current.getItems()

    const selectedDistributions = distributions
      .filter(({ id }) => ids.includes(id))

    return selectedDistributions
  }

  const onSelectionChange = ids => {
    const hasItems = ids.length !== 0

    if (!hasItems) {
      dispatch({})
      return
    }

    const distributions = table.current.getItems()

    const selectedDistributions = distributions
      .filter(({ id }) => ids.includes(id))

    const isHasBackfill = selectedDistributions
      .filter(({ isExternal }) => isExternal).length > 0

    const isAllApprove = selectedDistributions
      .filter(({ isApproved }) => !isApproved).length === ids.length

    const isAllReject = selectedDistributions
      .filter(({ isExternal }) => !isExternal).length === ids.length

    const stateItems = {}
    if (isHasBackfill) {
      stateItems.isDeleteDisabled = false
    }

    if (isAllApprove) {
      stateItems.isApproveDisabled = false
    }

    if (isAllReject) {
      stateItems.isRejectDisabled = false
    }

    dispatch(stateItems)
  }

  const afterAction = async () => {
    await table.current.indexItems()
    table.current.setSelectedRowKeys([])
    dispatch({})
  }

  const {
    approveDistribution,
    rejectDistribution,
    deleteBackfillDistributions
  } = useDistributionActions(getSelectedDistributions, afterAction)

  const actions = []

  const canDelete = hasAccess(['transactions-delete'])
  const canApproveOrReject = hasAccess(['transactions-approve'])

  if (canApproveOrReject) {
    actions.push(
    {
      icon: <PlusOutlined />,
      action: approveDistribution,
      title: LABEL_APPROVE,
      disabled: isApproveDisabled,
    },
    {
      icon: <MinusOutlined />,
      action: rejectDistribution,
      title: LABEL_REJECT,
      disabled: isRejectDisabled,
    })
  }

  if (canDelete) {
    actions.push({
      icon: <DeleteOutlined />,
      action: deleteBackfillDistributions,
      title: LABEL_DELETE,
      disabled: isDeleteDisabled,
    })
  }

  const hasActions = actions.length > 0

  if (!hasActions) {
    return []
  }

  return [ actions, onSelectionChange ]
}

export default useSelectableActions
