import React from "react"
import PropTypes from "prop-types"

import { VirtualTable } from "@components/Table"
import { indexTransactionsOperation as indexOperation } from "@api/services/transactions"

import useTableSummary from "./helpers/useTableSummary"
import useColumnsConfig from "./helpers/useColumnsConfig"
import getHeaderActions from "./helpers/getHeaderActions"
import useExportColumns from "./helpers/useExportColumns"
import TransactionsTableHeader from "./TransactionsTableHeader"
import useIndexOperationParameters from "./helpers/useIndexOperationParameters"

const DEFAULT_SORT_COLUMN = "date"


const TransactionsTable = ({
  table,
  openImportModal,
  openInvestmentDrawer,
  openTransactionDrawer,
}) => {
  const {
    updateDateRange,
    operationParameters,
  } = useIndexOperationParameters()

  const headerRenderTitle = title =>
    <TransactionsTableHeader
      title={title}
      updateDateRange={updateDateRange}
    />

  const size = "small"
  const sticky = true
  const summary = useTableSummary()
  const bordered = true
  const headerActions = getHeaderActions(openImportModal)
  const columnsConfig = useColumnsConfig(openInvestmentDrawer, openTransactionDrawer)
  const exportColumns = useExportColumns()
  const exportFileName = "transactions"
  const defaultSortColumn = DEFAULT_SORT_COLUMN

  const tableProps = {
    size,
    table,
    sticky,
    summary,
    bordered,
    exportColumns,
    headerActions,
    columnsConfig,
    indexOperation,
    exportFileName,
    headerRenderTitle,
    defaultSortColumn,
    operationParameters,
  }

  return (
    <VirtualTable {...tableProps} />
  )
}

TransactionsTable.propTypes = {
  table: PropTypes.shape().isRequired,
  openImportModal: PropTypes.func.isRequired,
  openInvestmentDrawer: PropTypes.func.isRequired,
  openTransactionDrawer: PropTypes.func.isRequired,
}

export default TransactionsTable
