import React from "react"
import { Descriptions } from "antd"

import { getResizedImageUrl } from "@components/Storage"
import { EmailPreview, Value } from "@components/Text"
import { OrganizationEmail, OrganizationDomain } from "@modules/backstage/organizations"

import OrganizationControllerDropdown from "../OrganizationControllerDropdown"

const { Item } = Descriptions

const LOGO_HEIGHT = 62

const LABEL_LOGO = "Logo"
const LABEL_NAME = "Name"
const LABEL_EMAIL = "Email"
const LABEL_PHONE = "Phone"
const LABEL_DOMAIN = "Domain"
const LABEL_SIGNATURE = "Email Signature"
const LABEL_CONTROLLER = "Controller"


const useItems = (organization) => {
  const {
    name,
    logoUrl,
    phoneNumber,
    emailFooterHtml,
  } = organization

  const items = [
    {
      label: LABEL_NAME,
      value: name
    },
    {
      label: LABEL_DOMAIN,
      value: (
        <OrganizationDomain organization={organization} />
      )
    },
    {
      label: LABEL_EMAIL,
      value: (
        <OrganizationEmail organization={organization} />
      )
    },
    {
      label: LABEL_PHONE,
      value: <Value value={phoneNumber} />
    },
    {
      label: LABEL_LOGO,
      value: logoUrl
        ? (
            <img
              alt={name}
              src={getResizedImageUrl(logoUrl, null, LOGO_HEIGHT * 2)}
              height={LOGO_HEIGHT}
            />
          )
        : ""
    },
    {
      label: LABEL_SIGNATURE,
      value: (
        <EmailPreview
          html={emailFooterHtml}
          style={{ padding: 0 }}
        />
      )
    },
  ]

  items.push({
    label: LABEL_CONTROLLER,
    value: <OrganizationControllerDropdown />,
  })

  return items
    .map(({ label, value }, key) =>
      <Item
        key={key}
        label={label}
      >
        {value}
      </Item>
    )
}

export default useItems
