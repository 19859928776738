import { TYPE_ROLE, TYPE_HIDDEN, TYPE_ARRAY, TYPE_PHONE } from "@components/Form"
import { UserGroupRoleEnumOptions } from "@api/services/backstage/shapes/UserGroupRoleEnum"

import getAdditionalPermissionInputs from "./getAdditionalPermissionInputs"

const LABEL_ROLE = "Select Role"
const LABEL_GROUPS = "Access Groups"
const LABEL_LAST_NAME = "Last Name"
const LABEL_FIRST_NAME = "First Name"


const getInputs = ({
  userGroups = [],
  isManager,
  updateSchema,
  organizationName,
  isConsoleOrganization
}) => {
  const additionalPermissionInputs = isConsoleOrganization
    ? []
    : getAdditionalPermissionInputs(organizationName)

  const userGroupsOptions = isConsoleOrganization
    ? userGroups.map(({ id: value, name: label }) => ({ value, label }))
    : []

  const memberRolesInputs = isConsoleOrganization
    ? []
    : [{
      name: "roles",
      type: TYPE_ROLE,
      label: LABEL_ROLE,
      inputProps: {
        onChange: () => updateSchema(),
      }
    }]

  const consoleOrganizationRolesInputs = isConsoleOrganization
    ? [{
      name: "roles",
      type: TYPE_ROLE,
      label: LABEL_ROLE,
      options: UserGroupRoleEnumOptions,
      inputProps: {
        onChange: () => updateSchema(),
      }
    }]
    : []

  const hasGroupsInput =
    isConsoleOrganization &&
    isManager

  const groupsInput = hasGroupsInput
    ? {
        name: "userGroupIds",
        type: TYPE_ARRAY,
        label: LABEL_GROUPS,
        options: userGroupsOptions,
        required: true,
      }
    : {
        name: "userGroupIds",
        type: TYPE_HIDDEN,
        required: false,
      }

  const mobileInput = isConsoleOrganization
    ? {
        name: "mobile",
        type: TYPE_HIDDEN,
      }
    : {
      name: "mobile",
      type: TYPE_PHONE,
    }

  return [
    ...memberRolesInputs,
    ...additionalPermissionInputs,
    {
      name: "givenName",
      label: LABEL_FIRST_NAME,
      itemProps: { style: { width: 270 } },
    },
    {
      name: "familyName",
      label: LABEL_LAST_NAME,
      itemProps: { style: { width: 270 } },
    },
    {
      name: "email",
      itemProps: { style: { width: 270 } },
    },
    {
      name: "sponsorId",
      type: TYPE_HIDDEN,
    },
    ...consoleOrganizationRolesInputs,
    mobileInput,
    groupsInput,
  ]
}

export default getInputs
