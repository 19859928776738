import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"

import { useStore } from "@components/Store"
import { useAppContext } from "@components/AppContext"
import { INVESTMENT_STATUS } from "@components/Domain"
import { indexAccountInvestmentsOperation as indexOperation } from "@api/services/investments"


const useInvestmentsStore = (investorAccountId) => {
  const { request } = useAppContext()
  const { getProject } = useOutletContext()

  const shouldAutoLoad = false

  const status = INVESTMENT_STATUS.ACTIVE

  const opearationParameters = useMemo(() =>
    ({ investorAccountId, status })
  , [ investorAccountId, status ])

  const {
    items,
    indexItems: indexInvestments
  } = useStore(indexOperation, request, shouldAutoLoad, opearationParameters)

  const investments = useMemo(() =>
    (items || []).map(investment => {
      const { projectId } = investment

      const investmentProject = getProject(projectId)

      return {
        ...investment,
        investmentProject
      }
    })
  , [ items, getProject ])

  return {
    investments,
    indexInvestments,
  }
}

export default useInvestmentsStore
export { indexOperation }
