import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button } from 'antd'
import { CloudDownloadOutlined } from "@ant-design/icons"

import useExportAction from "./helpers/useExportAction"
import ExportColumnShape from "./shapes/ExportColumnShape"


const ExportAction = ({
  type = "dashed",
  icon = (<CloudDownloadOutlined />),
  title = null,
  shape = "circle",
  columns = [],
  fileName,
  getItems,
}) => {
  const [ isLoading, setIsLoading ] = useState(false)

  const exportMethod = useExportAction(getItems, fileName, columns)

  const onClick = () => {
    setIsLoading(true)
    exportMethod()
    setIsLoading(false)
  }

  return (
    <Button
      icon={icon}
      type={type}
      shape={shape}
      onClick={onClick}
      loading={isLoading}
    >
      {title}
    </Button>
  )
}

ExportAction.propTypes = {
  columns: PropTypes.arrayOf(ExportColumnShape),
  fileName: PropTypes.string.isRequired,
  getItems: PropTypes.func.isRequired,
  title: PropTypes.string,
  shape: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.shape(),
}

export default ExportAction
