import React from "react"
import PropTypes from "prop-types"
import { Tag } from "antd"

const LABEL_DOCUMENTS = "Documents Pending"
const LABEL_FUNDS = "Funds Pending"
const LABEL_FUNDED = "Funds Received"


const InvestmentStatusTag = ({ isSigned, isFunded }) => {
  if (!isSigned) {
    return <Tag color="red">{LABEL_DOCUMENTS}</Tag>
  }

  if (!isFunded) {
    return <Tag color="red">{LABEL_FUNDS}</Tag>
  }

  return <Tag color="green">{LABEL_FUNDED}</Tag>
}

InvestmentStatusTag.propTypes = {
  isSigned: PropTypes.bool.isRequired,
  isFunded: PropTypes.bool.isRequired
}

export default InvestmentStatusTag
