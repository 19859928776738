import React from "react"
import { useOutletContext } from "react-router-dom"
import { Tag, Space, Tooltip } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

import { ago } from "@components/Date"
import Identity from "@components/Identity"
import { hasAccess } from "@components/Authorization"
import { ROLES_MAP } from "@components/Domain"
import { useAppContext } from "@components/AppContext"
import { UserGroupsColumn } from "@components/Column"

import TableRowActions from "../TableRowActions"

const LABEL_CONTROLLER = "This accountant controls the business's bank accounts."


const getRoleColor = roleId => {
  const role = ROLES_MAP[roleId]

  if (!role) {
    return []
  }

  const { tagColor } = role

  return tagColor
}

const getRoleTitle = roleId => {
  const role = ROLES_MAP[roleId]

  if (!role) {
    return ""
  }

  const { title } = role

  return title
}

const useTableColumns = (openUpdateModal) => {
  const { organization } = useOutletContext()
  const { isConsoleOrganization } = useAppContext()

  const { controllerId } = organization

  const isController = user =>
    controllerId === user.id

  const nameColumnProps = {
    key: "name",
    title: "Name",
    render: (name, user) => (
        <Identity strong id={user.id}>
          <Space>
            {name}
            {
              isController(user) && (
                <Tooltip title={LABEL_CONTROLLER} placement="top">
                  <ExclamationCircleOutlined />
                </Tooltip>
              )
            }
          </Space>
        </Identity>
      ),
    compute: item => `${item.givenName} ${item.familyName}`,
    isSearchable: true
  }

  const emailColumnProps = {
    key: "email",
    isSearchable: true
  }

  const userGroupsColumnProps = {
    key: "userGroupIds",
    title: "Groups",
    render: (_, { userGroupIds = [] }) =>
      <UserGroupsColumn userGroupIds={userGroupIds}/>
  }

  const rolesColumnProps = {
    key: "roles",
    title: "Roles",
    compute: ({ roles }) => getRoleTitle(roles[0]),
    isSortingEnabled: true,
    render: (value, { roles }) =>
      <Space size={0}>
        {
          roles.map((role, key) =>
            <Tag
              key={key}
              color={getRoleColor(role)}
              bordered={false}
            >
              {getRoleTitle(role)}
            </Tag>
          )
        }
      </Space>
  }

  const lastSignedInColumnProps = {
    key: "lastAuthenticatedAt",
    title: "Last Signed In",
    render: value => ago(value, true)
  }

  const actionsColumnProps = {
    key: "actions",
    title: false,
    render: (_, member) =>
      member.referenceUserGroupId
        ? null
        : (
            <TableRowActions
              user={member}
              openUpdateModal={openUpdateModal}
            />
          )
  }

  const columns = [
    { ...nameColumnProps },
    { ...emailColumnProps },
  ]

  if (isConsoleOrganization) {
    columns.push({ ...userGroupsColumnProps })
  }

  if (!isConsoleOrganization) {
    columns.push({ ...rolesColumnProps })
  }

  const canUpdateMembers =
    hasAccess(['users-write']) ||
    hasAccess(['fund-users-write'])

  if (canUpdateMembers) {
    columns.push(
      { ...lastSignedInColumnProps },
      { ...actionsColumnProps, width: 48 },
    )

  } else {
    columns.push(
      { ...lastSignedInColumnProps },
    )

  }

  return columns
}

export default useTableColumns
