import React, { useEffect, useRef, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { Form, Input, theme } from "antd"
import { MaskedInput as MaskedInputComponent } from "antd-mask-input"

import { ItemPropsShape, InputPropsShape } from "@api"

import FormShape from "../shapes/FormShape"

const DEFAULT_WIDTH = 140
const ERROR_INVALID_FORMAT = "Value doesn't match the format"

const REGEX_REPLACE_SYMBOLS = /\/|-|_/g


const MaskedInput = ({
  maskOptions = {},
  onChangeFormatter = (value, setValue) => setValue(value),
  form,
  mask,
  fieldPath,
  itemProps,
  inputProps,
  maskedRegex,
  getMaskedValue,
}) => {
  const maskRef = useRef()

  const { token } = theme.useToken()
  const { colorText } = token

  const {
    name,
    rules,
    initialValue,
    ...restItemProps
  } = itemProps

  const {
    placeholder,
    ...restinputProps
  } = inputProps

  if (initialValue) {
    restItemProps.initialValue = getMaskedValue(initialValue)
  }

  const isRequired = rules.find(rule => rule.required)

  const inputRules = [
    {
      ...isRequired
    },
    {
      message: ERROR_INVALID_FORMAT,
      validator: (_, value = "") => {
        const escapedValue = value.replace(REGEX_REPLACE_SYMBOLS, "")

        const isEscapedValueEmpty = escapedValue.length === 0
        const isEmptyValueValid = isEscapedValueEmpty && !isRequired

        if (isEmptyValueValid) {
          return Promise.resolve()
        }

        const isValid = maskedRegex.test(value)

        if (isValid) {
          return Promise.resolve()
        }

        return Promise.reject(ERROR_INVALID_FORMAT)
      }
    }
  ]

  const inputField = `_${fieldPath.slice(-1)}`
  const maskedInputFieldPath = useMemo(() => [...fieldPath.slice(0, -1), inputField], [inputField, fieldPath])

  const hiddenValue = Form.useWatch(fieldPath, form)
  const maskedInput = Form.useWatch(maskedInputFieldPath, form)

  const setValue = useCallback(() => {
    if (!hiddenValue) { return }

    const formattedValue = getMaskedValue(hiddenValue)
    form.setFieldValue(maskedInputFieldPath, formattedValue)
  }, [
    form,
    hiddenValue,
    getMaskedValue,
    maskedInputFieldPath,
  ])

  useEffect(() =>
    setValue()
  , [ setValue ])

  useEffect(() => {
    if (!maskedInput && hiddenValue) {
      setValue()
    }

    maskRef.current.input.value = maskedInput || ""
  }, [
    setValue,
    itemProps,
    hiddenValue,
    maskedInput,
  ])

  const onChange = ({ unmaskedValue, maskedValue }) => {
    const isValid = maskedRegex.test(maskedValue)

    if (!isValid) {
      form.setFieldValue(fieldPath, "")
      return
    }

    onChangeFormatter(unmaskedValue, value => form.setFieldValue(fieldPath, value))
  }

  const maskInputStyles = inputProps.disabled
    ? {
      style: { width: DEFAULT_WIDTH},
    }
    : {
      style: { width: DEFAULT_WIDTH, color: colorText },
      styles: { input: {color: colorText} }
    }

  return (
    <>
      <Form.Item
        key="hidden"
        name={name}
        hidden={true}
        initialValue={initialValue}
      >
        <Input />
      </Form.Item>

      <Form.Item
        key="masked"
        name={maskedInputFieldPath}
        rules={inputRules}
        className="mask-input"
        {...restItemProps}
      >
        <MaskedInputComponent
          ref={maskRef}
          mask={mask}
          onChange={onChange}
          maskOptions={maskOptions}
          {...restinputProps}
          {...maskInputStyles}
        />
      </Form.Item>
    </>
  )
}

MaskedInput.propTypes = {
  form: FormShape.isRequired,
  mask: PropTypes.string.isRequired,
  fieldPath: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemProps: ItemPropsShape.isRequired,
  inputProps: InputPropsShape.isRequired,
  maskOptions: PropTypes.shape(),
  maskedRegex: PropTypes.instanceOf(RegExp).isRequired,
  getMaskedValue: PropTypes.func.isRequired,
  onChangeFormatter: PropTypes.func,
}

export default MaskedInput
