import { TYPE_DOMAIN, TYPE_NONE } from "@components/Form"

const LABEL_DOMAIN = "Custom Domain"
const DOMAIN_REGEX = /^(?!.*@)([a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.)+[a-zA-Z]{2,}$/


const getInputs = () => {
  return [
    {
      name: "emailIdentity",
      label: "",
      schema: [
        {
          name: "domain",
          type: TYPE_DOMAIN,
          label: LABEL_DOMAIN,
          itemProps: {
            isSubdomain: false,
            rules: [
              {
                pattern: DOMAIN_REGEX,
                message: "Invalid domain",
              }
            ],
          },
        },
        {
          name: 'isVerified',
          type: TYPE_NONE
        },
        {
          name: 'dnsRecords',
          type: TYPE_NONE
        }
      ]
    }
  ]
}

export default getInputs
