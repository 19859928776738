import React from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

import List from "@components/List"
import { indexOperation } from "@components/Store/InvestorStore/useProjectsStore"

import useRenderItem from "./helpers/useRenderItem"
import { ExtendedProjectShape } from "../helpers/useGroupedProjects"


const ProjectsList = ({ title, items }) => {
  const navigate = useNavigate()
  const render = useRenderItem()

  const onClick = ({ id }) =>
    navigate(`/projects/${id}/about`)

  const listProps = {
    indexItems: () => {},
    title,
    items,
    render,
    onClick,
    indexOperation,
  }

  return (
    <List {...listProps} />
  )
}

ProjectsList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(ExtendedProjectShape).isRequired,
}

export default ProjectsList
