import React from "react"
import { ImportOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"

const LABEL_IMPORT = "Import"


const getHeaderActions = (openImportModal) => {
  const canCreateTransactions = hasAccess(['transactions-write'])

  const items = []

  if (canCreateTransactions) {
    items.push({
      icon: <ImportOutlined />,
      action: openImportModal,
      title: LABEL_IMPORT,
    })
  }

  return items
}

export default getHeaderActions
