import React from "react"
import { List } from "antd"
import PropTypes from "prop-types"

import { ListTemplatesOutputShape } from "@api/services/notifications"

import TemplateListItem from "./TemplateListItem"

const NOT_AVAILABLE_KEYS = []

const isAvailable = ({ key }) => !NOT_AVAILABLE_KEYS.includes(key)


const TemplatesList = ({
  templates,
  toggleTemplate,
  openUpdateDrawer
}) => {
  const size = "small"

  const dataSource = templates
    .filter(isAvailable)

  const renderItem = template =>
    <TemplateListItem
      onClick={() => openUpdateDrawer(template)}
      template={template}
      toggleTemplate={toggleTemplate}
    />

  const listProps = {
    size,
    dataSource,
    renderItem,
  }

  return (
    <List {...listProps} />
  )
}

TemplatesList.propTypes = {
  templates: PropTypes.arrayOf(ListTemplatesOutputShape).isRequired,
  toggleTemplate: PropTypes.func.isRequired,
  openUpdateDrawer: PropTypes.func.isRequired,
}

export default TemplatesList
