import React, { useState } from "react"
import PropTypes from "prop-types"

import { useAppContext } from "@components/AppContext"
import { UNPROCESSIBLE_CONDITION_ERROR } from "@api"
import {
  connectSourceOperation,
  readSourceLinkOperation,
} from "@api/services/transactions"

import PlaidConnect from "./PlaidConnect"


const PlaidModal = ({
  onSuccess: onSuccessCustom,
  modal,
  onClose,
  customerId,
  onConnectPlaidSelected = undefined
}) => {
  const [ linkToken, setLinkToken ] = useState()

  const {
    showErrorMessage,
    authenticatedRequest,
    addIdentityCustomerSource,
  } = useAppContext()

  const createLinkToken = () => {
    setLinkToken(null)
    return authenticatedRequest(readSourceLinkOperation)
      .then(({ data }) => setLinkToken(data.linkToken))
  }

  modal.current.open = async (setLoading) => {
    const callback = async () => {
      setLoading(true)
      await createLinkToken()
      setLoading(false)
    }

    return onConnectPlaidSelected
      ? onConnectPlaidSelected(() => callback())
      : callback()
  }

  const onSuccess = sourceToken => {
    const parameters = {
      mutation: {
        sourceToken,
        customerId
      }
    }

    const expectedErrors = [ UNPROCESSIBLE_CONDITION_ERROR ]

    return authenticatedRequest(connectSourceOperation, parameters, expectedErrors)
      .then(({ data: source }) => {
        addIdentityCustomerSource(customerId, source)
        return onSuccessCustom(source.id, source)
      })
      .catch(exception => {
        const { isExpected, originalError } = exception

        if (isExpected) {
          showErrorMessage(originalError.message)
        }

        throw exception
      })
      .finally(() => onClose())
  }

  if (!linkToken) {
    return null
  }

  return (
    <PlaidConnect
      token={linkToken}
      onExit={onClose}
      onSuccess={onSuccess}
    />
  )
}

PlaidModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  onConnectPlaidSelected: PropTypes.func,
}

export default PlaidModal
