import React from "react"
import PropTypes from "prop-types"
import { Descriptions } from "antd"

import { ReadActivityOutputShape } from "@api/services/activities"


const ActivitySummaryDescriptions = ({
  activity,
  renderActivity,
}) => {
  const items = [
    {
      key: 'summary',
      label: 'Summary',
      children: renderActivity(activity),
    },
  ]

  return (
    <Descriptions
      size="small"
      style={{ overflow: "hidden" }}
      items={items}
      layout="vertical"
      column={1}
      bordered={true}
    />
  )
}

ActivitySummaryDescriptions.propTypes = {
  activity: ReadActivityOutputShape.isRequired,
  renderActivity: PropTypes.func.isRequired,
}

export default ActivitySummaryDescriptions
