import React, { useRef, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Layout, theme } from "antd"

import Text from "@components/Text"
import { useBackstageStore } from "@components/Store"
import { ProjectCreateModal } from "@modules/backstage/projects"
import { SIDEBAR_WIDTH, isMobile } from "@components"
import { useLayoutTopOffset, ReleaseAlert } from "@components/Page"

import Sidebar from "./Sidebar"
import SecondFactorAlert from "./SecondFactorAlert"
import BackstageLayoutOutlet from "./BackstageLayoutOutlet"
import InvalidBankAccountAlert from "./InvalidBankAccountAlert"

const LABEL_INITIALIZE = "Initializing..."


const BackstageLayout = () => {
  const createProjectModal = useRef({})

  const { token } = theme.useToken()
  const { colorBgContainer } = token

  const { pathname } = useLocation()

  const {
    isReady,
    context: backstageContext
  } = useBackstageStore()

  const {
    projects,
    addProject,
    bankAccounts,
    failedTransactions,
  } = backstageContext

  const layoutTopOffset = useLayoutTopOffset(bankAccounts, projects)

  useEffect(() => {
    if (isMobile) {
      document
        .querySelector('meta[name="viewport"]')
        .setAttribute("content", "width=1400")
    }
  }, [])

  if (!isReady) {
    return <Text text={LABEL_INITIALIZE} />
  }

  const layoutProps = {
    style: { background: colorBgContainer }
  }

  const isFullscreenMode = pathname.startsWith('/backstage/campaigns/CAM_')

  if (!isFullscreenMode) {
    layoutProps.style.marginLeft = SIDEBAR_WIDTH
  }

  const openCreateProjectModal = () =>
    createProjectModal.current.open()

  const failedTransactionsCount = (failedTransactions || []).length

  const sidebarProps = {
    projects,
    openCreateProjectModal,
    failedTransactionsCount,
  }

  const projectCreateModalProps = {
    modal: createProjectModal,
    addProject,
  }

  const context = {
    layoutTopOffset,
    ...backstageContext,
  }

  return (
    <>
      {
        !isFullscreenMode && (
          <Sidebar {...sidebarProps} />
        )
      }

      <Layout {...layoutProps}>
        <Layout.Content>
          {
            !isFullscreenMode && (
              <>
                <ReleaseAlert />
                <SecondFactorAlert />
                <InvalidBankAccountAlert
                  projects={projects}
                  bankAccounts={bankAccounts}
                />
              </>
            )
          }

          <BackstageLayoutOutlet context={context} />
        </Layout.Content>
      </Layout>

      <ProjectCreateModal
        {...projectCreateModalProps}
      />
    </>
  )
}

export default BackstageLayout
