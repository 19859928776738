import PropTypes from "prop-types"

import SourceShape from "./SourceShape"

const properties = {
  businessVerificationFailedReasons: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string.isRequired,
  identityVerificationFailedReasons: PropTypes.arrayOf(PropTypes.string),
  investorAccountId: PropTypes.string,
  isSoleProprietorship: PropTypes.bool,
  name: PropTypes.string.isRequired,
  sources: PropTypes.arrayOf(SourceShape).isRequired,
  verificationStatus: PropTypes.string.isRequired,
  verificationStep: PropTypes.string
}

const CustomerShape = PropTypes.exact(properties)

export default CustomerShape
export { properties }
