import React from "react"
import PropTypes from "prop-types"
import { Modal, Spin, Typography, Row, Space } from "antd"

const { Text } = Typography


const BlockingModal = ({
  isOpen = false,
  text,
}) => {
  const modalProps = {
    open: isOpen,
    title: null,
    footer: null,
    closable: false,
    maskClosable: false,
    destroyOnClose: true,
  }

  return (
    <Modal {...modalProps}>
      <Row justify="center">
        <Space direction="vertical" style={{ maxWidth: 340 }}>
          <Spin size="large">
            <div style={{ height: 40, width: "100%" }} />
          </Spin>

          <Text>
            <center>{text}</center>
          </Text>
        </Space>
      </Row>
    </Modal>
  )
}

BlockingModal.propTypes = {
  text: PropTypes.oneOfType([ PropTypes.string,  PropTypes.shape()]).isRequired,
  isOpen: PropTypes.bool,
}

export default BlockingModal
