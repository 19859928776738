import { useState, useEffect, useCallback } from "react"

import { runRequest } from "@components/AppContext"


const useStore = (indexOperation, request, shouldAutoLoad = true, parameters = undefined, extraDependencies = []) => {
  const [ items, setItems ] = useState()

  const addItem = useCallback(
    newItem =>
      setItems(prevItems => [ newItem, ...prevItems ])
  , [])

  const updateItem = useCallback(
    updatedItem =>
      setItems((prevItems = []) =>
        prevItems.map(item => ({
          ...(item.id === updatedItem.id ? updatedItem : item)
        }))
      )
  , [])

  const removeItem = useCallback(
    id =>
      setItems(prevItems => [
        ...prevItems.filter(item => item.id !== id)
      ])
  , [])

  const resetItems = useCallback(
    () => setItems()
  , [])

  const getItem = useCallback(
    (id) => (items || []).find(item => item.id === id)
  , [ items ])

  const indexItems = useCallback(() =>
    request(indexOperation, parameters)
      .then(({ data }) => setItems(data))
  , [
    request,
    parameters,
    indexOperation,
  ])

  useEffect(() => {
    if (!shouldAutoLoad) {
      return
    }

    if (items) {
      return
    }

    return runRequest(indexItems)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ items, shouldAutoLoad, indexItems, ...extraDependencies ])

  return {
    items,
    getItem,
    addItem,
    setItems,
    removeItem,
    resetItems,
    indexItems,
    updateItem,
  }
}

export default useStore
