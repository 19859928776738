import React from "react"


const CollectionRow = (props) => {
  return (
    <tr {...props} />
  )
}

export default CollectionRow
