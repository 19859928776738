import { createUploadUrlsOperation } from "@api/services/storage"


const createUploadUrl = async (request, isPublic, contentType) => {
  contentType = contentType.replace('+', '-')
  const parameters = { quantity: 1, contentType, isPublic }

  const { data: { urls } } = await request(createUploadUrlsOperation, parameters)
  const [ uploadUrl ] = urls

  return uploadUrl
}

export default createUploadUrl
