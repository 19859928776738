import Big from "big.js"


const getFormattedDecimalValue = (firstValue, secondValue, operand) => {
  const big = new Big(firstValue)[operand](secondValue)
  const amount = big.toNumber()

  return amount
}

export default getFormattedDecimalValue
