import React from "react"

import { Header } from "@components/Form"
import { getUsAddressSchema } from "@components/Address"

import getBeneficiaryNameProps from "./getBeneficiaryNameProps"

const LABEL_ENTITY = "Entity"
const LABEL_LEGAL_NAME = "Legal Name"
const LABEL_ENTITY_INFO = `Please enter the entity and address information
exactly as it appears on your EIN (Employer Identification Number) letter.`


const getEntityInputs = (form, bankAccounts = []) => {
  const beneficiaryNameProps = getBeneficiaryNameProps(bankAccounts)

  const inputs = [
    {
      name: "_entity",
      component: ({ style }) =>
        <Header style={style} info={LABEL_ENTITY_INFO}>
          {LABEL_ENTITY}
        </Header>
    },
    {
      name: "beneficiaryName",
      label: LABEL_LEGAL_NAME,
      placeholder: "Example LLC",
      itemProps: {
        rules: [{
          pattern: /^[a-zA-Z0-9 \\\\s]{1,255}$/,
          message: "Should not include special characters"
        }]
      },
      ...beneficiaryNameProps
    },
    {
      name: "beneficiaryAddress",
      label: "",
      placeholder: "",
      schema: getUsAddressSchema(),
    },
  ]

  return inputs
}

export default getEntityInputs
