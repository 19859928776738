import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import { PlusOutlined, EditOutlined, RedoOutlined } from "@ant-design/icons"

const LABEL_ADD = "Add"
const LABEL_EDIT = "Edit"
const LABEL_REDO = "Refresh"
const LABEL_CONNECT = "Connect"

export const TYPES = {
  ADD: "add",
  EDIT: "edit",
  REDO: "redo",
  CONNECT: "connect",
}
const LABELS_MAP = {
  [TYPES.ADD]: LABEL_ADD,
  [TYPES.EDIT]: LABEL_EDIT,
  [TYPES.REDO]: LABEL_REDO,
  [TYPES.CONNECT]: LABEL_CONNECT,
}
const ICONS_MAP = {
  [TYPES.ADD]: <PlusOutlined />,
  [TYPES.EDIT]: <EditOutlined />,
  [TYPES.REDO]: <RedoOutlined />,
  [TYPES.CONNECT]: <PlusOutlined />,
}


const EditButton = ({
  type = TYPES.EDIT,
  path = "",
  onClick = undefined,
  ...otherButtonProps
}) => {
  const [ isLoading, setIsLoading ] = useState(false)
  const navigate = useNavigate()

  if (!onClick) {
    onClick = () => navigate(path)
  }

  const icon = ICONS_MAP[type]
  const title = LABELS_MAP[type]

  return (
    <Button
      icon={icon}
      size="small"
      shape="round"
      style={{ float: "right" }}
      loading={isLoading}
      onClick={async () => {
        setIsLoading(true)
        await onClick()
        setIsLoading(false)
      }}
      {...otherButtonProps}
    >
      {title}
    </Button>
  )
}

EditButton.propTypes = {
  type: PropTypes.string,
  path: PropTypes.string,
  onClick: PropTypes.func,
}

export default EditButton
