import { useMemo, useState } from "react"
import { useOutletContext, useParams, useNavigate } from "react-router-dom"

import { wait } from "@api"
import { FUND } from "@api/services/backstage/shapes/ProjectTypeEnum"
import { useAppContext } from "@components/AppContext"


const useInvestmentFormProps = ({
  investment = {},
  form,
  setIsLoading,
  openCreateTab,
  nextInvestmentId,
  isLastInvestment,
}) => {
  const [ shouldRenderCreateTab, setShouldRenderCreateTab ] = useState()

  const navigate = useNavigate()

  const { identity } = useAppContext()

  const {
    project,
    addInvestment,
    updateInvestment,
    getProjectInvestments
  } = useOutletContext()

  const { projectId } = useParams()

  const { tiers, type } = project
  const { organization } = identity

  const isFund = type === FUND

  const {
    isImmutable = false,
    id: investmentId = undefined,
  } = investment

  const isCreate = !investmentId

  const otherInvestments = useMemo(() =>
    getProjectInvestments(projectId)
      .filter(item => item.id !== investmentId)
  , [ projectId, investmentId, getProjectInvestments ])

  const onNext = () => {
    if (isLastInvestment) {
      return navigate(`/investments/${projectId}/sign`)
    }

    return navigate(`/investments/${projectId}/update/${nextInvestmentId}`)
  }

  const onSubmit = async (isSubmitAndCreate = false) => {
    setIsLoading(true)
    window.scrollTo({ top: 0 })

    setShouldRenderCreateTab(isSubmitAndCreate)
    await wait()

    if (isImmutable) {
      setIsLoading(false)
      if (isSubmitAndCreate) {
        return navigate(`/investments/${projectId}/create`)
      }

      return onNext()
    }

    const hasFormError = await form.validateFields()
      .then(() => false)
      .catch(() => true)

    if (hasFormError) {
      setIsLoading(false)

      return
    }

    form.submit()
  }

  const onAfterSubmit = async (submittedInvestment) => {
    if (isCreate) {
      addInvestment(submittedInvestment)

      /* td: doesn't take into account new profile, SDIRA should be disabled */
      form.resetFields()

    } else {
      updateInvestment(submittedInvestment)

    }

    if (!shouldRenderCreateTab) {
      return onNext()
    }

    openCreateTab()
    setIsLoading(false)
  }

  const onFailure = () =>
    setIsLoading(false)

  const formProps = {
    tiers,
    isFund,
    onFailure,
    organization,
    onAfterSubmit,
    otherInvestments,
  }

  if (isCreate) {
    formProps.projectId = projectId
  }

  return [ onSubmit, formProps ]
}

export default useInvestmentFormProps
