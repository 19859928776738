import React from "react"
import PropTypes from "prop-types"
import { List, Space, Typography, Button } from "antd"
import { PlusOutlined } from "@ant-design/icons" // DeleteOutlined

const LABEL_NO_FILE = "No File"


const ListItem = ({
  title,
  subtitle = <Typography.Text type="secondary">{LABEL_NO_FILE}</Typography.Text>,
  icon = <PlusOutlined />,
  onClick
}) => {
  const itemStyle = {
    paddingLeft: 4,
    paddingRight: 4
  }

  const style = {
    width: '100%',
    display: "flex",
    justifyContent: "space-between",
  }

  return (
    <List.Item style={itemStyle}>
      <Space style={style}>
        <Space size={0} direction="vertical">
          <Typography.Text>{title}</Typography.Text>
          {subtitle}
        </Space>

        <Button
          type="text"
          icon={icon}
          onClick={onClick}
        />
      </Space>
    </List.Item>
  )
}

ListItem.propTypes = {
  icon: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  subtitle: PropTypes.shape(),
}

export default ListItem
