import { useAppContext } from "@components/AppContext"
import { useOutletContext } from "react-router-dom"

import { sendInvestmentPendingRefundNotificationOperation as createNotificationOperation } from "@api/services/notifications"

const BACKUP = "BACKUP"
const ACCEPT = "ACCEPT"

const SKIP_ACTIONS = [ BACKUP, ACCEPT ]


const useSendNotifications = investment => {
  const { request } = useAppContext()
  const { project, activeAccountants } = useOutletContext()

  const { id: projectId } = project
  const { id: investmentId } = investment

  const { isFunded, hasSentFunds } = investment

  return async (actionName) => {
    const shouldSkip = SKIP_ACTIONS.includes(actionName)

    if (shouldSkip) {
      return
    }

    const hasNoRefund = !isFunded && !hasSentFunds

    if (hasNoRefund) {
      return
    }

    for (const user of activeAccountants) {
      const { email: toAddress } = user

      const mutation = {
        toAddress,
        projectId,
        investmentId
      }

      await request(createNotificationOperation, { mutation })
    }
  }
}

export default useSendNotifications
