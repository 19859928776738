import { TemplateCategoryEnumKeys } from "./shapes/TemplateCategoryEnum"
import { TemplateKeyEnumKeys } from "./shapes/TemplateKeyEnum"
import { TemplateTypeEnumKeys } from "./shapes/TemplateTypeEnum"

const ListTemplatesItemSchema = {
  key: { enum: TemplateKeyEnumKeys },
  type: { enum: TemplateTypeEnumKeys },
  category: { enum: TemplateCategoryEnumKeys },
  isDefault: { type: 'boolean' },
  isEnabled: { type: 'boolean' },
  isEditable: { type: 'boolean' },
  shouldCCOrganization: { type: 'boolean' },
  shouldCCPlatformSupport: { type: 'boolean' },
  sendAfterHoursCount: { type: 'number' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default ListTemplatesItemSchema
