import React from "react"

import { RedactorInput } from "../../Input"


const renderRedactor = (itemProps, inputProps, form, path) => {
  const componentProps = {
    form,
    path,
    itemProps,
    inputProps,
  }

  return (
    <RedactorInput {...componentProps} />
  )
}

export default renderRedactor
