import PropTypes from "prop-types"

import TierInvestmentShape from "./TierInvestmentShape"
import { ProfileTypeEnumKeys } from "./ProfileTypeEnum"

const properties = {
  investments: PropTypes.arrayOf(TierInvestmentShape).isRequired,
  type: PropTypes.oneOf(ProfileTypeEnumKeys).isRequired
}

const CommitmentProfileShape = PropTypes.exact(properties)

export default CommitmentProfileShape
export { properties }
