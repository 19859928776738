import React from "react"

import { PhoneInput } from "../../Input"


const renderPhone = (itemProps, inputProps, form, path) => {
  return (
    <PhoneInput
      form={form}
      path={path}
      itemProps={itemProps}
      inputProps={inputProps}
    />
  )
}

export default renderPhone
