import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

import { InvestmentShape } from "@components/Investment/shapes"
import { IndexBankAccountsOutputShape } from "@api/services/transactions"

import CustomerShape from "../../shapes/CustomerShape"
import BusinessCustomerUpgradeModal from "./BusinessCustomerUpgradeModal"


const BusinessCustomerUpgradeButton = ({
  title,
  customer,
  onSuccess = () => {},
  investment = undefined,
  bankAccount = undefined,
}) => {
  const modal = useRef({})

  const onClick = () =>
    modal.current.open()

  const { id: customerId } = customer

  const businessCustomerUpgradeModalProps = {
    modal,
    onSuccess,
    investment,
    customerId,
    bankAccount,
  }

  return (
    <>
      <Typography.Link onClick={onClick}>
        {title}
      </Typography.Link>

      <BusinessCustomerUpgradeModal
        {...businessCustomerUpgradeModalProps}
      />
    </>
  )
}

BusinessCustomerUpgradeButton.propTypes = {
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.shape() ]).isRequired,
  customer: CustomerShape.isRequired,
  onSuccess: PropTypes.func,
  investment: InvestmentShape,
  bankAccount: IndexBankAccountsOutputShape,
}

export default BusinessCustomerUpgradeButton
