import React from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

import { W0 } from "@components"


const Text = ({ text }) => {
  const labelStyle = {
    paddingTop: W0 / 2,
    paddingLeft: W0
  }

  return (
    <Typography.Paragraph style={labelStyle}>
      {text}
    </Typography.Paragraph>
  )
}

Text.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Text
