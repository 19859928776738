import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"

import { TYPE_AMOUNT } from "@components/Form"

const LABEL_STATUS = "Status"
const LABEL_EQUITY = "Equity Deployed"
const LABEL_PROPERTY = "Property"
const LABEL_STATUS_PLACEHOLDER = "Set status of the property"


const useInputs = (connectedPropertyIds) => {
  const { propertyOptions } = useOutletContext()

  const availablePropertyOptions = useMemo(() =>
    propertyOptions
      .filter(({ value: propertyId }) => !connectedPropertyIds.includes(propertyId))
  , [
    propertyOptions,
    connectedPropertyIds
  ])

  const inputs = [
    {
      name: "propertyId",
      label: LABEL_PROPERTY,
      options: availablePropertyOptions,
      placeholder: "Select property to add",
    },
    {
      name: "status",
      label: LABEL_STATUS,
      inputProps: { style: { width: 220 } },
      placeholder: LABEL_STATUS_PLACEHOLDER,
      initialValue: "ACTIVE",
    },
    {
      name: "investmentDate",
    },
    {
      name: "equityDeployedAmount",
      type: TYPE_AMOUNT,
      label: LABEL_EQUITY,
      inputProps: { style: { width: 130 }, controls: false },
      placeholder: ""
    },
  ]

  return inputs
}

export default useInputs
