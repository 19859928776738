import React from "react"
import PropTypes from "prop-types"
import { IdcardOutlined } from "@ant-design/icons"

import { ItemPropsShape, InputPropsShape } from "@api"

import FormShape from "../shapes/FormShape"
import MaskedInput from "./MaskedInput"

const MASK = "000-00-0000"
const MASK_PATTERN = "$1-$2-$3"
const MASKED_REGEX = /^\d{3}-\d{2}-\d{4}$/
const UNMASKED_REGEX = /^(\d{3})(\d{2})(\d{4})$/


const SsnInput = ({
  form,
  path,
  itemProps,
  inputProps
}) => {
  inputProps.prefix = <IdcardOutlined />

  return (
    <MaskedInput
      form={form}
      mask={MASK}
      fieldPath={path}
      itemProps={itemProps}
      inputProps={inputProps}
      maskedRegex={MASKED_REGEX}
      getMaskedValue={value => value.replace(UNMASKED_REGEX, MASK_PATTERN)}
    />
  )
}

SsnInput.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemProps: ItemPropsShape.isRequired,
  inputProps: InputPropsShape.isRequired,
}

export default SsnInput
