import { useEffect } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"


const IndexRedirect = ({ to }) => {
  const navigate = useNavigate()

  const { pathname: path } = window.location

  useEffect(() => {
    navigate(`${path}/${to}`.replace("//", "/"))
  })

  return null
}

IndexRedirect.propTypes = {
  to: PropTypes.string.isRequired,
}

export default IndexRedirect
