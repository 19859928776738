import { createContext, useContext } from 'react'

const ProjectDetailsContext = createContext({})
const useProjectDetailsContext = () => useContext(ProjectDetailsContext)

export {
  useProjectDetailsContext
}

export default ProjectDetailsContext
