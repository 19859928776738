import capitalize from "lodash.capitalize"

export const BACKSTAGE = "BACKSTAGE"
export const DWOLLA = "DWOLLA"
export const FUNDING = "FUNDING"
export const INVESTMENT = "INVESTMENT"
export const PROJECT_DOCUMENTS = "PROJECT_DOCUMENTS"
export const PROJECT_DROPOUTS = "PROJECT_DROPOUTS"

const TemplateCategoryEnum = {
  [BACKSTAGE]: BACKSTAGE,
  [DWOLLA]: DWOLLA,
  [FUNDING]: FUNDING,
  [INVESTMENT]: INVESTMENT,
  [PROJECT_DOCUMENTS]: PROJECT_DOCUMENTS,
  [PROJECT_DROPOUTS]: PROJECT_DROPOUTS
}

const TemplateCategoryEnumKeys = Object.keys(TemplateCategoryEnum)

const TemplateCategoryEnumOptions = TemplateCategoryEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  TemplateCategoryEnumKeys,
  TemplateCategoryEnumOptions
}

export default TemplateCategoryEnum
