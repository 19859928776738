import React from "react"
import PropTypes from "prop-types"
import { Tag, Tooltip } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

import { getConfig } from "@components/Config"

const PLATFORM_EMAIL = getConfig('PLATFORM_EMAIL')

const LABEL_FAILED = "Verification Failed"
const LABEL_TOOLTIP_VERIFICATION = `The number of invalid amount retries has been exceeded.
The bank account is blocked. Please contact ${PLATFORM_EMAIL} to resolve the issue.`
const LABEL_TOOLTIP_INVALID = 'Bank account failed verification due to incorrect details.'

const VerificationFailedStatus = ({
  bordered = false ,
  isInvalid = false,
}) => {
  const tooltipMessage = isInvalid
    ? LABEL_TOOLTIP_INVALID
    : LABEL_TOOLTIP_VERIFICATION

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
    >
      <Tag
        icon={<ExclamationCircleOutlined />}
        color="red"
        style={{ cursor: "pointer" }}
        bordered={bordered}
      >
        {LABEL_FAILED}
      </Tag>
    </Tooltip>
  )
}

VerificationFailedStatus.propTypes = {
  bordered: PropTypes.bool,
  isInvalid: PropTypes.bool,
}

export default VerificationFailedStatus
