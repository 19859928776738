import React from "react"

import {
  TYPE_EIN,
  TYPE_SSN,
  TYPE_DATE,
  TYPE_ENUM,
  TYPE_OBJECT,
  TYPE_STRING,
  SubmitButton,
} from "@components/Form"
import { validateDob } from "@components/Date"
import { MarkdownAlert } from "@components/Text"
import { getUsAddressSchema, getDwollaAddressSchema } from "@components/Address"

const LABEL_EIN = "Trust EIN"
const LABEL_DOB = "Date of Birth"
const LABEL_SSN = "Social Security Number"
const LABEL_SUBMIT = "Submit"
const LABEL_LAST_NAME = "Legal First Name"
const LABEL_FIRST_NAME = "Legal First Name"
const LABEL_CONTROLLER = "Controller"
const LABEL_ENTITY_NAME = "Trust Name"
const LABEL_CLICK_SELECT = "Click to select"
const LABEL_CLASSIFICATION = "Classification"
const LABEL_ENTITY_ADDRESS = "Physical Address"
const LABEL_CLASSIFICATION_GROUP = "Business Classification According to NAICS"
const LABEL_DESCLAIMER = `PO Box, shipping point, or mail receiving agent /
registered agent addresses are unable to be accepted for account verification
purposes.`


const getSolePropriatorshipCustomerInputs = (groupOptions, classificationOptions) => {
  const addressSchema = getUsAddressSchema()

  return [
    {
      name: "firstName",
      label: LABEL_FIRST_NAME,
      placeholder: "",
      required: true,
      maxLength: 50,
    },
    {
      name: "lastName",
      label: LABEL_LAST_NAME,
      placeholder: "",
      required: true,
      maxLength: 50,
    },
    {
      name: "dateOfBirth",
      type: TYPE_DATE,
      label: LABEL_DOB,
      required: true,
      itemProps: {
        rules: [validateDob(LABEL_CONTROLLER)]
      }
    },
    {
      name: "ssn",
      type: TYPE_SSN,
      label: LABEL_SSN,
      required: true,
    },
    {
      name: "businessName",
      type: TYPE_STRING,
      label: LABEL_ENTITY_NAME,
      required: true,
      maxLength: 255,
    },
    {
      name: "ein",
      type: TYPE_EIN,
      label: LABEL_EIN,
      required: false,
    },
    {
      name: "_disclaimer",
      component: () =>
        <MarkdownAlert
          type="warning"
          text={LABEL_DESCLAIMER}
        />
    },
    {
      name: "address",
      type: TYPE_OBJECT,
      label: LABEL_ENTITY_ADDRESS,
      schema: getDwollaAddressSchema(addressSchema),
      required: true,
    },
    {
      name: "classificationGroup",
      type: TYPE_ENUM,
      label: LABEL_CLASSIFICATION_GROUP,
      options: groupOptions,
      placeholder: LABEL_CLICK_SELECT,
      inputProps: {
        disabled: groupOptions.length === 0,
        onChange: (event, form) =>
          form.setFieldValue(["businessCustomer", "businessClassificationId"], null)
      },
      required: true,
    },
    {
      name: "businessClassificationId",
      type: TYPE_ENUM,
      label: LABEL_CLASSIFICATION,
      options: classificationOptions,
      placeholder: LABEL_CLICK_SELECT,
      inputProps: {
        disabled: classificationOptions.length === 0
      },
      required: true,
    },
    {
      name: "_submit",
      component: ({ form }) => {
        const isLoading = form.isSubmitting()

        return (
          <SubmitButton
            isLoading={isLoading}
          >
            {LABEL_SUBMIT}
          </SubmitButton>
        )
      }
    }
  ]
}

export default getSolePropriatorshipCustomerInputs
