import React from "react"
import PropTypes from "prop-types"
import { App, Space, Button } from "antd"
import { DeleteOutlined } from "@ant-design/icons"

import confirmAction from "@components/Page/helpers/confirmAction"
import { useAppContext } from "@components/AppContext"
import {
  ListMyCustomerBeneficialOwnersOutputShape,
  deleteMyCustomerBeneficialOwnerOperation
} from "@api/services/transactions"

import {
  BENEFICIAL_OWNER_STATUS_DOCUMENT,
  BENEFICIAL_OWNER_STATUS_INCOMPLETE,
} from "../../../../Constants"

const LABEL_EDIT = "Edit"
const LABEL_UPLOAD = "Upload"

const DELETE_ACTION = {
  type: "danger",
  title: "Remove Beneficial Owner",
  action: "Remove",
  content: 'Please confirm removal of the beneficial owner from the organization.',
}


const ActionButtons = ({
  onSuccess,
  customerId,
  beneficialOwner,
  openUpdateModal,
  openUploadModal,
}) => {
  const { modal } = App.useApp()
  const { authenticatedRequest } = useAppContext()

  const { beneficialOwnerUrl, verificationStatus } = beneficialOwner

  const isDocument = verificationStatus === BENEFICIAL_OWNER_STATUS_DOCUMENT
  const isIncomplete = verificationStatus === BENEFICIAL_OWNER_STATUS_INCOMPLETE

  const onDeleteConfirm = () =>
    confirmAction(
      modal,
      DELETE_ACTION.action,
      DELETE_ACTION.title,
      DELETE_ACTION.content,
      () => {
        return authenticatedRequest(deleteMyCustomerBeneficialOwnerOperation, { customerId, beneficialOwnerUrl })
          .then(() => onSuccess())
      },
      DELETE_ACTION.type
    )

  const renderActionButton = () => {
    const buttonProps = {
      type: "link"
    }

    if (isDocument) {
      return (
        <Button {...buttonProps} onClick={openUploadModal}>
          {LABEL_UPLOAD}
        </Button>
      )
    }

    if (isIncomplete) {
      return (
        <Button {...buttonProps} onClick={openUpdateModal}>
          {LABEL_EDIT}
        </Button>
      )
    }

    return null
  }

  return (
    <Space>
      {renderActionButton()}

      <Button
        icon={<DeleteOutlined />}
        type="text"
        onClick={onDeleteConfirm}
      />
    </Space>
  )
}

ActionButtons.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  openUpdateModal: PropTypes.func.isRequired,
  openUploadModal: PropTypes.func.isRequired,
  beneficialOwner: ListMyCustomerBeneficialOwnersOutputShape.isRequired,
}

export default ActionButtons
