import React from "react"
import { useOutletContext } from "react-router-dom"
import { App, Input, Space, Typography } from "antd"

import { ROLES } from "@components/Domain"
import { confirmAction } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import {
  enableUserOperation,
  disableUserOperation
} from "@api/services/backstage"

const { Text } = Typography
const { TextArea } = Input

const ENABLE = "ENABLE"
const DISABLE = "DISABLE"

const LABEL_MEMBER = "Staff"
const LABEL_BROKER = "Liaison"
const LABEL_ADMINISTRATOR = "Administrator"


let disableReason = ""

const onChange = ({ currentTarget }) => {
  disableReason = currentTarget.value
}


const useActions = (user) => {
  const { modal } = App.useApp()

  const { updateUser } = useOutletContext()

  const {
    request,
    showSuccessMessage,
    isConsoleOrganization
  } = useAppContext()

  const {
    id,
    roles,
    givenName,
    familyName,
  } = user

  const isBroker =
    roles.includes(ROLES.BROKER) &&
    roles.length === 1

  let entity = isBroker
    ? LABEL_BROKER
    : LABEL_MEMBER

  if (isConsoleOrganization) {
    entity = LABEL_ADMINISTRATOR
  }

  const fullName = `${givenName} ${familyName}`

  const ACTIONS_MAP = {
    [DISABLE]: {
      operation: disableUserOperation,
      type: "danger",
      title: `Disable ${entity}`,
      action: "Disable",
      success: `${entity} "$" is disabled`,
      content: (
        <Space
          direction="vertical"
          style={{ width: "100%"}}
        >
          <Text>{entity} becomes unauthorized to get access to the service.</Text>
          <TextArea
            rows={4}
            onChange={onChange}
            placeholder="Specify the reason..."
          />
        </Space>
      ),
    },
    [ENABLE]: {
      operation: enableUserOperation,
      type: "default",
      title: `Enable ${entity}`,
      action: "Enable",
      success: `${entity} "$" is enabled`,
      content: `${entity} becomes authorized to get access to the service.`,
    },
  }

  const action = actionName =>
    confirmAction(
      modal,
      ACTIONS_MAP[actionName].action,
      ACTIONS_MAP[actionName].title,
      ACTIONS_MAP[actionName].content,
      async () => {
        const message = ACTIONS_MAP[actionName].success.replace("$", fullName)
        const { operation } = ACTIONS_MAP[actionName]
        const parameters = { id }

        const isDisable = actionName === DISABLE

        if (isDisable) {
          parameters.mutation = { disableReason }
        }

        return request(operation, parameters)
          .then(({ data }) => updateUser(data))
          .then(() => disableReason = "")
          .then(() => {
            showSuccessMessage(message)
          })
      },
      ACTIONS_MAP[actionName].type,
    )

  const enableUser = () => action(ENABLE)
  const disableUser = () => action(DISABLE)

  return {
    enableUser,
    disableUser,
  }
}

export default useActions
