import { useAppContext } from "@components/AppContext"
import { getIsoDateFromDate } from "@components/Date"

import {
  deleteExternalFundingTransactionOperation as deleteOperation,
  registerExternalFundingTransactionOperation as createOperation,
} from "@api/services/transactions"


const useTransactionOperations = (collection, investmentId, readInvestment) => {
  const { request } = useAppContext()

  const deleteTransaction = ({ id }) =>
    request(deleteOperation, { id })
      .then(() => readInvestment(investmentId))

  const createTransaction = async () => {
    const items = collection.current.form.getFieldsValue()

    const [ key ] = Object.keys(items)
    const item = items[key]

    const {
      amount,
      description
    } = item

    const date = getIsoDateFromDate(new Date())

    const mutation = {
      date,
      amount,
      description,
      investmentId
    }

    return request(createOperation, { mutation })
      .then(() => readInvestment(investmentId))
  }

  return { deleteTransaction, createTransaction }
}

export default useTransactionOperations
