import React, { useState } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { UpdateModal } from "@components/Page"
import { MODAL_WIDTH } from "@components"
import {
  readPropertyOperation as readOperation,
  updatePropertyOperation as operation,
} from "@api/services/backstage"

import getInputs from "../ProjectPropertyCreateModal/helpers/getInputs"

const LABEL_ENTITY = "Property"
const LABEL_UPDATE = `Edit ${LABEL_ENTITY}`
const LABEL_SUBMIT = "Save"


const PropertyUpdateModal = ({ modal }) => {
  const [ schema, setSchema ] = useState([])
  const [ prevProjectId, setPrevProjectId ] = useState()

  const {
    projects,
    properties,
    getProject,
    getProperty,
    indexProjects,
    updateProperty,
  } = useOutletContext()

  modal.current.openProperty = propertyId => {
    const property = getProperty(propertyId)
    const { projectId } = property

    const project = getProject(projectId)
    setPrevProjectId(project?.id)

    const getUpdateInputs = getInputs({
      project,
      projects,
      properties,
    })

    const inputs = getUpdateInputs()
    const newSchema = operation.getSchema(inputs)
    setSchema(newSchema)

    modal.current.openItem(property)
  }

  const onSuccess = async (updatedProperty) => {
    const { projectId } = updatedProperty

    const hasProjectChanged = prevProjectId !== projectId

    if (hasProjectChanged) {
      await indexProjects()
    }

    updateProperty(updatedProperty)
  }

  const updateModalProps = {
    title: LABEL_UPDATE,
    width: MODAL_WIDTH * 1.25,
    entity: LABEL_ENTITY,
    submitLabel: LABEL_SUBMIT,
    modal,
    schema,
    operation,
    onSuccess,
    readOperation,
  }

  return (
    <UpdateModal {...updateModalProps} />
  )
}

PropertyUpdateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default PropertyUpdateModal
