import { FUND } from "@api/services/backstage/shapes/ProjectTypeEnum"


const ENTITY_NAME_KEY = "Entity Name"

const getEntityNames = (properties, getProperty, project) => {
  const { investmentSubjectIds = [], id: projectId, type } = project
  const isFund = type === FUND

  const targetProperties = isFund
    ? investmentSubjectIds.map(propertyId => getProperty(propertyId))
    : properties.filter(property => property.projectId === projectId)

  const propertiesTags = targetProperties
    .map(({ tags }) => tags)
    .reduce((a, b) => a.concat(b), [])

  const entityNames = propertiesTags
    .filter(({ label }) => label === ENTITY_NAME_KEY)
    .filter(({ value }) => !!value && value.length > 0)
    .map(({ value }) => value)

  const uniqueEntityNames = [
    ...new Set(entityNames)
  ]

  return uniqueEntityNames.join(", ")
}

export default getEntityNames
