import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import { UnderstandConfirmModal } from "@components/Page"

const LABEL_TITLE = "Add the bank details manually"
const LABEL_CONTENT = `You have selected to send in your investment funds by
manually adding your bank account details. This will require micro deposit
verification, which usually takes 2-3 business days. 
We recommend using Plaid to avoid this step and expedite the process.

After verification is complete, click the blue Send [AMOUNT] to initiate the transfer.

Your investment amount will be electronically transferred through 
ACH from your bank account. This means that the funds are pulled from your 
account within 1-2 business days and are received by your Sponsor within an 
additional 3-5 business days. The total time to transfer the funds should take 
4-7 business days in total (not including holidays and weekends).

If you send funds today, your funds will most likely arrive by [DATE].`

const ConnectBankAccountManuallyConfirmModal = ({ modal, amount }) => {
  const content = LABEL_CONTENT
    .replace('[AMOUNT]', amount)
    .replace('[DATE]', dayjs().add(9, 'day').format('MM/DD/YYYY'))

  return (
    <UnderstandConfirmModal
      modal={modal}
      content={content}
      title={LABEL_TITLE}
    />
  )
}

ConnectBankAccountManuallyConfirmModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  amount: PropTypes.string.isRequired,
}

export default ConnectBankAccountManuallyConfirmModal
