import PropTypes from "prop-types"


const TemplatesStoreShape = PropTypes.exact({
  getTemplates: PropTypes.func.isRequired,
  resetTemplate: PropTypes.func.isRequired,
  toggleTemplate: PropTypes.func.isRequired,
  indexTemplates: PropTypes.func.isRequired,
  getTemplateByKey: PropTypes.func.isRequired,
  updatedTemplateByKey: PropTypes.func.isRequired,
  isInvestmentFundedTemplateEnabled: PropTypes.func.isRequired,
  isInvestmentSignedTemplateEnabled: PropTypes.func.isRequired,
  isProjectFileCreatedTemplateEnabled: PropTypes.func.isRequired,
  isInvestorFileCreatedTemplateEnabled: PropTypes.func.isRequired,
  isProjectClosedNonFundTemplateEnabled: PropTypes.func.isRequired,
  isInvestmentRejectedNotInterestedTemplateEnabled: PropTypes.func.isRequired,
  isInvestmentRejectedDeadlinePassedTemplateEnabled: PropTypes.func.isRequired,
  isInvestmentRejectedNoAccreditationTemplateEnabled: PropTypes.func.isRequired,
})

export default TemplatesStoreShape
