import React from "react"
import { NavLink } from "react-router-dom"
import { EditOutlined, StopOutlined, UndoOutlined } from "@ant-design/icons"

const LABEL_EDIT = "Edit Details"
const LABEL_ENABLE = "Enable"
const LABEL_DISABLE = "Disable"


const useRowMenu = (user, { openUpdateModal, enableUser, disableUser }) => {
  const { id, isDisabled } = user

  const items = [
    {
      key: "update",
      icon: <EditOutlined />,
      label: (
        <NavLink onClick={() => openUpdateModal(id)}>
          {LABEL_EDIT}
        </NavLink>
      )
    },
    {
      type: "divider"
    },
  ]

  if (isDisabled) {
    items.push({
      key: "enable",
      icon: <UndoOutlined />,
      label: (
        <NavLink onClick={() => enableUser()}>
          {LABEL_ENABLE}
        </NavLink>
      )
    })

  } else {
    items.push({
      key: "disable",
      icon: <StopOutlined />,
      danger: true,
      label: (
        <NavLink onClick={() => disableUser()}>
          {LABEL_DISABLE}
        </NavLink>
      )
    })
  }

  return items
}

export default useRowMenu
