import React, { useRef } from "react"
import { Layout, Divider, theme } from "antd"

import { SIDEBAR_WIDTH } from "@components"
import { OrganizationsModal } from "@components/Organization"
import { GoToInvestmentModal } from "@components/Investment"

import SidebarMenu from "./SidebarMenu"
import SidebarDropdown, { useMenuItems } from "./SidebarDropdown"

const { Sider } = Layout


const Sidebar = ({ ...sidebarMenuProps }) => {
  const organizationsModal = useRef({})
  const goToInvestmentModal = useRef({})

  const openOrganizationsModal = () =>
    organizationsModal.current.open()

  const openGoToInvestmentModal = () =>
    goToInvestmentModal.current.open()

  const { token } = theme.useToken()

  const {
    colorBgContainer,
    colorBorderSecondary,
  } = token

  const items = useMenuItems({
    openOrganizationsModal,
    openGoToInvestmentModal
  })

  const style = {
    top: 0,
    left: 0,
    bottom: 0,
    overflow: 'auto',
    position: 'fixed',
    background: colorBgContainer,
    borderRight: `1px solid ${colorBorderSecondary}`,
  }

  return (
    <Sider
      width={SIDEBAR_WIDTH}
      style={style}
    >
      <SidebarDropdown items={items} />

      <Divider style={{ margin: 0 }} />

      <SidebarMenu {...sidebarMenuProps} />

      <OrganizationsModal
        modal={organizationsModal}
      />

      <GoToInvestmentModal
        modal={goToInvestmentModal}
      />
    </Sider>
  )
}

export default Sidebar
