
const parseCsv = (data, parse, validate, build) => {
  const columsKeys = data[0]
  data.splice(0, 1)

  const rows = data.map(row => {
    const rowMap = {}

    row.forEach((item, index) => {
      rowMap[columsKeys[index]] = item
    })

    return rowMap
  })

  const items = []

  rows.forEach((row, rowIndex) => {
    const parsedRow = parse(row, rowIndex)

    if (!parsedRow) {
      return
    }

    const isValid = validate(parsedRow, rowIndex)

    if (!isValid) {
      return
    }

    const item = build(parsedRow, rowIndex)

    items.push(item)
  })

  return items
}

export default parseCsv
