import React from "react"
import PropTypes from "prop-types"
import { Form, Table } from "antd"

import CollectionRow from "./CollectionRow"
import CollectionCell from "./CollectionCell"

import "./Collection.css"

export const EditableContext = React.createContext(null)


const Collection = ({
  items = [],
  onSave = () => {},
  summary = () => {},
  onChange = () => {},
  tableProps = {},
  collection = undefined,
  columnsConfig = {},
  selectedRowItems = [],
  enableSelectItem = true,
}) => {
  const [ form ] = Form.useForm()

  if (collection) {
    collection.current.form = form

    collection.current.hasFormError = async () =>
      form.validateFields()
        .then(() => true)
        .catch(() => false)
  }

  const isEditing = record => {
    if (!enableSelectItem) {
      return true
    }

    return selectedRowItems.includes(record.key)
  }

  const components = {
    body: {
      row: CollectionRow,
      cell: CollectionCell,
    }
  }

  const rowSelection = {
    selectedRowKeys: selectedRowItems,
    onChange: (selectedRowKeys, selectedRows) => onChange(selectedRowKeys, selectedRows),
    getCheckboxProps: record => {
      if (record.disabled) {
        return {
          disabled: true
        }
      }
    }
  }

  const getIndexRow = (record) => {
    return items.findIndex(item => item.id ? item.id === record.id : item.key === record.key)
  }

  const columns = columnsConfig.map(col => {
    if (!col.editable) {
      return col
    }

    const { isShow = () => true } = col

    return {
      ...col,
      onCell: record => ({
        title: col.title,
        input: col.input || {},
        editing: isEditing(record),
        editable: col.editable,
        indexRow: getIndexRow(record),
        dataIndex: col.dataIndex,
        isShow,
        record,
        onSave
      })
    }
  })

  const defaultTableProps = () => {
    const params = {
      summary,
      columns,
      components,
      size: "middle",
      bordered: false,
      pagination: false,
      dataSource: [ ...items ],
    }

    if (!enableSelectItem) {
      return params
    }

    return {
      ...params,
      rowSelection: {
        ...rowSelection,
        checkStrictly: false
      }
    }
  }

  return (
    <Form
      form={form}
      component={false}
    >
      <EditableContext.Provider
        value={form}
      >
        <Table
          className="editable-collection"
          {...defaultTableProps()}
          {...tableProps}
        />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableColumnConfigShape = PropTypes.exact({
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.func ]),
  input: PropTypes.shape(),
  align: PropTypes.string,
  isShow: PropTypes.func,
  render: PropTypes.func,
  ellipsis: PropTypes.bool,
  editable: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
})

Collection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  onSave: PropTypes.func,
  summary: PropTypes.func,
  onChange: PropTypes.func,
  tableProps: PropTypes.shape(),
  collection: PropTypes.shape(),
  columnsConfig: PropTypes.arrayOf(EditableColumnConfigShape),
  selectedRowItems: PropTypes.arrayOf(PropTypes.string),
  enableSelectItem: PropTypes.bool,
}

export default Collection
