import PropTypes from "prop-types"


const properties = {
  id: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  isMicroDepositsRequested: PropTypes.bool,
  isMicroDepositsSubmitted: PropTypes.bool,
  isMicroDepositsVerifiable: PropTypes.bool,
  isVerificationFailed: PropTypes.bool,
  isVerified: PropTypes.bool,
  name: PropTypes.string.isRequired
}

const CustomerSourceShape = PropTypes.exact(properties)

export default CustomerSourceShape
export { properties }
