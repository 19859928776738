import React from "react"
import { Descriptions, Space, Tooltip } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons"

import { Value } from "@components/Text"
import { breakRow } from "@components/Investment"
import { getFormattedTimezone } from "@components/Date"
import { TwoFactorAuthenticationSwitch, UpdateIdentityEmailLink } from "@components/Identity"

const { Item } = Descriptions

const LABEL_NO = "No"
const LABEL_YES = "Yes"
const LABEL_DOB = "Date of Birth"
const LABEL_2FA = "Two Factor Authentication"
const LABEL_EMAIL = "Email"
const LABEL_ADDRESS = "Mailing Address"
const LABEL_TIMEZONE = "Time Zone"
const LABEL_LEGAL_NAME = "Legal Full Name"
const LABEL_PHONE_NUMBER = "Phone Number"
const LABEL_ACCREDITATION = "Investor Accreditation"
const LABEL_PREFERRED_NAME = "Preferred Name"
const LABEL_IMMUTABLE_TEXT = `If you've changed your legal name, reach out to
us so we can update it for you.`


const getItems = (account, shouldShow2FASwitch, authorizeIdentity) => {
  const { investor, is2FaEnabled, isImmutable } = account

  const {
    email,
    address,
    givenName,
    familyName,
    dateOfBirth,
    mobileNumber,
    timeZoneCode,
    alternativeName,
    additionalName,
    hasAccreditation,
  } = investor

  const legalFullName = [
    givenName,
    additionalName,
    familyName
  ].join(" ")

  const items = [
    {
      label: (
        <Space>
          {LABEL_LEGAL_NAME}
          {
            isImmutable && (
              <Tooltip title={LABEL_IMMUTABLE_TEXT}>
                <InfoCircleOutlined />
              </Tooltip>
            )
          }
        </Space>
      ),
      value: legalFullName,
    },
    {
      label: LABEL_PREFERRED_NAME,
      value: alternativeName || givenName,
    },
    {
      span: 2,
      label: LABEL_EMAIL,
      value: <UpdateIdentityEmailLink title={email} />,
    },
    {
      label: LABEL_PHONE_NUMBER,
      value: <Value value={mobileNumber} />,
    },
  ]

  if (shouldShow2FASwitch && mobileNumber) {
    items.push({
      label: LABEL_2FA,
      value: (
        <TwoFactorAuthenticationSwitch
          mobile={mobileNumber}
          isEnabled={is2FaEnabled}
          onSuccess={authorizeIdentity}
        />
      )
    })
  }

  breakRow(items)

  items.push(
    {
      label: LABEL_ADDRESS,
      value: <Value value={address} />,
    },
    {
      label: LABEL_DOB,
      value: <Value value={dateOfBirth} />,
    },
    {
      span: 2,
      label: LABEL_TIMEZONE,
      value: getFormattedTimezone(timeZoneCode),
    },
    {
      span: 2,
      label: LABEL_ACCREDITATION,
      value: hasAccreditation
        ? LABEL_YES
        : LABEL_NO,
    }
  )

  return items.map(({ value, ...itemProps }, key) =>
    <Item key={key} {...itemProps}>
      {value}
    </Item>
  )
}

export default getItems
