import { useOutletContext } from "react-router-dom"

import { PENDING } from "@api/services/transactions/shapes/TransactionStatusEnum"
import { FUNDING } from "@api/services/transactions/shapes/TransactionTypeEnum"
import { useAppContext } from "@components/AppContext"
import { INSUFFICIENT_FUNDS_DWOLLA_ERROR_CODES } from "@components/Dwolla"


const useRecentFundingTransaction = (investmentId) => {
  const { isInvalidSource } = useAppContext()
  const { getInvestmentTransactions } = useOutletContext()

  const investmentTransactions = getInvestmentTransactions(investmentId)

  const [ transaction ] = investmentTransactions
    .filter(t => t.type === FUNDING)

  if (!transaction) {
    return {
      hasPendingFundingTransaction: false,
      isInvalidInvestorBankAccount: false,
      isInsufficientFundsInvestorBankAccount: false
    }
  }

  const {
    statusReason: statusReasonJson,
    status,
    senderSourceId,
  } = transaction

  const hasPendingFundingTransaction = status === PENDING
  const isInvalidInvestorBankAccount = isInvalidSource(senderSourceId)

  if (!statusReasonJson) {
    return {
      hasPendingFundingTransaction,
      isInvalidInvestorBankAccount,
      isInsufficientFundsInvestorBankAccount: false
    }
  }

  const { code } = JSON.parse(statusReasonJson)
  const isInsufficientFundsInvestorBankAccount = INSUFFICIENT_FUNDS_DWOLLA_ERROR_CODES.includes(code)

  return {
    hasPendingFundingTransaction,
    isInvalidInvestorBankAccount,
    isInsufficientFundsInvestorBankAccount
  }
}

export default useRecentFundingTransaction
