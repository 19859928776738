import capitalize from "lodash.capitalize"

export const DRAFT = "DRAFT"
export const HIDDEN = "HIDDEN"
export const PUBLISHED = "PUBLISHED"

const ProjectStatusEnum = {
  [DRAFT]: DRAFT,
  [HIDDEN]: HIDDEN,
  [PUBLISHED]: PUBLISHED
}

const ProjectStatusEnumKeys = Object.keys(ProjectStatusEnum)

const ProjectStatusEnumOptions = ProjectStatusEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  ProjectStatusEnumKeys,
  ProjectStatusEnumOptions
}

export default ProjectStatusEnum
