import React from "react"
import { Space, Tooltip, Typography } from "antd"
import { QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"
import { Value } from "@components/Text"
import {
  TRANSACTION_TYPES,
  TRANSACTION_STATUS,
  TRANSACTION_PROBLEM_STATUSES,
  TRANSACTION_TYPE_ABBREVIATIONS_MAP
} from "@components/Domain"

import { parseStatusReason } from "../../TransactionDrawer"


const getAmountColumnProps = ({
  type,
  organizationName,
  openTransactionDrawer,
  isInvalidBankAccountTransaction,
}) => {
  const canUpdateTransaction = hasAccess(['transactions-write'])

  const key = type

  const { title: columnTitle, description } = TRANSACTION_TYPE_ABBREVIATIONS_MAP[type]

  const title = (
    <Space>
      <span>{columnTitle}</span>
      <Tooltip
        placement="top"
        title={description}
      >
        <QuestionCircleOutlined />
      </Tooltip>
    </Space>
  )

  const render = (_, distribution) => {
    const transaction = distribution[type]

    const shouldRenderZero = !transaction

    if (shouldRenderZero) {
      return (
        <Typography.Text type="secondary">
          0
        </Typography.Text>
      )
    }

    const {
      amount,
      status,
      statusReason,
    } = transaction

    const isInvalidBankAccount = isInvalidBankAccountTransaction(transaction)

    if (!canUpdateTransaction) {
      return (
        <Value value={amount} />
      )
    }

    const isRejected = status === TRANSACTION_STATUS.REJECTED

    const isFundingTransaction = type === TRANSACTION_TYPES.FUNDING

    const hasProblem = TRANSACTION_PROBLEM_STATUSES.includes(status)

    const onClick = () => openTransactionDrawer(transaction)

    const tooltipText = parseStatusReason({
      isTooltip: true,
      statusReason,
      organizationName,
      isFundingTransaction,
      isInvalidBankAccount,
    })

    return (
      <Space>
        {
          hasProblem && (
            <Tooltip
              placement="top"
              title={tooltipText}
            >
              <WarningOutlined style={{ fontSize: "16px", color: "red" }} />
            </Tooltip>
          )
        }

        <Typography.Text delete={isRejected} strong>
          <Value
            value={amount}
            onClick={onClick}
          />
        </Typography.Text>
      </Space>
    )
  }

  const compute = distribution => {
    const transaction = distribution[type]
    const shouldRenderZero = !transaction

    if (shouldRenderZero) {
      return 0
    }

    const { amount } = transaction

    return amount
  }

  return {
    key,
    title,
    compute,
    render,
  }
}

export default getAmountColumnProps
