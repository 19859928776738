import PropTypes from "prop-types"


const properties = {
  campaignId: PropTypes.string.isRequired,
  clickedAt: PropTypes.string,
  clickedCount: PropTypes.number,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  deliveredAt: PropTypes.string,
  id: PropTypes.string.isRequired,
  investorAccountId: PropTypes.string.isRequired,
  isComplaint: PropTypes.bool,
  isHardBounce: PropTypes.bool,
  isSoftBounce: PropTypes.bool,
  openedAt: PropTypes.string,
  openedCount: PropTypes.number,
  pinpointCampaignId: PropTypes.string.isRequired,
  recipientEmail: PropTypes.string.isRequired,
  sentAt: PropTypes.string,
  unsubscribedAt: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const IndexRecipientReportsOutputShapeShape = PropTypes.exact(properties)

export default IndexRecipientReportsOutputShapeShape
export { properties }
