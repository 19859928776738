import React, { useRef } from "react"
import PropTypes from "prop-types"

import { ListMyCustomerBeneficialOwnersOutputShape } from "@api/services/transactions"

import UploadDocumentsModal from "../UploadDocumentsModal"
import BeneficialOwnersList from "./BeneficialOwnersList"
import AddBeneficialOwnerButton from "./AddBeneficialOwnerButton"
import BeneficialOwnerUpdateModal from "../BeneficialOwnerUpdateModal"


const BeneficialOwnersInput = ({
  beneficialOwners = undefined,
  onSuccess,
  customerId,
}) => {
  const updateModal = useRef({})
  const uploadModal = useRef({})

  const isLoading = !beneficialOwners

  const openUpdateModal = beneficialOwner =>
    updateModal.current.open(beneficialOwner)

  const openUploadModal = beneficialOwner =>
    uploadModal.current.open(beneficialOwner)

  const cantAddMoreOwners = !!beneficialOwners && beneficialOwners.length >= 4

  return (
    <>
      <BeneficialOwnersList
        isLoading={isLoading}
        onSuccess={onSuccess}
        customerId={customerId}
        openUpdateModal={openUpdateModal}
        openUploadModal={openUploadModal}
        beneficialOwners={beneficialOwners}
      />

      <AddBeneficialOwnerButton
        onSuccess={onSuccess}
        customerId={customerId}
        isDisabled={cantAddMoreOwners}
      />

      <BeneficialOwnerUpdateModal
        modal={updateModal}
        onSuccess={onSuccess}
        customerId={customerId}
      />

      <UploadDocumentsModal
        modal={uploadModal}
        onSuccess={onSuccess}
        customerId={customerId}
      />
    </>
  )
}

BeneficialOwnersInput.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  beneficialOwners: PropTypes.arrayOf(ListMyCustomerBeneficialOwnersOutputShape),
}

export default BeneficialOwnersInput
