import getAmountByInvestmentParams from "./getAmountByInvestmentParams"


const getSponsorsData = (partners, getSponsorName, investmentsMapBySponsorId, organizationName, fundsTarget) => {
  const sponsorPartners = partners.filter(({ sponsorId }) => !!sponsorId)

  const getInvestmentsBySponsor = id => investmentsMapBySponsorId[id] || []

  const data = sponsorPartners
    .map(({ sponsorId, targetAmount }) => {
      let title = getSponsorName(sponsorId)

      if (!title) {
        // eslint-disable-next-line no-console
        console.warn(`Sponsor for ID ${sponsorId} not found`)
        title = "Undefined"
      }

      if (title === organizationName) {
        targetAmount += fundsTarget
      }

      const investments = getInvestmentsBySponsor(sponsorId)
      const { amounts, percentages, counts } = getAmountByInvestmentParams(investments, targetAmount)

      return {
        title,
        target: targetAmount,
        amounts,
        percentages,
        counts,
        rangePercentage: 150,
        targetPercentage: 100,
        investments
      }
    })

  return data
}

export default getSponsorsData
