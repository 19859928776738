import { useState, useEffect, useCallback } from "react"

import { runRequest } from "@components/AppContext"


const useItemsStore = (request, table, tableProps) => {
  const [ items, setItems ] = useState()
  const [ isLoading, setIsLoading ] = useState(false)

  const {
    customItems = [],
    sortItems,
    indexOperation,
    customIndexItems,
    operationParameters,
    filterItemsCallback,
  } = tableProps

  const isCustomItemsStore = !!customIndexItems

  const indexItems = useCallback(
    (showSpinner = false) => {
      if (showSpinner) {
        setIsLoading(true)
      }

      const indexRequestPromise = customIndexItems
        ? customIndexItems()
        : request(indexOperation, operationParameters)
            .then(({ data: response = [] }) => {
              const filteredItems = filterItemsCallback
                ? filterItemsCallback(response)
                : response

              const sortedItems = sortItems(filteredItems)

              setItems(sortedItems)
            })

      return indexRequestPromise.then(() => setIsLoading(false))
    }
  , [
    request,
    sortItems,
    indexOperation,
    customIndexItems,
    operationParameters,
    filterItemsCallback,
  ])

  useEffect(() => {
    if (isCustomItemsStore) {
      return
    }

    return runRequest(() => indexItems(true))
  }, [
    indexItems,
    isCustomItemsStore,
  ])

  table.current.indexItems = showSpinner =>
    indexItems(showSpinner)

  if (isCustomItemsStore) {
    return [ customItems, isLoading, indexItems ]
  }

  table.current.getItem = id => items.find(item => item.id === id)

  table.current.getItems = () => items

  table.current.addItem = item => {
    const updatedItems = [item, ...items]
    setItems(updatedItems)
  }

  table.current.updateItem = (id, attributes = {}) => {
    const itemIndex = items.findIndex(item => item.id === id)
    items[itemIndex] = { ...items[itemIndex], ...attributes }

    setItems([...items])
  }

  table.current.deleteItem = id => {
    const itemIndex = items.findIndex(item => item.id === id)
    items.splice(itemIndex, 1)

    setItems([...items])
  }

  return [ items || [], isLoading, indexItems ]
}

export default useItemsStore
