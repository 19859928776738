import React, { useState } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { FUND } from "@api/services/backstage/shapes/ProjectTypeEnum"
import { Drawer } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import {
  updateInvestmentOperation as updateOperation,
  ReadAccountOutputShape,
  indexAccountInvestmentsOperation,
} from "@api/services/investments"

import InvestmentForm from "../../../InvestmentForm"
import { InvestmentShape } from "../../../shapes"

const LABEL_SAVE = "Save"
const LABEL_TITLE = "Edit Investment"


const InvestmentProfileUpdateDrawer = ({
  drawer,
  account,
  investment,
  updateInvestment
}) => {
  const [ block, setBlock ] = useState()
  const [ otherInvestments, setOtherInvestments ] = useState([])

  const { request } = useAppContext()
  const { getProject, organization } = useOutletContext()

  const status = 'ACTIVE'

  const { profiles: existingProfiles } = account
  const { projectId, investorAccountId } = investment

  const onOpen = () =>
    request(indexAccountInvestmentsOperation, { projectId, investorAccountId, status })
      .then(({ data }) => setOtherInvestments(data))

  const project = getProject(projectId)

  const { tiers, type } = project
  const isFund = type === FUND

  const isTypeSelectDisabled = false

  const onAfterSubmit = updatedInvestment => {
    updateInvestment(updatedInvestment)
    drawer.current.close()
  }

  const investmentFormProps = {
    submitLabel: LABEL_SAVE,
    tiers,
    block,
    isFund,
    setBlock,
    investment,
    organization,
    onAfterSubmit,
    updateOperation,
    existingProfiles,
    otherInvestments,
    isTypeSelectDisabled,
  }

  return (
    <Drawer
      title={LABEL_TITLE}
      drawer={drawer}
      onOpen={onOpen}
    >
      <InvestmentForm
        {...investmentFormProps}
      />
    </Drawer>
  )
}

InvestmentProfileUpdateDrawer.propTypes = {
  drawer: PropTypes.shape().isRequired,
  account: ReadAccountOutputShape.isRequired,
  investment: InvestmentShape.isRequired,
  updateInvestment: PropTypes.func.isRequired,
}

export default InvestmentProfileUpdateDrawer
