import React from "react"
import PropTypes from "prop-types"
import { Button } from "antd"


const Arrow = ({
  onClick = undefined,
  className = undefined
}) => {
  return (
    <Button onClick={onClick} className={className}/>
  )
}

Arrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default Arrow
