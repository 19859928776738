import PropTypes from "prop-types"

import UserOrganizationShape from "@api/services/backstage/shapes/UserOrganizationShape"
import InvestorOrganizationShape from "@api/services/investments/shapes/OrganizationShape"
import { ReadMyOrganizationOutputShape } from "@api/services/organizations"


const OrganizationShape = PropTypes.oneOfType([
  UserOrganizationShape,
  InvestorOrganizationShape,
  ReadMyOrganizationOutputShape,
])

export default OrganizationShape
