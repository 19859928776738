import React from "react"
import { Row, Col } from "antd"
import PropTypes from "prop-types"

import { W2 } from "@components"
import { hasAccess } from "@components/Authorization"
import { PROJECT_TYPES } from "@components/Domain"

import Videos from "./Videos"
import Documents from "./Documents"
import ProjectShape from "../shapes/ProjectShape"
import ProjectSettings from "./ProjectSettings"
import ProjectKeyDates from "./ProjectKeyDates"
import ProjectFundSummary from "./ProjectFundSummary"


const Sidebar = ({ project, isEditable }) => {
  const { documents = [], videos = [], type } = project
  const canViewSettings = hasAccess([ 'projects-read' ])

  const isFund = type === PROJECT_TYPES.FUND

  const summary = isFund
    ? <ProjectFundSummary project={project} isEditable={isEditable} />
    : <ProjectKeyDates project={project} isEditable={isEditable} />

  const hasDocuments = isEditable || documents.length > 0
  const hasVideos = isEditable || videos.length > 0

  return (
    <Row gutter={[ W2, W2 ]}>
      {
        canViewSettings && (
          <Col span={24}>
            <ProjectSettings
              project={project}
            />
          </Col>
        )
      }

      <Col span={24}>
        {summary}
      </Col>

      {
        hasDocuments && (
          <Col span={24}>
            <Documents
              documents={documents}
              isEditable={isEditable}
            />
          </Col>
        )
      }

      {
        hasVideos && (
          <Col span={24}>
            <Videos
              videos={videos}
              isEditable={isEditable}
            />
          </Col>
        )
      }
    </Row>
  )
}

Sidebar.propTypes = {
  project: ProjectShape.isRequired,
  isEditable: PropTypes.bool.isRequired,
}

export default Sidebar
