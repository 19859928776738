import { requestBatch } from "@api"
import { useAppContext } from "@components/AppContext"
import { registerExternalFundingTransactionOperation as createOperation } from "@api/services/transactions"

const MESSAGE_SUCCESS = "Transactions are imported"


const useOnSubmitTransactions = (collectionRef, setIsSubmitting, setProgress, onSuccess, onClose) => {
  const { showSuccessMessage, request } = useAppContext()

  return async () => {
    const hasFormError = await collectionRef.current.hasFormError()
    if (!hasFormError) {
      return
    }

    const transactionImportRows = collectionRef.current.getTransactions()
    const activeTransactions = transactionImportRows.filter(({amount}) => amount > 0)

    const transactionCreateMutations = activeTransactions.map(({date, amount, description, investmentId}) => ({
      parameters: {
        mutation: {
          date,
          amount,
          description,
          investmentId
        }
      }
    }))

    setIsSubmitting(true)
    const newTransactions = await requestBatch(setProgress, transactionCreateMutations, parameters => request(createOperation, parameters))
    await onSuccess(newTransactions)

    setIsSubmitting(false)

    showSuccessMessage(MESSAGE_SUCCESS)
    collectionRef.current.setNewTransactions([])
    onClose()
  }
}

export default useOnSubmitTransactions
