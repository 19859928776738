import PropTypes from "prop-types"


const properties = {
  authenticationJwt: PropTypes.string,
  email: PropTypes.string.isRequired,
  id: PropTypes.string,
  is2FaEnabled: PropTypes.bool
}

const AuthenticateOutputShapeShape = PropTypes.exact(properties)

export default AuthenticateOutputShapeShape
export { properties }
