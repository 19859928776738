import React from "react"
import { Link } from "react-router-dom"
import { Typography } from "antd"

import { Value } from "@components/Text"
import { getAmountWithoutCents } from "@components/Amount"

const COLUMNS_LABEL_MAP = {
  closeDate: "Project Funding Date",
  capitalInvested: "Capital Invested",
  receiveDistribution: "Received Distributions to Date",
}


const getTableColumns = () => {
  return [
    {
      key: "name",
      dataIndex: "name",
      title: "Investment",
      width: "25%",
      render: (name, { id }) =>
        <Typography.Text strong>
          <Link to={`/projects/${id}/about`}>
            {name}
          </Link>
        </Typography.Text>
    },
    {
      key: "capitalInvested",
      dataIndex: "capitalInvested",
      title: COLUMNS_LABEL_MAP.capitalInvested,
      width: "25%",
      render: value =>
        <Value value={getAmountWithoutCents(`${value}`)} />,
    },
    {
      key: "receiveDistribution",
      dataIndex: "receiveDistribution",
      title: COLUMNS_LABEL_MAP.receiveDistribution,
      width: "25%",
      render: value =>
        <Value value={getAmountWithoutCents(`${value}`)} />,
    },
    {
      key: "closeDate",
      dataIndex: "closeDate",
      title: COLUMNS_LABEL_MAP.closeDate,
      width: "25%",
      render: closeDate =>
        <Value value={closeDate} />,
    },
  ]
}

export default getTableColumns
