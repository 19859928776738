import { useAppContext } from "@components/AppContext"

import getConfig, { getEnvironment, PRD_ENV } from "./getConfig"

const PRODUCTION_TEST_ORGS = [
  'ORG', // Production / Fundamental Test
  'O6E' // Production / Rise48 Equity
]


const useConfig = (name) => {
  const { identity } = useAppContext()
  const { organization } = identity

  const isProduction = getEnvironment() === PRD_ENV
  const isTestOrganization = PRODUCTION_TEST_ORGS.includes(organization.id)

  const shouldOverride = isProduction && isTestOrganization
  const value = getConfig(name)

  if (!shouldOverride) {
    return value
  }

  return true
}

export default useConfig
