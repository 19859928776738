import { useState } from "react"

const DEFAULT_COLUMN_WIDTH = 36


const useSelectable = (isSelectable, table, onSelectionChange, getCheckboxProps) => {
  const [ selectedRowKeys, setSelectedRowKeys ] = useState([])

  if (!isSelectable) {
    return {}
  }

  const onSelect = ({ id }, isSelected) => {
    let items = selectedRowKeys

    if (isSelected) {
      items = [...items, id]
    } else {
      items = items.filter(item => item !== id)
    }

    setSelectedRowKeys(items)
    onSelectionChange(items)
  }

  const onSelectAll = (isSelected, _, changeRows) => {
    let items = selectedRowKeys
    const selectedItemIds = changeRows.map(({ id }) => id)

    if (isSelected) {
      items = [...items, ...selectedItemIds]
    } else {
      items = items.filter(item => !selectedItemIds.includes(item))
    }

    setSelectedRowKeys(items)
    onSelectionChange(items)
  }

  const rowSelection = {
    fixed: true,
    columnWidth: DEFAULT_COLUMN_WIDTH,
    selectedRowKeys,
    getCheckboxProps,
    onSelectAll,
    onSelect,
  }

  table.current.getSelectedRowKeys = () => selectedRowKeys
  table.current.setSelectedRowKeys = keys => setSelectedRowKeys(keys)

  return { rowSelection }
}

export default useSelectable
