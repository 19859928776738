
const DATETIME_REGEX = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2}))$/

// note: https://stackoverflow.com/questions/52869695/check-if-a-date-string-is-in-iso-and-utc-format
const isIsoDateTime = str => {
  if (!DATETIME_REGEX.test(str)) {
    return false
  }

  const d = new Date(str)

  // eslint-disable-next-line no-restricted-globals
  return d instanceof Date && !isNaN(d) // valid date // && d.toISOString()===str
}

export default isIsoDateTime
