import React, { useState } from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"

import { getInputs } from "@components/Import"
import { CreateModal } from "@components/Page"
import { useForm, useWatch } from "@components/Form"
import { useRequest as useEffect } from "@components/AppContext"
import { importInvestmentsOperation as operation } from "@api/services/investments"

const LABEL_TITLE = "Import Investments"
const LABEL_ENTITY = "Investments"
const LABEL_SUBMIT = "Import"

const TEMPLATE_URL = "/static/templates/INVESTMENTS_IMPORT.xlsx"


const InvestmentsImportModal = ({ modal, indexInvestments }) => {
  const form = useForm()

  const { id: projectId } = useParams()

  const [ errorsJson, setErrorsJson ] = useState()
  const [ isDisabled, setIsDisabled ] = useState(true)
  const [ isValidating, setIsValidating ] = useState(false)

  const importFileUrl = useWatch('importFileUrl', form)

  const updateErrorsJson = importErrorsJson => {
    setErrorsJson(importErrorsJson)
    setIsValidating(false)

    if (!importErrorsJson) {
      setIsDisabled(false)
    }
  }

  useEffect(request => {
    if (!importFileUrl) {
      setIsDisabled(true)
      setErrorsJson()
      return
    }

    setErrorsJson()
    setIsValidating(true)

    const mutation = { projectId, importFileUrl, shouldValidateOnly: true }
    request(operation, { mutation })
      .then(({ data }) => updateErrorsJson(data.validationErrorsJson))
  }, [
    projectId,
    importFileUrl
  ])

  const inputs = getInputs(TEMPLATE_URL, isValidating, errorsJson, projectId)
  const schema = operation.getSchema(inputs)

  const onSubmit = parameters => {
    parameters.mutation.shouldValidateOnly = false
    return parameters
  }

  const onExit = () => {
    setErrorsJson()
    setIsDisabled(true)
    setIsValidating(false)
  }

  const onSuccess = () =>
    indexInvestments()

  const submitButtonProps = {
    disabled: isDisabled,
  }

  const createModalProps = {
    title: LABEL_TITLE,
    entity: LABEL_ENTITY,
    submitLabel: LABEL_SUBMIT,
    form,
    modal,
    schema,
    onExit,
    onSubmit,
    onSuccess,
    operation,
    submitButtonProps,
  }

  return (
    <CreateModal {...createModalProps} />
  )
}

InvestmentsImportModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  indexInvestments: PropTypes.func.isRequired,
}

export default InvestmentsImportModal
