import React from "react"
import { PlusOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"

const LABEL_CREATE = "Create"


const getHeaderActions = (openCreateModal) => {
  const canCreateCampaigns = hasAccess(['campaigns-write'])

  if (!canCreateCampaigns) {
    return []
  }

  return [
    {
      icon: <PlusOutlined />,
      title: LABEL_CREATE,
      action: openCreateModal,
    },
  ]
}

export default getHeaderActions
