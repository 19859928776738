import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Space, Tooltip, Typography } from "antd"
import { FileTextOutlined, WarningOutlined } from "@ant-design/icons"

import { W0 } from "@components"
import { InvestmentShape } from "@components/Investment/shapes"
import { isSdiraInvestment, isTrustInvestment } from "@components/Investment"

import hasTin from "./helpers/hasTin"

const { Link: TextLink } = Typography

const LABEL_NON_US = "Non-US person."
const LABEL_NO_TIN = "Doesn't have EIN / SSN."
const LABEL_OTHER_CUSTODIAN = "Other custodian selected."

const OTHER_CUSTODIAN_ID = "OTHER"


const InvestorColumn = ({ name, investment, openInvestmentDrawer }) => {
  const [ hasWarning, warningTitle ] = useMemo(() => {
    const {
      profileJoint = {},
      profileSdira = {},
      profileEntity = {},
      profileIndividual = {},
    } = investment

    const {
      isUsPerson: entityIsUsPerson,
    } = profileEntity

    const {
      isUsPerson: jointIsUsPerson,
    } = profileJoint

    const {
      custodianId,
      isUsPerson: sdiraUsPerson
    } = profileSdira

    const {
      isUsPerson: individualUsPerson,
    } = profileIndividual

    let shouldShowWarning = false

    const isNonUsPerson =
      sdiraUsPerson === false ||
      jointIsUsPerson === false ||
      entityIsUsPerson === false ||
      individualUsPerson === false

    const isSdira = isSdiraInvestment(investment)
    const isTrust = isTrustInvestment(investment)

    const hasNoTin = !hasTin(investment)

    const isTinRequired = !isSdira && !isTrust

    const isTinMissing = isTinRequired && hasNoTin

    const isCustodianOther = custodianId === OTHER_CUSTODIAN_ID

    const warnings = []

    if (isNonUsPerson) {
      warnings.push(LABEL_NON_US)

      shouldShowWarning = true
    }

    if (isCustodianOther) {
      warnings.push(LABEL_OTHER_CUSTODIAN)

      shouldShowWarning = true
    }

    if (isTinMissing) {
      warnings.push(LABEL_NO_TIN)

      shouldShowWarning = true
    }

    const warning = warnings.join(" ")

    return [ shouldShowWarning, warning ]

  }, [ investment ])

  const size = W0 / 2

  const onClick = () => openInvestmentDrawer(investment)

  const { note } = investment

  return (
    <TextLink
      strong
      onClick={onClick}
    >
      <Space size={size}>
        {
          note && (
            <Tooltip
              title={note}
              placement="top"
            >
              <FileTextOutlined />
            </Tooltip>
          )
        }

        {
          hasWarning && (
            <Tooltip
              title={warningTitle}
              placement="top"
            >
              <WarningOutlined />
            </Tooltip>
          )
        }

        {name}
      </Space>
    </TextLink>
  )
}

InvestorColumn.propTypes = {
  name: PropTypes.shape().isRequired,
  investment: InvestmentShape.isRequired,
  openInvestmentDrawer: PropTypes.func.isRequired,
}

export default InvestorColumn
