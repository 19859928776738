import React from "react"
import PropTypes from "prop-types"
import { Descriptions } from "antd"

import { MODAL_WIDTH, isMobile } from "@components"

import ProjectShape from "../shapes/ProjectShape"
import { ProjectDealTags } from "../ProjectTags"
import ProjectEditableGroup from "./ProjectEditableGroup"

const { Item } = Descriptions


const ProjectDeal = ({ project, isEditable }) => {
  const { tags = [] } = project

  const {
    group,
    title,
    fieldName,
  } = ProjectDealTags

  const items = tags
    .filter(tag => tag.group === group)
    .map(({ label, value }) =>
      <Item key={label} label={label}>{value}</Item>
    )

  const hasItems = items.length > 0

  const fieldNames = [ fieldName ]
  const extraProps = { level: 4, isEditable }

  const labelStyle = {
    width: "50%"
  }

  if (isMobile) {
    labelStyle.padding = 8
  }

  return (
    <ProjectEditableGroup
      title={title}
      fieldNames={fieldNames}
      modalWidth={MODAL_WIDTH * 1.25}
      extraProps={extraProps}
    >
      {
        hasItems && (
          <Descriptions
            bordered
            size="small"
            column={1}
            className="compact black"
            labelStyle={labelStyle}
          >
            { items }
          </Descriptions>
        )
      }
    </ProjectEditableGroup>
  )
}

ProjectDeal.propTypes = {
  project: ProjectShape.isRequired,
  isEditable: PropTypes.bool.isRequired,
}

export default ProjectDeal
