import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Form as AntForm, Space } from "antd"
import { useParams, useOutletContext } from "react-router-dom"

import { Form, FormShape } from "@components/Form"
import { SelectFolderButton } from "@components/Button"
import { createBatchInvestorFilesOperation as createOperation } from "@api/services/backstage"

import getCreateInputs from "./helpers/getCreateInputs"

const FILENAME_SIZE_LIMIT = 512

const LABEL_FILENAME_SIZE_ERROR = `file name exceeds ${FILENAME_SIZE_LIMIT} characters`


export const BatchCreateForm = ({ form, onFilesSelected }) => {
  const [ schema, setSchema ] = useState([])

  const { id: projectId } = useParams()
  const { batchDocumentTypeOptions } = useOutletContext()

  const typeOptions = useMemo(() =>
    batchDocumentTypeOptions.map(name => ({ label: name, value: name }))
  , [ batchDocumentTypeOptions ])

  const isSent = AntForm.useWatch("isSent", form)

  useEffect(() => {
    const inputs = getCreateInputs(projectId, typeOptions, isSent)
    const newSchema = createOperation.getSchema(inputs)

    setSchema(newSchema)
  }, [ isSent, projectId, typeOptions ])

  const onSuccess = (files, metadatas, unsupportedFileNames = []) => {
    const fileNames = metadatas.map(({ name }) => name)

    const okFileNames = fileNames.filter(name => name.length <= FILENAME_SIZE_LIMIT)
    const errorFileNames = fileNames.filter(name => name.length > FILENAME_SIZE_LIMIT)

    const fileErrors = [
      ...unsupportedFileNames.map(name => ({ name })),
      ...errorFileNames.map(name => ({ name, reason: LABEL_FILENAME_SIZE_ERROR }))
    ]

    onFilesSelected(files, okFileNames, fileErrors)
  }

  const formProps = {}

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Form
        form={form}
        schema={schema}
        operation={createOperation}
        formProps={formProps}
      />

      <SelectFolderButton
        onSuccess={onSuccess}
      />
    </Space>
  )
}

BatchCreateForm.propTypes = {
  form: FormShape.isRequired,
  onFilesSelected: PropTypes.func.isRequired,
}

export default BatchCreateForm
