
const downloadFile = url => {
  const link = document.createElement("a")

  link.setAttribute("target", "_blank")
  link.setAttribute("download", "")
  link.href = url

  document.body.appendChild(link)

  link.click()
  link.remove()
}

export default downloadFile
