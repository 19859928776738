import React, { useRef } from "react"
import { Card, Descriptions, Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import { isMobile } from "@components"
import { InvestmentShape } from "@components/Investment/shapes"

import ProfileModal from "./ProfileModal"
import ProfileConnect from "./ProfileConnect"
import ProfileInvestments from "./ProfileInvestments"

const { Item } = Descriptions

const LABEL_MORE = "More"
const LABEL_INVESTMENTS = "Investments"
const LABEL_BANK_ACCOUNT = "Bank Account"


const ProfileCard = ({ investment }) => {
  const modal = useRef({})

  const { getProject } = useOutletContext()

  const { name: title, investments, projectId } = investment
  const { tiers } = getProject(projectId)

  const onClick = () =>
    modal.current.open()

  const extra =
    <Typography.Text strong>
      <Typography.Link onClick={onClick}>
        {LABEL_MORE}
      </Typography.Link>
    </Typography.Text>

  return (
    <Card
      size={ isMobile ? "small" : "middle" }
      type="inner"
      title={title}
      extra={extra}
    >
      <Descriptions
        size="small"
        column={1}
        layout="vertical"
        className="summary"
      >
        <Item label={LABEL_INVESTMENTS}>
          <ProfileInvestments
            tiers={tiers}
            investments={investments}
          />
        </Item>

        <Item label={LABEL_BANK_ACCOUNT}>
          <ProfileConnect investment={investment} />
        </Item>
      </Descriptions>

      <ProfileModal
        modal={modal}
        investment={investment}
      />
    </Card>
  )
}

ProfileCard.propTypes = {
  investment: InvestmentShape.isRequired,
}

export default ProfileCard
