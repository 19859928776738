import PropTypes from "prop-types"


const properties = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

const BusinessClassificationShape = PropTypes.exact(properties)

export default BusinessClassificationShape
export { properties }
