

const getClassificationGroup = (classificationsMap, businessClassificationId) => {
  for (const classificationGroup of Object.keys(classificationsMap)) {
    const { options } = classificationsMap[classificationGroup]

    const option = options.find(({ value }) => value === businessClassificationId)

    if (option) {
      return classificationGroup
    }
  }

  return undefined
}

export default getClassificationGroup
