import React from "react"
import PropTypes from "prop-types"

import { Bold } from "@components/Text"
import { SsnInput, FormShape } from "@components/Form"


const InvestorSsnNumber = ({
 form,
 path,
 required = false,
 itemProps = {},
 inputProps = {},
}) => {
  itemProps.label = <Bold>{itemProps.label}</Bold>

  if (required) {
    itemProps.rules = [{
      required: true,
      message: "Field is required"
    }]
  }

  itemProps.name = path
  itemProps.validateTrigger = "onBlur"

  return (
    <SsnInput
      form={form}
      path={path}
      itemProps={itemProps}
      inputProps={inputProps}
    />
  )
}

InvestorSsnNumber.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  required: PropTypes.bool,
  itemProps: PropTypes.shape(),
  inputProps: PropTypes.shape(),
}

export default InvestorSsnNumber
