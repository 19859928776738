import React from "react"

import { Tag } from "antd"


const renderTag = (value, color) => {
  return (
    <Tag
      color={color}
      bordered={false}
    >
      <strong>{value.toUpperCase()}</strong>
    </Tag>
  )
}

export default renderTag
