export const TYPE_ZIP = "zip"
export const TYPE_EIN = "ein"
export const TYPE_SSN = "ssn"
export const TYPE_TAGS = "tags"
export const TYPE_NONE = "none"
export const TYPE_DATE = "date"
export const TYPE_TIME = "time"
export const TYPE_TEXT = "text"
export const TYPE_ENUM = "enum"
export const TYPE_ROLE = "role"
export const TYPE_ARRAY = "array"
export const TYPE_EMAIL = "email"
export const TYPE_RADIO = "radio"
export const TYPE_PHONE = "phone"
export const TYPE_DOMAIN = "domain"
export const TYPE_UPLOAD = "upload"
export const TYPE_STRING = "string"
export const TYPE_AMOUNT = "amount"
export const TYPE_OBJECT = "object"
export const TYPE_NUMBER = "number"
export const TYPE_HIDDEN = "hidden"
export const TYPE_BOOLEAN = "boolean"
export const TYPE_COUNTRY = "country"
export const TYPE_INTEGER = "integer"
export const TYPE_CHECKBOX = "checkbox"
export const TYPE_REDACTOR = "redactor"
export const TYPE_DATETIME = "datetime"
export const TYPE_TIMEZONE = "time_zone"
export const TYPE_ARRAY_ENUM = "array_enum"
export const STRING_LIST_TYPE = "string_list"
export const TYPE_PASSWORD = "password"
