import React from "react"
import PropTypes from "prop-types"
import { Dropdown, Button } from "antd"
import { MoreOutlined } from "@ant-design/icons"


const TableRowActions = ({
  isLoading = false,
  items,
}) => {
  const hasItems = items.length > 0

  if (!hasItems) {
    return null
  }

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button
        type="text"
        icon={<MoreOutlined />}
        loading={isLoading}
        onClick={e => e.preventDefault()}
      />
    </Dropdown>
  )
}

TableRowActions.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool,
}

export default TableRowActions
