import React from "react"
import { useOutletContext } from "react-router-dom"
import { Row, Col, Space, Typography } from "antd"

import { Value } from "@components/Text"
import { TRANSACTION_TYPES } from "@components/Domain"
import { getTransactionTypeLabel } from "@components/Transaction"

import InvestorTransactionStatusTag from "../InvestorTransactionStatusTag"

const { Text } = Typography


const useRenderItem = () => {
  const { investmentsMap } = useOutletContext()

  const getProjectName = investmentId =>
    investmentsMap[investmentId]?.projectName || investmentId

  const render = (id, { type, investmentId, amount, date, status }) =>
    <>
      <Row>
        <Col flex="auto">
          <Text strong>{getProjectName(investmentId)}</Text>
        </Col>
        <Col>
          <Space size={2}>
            {TRANSACTION_TYPES.FUNDING === type ? '-' : '+'}
            <Value value={amount} />
          </Space>
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <Space>
            {getTransactionTypeLabel(type)}
            <InvestorTransactionStatusTag status={status} />
          </Space>
        </Col>
        <Col>
          <Text type="secondary">
            <Value value={date} />
          </Text>
        </Col>
      </Row>
    </>

  return render
}

export default useRenderItem
