import React from "react"
import { Space, Typography } from "antd"

import { useAppContext } from "@components/AppContext"

const LABEL_CURRENT = "Current"
const LABEL_ORGANIZATION = "Organization"


const useColumnsConfig = ({ onBeforeSwitch, onAfterSwitch }) => {
  const {
    identity,
    switchToOrganization
  } = useAppContext()

  const { organization } = identity
  const { id: currentOrganizationId } = organization

  const isCurrentOrganization = (id) =>
    id === currentOrganizationId

  const nameColumnProps = {
    key: "name",
    title: LABEL_ORGANIZATION,
    render: (value, { id }) =>
      isCurrentOrganization(id)
        ? (
            <Space>
              <Typography.Text strong>
                {value}
              </Typography.Text>
              <Typography.Text type="secondary">
                ({LABEL_CURRENT})
              </Typography.Text>
            </Space>
          )
        : (
          <Typography.Link
            strong
            onClick={() =>
              onBeforeSwitch()
                .then(() => switchToOrganization(id))
                .then(() => onAfterSwitch())}
          >
            {value}
          </Typography.Link>
        )
    ,
    isSearchable: true,
  }

  return [
    { ...nameColumnProps },
  ]
}

export default useColumnsConfig
