import React from "react"
import PropTypes from "prop-types"
import { Typography, Table, Alert, Space, Collapse } from "antd"

import { W1 } from "@components"
import DnsRecordShape from "@api/services/organizations/shapes/DnsRecordShape"

const { Text } = Typography

const LABEL_TITLE = "Publish DNS Records"
const LABEL_DESCRIPTION = `Add the records below to your DNS for [DOMAIN]. It
may take up to 72 hours to detect the changes.`


const EmailIdentityRecords = ({
  domain,
  dnsRecords,
  isCollapsed
}) => {
  const columns = [
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
      render: value => <Text>{value}</Text>
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      render: value => <Text copyable>{value}</Text>
    },
    {
      key: "value",
      title: "Value",
      dataIndex: "value",
      render: value => <Text copyable>{value}</Text>
    },
  ]

  const dataSource = [
    ...dnsRecords.map((record, index) => {
      const { type, name, value } = record

      return {
        key: index,
        type,
        name,
        value
      }
    })
  ]

  const items = [
    {
      key: '1',
      label: (
        <Typography.Text strong>
          {LABEL_TITLE}
        </Typography.Text>
      ),
      children: (
        <Space
          size={W1}
          direction="vertical"
        >
          <Alert
            showIcon
            type="info"
            message={LABEL_DESCRIPTION.replace("[DOMAIN]", domain)}
          />

          <Table
            size="small"
            columns={columns}
            pagination={false}
            dataSource={dataSource}
          />
        </Space>
      )
    }
  ]

  const defaultActiveKey = isCollapsed
    ? []
    : ['1']

  return (
    <Collapse
      ghost
      items={items}
      defaultActiveKey={defaultActiveKey}
    />
  )
}

EmailIdentityRecords.propTypes = {
  domain: PropTypes.string.isRequired,
  dnsRecords: PropTypes.arrayOf(DnsRecordShape).isRequired,
  isCollapsed: PropTypes.bool.isRequired,
}

export default EmailIdentityRecords
