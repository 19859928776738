import React, { useRef } from "react"
import { useOutletContext } from "react-router-dom"

import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"
import { NotSourceController } from "@components/Investor"
import { ConnectSourceButton } from "@components/Dwolla"
import { getInvestmentSourceSelectProps } from "@components/Investment"

import DistributionsConfirmModal from "./DistributionsConfirmModal"
import InvestmentProfileCardItem, { LABEL_PENDING, LABEL_COMPLETE } from "./InvestmentProfileCardItem"
import ConfirmRetryFailedTransactionsModal
  from "../../InvestorAccountPage/ConfirmRetryFailedTransactionsModal"

const LABEL_CONNECT = "Connect Distributions Bank Account"
const LABEL_CONNECT_TEXT = `
  Connect the bank account you would like distributions sent to.
`


const InvestmentProfileCardConnectReceiverSource = ({ investment }) => {
  const distributionsConfirmModal = useRef({})
  const confirmRetryFailedTransactionsModal = useRef({})

  const { updateInvestment } = useOutletContext()
  const { request, isImpersonated, isSourceController } = useAppContext()

  const connectReceiverSourceProps =
    getInvestmentSourceSelectProps({
      request,
      investment,
      confirmRetryFailedTransactionsModal,
      onInvestmentSourceChanged: updateInvestment,
      connectBankAccountPlaidConfirmModal: distributionsConfirmModal
    })

  const {
    isSigned,
    isPartiallySigned,
    receiverSourceId,
    receiverSourceName
  } = investment

  const isDocumentSigned = isPartiallySigned || isSigned

  const canConnectSource = isDocumentSigned && !isImpersonated

  const hasReceiverSource = !!receiverSourceId

  const isNotSourceController = !!receiverSourceId && !isSourceController(receiverSourceId)

  const isConnectReceiverDisabled = () => {
    if (!isDocumentSigned) {
      return true
    }

    if (!canConnectSource) {
      true
    }

    return  false
  }

  const renderConnectButton = () => {
    if (isNotSourceController) {
      return (
        <NotSourceController
          sourceName={receiverSourceName}
        />
      )
    }

    if (!isConnectReceiverDisabled()) {
      return (
        <>
          <ConnectSourceButton
            {...connectReceiverSourceProps}
          />

          <DistributionsConfirmModal
            modal={distributionsConfirmModal}
          />

          <ConfirmRetryFailedTransactionsModal
            modal={confirmRetryFailedTransactionsModal}
          />
        </>
      )
    }

    return null
  }

  let status = LABEL_PENDING
  let styleType = "danger"

  if (hasReceiverSource) {
    status = LABEL_COMPLETE
    styleType = "success"
  }

  return (
    <InvestmentProfileCardItem
      title={LABEL_CONNECT}
      status={status}
      subtitle={LABEL_CONNECT_TEXT}
      styleType={styleType}
    >
      {renderConnectButton()}
    </InvestmentProfileCardItem>
  )
}

InvestmentProfileCardConnectReceiverSource.propTypes = {
  investment: InvestmentShape.isRequired,
}

export default InvestmentProfileCardConnectReceiverSource
