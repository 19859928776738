import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { Spin, Space } from "antd"

import Table from "@components/Table"
import { SearchInput } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { indexOrganizationsOperation as indexOperation } from "@api/services/organizations"

import useOrganizations from "./helpers/useOrganizations"
import useColumnsConfig from "./helpers/useColumnsConfig"


const OrganizationsList = ({ onAfterSwitch: onAfterSwitchCustom = undefined }) => {
  const table = useRef({})

  const [ isLoading, setIsLoading ] = useState(false)

  const items = useOrganizations()
  const { authorizeOrganizationIdentity } = useAppContext()

  const onBeforeSwitch = async () =>
    setIsLoading(true)

  const onAfterSwitch = onAfterSwitchCustom || (() => {})

  const columnsConfig = useColumnsConfig({ onBeforeSwitch, onAfterSwitch })

  const size = "small"

  const pagination = {
    pageSize: 10,
    showSizeChanger: false,
  }

  const tableProps = {
    indexItems: () => authorizeOrganizationIdentity(),
    size,
    items,
    table,
    pagination,
    columnsConfig,
    indexOperation,
  }

  const onSearch = value =>
    table.current.search(value)

  return (
    <Spin spinning={isLoading}>
      <Space
        style={{ width: "100%" }}
        direction="vertical"
      >
        <SearchInput onSearch={onSearch} />

        <Table {...tableProps} />
      </Space>
    </Spin>
  )
}

OrganizationsList.propTypes = {
  onAfterSwitch: PropTypes.func,
}

export default OrganizationsList
