import capitalize from "lodash.capitalize"

export const BROKER_DEALER = "BROKER_DEALER"
export const CO_SPONSOR = "CO_SPONSOR"

const SponsorTypeEnum = {
  [BROKER_DEALER]: BROKER_DEALER,
  [CO_SPONSOR]: CO_SPONSOR
}

const SponsorTypeEnumKeys = Object.keys(SponsorTypeEnum)

const SponsorTypeEnumOptions = SponsorTypeEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  SponsorTypeEnumKeys,
  SponsorTypeEnumOptions
}

export default SponsorTypeEnum
