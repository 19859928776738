import { COUNTRY_US } from "@components/Address"
import { TYPE_STRING, TYPE_ZIP, TYPE_COUNTRY } from "@components/Form"

import getRegionsByCountry from "./getRegionsByCountry"

const PLACEHOLDER_STREET = "Street address, apt, suite, unit, building, floor, etc"

const DEFAULT_LABELS = {
  addressCountry: "Country",
  streetAddress: "Address",
  addressLocality: "City",
  usAddressRegion: "State",
  addressRegion: "Region",
  usPostalCode: "ZIP Code",
  postalCode: "Postal Code",
}

const PLACEHOLDER_ZIP_CODE = "XXXXX"
const PLACEHOLDER_SELECT_STATE = "Select state"


const getAddressSchema = (updateSchema, addressPath = [], address = {}, initialAddress = {}, labels = {}, isRequired = true) => {
  labels = { ...DEFAULT_LABELS, ...labels }

  const { addressCountry = COUNTRY_US } = address

  const isUs = addressCountry === COUNTRY_US

  const addressRegionInputProps = {}

  addressRegionInputProps.options = getRegionsByCountry(addressCountry)

  return [
    {
      name: "addressCountry",
      label: labels.addressCountry,
      type: TYPE_COUNTRY,
      required: isRequired,
      inputProps: {
        onChange: (_, form) => {
          form.setFieldValue([...addressPath, "addressRegion"], "")
          return updateSchema()
        }
      },
      initialValue: initialAddress.addressCountry || COUNTRY_US,
    },
    {
      name: "streetAddress",
      label: labels.streetAddress,
      required: isRequired,
      maxLength: 50,
      placeholder: PLACEHOLDER_STREET,
      initialValue: initialAddress.streetAddress,
    },
    {
      name: "addressLocality",
      label: labels.addressLocality,
      required: isRequired,
      maxLength: 32,
      placeholder: "",
      initialValue: initialAddress.addressLocality,
    },
    {
      name: "addressRegion",
      label: isUs ? labels.usAddressRegion : labels.addressRegion,
      required: isRequired,
      placeholder: isUs ? PLACEHOLDER_SELECT_STATE : "",
      initialValue: initialAddress.addressRegion,
      ...addressRegionInputProps,
    },
    {
      name: "postalCode",
      label: isUs ? labels.usPostalCode : labels.postalCode,
      type: isUs ? TYPE_ZIP : TYPE_STRING,
      required: isRequired,
      inputProps: {
        style: { width: 170 },
      },
      placeholder: isUs ? PLACEHOLDER_ZIP_CODE : "",
      initialValue: initialAddress.postalCode,
    }
  ]
}

export default getAddressSchema
