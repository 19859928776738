import React, { useState, useEffect } from "react"
import { gray } from "@ant-design/colors"
import { Card, Space, Row, Col, Typography } from "antd"
import { BankFilled } from "@ant-design/icons"

import { Value } from "@components/Text"
import { W0, W1 } from "@components"
import SourceShape from "@api/services/transactions/shapes/SourceShape"
import { CopyValueButton } from "@components/Button"
import { runRequest, useAppContext } from "@components/AppContext"
import { listSourceBankAccountsOperation } from "@api/services/transactions"

import SourceStatusTag from "./SourceStatusTag"
import BankAccountsList from "./BankAccountsList"

const { Text } = Typography


const CustomerSource = ({ source }) => {
  const [ bankAccounts, setBankAccounts ] = useState([])

  const { authenticatedRequest } = useAppContext()

  const {
    id: sourceId,
    name,
    createdAt,
    fundingSourceUrl
  } = source

  useEffect(() => {
    return runRequest(() =>
      authenticatedRequest(listSourceBankAccountsOperation, { sourceId })
        .then(({ data }) => setBankAccounts(data))
    )
  }, [ sourceId, authenticatedRequest ])

  const getDwollaSourceId = () => {
    const [ id ] = fundingSourceUrl.split('/').reverse()

    return id
  }

  const avatar = <BankFilled style={{ fontSize: 46, color: gray.primary }} />

  const description = (
    <Row>
      <Col flex="auto" style={{ paddingLeft: 8, paddingBottom: 4 }}>
        <Space direction="vertical" size={4}>
          <Typography.Text strong>
            {name}
          </Typography.Text>

          <CopyValueButton value={sourceId} />
        </Space>
      </Col>

      <Col>
        <SourceStatusTag source={source} />
      </Col>
    </Row>
  )

  const styles = {}

  const hasBankAccounts =
    !!bankAccounts &&
    bankAccounts.length > 0

  if (hasBankAccounts) {
    styles.body =  { paddingBottom: 0 }
  }

  return (
    <Card size="small" style={{ padding: W0 }} styles={styles}>
      <Card.Meta
        avatar={avatar}
        description={description}
      />

      <Space direction="vertical" style={{ marginTop: W1 }} size={W0 / 2}>
        <Text type="secondary">
          Added: <Value value={createdAt} />
        </Text>
        <Text type="secondary">
          Dwolla Source ID: {getDwollaSourceId()}
        </Text>
      </Space>

      {
        hasBankAccounts && (
          <BankAccountsList bankAccounts={bankAccounts} />
        )
      }
    </Card>
  )
}

CustomerSource.propTypes = {
  source: SourceShape.isRequired,
}

export default CustomerSource
