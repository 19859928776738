import { wait } from "@api/helpers/wait"
import { HOST } from "@components"

import Auth from "./Auth"
import Storage from "./Storage"
import { jsonRequest } from "../request"

const AUTHENTICATE_DEV_URL = `${HOST}/api/identity/Authenticate`


const authenticate = async (onAuthenticationError, isBackstage, shouldRefresh = false) => {
  if (!isBackstage) {
    const impersonatedAuthentication = Storage.popImpersonatedAuthentication()

    if (impersonatedAuthentication) {
      Storage.setInvestorAuthentication(impersonatedAuthentication)

      // eslint-disable-next-line no-console
      console.info('Authenticated as impersonated user')

      return impersonatedAuthentication
    }
  }

  let authentication

  if (!shouldRefresh) {
    authentication = isBackstage
      ? Storage.backstageAuthentication
      : Storage.investorAuthentication

    if (authentication) {
      return authentication
    }
  }

  // NOTE: Wait for 10ms to allow one of the threads to start.
  await wait(10)

  const { isPendingAuthentication } = Storage

  if (isPendingAuthentication) {
    // NOTE: If authentication errors, we do not stop these thread.
    do {
      await wait(250)

    } while (Storage.isPendingAuthentication)

    const authenticationJwt = Storage.getAuthentication(isBackstage)
    return authenticationJwt
  }

  Storage.startAuthentication()

  const idToken = await Auth.getIdToken()
  const hasNoAuthenticationParameter = !idToken

  if (hasNoAuthenticationParameter) {
    return null
  }

  const redirectUrl = isBackstage
    ? `${HOST}/backstage/`
    : `${HOST}/`

  const mutation = {
    idToken,
    redirectUrl
  }

  let authenticationJwt

  try {
    const { data } = await jsonRequest(AUTHENTICATE_DEV_URL, { mutation })
    authenticationJwt = data.authenticationJwt

  } catch (error) {
    onAuthenticationError(error.originalError?.message || error.message)
    return null

  }

  const setAuthentication = isBackstage
    ? Storage.setBackstageAuthentication
    : Storage.setInvestorAuthentication

  setAuthentication(authenticationJwt)
  Storage.stopAuthentication()

  return authenticationJwt
}

export default authenticate
