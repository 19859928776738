import { createTagsListInput } from "@components/Form/Input/TagsListInput"
import { PROPERTY_TAG_LABELS } from "@components/Domain"


const PropertyInformationTags = createTagsListInput({
  name: "tags",
  group: "INFO",
  title: "Additional Information",
  labels: PROPERTY_TAG_LABELS
})

export default PropertyInformationTags
