import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { UpdateModal } from "@components/Page"
import {
  ReadMyOrganizationOutputShape,
  updateMyOrganizationOperation as operation
} from "@api/services/organizations"

import getUpdateInputs from "./helpers/getUpdateInputs"

const LABEL_ENTITY = "Organization"
const LABEL_TITLE = `Edit ${LABEL_ENTITY}`
const LABEL_SUBMIT = "Save"


const OrganizationUpdateModal = ({
  title = LABEL_TITLE,
  onlyFieldNames = [],
  modal,
  organization,
}) => {
  const { indexSponsors, updateOrganization } = useOutletContext()

  const inputs = getUpdateInputs(organization, onlyFieldNames)
  const schema = operation.getSchema(inputs)

  const onSuccess = async (newOrganization) => {
    await indexSponsors()
    updateOrganization(newOrganization)
  }

  const updateModalProps = {
    entity: LABEL_ENTITY,
    submitLabel: LABEL_SUBMIT,
    modal,
    title,
    schema,
    onSuccess,
    operation,
  }

  return (
    <UpdateModal {...updateModalProps} />
  )
}

OrganizationUpdateModal.propTypes = {
  title: PropTypes.string,
  modal: PropTypes.shape().isRequired,
  organization: ReadMyOrganizationOutputShape.isRequired,
  onlyFieldNames: PropTypes.arrayOf(PropTypes.string),
}

export default OrganizationUpdateModal
