import React, { useEffect, useMemo, useRef, useState, useCallback } from "react"
import PropTypes from "prop-types"
import countriesPhoneList from "countries-phone-masks"
import { MaskedInput as MaskedInputComponent } from "antd-mask-input"
import { Form, Input, Space, Select, Typography } from "antd"
import { getCountryCodeForRegionCode, parsePhoneNumber } from "awesome-phonenumber"

import { W0 } from "@components"
import { COUNTRY_US } from "@components/Address"

import FormShape from "../../shapes/FormShape"
import getMaskedValue from "./helpers/getMaskedValue"
import getValidationRules from "./helpers/getValidationRules"
import getCountryCodesOptions from "./helpers/getCountryCodesOptions"

import "./PhoneInput.css"

const { Compact } = Space
const { Text } = Typography

const US_MASK = "(000)000-0000"

const REGEX_REPLACE_SYMBOLS = /\/|-|_|\(|\)/g

const countryCodesOptions = getCountryCodesOptions()


const PhoneInput = ({
  form,
  path,
  itemProps,
  inputProps,
}) => {
  const maskRef = useRef()

  const [ mask, setMask ] = useState(US_MASK)
  const [ inputMask, setInputMask ] = useState(US_MASK)

  const {
    name,
    rules,
    label,
    initialValue,
    ...restItemProps
  } = itemProps

  const codeField = `_code_${name.slice(-1)}`
  const inputField = `_${name.slice(-1)}`

  const inputName = [...name.slice(0, -1), inputField]
  const inputPath = useMemo(() => [...path.slice(0, -1), inputField], [path, inputField])

  const codeName = [...name.slice(0, -1), codeField]
  const codePath = useMemo(() => [...path.slice(0, -1), codeField], [path, codeField])

  const sourceValue = Form.useWatch(path, form)
  const inputSourceValue = Form.useWatch(inputName, form)

  const updateMaskByCountry = countryCode => {
    const country = countriesPhoneList.find(({ iso }) => countryCode === iso)

    const { mask: countryMask } = country

    const isMaskArray = Array.isArray(countryMask)

    if (isMaskArray) {
      const exampleMaskLengthList = countryMask
        .map(item => item.replace(REGEX_REPLACE_SYMBOLS, "0").length)

      const maxLength = Math.max(...exampleMaskLengthList)
      const maxLengthIndex = exampleMaskLengthList.findIndex(item => item === maxLength)
      const maxLengthCountryMask = countryMask[maxLengthIndex]
      const formattedMask = maxLengthCountryMask.replace(/0/g, "&").replace(/#/g, "!")

      setInputMask(formattedMask)
      setMask(maxLengthCountryMask)

    } else {
      const formattedMask = countryMask.replace(/0/g, "&").replace(/#/g, "!")
      setInputMask(formattedMask)
      setMask(countryMask)

    }
  }

  const setValue = useCallback(() => {
    const value = form.getFieldValue(path)

    if (!value) {
      return
    }

    const { valid, regionCode, number: { significant } } = parsePhoneNumber(`+${value}`)

    if (!valid) {
      return
    }

    const maskedValue = getMaskedValue(significant, inputMask)

    updateMaskByCountry(regionCode)

    form.setFieldValue(codePath, regionCode)
    form.setFieldValue(inputPath, maskedValue)
  }, [
    form,
    path,
    codePath,
    inputMask,
    inputPath,
  ])

  useEffect(() => {
    setValue()
  }, [
    setValue,
    sourceValue,
    inputSourceValue,
  ])

  const isRequired = rules.find(rule => rule.required)

  const itemRules = [
    ...getValidationRules({
      mask,
      form,
      codePath,
      isRequired,
    }),
    ...rules
  ]

  const phoneItemProps = {
    ...restItemProps,
    rules: itemRules
  }

  const onChange = ({ maskedValue }) => {
    const unmaskedValue = maskedValue.replace(/\(|\)|_|-/g, "")

    const hasValue = unmaskedValue.length > 0

    if (!hasValue) {
      form.setFieldValue(path, undefined)
      return
    }

    const regionCode = form.getFieldValue(codePath)
    const countryCode = getCountryCodeForRegionCode(regionCode)
    const fullValue = `${countryCode}${unmaskedValue}`
    form.setFieldValue(path, fullValue)
  }

  const onChangeCountry = countryCode => {
    const { code } = countriesPhoneList
      .find(({ iso }) => countryCode === iso)

    updateMaskByCountry(countryCode)

    const formattedCode = code.replace("+", "")

    form.setFieldValue(inputPath, "")
    form.setFieldValue(path, formattedCode)
  }

  const labelClass = isRequired
    ? "item-required"
    : ""

  const labelStyle = {
    paddingBottom: W0
  }

  const definitions = {
    '&': /^[0]{1}$/,
    '!': /^[0-9]{1}$/,
  }

  return (
    <>
      <Form.Item
        key="hidden"
        name={name}
        hidden={true}
        initialValue={initialValue}
      >
        <Input />
      </Form.Item>

      <div className="phone-input">
        <div>
          <div
            className={labelClass}
            style={labelStyle}
          >
            <Text strong>{label}</Text>
          </div>
        </div>

        <Compact>
          <Form.Item
            key="code"
            name={codeName}
            initialValue={COUNTRY_US}
          >
            <Select
              style={{ width: 110 }}
              options={countryCodesOptions}
              onChange={onChangeCountry}
            />
          </Form.Item>

          <Form.Item
            name={inputName}
            rules={itemRules}
            className="mask-input"
            {...phoneItemProps}
          >
            <MaskedInputComponent
              ref={maskRef}
              mask={inputMask}
              style={{ width: 160 }}
              onChange={onChange}
              allowClear={true}
              definitions={definitions}
              {...inputProps}
              placeholder=""
            />
          </Form.Item>
        </Compact>
      </div>
    </>
  )
}

PhoneInput.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemProps: PropTypes.shape().isRequired,
  inputProps: PropTypes.shape().isRequired,
}

export default PhoneInput
