import capitalize from "lodash.capitalize"

export const C_CORP = "C_CORP"
export const PARTNERSHIP = "PARTNERSHIP"
export const S_CORP = "S_CORP"

const TaxFilingStatusEnum = {
  [C_CORP]: C_CORP,
  [PARTNERSHIP]: PARTNERSHIP,
  [S_CORP]: S_CORP
}

const TaxFilingStatusEnumKeys = Object.keys(TaxFilingStatusEnum)

const TaxFilingStatusEnumOptions = TaxFilingStatusEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  TaxFilingStatusEnumKeys,
  TaxFilingStatusEnumOptions
}

export default TaxFilingStatusEnum
