import React from "react"
import { gray } from "@ant-design/colors"
import { Input } from "antd"
import Highlighter from "react-highlight-words"
import { SearchOutlined } from "@ant-design/icons"

import { WIDTHS } from "@components"

const { W0 } = WIDTHS

const COLOR_FILTER_ON = "#1890ff"
const COLOR_HIGHLIGHTER = "#ffc069"

const getSearchPlaceholder = column => `Search ${column}...`


const computeGetColumnSearchProps = (searchRef, searchText, searchColumn, searchAction) => {
  const getProps = (dataIndex, compute, render = value => value) => {
    const filterDropdown = ({
      confirm,
      clearFilters,
      selectedKeys,
      setSelectedKeys,
    }) => (
      <div style={{ padding: W0 }}>
        <Input
          ref={searchRef}
          allowClear
          prefix={<SearchOutlined style={{ color: gray.secondary }} />}
          placeholder={getSearchPlaceholder(dataIndex)}
          onChange={e => {
              const shouldClosePopup = e.type === "clear"
              const { value: query = "" } = e.target

              setSelectedKeys([ query ])

              const isEmpty = query === ""

              if (isEmpty) {
                clearFilters()
              }

              searchAction(query, confirm, dataIndex, shouldClosePopup)
            }
          }
          onPressEnter={() => {
            const [ query ] = selectedKeys

            const isEmpty = query === ""

            if (isEmpty) {
              clearFilters()
            }

            searchAction(query, confirm, dataIndex, true)
          }}
        />
      </div>
    )

    const onFilter = (value, item) =>
      compute(item)
        .toString()
        .toLowerCase()
        .includes((value).toLowerCase())

    const filterIcon = filtered =>
      <SearchOutlined style={{ color: filtered ? COLOR_FILTER_ON : undefined }} />

    const onFilterDropdownOpenChange = visible =>
      visible ? setTimeout(() => searchRef.current?.select(), 100) : {}

    const renderWithHighlighter = (_, item) => {
      const value = compute(item)
      const isColumnSearchOn = searchColumn === dataIndex

      if (!isColumnSearchOn) {
        return render(value, item)
      }

      const highlightedValue =
        <Highlighter
          className="highlighter-container"
          autoEscape
          searchWords={[ searchText ]}
          highlightStyle={{ backgroundColor: COLOR_HIGHLIGHTER, padding: 0 }}
          textToHighlight={value}
        />

      return render(highlightedValue, item)
    }

    return {
      render: renderWithHighlighter,
      onFilter,
      filterDropdown,
      filterIcon,
      onFilterDropdownOpenChange,
    }
  }

  return getProps
}

export default computeGetColumnSearchProps
