import uniq from "lodash.uniq"

import { ROLES } from "@components/Domain"
import { useMainSponsorId } from "@modules/backstage/sponsors"


const useOnSubmit = (roles = []) => {
  const mainSponsorId = useMainSponsorId()

  return parameters => {
    parameters.mutation.roles = parameters.mutation.roles || roles

    const {
      _isLiaison: isLiaisonChecked,
      _isAccountant: isAccountantChecked,
    } = parameters.mutation

    if (isLiaisonChecked) {
      parameters.mutation.roles = uniq([ ...parameters.mutation.roles, ROLES.BROKER ])
      parameters.mutation.sponsorId = mainSponsorId
    }

    if (isLiaisonChecked === false) {
      parameters.mutation.roles = parameters.mutation.roles
        .filter(roleId => roleId !== ROLES.BROKER)
    }

    if (isAccountantChecked) {
      parameters.mutation.roles = uniq([ ...parameters.mutation.roles, ROLES.ACCOUNTANT ])
    }

    if (isAccountantChecked === false) {
      parameters.mutation.roles = parameters.mutation.roles
        .filter(roleId => roleId !== ROLES.ACCOUNTANT)
    }

    return parameters
  }
}

export default useOnSubmit
