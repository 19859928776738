import React from "react"
import PropTypes from "prop-types"
import { Steps } from "antd"
import { ClockCircleOutlined } from "@ant-design/icons"


const getProjectStates = isFunded => {
  const states = [
    {
      title: "Specify Your Profile",
    },
    {
      title: 'Specify Your Investments',
    },
    {
      title: 'Sign Subscription Documents',
    },
    {
      title: 'Send Funding',
    },
    {
      title: "Confirmed",
    }
  ]

  if (!isFunded) {
    states[4].icon = <ClockCircleOutlined />
    states[4].title ='Pending Confirmation'
  }

  return states
}


const getCurrentStateIndex = (isFunded, isSigned, isProfileComplete, isInvestorComplete) => {
  if (!isInvestorComplete) {
    return 0
  }

  if (!isProfileComplete) {
    return 1
  }

  if (!isSigned) {
    return 2
  }

  if (!isFunded) {
    return 3
  }

  return 4
}


const InvestmentSteps = ({
  isFunded,
  isSigned,
  isProfileComplete,
  isInvestorComplete
}) => {
  const items = getProjectStates(isFunded)
  const current = getCurrentStateIndex(isFunded, isSigned, isProfileComplete, isInvestorComplete)

  return (
    <Steps
      size="small"
      items={items}
      current={current}
      direction="vertical"
    />
  )
}

InvestmentSteps.propTypes = {
  isFunded: PropTypes.bool.isRequired,
  isSigned: PropTypes.bool.isRequired,
  isProfileComplete: PropTypes.bool.isRequired,
  isInvestorComplete: PropTypes.bool.isRequired,
}

export default InvestmentSteps
