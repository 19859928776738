import React from "react"
import { NavLink } from "react-router-dom"
import { StopOutlined } from "@ant-design/icons"

const LABEL_DELETE = "Delete"


const useRowMenu = (bankAccount, { openDeleteConfirm }) => {
  const { id } = bankAccount

  const items = [    {
      key: "delete",
      icon: <StopOutlined />,
      danger: true,
      label: (
        <NavLink onClick={() => openDeleteConfirm(id)}>
          {LABEL_DELETE}
        </NavLink>
      )
    }
  ]

  return items
}

export default useRowMenu
