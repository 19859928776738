import React, { useState } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { UpdateModal } from "@components/Page"
import {
  readUserOperation as readOperation,
  updateBrokerOperation as updateOperation,
} from "@api/services/backstage"

import getUpdateInputs from "./helpers/getUpdateInputs"

const LABEL_ENTITY = "Liaison"
const LABEL_UPDATE = `Edit ${LABEL_ENTITY}`
const LABEL_SUBMIT = "Save"


const BrokerUpdateModal = ({ modal }) => {
  const [ schema, setSchema ] = useState([])

  const { updateUser } = useOutletContext()

  const onSuccess = updatedUser =>
    updateUser(updatedUser)

  const onItemRead = broker => {
    const updateInputs = getUpdateInputs(broker)
    const updateSchema = updateOperation.getSchema(updateInputs)
    setSchema(updateSchema)
  }

  return (
    <UpdateModal
      modal={modal}
      title={LABEL_UPDATE}
      entity={LABEL_ENTITY}
      schema={schema}
      onSuccess={onSuccess}
      operation={updateOperation}
      onItemRead={onItemRead}
      submitLabel={LABEL_SUBMIT}
      readOperation={readOperation}
    />
  )
}

BrokerUpdateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default BrokerUpdateModal
