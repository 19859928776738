import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { Form } from "antd"
import { useParams, useOutletContext } from "react-router-dom"

import { CreateModal } from "@components/Page"
import { useMainSponsorId } from "@modules/backstage/sponsors"
import { createBatchFilesOperation as createOperation } from "@api/services/backstage"

import getCreateInputs from "./helpers/getCreateInputs"
import useConfirmSubmit from "./helpers/useConfirmSubmit"
import getSponsorOptions from "./helpers/getSponsorOptions"

const LABEL_TITLE = "Upload Project File"
const LABEL_ENTITY = "File"
const LABEL_SUBMIT = "Upload File"


const BatchFilesCreateModal = ({ modal, onSuccess }) => {
  const [ schema, setSchema ] = useState([])
  const [ isEnabled, setIsEnabled ] = useState(false)

  const [ form ] = Form.useForm()
  const mainSponsorId = useMainSponsorId()
  const { id: projectId } = useParams()

  const {
    project,
    getSponsorName,
    documentTypeOptions,
    hasMultipleSponsors,
    ensureDocumentTypeOption,
  } = useOutletContext()

  const confirmSubmit = useConfirmSubmit()

  const typeOptions = useMemo(() =>
    documentTypeOptions.map(name => ({ label: name, value: name }))
  , [ documentTypeOptions ])

  const projectSponsorOptions = useMemo(() =>
    getSponsorOptions(project.partners || [], getSponsorName)
  , [ project, getSponsorName ])

  const isSent = Form.useWatch("isSent", form)
  const fileUrl = Form.useWatch("fileUrl", form)
  const isPublicFile = Form.useWatch("isPublicFile", form)

  useEffect(() => {
    const inputs = getCreateInputs(projectId, typeOptions, isSent, isPublicFile, mainSponsorId, hasMultipleSponsors, projectSponsorOptions)
    const newSchema = createOperation.getSchema(inputs)

    setSchema(newSchema)
  }, [
    isSent,
    isPublicFile,
    hasMultipleSponsors,
    mainSponsorId,
    projectId,
    projectSponsorOptions,
    typeOptions
  ])

  useEffect(() => {
    form.setFieldValue("fileUrl", null)
    form.setFieldValue("fileSourceFileName", null)
  }, [ isPublicFile, form ])

  useEffect(() => {
    const isFileUploaded = !!fileUrl
    setIsEnabled(isFileUploaded)
  }, [ fileUrl ])

  const onSubmit = async (parameters) => {
    const { mutation } = parameters
    const { documentType } = mutation

    await ensureDocumentTypeOption(documentType)

    return parameters
  }

  const onSubmitConfirm = (parameters, onConfirm) =>
    confirmSubmit(parameters, onConfirm)

  const createModalProps = {
    title: LABEL_TITLE,
    entity: LABEL_ENTITY,
    operation: createOperation,
    submitLabel: LABEL_SUBMIT,
    submitButtonProps: { disabled: !isEnabled },
    form,
    modal,
    schema,
    onSubmit,
    onSuccess,
    onSubmitConfirm,
  }

  return (
    <CreateModal {...createModalProps} />
  )
}

BatchFilesCreateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default BatchFilesCreateModal
