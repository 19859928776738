import { useState, useEffect, useCallback } from "react"
import { Form } from "antd"

import getInputs from "./getInputs"


const useInputs = ({
  form,
  isOpen,
  customer,
  groupOptions,
  businessTypes,
  classificationsMap
}) => {
  const [ step, setStep ] = useState(0)
  const [ schema, setSchema ] = useState([])

  const classificationGroup1 = Form.useWatch([ "businessCustomer", "classificationGroup" ], form)
  const classificationGroup2 = Form.useWatch([ "soleProprietorshipCustomer", "classificationGroup" ], form)

  const updateSchema = useCallback(currentStep => {
    const getClassificationGroupId = () => {
      if (classificationGroup1) {
        return classificationGroup1
      }

      if (classificationGroup2) {
        return classificationGroup2
      }

      return null
    }

    const classificationGroupId = getClassificationGroupId(classificationGroup1, classificationGroup2)
    const classificationOptions = classificationGroupId
      ? classificationsMap[classificationGroupId].options
      : []

    const goStep = toStep => {
      setStep(toStep)
      updateSchema(toStep)
    }

    const inputs = getInputs({
      form,
      goStep,
      customer,
      currentStep,
      updateSchema,
      groupOptions,
      businessTypes,
      classificationOptions
    })
    setSchema(inputs)
  }, [
    form,
    customer,
    groupOptions,
    businessTypes,
    classificationsMap,
    classificationGroup1,
    classificationGroup2
  ])

  useEffect(() => {
    if (!isOpen) {
      return
    }

    updateSchema(step)
  }, [
    step,
    isOpen,
    updateSchema
  ])

  return schema
}

export default useInputs
