import renderTag from "./renderTag"
import getSignStatusColor from "./getSignStatusColor"

const renderSignStatus = value => {
  const color = getSignStatusColor(value)

  const isSigned = value === "signed"
  const isPartiallySigned = value === "partiallySigned"

  if (isSigned) {
    return renderTag("Signed", color)
  }

  if (isPartiallySigned) {
    return renderTag("Partially Signed", color)
  }

  return renderTag("Pending", color)
}

export default renderSignStatus
