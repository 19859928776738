import React, { useState } from "react"
import { Switch } from "antd"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"

import { useAppContext } from "@components/AppContext"
import { updateInvestmentOperation } from "@api/services/investments"

const LABEL_ALLOW_NO_TIN = "Allow to fund without TIN"


const useItems = (investment, readInvestment) => {
  const { request } = useAppContext()
  const [ isLoading, setIsLoading ] = useState(false)

  const { id, isFundingUnlocked } = investment

  const mutation = { isFundingUnlocked: !isFundingUnlocked }

  const onChange = () => {
    setIsLoading(true)
    return request(updateInvestmentOperation, { id, mutation })
      .then(() => readInvestment(id))
      .then(() => setIsLoading(false))
  }

  const items = [
    {
      label: LABEL_ALLOW_NO_TIN,
      children: (
        <Switch
          loading={isLoading}
          checked={isFundingUnlocked}
          onChange={onChange}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      )
    }
  ]

  return items
}

export default useItems
