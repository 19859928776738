import React from "react"
import PropTypes from "prop-types"
import { Modal as AntModal } from "antd"

import { W1, W2, MODAL_WIDTH } from "@components"


const Modal = ({
  width = MODAL_WIDTH,
  title = undefined,
  footer = null,
  className = "",
  bodyStyle = undefined,
  isOpen,
  onClose,
  children,
  ...otherModalProps
}) => {
  const styles = {
    body: {
      marginTop: W1,
      ...(bodyStyle || {})
    }
  }

  const style = {
    top: W2,
    transform: "translateZ(0)"
  }

  const modalProps = {
    open: isOpen,
    onCancel: onClose,
    maskClosable: false,
    style,
    title,
    width,
    footer,
    styles,
    className,
    ...otherModalProps
  }

  return (
    <AntModal {...modalProps}>
      {children}
    </AntModal>
  )
}

Modal.propTypes = {
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.shape() ]),
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  footer: PropTypes.shape(),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  bodyStyle: PropTypes.shape(),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export default Modal
