import React, { useState, useRef, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Space, Typography, Spin } from "antd"

import PlaidModal from "@components/Plaid"

import getItems from "./helpers/getItems"
import CustomerShape from "../../shapes/CustomerShape"
import ConnectSourceModal from "../ConnectSourceModal"
import { CUSTOMER_STATUS_SUSPENDED } from "../../Constants"

const LABEL_PLACEHOLDER_CONNECT = "Connect"


const SourceSelectDropdown = ({
  sourceId = undefined,
  extraItems = [],
  onSelectSource: onSelectSourceCustom = undefined,
  isPlaidDisabled = false,
  isManualDisabled = false,
  connectSourceTitle = undefined,
  connectSourceHeader = undefined,
  onConnectPlaidSelected = undefined,
  isVerificationRequired = false,
  isOpenConnectSourceModal = false,
  onConnectConnectManuallySelected = undefined,
  customer,
  onSelect,
  isDropdownOpen,
  setIsDropdownOpen,
}) => {
  const plaidModal = useRef({})
  const connectSourceModal = useRef({})

  const [ isLoading, setIsLoading ] = useState(false)

  const {
    id: customerId,
    sources = [],
    name,
    verificationStatus,
  } = customer

  const isSuspended = verificationStatus === CUSTOMER_STATUS_SUSPENDED

  const currentSource = sources.find(source => source.id === sourceId)
  const isInvalid = currentSource?.isInvalid || isSuspended

  const openModal = useCallback(
    shouldUsePlaid => {
      const callback = () => {
        if (shouldUsePlaid) {
          return plaidModal.current.open(setIsLoading)
        }

        return onConnectConnectManuallySelected
          ? onConnectConnectManuallySelected(() => connectSourceModal.current.open())
          : connectSourceModal.current.open()
      }

      callback()
    }
  , [ onConnectConnectManuallySelected ])

  useEffect(() => {
    if (!isOpenConnectSourceModal) {
      return
    }

    openModal(false)
  }, [ isOpenConnectSourceModal, openModal ])

  const connectNewSource = async (targetSourceId) => {
    setIsLoading(true)
    const selectedSource = sources.find(source => source.id === targetSourceId)
    await onSelect(targetSourceId, selectedSource, false)
    setIsLoading(false)
  }

  const onSelectSource = targetSourceId => {
    const callback = () => connectNewSource(targetSourceId)

    if (!onSelectSourceCustom) {
      return callback()
    }

    return onSelectSourceCustom(currentSource, callback)
  }

  const items = getItems(name, openModal, onSelectSource, sources, sourceId, isPlaidDisabled, isManualDisabled)
  const hasOneItem = items.length === 1

  const callToAction = LABEL_PLACEHOLDER_CONNECT

  let dropdownTitle = currentSource?.name || callToAction

  dropdownTitle = isInvalid
    ? `${currentSource?.name} (Invalid)`
    : dropdownTitle

  const button = isInvalid
    ? (
        <Typography.Link>
          <Typography.Text type="danger">
            <Space>
              {dropdownTitle}
              <DownOutlined />
            </Space>
          </Typography.Text>
        </Typography.Link>
      )
    : (
        <Typography.Link>
          <Space>
            {dropdownTitle}
            <DownOutlined />
          </Space>
        </Typography.Link>
      )

  const shouldUsePlaid = !isPlaidDisabled

  const onClick = () =>
    openModal(shouldUsePlaid)

  const dropdownProps = {
    open: isDropdownOpen,
    menu: { items: [ ...items, ...extraItems ] },
    trigger: ['click'],
    onOpenChange: setIsDropdownOpen,
  }

  const dropdown = hasOneItem
    ? (
        <Typography.Link onClick={onClick}>
          {LABEL_PLACEHOLDER_CONNECT}
        </Typography.Link>
      )
    : (
        <Dropdown {...dropdownProps}>
          {button}
        </Dropdown>
      )

  const onExit = () =>
    setIsLoading(false)

  const onSuccess = async (selectedSourceId, selectedSource) => {
    setIsLoading(true)
    await onSelect(selectedSourceId, selectedSource)
    setIsLoading(false)
  }

  const connectSourceModalProps = {
    modal: connectSourceModal,
    onExit,
    onSuccess,
    customerId,
    isVerificationRequired,
    header: connectSourceHeader,
    title: connectSourceTitle
  }

  const plaidModalProps = {
    modal: plaidModal,
    onClose: onExit,
    onSuccess,
    customerId,
    onConnectPlaidSelected
  }

  return (
    <Spin spinning={isLoading}>
      {dropdown}

      <ConnectSourceModal
        {...connectSourceModalProps}
      />

      <PlaidModal
        {...plaidModalProps}
      />
    </Spin>
  )
}

SourceSelectDropdown.propTypes = {
  customer: CustomerShape.isRequired,
  sourceId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  extraItems: PropTypes.arrayOf(PropTypes.shape()),
  isDropdownOpen: PropTypes.bool.isRequired,
  onSelectSource: PropTypes.func,
  isPlaidDisabled: PropTypes.bool,
  isManualDisabled: PropTypes.bool,
  setIsDropdownOpen: PropTypes.func.isRequired,
  connectSourceTitle: PropTypes.string,
  connectSourceHeader: PropTypes.shape(),
  isVerificationRequired: PropTypes.bool,
  onConnectPlaidSelected: PropTypes.func,
  isOpenConnectSourceModal: PropTypes.bool,
  onConnectConnectManuallySelected: PropTypes.func,
}

export default SourceSelectDropdown
