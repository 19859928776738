import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { useAppContext } from "@components/AppContext"
import { PROJECT_STATUSES } from "@components/Domain"

import ProjectPartners from "./ProjectPartners"
import getCollectionItems from "./helpers/getCollectionItems"


const ProjectSponsors = ({ organizationName }) => {
  const [ items, setItems ] = useState([])

  const { identity } = useAppContext()
  const { organization: { parentOrganizationId } } = identity

  const { sponsors, project, brokers } = useOutletContext()
  const { status, id: projectId, partners = [] } = project

  const isPublishedProject = status === PROJECT_STATUSES.PUBLISHED

  const isChildFundOrganization = !!parentOrganizationId

  useEffect(() => {
    const sponsorsWithoutMain = sponsors
      .filter(({ name }) => name !== organizationName)

    const organizationSponsors = isChildFundOrganization
      ? sponsors
      : sponsorsWithoutMain

    const result = getCollectionItems(projectId, brokers, isPublishedProject, partners, organizationSponsors)
    setItems(result)
  }, [
    brokers,
    sponsors,
    partners,
    projectId,
    organizationName,
    isPublishedProject,
    isChildFundOrganization,
  ])

  return (
    <ProjectPartners
      items={items}
      setItems={setItems}
    />
  )
}

ProjectSponsors.propTypes = {
  organizationName: PropTypes.string.isRequired,
}

export default ProjectSponsors
