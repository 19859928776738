import PropTypes from "prop-types"

import InputShape from "./InputShape"


const OperationShape = PropTypes.exact({
  id: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  schema: PropTypes.arrayOf(InputShape),
  isCreate: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  getSchema: PropTypes.func,
  getIndexItemSchema: PropTypes.func,
})

export default OperationShape
