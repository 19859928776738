import React from "react"
import PropTypes from "prop-types"
import { Space, Button } from "antd"
import { CloseOutlined } from "@ant-design/icons"

import FilterSelect from "./FilterSelect"

const LABEL_RESET = "Reset"


const TableFilters = ({
  columns,
  onChange,
  resetFilters,
  getFilterValue,
  hasActiveFilters,
}) => {
  return (
    <Space>
      {
        columns.map(({ key, title: placeholder, dataIndex, filterMultiple = false, options = [] }) =>
          <FilterSelect
            key={key}
            value={getFilterValue(dataIndex)}
            options={options}
            onChange={value => onChange(dataIndex, value)}
            placeholder={placeholder}
            filterMultiple={filterMultiple}
          />
        )
      }

      {
        hasActiveFilters && (
          <Button
            type="text"
            size="small"
            icon={<CloseOutlined />}
            onClick={resetFilters}
          >
            {LABEL_RESET}
          </Button>
        )
      }
    </Space>
  )
}

TableFilters.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  getFilterValue: PropTypes.func.isRequired,
  hasActiveFilters: PropTypes.bool.isRequired,
}

export default TableFilters
