import React from "react"
import PropTypes from "prop-types"
import { Amplify } from "aws-amplify"
import { Authenticator } from "@aws-amplify/ui-react"

import { getConfig } from "@components/Config"
import {
  isInternalDomain,
  usePublicOrganization,
  PublicOrganizationContext,
} from "@components/AppContext"

import getFormFields from "./helpers/getFormFields"
import getQueryParams from "./helpers/getQueryParams"

import Header from './Header'
import Footer from './Footer'
import ConfirmSignUpHeader from "./ConfirmSignUpHeader"
import ConfirmSignUpFooter from "./ConfirmSignUpFooter"
import ConfirmSignInHeader from "./ConfirmSignInHeader"
import ConfirmSignInFooter from "./ConfirmSignInFooter"
import ResetPasswordHeader from './ResetPasswordHeader'
import ResetPasswordConfirmHeader from './ResetPasswordConfirmHeader'
import ResetPasswordConfirmFooter from "./ResetPasswordConfirmFooter"

import "@aws-amplify/ui-react/styles.css"
import "./AuthenticatorContext.css"
import validatePassword from "./helpers/validatePassword"
import PasswordRequirements from "./PasswordRequirements"

const PLATFORM_EMAIL = getConfig("PLATFORM_EMAIL")
const AUTHENTICATOR_CONFIG = getConfig("AUTHENTICATOR_CONFIG")
Amplify.configure(AUTHENTICATOR_CONFIG)

const LABEL_HELLO = 'Hello world!'
const LABEL_LEGACY_BROWSER = `You're using an outdated browser version. Please
update to the latest version or use another browser, like latest Chrome, or
Firefox. If you have questions contact`

// eslint-disable-next-line prefer-regex-literals
const PUBLIC_REGEX = new RegExp("^/scf/(thank-you|create/[^/]+/[^/]+)$")


const AuthenticatorContext = ({
  children
}) => {
  const publicOrganization = usePublicOrganization()

  const { name, logoUrl } = publicOrganization

  const isPublicScope = PUBLIC_REGEX.test(window.location.pathname)

  const { email, action: initialState } = getQueryParams()

  const components = {
    Header: () => Header(name, logoUrl),
    Footer: () => Footer(name),
    ResetPassword: {
      Header: ResetPasswordHeader,
    },
    ConfirmSignIn: {
      Header: ConfirmSignInHeader,
      Footer: ConfirmSignInFooter,
    },
    ConfirmSignUp: {
      Header: ConfirmSignUpHeader,
      Footer: ConfirmSignUpFooter,
    },
    ConfirmResetPassword: {
      Header: ResetPasswordConfirmHeader,
      Footer: ResetPasswordConfirmFooter,
    },
    SignUp: {
      FormFields() {
        return (
          <>
            <Authenticator.SignUp.FormFields />
            <PasswordRequirements />
          </>
        );
      },
    },
  }

  const isLegacyBrowser = !Object.hasOwn

  if (isLegacyBrowser) {
    return (
      <div style={{ margin: "4px 6px", fontSize: 14 }}>
        {LABEL_LEGACY_BROWSER} <a href={`mailto:${PLATFORM_EMAIL}`}>{PLATFORM_EMAIL}</a>.
      </div>
    )
  }

  const isInternal = isInternalDomain()

  if (isInternal) {
    return (
      <div style={{ margin: "4px 6px", fontSize: 14 }}>
        {LABEL_HELLO}
      </div>
    )
  }

  const formFields = getFormFields(email)
  const loginMechanisms = [ "email" ]

  if (isPublicScope) {
    return (
      <PublicOrganizationContext.Provider value={{ publicOrganization }}>
        {children}
      </PublicOrganizationContext.Provider>
    )
  }

  const services = {
    async validateCustomSignUp(formData) {
      return validatePassword(formData)
    },
  }

  return (
    <Authenticator
      components={components}
      services={services}
      formFields={formFields}
      initialState={initialState}
      loginMechanisms={loginMechanisms}
      /* td: Enable after backend is ready */
      // socialProviders={["google"]}
    >
      {
        () => (
          <PublicOrganizationContext.Provider value={{ publicOrganization }}>
            {children}
          </PublicOrganizationContext.Provider>
        )
      }
    </Authenticator>
  )
}

AuthenticatorContext.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthenticatorContext
