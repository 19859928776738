import { getFormattedDecimalValue } from "@components/Amount"
import { indexMyTransactionsOperation as indexOperation } from "@api/services/transactions"

const STATUS_FAILED = "FAILED"


const getFailedTransactionsAmount = async (request) => {
  const { data: failedTransactions } =
    await request(indexOperation, { status: STATUS_FAILED })

  const pendingFailedTransactions = failedTransactions
    .filter(({ retryTransactionId }) => !retryTransactionId)

  const totalAmount = pendingFailedTransactions
    .map(({ amount }) => amount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  return totalAmount
}

export default getFailedTransactionsAmount
