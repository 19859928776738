import React, { useRef } from "react"
import { Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import { W0, W1, W5 } from "@components"
import { BankAccountsTable, BankAccountCreateModal } from "@modules/backstage/bankAccounts"

const { Title } = Typography

const LABEL_TITLE = "Bank Accounts"


const SettingsBankAccountsPage = () => {
  const bankAccountCreateModal = useRef({})

  const { table } = useOutletContext()

  const openCreateModal = () =>
    bankAccountCreateModal.current.open()

  const style = {
    padding: W1 + W0,
    paddingTop: W1,
    paddingBottom: W5,
  }

  return (
    <div style={style}>
      <Title level={5} style={{ marginTop: 0 }}>
        {LABEL_TITLE}
      </Title>

      <BankAccountsTable
        table={table}
        openCreateModal={openCreateModal}
      />

      <BankAccountCreateModal
        modal={bankAccountCreateModal}
      />
    </div>
  )
}

export default SettingsBankAccountsPage
