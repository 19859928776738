
const getUpdateInputs = user => {
  const { correlationId } = user

  const inputs = [
    {
      name: "givenName",
      label: "First Name",
      itemProps: { style: { width: 270 } },
    },
    {
      name: "familyName",
      label: "Last Name",
      itemProps: { style: { width: 270 } },
    },
  ]

  if (correlationId) {
    inputs.push({
      name: "email",
      inputProps: { disabled: true },
      itemProps: { style: { width: 270 } },
    })

  } else {
    inputs.push({
      name: "email",
      itemProps: { style: { width: 270 } },
    })

  }

  inputs.push(
    {
      name: "mobile",
    }
  )

  return inputs
}

export default getUpdateInputs
