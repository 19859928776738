import { useState } from "react"
import dayjs from "dayjs"

const DEFAULT_OPERATION_PARAMETERS = { limit: 300 }


const getIndexOperationParameters = dateRange => {
  if (!dateRange) {
    const defaultStartDate = dayjs().subtract(14, 'day').toISOString()

    dateRange = [
      defaultStartDate,
      new Date().toISOString()
    ]
  }

  const [ firstDate, secondDate ] = dateRange

  const startDate = dayjs(firstDate).subtract(1, 'day').format('YYYY-MM-DD')
  const endDate = dayjs(secondDate).add(1, 'day').format('YYYY-MM-DD')

  return { startDate, endDate }
}


const useIndexOperationParameters = () => {
  const [ operationParameters, setOperationParameters ] = useState(DEFAULT_OPERATION_PARAMETERS)

  const updateOperationParameters = dateRange => {
    const parameters = getIndexOperationParameters(dateRange)
    setOperationParameters(parameters)
  }

  return [
    operationParameters,
    updateOperationParameters
  ]
}

export default useIndexOperationParameters
