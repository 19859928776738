import { getFormattedDecimalValue } from "@components/Amount"
import {
  INVESTMENT_PROFILE_TYPE_ENTITY,
  INVESTMENT_PROFILE_TYPE_INDIVIDUAL,
  INVESTMENT_PROFILE_TYPE_JOINT,
  INVESTMENT_PROFILE_TYPE_SDIRA,
  INVESTMENT_STATUS_ACTIVE,
  INVESTMENT_STATUS_BACKUP,
  INVESTMENT_STATUS_REJECTED,
} from "@components/Domain"


const getProjectStatistics = (project = null, investments = []) => {
  if (!project.id) {
    return {}
  }

  const { targetAmount = 0 } = project

  const activeInvestments = investments
    .filter(({ status }) => status === INVESTMENT_STATUS_ACTIVE)

  const raisedFundingAmount = activeInvestments
    .map(({ totalAmount }) => totalAmount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  const notFundedInvestmentsCount = activeInvestments
    .filter(({ receivedAmount }) => receivedAmount === 0)
    .length

  const fundedInvestmentsCount = activeInvestments
    .filter(({ isFunded }) => isFunded)
    .length

  const fundedAmount = activeInvestments
    .filter(({ isFunded }) => isFunded)
    .map(({ totalAmount }) => totalAmount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  const partiallyFundedInvestmentsCount = activeInvestments
    .filter(({ isPartiallyFunded }) => isPartiallyFunded)
    .length

  const partiallyFundedAmount = activeInvestments
    .filter(({ isPartiallyFunded }) => isPartiallyFunded)
    .map(({ totalAmount }) => totalAmount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  const overfundedInvestmentsCount = activeInvestments
    .filter(({ isOverfunded }) => isOverfunded)
    .length

  const overfundedAmount = activeInvestments
    .filter(({ isOverfunded }) => isOverfunded)
    .map(({ totalAmount }) => totalAmount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  const receivedFundingAmount = activeInvestments
    .map(({ receivedAmount }) => receivedAmount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  const getTiersData = () => {
    const tiers = project.tiers.map(({ name, id }) => {
      return {
        id,
        name,
        value: 0
      }
    })

    const investmentByTiers = tiers
      .reduce((_, value) => ({ ..._, [value.name]: value.value }), {})

    const investmentByTiersCount = tiers
      .reduce((_, value) => ({ ..._, [value.name]: 0 }), {})

    for (const investment of activeInvestments) {
      investment.investments.map(({ projectTierId, amount }) => {
        investmentByTiers[projectTierId] += amount
        investmentByTiersCount[projectTierId] += 1
      })
    }

    return tiers.map(({name}) => ({
      name,
      value: investmentByTiers[name],
      count: investmentByTiersCount[name]
    }))
  }

  const getCountAccreditedInvestors = hasAccreditation =>
    activeInvestments
      .filter(({ investor }) => investor.hasAccreditation === hasAccreditation)
      .length

  const getAmountByType = type =>
    activeInvestments
      .filter(({ profileType }) => profileType === type)
      .map(({ totalAmount }) => totalAmount)
      .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  const getCountByType = type =>
    activeInvestments
      .filter(({ profileType }) => profileType === type)
      .length

  const countSigned = investments
    .filter(({ isSigned }) => isSigned).length

  const countFunded = investments
    .filter(({ isFunded }) => isFunded).length

  const getTotalsByStatus = () => {
    const result = {}

    const statuses = [
      INVESTMENT_STATUS_ACTIVE,
      INVESTMENT_STATUS_BACKUP,
      INVESTMENT_STATUS_REJECTED,
    ]

    const getCountByStatus = statusValue =>
      investments
        .filter(({ status }) => status === statusValue)
        .length

    const getAmountByStatus = statusValue =>
      investments
        .filter(({ status }) => status === statusValue)
        .map(({ totalAmount }) => totalAmount)
        .reduce((_, a) => _ + a, 0)

    statuses.map(status => {
      result[status] = {}
      result[status].count = getCountByStatus(status)
      result[status].amount = getAmountByStatus(status)
    })

    return result
  }

  return {
    fundingAmount: targetAmount,
    raisedFundingAmount,
    receivedFundingAmount,
    fundedInvestmentsCount,
    fundedAmount,
    partiallyFundedInvestmentsCount,
    partiallyFundedAmount,
    overfundedInvestmentsCount,
    overfundedAmount,
    notFundedInvestmentsCount,
    countSigned,
    countFunded,
    tiersAmount: getTiersData(),
    profileTypes: {
      [INVESTMENT_PROFILE_TYPE_INDIVIDUAL]: getAmountByType(INVESTMENT_PROFILE_TYPE_INDIVIDUAL),
      [INVESTMENT_PROFILE_TYPE_JOINT]: getAmountByType(INVESTMENT_PROFILE_TYPE_JOINT),
      [INVESTMENT_PROFILE_TYPE_ENTITY]: getAmountByType(INVESTMENT_PROFILE_TYPE_ENTITY),
      [INVESTMENT_PROFILE_TYPE_SDIRA]: getAmountByType(INVESTMENT_PROFILE_TYPE_SDIRA),
      [INVESTMENT_PROFILE_TYPE_SDIRA]: getAmountByType(INVESTMENT_PROFILE_TYPE_SDIRA),
    },
    profileTypesCount: {
      [INVESTMENT_PROFILE_TYPE_INDIVIDUAL]: getCountByType(INVESTMENT_PROFILE_TYPE_INDIVIDUAL),
      [INVESTMENT_PROFILE_TYPE_JOINT]: getCountByType(INVESTMENT_PROFILE_TYPE_JOINT),
      [INVESTMENT_PROFILE_TYPE_ENTITY]: getCountByType(INVESTMENT_PROFILE_TYPE_ENTITY),
      [INVESTMENT_PROFILE_TYPE_SDIRA]: getCountByType(INVESTMENT_PROFILE_TYPE_SDIRA),
      [INVESTMENT_PROFILE_TYPE_SDIRA]: getCountByType(INVESTMENT_PROFILE_TYPE_SDIRA),
    },
    accreditedInvestorsCount: getCountAccreditedInvestors(true),
    nonAccreditedInvestorsCount: getCountAccreditedInvestors(false),
    totalByInvestmentStatus: getTotalsByStatus()
  }
}

export default getProjectStatistics
