import PropTypes from "prop-types"


const properties = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const ListTemplatesOutputShapeShape = PropTypes.exact(properties)

export default ListTemplatesOutputShapeShape
export { properties }
