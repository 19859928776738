import keyBy from "lodash.keyby"

import getInvestmentsMatch from "./getInvestmentsMatch"


const matchInvestments = async (request, investments, fileNames) => {
  const match = await getInvestmentsMatch(request, investments, fileNames)

  const matches = fileNames
    .map(fileName => ({ ...match(fileName), fileName }))

  const matchesMap = keyBy(matches, "investmentId")

  const results = investments
    .map(investment => {
      const fileMatch = matchesMap[investment.id]
      return { ...investment, sourceFileName: fileMatch?.fileName }
    })

  return results
}

export default matchInvestments
