import React, { useState } from "react"
import keyBy from "lodash.keyby"
import PropTypes from "prop-types"
import { Typography } from "antd"

import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"
import { CUSTOMER_STATUS_SUSPENDED } from "@components/Dwolla/Constants"

import SourceSelectDropdown from "./SourceSelectDropdown"
import CustomerCreateButton, { useCustomer } from "../CustomerCreateButton"

const { Text } = Typography

const LABEL_IMPERSONATED = "Disabled for impersonated user"
const LABEL_CUSTOMER_SUSPENDED = "ACH Account Suspended"


const ConnectSourceButton = ({
  sourceId = undefined,
  investment = undefined,
  businessName = undefined,
  dropdownProps = {},
  isReceiveOnly = false,
  impersonatedInvestorAccountId = undefined,
  onSelect,
}) => {
  const [ isDropdownOpen, setIsDropdownOpen ] = useState(false)
  const [ isOpenConnectSourceModal, setIsOpenConnectSourceModal ] = useState(false)

  const { isImpersonated } = useAppContext()

  const customer = useCustomer(businessName, impersonatedInvestorAccountId)

  const onSourceSelect = async (
    selectedSourceId,
    newSource,
    isNewSource = true
  ) => {
    const sourcesMap = keyBy(customer.sources, "id")
    const sourceName = newSource?.name || sourcesMap[selectedSourceId].name

    await onSelect(selectedSourceId, sourceName, isNewSource)
  }

  const connectSourceManually = () =>
    setIsOpenConnectSourceModal(true)

  let isPlaidDisabled = false

  let onSuccess = () =>
    setIsDropdownOpen(true)

  if (impersonatedInvestorAccountId) {
    onSuccess = () =>
      connectSourceManually()
  }

  if (isReceiveOnly) {
    isPlaidDisabled = true

    onSuccess = () =>
      connectSourceManually()
  }

  if (isImpersonated) {
    return (
      <Text strong>{LABEL_IMPERSONATED}</Text>
    )
  }

  if (!customer) {
    const customerCreateButtonProps = {
      onSuccess,
      businessName,
      isReceiveOnly,
      impersonatedInvestorAccountId,
    }

    return (
      <CustomerCreateButton
        {...customerCreateButtonProps}
      />
    )
  }

  const { verificationStatus } = customer
  const isCustomerSuspended = verificationStatus === CUSTOMER_STATUS_SUSPENDED

  if (isCustomerSuspended) {
    return (
      <Text type="danger">
        {LABEL_CUSTOMER_SUSPENDED}
      </Text>
    )
  }

  const sourceSelectDropdownProps = {
    onSelect: onSourceSelect,
    customer,
    sourceId,
    investment,
    isPlaidDisabled,
    isDropdownOpen,
    setIsDropdownOpen,
    isOpenConnectSourceModal,
    ...dropdownProps
  }

  return (
    <SourceSelectDropdown
      {...sourceSelectDropdownProps}
    />
  )
}

ConnectSourceButton.propTypes = {
  onSelect: PropTypes.func.isRequired,
  sourceId: PropTypes.string,
  investment: InvestmentShape,
  businessName: PropTypes.string,
  isReceiveOnly: PropTypes.bool,
  dropdownProps: PropTypes.shape(),
  impersonatedInvestorAccountId: PropTypes.string,
}

export default ConnectSourceButton
