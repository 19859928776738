import { App } from "antd"
import { useOutletContext } from "react-router-dom"

import confirmAction from "@components/Page/helpers/confirmAction"
import { useAppContext } from "@components/AppContext"

import useSendNotifications from "./useSendNotifications"
import computeInvestmentName from "../../helpers/computeInvestmentName"

const DELETE = "DELETE"
const BACKUP = "BACKUP"
const ACCEPT = "ACCEPT"

const ACTIONS_MAP = {
  [ACCEPT]: {
    title: "Accept Investment",
    action: "Accept",
    content: 'Investment "$" moves to active list again, investor is not notified.',
    success: 'Investment "$" is active again',
  },
  [DELETE]: {
    type: "danger",
    title: "Delete Investment",
    action: "Delete",
    content: 'Investment "$" to be deleted, investor is not notified.',
    success: 'Investment "$" is deleted',
  },
  [BACKUP]: {
    title: "Backup Investment",
    action: "Backup",
    content: 'Investment "$" moves to the backup list, investor is not notified.',
    success: 'Investment "$" is moved to the backup list',
  },
  NO_ACCREDITATION: {
    type: "danger",
    title: "Drop Investment",
    action: "Drop",
    content: 'Drop investment "$" due to lack of accreditation, investor would be notified via email.',
    success: 'Investment "$" is dropped',
    templateDisabledContent: `By clicking “Drop” below, the investment will be
marked as dropped. The email notifications will not be sent to the investor as
email notifications for project dropouts are currently turned off.`
  },
  DEADLINE_PASSED: {
    type: "danger",
    title: "Drop Investment",
    action: "Drop",
    content: 'Drop investment "$" due to deadline has passed, investor would be notified via email.',
    success: 'Investment "$" is dropped',
    templateDisabledContent: `By clicking “Drop” below, the investment will be
marked as dropped. The email notifications will not be sent to the investor as
email notifications for project dropouts are currently turned off.`
  },
  NO_REASON: {
    type: "danger",
    title: "Drop Investment",
    action: "Drop",
    content: 'Drop investment "$", investor is not notified.',
    success: 'Investment "$" is dropped',
  },
  NOT_INTERESTED: {
    type: "danger",
    title: "Drop Investment",
    action: "Drop",
    content: 'Drop investment "$" due to lack of interest from investor, investor would be notified via email.',
    success: 'Investment "$" is dropped',
    templateDisabledContent: `By clicking “Drop” below, the investment will be
marked as dropped. The email notifications will not be sent to the investor as
email notifications for project dropouts are currently turned off.`
  }
}


const useInvestmentActions = investment => {
  const { modal } = App.useApp()

  const { showSuccessMessage } = useAppContext()

  const {
    deleteInvestment,
    acceptInvestment,
    backupInvestment,
    rejectInvestment,
    projectTemplatesStore,
    computeAccountFullname,
  } = useOutletContext()

  const {
    isInvestmentRejectedNotInterestedTemplateEnabled,
    isInvestmentRejectedDeadlinePassedTemplateEnabled,
    isInvestmentRejectedNoAccreditationTemplateEnabled,
  } = projectTemplatesStore

  const sendNotifications = useSendNotifications(investment)

  const { id } = investment
  const name = computeInvestmentName(computeAccountFullname, investment)

  const executeAction = (actionName, executeOperation) => {
    const {
      templateDisabledContent,
      content: templateEnabledContent
    } = ACTIONS_MAP[actionName]

    let content = templateEnabledContent.replace("$", name)

    const isNotInterestedReason = actionName === 'NOT_INTERESTED'
    const isDeadlinePassedReason = actionName === 'DEADLINE_PASSED'
    const isNoAccreditationReason = actionName === 'NO_ACCREDITATION'

    if (isNotInterestedReason) {
      content = isInvestmentRejectedNotInterestedTemplateEnabled()
        ? content
        : templateDisabledContent
    }

    if (isDeadlinePassedReason) {
      content = isInvestmentRejectedDeadlinePassedTemplateEnabled()
        ? content
        : templateDisabledContent
    }

    if (isNoAccreditationReason) {
      content = isInvestmentRejectedNoAccreditationTemplateEnabled()
        ? content
        : templateDisabledContent
    }

    return confirmAction(
      modal,
      ACTIONS_MAP[actionName].action,
      ACTIONS_MAP[actionName].title,
      content,
      () =>
        executeOperation()
          .then(() => {
            const message = ACTIONS_MAP[actionName].success.replace("$", name)
            showSuccessMessage(message)
          })
          .then(() => sendNotifications(actionName))
      ,
      ACTIONS_MAP[actionName].type
    )
  }

  const acceptAction = () =>
    executeAction(ACCEPT, () => acceptInvestment(id))

  const backupAction = () =>
    executeAction(BACKUP, () => backupInvestment(id))

  const rejectAction = (_, rejectionReason) =>
    executeAction(rejectionReason, () => rejectInvestment(id, rejectionReason))

  const deleteAction = () => {
    const { type, action, title, content, success } = ACTIONS_MAP.DELETE

    confirmAction(
      modal,
      action,
      title,
      content.replace("$", name),
      () =>
        deleteInvestment(id)
          .then(() => {
            const message = success.replace("$", name)
            showSuccessMessage(message)
          })
      ,
      type
    )
  }

  return {
    deleteAction,
    acceptAction,
    backupAction,
    rejectAction,
  }
}

export default useInvestmentActions
