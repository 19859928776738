import PropTypes from "prop-types"

import UsAddressShape from "./UsAddressShape"

const properties = {
  address: UsAddressShape.isRequired,
  dateOfBirth: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  ssn: PropTypes.string
}

const PersonalCustomerShape = PropTypes.exact(properties)

export default PersonalCustomerShape
export { properties }
