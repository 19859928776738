import React from "react"
import PropTypes from "prop-types"
import { Descriptions } from "antd"

import useItems from "./helpers/useItems"
import { InvestmentShape } from "../../../../shapes"


const FundingDescriptions = ({ investment, readInvestment }) => {
  const items = useItems(investment, readInvestment)

  const descriptionsProps = {
    size: "small",
    column: 1,
    bordered: true,
    className: "compact black",
    labelStyle: { width: "30%" },
    items,
  }

  return (
    <Descriptions {...descriptionsProps} />
  )
}

FundingDescriptions.propTypes = {
  investment: InvestmentShape.isRequired,
  readInvestment: PropTypes.func.isRequired,
}

export default FundingDescriptions
