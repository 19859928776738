import keyBy from "lodash.keyby"

import { JOINT, SDIRA, ENTITY, INDIVIDUAL } from "@api/services/investments/shapes/ProfileTypeEnum"

const PROFILE_TYPES = [
  JOINT,
  SDIRA,
  ENTITY,
  INDIVIDUAL
]


const getProfilesMap = (isFund, profiles = [], investments = []) => {
  const profileMap = {}

  const investmentsMapByName = keyBy(investments, "name")

  const isProfileDisabled = (profileName) => {
    const investment = investmentsMapByName[profileName]

    if (!isFund) {
      const isDisabled = !!investment

      return isDisabled
    }

    if (!investment) {
      return false
    }

    // NOTE: Check if investment is signed and funded, otherwise investment
    //       has to be completed first.

    const { isSigned, isFunded } = investment

    const isComplete = isSigned && isFunded

    if (isComplete) {
      return false
    }

    return true
  }

  for (const profileType of PROFILE_TYPES) {
    profileMap[profileType] = profiles
      .filter(profile => profile.profileType === profileType)
      .map(profile => {
        return {
          ...profile,
          disabled: isProfileDisabled(profile.name),
        }
      })
  }

  return profileMap
}

export default getProfilesMap
