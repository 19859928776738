import React from "react"
import PropTypes from "prop-types"
import { Descriptions } from "antd"

import TierShape from "@api/services/backstage/shapes/TierShape"
import { EditButton } from "@components/Button"

import InvestmentShape from "./shapes/InvestmentShape"
import renderInvestmentItems from "./helpers/renderInvestmentItems"

const { Item } = Descriptions


const Investment = ({
  title = undefined,
  onEdit = undefined,
  editPath = undefined,
  extraItems = [],
  descriptionsProps = {},
  tiers,
  investment,
}) => {
  const items = renderInvestmentItems(tiers, investment)

  let extra
  const hasExtra = !!editPath || !!onEdit

  if (hasExtra) {
    extra = <EditButton path={editPath} onClick={onEdit} />
  }

  return (
    <Descriptions
      bordered
      size="middle"
      title={title}
      extra={extra}
      column={1}
      labelStyle={{ width: "40%" }}
      {...descriptionsProps}
    >
      {
        [...items, ...extraItems].map(({ label, value }, key) => (
          <Item
            key={key}
            label={label}
          >
            {value}
          </Item>
        ))
      }
    </Descriptions>
  )
}

Investment.propTypes = {
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.shape() ]),
  tiers: PropTypes.arrayOf(TierShape).isRequired,
  onEdit: PropTypes.func,
  editPath: PropTypes.string,
  investment: InvestmentShape.isRequired,
  extraItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.shape() ]).isRequired,
  })),
  descriptionsProps: PropTypes.shape(),
}

export default Investment
