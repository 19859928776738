import React from "react"

import { TagsInput } from "../../Input"


const renderTags = (itemProps, inputProps, form, path) => {
  const componentProps = {
    form,
    path,
    itemProps,
    inputProps,
  }

  return (
    <TagsInput {...componentProps} />
  )
}

export default renderTags
