import React from "react"
import { Alert } from "antd"

import { W1 } from "@components"
import { TYPE_STRING } from "@components/Form"

const LABEL_INFO = `[INVESTOR] would receive an invitation email that contains a
temporary password. Upon the initial sign-in, they will be prompted to create a
new, secure password.`
const LABEL_EMAIL = "Email"


const getInputs = (account) => {
  const { givenName, familyName, email } = account.investor

  const fullName = `${givenName} ${familyName}`

  const emailProps = {
    name: "_email",
    type: TYPE_STRING,
    label: LABEL_EMAIL,
    initialValue: email,
    inputProps: {
      disabled: true,
    }
  }

  const alertProps = {
    name: "_alert",
    component: () => (
      <Alert
        type="info"
        style={{ marginBottom: W1 }}
        message={LABEL_INFO.replace('[INVESTOR]', fullName)}
      />
    )
  }

  const inputs = [
    { ...alertProps },
    { ...emailProps },
  ]

  return inputs
}

export default getInputs
