import React from "react"
import { NavLink } from "react-router-dom"
import {
  BarsOutlined,
  SwapOutlined,
  LogoutOutlined,
  SettingOutlined,
  DesktopOutlined,
  FastForwardOutlined,
} from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"
import { getConfig } from "@components/Config"
import { useAppContext } from "@components/AppContext"
import { useOrganizations } from "@components/Organization"

const consoleOrganizationId = getConfig("consoleOrganizationId")

const BACKSTAGE_PATH = "/backstage"

const LABEL_CONSOLE = "Console"
const LABEL_SIGNOUT = "Sign Out"
const LABEL_PROJECTS = "Archived Projects"
const LABEL_SETTINGS = "Settings"
const LABEL_SWITCH_TO = "Switch To"
const LABEL_GO_TO_INVESTMENT = "Go To Investment"


const useMenuItems = ({ openOrganizationsModal, openGoToInvestmentModal }) => {
  const {
    hasConsoleAccess,
    switchToOrganization,
  } = useAppContext()


  const organizations = useOrganizations()

  const hasSettings = hasAccess(['org-read'])
  const hasArchivedProjects = hasAccess(['projects-read'])

  const items = []

  items.push({
    key: "investment",
    icon: <FastForwardOutlined />,
    label: LABEL_GO_TO_INVESTMENT,
    onClick: openGoToInvestmentModal,
  })

  if (hasArchivedProjects) {
    items.push({
      key: "projects",
      icon: <BarsOutlined />,
      label: (
        <NavLink to={`${BACKSTAGE_PATH}/projects/archived`}>
          {LABEL_PROJECTS}
        </NavLink>
      )
    })
  }

  if (hasSettings) {
    items.push({
      key: "settings",
      icon: <SettingOutlined />,
      label: (
        <NavLink to={`${BACKSTAGE_PATH}/settings/organization`}>
          {LABEL_SETTINGS}
        </NavLink>
      )
    })
  }

  if (hasConsoleAccess) {
    items.push({
      key: "console",
      icon: <DesktopOutlined />,
      label: (
        <NavLink onClick={() => switchToOrganization(consoleOrganizationId)}>
          {LABEL_CONSOLE}
        </NavLink>
      ),
    })
  }

  const hasItems = items.length > 0

  if (hasItems) {
    items.push({
      type: 'divider',
    })
  }

  const hasOtherOrganizationsAccess = organizations.length > 1

  if (hasOtherOrganizationsAccess) {
    items.push({
      key: "switch",
      icon: <SwapOutlined />,
      label: LABEL_SWITCH_TO,
      onClick: openOrganizationsModal,
    })
  }

  items.push({
    key: 'logout',
    icon: <LogoutOutlined />,
    label: (
      <NavLink to="/logout/backstage">
        {LABEL_SIGNOUT}
      </NavLink>
    )
  })

  return items
}

export default useMenuItems
