import React  from "react"
import PropTypes from "prop-types"

import { UnderstandConfirmModal } from "@components/Page"

const LABEL_TITLE = "Connect Plaid"
const LABEL_CONTENT = `You will see a prompt from Plaid
(a third-party banking service provider that works with most banking 
institutions in the United States) that will request you to sign into 
your bank account.

Once you sign in, you will select the bank account that you want 
to receive distributions.

After you connect your account, the Plaid popup window should disappear.`

const DistributionsConfirmModal = ({ modal }) => {
  return (
    <UnderstandConfirmModal
      modal={modal}
      content={LABEL_CONTENT}
      title={LABEL_TITLE}
    />
  )
}

DistributionsConfirmModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default DistributionsConfirmModal
