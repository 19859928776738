import { extendSchema } from "@api"


const getDwollaAddressSchema = addressSchema => {
    const newInputs = extendSchema(addressSchema, [
    {
      name: "streetAddress",
      maxLength: 100,
    }
  ])

  return newInputs
}

export default getDwollaAddressSchema
