import PropTypes from "prop-types"


const properties = {
  urls: PropTypes.arrayOf(PropTypes.string).isRequired
}

const CreateUploadUrlsOutputShapeShape = PropTypes.exact(properties)

export default CreateUploadUrlsOutputShapeShape
export { properties }
