import React from "react"
import { NavLink, useParams, useNavigate, useOutletContext } from "react-router-dom"
import {
  DeleteOutlined,
  LockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  DollarCircleOutlined
} from "@ant-design/icons"

import { wait } from "@api"
import { hasAccess } from "@components/Authorization"
import { PROJECT_STATES } from "@components/Domain"

import useScfMenuItem from "./useScfMenuItem"
import useProjectActions from "./useProjectActions"
import useValidateProject from "./useValidateProject"

const LABEL_OPEN = "Publish"
const LABEL_DRAFT = "Unpublish"
const LABEL_CLOSE = "Close"
const LABEL_DELETE = "Delete Project"
const LABEL_ARCHIVE = "Archive"
const LABEL_UNARCHIVE = "Unarchive"


const useHeaderMenu = projectStore => {
  const navigate = useNavigate()

  const { id: projectId } = useParams()

  const {
    getSponsor,
    updateProject,
    deleteProject,
  } = useOutletContext()

  const {
    project = {},
    investments = [],
    projectTemplatesStore,
    readProject
  } = projectStore

  const { partners = [] } = project

  const { isProjectClosedNonFundTemplateEnabled } = projectTemplatesStore

  const projectSponsorIds = partners
    .map(({ sponsorId }) => sponsorId)
    .filter(notNone => !!notNone)

  const projectSponsors = projectSponsorIds
    .map(sponsorId => getSponsor(sponsorId))
    .filter(notNone => !!notNone)

  const getScfMenuItem = useScfMenuItem(projectId, projectSponsors)

  const afterAction = async (_, updatedProject) => {
    const isDeleteAction = !updatedProject

    if (!isDeleteAction) {
      await readProject()
      return updateProject(updatedProject)
    }

    /* note: delete should happen after redirect otherwise issue in project
             when project is deleted */
    navigate('/backstage')
    await wait()

    deleteProject(projectId)
  }

  const {
    closeProject: close,
    deleteProject: destroy,
    publishProject: publish,
    archiveProject: archive,
    unpublishProject: unpublish,
    unarchiveProject: unarchive,
    publishProjectWithWarnings,
    publishProjectWithoutPpm,
    publishProjectWithoutBankAccount
  } = useProjectActions({
    project,
    investments,
    afterAction,
    isProjectClosedNonFundTemplateEnabled,
  })

  const onPublishClick = useValidateProject({
    project,
    publish,
    readProject,
    publishProjectWithoutPpm,
    publishProjectWithWarnings,
    publishProjectWithoutBankAccount,
  })

  const { state } = project

  const isHold = state === PROJECT_STATES.HOLD
  const isOpen = state === PROJECT_STATES.OPEN
  const isClosed = state === PROJECT_STATES.CLOSED
  const isArchived = state === PROJECT_STATES.ARCHIVED

  const items = []

  if (isOpen) {
    const scfMenuItem = getScfMenuItem()
    items.push(scfMenuItem)
  }

  const canDeleteProject = hasAccess(['projects-delete'])
  const canPublishProject = hasAccess(['projects-write'])
  const canArchiveProject = hasAccess(['projects-archive'])
  const canChangeProjectState = hasAccess(['projects-write'])

  if (canChangeProjectState) {
    const hasItems = items.length > 0
    const hasPublishAction = isHold && canPublishProject

    if (hasItems) {
      items.push({
        type: "divider",
      })
    }

    if (hasPublishAction) {
      items.push({
        key: "open",
        icon: <EyeOutlined />,
        label: (
          <NavLink onClick={onPublishClick}>
            {LABEL_OPEN}
          </NavLink>
        )
      })
    }

    if (isOpen) {
      items.push({
        key: "draft",
        icon: <EyeInvisibleOutlined />,
        label: (
          <NavLink onClick={unpublish}>
            {LABEL_DRAFT}
          </NavLink>
        )
      })

      items.push({
        key: "close",
        icon: <LockOutlined />,
        label: (
          <NavLink onClick={close}>
            {LABEL_CLOSE}
          </NavLink>
        )
      })
    }

    if (isClosed) {
      if (canPublishProject) {
        items.push({
          key: "open",
          icon: <EyeOutlined />,
          label: (
            <NavLink onClick={publish}>
              {LABEL_OPEN}
            </NavLink>
          )
        })
      }

      if (canArchiveProject) {
        items.push({
          key: "archive",
          icon: <DollarCircleOutlined />,
          label: (
            <NavLink onClick={archive}>
              {LABEL_ARCHIVE}
            </NavLink>
          )
        })
      }
    }

    if (isArchived) {
      items.push({
        key: "unarchive",
        icon: <LockOutlined />,
        label: (
          <NavLink onClick={unarchive}>
            {LABEL_UNARCHIVE}
          </NavLink>
        )
      })
    }
  }

  if (canDeleteProject) {
    const hasItems = items.length > 0

    if (hasItems) {
      items.push({
        type: "divider",
      })
    }

    items.push({
      key: "delete",
      icon: <DeleteOutlined />,
      danger: true,
      disabled: !isHold,
      label: (
        <NavLink onClick={destroy}>
          {LABEL_DELETE}
        </NavLink>
      )
    })
  }

  return items
}

export default useHeaderMenu
