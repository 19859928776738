import React, { useRef } from "react"
import PropTypes from "prop-types"
import { theme } from "antd"

const DEFAULT_HEIGHT = 400


const Redactor = ({
  height = DEFAULT_HEIGHT,
  content = "",
  options = {},
  bordered = true,
  onChange = () => {},
}) => {
  const iframe = useRef({})

  const {
    token: {
      colorBorder,
      borderRadius
    }
  } = theme.useToken()

  const onLoad = () => {
    // eslint-disable-next-line no-console
    console.info("Redactor.onLoad")

    const app = iframe.current.contentWindow.app.initialize({
      ...options,
      content,
      subscribe: {
        'editor.change': event => {
          const html = app.editor.getContent()
          const email = app.editor.getEmail()

          onChange(html, email)
        }
      },
    })
  }

  const style = {}

  if (bordered) {
    style.border = `1px solid ${colorBorder}`
    style.borderRadius = borderRadius
  }

  const iframeProps = {
    src: "/static/redactor.html",
    width: "100%",
    height: `${height}px`,
    frameBorder: "0",
    style,
    onLoad,
  }

  return (
    <div>
      <iframe
        ref={iframe}
        title="redactor"
        {...iframeProps}
      />
    </div>
  )
}

Redactor.propTypes = {
  height: PropTypes.number,
  options: PropTypes.shape(),
  content: PropTypes.string,
  onChange: PropTypes.func,
  bordered: PropTypes.bool,
}

export default Redactor
