import React from "react"
import { Tag, Row, Col, Typography } from "antd"

import { W1, DRAWER_WIDTH3 } from "@components"
import { InvestmentShape } from "../shapes"


const Title = ({
  investment = undefined
}) => {
  if (!investment) {
    return null
  }

  const { name, id } = investment

  const title = (
    <Typography.Title level={5} style={{ margin: 0, maxWidth: `${DRAWER_WIDTH3 - 180}px` }} ellipsis>
      {name}
    </Typography.Title>
  )

  return (
    <Row gutter={[ W1, W1 ]}>
      <Col flex="auto">
        {title}
      </Col>
      <Col>
        <Tag bordered={false}>
          {id}
        </Tag>
      </Col>
    </Row>
  )
}

Title.propTypes = {
  investment: InvestmentShape,
}

export default Title
