import { isLocal, LOCAL_DOMAIN } from "@components"


const isSignInAsEnabled = identity => {
  const { organization } = identity
  const { domain } = organization

  const isEnabled =
    window.location.hostname === domain ||
    (isLocal && domain === LOCAL_DOMAIN)

  return isEnabled
}

export default isSignInAsEnabled
