import PropTypes from "prop-types"

import { AssetTypeEnumKeys } from "./AssetTypeEnum"

const properties = {
  id: PropTypes.string.isRequired,
  lastModified: PropTypes.number.isRequired,
  mimeType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  thumb: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(AssetTypeEnumKeys).isRequired,
  url: PropTypes.string.isRequired
}

const AssetShape = PropTypes.exact(properties)

export default AssetShape
export { properties }
