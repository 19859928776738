import capitalize from "lodash.capitalize"


const getSuccessMessage = (isCreateOperation, entity, item = {}) => {
  let entityName = ""

  const { name } = item

  if (name ) {
    entityName = `"${name}" `
  }

  entity = capitalize(entity)
  const successMessage = isCreateOperation ?
    `${entity} ${entityName}is created` :
    `${entity} ${entityName}is updated`

  return successMessage
}

export default getSuccessMessage
