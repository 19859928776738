import { useParams } from "react-router-dom"

import { getCustomRequest } from "@components/Import"

import parseDistributions from "./parseDistributions"


const useImportDistributions = (investments, callbackSuccess, setErrors, callbackUpload) => {
  const { id: projectId } = useParams()

  const { parseRow, validateRow, buildRow } = parseDistributions(investments, setErrors, projectId)

  const customRequest = getCustomRequest(parseRow, validateRow, buildRow, callbackSuccess, callbackUpload)

  return { customRequest }
}

export default useImportDistributions
