import React from "react"
import { Routes, Route } from "react-router-dom"
import { ConfigProvider, App as AntApp } from "antd"

import { AppContext, useAppMessage, getThemeToken } from "@components/AppContext"
import { IndexRedirect, ScrollToTop, ResourceNotFoundRedirect } from "@components/Page"
import Authorization, { LogoutRedirect, useAuthorizationStore } from "@components/Authorization"

import BackstageLayout from "@layouts/backstage/BackstageLayout"
import BackstageProjectLayout from "@layouts/backstage/BackstageProjectLayout"
import BackstageSettingsLayout from "@layouts/backstage/BackstageSettingsLayout"

import InvestorLayout from "@layouts/investor/InvestorLayout"
import InvestorProjectLayout from "@layouts/investor/InvestorProjectLayout"
import InvestorInvestmentLayout from "@layouts/investor/InvestorInvestmentLayout"

import ThankYouPage from "@modules/investor/commitment/ThankYouPage"
import CreateCommitmentPage from "@modules/investor/commitment/CreateCommitmentPage"

import InvestmentAccountPage from "@modules/investor/investment/InvestmentAccountPage"
import InvestmentProfilesPage from "@modules/investor/investment/InvestmentProfilesPage"
import InvestmentFinalizePage from "@modules/investor/investment/InvestmentFinalizePage"

import InvestorAccountPage from "@modules/investor/InvestorAccountPage"
import InvestorDashboardPage from "@modules/investor/InvestorDashboardPage"
import InvestorDocumentsPage from "@modules/investor/InvestorDocumentsPage"
import InvestorTransactionsPage from "@modules/investor/InvestorTransactionsPage"
import InvestorProjectDetailsPage from "@modules/investor/InvestorProjectDetailsPage"
import InvestorProjectInvestmentsPage from "@modules/investor/InvestorProjectInvestmentsPage"

import AccountsPage from "@modules/backstage/investors/AccountsPage"
import ActivitiesPage from "@modules/backstage/activities/ActivitiesPage"
import PropertiesPage from "@modules/backstage/properties/PropertiesPage"
import TransactionsPage from "@modules/backstage/transactions/TransactionsPage"
import ArchivedProjectsPage from "@modules/backstage/projects/ArchivedProjectsPage"
import { CampaignsPage, CampaignUpdatePage } from "@modules/backstage/campaigns"

import ProjectDetailsPage from "@modules/backstage/projects/ProjectDetailsPage"
import ProjectDocumentsPage from "@modules/backstage/projects/ProjectDocumentsPage"
import ProjectInvestmentsPage from "@modules/backstage/projects/ProjectInvestmentsPage"
import ProjectDistributionsPage from "@modules/backstage/projects/ProjectDistributionsPage"

import SettingsMembersPage from "@modules/backstage/settings/SettingsMembersPage"
import SettingsBrokersPage from "@modules/backstage/settings/SettingsBrokersPage"
import SettingsBankAccountsPage from "@modules/backstage/settings/SettingsBankAccountsPage"
import SettingsOrganizationPage from "@modules/backstage/settings/SettingsOrganizationPage"
import SettingsEmailIdentityPage from "@modules/backstage/settings/SettingsEmailIdentityPage"

import ConsoleLayout from "@layouts/console/ConsoleLayout"

import UsersPage from "@modules/console/users/UsersPage"
import ReportsPage from "@modules/console/reports/ReportsPage"
import CustomersPage from "@modules/console/customers/CustomersPage"
import UserGroupsPage from "@modules/console/groups/UserGroupsPage"
import OrganizationsPage from "@modules/console/organizations/OrganizationsPage"
import OrganizationsListPage from "@modules/console/organizations/OrganizationsListPage"

import "./App.css"

const token = getThemeToken()


const App = () => {
  const [
    messageContextHolder,
    showErrorMessage,
    showSuccessMessage,
    showLoadingMessage
  ] = useAppMessage()

  const authorizationStore = useAuthorizationStore({
    showErrorMessage,
    showLoadingMessage,
    messageContextHolder,
  })

  const { isAppReady } = authorizationStore

  if (!isAppReady) {
    return (
      <Authorization
        authorizationStore={authorizationStore}
      />
    )
  }

  const context = {
    showErrorMessage,
    showLoadingMessage,
    showSuccessMessage,
    ...authorizationStore,
  }

  const renderInvestorPortal = () =>
    <Route path="/" element={<InvestorLayout />}>
      <Route index element={<IndexRedirect to="dashboard" />} />

      <Route path="dashboard" element={<InvestorDashboardPage />} />

      <Route path="investments/:projectId" element={<InvestorInvestmentLayout />}>
        <Route index element={<InvestmentAccountPage />} />
        <Route path="create" element={<InvestmentProfilesPage />} />
        <Route path="update/:investmentId?" element={<InvestmentProfilesPage />} />
        <Route path="sign" element={<InvestmentFinalizePage />} />
        <Route path="sign/:investmentId" element={<InvestmentFinalizePage />} />
      </Route>

      <Route path="projects/:projectId" element={<InvestorProjectLayout />}>
        <Route path="about" element={<InvestorProjectDetailsPage />} />
        <Route path="documents" element={<InvestorDocumentsPage />} />
        <Route path="investments" element={<InvestorProjectInvestmentsPage />} />
        <Route path="distributions" element={<InvestorTransactionsPage />} />
      </Route>

      <Route path="account" element={<InvestorAccountPage />} />
      <Route path="documents" element={<InvestorDocumentsPage />} />
      <Route path="transactions" element={<InvestorTransactionsPage />} />
    </Route>

  const renderOrganizationBackstage = () =>
    <Route path="/backstage" element={<BackstageLayout {...context} />}>
      <Route index element={<IndexRedirect to="investors" />} />

      <Route path="investors" element={<AccountsPage />} />
      <Route path="investors/create" element={<AccountsPage />} />
      <Route path="investors/:investorId" element={<AccountsPage />} />

      <Route path="transactions" element={<TransactionsPage />} />

      <Route path="activities" element={<ActivitiesPage />} />

      <Route path="properties" element={<PropertiesPage />} />

      <Route path="projects/archived" element={<ArchivedProjectsPage />} />

      <Route path="settings" element={<BackstageSettingsLayout />}>
        <Route path="sources" element={<SettingsBankAccountsPage /> } />
        <Route path="members" element={<SettingsMembersPage /> } />
        <Route path="brokers" element={<SettingsBrokersPage /> } />
        <Route path="organization" element={<SettingsOrganizationPage /> } />
        <Route path="email-identity" element={<SettingsEmailIdentityPage /> } />
      </Route>

      <Route path="project/:id" element={<BackstageProjectLayout />}>
        <Route path="details" element={<ProjectDetailsPage /> } />
        <Route path="documents" element={<ProjectDocumentsPage /> } />
        <Route path="investments" element={<ProjectInvestmentsPage /> } />
        <Route path="investments/:investmentsId" element={<ProjectInvestmentsPage /> } />
        <Route path="distributions" element={<ProjectDistributionsPage /> } />
      </Route>

      <Route path="campaigns" element={<CampaignsPage />} />
      <Route path="campaigns/:campaignId" element={<CampaignUpdatePage />} />
    </Route>

  const renderConsoleBackstage = () =>
    authorizationStore.isConsoleAdministrator
      ? renderConsoleAdministratorBackstage()
      : renderConsoleManagerBackstage()

  const renderConsoleManagerBackstage = () =>
    <Route path="/backstage" element={<OrganizationsListPage />} />

  const renderConsoleAdministratorBackstage = () =>
    <Route path="/backstage" element={<ConsoleLayout {...context} />}>
      <Route index element={<IndexRedirect to="organizations" />} />

      <Route path="organizations" element={<OrganizationsPage />} />
      <Route path="customers" element={<CustomersPage />} />
      <Route path="groups" element={<UserGroupsPage />} />
      <Route path="users" element={<UsersPage />} />
      <Route path="activities" element={<ActivitiesPage />} />
      <Route path="reports" element={<ReportsPage />} />
    </Route>

  const renderBackstage = () =>
    authorizationStore.isConsoleOrganization
      ? renderConsoleBackstage()
      : renderOrganizationBackstage()

  return (
    <React.StrictMode>
      <AntApp>
        <ScrollToTop />

        <ConfigProvider theme={{ token }}>
          {messageContextHolder}

          <AppContext context={context}>
            <Routes>
              <Route path="/logout/*" element={<LogoutRedirect />} />

              <Route path="/scf/thank-you" element={<ThankYouPage />} />
              <Route path="/scf/create/:projectId/:sponsorId" element={<CreateCommitmentPage />} />

              {renderInvestorPortal()}
              {renderBackstage()}

              <Route path="*" element={<ResourceNotFoundRedirect />} />
            </Routes>
          </AppContext>
        </ConfigProvider>
      </AntApp>
    </React.StrictMode>
  )
}

export default App
