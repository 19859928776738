

const getRecordItems = (recordJson) => {
  if (!recordJson) {
    return {}
  }

  const record = JSON.parse(recordJson)

  return record
}

export default getRecordItems
