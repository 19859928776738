import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { useForm } from "@components/Form"
import { UpdateModal } from "@components/Page"
import {
  readUserGroupOperation as readOperation,
  updateUserGroupOperation as updateOperation,
} from "@api/services/backstage"

const LABEL_ENTITY = "Group"
const LABEL_TITLE = `Edit ${LABEL_ENTITY}`
const LABEL_SUBMIT = "Save"


const UserGroupUpdateModal = ({ modal }) => {
  const form = useForm()

  const { updateUserGroup } = useOutletContext()

  const onSuccess = updatedUserGroup =>
    updateUserGroup(updatedUserGroup)

  const title = LABEL_TITLE
  const entity = LABEL_ENTITY
  const schema = updateOperation.getSchema([])

  const createModalProps = {
    operation: updateOperation,
    submitLabel: LABEL_SUBMIT,
    form,
    modal,
    title,
    entity,
    schema,
    onSuccess,
    readOperation,
  }

  return (
    <UpdateModal {...createModalProps} />
  )
}

UserGroupUpdateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default UserGroupUpdateModal
