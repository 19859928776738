import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { Collapse } from "antd"

import "./CollapseTable.css"

const DEFAULT_KEY = '1'


const CollapseTable = ({
  items = undefined,
  isOpenByDefault = true,
  title,
  children,
}) => {
  const [ activeKey, setActiveKey ] = useState(isOpenByDefault ? DEFAULT_KEY : null)

  const autoCollapse = useCallback(() => {
    const hasItems = items?.length > 0

    if (hasItems) {
      return
    }

    if (isOpenByDefault) {
      return
    }

    setActiveKey(null)
  }, [ isOpenByDefault, items ])

  useEffect(() => {
    autoCollapse()
  }, [ autoCollapse ])

  const onChange = target =>
    setActiveKey(target)

  const size = "small"
  const ghost = true
  const className = "collapse-table"

  const collapsible = items?.length === 0 && !isOpenByDefault
    ? "disabled"
    : "header"

  const collapseItems = [
    {
      key: DEFAULT_KEY,
      label: title,
      children,
    }
  ]

  const collapseProps = {
    items: collapseItems,
    size,
    ghost,
    onChange,
    className,
    activeKey,
    collapsible,
  }

  return (
    <Collapse {...collapseProps} />
  )
}

CollapseTable.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()),
  isOpenByDefault: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export default CollapseTable
