import React from "react"
import PropTypes from "prop-types"
import { Space } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

import Small from "./Small"


const Disclaimer = ({ text, style: styleCustom = {} }) => {
  const style = {
    marginTop: 4,
    lineHeight: 1.2,
    alignItems: "start",
    ...styleCustom
  }

  return (
    <Space style={style}>
      <ExclamationCircleOutlined />
      <Small>{text}</Small>
    </Space>
  )
}

Disclaimer.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.shape()
}

export default Disclaimer
