import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

import AppContextShape from "./shapes/AppContextShape"

const Context = createContext({})
const useAppContext = () => useContext(Context)


const AppContext = ({
  context,
  children
}) => {
  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
}

AppContext.propTypes = {
  context: AppContextShape.isRequired,
  children: PropTypes.node.isRequired,
}

export default AppContext

export {
  useAppContext
}
