import React from "react"
import PropTypes from "prop-types"

import { Drawer } from "@components/Page"
import { MODAL_WIDTH2 } from "@components"
import { useAppContext } from "@components/AppContext"
import { Form, useForm } from "@components/Form"
import {
  ReadTransactionOutputShape,
  updateRejectedTransactionAmountOperation as updateOperation
} from "@api/services/transactions"

import getUpdateInputs from "./helpers/getUpdateInputs"

const LABEL_TITLE = "Edit Transaction"
const LABEL_UPDATE = "Save"


const TransactionUpdateDrawer = ({
  drawer,
  onSuccess,
  transaction,
}) => {
  const form = useForm()

  const { request } = useAppContext()

  const inputs = getUpdateInputs()
  const schema = updateOperation.getSchema(inputs)

  const onAfterSubmit = async (updatedTransaction) => {
    await onSuccess(updatedTransaction)

    drawer.current.close()
  }

  const drawerProps = {
    title: LABEL_TITLE,
    width: MODAL_WIDTH2,
    drawer,
  }

  const formProps = {
    item: transaction,
    onSuccess: onAfterSubmit,
    operation: updateOperation,
    submitLabel: LABEL_UPDATE,
    form,
    schema,
    request,
  }

  return (
    <Drawer {...drawerProps}>
      <Form {...formProps} />
    </Drawer>
  )
}

TransactionUpdateDrawer.propTypes = {
  drawer: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
  transaction: ReadTransactionOutputShape.isRequired,
}

export default TransactionUpdateDrawer
