import React, { useRef } from "react"

import { W0, W1, W5 } from "@components"

import ProjectDetailsPageContext from "./ProjectDetailsPageContext"


const ProjectDetailsPage = () => {
  const projectUpdateModal = useRef({})
  const propertyCreateModal = useRef({})
  const propertyUpdateModal = useRef({})
  const propertyConnectModal = useRef({})

  const openProjectUpdateModal = (onlyFieldNames, modalWidth) =>
    projectUpdateModal.current.open(onlyFieldNames, modalWidth)

  const openPropertyUpdateModal = propertyId =>
    propertyUpdateModal.current.openWithProperties(propertyId)

  const openPropertyCreateModal = () =>
    propertyCreateModal.current.openWithProperties()

  const openPropertyConnectModal = () =>
    propertyConnectModal.current.open()

  const context = {
    openProjectUpdateModal,
    openPropertyCreateModal,
    openPropertyUpdateModal,
    openPropertyConnectModal,
  }

  const style = {
    padding: `${W1 + W0}px ${W1 + W0}px ${W5}px`
  }

  const contextProps = {
    context,
    projectUpdateModal,
    propertyCreateModal,
    propertyUpdateModal,
    propertyConnectModal,
  }

  return (
    <div style={style}>
      <ProjectDetailsPageContext {...contextProps} />
    </div>
  )
}

export default ProjectDetailsPage
