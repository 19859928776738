import { useState, useEffect, useCallback } from "react"

import { useAppContext } from "@components/AppContext"
import { readMyCustomerOperation } from "@api/services/transactions"


const useBusinessClassificationOptions = (customerId, shouldUpdate = false) => {
  const [ customer, setCustomer ] = useState()
  const [ groupOptions, setGroupOptions ] = useState([])
  const [ classificationsMap, setClassificationsMap ] = useState({})

  const { authenticatedRequest } = useAppContext()

  const setOptions = useCallback(() =>
    authenticatedRequest(readMyCustomerOperation, { id: customerId })
      .then(({ data }) => {
        const newClassificationsMap = {}

        const { businessClassificationGroups: groups } = data

        for (const group of groups) {
          const { id, name, classifications } = group

          const options = classifications
            .map(({ id: value, name: label }) => ({ label, value }))

          newClassificationsMap[id] = { name, options }
        }

        const newGroupOptions = Object
          .keys(newClassificationsMap)
          .map(id => ({
            label: newClassificationsMap[id].name,
            value: id
          }))

        setGroupOptions(newGroupOptions)
        setClassificationsMap(newClassificationsMap)
        setCustomer(data)
      })
  , [ authenticatedRequest, customerId ])

  useEffect(() => {
    if (!shouldUpdate) {
      return
    }

    setOptions()
  }, [ shouldUpdate, setOptions ])

  return [ groupOptions, classificationsMap, customer ]
}

export default useBusinessClassificationOptions
