import React from "react"
import PropTypes from "prop-types"

import { TableRowActions } from "@components/Table"

import useRowMenu from "./helpers/useRowMenu"
import useActions from "./helpers/useActions"
import { UserShape } from "../../shapes"


const RowActions = ({
  user,
  openUpdateModal
}) => {
  const actions = useActions(user)
  const items = useRowMenu(user, { ...actions, openUpdateModal })

  return (
    <TableRowActions items={items} />
  )
}

RowActions.propTypes = {
  user: UserShape.isRequired,
  openUpdateModal: PropTypes.func.isRequired,
}

export default RowActions
