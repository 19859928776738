import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Form, Radio, Space, Typography } from "antd"

import { ItemPropsShape, InputPropsShape } from "@api"

import FormShape from "../shapes/FormShape"

const { Text } = Typography


const RadioInput = ({
  options: optionsParam,
  form,
  itemProps,
  inputProps,
}) => {
  const [ options, setOptions ] = useState([])

  const {
    disabled: isGroupDisabled = false,
    onChange: onChangeCustom,
    ...restInputProps
  } = inputProps

  const isArray = Array.isArray(optionsParam)

  const isWatcher = !isArray && typeof optionsParam === "object"

  const { fieldName: watchPath } = optionsParam

  const watchField = Form.useWatch(watchPath, form)

  let watchFieldValue

  if (isWatcher) {
    watchFieldValue = watchField
  }

  useEffect(() => {
    if (!isArray) { return }

    setOptions(optionsParam)
  }, [
    isArray,
    optionsParam,
  ])

  useEffect(() => {
    if (!isWatcher) { return }

    const { getOptions } = optionsParam
    const computedOptions = getOptions(form, watchFieldValue)

    setOptions(computedOptions)
  }, [
    form,
    isWatcher,
    watchField,
    optionsParam,
    watchFieldValue,
  ])

  const isStringOptions = typeof options === "string"

  if (isStringOptions) {
    throw new Error(`Radio input type doesn't support string option type: ${options}`)
  }

  const onChange = value =>
    onChangeCustom
      ? onChangeCustom(value, form)
      : {}

  return (
    <>
      {
        options.length > 0 && (
          <Form.Item {...itemProps}>
            <Radio.Group disabled={isGroupDisabled}>
              <Space
                direction="vertical"
                onChange={onChange}
                {...restInputProps}
              >
                {
                  options.map(({ label, value, description, disabled = false }, index) => (
                    <Radio
                      key={index}
                      value={value}
                      disabled={isGroupDisabled ? isGroupDisabled : disabled}
                    >
                      <Space
                        direction="vertical"
                        size={0}
                      >
                        {label}
                        {
                          description && (
                            <Text type="secondary">
                              {description}
                            </Text>
                          )
                        }
                      </Space>
                    </Radio>
                  ))
                }
              </Space>
            </Radio.Group>
          </Form.Item>
        )
      }
    </>
  )
}

RadioInput.propTypes = {
  form: FormShape.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape())
  ]).isRequired,
  itemProps: ItemPropsShape.isRequired,
  inputProps: InputPropsShape.isRequired,
}

export default RadioInput
