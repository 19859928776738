
const TERMINATOR_1 = '<td class="email-content"'
const TERMINATOR_2 = '</td></tr></table></td></tr></table></body></html>'


const unwrapEmail = (html = "") => {
  const [, partial1 = "" ] = html.split(TERMINATOR_1)

  const [, ...partials ] = partial1.split('>')
  const partial2 = partials.join('>')

  const [ content ] = partial2.split(TERMINATOR_2)

  return content
}

export default unwrapEmail
