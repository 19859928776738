import React from "react"

import { Value } from "@components/Text"
import { hasAccess } from "@components/Authorization"
import { TransactionStatusTag } from "@components/Transaction"
import { getFormattedDecimalValue } from "@components/Amount"
import { getUsDateFromDate, getUsDateFromIsoDate } from "@components/Date"

import RowActions from "../RowActions"

const LABEL_AMOUNT_MIN_VALUE = "Minimum amount is $1"


const getColumnsConfig = ({
  createTransaction: addTransactionAction,
  deleteTransaction: deleteTransactionAction,
  collection,
  isInvestmentFundedTemplateEnabled
}) => {
  const isEditable = hasAccess(['transactions-write'])

  const dateColumnProps = {
    title: "Date",
    editable: false,
    dataIndex: "date",
    render: date => date
      ? getUsDateFromIsoDate(date)
      : getUsDateFromDate(new Date()),
  }

  const descriptionColumnProps = {
    title: "Description",
    editable: isEditable,
    dataIndex: "description",
    render: (_, {description, details}) => description || details,
    input: {
      type: "string",
      placeholder: "Add transaction description...",
      itemProps: {
        rules: [
          { required: false }
        ]
      }
    }
  }

  const amountColumnProps = {
    title: "Amount",
    render: amount => <Value value={amount}/>,
    editable: isEditable,
    dataIndex: "amount",
    width: 170,
    input: {
      type: "amount",
      required: true,
      itemProps: {
        rules: [
          {
            message: LABEL_AMOUNT_MIN_VALUE,
            validator: async (_, value) => {
              const formattedValue = getFormattedDecimalValue(value, 100, "times")
              const isValid = formattedValue > 0

              if (!isValid) {
                throw new Error(LABEL_AMOUNT_MIN_VALUE)
              }
            }
          }
        ]
      },
      inputProps: {
        style: { width: "100%" }
      }
    }
  }

  const statusColumnProps = {
    title: "Status",
    editable: false,
    dataIndex: "status",
    render: (status, transaction) => {
      if (!status) {
        return
      }

      return <TransactionStatusTag transaction={transaction} />
    }
  }

  const columns = [
    { ...dateColumnProps, width: 100 },
    { ...descriptionColumnProps },
    { ...amountColumnProps },
    { ...statusColumnProps },
  ]

  if (!isEditable) {
    return columns
  }

  const actionsColumnProps = {
    title: "",
    editable: false,
    dataIndex: "operation",
    render: (_, record) =>
      <RowActions
        record={record}
        collection={collection}
        isDeletable={record.isExternal || false}
        addTransactionAction={addTransactionAction}
        deleteTransactionAction={deleteTransactionAction}
        isInvestmentFundedTemplateEnabled={isInvestmentFundedTemplateEnabled}
      />
  }

  return [
    ...columns,
    { ...actionsColumnProps, width: 52 },
  ]
}

export default getColumnsConfig
