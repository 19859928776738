import React, { useMemo } from "react"
import PropTypes from "prop-types"
import keyBy from "lodash.keyby"
import { Space, Typography } from "antd"

import TierShape from "@api/services/backstage/shapes/TierShape"
import { Value } from "@components/Text"
import TierInvestmentShape from "@api/services/investments/shapes/TierInvestmentShape"

const { Text } = Typography

const LABEL_CLASS = "Class"


const ProfileInvestments = ({ investments, tiers }) => {
  const tiersMap = useMemo(() => keyBy(tiers, "name"), [ tiers ])

  const getClassName = id =>
    tiersMap[id].title || `${LABEL_CLASS} ${tiersMap[id].name}`

  const getClassDescription = id =>
    tiersMap[id].description

  return (
    <Space
      size={0}
      direction="vertical"
    >
      {
        investments.map(({ projectTierId, amount }, key) =>
          <Space
            key={key}
            size={0}
            direction="vertical"
          >
            <Space>
              <Text strong>
                {getClassName(projectTierId)}
              </Text>
              <span>—</span>
              <Value value={amount} />
            </Space>

            <Text type="secondary">
              {getClassDescription(projectTierId)}
            </Text>
          </Space>
        )
      }
    </Space>
  )
}

ProfileInvestments.propTypes = {
  tiers: PropTypes.arrayOf(TierShape).isRequired,
  investments: PropTypes.arrayOf(TierInvestmentShape).isRequired,
}

export default ProfileInvestments
