
const validateForm = async (collectionRef, form) => {
  const isCollectionError = await collectionRef.current.hasFormError()
  const isFormError = await form.validateFields()
    .then(() => true)
    .catch(() => false)

  if (!isCollectionError || !isFormError) return false

  return true
}

export default validateForm
