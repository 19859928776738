import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Spin } from "antd"

import { Value } from "@components/Text"
import { readMyCustomerOperation } from "@api/services/transactions"
import { runRequest, useAppContext } from "@components/AppContext"

import { useCustomer } from "../CustomerCreateButton"


const CustomerBalance = ({ customerName }) => {
  const [ amount, setAmount ] = useState()
  const [ isLoading, setIsLoading ] = useState(false)

  const { authenticatedRequest } = useAppContext()

  const customer = useCustomer(customerName)

  useEffect(() => {
    if (!customer) {
      return
    }

    const { id } = customer

    return runRequest(() => {
      setIsLoading(true)
      authenticatedRequest(readMyCustomerOperation, { id })
        .then(({ data }) => setAmount(data.balanceAmount))
        .then(() => setIsLoading(false))
    })
  }, [
    customer,
    authenticatedRequest
  ])

  if (isLoading) {
    return <Spin />
  }

  return (
    <Value value={amount} />
  )
}

CustomerBalance.propTypes = {
  customerName: PropTypes.string.isRequired,
}

export default CustomerBalance
