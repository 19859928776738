import operations from "@api/operations.json"
import httpMethods from "@api/httpMethods"


const getMethod = (operationUrl) => {
  const [ , , , , service, operationId ] = operationUrl.split('/')

  const operation = `${service}/${operationId}`
  const isSupported = operations.includes(operation)

  if (!isSupported) {
    const message = `Missing operation "${operation}", add it to ` +
      `"src/api/operations.json" and run "npm run update".`

    console.error(message)

    throw new Error(message)
  }

  const key = `${service}/${operationId}`
  const method = httpMethods[key]

  if (!method) {
    const message = `Method is not defined for "${operation}", add it to` +
      `"src/api/operations.json" and run "npm run update".`

    console.error(message)

    throw new Error(message)
  }

  return method
}

export default getMethod
