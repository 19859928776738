import { getFormattedDecimalValue } from "@components/Amount"
import {
  REJECTED,
  ERROR,
  FAILED,
  CANCELLED
} from "@api/services/transactions/shapes/TransactionStatusEnum"
import {
  FUNDING,
  CAPITAL_RETURN,
  INVESTMENT_RETURN,
  SALE_PROFIT,
  SPONSOR_PROMOTE
} from "@api/services/transactions/shapes/TransactionTypeEnum"

const SKIP_STATUSES = [
  REJECTED,
  ERROR,
  FAILED,
  CANCELLED
]


const computeTransactionsTotal = (types, transactions) => transactions
  .filter(({ status }) => !SKIP_STATUSES.includes(status))
  .filter(({ type }) => types.includes(type))
  .map(({ amount }) => amount)
  .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)


const computeFundingTransactionsTotal = transactions =>
   computeTransactionsTotal([ FUNDING ], transactions)

const computeDistributionsTransactionsTotal = transactions =>
  computeTransactionsTotal([ CAPITAL_RETURN, INVESTMENT_RETURN, SALE_PROFIT, SPONSOR_PROMOTE ], transactions)

export {
  computeFundingTransactionsTotal,
  computeDistributionsTransactionsTotal,
}
