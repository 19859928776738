import React from "react"
import { Alert } from "antd"

import { W1 } from "@components"
import { TYPE_STRING } from "@components/Form"

const LABEL_INFO = `By clicking "Reset Password" below, you will reset the
password for the user. The user will receive an email with a temporary password
that they can use to log in to the portal.`

const LABEL_EMAIL = "Email"


const getInputs = (account) => {
  const { email } = account.investor

  const emailProps = {
    name: "_email",
    type: TYPE_STRING,
    label: LABEL_EMAIL,
    initialValue: email,
    inputProps: {
      disabled: true,
    }
  }

  const alertProps = {
    name: "_alert",
    component: () => (
      <Alert
        type="info"
        style={{ marginBottom: W1 }}
        message={LABEL_INFO}
      />
    )
  }

  const inputs = [
    { ...alertProps },
    { ...emailProps },
  ]

  return inputs
}

export default getInputs
