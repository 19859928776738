import keyBy from "lodash.keyby"

import operationRequest from "@components/Authorization/request/operationRequest"
import { indexBankAccountsOperation } from "@api/services/transactions"


const getOrganizationBankAccounts = async (authorization) => {
  const parameters = {
    operation: indexBankAccountsOperation,
    headers: { authorization },
  }

  const { data: bankAccounts } = await operationRequest(parameters)
  const bankAccountsMap = keyBy(bankAccounts, "id")

  return { bankAccounts, bankAccountsMap }
}

export default getOrganizationBankAccounts
