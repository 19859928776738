import PropTypes from "prop-types"

import { projectStoreProperties } from "@components/Project/stores/useProjectStore"
import { backstageStoreProperties } from "@components/Store/BackstageStore/useBackstageStore"


const BackstageProjectLayoutContextShape = PropTypes.exact({
  table: PropTypes.shape().isRequired,
  layoutTopOffset: PropTypes.number.isRequired,
  ...backstageStoreProperties,
  ...projectStoreProperties,
})

export default BackstageProjectLayoutContextShape
