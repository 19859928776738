import React from "react"
import { Typography, Space } from "antd"

import { W0 } from "@components"
import { ACCREDITATION_REFERENCE_URL } from "@components/Domain"

const { Text, Link } = Typography

const LABEL_ACCREDITATION_NOTE = `
A person is considered an accredited investor if (1) net worth exceeds $1
million, either individually or jointly with spouse or (2) have an annual
income exceeding $200,000, or $300,000 for joint income, for the last two
years. To find out more about accredited investors click here
`


const AccreditationNote = () => {
  return (
    <Space
      size={0}
      style={{ marginTop: W0, lineHeight: 1.2 }}
      direction="vertical"
    >
      <Text
        type="secondary"
        style={{ fontSize: 12 }}
      >
        {LABEL_ACCREDITATION_NOTE}:{' '}
      </Text>
      <Link
        href={ACCREDITATION_REFERENCE_URL}
        style={{ fontSize: 12 }}
        target="_blank"
      >
        {ACCREDITATION_REFERENCE_URL}
      </Link>
    </Space>
  )
}

export default AccreditationNote
