import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { Typography, Spin, Tag, Space } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

import { useAppContext } from "@components/AppContext"

import CustomerShape from "../../shapes/CustomerShape"
import UploadDocumentsModal from "../UploadDocumentsModal"
import DocumentsReviewStatus from "../DocumentsReviewStatus"
import useBeneficialOwnersStore from "./stores/useBeneficialOwnersStore"
import CertifyBeneficialOwnershipModal from "./CertifyBeneficialOwnershipModal"
import { BusinessCustomerUpgradeModal } from "../../CustomerUpgradeButton"
import {
  CUSTOMER_STATUS_RETRY,
  CUSTOMER_STATUS_DOCUMENT,
  CUSTOMER_STATUS_VERIFIED,
  CUSTOMER_STATUS_SUSPENDED,
  CUSTOMER_STATUS_UNCERTIFIED,
  CUSTOMER_STATUS_PENDING_DOCUMENT_VERIFICATION
} from "../../Constants"

const LABEL_VERIFY = "Verification Required"
const LABEL_VERIFIED = "Verified"
const LABEL_SUSPENDED = "Suspended"


const BusinessCustomerVerificationButton = ({
  isModalOpen: shouldOpenInitialModal = false,
  title = undefined,
  onSuccess = () => {},
  customer,
}) => {
  const modal = useRef({})

  const [ isLoading, setIsLoading ] = useState(false)
  const [ isModalOpen, setIsModalOpen ] = useState(shouldOpenInitialModal)

  const {
    authenticatedRequest,
    updateMyCustomerVerificationStatus
  } = useAppContext()

  const { id: customerId } = customer

  const {
    beneficialOwners,
    indexBeneficialOwners
  } = useBeneficialOwnersStore(customerId, shouldOpenInitialModal)

  const updateCustomerVerificationStatus = async () => {
    setIsLoading(true)

    const updatedCustomer = await updateMyCustomerVerificationStatus(authenticatedRequest, customer.id)

    const isCustomerVerified = updatedCustomer.verificationStatus === CUSTOMER_STATUS_VERIFIED

    if (isCustomerVerified) {
      await onSuccess(updatedCustomer)
    }

    setIsLoading(false)
  }

  const { verificationStatus: status } = customer

  const isRetry = status === CUSTOMER_STATUS_RETRY
  const isDocument = status === CUSTOMER_STATUS_DOCUMENT
  const isVerified = status === CUSTOMER_STATUS_VERIFIED
  const isSuspended = status === CUSTOMER_STATUS_SUSPENDED
  const isUncertified = status === CUSTOMER_STATUS_UNCERTIFIED
  const isPendingDocumentVerification = status === CUSTOMER_STATUS_PENDING_DOCUMENT_VERIFICATION

  const onClick = async () => {
    await updateCustomerVerificationStatus()
    await indexBeneficialOwners()

    setIsModalOpen(true)

    modal.current.open()
  }

  if (isVerified) {
    return <Tag color="green">{LABEL_VERIFIED}</Tag>
  }

  if (isSuspended) {
    return <Tag color="red">{LABEL_SUSPENDED}</Tag>
  }

  if (isPendingDocumentVerification) {
    return (
      <DocumentsReviewStatus />
    )
  }

  title = title || (
    <Space>
      <ExclamationCircleOutlined />
      <span>{LABEL_VERIFY}</span>
    </Space>
  )

  return (
    <>
      <Spin spinning={isLoading}>
        <Typography.Link onClick={onClick}>
          <span style={{ whiteSpace: "nowrap" }}>
            {title}
          </span>
        </Typography.Link>
      </Spin>

      {
        isDocument && (
          <UploadDocumentsModal
            modal={modal}
            customer={customer}
            onSuccess={updateCustomerVerificationStatus}
            isModalOpen={isModalOpen}
          />
        )
      }

      {
        isUncertified && (
          <CertifyBeneficialOwnershipModal
            modal={modal}
            customer={customer}
            onSuccess={updateCustomerVerificationStatus}
            isModalOpen={isModalOpen}
            beneficialOwners={beneficialOwners}
            indexBeneficialOwners={indexBeneficialOwners}
          />
        )
      }

      {
        isRetry && (
          <BusinessCustomerUpgradeModal
            modal={modal}
            isRetry={true}
            onSuccess={updateCustomerVerificationStatus}
            customerId={customerId}
          />
        )
      }
    </>
  )
}

BusinessCustomerVerificationButton.propTypes = {
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.shape() ]),
  customer: CustomerShape.isRequired,
  onSuccess: PropTypes.func,
  isModalOpen: PropTypes.bool,
}

export default BusinessCustomerVerificationButton
