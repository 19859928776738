import React, { useMemo } from "react"
import PropTypes from "prop-types"

import { isMobile } from "@components"
import { PROJECT_TYPES } from "@components/Domain"

import ProjectsList from "./ProjectsList"
import ProjectsTable from "./ProjectsTable"
import { ExtendedProjectShape } from "../helpers/useGroupedProjects"


const filterOutFundsWithOnePendingInvestment = projects =>
  projects
    .filter(project =>
      project.type !== PROJECT_TYPES.FUND ||
        project.investments
          .filter(({ isFunded }) => isFunded).length > 0
    )


const InvestmentProjects = ({ title, projects }) => {
  const filteredProjects = useMemo(() =>
    filterOutFundsWithOnePendingInvestment(projects)
  , [ projects ])

  const hasProjects = filteredProjects.length

  if (!hasProjects) {
    return null
  }

  if (isMobile) {
    return <ProjectsList title={title} items={filteredProjects} />
  }

  return <ProjectsTable title={title} items={filteredProjects} />
}

InvestmentProjects.propTypes = {
  title: PropTypes.string.isRequired,
  projects: PropTypes.arrayOf(ExtendedProjectShape).isRequired,
}

export default InvestmentProjects
