import keyBy from "lodash.keyby"

import operationRequest from "@components/Authorization/request/operationRequest"
import { indexPropertiesOperation } from "@api/services/backstage"


const getOrganizationProperties = async (authorization) => {
  const parameters = {
    operation: indexPropertiesOperation,
    headers: { authorization },
  }

  const { data: properties } = await operationRequest(parameters)
  const propertiesMap = keyBy(properties, "id")

  const getProperty = id => propertiesMap[id]

  return { properties, getProperty }
}

export default getOrganizationProperties
