import PropTypes from "prop-types"

import AddressShape from "./AddressShape"
import { BeneficialOwnerEntityTypeEnumKeys } from "./BeneficialOwnerEntityTypeEnum"
import { EntityMembershipInterestsTitleEnumKeys } from "./EntityMembershipInterestsTitleEnum"
import { EntityTypeEnumKeys } from "./EntityTypeEnum"
import { TaxFilingStatusEnumKeys } from "./TaxFilingStatusEnum"
import { UsStateEnumKeys } from "./UsStateEnum"

const properties = {
  address: AddressShape,
  beneficialOwnerAddress: AddressShape,
  beneficialOwnerCustodianEinNumber: PropTypes.string,
  beneficialOwnerEinNumber: PropTypes.string,
  beneficialOwnerEntityType: PropTypes.oneOf(BeneficialOwnerEntityTypeEnumKeys),
  beneficialOwnerName: PropTypes.string,
  beneficialOwnerSsnNumber: PropTypes.string,
  beneficialOwnerTaxFilingStatus: PropTypes.oneOf(TaxFilingStatusEnumKeys),
  einNumber: PropTypes.string,
  entityType: PropTypes.oneOf(EntityTypeEnumKeys),
  hasEinNumber: PropTypes.bool,
  hasOtherUsResidenceState: PropTypes.bool,
  hasPurchasedExemptSecurities: PropTypes.bool,
  hasSsn: PropTypes.bool,
  isDisregardedForTaxPurposes: PropTypes.bool,
  isLiabilityAccepted: PropTypes.bool,
  isRetirementPlan: PropTypes.bool,
  isUsCitizen: PropTypes.bool,
  isUsPerson: PropTypes.bool,
  membershipInterestsTitle: PropTypes.oneOf(EntityMembershipInterestsTitleEnumKeys),
  name: PropTypes.string,
  profileName: PropTypes.string,
  residenceState: PropTypes.oneOf(UsStateEnumKeys),
  taxFilingStatus: PropTypes.oneOf(TaxFilingStatusEnumKeys),
  title: PropTypes.string
}

const EntityShape = PropTypes.exact(properties)

export default EntityShape
export { properties }
