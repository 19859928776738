import React from "react"
import { Typography } from "antd"
import { useNavigate, useOutletContext } from 'react-router-dom'

import Identity from "@components/Identity"
import { hasAccess } from "@components/Authorization"
import { Value } from "@components/Text"

import CampaignRowActions from "../CampaignRowActions"

const LABEL_TYPE = "Type"
const LABEL_CREATED = "Created At"
const LABEL_SCHEDULED = "Scheduled For"
const LABEL_UPDATED_AT = "Updated At"
const LABEL_UPDATED_BY = "Updated By"


const useColumnsConfig = (openTagsDrawer, openCampaignCloneModal) => {
  const canUpdateCampaign = hasAccess(['campaigns-write'])

  const navigate = useNavigate()
  const {
    getOptionName,
    computeUserFullname,
    subscriptionTypeOptions,
  } = useOutletContext()

  const openCampaignUpdatePage = id =>
    navigate(`/backstage/campaigns/${id}`)

  const createdAtColumnProps = {
    key: "createdAt",
    title: LABEL_CREATED,
    render: value => <Value value={value} />,
  }

  const updatedAtColumnProps = {
    key: "updatedAt",
    title: LABEL_UPDATED_AT,
    render: value => <Value value={value} />,
  }

  const updatedByColumnProps = {
    key: "updatedBy",
    title: LABEL_UPDATED_BY,
    compute: ({ updatedBy }) => computeUserFullname(updatedBy),
    render: (fullname, { updatedBy }) => (
      updatedBy
        ? <Identity id={updatedBy}>{fullname}</Identity>
        : null
    ),
    isSearchable: true,
  }

  const scheduledAtColumnProps = {
    key: "scheduledAt",
    title: LABEL_SCHEDULED,
    render: value => value && <Value value={value} />,
  }

  const nameColumnProps = {
    key: "name",
    render: (name, { id }) => (
      <Typography.Link
        strong
        onClick={() => openCampaignUpdatePage(id)}
      >
        {name}
      </Typography.Link>
    ),
    isSearchable: true,
  }

  const statusColumnProps = {
    key: "status",
    align: "center",
  }

  const createdByColumnProps = {
    key: "createdBy",
    compute: ({ createdBy }) => computeUserFullname(createdBy),
    render: (fullname, { createdBy }) => (
      <Identity id={createdBy}>
        {fullname}
      </Identity>
    ),
    isSearchable: true,
  }

  const subscriptionTypeColumnProps = {
    key: "subscriptionTypeId",
    title: LABEL_TYPE,
    filters: subscriptionTypeOptions.map(({ label }) => ({ label, value: label })),
    compute: ({ subscriptionTypeId }) => getOptionName(subscriptionTypeId),
    filterMultiple: true,
    isFilterEnabled: true,
  }

  const columns = [
    { ...nameColumnProps, fixed: "left", width: 240, hasTruncation: true },
    { ...createdAtColumnProps, width: 180 },
    { ...updatedAtColumnProps, width: 180 },
    { ...updatedByColumnProps, width: 200, hasTruncation: true },
    { ...scheduledAtColumnProps, width: 200 },
    { ...statusColumnProps, width: 100 },
    { ...createdByColumnProps, width: 200, hasTruncation: true },
    { ...subscriptionTypeColumnProps, width: 200 },
  ]

  if (!canUpdateCampaign) {
    return columns
  }

  columns.push({
    key: "actions",
    title: false,
    width: 48,
    fixed: "right",
    render: (_, campaign) =>
      <CampaignRowActions
        campaign={campaign}
        openTagsDrawer={openTagsDrawer}
        openCampaignCloneModal={openCampaignCloneModal}
      />
  })

  return columns
}

export default useColumnsConfig
