import { downloadContent } from "@components/Import"


const downloadReport = (
  organizationName,
  projectName,
  documentType,
  name,
  date,
  unmatchedFileNames
) => {
  const isReportEmpty = unmatchedFileNames.length === 0

  if (isReportEmpty) {
    return
  }

  const header = '"Organization Name","Project Name","Document Type","Date","Name","File Name"\n'

  const rows = unmatchedFileNames
    .map(fileName => {
      const row = [
        `"${organizationName}"`,
        `"${projectName}"`,
        `"${documentType}"`,
        `"${date}"`,
        `"${name}"`,
        `"${fileName}"`,
      ].join(",")

      return row
    })
    .join('\n')

  const content = `${header}${rows}`
  const fileName = `${organizationName} - ${projectName} ${documentType} ${date}.csv`

  downloadContent(fileName, content, 'text/csv', true)
}

export default downloadReport
