import { PROJECT_TYPES } from "@components/Domain"
import PropertyInformationTags from "@components/Project/PropertyInformationTags"
import {
  US_STATE_OPTIONS,
  LABEL_STATE_PLACEHOLDER
} from "@components/Address"
import {
  TYPE_ZIP,
  TYPE_NONE,
  TYPE_AMOUNT,
  TYPE_HIDDEN,
  PublicImagesInput
} from "@components/Form"

import getPropertyTagLabelOptions from "./getPropertyTagLabelOptions"

const LABEL_NAME = "Name"
const LABEL_CITY = "City"
const LABEL_STATE = "State"
const LABEL_PHOTOS = "Photos"
const LABEL_STREET = "Street"
const LABEL_EQUITY = "Equity Deployed"
const LABEL_PROJECT = "Primary Project"
const LABEL_ZIP_CODE = "ZIP Code"
const LABEL_ADD_PHOTO = "Add photo"
const LABEL_STATUS_PLACEHOLDER = "Select property status"
const LABEL_PROJECT_PLACEHOLDER = "Select a project"


const getInputs = ({
  projects = [],
  excludeInputNames = [],
  project,
  properties,
}) => {
  const isFundProject =
    project &&
    project.type === PROJECT_TYPES.FUND

  const nonFundProjectOptions = projects
    .filter(({ type }) => type !== PROJECT_TYPES.FUND)
    .map(({ id: value, name: label }) => ({ value, label }))

  const hasProjectOptions = nonFundProjectOptions.length > 0

  const projectInputProps = hasProjectOptions
    ? {
        label: LABEL_PROJECT,
        options: nonFundProjectOptions,
        placeholder: LABEL_PROJECT_PLACEHOLDER,
        itemProps: { style: { width: "66%" } }
      }
    : {
        type: TYPE_HIDDEN,
        initialValue: project?.id
      }

  return () => {
    const inputs = [
      {
        name: "name",
        label: LABEL_NAME,
        placeholder: "",
      },
      {
        name: "projectId",
        ...projectInputProps,
      },
      {
        name: "status",
        inputProps: { style: { width: 220 } },
        placeholder: LABEL_STATUS_PLACEHOLDER
      },
      {
        name: "investmentDate",
      },
      {
        name: "equityDeployedAmount",
        type: TYPE_AMOUNT,
        label: LABEL_EQUITY,
        inputProps: { style: { width: 130 }, controls: false },
        placeholder: ""
      },
      {
        name: "address",
        schema: [
          {
            name: "addressCountry",
            type: TYPE_HIDDEN,
            initialValue: "US"
          },
          {
            name: "streetAddress",
            label: LABEL_STREET,
            placeholder: "",
            required: true,
            itemProps: { style: { width: "50%" } }
          },
          {
            name: "addressLocality",
            label: LABEL_CITY,
            placeholder: "",
            required: true,
            itemProps: { style: { width: "50%" } }
          },
          {
            /* td: should be automatically detected by zip code */
            name: "addressRegion",
            label: LABEL_STATE,
            options: US_STATE_OPTIONS,
            placeholder: LABEL_STATE_PLACEHOLDER,
            inputProps: { style: { width: 180 } }
          },
          {
            name: "postalCode",
            type: TYPE_ZIP,
            label: LABEL_ZIP_CODE,
            placeholder: "",
            required: true,
            itemProps: { style: { width: 180 } }
          }
        ]
      },
      {
        name: "photos",
        component: PublicImagesInput,
        componentProps: {
          title: LABEL_PHOTOS,
          addTitle: LABEL_ADD_PHOTO,
        }
      },
      {
        name: "tags",
        component: PropertyInformationTags,
        componentProps: {
          options: getPropertyTagLabelOptions(properties)
        },
      }
    ]

    if (!isFundProject) {
      excludeInputNames = [
        ...excludeInputNames,
        "status",
        "investmentDate",
        "equityDeployedAmount"
      ]
    }

    const filteredInputs = inputs
      .map(input => {
        const { name } = input

        const isNoneInput = excludeInputNames.includes(name)

        if (isNoneInput) {
          return {
            name,
            type: TYPE_NONE
          }
        }

        return input
      })

    return filteredInputs
  }
}

export default getInputs
