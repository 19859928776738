import React from "react"
import PropTypes from "prop-types"

import { InvestmentShape } from "@components/Investment/shapes"

import getWireLabelKey from "./helpers/getWireLabelKey"
import { OpenWireModalButton } from "./WireModal"
import InvestmentProfileCardItem from "../InvestmentProfileCardItem"
import {
  PENDING_KEY,
  COMPLETE_KEY,
  INCOMPLETE_KEY,
  OVERFUNDED_KEY,
  PARTIALLY_FUNDED_KEY,
  DANGER_STYLE_TYPE,
  WARNING_STYLE_TYPE,
  SUCCESS_STYLE_TYPE,
  PENDING_SUBTITLE,
  COMPLETE_SUBTITLE,
  PENDING_STATUS,
  COMPLETE_STATUS,
  INCOMPLETE_STATUS,
  OVERFUNDED_STATUS,
  PARTIALLY_FUNDED_STATUS,
} from "./helpers/Constants"

const LABELS_MAP = {
  [INCOMPLETE_KEY]: {
    status: INCOMPLETE_STATUS,
    subtitle: `Please provide your custodian with the wire transfer
instructions to complete the funding process.`,
    styleType: DANGER_STYLE_TYPE
  },
  [PENDING_KEY]: {
    status: PENDING_STATUS,
    subtitle: PENDING_SUBTITLE,
    styleType: WARNING_STYLE_TYPE,
  },
  [COMPLETE_KEY]: {
    status: COMPLETE_STATUS,
    subtitle: COMPLETE_SUBTITLE,
    styleType: SUCCESS_STYLE_TYPE,
  },
  [OVERFUNDED_KEY]: {
    status: OVERFUNDED_STATUS,
    subtitle: `We received more funds than your committed amount. Please contact
your sponsor for the next steps to resolve this issue.`,
    styleType: DANGER_STYLE_TYPE,
  },
  [PARTIALLY_FUNDED_KEY]: {
    status: PARTIALLY_FUNDED_STATUS,
    subtitle: `Received amount doesn't match the committed amount. Reach out to
your custodian to add funds or contact support for assistance.`,
    styleType: DANGER_STYLE_TYPE
  },
}


const FundSdiraInvestmentItem = ({
  investment,
  openWireModal,
  ...otherProps
}) => {
  const [ key, shouldRenderWireButton ] = getWireLabelKey(investment)
  const isPending = key === PENDING_KEY

  let shouldRenderWireButtonByStatus = shouldRenderWireButton
  if (isPending) {
    shouldRenderWireButtonByStatus = true
  }

  const labels = LABELS_MAP[key]

  const itemProps = {
    ...labels,
    ...otherProps
  }

  return (
    <InvestmentProfileCardItem {...itemProps}>
      {
        shouldRenderWireButtonByStatus && (
          <OpenWireModalButton onClick={openWireModal} />
        )
      }
    </InvestmentProfileCardItem>
  )
}

FundSdiraInvestmentItem.propTypes = {
  investment: InvestmentShape.isRequired,
  openWireModal: PropTypes.func.isRequired,
}

export default FundSdiraInvestmentItem
