import React, { useMemo, useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"

import { W1 } from "@components"
import { Form } from "@components/Form"
import { useAppContext } from "@components/AppContext"
import {
  KEY_TIER_IDS,
  KEY_AMOUNTS_MAP
} from "@components/Investment/InvestmentForm/InvestmentsInput/InvestmentsInput"
import { ReadPublicPublishedProjectOutputShape } from "@api/services/backstage"
import { createCommitmentOperation as createOperation } from "@api/services/investments"

import getInputs from "./helpers/getInputs"

const LABEL_FAILED = "Failed to submit commitment"
const LABEL_SUBMIT = "Reserve Your Spot"


const CreateCommitmentForm = ({
  project,
  onSuccess,
}) => {
  const { request, showErrorMessage } = useAppContext()

  const [ schema, setSchema ] = useState([])

  useEffect(() => {
    const { tiers, sponsorBrokers: brokers = [] } = project

    const inputs = getInputs(tiers, brokers)
    const newSchema = createOperation.getSchema(inputs)

    setSchema(newSchema)
  }, [ project ])

  const onSubmit = useCallback(
    parameters => {
      const { mutation, ...rest } = parameters

      const [ firstProfile ] = mutation.profiles
      const { brokerUserId } = firstProfile

      mutation.projectId = project.id
      mutation.brokerUserId = brokerUserId

      /* note: cleanup mutation from helper attributes */
      delete firstProfile.brokerUserId

      /* td: extend form update method to get rid of virtual fields starting with _ */
      for (const profile of mutation.profiles) {
        delete profile[KEY_TIER_IDS]
        delete profile[KEY_AMOUNTS_MAP]
      }

      return { ...rest, mutation }
    }
  , [ project ])

  const onFailure = useCallback(
    () => showErrorMessage(LABEL_FAILED)
  , [ showErrorMessage ])

  const submitButtonProps = useMemo(() => ({
    style: { width: "100%", height: 50, marginTop: -W1 }
  }), [])

  const formProps = {
    operation: createOperation,
    submitLabel: LABEL_SUBMIT,
    schema,
    request,
    onSubmit,
    onSuccess,
    onFailure,
    submitButtonProps
  }

  return (
    <Form {...formProps} />
  )
}

CreateCommitmentForm.propTypes = {
  project: ReadPublicPublishedProjectOutputShape.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default CreateCommitmentForm
