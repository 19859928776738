import React from "react"
import PropTypes from "prop-types"
import { App, Button } from "antd"
import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"
import { useAppContext } from "@components/AppContext"
import { TRANSACTION_TYPES } from "@components/Domain"
import {
  ReadTransactionOutputShape,
  deleteTransactionOperation,
  deleteExternalFundingTransactionOperation,
  deleteExternalDistributionTransactionOperation
} from "@api/services/transactions"

const LABEL_TITLE = "Delete Transaction"
const LABEL_DELETE = "Delete"
const LABEL_CONTENT = "By clicking Delete below, the transaction will be deleted. This action is not reversible."
const LABEL_DELETE_MESSAGE = "Transaction is deleted"


const TransactionDelete = ({
  drawer,
  transaction,
  onAfterDelete
}) => {
  const { modal } = App.useApp()

  const {
    request,
    showSuccessMessage
  } = useAppContext()

  const canDelete = hasAccess(['transactions-delete'])

  if (!canDelete) {
    return null
  }

  const { id, isApproved, isExternal, type } = transaction

  const isDisabled = () => {
    if (isExternal) {
      return false
    }

    if (isApproved) {
      return true
    }

    if (!id) {
      return true
    }

    return false
  }

  const getOperation = () => {
    const isFunding = type === TRANSACTION_TYPES.FUNDING

    if (isFunding) {
      return deleteExternalFundingTransactionOperation
    }

    if (isExternal) {
      return deleteExternalDistributionTransactionOperation
    }

    return deleteTransactionOperation
  }

  const onDeleteTransaction = () => {
    modal.confirm({
      icon: <ExclamationCircleFilled />,
      title: LABEL_TITLE,
      okText: LABEL_DELETE,
      okType: "danger",
      content: LABEL_CONTENT,
      onOk() {
        const operation = getOperation()

        return request(operation, { id })
          .then(() => onAfterDelete())
          .then(() => {
            drawer.current.close()
            showSuccessMessage(LABEL_DELETE_MESSAGE)
          })
      },
      onCancel() {}
    })
  }

  return (
    <Button
      danger
      type="text"
      size="small"
      icon={<DeleteOutlined />}
      onClick={onDeleteTransaction}
      disabled={isDisabled()}
    >
      {LABEL_DELETE}
    </Button>
  )
}

TransactionDelete.propTypes = {
  drawer: PropTypes.shape().isRequired,
  transaction: ReadTransactionOutputShape.isRequired,
  onAfterDelete: PropTypes.func.isRequired,
}

export default TransactionDelete
