import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Upload} from "antd"
import { UploadOutlined } from "@ant-design/icons"

import getDummyRequest from "./helpers/getDummyRequest"

const DOCUMENTS_SUPPORTED_FILES = [
  "application/pdf",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/rtf",
  "text/plain",
]

const LABEL_SELECT = "Select Folder"

const STATUS_DONE = "done"


const isHiddenFile = ({ name }) => name.startsWith(".")


const isFileTypeSupported = ({ name, type }) =>
  !isHiddenFile({name}) && DOCUMENTS_SUPPORTED_FILES.includes(type)


const SelectFolderButton = ({ onSuccess }) => {
  const [ files, setFiles ] = useState([])
  const [ metadatas, setMetadatas ] = useState([])

  const customRequest = getDummyRequest(selectedFile =>
    setFiles(prevFiles => [...prevFiles, selectedFile])
  )

  const finish = (resultMetadatas) => {
    const supportedFiles = files
      .filter(isFileTypeSupported)

    const unsupportedFileNames = files
      .filter(item => !isHiddenFile(item))
      .filter(item => !isFileTypeSupported(item))
      .map(({ name: fileName }) => fileName)

    onSuccess(supportedFiles, resultMetadatas, unsupportedFileNames)

    setFiles([])
    setMetadatas([])
  }

  const onChange = ({ file: metadata, fileList }) => {
    const { uid, type, name, status } = metadata

    const isDone = status === STATUS_DONE
    const isHidden = name.startsWith(".")
    const isSupportedType = DOCUMENTS_SUPPORTED_FILES.includes(type)

    const lastFile = fileList.slice(-1).pop()
    const isLast = uid === lastFile.uid

    const shouldSkipFile = !isDone || isHidden || !isSupportedType

    if (shouldSkipFile) {
      if (!isLast) {
        return
      }

      finish(metadatas)
      return
    }

    if (!isLast) {
      setMetadatas(prevMetadatas => [ ...prevMetadatas, metadata ])
      return
    }

    finish([ ...metadatas, metadata ])
  }

  return (
    <Upload
      directory
      multiple={false}
      onChange={onChange}
      customRequest={customRequest}
      showUploadList={false}
    >
      <Button
        type="dashed"
        icon={<UploadOutlined />}
      >
        {LABEL_SELECT}
      </Button>
    </Upload>
  )
}

SelectFolderButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default SelectFolderButton
