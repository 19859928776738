import React from "react"
import { Checkbox, Form } from "antd"

import { ItemPropsShape, InputPropsShape } from "@api"


const CheckboxInput = ({
  itemProps,
  inputProps,
}) => {
  const { label, ...restInputProps } = inputProps

  return (
    <Form.Item
      valuePropName='checked'
      {...itemProps}
    >
      <Checkbox {...restInputProps}>
        {label}
      </Checkbox>
    </Form.Item>
  )
}

CheckboxInput.propTypes = {
  itemProps: ItemPropsShape.isRequired,
  inputProps: InputPropsShape.isRequired,
}

export default CheckboxInput
