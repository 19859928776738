import React, { useState } from "react"
import PropTypes from "prop-types"
import { Typography, Button, Space } from "antd"
import { MaskedInput } from "antd-mask-input"

import { W1 } from "@components"
import { Value } from "@components/Text"
import { useAppContext } from "@components/AppContext"
import { OperationShape } from "@api"

const MASK = "000000"


const ActivateVerificationRequest = ({
  mobile,
  onSuccess,
  enableOperation,
  verificationRequestId,
  onResendVerificationCode,
}) => {
  const [ isLoading, setIsLoading ] = useState(false)
  const [ verificationCode, setVerificationCode ] = useState("")

  const { request, showErrorMessage } = useAppContext()

  const activateVerificationRequest = async () => {
    const parameters = {
      verificationCode,
      verificationRequestId,
    }

    try {
      await request(enableOperation, parameters, [ 'UnprocessibleConditionError' ])
      await onSuccess()

    } catch (error) {
      const isExpected = error.code === 'UnprocessibleConditionError'

      if (!isExpected) {
        console.error(error)
        return
      }

      const message = 'Invalid or expired verification code'
      showErrorMessage(message)
    }
  }

  const onClick = async () => {
    if (!isVerificationCodeValid) {
      return
    }

    setIsLoading(true)
    await activateVerificationRequest()
    setIsLoading(false)
  }

  const onChange = (input) => {
    const { unmaskedValue } = input
    setVerificationCode(unmaskedValue)
  }

  const isVerificationCodeValid = verificationCode.length === 6

  return (
    <>
      <Typography.Paragraph>
        <Typography.Text>
          We sent a verification code to your mobile number{" "}
          <Typography.Text strong><Value value={mobile} /></Typography.Text>.{" "}
          Kindly check your incoming messages and enter it below.
        </Typography.Text>
      </Typography.Paragraph>

      <center>
        <Space direction="vertical" size={W1}>
          <div style={{ marginTop: W1, marginBottom: W1 }}>
            <MaskedInput
              size="large"
              mask={MASK}
              style={{ width: 90, textAlign: "center" }}
              onChange={onChange}
            />
          </div>

          <Button
            size="large"
            type="primary"
            loading={isLoading}
            onClick={onClick}
            disabled={!isVerificationCodeValid}
          >
            {"Verify mobile number"}
          </Button>

          <Button
            type="link"
            onClick={onResendVerificationCode}
          >
            {"Resend verification code"}
          </Button>
        </Space>
      </center>
    </>
  )
}

ActivateVerificationRequest.propTypes = {
  mobile: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  enableOperation: OperationShape.isRequired,
  verificationRequestId: PropTypes.string.isRequired,
  onResendVerificationCode: PropTypes.func.isRequired,
}

export default ActivateVerificationRequest
