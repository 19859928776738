import React, { useCallback, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { Form, Input, InputNumber } from "antd"

import { getFormattedAmountValue } from "@components/Amount"
import { ItemPropsShape, InputPropsShape } from "@api"

import FormShape from "../shapes/FormShape"

const { Item } = Form


const AmountInput = ({
  form,
  path,
  itemProps,
  inputProps
}) => {
  const {
    name,
    ...restItemProps
  } = itemProps

  const inputField = `_${name.slice(-1)}`
  const inputName = [...name.slice(0, -1), inputField]
  const inputPath = useMemo(() => [...path.slice(0, -1), inputField], [path, inputField])

  const sourceValue = Form.useWatch(path, form)
  const inputSourceValue = Form.useWatch(inputName, form)

  inputProps = {
    ...inputProps,
    prefix: "$",
    parser: value => value.replace(/\$\s?|(,*)/g, ''),
    formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  }

  const setValue = useCallback(() => {
    let value = form.getFieldValue(path)

    if (value) {
      value = getFormattedAmountValue(value)
    }

    form.setFieldValue(inputPath, value)
  }, [
    form,
    path,
    inputPath,
  ])

  useEffect(() => {
    setValue()
  }, [
    setValue,
    sourceValue,
    inputSourceValue,
  ])

  const onChange = value => {
    if (!(typeof value === "number")) {
      form.setFieldValue(path, undefined)
      return
    }

    let formatedValue = parseFloat(value).toFixed(2)
    formatedValue *= 100

    form.setFieldValue(path, Math.round(formatedValue))
  }

  return (
    <>
      <Item
        key="hidden"
        name={name}
        hidden={true}
      >
        <Input />
      </Item>

      <Item
        name={inputName}
        {...restItemProps}
      >
        <InputNumber
          onChange={onChange}
          {...inputProps}
        />
      </Item>
    </>
  )
}

AmountInput.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  itemProps: ItemPropsShape.isRequired,
  inputProps: InputPropsShape.isRequired,
}

export default AmountInput
