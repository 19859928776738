import React, { useRef } from "react"
import { Tag, Space } from "antd"
import PropTypes from "prop-types"

import { DropdownButton } from "@components/Button"
import {
  INVESTOR_STATUS_COLORS_MAP,
  INVESTOR_STATUS_LABELS_MAP,
} from "@components/Domain"

import useItems from "./helpers/useItems"
import { AccountShape } from "../shapes"
import ResetIdentityModal from "./ResetIdentityModal"
import CreateIdentityModal from "./CreateIdentityModal"

const LABEL_SHADOW = "Shadow"
const LABEL_DISABLED = "Disabled"


const Header = ({
  account = undefined,
  readAccount,
  updateAccount
}) => {
  const resetIdentityModal = useRef({})
  const createIdentityModal = useRef({})

  const openResetIdentityModal = () =>
    resetIdentityModal.current.openItem(account)

  const openCreateIdentityModal = () =>
    createIdentityModal.current.openItem(account)

  const items = useItems(
    account,
    updateAccount,
    openResetIdentityModal,
    openCreateIdentityModal
  )

  if (!account) {
    return null
  }

  const { givenName, familyName } = account.investor
  const fullName = `${givenName} ${familyName}`

  const { status, isDisabled, correlationId } = account

  const color = INVESTOR_STATUS_COLORS_MAP[status]
  const statusTitle = INVESTOR_STATUS_LABELS_MAP[status]

  const isShadow = !correlationId
  const hasDropdown = items.length > 0

  return (
    <Space>
      { !hasDropdown && fullName }

      {
        hasDropdown && (
          <DropdownButton
            name={fullName}
            items={items}
          />
        )
      }

      {"   "}
      <Space size={0}>
        <Tag
          color={color}
          style={{ fontWeight: "normal" }}
          bordered={false}
        >
          {statusTitle}
        </Tag>

        {
          isShadow && (
            <Tag
              style={{ fontWeight: "normal" }}
              bordered={false}
            >
              {LABEL_SHADOW}
            </Tag>
          )
        }

        {
          isDisabled && (
            <Tag
              style={{ fontWeight: "normal" }}
              bordered={false}
            >
              {LABEL_DISABLED}
            </Tag>
          )
        }
      </Space>

      <CreateIdentityModal
        modal={createIdentityModal}
        account={account}
        readAccount={readAccount}
      />

      <ResetIdentityModal
        modal={resetIdentityModal}
        account={account}
        readAccount={readAccount}
      />
    </Space>
  )
}

Header.propTypes = {
  account: AccountShape,
  readAccount: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
}

export default Header
