import capitalize from "lodash.capitalize"

export const ACTIVE = "ACTIVE"
export const CLOSED = "CLOSED"
export const SOLD = "SOLD"

const PropertyStatusEnum = {
  [ACTIVE]: ACTIVE,
  [CLOSED]: CLOSED,
  [SOLD]: SOLD
}

const PropertyStatusEnumKeys = Object.keys(PropertyStatusEnum)

const PropertyStatusEnumOptions = PropertyStatusEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  PropertyStatusEnumKeys,
  PropertyStatusEnumOptions
}

export default PropertyStatusEnum
