import { computeInvestor } from "@modules/backstage/investments"
import { getAmountWithoutCents } from "@components/Amount"

import getAmountType from "./getAmountType"


const getBuildInvestorTransactions = (investments, projectId) => {
  return ({email, profitOnSale, returnOfCapital, returnOnInvestment, sponsorPromote, profileName}, rowIndex) => {
    const currentInvestment = investments.find(investment => {
      const { investor } = investment
      const isSameProfile = computeInvestor(investment) === profileName
      const isSameInvestorEmail = investor.email === email

      return isSameProfile && isSameInvestorEmail
    })

    const { id, name, profileType, investorAccountId } = currentInvestment

    const profitOnSaleAmount = getAmountWithoutCents(profitOnSale)
    const returnOfCapitalAmount = getAmountWithoutCents(returnOfCapital)
    const returnOnInvestmentAmount = getAmountWithoutCents(returnOnInvestment)
    const sponsorPromoteAmount = getAmountWithoutCents(sponsorPromote)

    const total = profitOnSaleAmount + returnOfCapitalAmount + returnOnInvestmentAmount + sponsorPromoteAmount

    const transaction = {
      id,
      name,
      profileType,
      email,
      investorAccountId,
      profileName: name,
      type: getAmountType(profitOnSale, returnOfCapital, returnOnInvestment),
      profitOnSale: profitOnSaleAmount,
      returnOfCapital: returnOfCapitalAmount,
      returnOnInvestment: returnOnInvestmentAmount,
      sponsorPromote: sponsorPromoteAmount,
      rowIndex: rowIndex + 1,
      projectId,
      total
    }

    return transaction
  }
}

export default getBuildInvestorTransactions
