import uniq from "lodash.uniq"


const getColumnFilterProps = (
  hasHeaderFilters,
  items,
  compute,
  onFilter,
  filterOptions,
  filterText = value => value,
) => {
  onFilter = onFilter
    ? onFilter
    : (selectedValues, item) => selectedValues.includes(compute(item))

  if (filterOptions) {
    return {
      options: filterOptions,
      onFilter,
    }
  }

  if (!items) {
    return {}
  }

  const sortedValues = uniq(
    items.map(item => compute(item))
  ).sort()

  if (hasHeaderFilters) {
    const options = sortedValues
      .map(value => ({
        value,
        label: filterText(value)
      }))

    return {
      options,
      onFilter,
    }
  }

  const filters = sortedValues
    .map(value => ({
      value,
      text: filterText(value)
    }))

  return {
    filters,
    onFilter,
  }
}


export default getColumnFilterProps
