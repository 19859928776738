import React  from "react"
import { Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import { Value } from "@components/Text"
import { getFormattedDecimalValue } from "@components/Amount"
import {
  PROJECT_TYPES,
  PROJECT_STATES,
  TRANSACTION_TYPES,
} from "@components/Domain"

const LABEL_STATUS = "Status"
const LABEL_PROJECT = "Project"
const LABEL_PROFILE = "Profile"
const LABEL_RECEIVED = "Investments"
const LABEL_INVESTED = "Distributions"

const LABEL_CLOSED = "Closed"
const LABEL_ACTIVE = "Active"
const LABEL_PENDING = "Pending"


const useColumnConfig = (investments, transactions, openInvestmentDrawer) => {
  const { getProject } = useOutletContext()

  const getInvestmentStatus = (projectId, isFunded, isOverfunded) => {
    const project = getProject(projectId)

    const { type, state } = project

    const isArchived = state === PROJECT_STATES.ARCHIVED
    const isClosed = state === PROJECT_STATES.CLOSED
    const isFund = type === PROJECT_TYPES.FUND

    const isFullyFunded = isFunded || isOverfunded

    if (isArchived) {
      return LABEL_CLOSED
    }

    if (isClosed) {
      return isFund
        ? LABEL_CLOSED
        : LABEL_ACTIVE
    }

    const isFundFunded = isFullyFunded && isFund

    return isFundFunded
      ? LABEL_ACTIVE
      : LABEL_PENDING
  }

  const getProjectName = projectId =>
    getProject(projectId)?.shortName || getProject(projectId)?.name || projectId

  const getInvestedAmount = classInvestments =>
    classInvestments
      .reduce((amount, investment) => getFormattedDecimalValue(amount, investment.amount, "plus"), 0)

  const getReceivedAmount = investmentId =>
    transactions
      .filter(({ type }) => type !== TRANSACTION_TYPES.FUNDING)
      .filter(transaction => transaction.investmentId === investmentId)
      .reduce((amount, transaction) => getFormattedDecimalValue(amount, transaction.amount, "plus"), 0)

  const statusColumnProps = {
    key: "_status",
    title: LABEL_STATUS,
    compute: ({ projectId, isFunded, isOverfunded }) => getInvestmentStatus(projectId, isFunded, isOverfunded)
  }

  const projectColumnProps = {
    key: "projectId",
    title: LABEL_PROJECT,
    render: projectId => getProjectName(projectId)
  }

  const profileColumnProps = {
    key: "name",
    title: LABEL_PROFILE,
    render: (name, { id, projectId }) =>
      <Typography.Link
        strong
        onClick={() => openInvestmentDrawer(id)}
      >
        {name}
      </Typography.Link>
  }

  const investedColumnProps = {
    key: "_invested",
    title: LABEL_RECEIVED,
    align: "right",
    compute: (investment) => getInvestedAmount(investment.investments),
    render: value =>
      <Value value={value} />,
  }

  const receivedColumnProps = {
    key: "_received",
    title: LABEL_INVESTED,
    align: "right",
    compute: ({ id }) => getReceivedAmount(id),
    render: value =>
      <Value value={value} />,
  }

  return [
    { ...statusColumnProps, width: 90 },
    { ...projectColumnProps },
    { ...profileColumnProps },
    { ...investedColumnProps, width: 140 },
    { ...receivedColumnProps, width: 140 },
  ]
}

export default useColumnConfig
