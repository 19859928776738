import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { CreateModal } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { createOrganizationOperation as createOperation }
from "@api/services/organizations"
import getInputs from "./helpers/getInputs"

const LABEL_ADD = "New Organization"
const LABEL_ENTITY = "Organization"


const OrganizationCreateModal = ({
  modal,
  onSuccess,
}) => {
  const { authenticatedRequest } = useAppContext()
  const { userGroups = [] } = useOutletContext()

  const inputs = getInputs(userGroups)
  const schema = createOperation.getSchema(inputs)

  return (
    <CreateModal
      modal={modal}
      title={LABEL_ADD}
      entity={LABEL_ENTITY}
      schema={schema}
      request={authenticatedRequest}
      onSuccess={onSuccess}
      operation={createOperation}
    />
  )
}

OrganizationCreateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default OrganizationCreateModal
