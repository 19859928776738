import capitalize from "lodash.capitalize"

export const checking = "checking"
export const savings = "savings"

const BankAccountTypeEnum = {
  [checking]: checking,
  [savings]: savings
}

const BankAccountTypeEnumKeys = Object.keys(BankAccountTypeEnum)

const BankAccountTypeEnumOptions = BankAccountTypeEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  BankAccountTypeEnumKeys,
  BankAccountTypeEnumOptions
}

export default BankAccountTypeEnum
