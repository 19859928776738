import React, { useState } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { Button } from "antd"

import { isMobile } from "@components"
import InvestorHeader from "@layouts/investor/InvestorLayout/Header"

const LABEL_EXIT = "Save and Exit"

const DASHBOARD_PATH = "/dashboard"


const Header = ({ currentForm }) => {
  const navigate = useNavigate()

  const [ isLoading, setIsLoading ] = useState(false)

  const onClick = async () => {
    setIsLoading(true)
    await currentForm.current.submit()

    navigate(DASHBOARD_PATH)
  }

  const buttonStyle = {
    margin: 0,
    marginRight: 10,
    fontWeight: 600,
    paddingLeft: 0,
    paddingRight: 0,
  }

  if (isMobile) {
    buttonStyle.fontSize = 16
    buttonStyle.marginRight = 0
  }

  return (
    <InvestorHeader>
      <Button
        type="link"
        style={buttonStyle}
        onClick={onClick}
        loading={isLoading}
      >
        {LABEL_EXIT}
      </Button>
    </InvestorHeader>
  )
}

Header.propTypes = {
  currentForm: PropTypes.shape().isRequired,
}

export default Header
