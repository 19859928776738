import React, { useRef } from "react"
import { useOutletContext } from "react-router-dom"
import { Descriptions, Typography, Space, Tooltip } from "antd"
import { EyeOutlined, WarningOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"
import { Value } from "@components/Text"
import { getConfig } from "@components/Config"
import { BankAccountModal } from "@modules/backstage/bankAccounts"

import ProjectShape from "../shapes/ProjectShape"
import ProjectEditableGroup from "./ProjectEditableGroup"
import { ProjectPartnersModal } from "./ProjectPartners"

const { Item } = Descriptions
const { Text, Link: TextLink } = Typography

const LABEL_NAI = "Non-Accredited Investors"
const LABEL_OPEN = "Go Live Date"
const LABEL_CLOSE = "Funding Date"
const LABEL_TITLE = "Project Settings"
const LABEL_INVALID = "Invalid Bank Account"
const LABEL_CLASSES = "Investment Classes"
const LABEL_TEMPLATE = "PPM Template"
const LABEL_BENEFICIARY = "Beneficiary"
const LABEL_TARGET_AMOUNT = "Funding Amount"
const LABEL_BENEFICIARY_ACCOUNT = "Bank Account"
const MODAL_WIDTH = 800

const isAchOptionEnabled = getConfig("isAchOptionEnabled")


const ProjectSettings = ({ project }) => {
  const bankAccountModal = useRef({})
  const projectPartnersModal = useRef({})

  const { hasMultipleSponsors, bankAccountsMap } = useOutletContext()

  const isPartnerProject = hasAccess(['fund-projects-write'])
  const isEditable = hasAccess(['projects-write']) || isPartnerProject

  const {
    tiers,
    openDate,
    closeDate,
    targetAmount,
    ppmTemplateId,
    bankAccountId,
    nonAccreditedInvestorsMax
  } = project

  const openBankAccount = () =>
    bankAccountModal.current.open(bankAccountsMap[bankAccountId])

  const openProjectPartnersModal = () =>
    projectPartnersModal.current.open()

  let fieldNames = [
    'name',
    'shortName',
    'ppmTemplateId',
    'bankAccountId',
    'shouldResetSignedInvestments',
  ]

  if (isAchOptionEnabled) {
    fieldNames.push('isAchFundingEnabled')
  }

  if (!isPartnerProject) {
    fieldNames = [
      'openDate',
      'closeDate',
      ...fieldNames
    ]
  }

  if (!hasMultipleSponsors) {
    fieldNames.push('nonAccreditedInvestorsMax')
    fieldNames.push('targetAmount')
  }

  fieldNames.push('tiers')

  const canUpdateProject =
    hasAccess(['projects-write']) || hasAccess(['fund-projects-write'])

  const canUpdatePartners = hasMultipleSponsors && canUpdateProject

  const investmentClasses = tiers
    .map(({ name, title }) => title || name)
    .join(", ")

  const bankAccount = bankAccountsMap[bankAccountId]

  const items = [
    {
      label: LABEL_TEMPLATE,
      value: ppmTemplateId ? "Yes" : "No",
    },
    {
      label: LABEL_BENEFICIARY,
      value: bankAccountId
        ? <Text strong>{bankAccount?.beneficiaryName}</Text>
        : "No",
    },
    {
      label: LABEL_BENEFICIARY_ACCOUNT,
      value: bankAccountId
        ? (
          <Space>
            {
              bankAccount?.isInvalid && (
                <Tooltip
                  placement="top"
                  title={LABEL_INVALID}
                >
                  <WarningOutlined style={{ color: "red" }} />
                </Tooltip>
              )
            }
            <Text>
              {bankAccount?.sourceName}
            </Text>
            <TextLink onClick={openBankAccount}>
              <EyeOutlined />
            </TextLink>
          </Space>
        )
        : "No",
    },
    {
      label: LABEL_OPEN,
      value: <Value value={openDate} />,
    },
    {
      label: LABEL_CLOSE,
      value: <Value value={closeDate} />,
    },
    {
      label: LABEL_TARGET_AMOUNT,
      value:
        canUpdatePartners
          ? <Value onClick={openProjectPartnersModal} value={targetAmount} />
          : <Value value={targetAmount} />
    },
    {
      label: LABEL_NAI,
      value:
        canUpdatePartners
          ? (
              <TextLink onClick={openProjectPartnersModal}>
                {nonAccreditedInvestorsMax}
              </TextLink>
            )
          : nonAccreditedInvestorsMax
    },
    {
      label: LABEL_CLASSES,
      value: investmentClasses
    },
  ].map(({ label, value }, key) =>
    <Item
      key={key}
      label={label}
    >
      {value}
    </Item>
  )

  return (
    <ProjectEditableGroup
      title={LABEL_TITLE}
      fieldNames={fieldNames}
      extraProps={{ isEditable }}
      modalWidth={MODAL_WIDTH}
    >
      <Descriptions
        size="small"
        column={1}
        bordered={false}
        style={{marginBottom: 0}}
      >
        {items}
      </Descriptions>

      <BankAccountModal
        modal={bankAccountModal}
      />

      <ProjectPartnersModal
        modal={projectPartnersModal}
      />
    </ProjectEditableGroup>
  )
}

ProjectSettings.propTypes = {
  project: ProjectShape.isRequired,
}

export default ProjectSettings
