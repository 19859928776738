import get from "lodash.get"

import {
  CORPORATION,
  BusinessTypeEnumKeys,
} from "@api/services/transactions/shapes/BusinessTypeEnum"
import {
  LLC,
  S_CORP,
  C_CORP,
  PARTNERSHIP,
  EXEMPT_ORGANIZATION
} from "@api/services/investments/shapes/EntityTypeEnum"

const getBusinessTypes = investment => {
  if (!investment) {
    return BusinessTypeEnumKeys
  }

  const entityType = get(investment, 'profileEntity.entityType')
  const isLlc = entityType === LLC
  const isPartnership = entityType === PARTNERSHIP
  const isCorporation = [S_CORP, C_CORP].includes(entityType)
  const isLlcCorporation = entityType === EXEMPT_ORGANIZATION

  if (isLlc) {
    return [LLC]
  }

  if (isPartnership) {
    return [PARTNERSHIP]
  }

  if (isCorporation) {
    return [CORPORATION]
  }

  if (isLlcCorporation) {
    return [CORPORATION, LLC]
  }

  return BusinessTypeEnumKeys
}

export default getBusinessTypes
