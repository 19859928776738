import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import { StopOutlined } from "@ant-design/icons"
import { useOutletContext, useParams } from "react-router-dom"

import { W0 } from "@components"
import { useAppContext } from "@components/AppContext"

const LABEL_REMOVE = "Remove from the project"
const LABEL_REMOVE_MESSAGE = "Property $ is removed from the project"


const Footer = ({ closeModal, propertyId, propertyName }) => {
  const [ isRemoving, setIsRemoving ] = useState(false)

  const { id: projectId } = useParams()

  const { showSuccessMessage } = useAppContext()

  const {
    readProject,
    indexProjects,
    indexProperties,
    removeProjectProperty
  } = useOutletContext()

  const onClick = () => {
    setIsRemoving(true)
    return removeProjectProperty(projectId, propertyId)
      .then(() => Promise.all([
        readProject(),
        indexProjects(),
        indexProperties(),
      ]))
      .then(() => closeModal())
      .then(() => showSuccessMessage(LABEL_REMOVE_MESSAGE.replace("$", `"${propertyName}"`)))
      .finally(() => setIsRemoving(false))
  }

  return (
    <div style={{ textAlign: "center", marginBottom: W0 }}>
      <Button
        danger
        type="text"
        icon={<StopOutlined />}
        onClick={onClick}
        loading={isRemoving}
      >
        {LABEL_REMOVE}
      </Button>
    </div>
  )
}

Footer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  propertyId: PropTypes.string.isRequired,
  propertyName: PropTypes.string.isRequired,
}

export default Footer
