import last from "lodash.last"


const breakRow = items => {
  const count = items
    .map(item => item.span || 1)
    .reduce((acc, current) => acc + current, 0)

  const isEvenCol = count % 2 === 0

  if (!isEvenCol) {
    const lastItem = last(items)
    lastItem.span = 2
  }
}

export default breakRow
