import React from "react"

import { W0 } from "@components"
import { BackButton, SubmitButton, FormNavigationFooter } from "@components/Form"

const LABEL_SUBMIT = "Submit"
const LABEL_CONTINUE = "Continue"


const hideInput = input => {
  const { itemProps = {} } = input
  const { style: itemStyle = {} } = itemProps

  const { componentProps = {} } = input
  const { style: componentStyle = {} } = componentProps

  return {
    ...input,
    itemProps: {
      ...itemProps,
      style: {
        ...itemStyle,
        display: "none",
      }
    },
    componentProps: {
      ...componentProps,
      style: {
        ...componentStyle,
        display: "none",
      }
    }
  }
}

const getStepInputs = (currentStep, goStep, businessInputs, controllerInputs) => {
  const scrollTop = () => {
    const [ wrapEl ] = document.getElementsByClassName('ant-modal-wrap')
    wrapEl.scrollTo({ top: 0, behavior: "instant" })
  }

  const isFirstStep = currentStep === 0

  if (isFirstStep) {
    return [
      ...businessInputs,
      {
        name: "_continue",
        component: ({ form }) => {
          const onClick = () =>
            form
              .validateFields()
              .then(() => scrollTop())
              .then(() => goStep(currentStep + 1))
              .catch(() => false)

          return (
            <SubmitButton
              style={{ marginBottom: W0 }}
              onClick={onClick}
            >
              {LABEL_CONTINUE}
            </SubmitButton>
          )
        }
      }
    ]
  }

  const onBack = () => {
    scrollTop()
    goStep(0)
  }

  const hiddenInputs = businessInputs.map(hideInput)

  return [
    ...hiddenInputs,
    ...controllerInputs,
    {
      name: "_submit",
      component: ({ form }) => {
        const isLoading = form.isSubmitting()

        return (
          <FormNavigationFooter>
            <BackButton onClick={onBack} />

            <SubmitButton
              isLoading={isLoading}
            >
              {LABEL_SUBMIT}
            </SubmitButton>
          </FormNavigationFooter>
        )
      }
    }
  ]
}

export default getStepInputs
