import { PROJECT_TYPES } from "@components/Domain"


const computePropertyStatus = (property, getProject) => {
  const { projectId, status } = property

  if (!projectId) {
    return status
  }

  const project = getProject(projectId)

  if (!project) {
    return status
  }

  const isFundProject = project.type === PROJECT_TYPES.FUND

  if (isFundProject) {
    return status
  }

  return project.state
}

export default computePropertyStatus
