import React from "react"

import { UsDateInput } from "../../Input"


const renderDate = (itemProps, inputProps, form, path) => {
  return (
    <UsDateInput
      form={form}
      itemProps={itemProps}
      inputProps={inputProps}
      path={path}
    />
  )
}


export default renderDate
