import React from "react"
import PropTypes from "prop-types"

import { InvestmentShape } from "@components/Investment/shapes"

import getWireLabelKey from "./helpers/getWireLabelKey"
import { OpenWireModalButton } from "./WireModal"
import InvestmentProfileCardItem from "../InvestmentProfileCardItem"
import {
  INCOMPLETE_KEY,
  INCOMPLETE_STATUS,
  DANGER_STYLE_TYPE,
  WIRE_DEFAULT_LABELS_MAP
} from "./helpers/Constants"

const LABELS_MAP = {
  ...WIRE_DEFAULT_LABELS_MAP,
  [INCOMPLETE_KEY]: {
    status: INCOMPLETE_STATUS,
    subtitle: `Your funding is incomplete. Please be aware that the funding
deadline has passed. Make a wire transfer to proceed.`,
    styleType: DANGER_STYLE_TYPE
  },
}


const FundTargetDatePassedItem = ({
  investment,
  openWireModal,
  ...otherProps
}) => {
  const [ key, shouldRenderWireButton ] = getWireLabelKey(investment)
  const labels = LABELS_MAP[key]

  const itemProps = {
    ...labels,
    ...otherProps
  }

  return (
    <InvestmentProfileCardItem {...itemProps}>
      {
        shouldRenderWireButton && (
          <OpenWireModalButton onClick={openWireModal} />
        )
      }
    </InvestmentProfileCardItem>
  )
}

FundTargetDatePassedItem.propTypes = {
  investment: InvestmentShape.isRequired,
  openWireModal: PropTypes.func.isRequired,
}

export default FundTargetDatePassedItem
