import { ACTIVE } from "@api/services/investments/shapes/InvestmentStatusEnum"
import operationRequest from "@components/Authorization/request/operationRequest"
import { indexProjectInvestmentsOperation } from "@api/services/investments"


const getProjectInvestments = async (authorization, projectId) => {
  const parameters = {
    headers: { authorization },
    operation: indexProjectInvestmentsOperation,
    parameters: { projectId },
  }

  const { data: projectInvestments } = await operationRequest(parameters)

  const activeProjectInvestments = projectInvestments
    .filter(({ status }) => status === ACTIVE)

  return activeProjectInvestments
}

export default getProjectInvestments
