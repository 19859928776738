import React from "react"
import { UserAddOutlined, RedoOutlined, UserDeleteOutlined } from '@ant-design/icons'

import { hasAccess } from "@components/Authorization"

import useActions from "./useActions"

const LABEL_ENABLE = "Grant Access"
const LABEL_DISABLE = "Revoke Access"
const LABEL_CREATE_USER = "Create User"
const LABEL_RESET_PASSWORD = "Reset Password"


const useItems = (
  account,
  updateAccount,
  openResetIdentityModal,
  openCreateIdentityModal
) => {
  const {
    enableAccount,
    disableAccount
  } = useActions(account, updateAccount)

  const items = []

  if (!account) {
    return items
  }

  const {
    investor,
    isDisabled,
    correlationId,
    lastAuthenticatedAt
  } = account

  const hasEmail = !!investor.email
  const hasIdentity = !!correlationId
  const isNeverSignedIn = !lastAuthenticatedAt

  const canWriteAccounts = hasAccess(['accounts-write'])

  const isCreateIdentityAvailable =
    canWriteAccounts &&
    hasEmail &&
    !hasIdentity

  const isResetIdentityAvailable =
    canWriteAccounts &&
    hasEmail &&
    hasIdentity &&
    isNeverSignedIn

  if (isCreateIdentityAvailable) {
    items.push({
      key: "createIdentity",
      icon: <UserAddOutlined />,
      label: LABEL_CREATE_USER,
      onClick: openCreateIdentityModal
    })
  }

  if (isResetIdentityAvailable) {
    items.push({
      key: "resetIdentity",
      icon: <RedoOutlined />,
      label: LABEL_RESET_PASSWORD,
      onClick: openResetIdentityModal
    })
  }

  if (isDisabled) {
    items.push({
      key: "grantAccess",
      icon: <UserAddOutlined />,
      label: LABEL_ENABLE,
      onClick: enableAccount
    })

  } else {
    items.push({
      key: "revokeAccess",
      icon: <UserDeleteOutlined />,
      label: LABEL_DISABLE,
      danger: true,
      onClick: disableAccount,
    })

  }

  return items
}

export default useItems
