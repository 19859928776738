import { TRANSACTION_STATUS } from "@components/Domain"
import { getTransactionStatusLabel } from "@components/Transaction"


const computeStatus = transaction => {
  let { status } = transaction

  const { isApproved, retryTransactionId } = transaction

  const isPending = status === TRANSACTION_STATUS.PENDING
  const isPendingApproval = isPending && !isApproved

  const isRetried = !!retryTransactionId

  if (isRetried) {
    status = TRANSACTION_STATUS.RETRIED
  }

  if (isPendingApproval) {
    status = TRANSACTION_STATUS.PENDING_APPROVAL
  }

  const label = getTransactionStatusLabel(status)

  return label
}

export default computeStatus
