import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { Spin } from "antd"

import { Form } from "@components/Form"
import { Modal } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"
import {
  upgradeMyUnverifiedPersonalCustomerOperation as operation,
  readMyCustomerOperation
} from "@api/services/transactions"

import getInputs from "./helpers/getInputs"
import getInvestmentPersonalCustomer from "./helpers/getInvestmentPersonalCustomer"

const LABEL_TITLE = "Additional Details Required"
const LABEL_CONTINUE = "Continue"


const PersonalCustomerUpgradeModal = ({
  onSuccess: onCustomSuccess,
  title = LABEL_TITLE,
  isRetry = false,
  modal,
  investment,
  customerId,
}) => {
  const [ item, setItem ] = useState({})
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)

  const { getInvestorSsnNumber } = useOutletContext()

  const {
    authenticatedRequest,
    updateIdentityCustomer
  } = useAppContext()

  const getRetryCustomer = useCallback(async () => {
    setIsLoading(true)

    const { data } = await authenticatedRequest(readMyCustomerOperation, { id: customerId })
    const { personalCustomer } = data

    const investorSsn = getInvestorSsnNumber()
    const { ssn, dateOfBirth } = getInvestmentPersonalCustomer(investorSsn, investment)

    setIsLoading(false)
    return { ...personalCustomer, ssn, dateOfBirth }
  }, [authenticatedRequest, customerId, getInvestorSsnNumber, investment])

  useEffect(() => {
    if (!isOpen) {
      return
    }

    if (!isRetry) {
      const investorSsn = getInvestorSsnNumber()
      const personalCustomer = getInvestmentPersonalCustomer(investorSsn, investment)

      setItem({ id: customerId, personalCustomer })

      return
    }

    getRetryCustomer()
      .then(personalCustomer => setItem({ id: customerId, personalCustomer }))

  }, [ investment, customerId, isRetry, isOpen, getInvestorSsnNumber, getRetryCustomer ])

  const onClose = () => {
    setItem({})
    setIsOpen(false)
  }

  modal.current.open = () =>
    setIsOpen(true)

  const modalProps = {
    width: 420,
    title,
    isOpen,
    onClose,
  }

  const inputs = getInputs(isRetry)
  const schema = operation.getSchema(inputs)

  const onSuccess = data => {
    updateIdentityCustomer(data)
    onCustomSuccess(data)
    onClose()
  }

  const formOptions = {
    request: authenticatedRequest,
    submitLabel: LABEL_CONTINUE,
    shouldOptimizeUpdateMutation: false,
    item,
    schema,
    onSuccess,
    operation,
  }

  return (
    <Modal {...modalProps}>
      <Spin spinning={isLoading}>
        <Form {...formOptions} />
      </Spin>
    </Modal>
  )
}

PersonalCustomerUpgradeModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  title: PropTypes.string,
  isRetry: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  investment: InvestmentShape.isRequired,
  customerId: PropTypes.string.isRequired,
}

export default PersonalCustomerUpgradeModal
