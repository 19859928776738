import capitalize from "lodash.capitalize"

export const DEADLINE_PASSED = "DEADLINE_PASSED"
export const NOT_INTERESTED = "NOT_INTERESTED"
export const NO_ACCREDITATION = "NO_ACCREDITATION"
export const NO_REASON = "NO_REASON"

const RejectionReasonEnum = {
  [DEADLINE_PASSED]: DEADLINE_PASSED,
  [NOT_INTERESTED]: NOT_INTERESTED,
  [NO_ACCREDITATION]: NO_ACCREDITATION,
  [NO_REASON]: NO_REASON
}

const RejectionReasonEnumKeys = Object.keys(RejectionReasonEnum)

const RejectionReasonEnumOptions = RejectionReasonEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  RejectionReasonEnumKeys,
  RejectionReasonEnumOptions
}

export default RejectionReasonEnum
