

const IndexRecipientReportsItemSchema = {
  recipientEmail: { format: 'email' },
  sentAt: { format: 'date-time' },
  deliveredAt: { format: 'date-time' },
  openedAt: { format: 'date-time' },
  openedCount: { type: 'integer' },
  clickedAt: { format: 'date-time' },
  clickedCount: { type: 'integer' },
  unsubscribedAt: { format: 'date-time' },
  isComplaint: { type: 'boolean' },
  isSoftBounce: { type: 'boolean' },
  isHardBounce: { type: 'boolean' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexRecipientReportsItemSchema
