import PropTypes from "prop-types"

import UsAddressShape from "./shapes/UsAddressShape"
import { BankAccountTypeEnumKeys } from "./shapes/BankAccountTypeEnum"

const properties = {
  accountNumber: PropTypes.string.isRequired,
  accountType: PropTypes.oneOf(BankAccountTypeEnumKeys).isRequired,
  alternativeRoutingNumber: PropTypes.string,
  bankAddress: UsAddressShape.isRequired,
  bankCodeName: PropTypes.string.isRequired,
  bankName: PropTypes.string.isRequired,
  beneficiaryAddress: UsAddressShape.isRequired,
  beneficiaryName: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  id: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  isSourceVerified: PropTypes.bool.isRequired,
  organizationId: PropTypes.string,
  routingNumber: PropTypes.string.isRequired,
  sourceId: PropTypes.string.isRequired,
  sourceName: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const CreateBankAccountOutputShapeShape = PropTypes.exact(properties)

export default CreateBankAccountOutputShapeShape
export { properties }
