import { INVESTMENT_PROFILE_FIELD_MAP } from "@components/Domain"
import { isSdiraInvestment, isJointInvestment, isIndividualInvestment } from "@components/Investment"


const hasTin = investment => {
  const isSdira = isSdiraInvestment(investment)
  const isJoint = isJointInvestment(investment)
  const isIndividual = isIndividualInvestment(investment)

  const { profileType } = investment
  const profile = investment[INVESTMENT_PROFILE_FIELD_MAP[profileType]] || {}

  const hasTaxIdentificationNumber = () => {
    // NOTE: For SDIRA profiles, EIN is a required field if other custodian is
    //       selected.
    if (isSdira) {
      return true
    }

    if (isJoint || isIndividual) {
      return !!profile.ssnNumber
    }

    const { isDisregardedForTaxPurposes } = profile

    if (!isDisregardedForTaxPurposes) {
      return !!profile.einNumber
    }

    return !!profile.beneficialOwnerSsnNumber
      || !!profile.beneficialOwnerEinNumber
      || !!profile.beneficialOwnerCustodianEinNumber
  }

  const isTinRequiredAndPresent = hasTaxIdentificationNumber()

  return isTinRequiredAndPresent
}

export default hasTin
