import get from "lodash.get"


const getExportData = (columns, items) => {
  const hasColumns = columns.length > 0

  if (!hasColumns) {
    return items
  }

  const data = items.map(item => {
    const row = {}

    for (const { title, value } of columns) {
      const isPath = typeof value === "string"

      const path = isPath ? value : undefined
      const compute = !isPath ? value : undefined

      row[title] = ""

      if (path) {
        row[title] = get(item, path)
      }

      if (compute) {
        row[title] = compute(item)
      }
    }

    return row
  })

  return data
}

export default getExportData
