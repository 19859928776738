import React from "react"

import { DomainInput } from "../../Input"


const renderDomain = (itemProps, inputProps, form, path) => {
  return (
    <DomainInput
      itemProps={itemProps}
      inputProps={inputProps}
      form={form}
      path={path}
    />
  )
}

export default renderDomain
