import React from "react"

import { Value } from "@components/Text"
import { PROJECT_DATES_MAP } from "@components/Domain"

const COLOR_PAST = "blue"
const COLOR_FUTURE = "grey"


const getProjectTimelineItems = project => {
  const {
    openDate,
    targetCloseDate,
    signDeadlineDate,
    fundDeadlineDate,
  } = project

  const dates = [
    openDate,
    signDeadlineDate,
    fundDeadlineDate,
    targetCloseDate
  ]

  const items = dates
    .map((isoDate, index) => {
      const projectDateKeys = Object.keys(PROJECT_DATES_MAP)

      const key = projectDateKeys[index]
      const label = PROJECT_DATES_MAP[key]

      const today = new Date()
      const isPast = Date.parse(isoDate) - today < 0

      const item = {
        color: isPast ? COLOR_PAST : COLOR_FUTURE,
        label: <Value value={isoDate} />,
        children: label,
      }

      return item
    })

  return items
}

export default getProjectTimelineItems
