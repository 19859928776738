import cloneDeep from "lodash.clonedeep"

import { useAppContext } from "@components/AppContext"
import { getFormattedAmountValue } from "@components/Amount"

import validateTransactions from "./validateTransactions"


const useValidateTransaction = (projectId, investments) => {
  const { request, showErrorMessage } = useAppContext()

  return async parameters => {
    const { mutation: transaction } = cloneDeep(parameters)

    const investment = investments
      .find(({ id }) => transaction.investmentId === id)

    const { name: profileName } = investment ?? {}

    const { amount } = transaction

    const transactions = [{
      ...transaction,
      amount: getFormattedAmountValue(amount),
      profileName,
    }]

    const errors =
      await validateTransactions(request, projectId, transactions, investments)

    const isTransactionValid = errors.length === 0

    if (isTransactionValid) {
      return parameters
    }

    const [ error ] = errors
    const { reason } = error

    showErrorMessage(reason)
    throw new Error(reason)
  }
}

export default useValidateTransaction
