import React from "react"
import PropTypes from "prop-types"

import { Drawer } from "@components/Page"
import { W0, HEADER_HEIGHT } from "@components"

import OptionsCollection from "./OptionsCollection"

const LABEL_TITLE = "Edit Tags"

const DRAWER_WIDTH = 420


const TagsDrawer = ({
  placement = "right",
  group,
  drawer,
  tagOptions
}) => {
  const styles = {
    body: { paddingTop: W0 },
    header: { minHeight: HEADER_HEIGHT + 1 },
  }

  return (
    <Drawer
      width={DRAWER_WIDTH}
      title={LABEL_TITLE}
      styles={styles}
      drawer={drawer}
      placement={placement}
    >
      <OptionsCollection
        group={group}
        items={tagOptions}
      />
    </Drawer>
  )
}

TagsDrawer.propTypes = {
  group: PropTypes.string.isRequired,
  drawer: PropTypes.shape().isRequired,
  placement: PropTypes.string,
  tagOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export default TagsDrawer
