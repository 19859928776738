import React from "react"
import { App } from "antd"
import { ExclamationCircleFilled } from "@ant-design/icons"
import { useOutletContext } from "react-router-dom"

import { useAppContext } from "@components/AppContext"

import { deleteUserGroupOperation as deleteOperation } from "@api/services/backstage"

const LABEL_YES = "Yes"
const LABEL_TITLE = "Delete Group"
const LABEL_CONTENT = `Group is going to be deleted, group users will loose
access to linked organizations.`
const LABEL_SUCCESS_MESSAGE = "Group $ is deleted"


const useAction = (userGroup, removeUserGroup) => {
  const { modal: deleteModal } = App.useApp()
  const { request, showSuccessMessage } = useAppContext()
  const { indexUsers } = useOutletContext()

  return id => deleteModal.confirm({
    icon: <ExclamationCircleFilled />,
    title: LABEL_TITLE,
    content: LABEL_CONTENT,
    okText: LABEL_YES,
    okType: "danger",
    onOk() {
      return request(deleteOperation, { id })
        .then(() => removeUserGroup(id))
        .then(() => indexUsers())
        .then(() => {
          const { name } = userGroup
          showSuccessMessage(LABEL_SUCCESS_MESSAGE.replace("$", `"${name}"`))
        })
    },
    onCancel() {}
  })
}

export default useAction
