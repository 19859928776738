import { useState } from "react"

import { useRequest as useEffect } from "@components/AppContext"
import { readMyFundingBankAccountOperation } from "@api/services/transactions"

const EXPTECTED_ERRORS = [ "DocumentNotFoundError" ]


const useFundingBankAccount = (investment) => {
  const [ isInvalid, setIsInvalid ] = useState(false)
  const [ bankAccount, setBankAccount ] = useState()

  const { id: investmentId } = investment

  useEffect(request => {
    request(readMyFundingBankAccountOperation, { investmentId }, EXPTECTED_ERRORS)
      .then(({ data }) => setBankAccount(data))
      .catch(exception => {
        const { isExpected } = exception

        if (isExpected) {
          setIsInvalid(true)
        }
      })
  }, [ investmentId ])

  return [ isInvalid, bankAccount ]
}

export default useFundingBankAccount
