import React from "react"
import PropTypes from "prop-types"


import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"
import { IndexPublishedProjectsOutputShape } from "@api/services/backstage"

import useAchLabelKey from "./helpers/useAchLabelKey"
import FundInvestment from "./FundInvestment"
import useInvestmentCustomer from "./helpers/useInvestmentCustomer"
import useAchFundsArrivalDate from "./helpers/useAchFundsArrivalDate"
import { OpenWireModalButton } from "./WireModal"
import InvestmentProfileCardItem from "../InvestmentProfileCardItem"

import {
  PENDING_KEY,
  COMPLETE_KEY,
  ACH_PENDING_KEY,
  CONNECT_SOURCE_KEY,
  CUSTOMER_SUSPENDED_KEY,
  INCOMPLETE_KEY,
  OVERFUNDED_KEY,
  PARTIALLY_FUNDED_KEY,
  INVALID_INVESTOR_BANK_ACCOUNT_KEY,
  INSUFFICIENT_FUNDS_INVESTOR_BANK_ACCOUNT_KEY,
  PENDING_STATUS,
  COMPLETE_STATUS,
  OVERFUNDED_STATUS,
  INCOMPLETE_STATUS,
  PARTIALLY_FUNDED_STATUS,
  PENDING_SUBTITLE,
  COMPLETE_SUBTITLE,
  DANGER_STYLE_TYPE,
  SUCCESS_STYLE_TYPE,
  WARNING_STYLE_TYPE,
} from "./helpers/Constants"

const LABELS_MAP = {
  [CONNECT_SOURCE_KEY]: {
    status: INCOMPLETE_STATUS,
    subtitle: `Your funding is incomplete. Click "Connect" to begin the process
to electronically fund via ACH.

Alternatively, if you prefer to send funds manually via wire, click "Connect"
and "View and Download Wiring Instructions" to download the wiring instructions
and send your investment through a wire transfer.`,
    styleType: WARNING_STYLE_TYPE,
  },
  [INCOMPLETE_KEY]: {
    status: INCOMPLETE_STATUS,
    subtitle: `Your funding is incomplete. Click the "Send Funds" button or make
a wire transfer to proceed.`,
    styleType: DANGER_STYLE_TYPE,
  },
  [COMPLETE_KEY]: {
    status: COMPLETE_STATUS,
    subtitle: COMPLETE_SUBTITLE,
    styleType: SUCCESS_STYLE_TYPE,
  },
  [OVERFUNDED_KEY]: {
    status: OVERFUNDED_STATUS,
    subtitle: `You've sent more funds than your committed amount. Please contact
support for assistance.`,
    styleType: DANGER_STYLE_TYPE,
  },
  [PARTIALLY_FUNDED_KEY]: {
    status: PARTIALLY_FUNDED_STATUS,
    subtitle: `Received amount doesn't match the committed amount. Add funds via
wire transfer to match your commitment or contact support for assistance.`,
    styleType: DANGER_STYLE_TYPE
  },
  [PENDING_KEY]: {
    status: PENDING_STATUS,
    subtitle: PENDING_SUBTITLE,
    styleType: WARNING_STYLE_TYPE,
  },
  [ACH_PENDING_KEY]: {
    status: PENDING_STATUS,
    subtitle: `Your electronic transfer is pending. Once the funds are received,
the status will be updated to "Complete." This process typically takes 4-7
business days (excluding holidays and weekends). Your funds will most likely
arrive by [FUNDS_ARRIVAL_DATE].`,
    styleType: WARNING_STYLE_TYPE,
  },
  [CUSTOMER_SUSPENDED_KEY]: {
    status: INCOMPLETE_STATUS,
    subtitle: `Identity verification did not succeed. Please click "View and
Download Wiring Instructions" to download the wiring instructions and send your
investment through a wire transfer.`,
    styleType: DANGER_STYLE_TYPE,
  },
  [INVALID_INVESTOR_BANK_ACCOUNT_KEY]: {
    status: INCOMPLETE_STATUS,
    subtitle: `The bank account initially linked is invalid and funds have not
been debited from your account. Please connect a new valid funding bank
account.

Once you have connected a new valid funding bank account, click "Send Funds" to
initiate your investment funds transfer to the [ORGANIZATION_NAME] bank
account.`,
    styleType: WARNING_STYLE_TYPE,
  },
  [INSUFFICIENT_FUNDS_INVESTOR_BANK_ACCOUNT_KEY]: {
    status: INCOMPLETE_STATUS,
    subtitle: `The bank account initially linked does not have sufficient funds.
Please add funds to the bank account or connect a new funding bank account.

Once the issue is addressed, click "Send Funds" to initiate your investment
funds transfer to the [ORGANIZATION_NAME] bank account.`,
    styleType: WARNING_STYLE_TYPE,
  },
}


const FundInvestmentItem = ({
  project,
  investment,
  openWireModal,
  ...otherProps
}) => {
  const { getOrganizationName } = useAppContext()
  const organizationName = getOrganizationName()

  const customer = useInvestmentCustomer(investment)
  const achFundsArrivalDate = useAchFundsArrivalDate(investment)

  const [ key, shouldRenderAch, shouldRenderWire ] = useAchLabelKey(investment, customer)
  const labels = LABELS_MAP[key]

  const subtitle = labels.subtitle
    .replace('[ORGANIZATION_NAME]', organizationName)
    .replace('[FUNDS_ARRIVAL_DATE]', achFundsArrivalDate)

  const itemProps = {
    ...labels,
    ...otherProps,
    subtitle
  }

  return (
    <InvestmentProfileCardItem {...itemProps}>
      {
        shouldRenderAch && (
          <FundInvestment
            project={project}
            customer={customer}
            investment={investment}
            onOpenFundingModal={openWireModal}
          />
        )
      }

      {
        shouldRenderWire && (
          <OpenWireModalButton
            onClick={openWireModal}
          />
        )
      }
    </InvestmentProfileCardItem>
  )
}

FundInvestmentItem.propTypes = {
  project: IndexPublishedProjectsOutputShape.isRequired,
  investment: InvestmentShape.isRequired,
  openWireModal: PropTypes.func.isRequired,
}

export default FundInvestmentItem
