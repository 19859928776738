import { readString } from "react-papaparse"

import parseCSV from "./parseCsv"


const getCustomRequest = (parse, validate, build, callbackSuccess, callbackUpload) => {
  return ({ file, onSuccess }) => {
    setTimeout(() => {
      if (callbackUpload) {
        callbackUpload(file)
      }

      const reader = new FileReader()

      reader.onload = (e) => {
        const { data } = readString(e.target.result.trim())
        const items = parseCSV(data, parse, validate, build)

        callbackSuccess(items)
      }

      reader.readAsText(file)
      onSuccess("ok")
    }, 0)
  }
}

export default getCustomRequest
