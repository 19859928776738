import { parsePhoneNumber } from "awesome-phonenumber"


const formatPhoneNumber = value => {
  const { valid: isValid, number: phoneNumber, regionCode } = parsePhoneNumber(`+${value}`)

  if (!isValid) {
    return
  }

  const isUs = regionCode === "US"

  const { international, national } = phoneNumber

  const result = isUs
    ? national
    : international

  return result
}

export default formatPhoneNumber
