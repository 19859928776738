import React from "react"
import PropTypes from "prop-types"
import { Card, Space, Button, Typography } from "antd"

import { W0, W1 } from "@components"

const LABEL_GENERATE = "Generate"


const ReportCard = ({
  title,
  summary,
  onClick = undefined,
  renderAction = undefined
}) => {
  const renderButton = () => {
    if (renderAction) {
      return renderAction()
    }

    return (
      <Button
        type="primary"
        onClick={onClick}
        style={{ marginTop: W1 }}
      >
        {LABEL_GENERATE}
      </Button>
    )
  }

  return (
    <Card>
      <Space direction="vertical" size={W0 / 2}>
        <Typography.Text strong>{title}</Typography.Text>

        <Typography.Text>{summary}</Typography.Text>

        {renderButton()}
      </Space>
    </Card>
  )
}

ReportCard.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  renderAction: PropTypes.func,
}

export default ReportCard
