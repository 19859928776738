import React from "react"
import { Space, Typography } from "antd"

import { Value } from "@components/Text"

import renderAdditional from "./renderAdditional"

const LABEL_NAME = "Full Legal SDIRA Account Name"
const LABEL_CUSTODIAN = "Custodian"
const LABEL_INVESTMENTS = "Investments"


const renderProfileSdira = (profileSdira, formattedInvestments, investor, additionalExtra) => {
  const {
    accountName,
    custodianName,
    custodianAddress = {},
    custodianEinNumber
  } = profileSdira

  const items = []

  const isOtherCustodian = !custodianAddress.addressRegion

  items.push({
    span: 2,
    label: LABEL_NAME,
    value: accountName,
  })

  if (formattedInvestments) {
    items.push({
      span: 2,
      label: LABEL_INVESTMENTS,
      value: formattedInvestments
    })
  }

  items.push({
    span: 2,
    label: LABEL_CUSTODIAN,
    value: isOtherCustodian
      ? "Other"
      : (
        <Space direction="vertical" size={0}>
          <Typography.Text>
            <Typography.Text strong>
              {custodianName}
            </Typography.Text>
            {" "}
            <Typography.Text type="secondary">
              (<Value value={custodianEinNumber} isEin />)
            </Typography.Text>
          </Typography.Text>

          <Value value={custodianAddress} />
        </Space>
      )
  })

  items.push(renderAdditional(profileSdira, additionalExtra))

  return items
}

export default renderProfileSdira
