import React from "react"
import { Tag, Badge } from "antd"
import Highlighter from "react-highlight-words"

import { W0, HIGHLIGHT_STYLE } from "@components"
import { STATE_LABELS_MAP, PROJECT_STATES, PROJECT_TYPES } from "@components/Domain"

const STATE_COLORS_MAP = {
  [PROJECT_STATES.HOLD]: "orange",
  [PROJECT_STATES.OPEN]: "blue",
  [PROJECT_STATES.CLOSED]: "green",
  [PROJECT_STATES.ARCHIVED]: "violet"
}


const renderProjectLabel = (name, shortName, state, type, searchQuery = "") => {
  const isFund = type === PROJECT_TYPES.FUND

  const text = shortName
    ? shortName
    : name

  const title = text

  const shouldHighlight = searchQuery.length > 0

  const value = shouldHighlight
    ? (
        <Highlighter
          className="highlighter-container"
          autoEscape
          searchWords={[ searchQuery ]}
          highlightStyle={HIGHLIGHT_STYLE}
          textToHighlight={text}
        />
      )
    : title

  return (
    <>
      {value}

      {
        isFund && (
          <Badge
            color="blue"
            style={{ position: "absolute", left: W0 + 4 }}
          />
        )
      }

      <Tag
        color={STATE_COLORS_MAP[state]}
        style={{ position: "absolute", top: W0 + 1, right: 0 }}
        bordered={false}
      >
        {STATE_LABELS_MAP[state]}
      </Tag>
    </>
  )
}

export default renderProjectLabel
