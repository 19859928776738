import { TYPE_STRING } from "@components/Form"


const getInvestorInputs = () => {
  return [
    {
      name: "subject",
      type: TYPE_STRING,
      placeholder: "Subject of your request"
    },
    {
      name: "message",
      placeholder: "",
      inputProps: {
        rows: 5
      }
    },
  ]
}

export default getInvestorInputs
