import capitalize from "lodash.capitalize"

export const ARCHIVED = "ARCHIVED"
export const CLOSED = "CLOSED"
export const HOLD = "HOLD"
export const OPEN = "OPEN"

const ProjectStateEnum = {
  [ARCHIVED]: ARCHIVED,
  [CLOSED]: CLOSED,
  [HOLD]: HOLD,
  [OPEN]: OPEN
}

const ProjectStateEnumKeys = Object.keys(ProjectStateEnum)

const ProjectStateEnumOptions = ProjectStateEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  ProjectStateEnumKeys,
  ProjectStateEnumOptions
}

export default ProjectStateEnum
