import { UserGroupRoleEnumKeys } from "./shapes/UserGroupRoleEnum"

const IndexUserGroupsItemSchema = {
  email: { format: 'email' },
  role: { enum: UserGroupRoleEnumKeys },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexUserGroupsItemSchema
