import dayjs from "dayjs"

import { getOffset } from "@components/Date"
import { DEFAULT_TIMEZONE } from "@components/Form/Input/DateTimeInput/SelectTimezone"
import { getTimezoneByOffset } from "@components/Form/Input/DateTimeInput"


const range = (start, end) => {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}


const isDisabledScheduledAt = (date, form, includeTime = false) => {
  const { scheduledAt } = form.getFieldsValue()

  let timeZone = DEFAULT_TIMEZONE
  if (scheduledAt) {
    const offset = getOffset(scheduledAt)
    timeZone = getTimezoneByOffset(offset)
  }

  const todayPlusOneDay = dayjs().tz(timeZone).add(24, "hour")
  const dateWithTimeZone = date.tz(timeZone, true)
  const isDisabled = dateWithTimeZone.isSameOrBefore(todayPlusOneDay)

  if (!includeTime) {
    return isDisabled
  }

  const isSameDay = todayPlusOneDay.isSame(dateWithTimeZone, 'day')

  if (!isSameDay) {
    return {}
  }

  const dateWithTimeZoneStartDay = dateWithTimeZone.startOf("day")
  const dateWithTimeZoneStartHour = dateWithTimeZone.startOf("hour")
  const differenceInHours = todayPlusOneDay.diff(dateWithTimeZoneStartDay, "hour")
  const differenceInMinutes = todayPlusOneDay.diff(dateWithTimeZoneStartHour, "minute")

  return {
    disabledHours: () => range(0, differenceInHours),
    disabledMinutes: () => range(0, differenceInMinutes),
  }
}

export default isDisabledScheduledAt
