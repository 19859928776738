import { PROJECT_TAG_LABELS } from "@components/Domain"
import { createTagsListInput } from "@components/Form/Input/TagsListInput"


const ProjectDealTags = createTagsListInput({
  name: "_dealTags",
  group: "DEAL",
  title: "Deal Information",
  labels: PROJECT_TAG_LABELS
})

export default ProjectDealTags
