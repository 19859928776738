import { useState, useCallback } from "react"
import PropTypes from "prop-types"

import { hasAccess } from "@components/Authorization"
import { useAppContext } from "@components/AppContext"
import {
  readMyOrganizationOperation,
  updateMyOrganizationDomainStatusOperation,
  resetMyOrganizationEmailIdentityOperation,
  verifyMyOrganizationEmailIdentityOperation,
  updateMyOrganizationEmailVerificationStatusOperation,
  ReadMyOrganizationOutputShape,
  ReadPublicOrganizationOutputShape
} from "@api/services/organizations"
import UserOrganizationShape
  from "@api/services/backstage/shapes/UserOrganizationShape"


const useOrganizationStore = (getOrganizationFromContext) => {
  const [ organization, setOrganization ] = useState()

  const { request, identity } = useAppContext()

  const canRead = hasAccess(['org-read'])

  const readOrganization = useCallback(() => {
    if (!canRead) {
      return setOrganization(getOrganizationFromContext())
    }

    return request(readMyOrganizationOperation)
      .then(({ data }) => setOrganization(data))
  }, [ request, canRead, getOrganizationFromContext ])

  const updateOrganizationDomainStatus = () =>
    request(updateMyOrganizationDomainStatusOperation)
      .then(({ data }) => {
        setOrganization(data)
        return data
      })

  const updateOrganizationEmailVerificationStatus = useCallback(() =>
    request(updateMyOrganizationEmailVerificationStatusOperation)
      .then(({ data }) => {
        setOrganization(data)
        return data
      })
  , [ request ])

  const resetOrganizationEmailIdentity = () =>
    request(resetMyOrganizationEmailIdentityOperation)
      .then(({ data }) => {
        setOrganization(data)
        return data
      })

  const verifyOrganizationEmailIdentity = () =>
    request(verifyMyOrganizationEmailIdentityOperation)
      .then(({ data }) => {
        setOrganization(data)
        return data
      })

  const updateOrganization = setOrganization

  const getOrganizationControllerId = () => {
    const { controllerId } = organization

    return controllerId
  }

  const isOrganizationController = () => {
    const controllerId = getOrganizationControllerId()

    if (!identity) {
      return false
    }

    const { id: userId } = identity

    return userId === controllerId
  }

  return {
    organization,
    readOrganization,
    updateOrganization,
    isOrganizationController,
    getOrganizationControllerId,
    updateOrganizationDomainStatus,
    resetOrganizationEmailIdentity,
    verifyOrganizationEmailIdentity,
    updateOrganizationEmailVerificationStatus,
    // getOrganization,
    // getOrganizationId,
    // getOrganizationName,
    // getOrganizationEmail,
  }
}

const organizationStoreProperties = {
  organization: PropTypes.oneOfType([
    ReadPublicOrganizationOutputShape,
    ReadMyOrganizationOutputShape,
    UserOrganizationShape
  ]),
  readOrganization: PropTypes.func.isRequired,
  updateOrganization: PropTypes.func.isRequired,
  isOrganizationController: PropTypes.func.isRequired,
  getOrganizationControllerId: PropTypes.func.isRequired,
  updateOrganizationDomainStatus: PropTypes.func.isRequired,
  resetOrganizationEmailIdentity: PropTypes.func.isRequired,
  verifyOrganizationEmailIdentity: PropTypes.func.isRequired,
  updateOrganizationEmailVerificationStatus: PropTypes.func.isRequired,
}

export default useOrganizationStore

export {
  organizationStoreProperties
}
