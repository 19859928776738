import uniq from "lodash.uniq"

import { PROPERTY_TAG_LABELS } from "@components/Domain"


const getPropertyTagLabelOptions = (properties = []) => {
  const labels = properties
    .reduce((result, { tags = [] }) => [...result, ...tags], [])
    .map(({ label }) => label)

  const uniqLabels = uniq([...PROPERTY_TAG_LABELS, ...labels]).sort()

  const options = uniqLabels.map(item => ({ value: item, label: item }))

  return options
}

export default getPropertyTagLabelOptions
