import capitalize from "lodash.capitalize"

export const BLUE = "BLUE"
export const CYAN = "CYAN"
export const GEEKBLUE = "GEEKBLUE"
export const GOLD = "GOLD"
export const GREEN = "GREEN"
export const LIME = "LIME"
export const MAGENTA = "MAGENTA"
export const ORANGE = "ORANGE"
export const PINK = "PINK"
export const PURPLE = "PURPLE"
export const RED = "RED"
export const VOLCANO = "VOLCANO"
export const YELLOW = "YELLOW"

const ColorEnum = {
  [BLUE]: BLUE,
  [CYAN]: CYAN,
  [GEEKBLUE]: GEEKBLUE,
  [GOLD]: GOLD,
  [GREEN]: GREEN,
  [LIME]: LIME,
  [MAGENTA]: MAGENTA,
  [ORANGE]: ORANGE,
  [PINK]: PINK,
  [PURPLE]: PURPLE,
  [RED]: RED,
  [VOLCANO]: VOLCANO,
  [YELLOW]: YELLOW
}

const ColorEnumKeys = Object.keys(ColorEnum)

const ColorEnumOptions = ColorEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  ColorEnumKeys,
  ColorEnumOptions
}

export default ColorEnum
