import keyBy from "lodash.keyby"

import {
  disconnectPartnerOperation,
  connectFundPartnerOperation,
  connectSponsorPartnerOperation,
} from "@api/services/backstage"


const getToggleDisableItemsMap = (isDisabled, items) => {
  const newItems = items.map(item => ({
    ...item,
    disabled: isDisabled
  }))

  const itemsMap = keyBy(newItems, "id")

  return itemsMap
}


const disconnectPartners = async (request, partners) => {
  for (const partner of partners) {
    const { projectPartnerId } = partner

    await request(disconnectPartnerOperation, { id: projectPartnerId })
  }

  const partnerData = partners.map(({id}) => ({
    id,
    projectPartnerId: undefined,
    connected: false
  }))

  return partnerData
}


const connectPartners = async (request, partners) => {
  const partnersData = []

  for (const partner of partners) {
    const { id, type, goal, limit, projectId } = partner

    const isSponsor = type === "sponsor"

    const operationId = isSponsor
      ? connectSponsorPartnerOperation
      : connectFundPartnerOperation

    const parameters = isSponsor
      ? { mutation: { projectId, sponsorId: id, targetAmount: goal, nonAccreditedInvestorsMax: limit } }
      : { mutation: { projectId, fundId: id, targetAmount: goal }  }

    await request(operationId, parameters)
      .then(({ data }) => partnersData.push({
        connected: true,
        projectPartnerId: data.id,
        id
      }))
  }

 return partnersData
}


const getSelectionChange = (request, updatePartners, items) => {
  const onChange = async (selectedRowKeys, selectedRows) => {
    const hasNoSelectedRows = selectedRows.length === 0

    if (hasNoSelectedRows) {
      const disabledItemsMap = getToggleDisableItemsMap(true, items)
      updatePartners(disabledItemsMap)

      const connectedPartners = items.filter(item => item.connected)

      const newDisconnectedItems = await disconnectPartners(request, connectedPartners)

      const enabledItemsMap = getToggleDisableItemsMap(false, newDisconnectedItems)
      return updatePartners(enabledItemsMap, true)
    }

    const partnersToConnect = items
      .filter(item => selectedRowKeys.includes(item.id))
      .filter(item => !item.connected)

    const partnersToDisconnect = items
      .filter(item => !selectedRowKeys.includes(item.id))
      .filter(item => item.connected)

    const partnersToMutate = [...partnersToConnect, ...partnersToDisconnect]

    const disabledItemsMap = getToggleDisableItemsMap(true, partnersToMutate)
    updatePartners(disabledItemsMap)

    const newConnectedItems = await connectPartners(request, partnersToConnect)
    const newDisconnectedItems = await disconnectPartners(request, partnersToDisconnect)

    const newItems = [...newConnectedItems, ...newDisconnectedItems]

    const enabledItemsMap = getToggleDisableItemsMap(false, newItems)
    updatePartners(enabledItemsMap, true)
  }

  return onChange
}

export default getSelectionChange
