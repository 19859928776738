import { useMemo } from "react"
import sortBy from "lodash.sortby"
import { useOutletContext } from "react-router-dom"

const LABEL_LISTS = "Lists"
const LABEL_INVESTORS = "Investors"


const useOptions = (subscriptionTypeId, isInvestorOptedOut) => {
  const {
    segments,
    accounts,
    computeAccountFullname,
  } = useOutletContext()

  return useMemo(() => {
    const segmentOptions =  segments
      .map(({ name: label, id: value }) => ({ label, value }))

    let accountOptions = accounts
      .filter(({ isDisabled }) => !isDisabled)
      .map(({ isSubscribedForCampaigns, id: value, investor }) => {
        const investorFullname = computeAccountFullname(value)

        let label = investorFullname

        if (!isSubscribedForCampaigns) {
          label = `${investorFullname} (not subscribed)`
        }

        const isOptedOut = isInvestorOptedOut(investor)

        if (isOptedOut) {
          label = `${investorFullname} (opted out)`
        }

        const disabled = !isSubscribedForCampaigns || isOptedOut

        return {
          label,
          value,
          disabled
        }
      })

    accountOptions = sortBy(accountOptions, 'label')

    return [
      {
        label: LABEL_LISTS,
        title: 'segments',
        options: segmentOptions
      },
      {
        label: LABEL_INVESTORS,
        title: 'investors',
        options: accountOptions
      }
    ]
  }, [
    segments,
    accounts,
    isInvestorOptedOut,
    computeAccountFullname
  ])
}

export default useOptions
