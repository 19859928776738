import React from "react"
import { Typography } from "antd"

import { Value } from "@components/Text"

const { Text } = Typography


const renderLabel = (text, secondaryText) => {
  return (
    <div>
      {text}
      <br/>
      <Text style={{ fontWeight: 400 }} >
        {secondaryText}
      </Text>
    </div>
  )
}


const getItems = ({
  isWire,
  extraItems,
  bankAccount,
  referenceNote,
}) => {
  const {
    bankName,
    bankCodeName,
    bankAddress,
    routingNumber,
    accountNumber,
    beneficiaryName,
    beneficiaryAddress,
    alternativeRoutingNumber,
  } = bankAccount

  const routingNumbers = isWire
    ? [
        {
          label: "Routing Number",
          value: alternativeRoutingNumber
        }
      ]
    : [
        {
          label: "ACH Routing Number",
          value: routingNumber
        },
        {
          label: "Wire Routing Number",
          value: alternativeRoutingNumber
        }
      ]

  const items = [
    {
      label: "Beneficiary Legal Name",
      value: beneficiaryName,
    },
    {
      label: renderLabel("Beneficiary Address", "(if required)"),
      value: beneficiaryAddress ? <Value value={beneficiaryAddress} /> : "",
    },
    {
      label: "Beneficiary Account Number ",
      value: accountNumber
    },
    ...routingNumbers,
    {
      label: "Bank Name",
      value: bankName,
    },
    {
      label: "Bank Code Name",
      value: bankCodeName,
    },
    {
      label: renderLabel("Bank Address", "(if required)"),
      value: bankAddress ? <Value value={bankAddress} /> : "",
    }
  ]

  if (referenceNote) {
    items.push({
      label: renderLabel("Reference Information", "(to verify source of funds)"),
      value: referenceNote
    })
  }

  const extendedItems = items.concat(extraItems)

  return extendedItems
}

export default getItems
