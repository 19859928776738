import React from "react"

import { W0, W1 } from "@components"
import { Disclaimer } from "@components/Text"
import { TYPE_RADIO } from "@components/Form"

const LABEL_TEST = "Test Scenario"
const LABEL_TEST_SCENARIO = "Test scenario option is available only for dev environment"

const TEST_SCENARIO_OPTIONS = [
  {
    value: "CONTROLLER_FAILED",
    label: "Controller verification failed",
  },
  {
    value: "BUSINESS_FAILED",
    label: "Business verification failed",
  },
  {
    value: "CONTROLLER_BUSINESS_FAILED",
    label: "Controller & business verification failed",
  },
  {
    value: "SUCCESS",
    label: "Verification success",
  },
]


const getBusinessTestScenarioInput = () => {
  const input = {
    name: "testScenario",
    type: TYPE_RADIO,
    label: LABEL_TEST,
    options: TEST_SCENARIO_OPTIONS,
    required: false,
    itemProps: {
      style: { marginTop: W1 },
      extra: <Disclaimer text={LABEL_TEST_SCENARIO} style={{ marginTop: W0 }} />
    }
  }

  return input
}

export default getBusinessTestScenarioInput
