import React from "react"

import CustomerShape from "../shapes/CustomerShape"
import { CUSTOMER_NAME_PERSONAL } from "../CustomerCreateButton"
import PersonalCustomerVerificationButton from "./PersonalCustomerVerificationButton"
import BusinessCustomerVerificationButton from "./BusinessCustomerVerificationButton"


const CustomerVerificationButton = ({
  customer,
  ...otherProps
}) => {
  const { name } = customer

  const isPersonalCustomer = name === CUSTOMER_NAME_PERSONAL

  if (isPersonalCustomer) {
    return <PersonalCustomerVerificationButton {...otherProps } customer={customer} />
  }

  return <BusinessCustomerVerificationButton {...otherProps } customer={customer} />
}

CustomerVerificationButton.propTypes = {
  customer: CustomerShape.isRequired,
}

export default CustomerVerificationButton
