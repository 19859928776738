import React, { useRef } from "react"
import { useOutletContext, useLocation } from "react-router-dom"

import Page, { PageMenu } from "@components/Page"

import useSettingsLinks from "./helpers/useSettingsLinks"
import BackstageSettingsLayoutOutlet from "./BackstageSettingsLayoutOutlet"

const LABEL_TITLE = "Settings"

const SEARCH_MODULES = [
  "members",
  "brokers",
  "sources",
  "templates",
  "custodians",
]


const BackstageSettingsLayout = () => {
  const table = useRef({})

  const outletContext = useOutletContext()
  const { pathname } = useLocation()

  const links = useSettingsLinks()

  const onSearch = value =>
    table.current.search(value)

  const title = LABEL_TITLE
  const style = { padding: 0 }

  const pageProps = {
    style,
    title
  }

  const isSearchEnabled = SEARCH_MODULES
    .filter(item => pathname.endsWith(item))
    .length > 0

  if (isSearchEnabled) {
    pageProps.onSearch = onSearch
  }

  const context = {
    table,
    ...outletContext,
  }

  return (
    <Page {...pageProps}>
      <PageMenu links={links} />

      <BackstageSettingsLayoutOutlet context={context} />
    </Page>
  )
}

export default BackstageSettingsLayout
