import React from "react"
import PropTypes from "prop-types"
import { Col, Row, Card, Typography } from "antd"

import { W2, isMobile } from "@components"
import { PROJECT_TYPES } from "@components/Domain"

import Investment from "./Investment"
import { ExtendedProjectShape } from "../helpers/useGroupedProjects"

const { Title } = Typography


const getPendingInvestments = project =>
  project.type !== PROJECT_TYPES.FUND
    ? project.investments
    : project.investments
        .filter(({ isFunded, isSigned }) => !isFunded || !isSigned)


const hasPendingInvestments = project =>
  getPendingInvestments(project).length > 0


const ActiveInvestments = ({
  title,
  projects
}) => {
  const hasProjects = projects.length > 0

  if (!hasProjects) {
    return null
  }

  const children =
    <Row gutter={isMobile ? 0 : W2}>
      {
        projects
          .filter(hasPendingInvestments)
          .map((project, key) => (
            <Col key={key} md={8}>
              <Investment
                id={project.id}
                name={project.name}
                investments={getPendingInvestments(project)}
                coverImageUrl={project.coverImageUrl}
              />
            </Col>
        ))
      }
    </Row>

  if (isMobile) {
    const cardStyle = {
      padding: 0,
      borderBottom: "1px solid #f0f0f0",
    }

    const bodyStyle = {
      padding: 0,
    }

    return (
      <Card
        type="inner"
        size={"small"}
        title={title}
        style={cardStyle}
        styles={{ body: bodyStyle }}
        bordered={false}
        className="card-mobile"
      >
        {children}
      </Card>
    )
  }

  return (
    <>
      <div style={{ padding: "0 8px" }}>
        <Title level={3}>{title}</Title>
      </div>

      {children}
    </>
  )
}

ActiveInvestments.propTypes = {
  title: PropTypes.string.isRequired,
  projects: PropTypes.arrayOf(ExtendedProjectShape).isRequired,
}

export default ActiveInvestments
