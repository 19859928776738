import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { useOutletContext, useParams } from "react-router-dom"
import { Form, Progress, Space, Button, Row, Col } from "antd"

import { W1 } from "@components"
import { Modal } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { InvestmentFilesCollection } from "@modules/backstage/documents"
import { createBatchInvestorFilesOperation as createOperation } from "@api/services/backstage"

import validateForm from "./helpers/validateForm"
import downloadReport from "./helpers/downloadReport"
import BatchCreateForm from "./BatchCreateForm"
import useBatchActions from "./helpers/useBatchActions"
import FileErrorsAlert from "./FileErrorsAlert"
import uploadInvestorFiles from "./helpers/uploadInvestorFiles"

const MODAL_WIDTH = 982

const LABEL_TITLE = "Upload Investor Files"
const LABEL_SUBMIT = "Upload Files"
const LABEL_MESSAGE_SUCCESS = "Investor files are uploaded"


const BatchCreateModal = ({ modal, onSuccess }) => {
  const collection = useRef({})

  const [ files, setFiles ] = useState([])
  const [ isOpen, setIsOpen ] = useState(false)
  const [ progress, setProgress ] = useState(0)
  const [ isEnabled, setIsEnabled ] = useState(false)
  const [ fileErrors, setFileErrors ] = useState([])
  const [ isSubmitting, setIsSubmitting ] = useState(false)

  const [ form ] = Form.useForm()
  const { id: projectId } = useParams()

  const { project, ensureBatchDocumentTypeOption } = useOutletContext()
  const {
    request,
    showErrorMessage,
    showSuccessMessage,
    getOrganizationName
  } = useAppContext()

  const organizationName = getOrganizationName()
  const { name: projectName } = project

  modal.current.open = () =>
    setIsOpen(true)

  const onClose = () => {
    setFiles([])
    setIsOpen(false)
    setProgress(0)
    setIsEnabled(false)
    setFileErrors([])
    setIsSubmitting(false)
  }

  const onFilesSelected = (newFiles, fileNames, newFileErrors) => {
    setFiles(newFiles)
    setFileErrors(newFileErrors)

    collection.setFileNames(fileNames)
  }

  const onConfirm = async () => {
    const items = collection.getItems()

    const {
      name,
      date,
      isSent,
      scheduledAt,
      documentType
    } = form.getFieldsValue([ 'name', 'date', 'isSent', 'scheduledAt', 'documentType' ])

    setIsSubmitting(true)

    await ensureBatchDocumentTypeOption(documentType)

    const investorFiles = await uploadInvestorFiles({
      files,
      items,
      request,
      setProgress,
      showErrorMessage
    })
    const mutation = {
      name,
      date,
      isSent,
      projectId,
      scheduledAt,
      documentType,
      investorFiles,
    }

    const { data: batch } = await request(createOperation, { mutation })

    const unmatchedFileNames = collection.getUnmatchedFileNames()

    downloadReport(
      organizationName,
      projectName,
      documentType,
      name,
      date,
      unmatchedFileNames
    )

    onClose()
    showSuccessMessage(LABEL_MESSAGE_SUCCESS)

    return onSuccess(batch)
  }

  const [ confirmUpload ] = useBatchActions(form, collection, onConfirm)

  const onClick = async () => {
    const isValid = await validateForm(collection, form)

    if (!isValid) {
      return
    }

    confirmUpload()
  }

  return (
    <Modal
      width={MODAL_WIDTH}
      title={LABEL_TITLE}
      isOpen={isOpen}
      onClose={onClose}
      destroyOnClose={true}
    >
      {
        isSubmitting && (
          <Progress percent={progress} />
        )
      }

      {
        !isSubmitting && (
          <Space
            size={W1}
            style={{ width: "100%" }}
            direction="vertical"
          >
            <BatchCreateForm
              form={form}
              onFilesSelected={onFilesSelected}
            />

            <InvestmentFilesCollection
              collection={collection}
              enableUpload={setIsEnabled}
            />

            <FileErrorsAlert
              errors={fileErrors}
              onClose={() => setFileErrors([])}
            />

            <Row align="middle">
              <Col>
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  onClick={onClick}
                  loading={isSubmitting}
                  disabled={!isEnabled}
                >
                  {LABEL_SUBMIT}
                </Button>
              </Col>
            </Row>
          </Space>
        )
      }
    </Modal>
  )
}

BatchCreateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default BatchCreateModal
