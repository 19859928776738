import AccountCard from "./AccountCard"
import useUpdateSchema from "./AccountCard/helpers/useUpdateSchema"
import InvestorAccountPage from "./InvestorAccountPage"

export {
  AccountCard,
  useUpdateSchema
}

export default InvestorAccountPage
