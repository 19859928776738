import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"

import { INVESTMENT_STATUS } from "@components/Domain"


const useInvestments = () => {
  const { investments = [] } = useOutletContext()

  const commitmentInvestments = useMemo(() =>
    investments
      .filter(({ status }) => status === INVESTMENT_STATUS.ACTIVE)
      .filter(({ isPartner }) => !isPartner)
  , [ investments ])

  const fundInvestments = useMemo(() =>
    investments
      .filter(({ status }) => status === INVESTMENT_STATUS.ACTIVE)
      .filter(({ isPartner }) => isPartner)
  , [ investments ])

  const backupInvestments = useMemo(() =>
    investments.filter(({ status }) => status === INVESTMENT_STATUS.BACKUP)
  , [ investments ])

  const dropoutInvestments = useMemo(() =>
    investments.filter(({ status }) => status === INVESTMENT_STATUS.REJECTED)
  , [ investments ])

  return [
    commitmentInvestments,
    fundInvestments,
    backupInvestments,
    dropoutInvestments
  ]
}

export default useInvestments
