
const LABEL_NO = "No"
const LABEL_YES = "Yes"

const renderBoolean = (value, trueValue = LABEL_YES, falseValue = LABEL_NO) => {
  return {
    true: trueValue,
    false: falseValue,
  }[value]
}

export default renderBoolean
