import React from "react"
import PropTypes from "prop-types"
import { InfoCircleOutlined } from "@ant-design/icons"

import { W0, W1, W2, isMobile } from "@components"


const InputExtra = ({ text }) => {
  const containerStyle = {
    fontSize: 12,
    marginTop: W0
  }

  const iconStyle = {
    float: "left",
    marginTop: 2
  }

  const textStyle = {
    marginLeft: isMobile
      ? (W2 + 1)
      : (W1 + 2)
  }

  return (
    <div style={containerStyle}>
      <InfoCircleOutlined style={iconStyle} />

      <div style={textStyle}>
        {text}
      </div>
    </div>
  )
}

InputExtra.propTypes = {
  text: PropTypes.string.isRequired,
}

export default InputExtra
