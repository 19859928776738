import { country } from "iso-3166-2"
import sortBy from "lodash.sortby"


const getRegionsByCountry = (countryCode) => {
  const { sub = {} } = country(countryCode)
  const hasRegions = Object.values(sub).length > 0

  if (!hasRegions) {
    return []
  }

  const regions = []
  for (const regionCode in sub) {
    if (sub[regionCode]) {
      const value = regionCode.replace(`${countryCode}-`, '')
      const label = sub[regionCode].name
      regions.push({ value, label })
    }
  }

  const sortedRegions = sortBy(regions, "label")

  return sortedRegions
}

export default getRegionsByCountry
