import React from "react"

import { RadioInput } from "../../Input"


const renderRadio = (itemProps, inputProps, options, form) => {
  return (
    <RadioInput
      form={form}
      options={options}
      itemProps={itemProps}
      inputProps={inputProps}
    />
  )
}

export default renderRadio
