import { TYPE_ARRAY, TYPE_NONE, TYPE_DOMAIN } from "@components/Form"

const LABEL_GROUPS = "Access Groups"
const PLACEHOLDER_EMAIL = "example@host.com"
const PLACEHOLDER_DOMAIN = "subdomain"
const PLACEHOLDER_ORGANIZATION_NAME = "Organization Name"

const getInputs = (userGroups) => {
  const userGroupsOptions =
    userGroups.map(({ id: value, name: label }) => ({ value, label }))

  return [
    {
      name: "name",
      placeholder: PLACEHOLDER_ORGANIZATION_NAME,
    },
    {
      name: "domain",
      type: TYPE_DOMAIN,
      inputProps: {
        style: { textAlign: "right" },
        placeholder: PLACEHOLDER_DOMAIN,
      }
    },

    {
      name: "email",
      required: true,
      placeholder: PLACEHOLDER_EMAIL,
    },
    {
      name: "userGroupIds",
      type: TYPE_ARRAY,
      label: LABEL_GROUPS,
      options: userGroupsOptions,
      required: false,
    },
    {
      name: "logoUrl",
      type: TYPE_NONE,
    }
  ]
}

export default getInputs
