import React, { useState, useEffect, useCallback } from "react"
import { Alert } from "antd"

import { Markdown } from "@components/Text"
import { isLocal } from "@components"
import { getConfig } from "@components/Config"

const LABEL_MESSAGE = `New application version [VERSION] released, refresh the
page in [SECONDS] seconds...`

const PLATFORM_VERSION = getConfig("PLATFORM_VERSION")

const PATH = '/static/release.json'
const UPDATE_DELAY = 15 * 1000
const REFRESH_STEP = 1 * 1000
const REFRESH_DELAY = 5


const ReleaseAlert = () => {
  const [ seconds, setSeconds ] = useState(REFRESH_DELAY)
  const [ version, setVersion ] = useState(PLATFORM_VERSION)

  const autoRefresh = useCallback(
    (currentVersion) => {
      let secondsLeft = seconds

      const countdown = setInterval(() => {
        secondsLeft -= 1

        const shouldRefresh = secondsLeft === 0

        if (shouldRefresh) {
          clearInterval(countdown)

          const [ url ] = window.location.href.split('?')
          window.location.href = `${url}?v=${currentVersion}`

          return
        }

        setSeconds(secondsLeft)
      }, REFRESH_STEP)
    }
  , [ seconds ])

  const updateVersion = useCallback(
    async () => {
      const timestamp = new Date().getTime()

      const url = `${PATH}?ts=${timestamp}`
      let result

      try {
        const response = await fetch(url)
        result = await response.json()

      } catch (error) {
        console.error(error)

        return
      }

      const { version: currentVersion } = result

      setVersion(currentVersion)
      const shouldRefresh = currentVersion !== PLATFORM_VERSION

      if (!shouldRefresh) {
        return
      }

      if (isLocal) {
        return
      }

      autoRefresh(currentVersion)
    }
  , [ autoRefresh ])

  useEffect(() => {
    if (!isLocal) {
      updateVersion()
    }

    const interval = setInterval(updateVersion, UPDATE_DELAY)

    return () => clearInterval(interval)
  }, [ updateVersion ])

  const isLatestVersion = version === PLATFORM_VERSION

  if (isLatestVersion) {
    return null
  }

  const text = LABEL_MESSAGE
    .replace("[VERSION]", version)
    .replace("[SECONDS]", seconds)

  return (
    <div className="header-alerts">
      <Alert
        banner
        message={
          <Markdown text={text} />
        }
      />
    </div>
  )
}

export default ReleaseAlert
