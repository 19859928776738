import React from "react"
import { useOutletContext } from "react-router-dom"
import { Space } from "antd"

import { Value } from "@components/Text"
import { hasAccess } from "@components/Authorization"
import { ago, getFormattedTimezone } from "@components/Date"
import Identity, { TwoFactorAuthenticationSwitch } from "@components/Identity"

import useInvestorAccreditation from "./useInvestorAccreditation"

const LABEL_NO = "No"
const LABEL_YES = "Yes"
const LABEL_NEVER = "Never"
const LABEL_CREATED = "Created"
const LABEL_TIMEZONE = "Time Zone"
const LABEL_REFERRED = "Referred From"
const LABEL_SUBSCRIBED = "Subscribed to Campaigns"
const LABEL_LAST_SIGNED_IN = "Last Signed In"
const LABEL_LAST_FUNDED_AT = "Last Invested Date"


const useItems = (account, onAfterUpdated) => {
  const {
    getSponsorName,
    hasMultipleSponsors,
  } = useOutletContext()

  const canUpdateAccount = hasAccess(['accounts-write'])

  const {
    id: accountId,
    investor,
    sponsorId,
    createdAt,
    createdBy,
    isDisabled,
    brokerUserId,
    lastFundedAt,
    disableReason,
    correlationId,
    referralSource,
    isSubscribedForCampaigns,
  } = account

  const sponsorName = getSponsorName(sponsorId)

  const {
    email,
    address,
    givenName,
    familyName,
    dateOfBirth,
    mobileNumber,
    timeZoneCode,
    additionalName,
    alternativeName,
    alternativeEmail,
    hasAccreditation,
    accreditationExpiresAt,
    campaignEmails = []
  } = investor

  const hasInvestorAccreditation = useInvestorAccreditation(hasAccreditation, accreditationExpiresAt)

  const shouldInclude2Fa =
    mobileNumber &&
    correlationId &&
    canUpdateAccount

  const legalFullName = [
    givenName,
    additionalName,
    familyName
  ].join(" ")

  const onSuccessSwitch2FA = is2FaEnabled =>
    onAfterUpdated({ ...account, is2FaEnabled })

  const liaisonItems = [
    {
      label: "Liaison",
      value: brokerUserId && <Identity id={brokerUserId} />
    },
  ]

  if (hasMultipleSponsors) {
    liaisonItems.push(
      {
        label: "Sponsor",
        value: sponsorName,
      }
    )
  }

  const items = [
    {
      label: "Legal Full Name",
      value: legalFullName,
    },
    {
      label: "Preferred Name",
      value: alternativeName,
    },
    {
      label: "Date of Birth",
      value: dateOfBirth && <Value value={dateOfBirth} />,
    },
    {
      label: "Email",
      value: email,
    },
    {
      label: "Alternative Email",
      value: alternativeEmail,
    },
    {
      label: "Campaign Emails",
      value: campaignEmails.join(", "),
    },
    {
      label: LABEL_SUBSCRIBED,
      value: isSubscribedForCampaigns ? LABEL_YES : LABEL_NO,
    },
    {
      label: "Phone Number",
      value: mobileNumber && <Value value={mobileNumber} />,
    },
  ]

  if (shouldInclude2Fa) {
    const { is2FaEnabled = false } = account

    items.push({
      label: "Two Factor Authentication",
      value: (
        <TwoFactorAuthenticationSwitch
          accountId={accountId}
          isEnabled={is2FaEnabled}
          onSuccess={onSuccessSwitch2FA}
        />
      )
    })
  }

  items.push(
    {
      label: "Mailing Address",
      value: address && <Value value={address} />,
    },
    {
      label: LABEL_TIMEZONE,
      value: getFormattedTimezone(timeZoneCode),
    },
    {
      label: "Accredited Investor",
      value: hasInvestorAccreditation,
    },
    ...liaisonItems,
    {
      label: LABEL_REFERRED,
      value: referralSource,
    },
    {
      label: LABEL_CREATED,
      value: (
        <Space>
          <Value value={createdAt} />
          { createdBy && <>by <Identity id={createdBy} /></> }
        </Space>
      ),
    },
    {
      label: LABEL_LAST_SIGNED_IN,
      value: ago(account.lastAuthenticatedAt) || LABEL_NEVER
    },
    {
      label: LABEL_LAST_FUNDED_AT,
      value: lastFundedAt
        ? <Value value={lastFundedAt} />
        : LABEL_NEVER
    },
  )

  const shouldShowDisableReason = isDisabled && disableReason

  if (shouldShowDisableReason) {
    items.push(
      {
        label: "Disable Reason",
        value: disableReason,
      }
    )
  }

  return items
}

export default useItems
