import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { AutoComplete, Form, Select, Space, Button } from "antd"
import isEqual from "lodash.isequal"
import { SaveOutlined } from "@ant-design/icons"

import FormItem from "@components/Form/FormItem"

import { EditableContext } from "./Collection"

const { Compact } = Space


const CollectionCell = ({
  input = undefined,
  record = undefined,
  isShow = () => true,
  onSave = () => {},
  editing = false,
  children,
  editable = false,
  indexRow = 0,
  dataIndex = 0,
  ...restProps
}) => {
  const form = useContext(EditableContext)

  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    if (!record) {
      return
    }

    form.setFieldValue([ indexRow, dataIndex ], record[dataIndex])
  }, [
    form,
    record,
    editing,
    indexRow,
    dataIndex,
  ])

  const save = async () => {
    const value = form.getFieldValue([ indexRow, dataIndex ])
    const formValue = form.getFieldValue(indexRow)

    const newValues = {
      ...record,
      ...formValue,
      [dataIndex]: value
    }

    const hasChanged = !isEqual(record, newValues)

    if (!hasChanged) { return }

    try {
      setLoading(true)
      await onSave(newValues)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const isDisabled = () => {
    if (loading) {
      return true
    }

    return !editing && editable
  }

  const itemProps = () => {
    const { hasAutoComplete, ...props } = input.itemProps || {}

    return {
      ...props,
      name: [ indexRow, dataIndex ],
      style: { margin: 0 }
    }
  }

  if (isShow && !isShow(record)) {
    return (
      <td {...restProps}/>
    )
  }

  const autoCompleteFilterOptions = (query, option) =>
      (option?.value ?? '').toLowerCase().includes(query.toLowerCase())

  const inputNode = () => {
    let hasAutoComplete = false

    if (input.itemProps) {
      hasAutoComplete = input.itemProps.hasAutoComplete || false
    }

    if (input.type === "select") {
      if (hasAutoComplete) {
        return (
          <Compact
            style={{ width: "100%", alignItems: "center" }}
          >
            <Form.Item
              {...itemProps()}
              validateTrigger="onClick"
              style={{width: "100%", marginBottom: 0}}
            >
              <AutoComplete
                style={{ margin: 0 }}
                options={input.options(record)}
                disabled={isDisabled()}
                showSearch={true}
                filterOption={autoCompleteFilterOptions}
              />
            </Form.Item>

            <Button
              type="link"
              icon={<SaveOutlined />}
              onClick={save}
              loading={loading}
              disabled={isDisabled()}
            />
          </Compact>
        )
      }

      return (
        <Form.Item
          {...itemProps()}
          validateTrigger="onClick"
        >
          <Select
            onChange={save}
            style={{width: "100%", margin: 0}}
            disabled={isDisabled()}
            options={input.options(record)}
            allowClear={input.allowClear}
          />
        </Form.Item>
      )
    }

    return (
      <FormItem
        {...input}
        form={form}
        path={[indexRow, dataIndex]}
        itemProps={itemProps()}
        inputProps={inputProps()}
      />
    )
  }

  const inputProps = () => {
    const { disabled: inputDisabled = false } = input.inputProps || {}

    const defaultParams = {
      disabled: inputDisabled ? inputDisabled(record) : isDisabled()
    }

    if (input.type === "select") {
      return {
        ...input.inputProps,
        ...defaultParams,
        onChange: save,
      }
    }

    return {
      ...input.inputProps,
      ...defaultParams,
      onBlur: save,
      onPressEnter: save
    }
  }

  if (record && record.editableRow !== undefined && !record.editableRow) {
    return (
      <td {...restProps}>
        {children}
      </td>
    )
  }

  return (
    <td {...restProps}>
      {editing && editable && (
        inputNode()
        )
      }

      {!editing && editable && (
        inputNode()
      )}

      {!editing && !editable && (
        children
      )}
    </td>
  )
}

CollectionCell.propTypes = {
  input: PropTypes.shape(),
  record: PropTypes.shape(),
  onSave: PropTypes.func,
  isShow: PropTypes.func,
  editing: PropTypes.bool,
  editable: PropTypes.bool,
  indexRow: PropTypes.number,
  dataIndex: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export default CollectionCell
