import React from "react"
import PropTypes from "prop-types"
import { Select } from "antd"
import dayjs from "dayjs"

import { getOffset } from "@components/Date"

export const DEFAULT_TIMEZONE = "America/New_York"
const FORMAT_ISO_DATE_TIME = "YYYY-MM-DDTHH:mm:ss"

const US_TIMEZONES = [
  {
    value: DEFAULT_TIMEZONE,
    label: "Eastern Time (EST)",
    offset: "-05:00"
  },
  {
    value: "America/Chicago",
    label: "Central Time (CST)",
    offset: "-06:00"
  },
  {
    value: "America/Phoenix",
    label: "Mountain Time (MST)",
    offset: "-07:00"
  },
  {
    value: "America/Los_Angeles",
    label: "Pacific Time (PST)",
    offset: "-08:00"
  }
]


export const getTimezone = isoDateTime => {
  const offset = getOffset(isoDateTime)
  const timezone = getTimezoneByOffset(offset)

  return timezone
}


export const getTimezoneByOffset = offset =>
  US_TIMEZONES.find(option => option.offset === offset)?.value || DEFAULT_TIMEZONE


const getOffsetByTimezone = offset =>
  US_TIMEZONES.find(option => option.value === offset)?.offset


export const getIsoDateTime = (value, timezone) => {
  const dateTimeString = dayjs(value).format(FORMAT_ISO_DATE_TIME)

  const timezoneOffset = getOffsetByTimezone(timezone)
  const isoDateTime = `${dateTimeString}${timezoneOffset}`

  return isoDateTime
}


const SelectTimezone = ({
  size = undefined,
  variant = undefined,
  disabled = false,
  value,
  onChange,
}) => {
  return (
    <Select
      size={size}
      value={value}
      variant={variant}
      options={US_TIMEZONES}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

SelectTimezone.propTypes = {
  size: PropTypes.string,
  value: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default SelectTimezone
