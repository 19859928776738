import React from "react"
import { Steps, theme } from "antd"

import { W1 } from "@components"
import { Disclaimer } from "@components/Text"
import { ReadMyOrganizationOutputShape } from "@api/services/organizations"

import getRecordItems from "./helpers/getRecordItems"
import VerifyCertificate from "./VerifyCertificate"

const LABEL_DOMAIN_TEXT = "DNS changes may take some time to take effect. Once the CNAME record is successfully created, please return to this page to ensure that the portal is successfully connected to your domain."
const LABEL_SUCCESS_TEXT = "Domain [domain] is connected."
const LABEL_DOMAIN_TITLE = "Connect Domain"
const LABEL_SUCCESS_TITLE = "Finished"
const LABEL_CERTIFICATE_TITLE = "Connect Certificate"
const LABEL_CERTIFICATION_TEXT = "DNS changes may take some time to take effect. Once the CNAME record is successfully created, please return to this page to complete the process of connecting your domain."


const OrganizationDnsRecord = ({ organization }) => {
  const { token } = theme.useToken()

  const {
    domain,
    domainStatus,
    domainRecordJson,
    certificateRecordJson
  } = organization

  const certificateRecord = getRecordItems(certificateRecordJson)
  const domainRecord = getRecordItems(domainRecordJson)

  const {
    name: certificateRecordName,
    value: certificateRecordValue
  } = certificateRecord

  const {
    name: domainRecordName,
    value: domainRecordValue
  } = domainRecord

  const currentStep = () => {
    const isPending = domainStatus === "DOMAIN_RECORD"

    const isVerification =
      domainStatus === "CERTIFICATE_PENDING" ||
      domainStatus === "CERTIFICATE_VERIFICATION" ||
      domainStatus === "CERTIFICATE_RECORD"

    if (isVerification) {
      return 0
    }

    if (isPending) {
      return 1
    }

    return 2
  }

  const steps = [
    {
      title: LABEL_CERTIFICATE_TITLE,
      content: <VerifyCertificate
        dnsName={certificateRecordName}
        dnsValue={certificateRecordValue}
      />,
      tooltipText: LABEL_CERTIFICATION_TEXT
    },
    {
      title: LABEL_DOMAIN_TITLE,
      content: <VerifyCertificate
        dnsName={domainRecordName}
        dnsValue={domainRecordValue}
      />,
      tooltipText: LABEL_DOMAIN_TEXT
    },
    {
      title: LABEL_SUCCESS_TITLE,
      content: LABEL_SUCCESS_TEXT.replace("[domain]", domain),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const contentStyle = {
    padding: W1,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: W1,
    marginBottom: W1,
    borderRadius: token.borderRadiusLG,
    backgroundColor: token.colorFillAlter,
  };

  const currentStepContent = steps[currentStep()].content
  const currentStepTooltip = steps[currentStep()].tooltipText

  return (
    <>
      <Steps current={currentStep()} items={items} />

      <div style={contentStyle}>
        {currentStepContent}
      </div>

      {
        currentStepTooltip && (
          <Disclaimer text={currentStepTooltip} />
        )
      }
    </>
  )
}

OrganizationDnsRecord.propTypes = {
  organization: ReadMyOrganizationOutputShape.isRequired,
}

export default OrganizationDnsRecord
