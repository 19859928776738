import React from "react"
import { Alert } from "antd"

import { Markdown } from "@components/Text"
import { useAppContext } from "@components/AppContext"

const LABEL_MESSAGE = `
  You are using an investor portal while impersonating **FULL_NAME**.
  Some functionality is restricted.
`


const ImpersonatedAccountAlert = () => {
  const { identity, isImpersonated } = useAppContext()

  if (!isImpersonated) {
    return null
  }

  const { familyName, givenName } = identity.investor
  const fullName = `${givenName} ${familyName}`

  const text = LABEL_MESSAGE
    .replace('FULL_NAME', fullName)

  return (
    <Alert
      banner
      message={
        <Markdown
          text={text}
        />
      }
    />
  )
}

export default ImpersonatedAccountAlert
