import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import cloneDeep from "lodash.clonedeep"

import Collection from "@components/Collection"

import getCollectionColumns from "./helpers/getCollectionColumns"
import getCollectionSummary from "./helpers/getCollectionSummary"


const DistributionsCollection = ({ collection, onUpdateCollection }) => {
  const collectionRef = useRef({})

  const [ transactions, setTransactions ] = useState([])

  collection.current.setNewTransactions = (targetTransactions = []) => {
    const hasItems = targetTransactions.length > 0

    if (!hasItems) {
      return
    }

    targetTransactions.forEach((transaction, index) => {
      transaction.rowKey = `${index + 1}-${transaction.id}`
    })

    setTransactions(targetTransactions)
    onUpdateCollection(targetTransactions)
  }

  collection.current.hasFormError = () => {
    return collectionRef.current.hasFormError()
  }

  collection.current.getTransactions = () => transactions

  const updateAsync = async record => {
    const transactionsList = cloneDeep(transactions)

    const index = transactionsList.findIndex(item => item.rowKey === record.rowKey)
    transactionsList[index] = record

    setTransactions(transactionsList)
    onUpdateCollection(transactionsList)
  }

  const handleDelete = (record, index) => {
    setTransactions(prevTransactions => {
      const newTransactions = [ ...prevTransactions.filter((_, i) => i !== index) ]
      onUpdateCollection(newTransactions)
      return newTransactions
    })
  }

  const columns = getCollectionColumns(handleDelete)
  const tableProps = { bordered: false, rowKey: "rowKey" }
  const enableSelectItem = false
  const selectedRowItems = transactions.map(({ id }) => id)

  const summary = items => getCollectionSummary(items)

  return (
    <Collection
      items={transactions}
      onSave={updateAsync}
      summary={summary}
      tableProps={tableProps}
      collection={collectionRef}
      columnsConfig={columns}
      enableSelectItem={enableSelectItem}
      selectedRowItems={selectedRowItems}
    />
  )
}

DistributionsCollection.propTypes = {
  collection: PropTypes.shape().isRequired,
  onUpdateCollection: PropTypes.func.isRequired,
}

export default DistributionsCollection
