import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import last from "lodash.last"
import dayjs from "dayjs"
import { Space, DatePicker, Typography } from "antd"

import { IndexActivitiesOutputShape } from "@api/services/activities"
import { getUsDateFromIsoDate, US_DATE_FORMAT } from "@components/Date"

const { Text } = Typography
const { RangePicker } = DatePicker


const getDateRange = (startDate, endDate) => {
  const start = dayjs(getUsDateFromIsoDate(startDate), US_DATE_FORMAT).subtract(1, 'day')
  const end = dayjs(getUsDateFromIsoDate(endDate), US_DATE_FORMAT).add(1, 'day')

  return [ start, end ]
}

const ActivitiesTableHeader = ({
  title,
  items,
  updateOperationParameters
}) => {
  const [ value, setValue ] = useState()

  useEffect(() => {
    const hasItems = items.length > 0

    if (!hasItems) {
      return
    }

    const hasDateRange = value !== undefined

    if (hasDateRange) {
      return
    }

    const [ firstItem ] = items
    const lastItem = last(items)

    const { createdAt: startDate } = lastItem
    const { createdAt: endDate } = firstItem

    const newDateRange = getDateRange(startDate, endDate)
    setValue(newDateRange)
  }, [ value, items ])

  const onChange = dateRange => {
    setValue(dateRange)
    updateOperationParameters(dateRange)
  }

  const style = {
    width: 230
  }

  return (
    <Space size={0}>
      <Text>{title} for</Text>

      <RangePicker
        size="small"
        style={style}
        value={value}
        format={US_DATE_FORMAT}
        variant="borderless"
        onChange={onChange}
        className={"day-picker"}
        allowClear={false}
        placeholder=""
      />
    </Space>
  )
}

ActivitiesTableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(IndexActivitiesOutputShape).isRequired,
  updateOperationParameters: PropTypes.func.isRequired,
}

export default ActivitiesTableHeader
