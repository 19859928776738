import React from "react"

import { validateDob } from "@components/Date"
import { COUNTRY_US, getAddressSchema, getDwollaAddressSchema } from "@components/Address"
import { isBackstage } from "@components"
import {
  Header,
  TYPE_SSN,
  TYPE_DATE,
  TYPE_NONE,
  TYPE_OBJECT,
  TYPE_STRING,
} from "@components/Form"

const LABEL_DOB = "Date of Birth"
const LABEL_SSN = "Social Security Number"
const LABEL_TITLE = "Job Title"
const LABEL_ADDRESS = "Controller's Physical Address"
const LABEL_LAST_NAME = "Last Name"
const LABEL_FIRST_NAME = "First Name"
const LABEL_CONTROLLER = "Controller"
const LABEL_PASSPORT = "Passport Number"
const LABEL_CONTROLLER_INFO = `Please enter entity controller's name and address
exactly as it appears on their personal identification document (e.g., drivers
license, state-issued personal ID, passport, etc.).`
const LABEL_BACKSTAGE_CONTROLLER_INFO = `Please enter entity controller's name and address
exactly as it appears on their personal identification document (e.g., drivers
license, state-issued personal ID, passport, etc.).

PO boxes, registered agent addresses, shipping offices, and virtual 
offices are not accepted.`


const getControllerInputs = (updateSchema, form) => {
  const address = form.getFieldValue(["businessCustomer", "controller", "address"])
  const { addressCountry = COUNTRY_US } = address || {}
  const isUs = addressCountry === COUNTRY_US

  const addressSchema = getAddressSchema(updateSchema, ["businessCustomer", "controller", "address"], address)

  const controllerInfo = isBackstage
    ? LABEL_BACKSTAGE_CONTROLLER_INFO
    : LABEL_CONTROLLER_INFO

  const inputs = [
    {
      name: "_controller",
      component: ({ style }) =>
        <div style={style}>
          <Header info={controllerInfo}>
            {LABEL_CONTROLLER}
          </Header>
        </div>
    },
    {
      name: "controller",
      type: TYPE_OBJECT,
      label: "",
      schema: [
        {
          name: "title",
          type: TYPE_STRING,
          label: LABEL_TITLE,
          required: true,
          maxLength: 50,
          placeholder: "",
          inputProps: { style: { width: "75%" } },
        },
        {
          name: "firstName",
          type: TYPE_STRING,
          label: LABEL_FIRST_NAME,
          required: true,
          maxLength: 50,
          inputProps: { style: { width: "75%" } },
        },
        {
          name: "lastName",
          type: TYPE_STRING,
          label: LABEL_LAST_NAME,
          required: true,
          maxLength: 50,
          inputProps: { style: { width: "75%" } },
        },
        {
          name: "dateOfBirth",
          type: TYPE_DATE,
          label: LABEL_DOB,
          required: true,
          itemProps: {
            rules: [validateDob(LABEL_CONTROLLER)]
          }
        },
        {
          name: "address",
          type: TYPE_OBJECT,
          label: LABEL_ADDRESS,
          required: true,
          schema: getDwollaAddressSchema(addressSchema),
        },
        {
          name: "ssn",
          label: LABEL_SSN,
          type: isUs ? TYPE_SSN : TYPE_NONE,
          required: isUs,
        },
        {
          name: "passport",
          label: LABEL_PASSPORT,
          type: isUs ? TYPE_NONE : TYPE_STRING,
          required: !isUs,
          inputProps: { style: { width: 140 } },
        },
      ]
    }
  ]

  return inputs
}

export default getControllerInputs
