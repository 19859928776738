import { TYPE_DATE } from "@components/Form"
import { getIsoDateFromStringDate } from "@components/Date"

const getImportInputs = () => {
  return [
    {
      name: "date",
      type: TYPE_DATE,
      label: "Date",
      required: true,
      initialValue: getIsoDateFromStringDate(new Date())
    },
  ]
}

export default getImportInputs
