import React from "react"
import { Form, Input } from "antd"

const { Item } = Form
const { Password } = Input


const renderPassword = (itemProps, inputProps) => {
  return (
    <Item {...itemProps}>
      <Password {...inputProps} />
    </Item>
  )
}

export default renderPassword
