import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { Descriptions } from "antd"

import { ReadTransactionOutputShape } from "@api/services/transactions"

import useItems from "./helpers/useItems"

const { Item } = Descriptions


const Transaction = ({
  onEdit = undefined,
  transaction,
}) => {
  const { organization } = useOutletContext()

  const { name: organizationName } = organization

  const items = useItems(organizationName, transaction, onEdit)

  return (
    <Descriptions
      size="small"
      column={1}
      layout="vertical"
    >
      {
        items.map(({ label, value }, key) =>
          <Item
            key={key}
            label={label}
          >
            {value}
          </Item>
        )
      }
    </Descriptions>
  )
}

Transaction.propTypes = {
  onEdit: PropTypes.func,
  transaction: ReadTransactionOutputShape.isRequired,
}

export default Transaction
