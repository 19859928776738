import capitalize from "lodash.capitalize"

export const ENTITY = "ENTITY"
export const INDIVIDUAL = "INDIVIDUAL"
export const JOINT = "JOINT"
export const SDIRA = "SDIRA"

const ProfileTypeEnum = {
  [ENTITY]: ENTITY,
  [INDIVIDUAL]: INDIVIDUAL,
  [JOINT]: JOINT,
  [SDIRA]: SDIRA
}

const ProfileTypeEnumKeys = Object.keys(ProfileTypeEnum)

const ProfileTypeEnumOptions = ProfileTypeEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  ProfileTypeEnumKeys,
  ProfileTypeEnumOptions
}

export default ProfileTypeEnum
