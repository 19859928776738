import { useMemo } from "react"
import { App } from "antd"

import { confirmAction } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { INVESTMENT_STATUS_ACTIVE } from "@components/Domain"
import {
  closeProjectOperation,
  deleteProjectOperation,
  archiveProjectOperation,
  publishProjectOperation,
  unpublishProjectOperation,
} from "@api/services/backstage"

const CLOSE = "CLOSE"
const DELETE = "DELETE"
const ARCHIVE = "ARCHIVE"
const PUBLISH = "PUBLISH"
const UNPUBLISH = "UNPUBLISH"
const UNARCHIVE = "UNARCHIVE"
const PUBLISH_WITH_WARNINGS = "PUBLISH_ANYWAY"
const PUBLISH_WITH_NO_PPM = "PUBLISH_WITH_NO_PPM"
const PUBLISH_WITH_NO_BANK_ACCOUNT = "PUBLISH_WITH_NO_BANK_ACCOUNT"

const MESSAGE_FORBIDDEN_UNPUBLISH = "The project cannot be unpublished because it contains investments. To unpublish the project, its investments must be dropped out."
const TITLE_FORBIDDEN_UNPUBLISH = "Unpublish Project"

const ACTIONS_MAP = {
  [CLOSE]: {
    operation: closeProjectOperation,
    success: 'Project "$" is active and closed for investments',
    action: "Confirm",
    title: "Close Project",
    content: `By clicking "Confirm" below, an email notification will be sent
to all investors who have completed their investment (i.e., signed the
subscription docs, funded the investment, and linked their distribution
account), informing them that the project is now Active.`,
    templateDisabledContent: `By clicking "Confirm" below, the project will be
marked as Active. Email notifications will not be sent to investors, as email
notifications for closing the deal are currently turned off.`
  },
  [PUBLISH]: {
    operation: publishProjectOperation,
    success: 'Project "$" is open for investments',
    action: "Publish",
    title: "Publish Project",
    content: `By clicking "Publish" below, project "$" will become
available for investors and open for investments.`,
  },
  [ARCHIVE]: {
    operation: archiveProjectOperation,
    success: 'Project "$" is sold and archived',
    action: "Archive - Full Cycle",
    title: "Archive Project",
    content: `By clicking "Archive - Full Cycle" below, project "$" will be
archived. The project will be considered to have gone full cycle.`,
    type: "danger",
  },
  [UNPUBLISH]: {
    operation: unpublishProjectOperation,
    success: 'Project "$" is on hold',
    action: "Unpublish",
    title: "Unpublish Project",
    content: `By clicking "Unpublish" below, project "$" will be hidden for
investors and will not be able to accept new investments.`,
  },
  [DELETE]: {
    operation: deleteProjectOperation,
    success: 'Project "$" is deleted',
    action: "Delete",
    title: "Delete Project",
    content: 'Project "$" is deleted with all related information, the operation is not recoverable.',
    type: "danger",
  },
  [UNARCHIVE]: {
    operation: closeProjectOperation,
    success: 'Project "$" is active and closed for investments',
    action: "Unarchive",
    title: "Unarchive Project",
    content: 'Project "$" becomes active and closed for new investments.',
  },
  [PUBLISH_WITH_WARNINGS]: {
    operation: publishProjectOperation,
    success: 'Project "$" is open for investments',
    action: "Publish Anyway",
    title: "Publish Project",
    content: "PPM template and bank account are not set. Investors won't be able to sign PPM documents and fund investments.",
    type: "danger",
  },
  [PUBLISH_WITH_NO_PPM]: {
    operation: publishProjectOperation,
    success: 'Project "$" is open for investments',
    action: "Publish Anyway",
    title: "Publish Project",
    content: "PPM template is not set. Investors won't be able to sign PPM documents.",
    type: "danger",
  },
  [PUBLISH_WITH_NO_BANK_ACCOUNT]: {
    operation: publishProjectOperation,
    success: 'Project "$" is open for investments',
    action: "Publish Anyway",
    title: "Publish Project",
    content: "Bank account is not set. Investors won't be able to fund investments.",
    type: "danger",
  }
}


const useProjectActions = ({
  project,
  investments,
  afterAction,
  isProjectClosedNonFundTemplateEnabled,
}) => {
  const { modal } = App.useApp()
  const { request, showSuccessMessage } = useAppContext()

  const { id, name } = project

  const hasActiveInvestments = useMemo(() =>
    investments
      .filter(({ status }) => status === INVESTMENT_STATUS_ACTIVE)
      .length > 0
  , [ investments ])

  const action = actionName => {
    const {
      templateDisabledContent,
      content: templateEnabledContent
    } = ACTIONS_MAP[actionName]

    let content = templateEnabledContent
      .replace("$", name)

    const isCloseAction = actionName === CLOSE

    if (isCloseAction) {
      content = isProjectClosedNonFundTemplateEnabled()
        ? content
        : templateDisabledContent
    }

    return confirmAction(
      modal,
      ACTIONS_MAP[actionName].action,
      ACTIONS_MAP[actionName].title,
      content,
      () => {
        return request(ACTIONS_MAP[actionName].operation, { id })
          .then(async ({ data }) => {
            const message = ACTIONS_MAP[actionName]
              .success
              .replace("$", name)

            showSuccessMessage(message)
            await afterAction(id, data)
          })
      },
      ACTIONS_MAP[actionName].type,
    )
  }

  const unpublishProject = () => {
    if (hasActiveInvestments) {
      return modal.error({
        title: TITLE_FORBIDDEN_UNPUBLISH,
        content: MESSAGE_FORBIDDEN_UNPUBLISH,
      })
    }

    return action(UNPUBLISH)
  }

  const publishProject = () => action(PUBLISH)

  const closeProject = () => action(CLOSE)

  const archiveProject = () => action(ARCHIVE)

  const deleteProject = () => action(DELETE)

  const unarchiveProject = () => action(UNARCHIVE)

  const publishProjectWithWarnings = () => action(PUBLISH_WITH_WARNINGS)

  const publishProjectWithoutPpm = () => action(PUBLISH_WITH_NO_PPM)

  const publishProjectWithoutBankAccount = () => action(PUBLISH_WITH_NO_BANK_ACCOUNT)

  return {
    closeProject,
    deleteProject,
    publishProject,
    archiveProject,
    unpublishProject,
    unarchiveProject,
    publishProjectWithWarnings,
    publishProjectWithoutPpm,
    publishProjectWithoutBankAccount
  }
}

export default useProjectActions
