import capitalize from "lodash.capitalize"

export const ARCHIVE = "ARCHIVE"
export const DOCUMENT = "DOCUMENT"
export const EMBEDED_VIDEO = "EMBEDED_VIDEO"
export const IMAGE = "IMAGE"

const AssetTypeEnum = {
  [ARCHIVE]: ARCHIVE,
  [DOCUMENT]: DOCUMENT,
  [EMBEDED_VIDEO]: EMBEDED_VIDEO,
  [IMAGE]: IMAGE
}

const AssetTypeEnumKeys = Object.keys(AssetTypeEnum)

const AssetTypeEnumOptions = AssetTypeEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  AssetTypeEnumKeys,
  AssetTypeEnumOptions
}

export default AssetTypeEnum
