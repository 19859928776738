import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { Space, Tooltip } from "antd"
import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons"

import { getConfig } from "@components/Config"
import { useAppContext } from "@components/AppContext"
import { ConnectSourceButton } from "@components/Dwolla"
import { updateInvestmentSourcesOperation } from "@api/services/investments"

import { InvestmentShape } from "../../../../shapes"
import getConnectSourceProps from "./helpers/getConnectSourceProps"

const LABEL_INFO = "Contact [NAME] to link the distributions bank account."
const LABEL_NO_CONTROLLER = "The organization controller needs to be configured in the settings."
const LABEL_NOT_AVAILABLE = "Not Available"

const IMPERSONATION_ENABLED_ORG_IDS = getConfig("IMPERSONATION_ENABLED_ORG_IDS")


const ImpersonatedSourceConnect = ({
  investment,
  updateInvestment
}) => {
  const { request } = useAppContext()

  const {
    getAccount,
    organization,
    computeUserFullname,
    isOrganizationController,
    getOrganizationControllerId,
  } = useOutletContext()

  const { id, investorAccountId } = investment

  const {
    receiverSourceId: sourceId,
    receiverSourceName: sourceName
  } = investment

  const account = getAccount(investorAccountId)
  const isController = isOrganizationController()
  const hasController = !!getOrganizationControllerId()

  if (!account) {
    return null
  }

  const { id: organizationId } = organization
  const isShadowInvestor = !account.correlationId
  const isImpersonationEnabled = IMPERSONATION_ENABLED_ORG_IDS.includes(organizationId)

  const hasSource = !!sourceId

  const shouldRenderConnect =
    isShadowInvestor &&
    isController &&
    isImpersonationEnabled

  if (shouldRenderConnect) {
    const onSelect = (receiverSourceId) =>
      request(updateInvestmentSourcesOperation, { id, mutation: { receiverSourceId } })
        .then(({ data }) => updateInvestment(data))

    const connectSourceProps = getConnectSourceProps(investment, onSelect)

    return (
      <ConnectSourceButton {...connectSourceProps} />
    )
  }

  if (!hasSource) {
    const shouldShowTooltip = isShadowInvestor && !isController

    const getTitle = () => {
      if (!hasController) {
        return LABEL_NO_CONTROLLER
      }

      const controllerId = getOrganizationControllerId()
      const controllerName = computeUserFullname(controllerId)

      return LABEL_INFO.replace("[NAME]", controllerName)
    }


    return (
      <Space>
        {LABEL_NOT_AVAILABLE}
        {
          shouldShowTooltip && (
            <Tooltip
              placement="top"
              title={getTitle()}
            >
              {
                hasController ? <InfoCircleOutlined /> : <WarningOutlined />
              }
            </Tooltip>
          )
        }
      </Space>
    )
  }

  return (
    <>{sourceName}</>
  )
}

ImpersonatedSourceConnect.propTypes = {
  investment: InvestmentShape.isRequired,
  updateInvestment: PropTypes.func.isRequired,
}

export default ImpersonatedSourceConnect
