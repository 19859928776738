import {
  INVESTMENT_PROFILE_FIELD_MAP,
  INVESTMENT_PROFILE_TYPE_INDIVIDUAL
} from "@components/Domain"

const SUPPORTED_PROFILE_TYPES = [
  INVESTMENT_PROFILE_TYPE_INDIVIDUAL
]


const setDefaultProfile = (form, profileType, profilesMap) => {
  const isSupported = SUPPORTED_PROFILE_TYPES.includes(profileType)

  if (!isSupported) {
    return
  }

  const profileField = INVESTMENT_PROFILE_FIELD_MAP[profileType]

  const existingProfiles = profilesMap[profileType]
  const hasProfiles = existingProfiles.length > 0

  if (!hasProfiles) {
    return
  }

  const [ recentProfile ] = existingProfiles

  const profile = {
    ...recentProfile[profileField]
  }

  form.setFieldsValue({
    [profileField]: profile
  })
}

export default setDefaultProfile
