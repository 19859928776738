import PropTypes from "prop-types"

import { AssetTypeEnumKeys } from "./AssetTypeEnum"

const properties = {
  createdAt: PropTypes.string,
  description: PropTypes.string,
  isPublic: PropTypes.bool.isRequired,
  name: PropTypes.string,
  path: PropTypes.string.isRequired,
  type: PropTypes.oneOf(AssetTypeEnumKeys).isRequired,
  url: PropTypes.string
}

const AssetShape = PropTypes.exact(properties)

export default AssetShape
export { properties }
