import PropTypes from "prop-types"

import AddressShape from "./shapes/AddressShape"

const properties = {
  address: AddressShape.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  einNumber: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  receiverSourceId: PropTypes.string,
  receiverSourceName: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const ListCustodiansOutputShapeShape = PropTypes.exact(properties)

export default ListCustodiansOutputShapeShape
export { properties }
