import React from "react"
import PropTypes from "prop-types"

import { TableRowActions } from "@components/Table"
import { ReadUserGroupOutputShape } from "@api/services/backstage"

import useRowMenu from "./helpers/useRowMenu"
import useAction from "./helpers/useAction"

const RowActions = ({
  userGroup,
  openUpdateModal,
  removeUserGroup
}) => {
  const openDeleteConfirm = useAction(userGroup, removeUserGroup)
  const items = useRowMenu(userGroup, { openDeleteConfirm, openUpdateModal })

  return (
    <TableRowActions items={items} />
  )
}

RowActions.propTypes = {
  userGroup: ReadUserGroupOutputShape.isRequired,
  openUpdateModal: PropTypes.func.isRequired,
  removeUserGroup: PropTypes.func.isRequired,
}

export default RowActions
