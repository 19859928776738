import { useState, useEffect, useCallback } from "react"
import { useOutletContext } from "react-router-dom"

import { runRequest, useAppContext } from "@components/AppContext"
import { readAccountOperation as readOperation } from "@api/services/investments"
import { DOCUMENT_NOT_FOUND_ERROR } from "@api"

import useInvestmentsStore from "./useInvestmentsStore"
import useTransactionsStore from "./useTransactionsStore"

const useAccountStore = (accountId, onAccountNotFound) => {
  const { request } = useAppContext()
  const { setAccountTags: setAccountTagsCustom } = useOutletContext()

  const [ account, setAccount ] = useState()
  const [ isLoading, setIsLoading ] = useState()

  const {
    transactions,
    indexTransactions
  } = useTransactionsStore(accountId)

  const {
    investments,
    indexInvestments,
  } = useInvestmentsStore(accountId)

  const readAccount = useCallback(
    id =>
      request(readOperation, { id }, [ DOCUMENT_NOT_FOUND_ERROR ])
        .then(({ data }) => setAccount(data))
        .catch(() => onAccountNotFound(id))
  , [ request, onAccountNotFound ])

  useEffect(() => {
    if (!accountId) {
      setAccount()
      return
    }

    return runRequest(() => {
      setIsLoading(true)
      Promise.all([
        readAccount(accountId),
        indexInvestments(),
        indexTransactions(),
      ]).then(() => setIsLoading(false))
    })
  }, [ accountId, readAccount, indexInvestments, indexTransactions ])

  const updateAccount = updatedAccount =>
    setAccount(prevAccount => ({ ...prevAccount, ...updatedAccount }))

  const setAccountTags = async (tagIds) => {
    const updatedAccount = await setAccountTagsCustom(accountId, tagIds)
    updateAccount(updatedAccount)
  }

  return {
    account,
    isLoading,
    investments,
    readAccount,
    transactions,
    updateAccount,
    setAccountTags,
    indexInvestments,
    indexTransactions,
  }
}

export default useAccountStore
