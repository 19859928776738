import { useOutletContext } from "react-router-dom"

import { useAppContext } from "@components/AppContext"
import {
  sendDistributionPendingApprovalNotificationOperation as createNotificationOperation
} from "@api/services/notifications"


const useSendNotifications = () => {
  const { request } = useAppContext()
  const { project, activeApprovers } = useOutletContext()

  const { id: projectId } = project

  return async () => {
    for (const user of activeApprovers) {
      const { email: toAddress } = user

      const mutation = { toAddress, projectId }

      await request(createNotificationOperation, { mutation })
    }
  }
}

export default useSendNotifications
