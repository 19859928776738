import countriesPhoneList from "countries-phone-masks"
import { parsePhoneNumber } from "awesome-phonenumber"

const ERROR_INVALID_NUMBER = "Number is invalid"


const getValidationRules = ({
  mask,
  form,
  codePath,
  isRequired,
}) => {
  return [
    {
      message: ERROR_INVALID_NUMBER,
      validator: (_, value) => {
        const formattedMask = mask.replace(/#/g, '_')

        const isEmpty = !value || value.length === 0 || formattedMask === value

        if (!isRequired && isEmpty) {
          return Promise.resolve()
        }

        const regionCode = form.getFieldValue(codePath)
        const { code } = countriesPhoneList.find(({ iso }) => regionCode === iso)

        const test = parsePhoneNumber(`${code}${value}`)
        const { valid } = test

        if (!valid) {
          throw new Error(ERROR_INVALID_NUMBER)
        }

        return Promise.resolve()
      }
    },
  ]
}

export default getValidationRules
