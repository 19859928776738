import React from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"

import { CreateModal } from "@components/Page"
import { IndexAccountInvestmentsOutputShape } from "@api/services/investments"

import useSchema from "./helpers/useSchema"
import useValidateTransaction from "./helpers/useValidateTransaction"

const LABEL_ENTITY = "Transaction"
const LABEL_CREATE = `Create ${LABEL_ENTITY}`


const TransactionCreateModal = ({
  modal,
  onSuccess,
  investments,
}) => {
  const { id: projectId } = useParams()

  const [ form, schema, operation, prepareMutation ] = useSchema(investments)
  const validateTransaction = useValidateTransaction(projectId, investments)

  const onSubmit = async parameters => {
    const newParameters = prepareMutation(parameters)

    return validateTransaction(newParameters)
  }

  return (
    <CreateModal
      modal={modal}
      form={form}
      title={LABEL_CREATE}
      entity={LABEL_ENTITY}
      schema={schema}
      onSubmit={onSubmit}
      operation={operation}
      onSuccess={onSuccess}
    />
  )
}

TransactionCreateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
  investments: PropTypes.arrayOf(IndexAccountInvestmentsOutputShape).isRequired,
}

export default TransactionCreateModal
