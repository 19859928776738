import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import { StopOutlined } from "@ant-design/icons"

import { W1 } from "@components"
import { EntityForm } from "@components/Form"
import { useAppContext } from "@components/AppContext"
import { wait, OperationShape } from "@api"

import Modal from "./Modal"


const UpdateModal = ({
  onSuccess: onSuccessCustom = undefined,
  table = undefined,
  width = undefined,
  footer = undefined,
  onOpen = undefined,
  onItemRead = undefined,
  deleteTitle = undefined,
  deleteAction = undefined,
  readOperation = undefined,
  modal,
  title,
  ...otherEntityFormProps
}) => {
  const { request } = useAppContext()

  const [ item, setItem ] = useState()
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isRemoving, setIsRemoving ] = useState(false)

  const onClose = async () => {
    setIsOpen(false)

    await wait()
    setItem()
  }

  const onSuccess = async (updatedItem) => {
    if (onSuccessCustom) {
      await onSuccessCustom(updatedItem)
    }

    if (table) {
      table.current.updateItem(item.id, updatedItem)
    }

    return onClose()
  }

  modal.current.open = id => {
    setIsOpen(true)

    if (!readOperation) {
      const message = 'Prop "readOperation" is not defined, use .openItem(item) operation instead.'
      throw new Error(message)
    }

    return request(readOperation, { id })
      .then(({ data }) => {
        setItem(data)

        if (onItemRead) {
          onItemRead(data)
        }
      })
  }

  modal.current.close = () =>
    setIsOpen(false)

  modal.current.openItem = async (customItem) => {
    setItem({ ...customItem })

    if (onOpen) {
      onOpen({ ...customItem })
    }

    setIsOpen(true)
  }

  let deleteFooter

  if (deleteAction) {
    const onDelete = async () => {
      setIsRemoving(true)
      await deleteAction(item)
      setIsRemoving(false)
    }

    deleteFooter = (
      <div style={{ textAlign: "center", marginTop: W1 - 6 }}>
        <Button
          danger
          type="text"
          icon={<StopOutlined />}
          onClick={onDelete}
          loading={isRemoving}
        >
          {deleteTitle}
        </Button>
      </div>
    )
  }

  const modalProps = {
    forceRender: true,
    destroyOnClose: true,
    width,
    title,
    isOpen,
    onClose,
  }

  return (
    <Modal {...modalProps}>
      <EntityForm
        item={item}
        setItem={setItem}
        onSuccess={onSuccess}
        {...otherEntityFormProps}
      />

      {footer && footer(item)}

      {deleteAction && deleteFooter}
    </Modal>
  )
}

UpdateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  table: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
  footer: PropTypes.func,
  onOpen: PropTypes.func,
  onSuccess: PropTypes.func,
  onItemRead: PropTypes.func,
  deleteTitle: PropTypes.string,
  deleteAction: PropTypes.func,
  readOperation: OperationShape,
}

export default UpdateModal
