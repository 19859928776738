import PropTypes from "prop-types"

import { useAppContext } from "@components/AppContext"
import {
  indexMyFilesOperation as indexOperation,
  IndexMyFilesOutputShape
} from "@api/services/backstage"

import useStore from "../helpers/useStore"


const useDocumentsStore = () => {
  const { request } = useAppContext()

  const shouldAutoLoad = false

  const {
    items: documents,
    indexItems: indexDocuments
  } = useStore(indexOperation, request, shouldAutoLoad)

  return {
    documents,
    indexDocuments,
  }
}

const documentsStoreProperties = {
  documents: PropTypes.arrayOf(IndexMyFilesOutputShape),
  indexDocuments: PropTypes.func.isRequired,
}

export default useDocumentsStore

export {
  indexOperation,
  documentsStoreProperties
}
