import keyBy from "lodash.keyby"

import { DOMAIN } from "@components"

import { Storage } from "../authenticate"


const getOrganization = organizations => {
  const mapById = keyBy(organizations, "id")
  const mapByDomain = keyBy(organizations, "domain")

  const organizationId = Storage.customOrganizationId

  let organization

  organization = mapById[organizationId]

  if (organization) {
    return organization
  }

  organization = mapByDomain[DOMAIN]

  return organization
}

export default getOrganization
