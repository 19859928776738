import React from "react"
import { Space, Typography } from "antd"

import renderBoolean from "./renderBoolean"

const { Text } = Typography

const LABEL_TITLE = "Additional Details"
const LABEL_HAS_PURCHASED = "I previously purchased securities exempt from federal and state registration."
const LABEL_OWN_DECISIONS = `I make my own investment decisions with respect to the investments in
 marketable securities, limited partnerships, limited liability companies
 or other private investments.
`
const LABEL_HAS_NOT_PURCHASED = "I didn't purchase securities exempt from federal and state registration."
const LABEL_NOT_OWN_DECISIONS = "I do not make my own investment decisions."


const renderAdditional = ({ isLiabilityAccepted, hasPurchasedExemptSecurities }, extraItems) => {
  const renderText = value =>
    <Text type="secondary" style={{ fontSize: 12 }}>
      {value}
    </Text>

  const style = {
    lineHeight: 1.3
  }

  const additional =
    <Space
      size={0}
      style={style}
      direction="vertical"
    >
      {
        renderBoolean(hasPurchasedExemptSecurities,
          renderText(LABEL_HAS_PURCHASED),
          renderText(LABEL_HAS_NOT_PURCHASED)
        )
      }

      {
        renderBoolean(isLiabilityAccepted,
          renderText(LABEL_OWN_DECISIONS),
          renderText(LABEL_NOT_OWN_DECISIONS)
        )
      }

      {
        extraItems && (
          <>{extraItems}</>
        )
      }
    </Space>

  return {
    span: 2,
    label: LABEL_TITLE,
    value: additional,
  }
}

export default renderAdditional
