import React from "react"
import PropTypes from "prop-types"
import { Space } from "antd"
import Vimeo from "@u-wave/react-vimeo"
import YouTube from '@u-wave/react-youtube'

import { AssetShape } from "@components/Form"
import { W1, VIMEO_REGEX, YOUTUBE_REGEX } from "@components"

import ProjectEditableGroup from "./ProjectEditableGroup"

const LABEL_TITLE = "Videos"


const Videos = ({ videos = [], isEditable }) => {
  const hasAssets = videos.length > 0
  const fieldNames = [ 'videos' ]

  const extraProps = { isEditable }

  const youtubeContainerStyles = {
    width: "100%",
    aspectRatio: "16/9"
  }

  return (
    <ProjectEditableGroup
      title={LABEL_TITLE}
      fieldNames={fieldNames}
      extraProps={extraProps}
    >
      {
        hasAssets && (
          <Space
            size={W1}
            style={{ width: "100%" }}
            direction="vertical"
          >
            {
              videos.map(({ url }, key) => {
                const props = {
                  showTitle: false,
                  showByline: false,
                  responsive: true,
                  playsInline: false,
                  transparent: false,
                  showPortrait: false
                }

                if (VIMEO_REGEX.test(url)) {
                  return (<Vimeo id={`video-${key}`} key={key} video={url} {...props}/>)
                }

                if (YOUTUBE_REGEX.test(url)) {
                  const videoId = url.match(YOUTUBE_REGEX)[1]

                  return (<div key={key}><YouTube id={`video-${key}`} style={youtubeContainerStyles} video={videoId} {...props}/></div>)
                }
              })
            }
          </Space>
        )
      }
    </ProjectEditableGroup>
  )
}

Videos.propTypes = {
  videos: PropTypes.arrayOf(AssetShape),
  isEditable: PropTypes.bool.isRequired,
}

export default Videos
