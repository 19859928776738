import PropTypes from "prop-types"

import InvestorShape from "./shapes/InvestorShape"
import { AccountStatusEnumKeys } from "./shapes/AccountStatusEnum"

const properties = {
  acceptedTermsVersion: PropTypes.number,
  brokerUserId: PropTypes.string,
  correlationId: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  disableReason: PropTypes.string,
  id: PropTypes.string.isRequired,
  investor: InvestorShape,
  is2FaEnabled: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
  isImmutable: PropTypes.bool.isRequired,
  isSubscribedForCampaigns: PropTypes.bool,
  lastAdminAuthorizedAt: PropTypes.string,
  lastAuthenticatedAt: PropTypes.string,
  lastFundedAt: PropTypes.string,
  note: PropTypes.string,
  noteUpdatedAt: PropTypes.string,
  noteUpdatedBy: PropTypes.string,
  projectIds: PropTypes.arrayOf(PropTypes.string),
  referralSource: PropTypes.string,
  sponsorId: PropTypes.string,
  status: PropTypes.oneOf(AccountStatusEnumKeys).isRequired,
  tagIds: PropTypes.arrayOf(PropTypes.string),
  totalInvestedAmount: PropTypes.number.isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  validationErrorsJson: PropTypes.string,
  verificationRequestCreatedAt: PropTypes.string,
  verificationRequestId: PropTypes.string
}

const UpdateAccountOutputShapeShape = PropTypes.exact(properties)

export default UpdateAccountOutputShapeShape
export { properties }
