import React from "react"
import PropTypes from "prop-types"
import { Space, Typography } from "antd"

import Identity from "@components/Identity"
import { W0, W2 } from "@components"
import { Value } from "@components/Text"

const LABEL_LAST_UPDATED = "Last updated on"


const Footer = ({
  updatedAt = undefined,
  updatedBy = undefined
}) => {
  const style = {
    top: W0 + 4,
    height: 24,
    position: "relative",
    marginLeft: W2 * 3,
  }

  return (
    <div style={style}>
      {
        updatedAt && (
          <Space size={6}>
            <Typography.Text>
              {LABEL_LAST_UPDATED}
            </Typography.Text>

            <Value value={updatedAt} />
            by
            <Identity id={updatedBy} />
          </Space>
        )
      }
    </div>
  )
}

Footer.propTypes = {
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
}

export default Footer
