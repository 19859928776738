import { useState, useEffect, useCallback } from "react"
import { useOutletContext } from "react-router-dom"

import { updateAccountOperation as operation } from "@api/services/investments"

import getInputs from "./getInputs"


const useSchema = (form, account) => {
  const [ schema, setSchema ] = useState([])

  const { brokersMap } = useOutletContext()

  const { correlationId } = account
  const isEmailDisabled = !!correlationId

  const updateSchema = useCallback(
    () => {
      const formAddress = form.getFieldValue(["investor", "address"])
      const formHasAccreditation = form.getFieldValue(["investor", "hasAccreditation"])

      const inputs = getInputs({
        address: formAddress,
        hasAccreditation: formHasAccreditation,
        brokersMap,
        updateSchema,
        isEmailDisabled,
      })

      const accountSchema = operation.getSchema(inputs)

      setSchema(accountSchema)
    }
  , [ form, brokersMap, isEmailDisabled ])

  useEffect(() => {
    updateSchema()
  }, [ updateSchema ])

  return schema
}

export default useSchema
export { operation }
