import React, { useMemo } from "react"
import PropTypes from "prop-types"

import { Form } from "@components/Form"
import { W0, W1 } from "@components"
import { useAppContext } from "@components/AppContext"
import { OperationShape } from "@api"

import Footer from "./Footer"

const LABEL_SUBMIT = "Save"
const LABEL_SUCCESS = "Note is saved"


const Note = ({
  note = "",
  updatedAt = undefined,
  updatedBy = undefined,
  onAfterSubmit = () => {},
  id,
  operation,
  isEditable,
}) => {
  const item = useMemo(() => ({ id, note }), [ id, note ])

  const { request, showSuccessMessage } = useAppContext()

  const schema = operation.getSchema([
    {
      name: "note",
      label: "",
      itemProps: {
        style: { paddingBottom: 0, marginBottom: 0 }
      },
      inputProps: {
        rows: 4,
        disabled: !isEditable,
        showCount: true,
        maxLength: 2048,
      },
    },
    {
      name: "_stamps",
      component: () => <Footer updatedAt={updatedAt} updatedBy={updatedBy} />
    }
  ])

  const onSuccess = updatedNote => {
    onAfterSubmit(updatedNote)
    showSuccessMessage(LABEL_SUCCESS)
  }

  const submitItemProps = {
    style: {
      marginTop: -1 * W1,
      marginBottom: W0
    }
  }

  const submitButtonProps = {
    size: "small",
    style: { width: "initial" }
  }

  const submitLabel = LABEL_SUBMIT
  const hasSubmitButton = isEditable

  const formProps = {
    item,
    schema,
    request,
    onSuccess,
    operation,
    submitLabel,
    hasSubmitButton,
    submitItemProps,
    submitButtonProps,
  }

  return (
    <Form {...formProps} />
  )
}

Note.propTypes = {
  id: PropTypes.string.isRequired,
  note: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  operation: OperationShape.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onAfterSubmit: PropTypes.func,
}

export default Note
