import React from "react"

import { Value } from "@components/Text"
import { getCurrencyValue } from "@components/Amount"

const LABEL_FUNDING = "Send Funding"


const getInvestmentFundingAmountTitle = investment => {
  const {
    totalAmount,
    receivedAmount,
    isPartiallyFunded,
  } = investment

  const fundingAmount = isPartiallyFunded
    ? `${getCurrencyValue(receivedAmount)} of ${getCurrencyValue(totalAmount)}`
    : <Value value={totalAmount} />

  const title = <>{LABEL_FUNDING} ({fundingAmount})</>

  return title
}

export default getInvestmentFundingAmountTitle
