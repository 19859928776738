import React from "react"

import { W3 } from "@components"
import { PlatformFooter } from "@components/Page"


const Footer = () => {
  return (
    <PlatformFooter
      marginTop={W3}
      shouldRenderOnMobile={true}
    />
  )
}

export default Footer
