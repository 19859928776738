import React, { useState } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { PlusOutlined } from "@ant-design/icons"
import { Divider, Input, Row, Col, Button } from "antd"

import { W1 } from "@components"

const LABEL_ADD = "Add"
const LABEL_PLACEHOLDER = "Enter subscription type name..."


const OptionsDropdown = ({ menu, group }) => {
  const { ensureOption } = useOutletContext()

  const [ name, setName ] = useState("")
  const [ isLoading, setIsLoading ] = useState(false)

  const isDisabled = name === ""

  const onNameChange = (event) => {
    const { value = "" } = event.target
    setName(value)
  }

  const onClick = async (e) => {
    e.preventDefault()

    const isEmpty = name === ""

    if (isEmpty) {
      return
    }

    setIsLoading(true)
    return ensureOption(group, name)
      .then(() => setName(""))
      .then(() => setIsLoading(false))
  }

  return (
    <>
      {menu}

      <Divider style={{ margin: "8px 0" }} />

      <Row
        style={{ padding: "0 0 4px" }}
        gutter={W1}
      >
        <Col flex="auto">
          <Input
            value={name}
            variant="borderless"
            onChange={onNameChange}
            onKeyDown={(e) => e.stopPropagation()}
            placeholder={LABEL_PLACEHOLDER}
          />
        </Col>

        <Col>
          <Button
            type="text"
            icon={<PlusOutlined />}
            loading={isLoading}
            onClick={onClick}
            disabled={isDisabled}
          >
            {LABEL_ADD}
          </Button>
        </Col>
      </Row>
    </>
  )
}

OptionsDropdown.propTypes = {
  menu: PropTypes.shape().isRequired,
  group: PropTypes.string.isRequired,
}

export default OptionsDropdown
