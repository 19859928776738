import React from "react"
import { Typography, Space, Tag } from "antd"

import { Value } from "@components/Text"
import { CopyValueButton } from "@components/Button"

import VerificationStatusTag from "../../CustomersTable/VerificationStatusTag"

const LABEL_ID = "ID"
const LABEL_NAME = "Name"
const LABEL_EMAIL = "Email"
const LABEL_STATUS = "Status"
const LABEL_UPDATED = "Last Updated At"
const LABEL_COGNITO = "Cognito ID"
const LABEL_CREATED = "Created At"
const LABEL_IMPERSONATED = "Impersonated"


const getItems = customer => {
  if (!customer) {
    return []
  }

  const {
    id,
    lastName,
    firstName,
    createdAt,
    updatedAt,
    controllerId,
    isImpersonated,
    controllerEmail,
    verificationStatus,
  } = customer

  let { name } = customer

  const isPersonal = [ "Impersonated", "Personal" ].includes(name)

  if (isPersonal) {
    name = `${firstName} ${lastName}`
  }

  const items = [
    {
      key: "name",
      label: LABEL_NAME,
      children: (
        <Space>
          <Typography.Text strong>
            {name}
          </Typography.Text>

          {
            isImpersonated && (
              <Tag>{LABEL_IMPERSONATED}</Tag>
            )
          }
        </Space>
      ),
    },
    {
      key: "id",
      label: LABEL_ID,
      children: (
        <CopyValueButton value={id} />
      ),
    },
    {
      key: "controllerEmail",
      label: LABEL_EMAIL,
      children: controllerEmail,
    },
    {
      key: "controllerId",
      label: LABEL_COGNITO,
      children: controllerId,
    },
    {
      key: "verificationStatus",
      label: LABEL_STATUS,
      children: <VerificationStatusTag status={verificationStatus} />,
    },
    {
      key: "createdAt",
      label: LABEL_CREATED,
      children: <Value value={createdAt} />,
    },
    {
      key: 'updatedAt',
      label: LABEL_UPDATED,
      children: <Value value={updatedAt} />,
    }
  ]

  return items
}

export default getItems
