import React from "react"

import Text from "@components/Text"
import { isBackstage } from "@components"

import LogoutRedirect from "./LogoutRedirect"
import AuthorizationError from "./AuthorizationError"
import AuthorizationStoreShape from "./shapes/AuthorizationStoreShape"

const LABEL_AUTHORIZING = "Authorizing..."


const Authorization = ({
  authorizationStore
}) => {
  const {
    isLoading,
    isAuthorized,
    isAuthenticated,
    authorizationError,
    authenticationError,
  } = authorizationStore

  if (isLoading) {
    return <Text text={LABEL_AUTHORIZING} />
  }

  if (!isAuthenticated) {
    if (authorizationError) {
      return (
        <AuthorizationError
          message={authenticationError}
          isBackstage={isBackstage}
        />
      )
    }

    return (
      <LogoutRedirect />
    )
  }

  if (!isAuthorized) {
    if (authorizationError) {
      return (
        <AuthorizationError
          message={authorizationError}
          isBackstage={isBackstage}
        />
      )
    }

    return <Text text={LABEL_AUTHORIZING} />
  }

  return null
}

Authorization.propTypes = {
  authorizationStore: AuthorizationStoreShape.isRequired,
}

export default Authorization
