import { TransactionStatusEnumKeys } from "./shapes/TransactionStatusEnum"
import { TransactionTypeEnumKeys } from "./shapes/TransactionTypeEnum"

const IndexMyTransactionsItemSchema = {
  type: { enum: TransactionTypeEnumKeys },
  date: { format: 'date' },
  amount: { type: 'integer' },
  isApproved: { type: 'boolean' },
  isExternal: { type: 'boolean' },
  status: { enum: TransactionStatusEnumKeys },
  idempotencyCount: { type: 'integer' },
  senderFailedNotificationCreatedAt: { format: 'date-time' },
  receiverFailedNotificationCreatedAt: { format: 'date-time' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexMyTransactionsItemSchema
