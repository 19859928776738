import React, {useMemo, useState} from "react"
import PropTypes from "prop-types"
import { Button, Upload } from "antd"
import { UploadOutlined } from "@ant-design/icons"

import { getCustomUploadRequest, FILE_TYPES } from "@components/Storage"

import FormShape from "../shapes/FormShape"

const ACTION_TITLE = "Upload File"


/* td: add support for thumbnails */
const getFileList = (fileList, path, form) => {
  if (fileList) {
    return fileList
  }

  const url = form.getFieldValue(path)

  if (!url) { return [] }

  const [ name ] = url.split('/').reverse()

  return [{ url, name }]
}


const UploadInput = ({
  isPublic = false,
  onChange = () => {},
  fileTypes = FILE_TYPES,
  actionTitle = ACTION_TITLE,
  beforeUpload = undefined,
  onUploadChange = undefined,
  isUploadRemovable = true,
  getDefaultFileList = undefined,
  form,
  path,
  request,
}) => {
  const [ fileList, setFileList ] = useState()

  const customRequest = getCustomUploadRequest(request, isPublic)

  onUploadChange = onUploadChange
    ? onUploadChange
    : (_path, _form, event) => {
      setFileList(event.fileList)

      const { file } = event

      const isDone = file.status === "done"
      const isRemoved = file.status === "removed"
      const shouldIgnore = !isDone && !isRemoved

      if (shouldIgnore) {
        return
      }

      let url = ""

      if (isDone) {
        url = file.response.url
      }

      onChange(event, form)

      form.setFieldValue(path, url)
    }

  const props = {
    accept: fileTypes.join(','),
    method: "PUT",
    maxCount: 1,
    onChange: info => onUploadChange(path, form, info),
    customRequest,
  }

  if (isUploadRemovable) {
    props.onRemove = file => onUploadChange(path, form, { file, fileList: [] })

  } else {
    props.showUploadList = {
      showRemoveIcon: false
    }
  }

  if (beforeUpload) {
    props.beforeUpload = file =>
      new Promise((resolve, reject) => {
        const shouldUpload = beforeUpload(file)

        if (!shouldUpload) {
          reject()
        }

        resolve()
      })
  }

  props.defaultFileList = useMemo(() => getDefaultFileList
    ? getDefaultFileList(path, form)
    : undefined
  , [ getDefaultFileList, form, path ])

  props.fileList =  useMemo(() => getDefaultFileList
    ? undefined
    : getFileList(fileList, path, form)
  , [ getDefaultFileList, fileList, form, path ])

  return (
    <Upload {...props}>
      <Button icon={<UploadOutlined/>}>
        {actionTitle}
      </Button>
    </Upload>
  )
}

UploadInput.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  /* note: custom component props */
  request: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  isPublic: PropTypes.bool,
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  actionTitle: PropTypes.string,
  onUploadChange: PropTypes.func,
  isUploadRemovable: PropTypes.bool,
  getDefaultFileList: PropTypes.func,
  beforeUpload: PropTypes.func,
}

export default UploadInput
