import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { useForm } from "@components/Form"
import { CreateModal } from "@components/Page"
import { createUserGroupOperation as createOperation } from "@api/services/backstage"

const LABEL_SUBMIT = "Add"
const LABEL_ENTITY = "Group"
const LABEL_TITLE = `Add ${LABEL_ENTITY}`


const UserGroupCreateModal = ({ modal }) => {
  const form = useForm()

  const { addUserGroup } = useOutletContext()

  const onSuccess = newUserGroup =>
    addUserGroup(newUserGroup)

  const title = LABEL_TITLE
  const entity = LABEL_ENTITY
  const schema = createOperation.getSchema([])

  const createModalProps = {
    operation: createOperation,
    submitLabel: LABEL_SUBMIT,
    form,
    modal,
    title,
    entity,
    schema,
    onSuccess,
  }

  return (
    <CreateModal {...createModalProps} />
  )
}

UserGroupCreateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default UserGroupCreateModal
