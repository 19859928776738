import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import { EyeOutlined } from "@ant-design/icons"

const LABEL_PREVIEW = "Preview"


const CampaignPreviewButton = ({ saveCampaign, openPreviewModal }) => {
  const [ isLoading, setIsLoading ] = useState(false)

  const style = { width: "100%" }

  const onClick = () => {
    setIsLoading(true)
    return saveCampaign()
      .then(isSaved => isSaved ? openPreviewModal() : false)
      .then(() => setIsLoading(false))
  }

  const buttonProps = {
    icon: <EyeOutlined />,
    size: "large",
    type: "dashed",
    shape: "round",
    loading: isLoading,
    style,
    onClick,
  }

  return (
    <>
      <Button {...buttonProps}>
        {LABEL_PREVIEW}
      </Button>
    </>
  )
}

CampaignPreviewButton.propTypes = {
  saveCampaign: PropTypes.func.isRequired,
  openPreviewModal: PropTypes.func.isRequired,
}

export default CampaignPreviewButton
