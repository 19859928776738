import {
  COUNTRY_US,
  US_STATE_OPTIONS,
  LABEL_STATE_PLACEHOLDER
} from "@components/Address"
import {
  TYPE_ZIP,
  TYPE_STRING,
  TYPE_HIDDEN,
} from "@components/Form"


const getUsAddressSchema = (address = {}) => {
  const {
    streetAddress,
    addressLocality,
    addressRegion,
    postalCode,
    // addressCountry
  } = address

  return [
    {
      name: "streetAddress",
      type: TYPE_STRING,
      label: "Street",
      maxLength: 50,
      placeholder: "",
      itemProps: { style: { width: "75%" } },
      initialValue: streetAddress,
    },
    {
      name: "addressLocality",
      type: TYPE_STRING,
      label: "City",
      maxLength: 32,
      placeholder: "",
      itemProps: { style: { width: "75%" } },
      initialValue: addressLocality,
    },
    {
      name: "addressRegion",
      type: TYPE_STRING,
      label: "State",
      options: US_STATE_OPTIONS,
      placeholder: LABEL_STATE_PLACEHOLDER,
      inputProps: {
        style: { width: 180 },
      },
      initialValue: addressRegion,
    },
    {
      name: "postalCode",
      type: TYPE_ZIP,
      label: "ZIP code",
      placeholder: "",
      initialValue: postalCode,
    },
    {
      name: "addressCountry",
      type: TYPE_HIDDEN,
      initialValue: COUNTRY_US,
      placeholder: "",
      // initialValue: addressCountry,
    },
  ]
}

export default getUsAddressSchema
