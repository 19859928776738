import PropTypes from "prop-types"

import EntityShape from "./EntityShape"
import IndividualShape from "./IndividualShape"
import JointShape from "./JointShape"
import SdiraShape from "./SdiraShape"
import { ProfileTypeEnumKeys } from "./ProfileTypeEnum"

const properties = {
  investmentIds: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  profileEntity: EntityShape,
  profileIndividual: IndividualShape,
  profileJoint: JointShape,
  profileSdira: SdiraShape,
  profileType: PropTypes.oneOf(ProfileTypeEnumKeys).isRequired,
  receiverSourceId: PropTypes.string,
  receiverSourceName: PropTypes.string
}

const ProfileShape = PropTypes.exact(properties)

export default ProfileShape
export { properties }
