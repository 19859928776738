import React, { useState } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { UpdateModal } from "@components/Page"
import { MODAL_WIDTH } from "@components"
import { updatePropertyOperation as operation } from "@api/services/backstage"

import getInputs from "../ProjectPropertyCreateModal/helpers/getInputs"

import Footer from "./Footer"

const LABEL_ENTITY = "Property"
const LABEL_TITLE = `Edit ${LABEL_ENTITY}`
const LABEL_SUBMIT = "Save"


const ProjectPropertyUpdateModal = ({ modal }) => {
  const [ schema, setSchema ] = useState([])

  const {
    project,
    projects,
    properties,
    getProperty,
    updateProperty,
  } = useOutletContext()

  modal.current.openWithProperties = async (id) => {
    const getUpdateInputs = getInputs({
      project,
      projects,
      properties,
      excludeInputNames: [ "projectId" ]
    })

    const inputs = getUpdateInputs()
    const newSchema = operation.getSchema(inputs)
    setSchema(newSchema)

    const property = getProperty(id)
    modal.current.openItem(property)
  }

  const closeModal = () =>
    modal.current.close()

  const onSuccess = updatedProperty =>
    updateProperty(updatedProperty)

  const footer = property =>
    property
      ? (
          <Footer
            closeModal={closeModal}
            propertyId={property.id}
            propertyName={property.name}
          />
        )
      : null

  const updateModalProps = {
    title: LABEL_TITLE,
    width: MODAL_WIDTH * 1.25,
    entity: LABEL_ENTITY,
    submitLabel: LABEL_SUBMIT,
    modal,
    schema,
    footer,
    onSuccess,
    operation,
  }

  return (
    <UpdateModal {...updateModalProps} />
  )
}

ProjectPropertyUpdateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default ProjectPropertyUpdateModal
