import { getAddressSchema } from "@components/Address"
import { TYPE_NONE, TYPE_TIMEZONE } from "@components/Form"

import { updateMyImmutableAccountInvestorOperation as updateOperation } from "@api/services/investments"

const LABEL_TIMEZONE = "Time Zone"


const getUpdateImmutableAccountSchema = ({
  address,
  updateSchema,
}) => {
  const schema = updateOperation.getSchema([
    {
      name: "alternativeName",
      label: "Preferred Name",
    },
    {
      name: "mobileNumber",
    },
    {
      name: "address",
      label: "",
      schema: getAddressSchema(updateSchema, ["address"], address),
    },
    {
      name: "timeZoneCode",
      type: TYPE_TIMEZONE,
      label: LABEL_TIMEZONE,
      required: false,
    },
    {
      name: "landNumber",
      type: TYPE_NONE,
    },
  ])

  return schema
}

export default getUpdateImmutableAccountSchema
