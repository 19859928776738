import React from "react"
import keyBy from "lodash.keyby"
import { Typography } from "antd"

const DEFAULT_LIMIT = 5
const DEFAULT_AMOUNT = 100000000

const { Text } = Typography


const getCollectionItems = (projectId, brokers, isPublishedProject, partners = [], sponsors = [], funds = []) => {
  const activeBrokers = brokers
    .filter(({ isDisabled }) => !isDisabled)

  const brokersMapBySponsorId = keyBy(activeBrokers, "sponsorId")

  const sortedSponsorPartners =
    [...sponsors].sort((a, b) => a.name > b.name ? 1 : -1)

  const sortedFundPartners =
    [...funds].sort((a, b) => a.name > b.name ? 1 : -1)

  const allPartners = [
    ...sortedSponsorPartners,
    ...sortedFundPartners
  ].filter(notEmpty => notEmpty)

  const connectedFundsMap = keyBy(partners, 'fundId')
  const connectedSponsorsMap = keyBy(partners, 'sponsorId')

  const items = allPartners
    .map(({ id, name, receiverSourceId, receiverSourceName }) => {
      const connectedPartner = connectedFundsMap[id] || connectedSponsorsMap[id]
      const isConnected = !!connectedPartner

      const type = id.startsWith("SPO_") ? "sponsor" : "fund"
      const isSponsor = id.startsWith("SPO_")

      const projectPartnerId = connectedPartner?.id
      const hasBroker = brokersMapBySponsorId[id] !== undefined
      const sponsorName = hasBroker ? name : <>{name} <Text type="secondary">(no liaisons)</Text></>
      const partnerName = isSponsor ? sponsorName : name

      let goal = DEFAULT_AMOUNT
      let limit = DEFAULT_LIMIT

      if (connectedPartner) {
        goal = connectedPartner.targetAmount
        limit = connectedPartner.nonAccreditedInvestorsMax
      }

      return {
        key: id,
        disabled: isPublishedProject,
        connected: isConnected,
        id,
        type,
        name: partnerName,
        goal,
        limit,
        projectId,
        isSponsor,
        receiverSourceId,
        projectPartnerId,
        receiverSourceName,
      }
    })

  return items
}

export default getCollectionItems
