import React from "react"
import { useOutletContext } from "react-router-dom"

import { W0, W1 } from "@components"
import {
  OrganizationEmailIdentity,
  OrganizationEmailIdentityConnect,
} from "@modules/backstage/organizations/OrganizationEmailIdentity"


const SettingsOrganizationEmailIdentityPage = () => {
  const { organization } = useOutletContext()

  const { emailIdentity = {} } = organization

  const hasEmailIdentity = Object.values(emailIdentity).length > 0

  const style = {
    padding: `${W1}px ${W1 + W0}px`,
  }

  return (
    <div style={style}>
      {
        !hasEmailIdentity && (
          <OrganizationEmailIdentityConnect />
        )
      }

      {
        hasEmailIdentity && (
          <OrganizationEmailIdentity />
        )
      }
    </div>
  )
}

export default SettingsOrganizationEmailIdentityPage
