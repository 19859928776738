import { isBackstage } from "@components"


const getThemeToken = () => {
  if (isBackstage) {
    return {}
  }

  return {
    colorText: '#253B80',
    colorLink: '#169BD7',
    colorPrimary: '#169BD7',
    colorTextDescription: '#8B8DB9',
    Descriptions: {
      colorTextTertiary: '#253B80',
    },
    Table: {
      headerBg: '#F5F5F5',
      headerSplitColor: '#E6E6E6',
      headerBorderRadius: 4,
      cellPaddingInlineMD: 16,
    }
  }
}

export default getThemeToken
