import React from "react"
import PropTypes from "prop-types"
import { Descriptions, Space } from "antd"

import TierShape from "@api/services/backstage/shapes/TierShape"

import InvestmentShape from "./shapes/InvestmentShape"
import InvestmentErrors from "./InvestmentErrors"
import renderInvestmentItems from "./helpers/renderInvestmentItems"

const { Item } = Descriptions


const InvestmentProfile = ({
  footer = null,
  tiers,
  investment,
}) => {
  const items = renderInvestmentItems(tiers, investment)

  const {
    profileErrors = [],
    isFunded
  } = investment

  return (
    <Space direction="vertical">
      <Descriptions
        size="small"
        column={2}
        layout="vertical"
        className="summary"
      >
        {
          items.map(({ value, ...itemProps }, key) => (
            <Item key={key} {...itemProps}>
              {value}
            </Item>
          ))
        }
      </Descriptions>

      <InvestmentErrors
        errors={profileErrors}
        isFunded={isFunded}
      />

      {footer}
    </Space>
  )
}

InvestmentProfile.propTypes = {
  tiers: PropTypes.arrayOf(TierShape).isRequired,
  footer: PropTypes.shape(),
  investment: InvestmentShape.isRequired,
}

export default InvestmentProfile
