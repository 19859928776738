import React from "react"
import PropTypes from "prop-types"
import { Form } from "antd"

import { W0, W1 } from "@components"
import { OperationShape } from "@api"

import SubmitButton from "./SubmitButton"

const { Item } = Form

const LABEL_CREATE_ACTION = "Create"
const LABEL_UPDATE_ACTION = "Update"


const FormSubmit = ({
  submitLabel = undefined,
  submitItemProps = {},
  submitButtonProps = {},
  isLoading,
  operation,
}) => {
  const { isCreate } = operation

  const defaultSubmitLabel = isCreate
    ? LABEL_CREATE_ACTION
    : LABEL_UPDATE_ACTION

  submitLabel = submitLabel
    ? submitLabel
    : defaultSubmitLabel

  const style = {
    marginTop: W1,
    marginBottom: W0,
  }

  return (
    <Item
      style={style}
      {...submitItemProps}
    >
      <SubmitButton
        isLoading={isLoading}
        {...submitButtonProps}
      >
        {submitLabel}
      </SubmitButton>
    </Item>
  )
}

FormSubmit.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  operation: OperationShape.isRequired,
  submitLabel: PropTypes.string,
  submitItemProps: PropTypes.shape(),
  submitButtonProps: PropTypes.shape(),
}

export default FormSubmit
