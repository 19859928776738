import PropTypes from "prop-types"

import BusinessControllerShape from "./BusinessControllerShape"
import UsAddressShape from "./UsAddressShape"
import { BusinessTypeEnumKeys } from "./BusinessTypeEnum"

const properties = {
  address: UsAddressShape.isRequired,
  businessClassificationId: PropTypes.string.isRequired,
  businessName: PropTypes.string.isRequired,
  businessType: PropTypes.oneOf(BusinessTypeEnumKeys).isRequired,
  controller: BusinessControllerShape.isRequired,
  ein: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired
}

const BusinessCustomerShape = PropTypes.exact(properties)

export default BusinessCustomerShape
export { properties }
