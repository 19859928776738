import sortBy from "lodash.sortby"
import groupBy from "lodash.groupby"

import { getFormattedDecimalValue } from "@components/Amount"
import { TRANSACTION_STATUS, TRANSACTION_TYPES } from "@components/Domain"

import computeDistributionStatus from "./computeDistributionStatus"

const TRANSACTION_STATUSES = Object.keys(TRANSACTION_STATUS)


const isDistributionTransaction = ({ type, retryTransactionId }) =>
  type !== TRANSACTION_TYPES.FUNDING && !retryTransactionId


const getGroupId = ({ date, investmentId }) =>
  `${date}-${investmentId}`


const computeDistributions = transactions => {
  const distributions = []

  const targetTransaction = transactions
    .filter(isDistributionTransaction)
    .map(transaction => ({ _groupId: getGroupId(transaction), ...transaction }))

  const sortedTransactions = sortBy(targetTransaction, "createdAt")
  const groupedTransactionsMap = groupBy(sortedTransactions, "_groupId")

  const groupIds = Object.keys(groupedTransactionsMap)

  for (const groupId of groupIds) {
    const groupTransactions = groupedTransactionsMap[groupId]

    const getTransactionsByStatus = status =>
      groupTransactions.filter(t => t.status === status)

    for (const status of TRANSACTION_STATUSES) {
      const statusGroupTransactions = getTransactionsByStatus(status)

      const transactionsByTypeMap = groupBy(statusGroupTransactions, 'type')
      const transactionsByTypeValues = Object.values(transactionsByTypeMap)
      const transactionsByTypeCountMax = transactionsByTypeValues.length > 0 ?
        Math.max(...transactionsByTypeValues.map(array => array.length)) : 0

      const statusGroupDistributionsApproved = Array.from({ length: transactionsByTypeCountMax }, () => [])
      const statusGroupDistributionsUnapproved = Array.from({ length: transactionsByTypeCountMax }, () => [])

      const types = Object.keys(transactionsByTypeMap)

      for (const type of types) {
        const typeTransactions = transactionsByTypeMap[type]

        Object.entries(typeTransactions).forEach(([ index, transaction ]) => {
          const { isApproved } = transaction

          const targetArray = isApproved
            ? statusGroupDistributionsApproved[index]
            : statusGroupDistributionsUnapproved[index]

          targetArray.push(transaction)
        })
      }

      const statusGroupDistributions = statusGroupDistributionsApproved
        .concat(statusGroupDistributionsUnapproved)
        .filter(array => array.length > 0)

      for (const [ index, distributionTransactions ] of Object.entries(statusGroupDistributions)) {
        const distributionId = `${groupId}-${status}-${index}`
        const [ firstTransaction ] = distributionTransactions

        const {
          date,
          isApproved,
          investmentId,
          brokerUserId,
          investmentName,
          investorAccountId,
          isExternal
        } = firstTransaction

        const distributionStatus = computeDistributionStatus(distributionTransactions)
        const transactionIds = distributionTransactions.map(({ id }) => id)

        const total = distributionTransactions
          .map(({ amount }) => amount)
          .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

        const distribution = {
          id: distributionId,
          status: distributionStatus,
          transactions: distributionTransactions,
          date,
          total,
          isApproved,
          brokerUserId,
          investmentId,
          investmentName,
          transactionIds,
          investorAccountId,
          isExternal
        }

        distributionTransactions.forEach(transaction =>
          distribution[transaction.type] = transaction
        )

        distributions.push(distribution)
      }
    }
  }

  return distributions
}

export default computeDistributions
