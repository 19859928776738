

const getHeaderTitle = (itemsCount, filteredItemsCount) => {
  const hasFilters = filteredItemsCount !== itemsCount

  const count = hasFilters
    ? `${filteredItemsCount} of ${itemsCount}`
    : `${itemsCount}`

  const hasOne = filteredItemsCount === 1

  const entries = hasOne
    ? 'entry'
    : 'entries'

  return `Showing ${count} ${entries}`
}

export default getHeaderTitle
