import { TYPE_ARRAY } from "@components/Form"

const LABEL_GROUPS = "Access Groups"
const PLACEHOLDER_ORGANIZATION_NAME = "Organization Name"

const getInputs = (userGroups) => {
  const userGroupsOptions =
    userGroups.map(({ id: value, name: label }) => ({ value, label }))

  return [
    {
      name: "name",
      placeholder: PLACEHOLDER_ORGANIZATION_NAME
    },
    {
      name: "userGroupIds",
      type: TYPE_ARRAY,
      label: LABEL_GROUPS,
      options: userGroupsOptions,
      required: false,
    }
  ]
}

export default getInputs
