import React from "react"
import { Alert } from "antd"
import PropTypes from "prop-types"

import { useAppContext } from "@components/AppContext"
import { PROJECT_STATES } from "@components/Domain"
import { InvestmentShape } from "@components/Investment/shapes"

import getInvalidSourceParams from "./helpers/getInvalidSourceParams"
import InvalidSourcesAlertMessage from "./InvalidSourcesAlertMessage"


const InvalidSourcesAlert = ({
  getProject,
  investments,
}) => {
  const { identity } = useAppContext()

  const isActive = projectId =>
    getProject(projectId)?.state !== PROJECT_STATES.ARCHIVED

  const activeInvestments = investments
    .filter(({ projectId }) => isActive(projectId))

  const [
    hasInvalidSourceInvestments,
    sourceName,
    customerName,
    isVerifiedCustomer,
  ] = getInvalidSourceParams(identity, activeInvestments)

  if (!hasInvalidSourceInvestments) {
    return null
  }

  return (
    <Alert
      banner
      type="error"
      message={
        <InvalidSourcesAlertMessage
          sourceName={sourceName}
          customerName={customerName}
          isVerifiedCustomer={isVerifiedCustomer}
        />
      }
    />
  )
}

InvalidSourcesAlert.propTypes = {
  getProject: PropTypes.func.isRequired,
  investments: PropTypes.arrayOf(InvestmentShape).isRequired,
}

export default InvalidSourcesAlert
