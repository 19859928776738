import React from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

const { Text } = Typography


const VerifyCertificate = ({
  dnsName = "-",
  dnsValue = "-"
}) => {
  return (
    <>
      <Text>
        To connect your domain to the portal, you&apos;ll need to create a CNAME record in your DNS settings. This record acts as proof of ownership to successfully verify the issued SSL certificate.
      </Text>

      <ol>
        <li>
          Log in to your domain&apos;s DNS management panel.
        </li>
        <li>
          Locate the section to add a new record (often labeled <strong>DNS Management</strong>, <strong>DNS Settings</strong>, <strong>DNS Records Manager</strong> etc).
        </li>
        <li>
          Choose <strong>CNAME</strong> as the record type.
        </li>
        <li>
          Enter the following details:
          <ul>
            <li>
              Name/Host/Alias: <Text code copyable>{dnsName}</Text>
            </li>
            <li>
              Value/Points to/Destination: <Text code copyable>{dnsValue}</Text>
            </li>
            <li>
              TTL (Time to Live): 60 seconds <i>(optional)</i>
            </li>
          </ul>
        </li>
        <li>
          Save the changes.
        </li>
      </ol>
    </>
  )
}

VerifyCertificate.propTypes = {
  dnsName: PropTypes.string,
  dnsValue: PropTypes.string,
}

export default VerifyCertificate
