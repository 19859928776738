import { useState, useEffect } from "react"

import { runRequest } from "@components/AppContext"

import useProfilesStore, { profilesStoreProperties } from "./useProfilesStore"
import useProjectsStore, { projectsStoreProperties } from "./useProjectsStore"
import useDocumentsStore, { documentsStoreProperties } from "./useDocumentsStore"
import useInvestmentsStore, { investmentsStoreProperties } from "./useInvestmentsStore"
import useTransactionsStore, { transactionsStoreProperties } from "./useTransactionsStore"


const useInvestorStore = () => {
  const [ isLoading, setIsLoading ] = useState()

  const profilesStore = useProfilesStore()
  const projectsStore = useProjectsStore()
  const documentsStore = useDocumentsStore()
  const investmentsStore = useInvestmentsStore()
  const transactionsStore = useTransactionsStore()

  const { indexProfiles } = profilesStore
  const { indexProjects } = projectsStore
  const { indexDocuments } = documentsStore
  const { indexInvestments } = investmentsStore
  const { indexTransactions } = transactionsStore

  const isReady = isLoading !== undefined && !isLoading

  useEffect(() => {
    if (isReady) {
      return
    }

    if (isLoading) {
      return
    }

    return runRequest(() => {
      setIsLoading(true)

      Promise.all([
        indexProfiles(),
        indexProjects(),
        indexDocuments(),
        indexInvestments(),
        indexTransactions(),
      ]).then(() => setIsLoading(false))
    })
  }, [
    isReady,
    isLoading,
    indexProjects,
    indexProfiles,
    indexDocuments,
    indexInvestments,
    indexTransactions
  ])

  const context = {
    ...profilesStore,
    ...projectsStore,
    ...documentsStore,
    ...investmentsStore,
    ...transactionsStore,
  }

  return {
    isReady,
    context
  }
}

const investorStoreProperties = {
  ...profilesStoreProperties,
  ...projectsStoreProperties,
  ...documentsStoreProperties,
  ...investmentsStoreProperties,
  ...transactionsStoreProperties
}

export default useInvestorStore

export {
  investorStoreProperties
}
