import { App } from "antd"

import { confirmAction } from "@components/Page"

const ACTION_ADD = "ADD"
const ACTION_DELETE = "DELETE"

const ACTIONS_MAP = {
  [ACTION_ADD]: {
    type: "primary",
    title:  "Confirm Investment Funding?",
    action: "Confirm",
    content: `By clicking "Confirm" below, the funding status will change. An
email notification will be sent to the investor.`,
    templateDisabledContent: `By clicking "Confirm" below, the funding status
will change. No email notification will be sent to the investor.`
  },
  [ACTION_DELETE]: {
    type: "danger",
    title: "Remove Funding Transaction",
    action: "Remove",
    content: 'Removes funding transaction from investment, updates investment funding status.',
  },
}


const useInvestmentTransactionActions = ({
  record,
  addTransactionAction,
  deleteTransactionAction,
  isInvestmentFundedTemplateEnabled
}) => {
  const { modal } = App.useApp()

  const action = actionName => {
      const {
        templateDisabledContent,
        content: templateEnabledContent
      } = ACTIONS_MAP[actionName]

      let content = templateEnabledContent

      const isAddAction = actionName === ACTION_ADD

      if (isAddAction) {
        content = isInvestmentFundedTemplateEnabled()
          ? templateEnabledContent
          : templateDisabledContent
      }

      return confirmAction(
        modal,
        ACTIONS_MAP[actionName].action,
        ACTIONS_MAP[actionName].title,
        content,
        () => {
          if (actionName === ACTION_ADD) {
            return addTransactionAction()
          }

          return deleteTransactionAction(record)
        },
        ACTIONS_MAP[actionName].type
      )

  }

  const addTransaction = () => action(ACTION_ADD)

  const deleteTransaction = () => action(ACTION_DELETE)

  return {
    addTransaction,
    deleteTransaction,
  }
}

export default useInvestmentTransactionActions
