import { useOutletContext } from "react-router-dom"

import { ACTIVE } from "@api/services/investments/shapes/InvestmentStatusEnum"
import { useAppContext } from "@components/AppContext"
import { indexProjectInvestmentsOperation } from "@api/services/investments"
import {
  useExportProgress,
  getInvestmentExportColumns,
  getGlobalInvestmentClassColumns,
} from "@components/Export"


const useInvestmentsExport = (onReady) => {
  const {
    projects,
    properties,
    getAccount,
    getProperty,
    getTierName,
    getSponsorName,
    bankAccountsMap,
    computeUserFullname,
  } = useOutletContext()

  const { request, getOrganizationId } = useAppContext()

  const totalCount = projects.length

  const currentOrganizationId = getOrganizationId()

  const targetProjects = projects
    .map(project => ({ ...project, globalProjectId: `${currentOrganizationId}-${project.id}` }))

  const classColumns = getGlobalInvestmentClassColumns(targetProjects, getTierName)

  const fileName = `${currentOrganizationId}_investments`

  const getRows = async ({ shouldStop, updateProgress }) => {
    const rows = []
    let index = 1

    for (const project of projects) {
      if (shouldStop()) {
        return
      }

      const { id: projectId } = project

      const parameters = { projectId }
      const { data: projectInvestments } = await request(indexProjectInvestmentsOperation, parameters)

      const activeProjectInvestments = projectInvestments
        .filter(({ status }) => status === ACTIVE)

      for (const investment of activeProjectInvestments) {
        if (shouldStop()) {
          return
        }

        const columns = getInvestmentExportColumns({
          project,
          properties,
          getAccount,
          getProperty,
          classColumns,
          getSponsorName,
          bankAccountsMap,
          computeUserFullname
        })

        const row = {}

        for (const column of columns) {
          const { title, value } = column
          row[title] = value(investment, currentOrganizationId)
        }

        rows.push(row)
      }

      updateProgress(index, totalCount)
      index++
    }

    return rows
  }

  return useExportProgress({ onReady, fileName, getRows })
}

export default useInvestmentsExport
