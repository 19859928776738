import { TRANSACTION_STATUS } from "@components/Domain"

const PROBLEM_STATUSES = [
  TRANSACTION_STATUS.ERROR,
  TRANSACTION_STATUS.FAILED,
]


const computeStatusReason = (status, statusReason) => {
  const hasProblem = PROBLEM_STATUSES.includes(status)

  if (!hasProblem) {
    return "-"
  }

  if (!statusReason) {
    return "-"
  }

  const isJson = statusReason.startsWith("{\"")

  if (!isJson) {
    return statusReason
  }

  const { body, explanation, code } = JSON.parse(statusReason)

  if (explanation) {
    return `${code} - ${explanation}`
  }

  const { message, _embedded } = body

  if (_embedded) {
    const { errors } = _embedded
    return errors.map(error => error.message).join(" ")
  }

  return message || "N/A"
}

export default computeStatusReason
