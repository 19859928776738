import {
  PENDING_KEY,
  COMPLETE_KEY,
  INCOMPLETE_KEY,
  OVERFUNDED_KEY,
  PARTIALLY_FUNDED_KEY
} from "./Constants"


const getWireLabelKey = investment => {
  const {
    isFunded,
    hasSentFunds,
    isOverfunded,
    isPartiallyFunded,
  } = investment

  if (isFunded) {
    return [ COMPLETE_KEY, false ]
  }

  if (isOverfunded) {
    return [ OVERFUNDED_KEY, false ]
  }

  if (isPartiallyFunded) {
    return [ PARTIALLY_FUNDED_KEY, true ]
  }

  if (hasSentFunds) {
    return [ PENDING_KEY, false ]
  }

  return [ INCOMPLETE_KEY, true ]
}

export default getWireLabelKey
