import React, { useRef, useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { Select, Space, Button } from "antd"

import { SEGMENT } from "@components/Store/BackstageStore/useOptionsStore"

import getFilterValues from "./helpers/getFilterValues"
import SegmentNameModal from "./SegmentNameModal"
import getSegmentOptions from "./helpers/getSegmentOptions"

const LABEL_ALL = "All"
const LABEL_NEW = "New List"
const LABEL_SAVE = "Save"
const LABEL_PLACEHOLDER_FILTERS = "Type to add filters..."
const LABEL_PLACEHOLDER_SEGMENTS = "Select list..."


const TableSegment = ({
  onChange: onFilterChange,
  columns,
  getFilterValue
}) => {
  const segmentNameModal = useRef({})

  const {
    segments,
    getOption,
    addOption
  } = useOutletContext()

  const options = useMemo(() =>
    getSegmentOptions(columns)
  , [ columns ])

  const filterValues = getFilterValues(columns, getFilterValue)
  const hasEnabledFilters = filterValues.length > 0

  const segmentValue = useMemo(() => {
    const description = filterValues.join('::')

    const segment = segments
      .find(option => option.description === description)

    return segment?.id || ""
  }, [ filterValues, segments ])

  const getDefaultLabel = useCallback(() => {
    if (segmentValue) {
      return LABEL_ALL
    }

    if (hasEnabledFilters) {
      return LABEL_NEW
    }

    return LABEL_ALL
  }, [hasEnabledFilters, segmentValue])

  const segmentsOptions = useMemo(() =>
    [
      { label: getDefaultLabel(), value: "" },
      ...segments
        .map(({ name: label, id }) => ({ label, value: id }))
    ]
  , [ segments, getDefaultLabel ])

  const openSegmentNameModal = () =>
    segmentNameModal.current.open()

  const isSaveable = () => {
    if (!hasEnabledFilters) {
      return false
    }

    if (segmentValue) {
      return false
    }

    return true
  }

  const onSubmit = parameters => {
    const { name } = parameters.mutation
    const description = filterValues.join('::')
    parameters.mutation = { group: SEGMENT, name, description }

    return parameters
  }

  const filterOption = (input, option) =>
    (option?.label ?? '')
      .toLowerCase()
      .includes(input.toLowerCase())

  const updateFilters = selectedFilterValues => {
    const filterKeys = columns.map(column => column.dataIndex)

    for (const filterKey of filterKeys) {
      const newFilterValues = selectedFilterValues
        .filter(selectedValue => selectedValue.startsWith(`${filterKey}:`))
        .map(selectedValue => selectedValue.split(":")[1])

      onFilterChange(filterKey, newFilterValues)
    }
  }

  const onChange = selectedValues =>
    updateFilters(selectedValues)

  const onSegmentChange = segmentId => {
    if (!segmentId) {
      return updateFilters([])
    }

    const segment = getOption(segmentId)
    const { description = "" } = segment

    const selectedFilterValues = description.split("::")
    updateFilters(selectedFilterValues)
  }

  const selectSegmentProps = {
    value: segmentValue,
    style: { minWidth: 150 },
    variant: "filled",
    options: segmentsOptions,
    onChange: onSegmentChange,
    showSearch: false,
    allowClear: false,
    placeholder: LABEL_PLACEHOLDER_SEGMENTS,
  }

  const selectFiltersProps = {
    mode: "multiple",
    value: filterValues,
    style: { minWidth: 300 },
    variant: "filled",
    showSearch: true,
    allowClear: true,
    placeholder: LABEL_PLACEHOLDER_FILTERS,
    optionFilterProp: "children",
    options,
    onChange,
    filterOption,
  }

  return (
    <>
      <Space.Compact>
        <Select key="segments" {...selectSegmentProps} />

        <Select key="filters" {...selectFiltersProps} />

        {
          isSaveable() && (
            <Button
              type="link"
              onClick={openSegmentNameModal}
            >
              {LABEL_SAVE}
            </Button>
          )
        }
      </Space.Compact>

      <SegmentNameModal
        modal={segmentNameModal}
        onSubmit={onSubmit}
        onSuccess={addOption}
      />
    </>
  )
}

TableSegment.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  getFilterValue: PropTypes.func.isRequired,
}

export default TableSegment
