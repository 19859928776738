import Storage from "./authenticate/Storage"
import Authorization from "./Authorization"
import LogoutRedirect from "./LogoutRedirect"
import operationRequest from "./request/operationRequest"
import useAuthorizationStore from "./stores/useAuthorizationStore"
import { signOut, getAuthentication } from "./authenticate"

export default Authorization

const hasAccess = permissions => Storage.hasAccess(permissions)

export {
  signOut,
  Storage,
  hasAccess,
  LogoutRedirect,
  operationRequest,
  getAuthentication,
  useAuthorizationStore,
}
