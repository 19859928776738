import getInvestmentAmountForProjectTier from "./getInvestmentAmountForProjectTier"


const getInvestmentClassColumns = (project, getTierName) => {
  const { tiers: projectTiers } = project

  return projectTiers.map(tier => {
    const { name: tierId } = tier

    const title = getTierName(tier)
    const value = (investment) => {
      return getInvestmentAmountForProjectTier(tierId, investment)
    }

    return {
      value,
      title,
    }
  })
}

export default getInvestmentClassColumns
