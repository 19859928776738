import flatten from "lodash.flatten"

import upload from "./upload"
import getImgixUrl from "./getImgixUrl"
import readFileContent from "./readFileContent"
import convertToImgixUrls from "./convertToImgixUrls"
import getResizedImageUrl from "./getResizedImageUrl"
import getCustomUploadRequest from "./getCustomUploadRequest"


/* note: map of mime types to files types based on mime types enum from storage spec */
export const MIME_TYPES_MAP = {
  "text/plain": ".txt",
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": ".png",
  "image/gif": ".gif",
  "image/svg+xml": ".svg",
  "image/heic": "heic",
  "application/zip": ".zip",
  "application/pdf": ".pdf",
  "application/msword": ".doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx",
  "application/vnd.ms-excel": ".xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
  "application/vnd.ms-powerpoint": ".ppt",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": ".pptx",
}

export const FILE_TYPES = flatten(Object.values(MIME_TYPES_MAP))

export {
  upload,
  getImgixUrl,
  readFileContent,
  convertToImgixUrls,
  getResizedImageUrl,
  getCustomUploadRequest,
}
