import PropTypes from "prop-types"

import { properties } from "@api/services/backstage/IndexUsersOutputShape"


const UserShape = PropTypes.exact({
  ...properties,
  fullName: PropTypes.string.isRequired,
})

export default UserShape
