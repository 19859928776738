import React from "react"
import PropTypes from "prop-types"
import { App, Typography } from "antd"
import { useOutletContext } from "react-router-dom"
import { CopyOutlined, DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons"

import { TableRowActions } from "@components/Table"
import { IndexCampaignsOutputShape } from "@api/services/campaigns"

const LABEL_CLONE = "Clone"
const LABEL_DELETE = "Delete"
const LABEL_CONFIRM_TITLE = "Delete Campaign"
const LABEL_CONFIRM_CONTENT = 'Delete campaign. Operation is irreversible.'

const PUBLISHED_STATUS = "PUBLISHED"


const CampaignRowActions = ({
  campaign,
  openCampaignCloneModal
}) => {
  const { modal } = App.useApp()

  const { deleteCampaign } = useOutletContext()

  const { id, status } = campaign
  const isPublished = status === PUBLISHED_STATUS

  const openDeleteConfirm = () => {
    modal.confirm({
      icon: <ExclamationCircleFilled />,
      title: LABEL_CONFIRM_TITLE,
      content: LABEL_CONFIRM_CONTENT,
      okText: LABEL_DELETE,
      okType: "danger",
      onOk() {
        return deleteCampaign(id)
      },
      onCancel() {}
    })
  }

  const items = [
    {
      key: "clone",
      icon: <CopyOutlined />,
      label:
        <Typography.Link onClick={() => openCampaignCloneModal(campaign)}>
          {LABEL_CLONE}
        </Typography.Link>
    },
    {
      type: "divider",
    },
    {
      key: "delete",
      icon: <DeleteOutlined />,
      label:
        <Typography.Link onClick={() => openDeleteConfirm()}>
          {LABEL_DELETE}
        </Typography.Link>,
      danger: true,
      disabled: isPublished,
    }
  ]

  return (
    <TableRowActions
      items={items}
    />
  )
}

CampaignRowActions.propTypes = {
  campaign: IndexCampaignsOutputShape.isRequired,
  openCampaignCloneModal: PropTypes.func.isRequired,
}

export default CampaignRowActions
