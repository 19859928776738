import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { hasAccess } from "@components/Authorization"
import { useForm } from "@components/Form"
import { CreateModal } from "@components/Page"
import { useAppContext } from "@components/AppContext"
// NOTE: Revise operations after child funds concept is revised:
import { createFundMemberOperation } from "@api/services/backstage"

import useOnSubmit from "./helpers/useOnSubmit"
import useSchema, { createMemberOperation } from "./helpers/useSchema"

const LABEL_ADD = "Add"
const LABEL_TITLE = `Add [ENTITY]`
const LABEL_STAFF = "Staff"
const LABEL_USER = "User"


const UserCreateModal = ({ modal }) => {
  const form = useForm()

  const { addUser } = useOutletContext()
  const { isConsoleOrganization } = useAppContext()

  const onSuccess = newUser =>
    addUser(newUser)

  const schema = useSchema(form)
  const onSubmit = useOnSubmit()

  const isFund = hasAccess(["fund-users-write"])

  const createOperation = isFund
    ? createFundMemberOperation
    : createMemberOperation

  const entity = isConsoleOrganization
    ? LABEL_USER
    : LABEL_STAFF

  const title = LABEL_TITLE
    .replace('[ENTITY]', entity)

  const createModalProps = {
    operation: createOperation,
    submitLabel: LABEL_ADD,
    form,
    modal,
    title,
    entity,
    schema,
    onSubmit,
    onSuccess,
  }

  return (
    <CreateModal {...createModalProps} />
  )
}

UserCreateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default UserCreateModal
