import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { Row, Col, List, Space, Tag } from "antd"

import { Header } from "@components/Form"

const LABEL_FUNDING = "Funding"
const LABEL_PROJECTS = "Linked Projects"
const LABEL_DISTRIBUTIONS = "Distributions"


const LinkedProjects = ({ bankAccountId }) => {
  const { projects } = useOutletContext()

  const isLinked = project =>
    (bankAccountId === project.bankAccountId || bankAccountId === project.distributionBankAccountId)

  const linkedProjects = projects.filter(isLinked)

  const hasLinkedProjects = linkedProjects.length > 0

  if (!hasLinkedProjects) {
    return null
  }

  const isFundingOn = project =>
    project.bankAccountId === bankAccountId

  const isDistributionOn = project =>
    project.distributionBankAccountId === bankAccountId

  const itemStyle = {
    paddingLeft: 0,
    paddingRight: 0
  }

  return (
    <>
      <Header>
        {LABEL_PROJECTS}
      </Header>

      <List
        size="small"
        itemLayout="horizontal"
        dataSource={linkedProjects}
        renderItem={project => (
          <List.Item style={itemStyle}>
            <Row style={{ width: "100%" }}>
              <Col flex="auto">
                {project.name}
              </Col>

              <Col>
                <Space size={0}>
                  {
                    isFundingOn(project) && (
                      <Tag bordered={false} color="cyan">{LABEL_FUNDING}</Tag>
                    )
                  }

                  {
                    isDistributionOn(project) && (
                      <Tag bordered={false} color="magenta">{LABEL_DISTRIBUTIONS}</Tag>
                    )
                  }
                </Space>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </>
  )
}

LinkedProjects.propTypes = {
  bankAccountId: PropTypes.string.isRequired,
}

export default LinkedProjects
