import React from "react"
import { Typography } from "antd"

import { Value } from "@components/Text"
import { JOINT_MEMBERSHIP_INTERESTS_TITLES_MAP } from "@components/Domain"

import breakRow from "./breakRow"
import renderAdditional from "./renderAdditional"
import renderResidenceState from "./renderResidenceState"

const { Text } = Typography

const LABEL_SSN = "Social Security Number"
const LABEL_TITLE = "Title of Membership Interests"
const LABEL_NAME1 = "Investor Legal Full Name"
const LABEL_NAME2 = "Co-Investor Legal Full Name"
const LABEL_EMAIL2 = "Co-Investor Email"
const LABEL_ADDRESS = "Address"
const LABEL_INVESTMENTS = "Investments"


const renderProfileJoint = (profileJoint, investmentsValue, investor, additionalExtra) => {
  const {
    membershipInterestsTitle,
    ssnNumber,
    jointEmail,
    jointAddress,
    jointGivenName,
    jointFamilyName,
    jointAdditionalName,
    shouldCoInvestorSign
  } = profileJoint

  const legalFullNameInvestor2 = [
    jointGivenName,
    jointAdditionalName,
    jointFamilyName
  ].join(" ").trim()


  const items = [
    {
      label: LABEL_TITLE,
      value: JOINT_MEMBERSHIP_INTERESTS_TITLES_MAP[membershipInterestsTitle]
    }
  ]

  if (investmentsValue) {
    items.push({
      label: LABEL_INVESTMENTS,
      value: investmentsValue
    })
  }

  if (jointAddress) {
    items.push(
      ...renderResidenceState(profileJoint, jointAddress.addressCountry, jointAddress.addressRegion)
    )
  }

  items.push({
    label: LABEL_SSN,
    value: <Value value={ssnNumber} />
  })

  if (investor) {
    const {
      givenName,
      additionalName,
      familyName,
    } = investor

    const legalFullNameInvestor1 = [
      givenName,
      additionalName,
      familyName
    ].join(" ")

    items.push({
      label: LABEL_NAME1,
      value: legalFullNameInvestor1,
    })
  }

  items.push({
    label: LABEL_NAME2,
    value: legalFullNameInvestor2,
  })

  if (shouldCoInvestorSign) {
    items.push({
      label: LABEL_EMAIL2,
      value: jointEmail,
    })
  }

  items.push({
    label: LABEL_ADDRESS,
    value: <Value value={jointAddress} />,
  })

  additionalExtra = shouldCoInvestorSign
    ? additionalExtra
    : (
      <>
        <Text type="secondary" style={{ fontSize: 12 }}>
          Co-investor signature is not required.
        </Text>
        {additionalExtra}
      </>
    )

  breakRow(items)
  items.push(renderAdditional(profileJoint, additionalExtra))

  return items
}

export default renderProfileJoint
