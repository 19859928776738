import React from "react"
import { Layout, theme } from "antd"

import Text from "@components/Text"
import { SIDEBAR_WIDTH } from "@components"
import { useConsoleStore } from "@components/Store"
import { useLayoutTopOffset, ReleaseAlert } from "@components/Page"

import Sidebar from "./Sidebar"
import ConsoleLayoutOutlet from "./ConsoleLayoutOutlet"
import { SecondFactorAlert } from "../../backstage"

const LABEL_INITIALIZE = "Initializing..."


const ConsoleLayout = () => {
  const { token } = theme.useToken()
  const { colorBgContainer } = token

  const layoutTopOffset = useLayoutTopOffset()
  const { isReady, context: consoleContext } = useConsoleStore()

  if (!isReady) {
    return <Text text={LABEL_INITIALIZE} />
  }

  const layoutProps = {
    style: {
      marginLeft: SIDEBAR_WIDTH,
      background: colorBgContainer,
    }
  }

  const context = {
    layoutTopOffset,
    ...consoleContext
  }

  return (
    <>
      <Sidebar />

      <Layout {...layoutProps}>
        <ReleaseAlert />
        <SecondFactorAlert />

        <Layout.Content>
          <ConsoleLayoutOutlet context={context} />
        </Layout.Content>
      </Layout>
    </>
  )
}

export default ConsoleLayout
