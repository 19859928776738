import get from "lodash.get"
import { useState, useRef, useCallback } from "react"

import computeGetColumnSearchProps from "./computeGetColumnSearchProps"
import computeGetGlobalSearchProps from "./computeGetGlobalSearchProps"


const useSearch = (table, columns, isColumnSearchEnabled) => {
  const searchRef = useRef(null)

  const [ query, setQuery ] = useState("")
  const [ searchColumn, setSearchColumn ] = useState("")

  table.current.search = inputValue =>
    setQuery(`${inputValue}`.toLowerCase())

  const searchAction = (inputValue, confirm, dataIndex, closeDropdown = false) => {
    confirm({ closeDropdown })
    setQuery(`${inputValue}`.toLowerCase())
    setSearchColumn(dataIndex)
  }

  const globalSearchItems = useCallback(items => {
    const result = []

    for (const item of items) {
      const searchableColumnValues = columns
        .filter(({ isSearchable }) => isSearchable)
        .map(column => {
          const { key } = column
          const defaultCompute = i => get(i, key)
          const { compute = defaultCompute } = column

          const value = compute(item)

          return value
        })

      const isMatch =
        searchableColumnValues.some(v => `${v}`.toLowerCase().includes(query))

      if (isMatch) {
        result.push(item)
      }
    }

    return result
  }, [ query, columns ])

  if (isColumnSearchEnabled) {
    const getColumnSearchProps = computeGetColumnSearchProps(searchRef, query, searchColumn, searchAction, searchAction)
    return [ getColumnSearchProps, items => items ]
  }

  const hasSearchableColumns = columns
    .filter(({ isSearchable }) => isSearchable)
    .length > 0

  if (!hasSearchableColumns) {
    return [ {}, items => items ]
  }

  const getGlobalSearchProps = computeGetGlobalSearchProps(query, columns)

  return [ getGlobalSearchProps, globalSearchItems ]
}

export default useSearch
