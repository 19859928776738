import PropTypes from "prop-types"

import EntityShape from "./shapes/EntityShape"
import IndividualShape from "./shapes/IndividualShape"
import InvestorShape from "./shapes/InvestorShape"
import JointShape from "./shapes/JointShape"
import SdiraShape from "./shapes/SdiraShape"
import TierInvestmentShape from "./shapes/TierInvestmentShape"
import { InvestmentStatusEnumKeys } from "./shapes/InvestmentStatusEnum"
import { ProfileTypeEnumKeys } from "./shapes/ProfileTypeEnum"
import { RejectionReasonEnumKeys } from "./shapes/RejectionReasonEnum"

const properties = {
  brokerUserId: PropTypes.string.isRequired,
  completedAt: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  fundedAt: PropTypes.string,
  fundsSentAt: PropTypes.string,
  hasSentFunds: PropTypes.bool,
  id: PropTypes.string.isRequired,
  investments: PropTypes.arrayOf(TierInvestmentShape).isRequired,
  investor: InvestorShape.isRequired,
  investorAccountId: PropTypes.string.isRequired,
  investorErrors: PropTypes.arrayOf(PropTypes.string),
  isCountersigned: PropTypes.bool,
  isFunded: PropTypes.bool.isRequired,
  isFundingUnlocked: PropTypes.bool,
  isImmutable: PropTypes.bool,
  isInvestorComplete: PropTypes.bool.isRequired,
  isOverfunded: PropTypes.bool.isRequired,
  isPartiallyFunded: PropTypes.bool.isRequired,
  isPartiallySigned: PropTypes.bool.isRequired,
  isPartner: PropTypes.bool,
  isPostponed: PropTypes.bool,
  isProfileComplete: PropTypes.bool.isRequired,
  isProfileValid: PropTypes.bool,
  isSigned: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  note: PropTypes.string,
  noteUpdatedAt: PropTypes.string,
  noteUpdatedBy: PropTypes.string,
  ppmSignHash: PropTypes.string,
  profileEntity: EntityShape,
  profileErrors: PropTypes.arrayOf(PropTypes.string),
  profileIndividual: IndividualShape,
  profileJoint: JointShape,
  profileSdira: SdiraShape,
  profileType: PropTypes.oneOf(ProfileTypeEnumKeys).isRequired,
  projectClosedNotificationCreatedAt: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  receivedAmount: PropTypes.number.isRequired,
  receiverSourceId: PropTypes.string,
  receiverSourceName: PropTypes.string,
  rejectionReason: PropTypes.oneOf(RejectionReasonEnumKeys),
  senderSourceId: PropTypes.string,
  senderSourceName: PropTypes.string,
  signedAt: PropTypes.string,
  sponsorId: PropTypes.string.isRequired,
  status: PropTypes.oneOf(InvestmentStatusEnumKeys).isRequired,
  subscriptionAdminUrl: PropTypes.string,
  subscriptionId: PropTypes.string,
  subscriptionSignUrl: PropTypes.string,
  totalAmount: PropTypes.number,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  validationErrorsJson: PropTypes.string
}

const UpdateInvestmentSignedOutputShapeShape = PropTypes.exact(properties)

export default UpdateInvestmentSignedOutputShapeShape
export { properties }
