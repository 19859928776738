import React from "react"
import { Alert, Typography } from "antd"
import { Link } from "react-router-dom"

import { Markdown } from "@components/Text"
import { getConfig } from "@components/Config"
import { useAppContext } from "@components/AppContext"

const is2FaRequired = getConfig('is2FaRequired')

const ACCOUNT_PATH = '/account'

const LABEL_MESSAGE = `
  Two factor authentication should be enabled,
  [click here](ACCOUNT_PATH) to proceed.
`


const SecondFactorAlert = () => {
  const { identity, isImpersonated } = useAppContext()

  const { is2FaEnabled } = identity

  if (!is2FaRequired) {
    return null
  }

  if (is2FaEnabled) {
    return null
  }

  if (isImpersonated) {
    return null
  }

  const text = LABEL_MESSAGE
    .replace('ACCOUNT_PATH', ACCOUNT_PATH)

  const components = {
    a(props) {
      // eslint-disable-next-line react/prop-types
      const { href, children } = props

      return (
        <Typography.Text>
          <Link to={href}>
            {children}
          </Link>
        </Typography.Text>
      )
    }
  }

  return (
    <Alert
      banner
      message={
        <Markdown
          text={text}
          components={components}
        />
      }
    />
  )
}

export default SecondFactorAlert
