

const IndexNotificationsItemSchema = {
  isSent: { type: 'boolean' },
  sentAt: { format: 'date-time' },
  scheduledAt: { format: 'date-time' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexNotificationsItemSchema
