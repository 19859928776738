import PropTypes from "prop-types"


const properties = {
  email: PropTypes.string,
  id: PropTypes.string.isRequired,
  isReady: PropTypes.bool.isRequired,
  logoUrl: PropTypes.string,
  name: PropTypes.string.isRequired
}

const ReadPublicOrganizationOutputShapeShape = PropTypes.exact(properties)

export default ReadPublicOrganizationOutputShapeShape
export { properties }
