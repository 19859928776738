import { HOST } from "@components"

import parseJwt from "../authenticate/parseJwt"
import { jsonRequest } from "../request"

const AUTHORIZE_USER_URL = `${HOST}/api/backstage/Authorize`
const AUTHORIZE_ROOT_USER_URL = `${HOST}/api/backstage/AuthorizeAdministrator`


const authorizeUser = async (authentication, organizationId) => {
  const parameters = {}

  if (organizationId) {
    parameters.organizationId = organizationId
  }

  const headers = { authentication }
  const { isRoot } = parseJwt(authentication)

  const url = isRoot
    ? AUTHORIZE_ROOT_USER_URL
    : AUTHORIZE_USER_URL

  const { data } = await jsonRequest(url, parameters, headers)
  const { authorizationJwt, ...user } = data

  return [ authorizationJwt, user ]
}

export default authorizeUser
