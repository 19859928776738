import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Spin } from "antd"

import { Modal } from "@components/Page"
import Form, { useForm } from "@components/Form"
import { useAppContext } from "@components/AppContext"

import useInputs from "./helpers/useInputs"
import CustomerShape from "../../shapes/CustomerShape"
import { CUSTOMER_NAME_PERSONAL } from "../../CustomerCreateButton"

const LABEL_SUBMIT = "Continue"
const LABEL_SUCCESS = "Document successfully uploaded"
const LABEL_BUSINESS_TITLE = "Business Verification"
const LABEL_PERSONAL_TITLE = "Identity Verification"

const DEFAULT_EXPECTED_ERRORS = ["InvalidParametersError"]


const UploadDocumentsModal = ({
  onSuccess: onSuccessCustom,
  modal,
  customer,
  isModalOpen,
}) => {
  const form = useForm()

  const [ isOpen, setIsOpen ] = useState(isModalOpen)
  const [ isLoading, setIsLoading ] = useState(false)

  const { name } = customer
  const isPersonalCustomer = name === CUSTOMER_NAME_PERSONAL

  const [ schema, operation, updateSchema ] = useInputs(isPersonalCustomer, customer, form)

  const { authenticatedRequest, showSuccessMessage } = useAppContext()

  useEffect(() => {
    updateSchema()
  }, [ updateSchema ])

  const onClose = () =>
    setIsOpen(false)

  const onSuccess = async (newCustomer) => {
    setIsLoading(true)

    showSuccessMessage(LABEL_SUCCESS)
    await onSuccessCustom(newCustomer)

    setIsLoading(false)
  }

  modal.current.open = () => {
    updateSchema()
    setIsOpen(true)
  }

  modal.current.close = () =>
    setIsOpen(false)

  const onSubmitFailed = () =>
    form.resetFields()

  const formOptions = {
    item: customer,
    request: authenticatedRequest,
    submitLabel: LABEL_SUBMIT,
    hasSubmitButton: false,
    shouldOptimizeUpdateMutation: false,
    form,
    schema,
    operation,
    onSuccess,
    onSubmitFailed,
    expectedErrors: DEFAULT_EXPECTED_ERRORS
  }

  const title = isPersonalCustomer
    ? LABEL_PERSONAL_TITLE
    : LABEL_BUSINESS_TITLE

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Spin spinning={isLoading}>
        <Form
          {...formOptions}
        />
      </Spin>
    </Modal>
  )
}

UploadDocumentsModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  customer: CustomerShape.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
}

export default UploadDocumentsModal
