import React from "react"
import { Typography } from "antd"
import { PlusOutlined, CheckOutlined, WarningOutlined, LinkOutlined } from "@ant-design/icons"

const LABEL_RECOMMENDED = "(recommended)"
const LABEL_CONNECT_PLAID = "Securely link your bank account details"
const LABEL_CONNECT_MANUAL = "Add the bank details manually"


const getItems = (customerName, openModal, onSelectSource, sources, sourceId, isPlaidDisabled = false, isManualDisabled = false) => {
  const items = []

  const hasSources = sources.length > 0

  if (hasSources) {
    const children = sources
      .map(({ id: key, name, isInvalid }) => {
        const isSelected = key === sourceId
        const isDisabled = isSelected || isInvalid

        const label = isInvalid
          ? `${name} (Invalid)`
          : name

        let icon = isSelected ? (<CheckOutlined />) : null
        icon = isInvalid ? (<WarningOutlined />) : icon

        return (
          {
            key,
            icon,
            disabled: isDisabled,
            label: (
              <Typography.Link
                style={{ marginLeft: isDisabled ? 0 : 22 }}
                onClick={() => onSelectSource(key)}
              >
                {label}
              </Typography.Link>
            )
          }
        )
      })

    items.push(
      {
        key: "title",
        type: "group",
        label: customerName,
        children,
      },
      {
        type: "divider"
      }
    )
  }

  if (!isPlaidDisabled) {
    items.push({
      key: "connect-plaid",
      icon: <LinkOutlined />,
      label:
        <Typography.Link onClick={() => openModal(true)}>
          <Typography.Text strong={!isManualDisabled}>
            {LABEL_CONNECT_PLAID}
          </Typography.Text>

          {
            !isManualDisabled && (
              <Typography.Text type="secondary">
                {" "}{LABEL_RECOMMENDED}
              </Typography.Text>
            )
          }
        </Typography.Link>
    })
  }

  if (!isManualDisabled) {
    items.push({
      key: "connect-manual",
      icon: <PlusOutlined />,
      label: (
        <Typography.Link onClick={() => openModal()}>
          {LABEL_CONNECT_MANUAL}
        </Typography.Link>
      )
    })
  }

  return items
}

export default getItems
