import React from "react"
import { Typography, Col, Row, Statistic, Space, Collapse } from "antd"

import { ReadCampaignMetricsOutputShape } from "@api/services/campaigns"

import CampaignMetricsExtendedSummary from "./CampaignMetricsExtendedSummary"

const { Text } = Typography

const LABEL_OPENS = "Opens"
const LABEL_CLICKS = "Clicks"
const LABEL_DELIVERIES = "Deliveries"
const LABEL_UNSUBSCRIBES = "Unsubscribes"


const CampaignMetricsSummary = ({ metrics }) => {
  const { emailsCount } = metrics

  const successfulDeliveriesRate = emailsCount !== 0
    ? (metrics.deliveriesCount / emailsCount * 100).toFixed(0)
    : 0

  const opensRate = emailsCount !== 0
    ? (metrics.uniqueOpensCount / emailsCount * 100).toFixed(0)
    : 0

  const clicksRate = emailsCount !== 0
    ? (metrics.uniqueClicksCount / emailsCount * 100).toFixed(0)
    : 0

  const unsubscribesRate = emailsCount !== 0
    ? (metrics.unsubscribesCount / emailsCount * 100).toFixed(0)
    : 0

  // const complainsCountRate = emailsCount !== 0
  //   ? (metrics.complainsCount / emailsCount * 100).toFixed(0)
  //   : 0

  const metricItems = [
    {
      title: LABEL_DELIVERIES,
      value: metrics.deliveriesCount,
      formatter: value =>
        <Space>
          {value}
          <Text type="secondary">{successfulDeliveriesRate}%</Text>
        </Space>
    },
    {
      title: LABEL_OPENS,
      value: metrics.uniqueOpensCount,
      formatter: value =>
        <Space>
          {value}
          <Text type="secondary">{opensRate}%</Text>
        </Space>
    },
    {
      title: LABEL_CLICKS,
      value: metrics.uniqueClicksCount,
      formatter: value =>
        <Space>
          {value}
          <Text type="secondary">{clicksRate}%</Text>
        </Space>
    },
    // {
    //   title: 'Bounces',
    //   value: metrics.bouncesCount,
    //   formatter: value =>
    //     <Space>
    //       {value}
    //       <Text type="secondary">{bouncesRate}%</Text>
    //     </Space>
    // },
    {
      title: LABEL_UNSUBSCRIBES,
      value: metrics.unsubscribesCount,
      formatter: value =>
        <Space>
          {value}
          <Text type="secondary">{unsubscribesRate}%</Text>
        </Space>
    },
    // {
    //   title: 'Spam Reports',
    //   value: metrics.complainsCount,
    //   formatter: value =>
    //     <Space>
    //       {value}
    //       <Text type="secondary">{complainsCountRate}%</Text>
    //     </Space>
    // },

  ]

  const header = (
    <Row>
      {
        metricItems.map(({ ...props }, key) =>
          <Col key={key} span={6}>
            <Statistic {...props} />
          </Col>
        )
      }
    </Row>
  )

  const panels = [{
    key: "panel",
    label: header,
    forceRender: false,
    children: (
      <CampaignMetricsExtendedSummary metrics={metrics} />
    ),
  }]

  return (
    <Collapse
      size="small"
      items={panels}
      bordered={false}
    />
  )
}

CampaignMetricsSummary.propTypes = {
  metrics: ReadCampaignMetricsOutputShape.isRequired,
}

export default CampaignMetricsSummary
