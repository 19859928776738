import PropTypes from "prop-types"


const properties = {
  validationErrorsJson: PropTypes.string
}

const ImportInvestmentsOutputShapeShape = PropTypes.exact(properties)

export default ImportInvestmentsOutputShapeShape
export { properties }
