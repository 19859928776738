import React from "react"
import PropTypes from "prop-types"
import { Tag, Tooltip } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

import { LABEL_MICRO_DEPOSIT } from "@components/Dwolla"

const LABEL_PENDING = "Pending Verification"


const PendingStatus = ({ bordered = false }) => {
  return (
    <Tooltip
      title={LABEL_MICRO_DEPOSIT}
      placement="top"
    >
      <Tag
        icon={<ExclamationCircleOutlined />}
        color="blue"
        style={{ cursor: "pointer" }}
        bordered={bordered}
      >
        {LABEL_PENDING}
      </Tag>
    </Tooltip>
  )
}

PendingStatus.propTypes = {
  bordered: PropTypes.bool,
}

export default PendingStatus
