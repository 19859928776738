import React from "react"
import PropTypes from "prop-types"
import { Alert, Typography } from "antd"

import { Markdown } from "@components/Text"
import { ListProjectsOutputShape } from "@api/services/backstage"
import { IndexBankAccountsOutputShape } from "@api/services/transactions"

import useInvalidBankAccountAlert from "./helpers/useInvalidBankAccountAlert"


const InvalidBankAccountAlert = ({
  projects = [],
  bankAccounts = [],
}) => {
  const {
    text,
    onClick,
    shouldRenderAlert,
  } = useInvalidBankAccountAlert(bankAccounts, projects)

  if (!shouldRenderAlert) {
    return null
  }

  const components = {
    a(props) {
      // eslint-disable-next-line react/prop-types
      const { children } = props

      return (
        <Typography.Link onClick={onClick}>
          {children}
        </Typography.Link>
      )
    }
  }

  return (
    <div className="header-alerts">
      <Alert
        banner
        message={
          <Markdown
            text={text}
            components={components}
          />
        }
      />
    </div>
  )
}

InvalidBankAccountAlert.propTypes = {
  projects: PropTypes.arrayOf(ListProjectsOutputShape),
  bankAccounts: PropTypes.arrayOf(IndexBankAccountsOutputShape),
}

export default InvalidBankAccountAlert
