import React, { useRef } from "react"
import PropTypes from "prop-types"

import Table from "@components/Table"
import { InvestmentDrawer } from "@components/Investment"

import useColumnConfig from "./helpers/useColumnConfig"
import useTableSummary from "./helpers/useTableSummary"
import { indexOperation } from "../../stores/useInvestmentsStore"
import { InvestmentShape, TransactionShape } from "../../shapes"


const AccountInvestmentsTable = ({
  items,
  indexItems,
  transactions,
}) => {
  const investmentDrawer = useRef({})

  const openInvestmentDrawer = id =>
    investmentDrawer.current.open(id)

  const size = "small"
  const summary = useTableSummary(items, transactions)
  const bordered = false
  const showHeader = true
  const columnsConfig = useColumnConfig(items, transactions, openInvestmentDrawer)
  const shouldRenderHeader = false

  const tableProps = {
    size,
    items,
    summary,
    bordered,
    showHeader,
    indexItems,
    columnsConfig,
    indexOperation,
    shouldRenderHeader,
  }

  return (
    <>
      <Table {...tableProps} />

      <InvestmentDrawer
        drawer={investmentDrawer}
      />
    </>
  )
}

AccountInvestmentsTable.propTypes = {
  items: PropTypes.arrayOf(InvestmentShape).isRequired,
  indexItems: PropTypes.func.isRequired,
  transactions: PropTypes.arrayOf(TransactionShape).isRequired,
}

export default AccountInvestmentsTable
