import React from "react"

import { SelectInput } from "../../Input"
import { TYPE_ARRAY, TYPE_ARRAY_ENUM } from "../types"


const renderSelect = (itemProps, inputProps, options, extraOptions, operation, form) => {
  const { type } = itemProps

  const isMultiple =
    type === TYPE_ARRAY_ENUM ||
    type === TYPE_ARRAY

  return (
    <SelectInput
      form={form}
      options={options}
      operation={operation}
      itemProps={itemProps}
      isMultiple={isMultiple}
      inputProps={inputProps}
      extraOptions={extraOptions}
    />
  )
}

export default renderSelect
