import { useState, useRef } from "react"
import { usePapaParse } from "react-papaparse"

import authenticate from "@components/Authorization/authenticate/authenticate"
import authorizeUser from "@components/Authorization/authorize/authorizeUser"
import { useAppContext } from "@components/AppContext"
import { downloadContent } from "@components/Import"


const useExportProgress = ({ onReady, fileName, getRows }) => {
  const shouldStopRef = useRef(false)

  const [ progress, setProgress ] = useState(0)

  const { jsonToCSV } = usePapaParse()
  const { showErrorMessage } = useAppContext()

  const authorizeOrganization = async (organizationId) => {
    const isBackstage = true
    const shouldRefresh = true

    const authenticationJwt = await authenticate(showErrorMessage, isBackstage, shouldRefresh)
    const [ authorization ] = await authorizeUser(authenticationJwt, organizationId)

    return authorization
  }

  const updateProgress = (currentIndex, totalCount) =>
    setProgress(Math.ceil(currentIndex * 100 / totalCount))

  const shouldStop = () => shouldStopRef.current

  const start = async (...args) => {
    setProgress(0)
    shouldStopRef.current = false

    const rows = await getRows({ authorizeOrganization, shouldStop, updateProgress }, ...args)

    if (!shouldStopRef.current) {
      const content = jsonToCSV(rows)
      downloadContent(`${fileName}.csv`, content, 'text/csv', true)
      onReady()
    }
  }

  const stop = () =>
    shouldStopRef.current = true

  return [ progress, start, stop ]
}

export default useExportProgress
