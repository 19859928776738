import React, { useMemo } from "react"
import groupBy from "lodash.groupby"
import { Typography, Space } from "antd"
import { useOutletContext } from "react-router-dom"

import ProfileShape from "@api/services/investments/shapes/ProfileShape"
import { W0, isMobile } from "@components"

import ProfileInvestments from "./ProfileInvestments"


const Profile = ({ profile }) => {
  const { name, investmentIds = [] } = profile

  const { investmentsMap } = useOutletContext()

  const groups = useMemo(() => {
    const investmentsByReceiverSourceIdMap = groupBy(investmentIds,
      investmentId => investmentsMap[investmentId].receiverSourceId
    )

    return Object.values(investmentsByReceiverSourceIdMap)
  }, [ investmentIds, investmentsMap ])

  const getReceiverSourceId = ([ investmentId ]) =>
    investmentsMap[investmentId].receiverSourceId

  const getReceiverSourceName = ([ investmentId ]) =>
    investmentsMap[investmentId].receiverSourceName

  const getSignedInvestmentIds = ids =>
    ids.filter(id => investmentsMap[id].isPartiallySigned || investmentsMap[id].isSigned)

  const style = { margin: 0, marginBottom: !isMobile ? W0 : 0 }

  return (
    <Space direction="vertical">
      <Typography.Title
        level={5}
        style={style}
      >
        {name}
      </Typography.Title>

      {
        groups
          .filter(ids => getSignedInvestmentIds(ids).length > 0)
          .map((group, key) => (
            <ProfileInvestments
              key={key}
              profile={profile}
              investmentIds={getSignedInvestmentIds(group)}
              receiverSourceId={getReceiverSourceId(group)}
              receiverSourceName={getReceiverSourceName(group)}
            />
          ))
      }
    </Space>
  )
}

Profile.propTypes = {
  profile: ProfileShape.isRequired,
}

export default Profile
