import dayjs from "dayjs"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"

dayjs.extend(isSameOrBefore)

const LABEL_INVESTOR = "Investor"
const LABEL_INVALID_DOB_ERROR = "[TARGET] has to be at least 18 years old"


const validateDob = (target = LABEL_INVESTOR) => {
  const message = LABEL_INVALID_DOB_ERROR.replace("[TARGET]", target)

  return {
    message,
    validator: async (_, value) => {
      if (!value) {
        return true
      }

      const max18Years = dayjs().subtract(18, 'year')

      const isValid = dayjs(value).isSameOrBefore(max18Years)

      if (!isValid) {
        throw new Error(message)
      }
    }
  }
}

export default validateDob
