import React from "react"
import PropTypes from "prop-types"
import { Space, Tag } from "antd"
import { useOutletContext } from "react-router-dom"

import "./List.css"


const List = ({
  tagIds = [],
  untagItem,
 }) => {
  const { optionsMap = {} } = useOutletContext()

  const onClose = tagId =>
    untagItem(tagId)

  const existingTags = (tagIds || [])
    .filter(tagId => !!optionsMap[tagId])

  return (
    <Space size={0}>
      {
        existingTags.map(tagId =>
          <Tag
            key={tagId}
            color={optionsMap[tagId].color?.toLowerCase()}
            onClose={() => onClose(tagId)}
            bordered={false}
            closable
          >
            {optionsMap[tagId].name}
          </Tag>
        )
      }
    </Space>
  )
}

List.propTypes = {
  tagIds: PropTypes.arrayOf(PropTypes.string),
  untagItem: PropTypes.func.isRequired,
}

export default List
