const ERROR_PASSWORD_REQUIRED = "Password is required field"
const ERROR_PASSWORD_MIN_LENGTH = "Minimum length 8 symbols"
const ERROR_PASSWORD_SYMBOL_NUMBER = "Should contain at least 1 number"
const ERROR_PASSWORD_SYMBOL_SPECIAL = "Should contain at least 1 special character"
const ERROR_PASSWORD_SYMBOL_LOWERCASE = "Should contain at least 1 lowercase letter"
const ERROR_PASSWORD_SYMBOL_UPPERCASE = "Should contain at least 1 uppercase letter"

const REGEX_SYMBOL_NUMBER = /[\d]/
const REGEX_SYMBOL_SPECIAL = /[~^*!@#$%_&+\-()/{}\\"'.?[\]|,:;<>=`]/
const REGEX_SYMBOL_LOWERCASE = /[a-z]/
const REGEX_SYMBOL_UPPERCASE = /[A-Z]/


const validatePassword = formData => {
  const { password } = formData

  if (!password) {
    return {
      passwordRequired: ERROR_PASSWORD_REQUIRED,
    }
  }

  const errors = {}

  const isValidLength = password && password.length >= 8

  if (!isValidLength) {
    errors.passwordLength = ERROR_PASSWORD_MIN_LENGTH
  }

  const isExistLowercaseSymbol = REGEX_SYMBOL_LOWERCASE.test(password)

  if (!isExistLowercaseSymbol) {
    errors.passwordLowercaseSymbol = ERROR_PASSWORD_SYMBOL_LOWERCASE
  }

  const isExistUppercaseSymbol = REGEX_SYMBOL_UPPERCASE.test(password)

  if (!isExistUppercaseSymbol) {
    errors.passwordUppercaseSymbol = ERROR_PASSWORD_SYMBOL_UPPERCASE
  }

  const isExistNumberSymbol = REGEX_SYMBOL_NUMBER.test(password)

  if (!isExistNumberSymbol) {
    errors.passwordNumberSymbol = ERROR_PASSWORD_SYMBOL_NUMBER
  }

  const isExistSpecialSymbol = REGEX_SYMBOL_SPECIAL.test(password)

  if (!isExistSpecialSymbol) {
    errors.passwordSpecialSymbol = ERROR_PASSWORD_SYMBOL_SPECIAL
  }

  return errors
}

export default validatePassword
