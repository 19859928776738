import React from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

import { getResizedImageUrl } from "@components/Storage"

const { Title } = Typography


const Logo = ({
  src = undefined,
  height = 36,
  alt,
}) => {
  const marginTop = -1 * height / 2

  const imageContainerStyle = {
    position: "absolute",
    marginTop,
    height,
  }

  const resizeOptions = {
    trim: "color",
    "trim-color": "00FFFFFF"
  }

  return (
    <>
      {
        src && (
          <div style={imageContainerStyle}>
            <img
              alt={alt}
              src={getResizedImageUrl(src, null, height * 2, resizeOptions)}
              height={height}
            />
          </div>
        )
      }

      {
        !src && (
          <Title level={4} style={{ margin: 0 }}>{alt}</Title>
        )
      }
    </>
  )
}

Logo.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  height: PropTypes.number,
}

export default Logo
