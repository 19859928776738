import React from "react"
import { Tooltip, Space } from "antd"
import { QuestionCircleOutlined } from "@ant-design/icons"

const FUND_TYPE = "fund"


const getTableColumns = () => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      editable: false,
    },
    {
      title: "Goal",
      dataIndex: "goal",
      editable: true,
      width: "20%",
      input: {
        type: "amount",
        inputProps: {
          style: { width: "100%" },
          controls: false,
          min: 0,
        }
      }
    },
    {
      title: () => {
        return (
          <Space>
            <span>
              NAI Limit
            </span>

            <Tooltip
              placement="top"
              title="Non-Accredited Investor Limit"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        )
      },
      dataIndex: "limit",
      editable: true,
      width: "20%",
      isShow: ({type}) => type !== FUND_TYPE,
      input: {
        type: "number",
        inputProps: {
          style: { width: "100%" },
          controls: false,
          min: 0,
        }
      }
    }
  ]

  return columns
}

export default getTableColumns
