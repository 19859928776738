import PropTypes from "prop-types"


const properties = {
  batchId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  date: PropTypes.string.isRequired,
  documentType: PropTypes.string,
  id: PropTypes.string.isRequired,
  investmentId: PropTypes.string,
  investorAccountId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  sourceFileName: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  url: PropTypes.string.isRequired
}

const IndexMyFilesOutputShapeShape = PropTypes.exact(properties)

export default IndexMyFilesOutputShapeShape
export { properties }
