import React, { useRef } from "react"
import get from "lodash.get"
import { useOutletContext } from "react-router-dom"

import Page from "@components/Page"
import { W0 } from "@components"
import { CollapseTable } from "@components/Table"
import { UsersTable, UserCreateModal, UserUpdateModal } from "@modules/backstage/users"

const LABEL_TITLE = "Users"
const LABEL_ACTIVE = "Active"
const LABEL_DISABLED = "Inactive"


const UsersPage = () => {
  const createModal = useRef({})
  const updateModal = useRef({})
  const activeUsersTable = useRef({})
  const disabledUsersTable = useRef({})

  const {
    activeUsers,
    inactiveUsers,
  } = useOutletContext()

  const openCreateModal = () =>
    createModal.current.open()

  const openUpdateModal = id =>
    updateModal.current.open(id)

  const onSearch = value => {
    const tables = [
      activeUsersTable,
      disabledUsersTable,
    ]

    for (const usersTable of tables) {
      const search = get(usersTable, 'current.search')

      if (search) {
        search(value)
      }
    }
  }

  const style = {
    paddingLeft: W0 / 2,
    paddingRight: W0 / 2,
    paddingBottom: 0,
  }

  return (
    <Page
      style={style}
      title={LABEL_TITLE}
      onSearch={onSearch}
    >
      <CollapseTable
        items={activeUsers}
        title={LABEL_ACTIVE}
      >
        <UsersTable
          table={activeUsersTable}
          items={activeUsers}
          openCreateModal={openCreateModal}
          openUpdateModal={openUpdateModal}
        />
      </CollapseTable>

      <CollapseTable
        items={inactiveUsers}
        title={LABEL_DISABLED}
        isOpenByDefault={false}
      >
        <UsersTable
          table={disabledUsersTable}
          items={inactiveUsers}
          openCreateModal={openCreateModal}
          openUpdateModal={openUpdateModal}
        />
      </CollapseTable>

      <UserCreateModal modal={createModal} />
      <UserUpdateModal modal={updateModal} />
    </Page>
  )
}


export default UsersPage
