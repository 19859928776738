import PropTypes from "prop-types"


const properties = {
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string
}

const ActivityAttributeShape = PropTypes.exact(properties)

export default ActivityAttributeShape
export { properties }
