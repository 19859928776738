import React from "react"

import { RolesInput } from "../../Input"


const renderRole = (itemProps, inputProps, form, path) => {
  return (
    <RolesInput
      itemProps={itemProps}
      inputProps={inputProps}
      form={form}
      path={path}
    />
  )
}

export default renderRole
