import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Space, Descriptions } from "antd"

import Identity from "@components/Identity"
import { Value } from "@components/Text"
import InvestorShape from "@api/services/investments/shapes/InvestorShape"
import { ReadAccountOutputShape } from "@api/services/investments"
import { AccountUpdateDrawer, useInvestorAccreditation } from "@components/Investor"

import InvestmentErrors from "./InvestmentErrors"

const LABEL_DOB = "Date of Birth"
const LABEL_ADDRESS = "Mailing Address"
const LABEL_ACCREDITATION = "Accreditation"


const InvestmentInvestor = ({
  errors,
  onClick,
  account,
  investor,
  isFunded,
  onAfterAccountUpdate,
}) => {
  const accountUpdateDrawer = useRef({})

  const openAccountUpdateDrawer = () =>
    accountUpdateDrawer.current.open()

  const {
    address,
    dateOfBirth,
    hasAccreditation,
    accreditationExpiresAt
  } = investor

  const hasInvestorAccrediation = useInvestorAccreditation(hasAccreditation, accreditationExpiresAt)

  const items = [
    {
      key: "address",
      label: LABEL_ADDRESS,
      children: <Value value={address} />,
    },
    {
      key: "dob",
      label: LABEL_DOB,
      children: <Value value={dateOfBirth} />,
    },
    {
      key: "accreditation",
      label: LABEL_ACCREDITATION,
      children: hasInvestorAccrediation,
    },
  ]

  const { id } = account

  return (
    <Space
      size={0}
      direction="vertical"
      className="descriptions-children"
    >
      <div style={{ marginLeft: 3 }}>
        <Identity
          strong
          id={id}
          onClick={onClick}
          hasAvatar={false}
        />
      </div>

      <Descriptions
        size="small"
        items={items}
        layout="vertical"
      />

      <InvestmentErrors
        errors={errors}
        onResolve={openAccountUpdateDrawer}
        isFunded={isFunded}
      />

      <AccountUpdateDrawer
        drawer={accountUpdateDrawer}
        account={account}
        onSuccess={onAfterAccountUpdate}
      />
    </Space>
  )
}

InvestmentInvestor.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  account: ReadAccountOutputShape.isRequired,
  onClick: PropTypes.func.isRequired,
  investor: InvestorShape.isRequired,
  isFunded: PropTypes.bool.isRequired,
  onAfterAccountUpdate: PropTypes.func.isRequired,
}

export default InvestmentInvestor
