import React from "react"

import FormHeader from "./FormHeader"


const ConfirmSignInHeader = () => {
  return (
    <FormHeader
      heading="Sign In Confirmation"
      text="A confirmation code was sent to the mobile number linked to your account. Please enter the confirmation code below. It might take a few moments for the code to arrive."
    />
  )
}

export default ConfirmSignInHeader
