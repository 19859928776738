import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button } from "antd"

import { isMobile } from "@components"
import { BlockingModal } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import {
  updateMyInvestmentSignUrlOperation as updateSignUrlOperation,
  updateMyInvestmentSubscriptionOperation as createSubscriptionOperation,
} from "@api/services/investments"

const LABEL_SIGN = "Sign Documents"
const LABEL_WAIT = `
  Please leave this window open. We're currently generating subscription documents for you to sign.
`


const SignDocumentsButton = ({
  isDisabled = false,
  subscriptionId = undefined,
  investmentId,
}) => {
  const { request } = useAppContext()

  const [ isInProgress, setIsInProgress ] = useState(false)

  const onClick = async () => {
    setIsInProgress(true)

    let subscriptionSignUrl

    if (!subscriptionId) {
      const { data } = await request(createSubscriptionOperation, { id: investmentId })
      subscriptionSignUrl = data.subscriptionSignUrl

    } else {
      const { data } = await request(updateSignUrlOperation, { id: investmentId })
      subscriptionSignUrl = data.subscriptionSignUrl
    }

    window.location = subscriptionSignUrl
  }

  const style = {}

  if (isMobile) {
    style.width = "100%"
  }

  return (
    <>
      <Button
        type="primary"
        size="large"
        shape="round"
        style={style}
        onClick={onClick}
        disabled={isDisabled}
      >
        {LABEL_SIGN}
      </Button>

      <BlockingModal
        text={LABEL_WAIT}
        isOpen={isInProgress}
      />
    </>
  )
}

SignDocumentsButton.propTypes = {
  isDisabled: PropTypes.bool,
  investmentId: PropTypes.string.isRequired,
  subscriptionId: PropTypes.string,
}

export default SignDocumentsButton
