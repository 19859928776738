import React from "react"
import { Tag, Tooltip } from "antd"
import { HourglassOutlined } from "@ant-design/icons"

const LABEL_IN_REVIEW = "Documents in Review"
const LABEL_TOOLTIP = `Your documents have been submitted and are being
reviewed. This may take up to 2 business days. Please check back later for the
results.`


const DocumentsReviewStatus = () => {
  return (
    <Tooltip
      title={LABEL_TOOLTIP}
      placement="top"
    >
      <Tag
        icon={<HourglassOutlined />}
        color="yellow"
      >
        {LABEL_IN_REVIEW}
      </Tag>
    </Tooltip>
  )
}

export default DocumentsReviewStatus
