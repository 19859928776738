import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { VirtualTable } from "@components/Table"
import { indexPropertiesOperation as indexOperation } from "@api/services/backstage"

import getHeaderActions from "./helpers/getHeaderActions"
import useColumnsConfig from "./helpers/useColumnsConfig"
import useExportColumns from "./helpers/useExportColumns"
import usePropertyDeleteConfirm from "./helpers/usePropertyDeleteConfirm"


const PropertiesTable = ({
  table,
  onOpen,
  onUpdate,
  onCreate,
}) => {
  const {
    properties: items,
    indexProperties: indexItems,
  } = useOutletContext()

  const onDelete = usePropertyDeleteConfirm()
  const exportColumns = useExportColumns()
  const columnsConfig = useColumnsConfig(onOpen, onUpdate, onDelete)

  const size = "small"
  const sticky = true
  const className = "last-column-action"
  const headerActions = getHeaderActions(onCreate)
  const exportFileName = "properties"
  const defaultSortColumn = "name"

  const tableProps = {
    size,
    items,
    table,
    sticky,
    className,
    indexItems,
    exportColumns,
    columnsConfig,
    headerActions,
    indexOperation,
    exportFileName,
    defaultSortColumn,
  }

  return (
    <VirtualTable {...tableProps} />
  )
}

PropertiesTable.propTypes = {
  table: PropTypes.shape().isRequired,
  onOpen: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
}

export default PropertiesTable
