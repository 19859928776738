import React from "react"
import { Typography } from "antd"

import { Value } from "@components/Text"
import TableRowActions from "@components/Table/TableRowActions"

import getRowMenu from "./getRowMenu"
import PropertyStatusTag from "../../PropertyStatusTag"

const { Link: TextLink } = Typography

const LABEL_ENTITY_NAME = "Entity Name"


const getTableColumns = (isEditable, onClick, onUpdate, onRemove) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (name, { id }) =>
        <TextLink strong onClick={() => onClick(id)}>
          {name}
        </TextLink>
    },
    {
      title: "Entity",
      dataIndex: "tags",
      render: (name, { tags }) =>
        tags.find(({ label }) => label === LABEL_ENTITY_NAME)?.value
    },
    {
      title: "Equity Deployed",
      dataIndex: "equityDeployedAmount",
      width: 160,
      render: value => <Value value={value} />,
    },
    {
      title: "Investment Date",
      dataIndex: "investmentDate",
      width: 160,
      render: value => <Value value={value} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 160,
      render: status => status
        ? <PropertyStatusTag status={status} />
        : "N/A"
    },
  ]

  if (isEditable) {
    columns.push({
      key: "actions",
      title: false,
      width: 48,
      render: (_, item) => (
        <TableRowActions items={getRowMenu(item, onUpdate, onRemove)} />
      )
    })
  }

  return columns
}

export default getTableColumns
