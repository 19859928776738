import PropTypes from "prop-types"

import { properties as investmentProps } from "@api/services/investments/IndexAccountInvestmentsOutputShape"
import { properties as projectProperties } from "@api/services/backstage/ListProjectsOutputShape"


const InvestmentShape = PropTypes.exact({
  ...investmentProps,
  investmentProject: PropTypes.exact({
    ...projectProperties
  }),
})

export default InvestmentShape
