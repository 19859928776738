import PropTypes from "prop-types"

import InvestmentDocumentShape from "./shapes/InvestmentDocumentShape"

const properties = {
  investmentDocuments: PropTypes.arrayOf(InvestmentDocumentShape).isRequired
}

const MapInvestmentDocumentsOutputShapeShape = PropTypes.exact(properties)

export default MapInvestmentDocumentsOutputShapeShape
export { properties }
