import PropTypes from "prop-types"

import { investorStoreProperties } from "@components/Store/InvestorStore/useInvestorStore"


const InvestorLayoutContextShape = PropTypes.exact({
  getInvestorSsnNumber: PropTypes.func.isRequired,
  ...investorStoreProperties,
})

export default InvestorLayoutContextShape
