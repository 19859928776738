import React, { useState } from "react"
import { useOutletContext } from "react-router-dom"
import { Descriptions, Space, Button, App } from "antd"

import { W1, W2 } from "@components"
import { EditableGroup } from "@components/Button"
import { confirmAction } from "@components/Page"

import useItems from "./helpers/useItems"
import EmailIdentityRecords from "./EmailIdentityRecords"

const MAX_WIDTH = 540

const LABEL_TITLE = "Custom Domain"
const LABEL_DISCONNECT = "Disconnect"

const ACTION = {
  type: "danger",
  title: "Disconnect Custom Domain",
  action: "Disconnect",
  content: `By clicking "disconnect" below, the custom domain will be
disconnected from the organization, allowing a new one to be linked.`,
}


const OrganizationEmailIdentity = () => {
  const { modal } = App.useApp()

  const [ isLoading, setIsLoading ] = useState(false)

  const {
    organization,
    resetOrganizationEmailIdentity,
    verifyOrganizationEmailIdentity
  } = useOutletContext()

  const { emailIdentity = {} } = organization

  const items = useItems(emailIdentity)

  const hasEmailIdentity = Object.values(emailIdentity).length > 0

  if (!hasEmailIdentity) {
    return null
  }

  const disconnect = () => confirmAction(
    modal,
    ACTION.action,
    ACTION.title,
    ACTION.content,
    async () => {
      setIsLoading(true)
      return resetOrganizationEmailIdentity()
        .then(() => setIsLoading(false))
    },
    ACTION.type
  )

  const {
    domain,
    isVerified,
    dnsRecords = [],
  } = emailIdentity

  return (
    <Space
      style={{ width: "100%" }}
      size={W2}
      direction="vertical"
    >
      <div style={{ maxWidth: MAX_WIDTH }}>
        <EditableGroup
          title={LABEL_TITLE}
          style={{ width: "100%" }}
          onClick={() => verifyOrganizationEmailIdentity()}
          isEditable={true}
          buttonProps={{ type: "redo" }}
        >
          <Descriptions
            bordered
            size="small"
            column={1}
            labelStyle={{ width: 140 }}
          >
            {items}
          </Descriptions>
        </EditableGroup>
      </div>

      <Button
        block
        danger
        type="dashed"
        shape="round"
        style={{ maxWidth: MAX_WIDTH }}
        loading={isLoading}
        onClick={disconnect}
      >
        {LABEL_DISCONNECT}
      </Button>

      <div style={{ marginTop: -1 * W1, marginLeft: -1 * W1 }}>
        <EmailIdentityRecords
          domain={domain}
          dnsRecords={dnsRecords}
          isCollapsed={isVerified}
        />
      </div>
    </Space>
  )
}

export default OrganizationEmailIdentity
