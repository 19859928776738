
const NO = { value: "No", color: "red" }
const YES = { value: "Yes", color: "green" }
const UNVERIFIED = { value: "Unverified", color: "orange" }


const getAccreditation = (hasAccreditation, accreditationExpiresAt, projectCloseDate, isFunded = false, isNonFund = true) => {
  if (isNonFund) {
    return hasAccreditation ? YES : NO
  }

  if (!hasAccreditation) {
    return NO
  }

  if (!accreditationExpiresAt) {
    return UNVERIFIED
  }

  if (isFunded) {
    return YES
  }

  const todayTime = new Date().getTime()
  const accreditationExpiresTime = Date.parse(accreditationExpiresAt)

  const isExpired = todayTime > accreditationExpiresTime

  if (!isExpired) {
    return YES
  }

  const projectCloseTime = Date.parse(projectCloseDate)

  const hasExpiredAfterProjectClose = accreditationExpiresTime > projectCloseTime

  if (hasExpiredAfterProjectClose) {
    return YES
  }

  return UNVERIFIED
}

export default getAccreditation
