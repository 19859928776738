import React from "react"

import { W0 } from "@components"
import { Disclaimer } from "@components/Text"
import { TYPE_RADIO } from "@components/Form/FormItem/types"

const LABEL_TEST = "Test Scenario"
const LABEL_TEST_SCENARIO = "Test scenario option is available only for dev environment"

const TEST_SCENARIO_OPTIONS = [
  {
    value: "FAILED",
    label: "Verification failed",
  },
  {
    value: "SUCCESS",
    label: "Verification success",
  },
]


const getPersonalTestScenarioInput = () => {
  return {
    name: "testScenario",
    type: TYPE_RADIO,
    label: LABEL_TEST,
    options: TEST_SCENARIO_OPTIONS,
    required: false,
    itemProps: {
      extra: <Disclaimer text={LABEL_TEST_SCENARIO} style={{ marginTop: W0 }} />
    }
  }
}

export default getPersonalTestScenarioInput
