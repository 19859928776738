import React from "react"
import PropTypes from "prop-types"

import { W0, W1 } from "@components"


const FormNavigationFooter = ({ children }) => {
  return (
    <div style={{
      gap: W1,
      width: "100%",
      display: "flex",
      marginBottom: W0,
      justifyContent: "space-between",
    }}>
      {children}
    </div>
  )
}

FormNavigationFooter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export default FormNavigationFooter
