const getFormFields = (userName) => {
  const formFields = {
    signIn: {
      username: {
        order: 1,
        label: "Email",
        placeholder: "name@host.com",
        isRequired: true,
      },
      password: {
        order: 2,
        label: "Password",
        placeholder: "",
        isRequired: true,
      },
    },
    signUp: {
      email: {
        placeholder: "name@host.com",
        isRequired: true,
        label: "Email",
        order: 1,
      },
      password: {
        label: "Password",
        placeholder: "",
        isRequired: true,
        order: 2,
      },
      confirm_password: {
        label: "Confirm Password",
        placeholder: "",
        isRequired: true,
        order: 3,
      },
    },
    resetPassword: {
      username: {
        placeholder: "name@host.com",
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        label: "Code",
        placeholder: "",
        isRequired: false,
      },
      password: {
        placeholder: "",
      },
      confirm_password: {
        placeholder: "",
      },
    },
    // forceNewPassword: {
    //   password: {
    //     placeholder: "Password",
    //   },
    // },
    // setupTOTP: {
    //   QR: {
    //     totpIssuer: "test issuer",
    //     totpUsername: "amplify_qr_test_user",
    //   },
    //   confirmation_code: {
    //     label: "Code",
    //     isRequired: false,
    //   },
    // },
    confirmSignIn: {
      confirmation_code: {
        label: "Code",
        isRequired: true,
        type: "text"
      },
    },
  }

  if (userName) {
    formFields.signUp.email.defaultValue = userName
    formFields.signIn.username.defaultValue = userName
  }

  return formFields
}

export default getFormFields

