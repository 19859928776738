import React from "react"
import PropTypes from "prop-types"
import { Typography, Skeleton } from "antd"

import { W1, PATH_BACKSTAGE } from "@components"

import EditButton from "./EditButton"

const { Title } = Typography


const EditableGroup = ({
  level = 5,
  style = {},
  onClick = () => {},
  children = null,
  isEditable = true,
  buttonProps = {},
  marginBottom = W1,
  title,
}) => {
  const isBackstage = window.location.pathname.startsWith(PATH_BACKSTAGE)

  const shouldRenderNull = !children && !isBackstage

  if (shouldRenderNull) {
    return null
  }

  return (
    <div style={style}>
      <div style={{ marginBottom }}>
        { isEditable && <EditButton onClick={onClick} {...buttonProps} /> }

        <Title level={level} style={{ marginTop: 0 }}>
          {title}
        </Title>
      </div>

      {children}

      {!children && <Skeleton /> }
    </div>
  )
}

EditableGroup.propTypes = {
  title: PropTypes.string.isRequired,
  level: PropTypes.number,
  style: PropTypes.shape(),
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.shape(),
    PropTypes.bool,
  ]),
  isEditable: PropTypes.bool,
  buttonProps: PropTypes.shape(),
  marginBottom: PropTypes.number,
}

export default EditableGroup
