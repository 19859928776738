import React, { useEffect, useState, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { DatePicker, Form, Input, Space, Typography } from "antd"
import dayjs from "dayjs"
import utcPlugin from "dayjs/plugin/utc"
import timezonePlugin from "dayjs/plugin/timezone"
import advancedFormatPlugin from "dayjs/plugin/advancedFormat"
import customParseFormatPlugin from "dayjs/plugin/customParseFormat"

import { W0 } from "@components"
import { getIsoDateFromStringDate, US_DATE_FORMAT, getOffset } from "@components/Date"

import FormShape from "../../shapes/FormShape"
import SelectTimezone, { DEFAULT_TIMEZONE, getIsoDateTime, getTimezone } from "./SelectTimezone"

import "./DateTimeInput.css"

dayjs.extend(advancedFormatPlugin)
dayjs.extend(timezonePlugin)
dayjs.extend(utcPlugin)
dayjs.extend(customParseFormatPlugin)

const { Compact } = Space

const FORMAT_US_DATE_TIME = `${US_DATE_FORMAT} hh:mm a`

const { Text } = Typography


const DateTimeInput = ({
  form,
  path,
  itemProps,
  inputProps
}) => {
  const [ timezone, setTimezone ] = useState(DEFAULT_TIMEZONE)
  const {
    name,
    initialValue,
    hasTimeInput = true,
    label,
    ...restItemProps
  } = itemProps

  const { rules } = itemProps

  const {
    onChange: onChangeCustom,
    addonBefore,
    ...restInputProps
  } = inputProps

  const inputField = `_${name.slice(-1)}`
  const inputName = [...name.slice(0, -1), inputField]
  const inputPath = useMemo(() => [...path.slice(0, -1), inputField], [path, inputField])

  const sourceValue = Form.useWatch(path, form)
  const inputValue = Form.useWatch(inputName, form)

  const isRequired = rules.find(rule => rule.required)

  const syncValue = useCallback(() => {
    let value = form.getFieldValue(path)

    if (value && hasTimeInput) {
      const offset = getOffset(value)

      value = value.replace(offset, "")
    }

    form.setFieldValue(inputPath, value)
  }, [form, hasTimeInput, inputPath, path])

  useEffect(() => {
    syncValue()

    if (!hasTimeInput) {
      return
    }

    if (!sourceValue) {
      return
    }

    const newTimezone = getTimezone(sourceValue)

    setTimezone(newTimezone)
  }, [ sourceValue, hasTimeInput, syncValue ])

  useEffect(() => {
    syncValue()
  }, [ inputValue, syncValue ])

  const format = hasTimeInput
    ? FORMAT_US_DATE_TIME
    : US_DATE_FORMAT

  const onDatePickerChange = dateObject => {
    if (!dateObject) {
      return form.setFieldValue(path, undefined)
    }

    const { $d } = dateObject
    const isoValue = hasTimeInput
      ? getIsoDateTime($d, timezone)
      : getIsoDateFromStringDate($d, hasTimeInput)

    form.setFieldValue(path, isoValue)

    if (onChangeCustom) {
      onChangeCustom(isoValue, form)
    }
  }

  const onTimezoneChange = newTimezone => {
    setTimezone(newTimezone)
    const isoValue = getIsoDateTime(inputValue, newTimezone)
    form.setFieldValue(path, isoValue)
  }

  // NOTE: Do we need this?
  const getValueProps = value => {
    if (!value) {
      return { value }
    }

    return { value: dayjs(value) }
  }

  const onOk = value => {
    const isoValue = getIsoDateTime(value, timezone)
    form.setFieldValue(path, isoValue)
  }

  const datePickerProps = {
    format,
    onChange: onDatePickerChange,
    allowClear: true,
    ...restInputProps
  }

  if (hasTimeInput) {
    datePickerProps.showTime = {
      format: "hh:mm a",
      use12Hours: true,
      defaultValue: dayjs('12:00:00', 'HH:mm:ss')
    }

    datePickerProps.format = value =>
      dayjs(value).tz(timezone, true).format(format)

    datePickerProps.onOk = onOk
  }

  const labelClassName = isRequired
    ? "item-required"
    : ""

  const input = hasTimeInput
    ? (
        <div className="dateTime-input">
          <div style={{marginBottom: W0}}>
            <Text
              className={labelClassName}
              strong
            >
              {label}
            </Text>
          </div>
          <Compact>
            {
              addonBefore && (
                <div>
                  {addonBefore}
                </div>
              )
            }
            <Form.Item
              name={inputName}
              getValueProps={getValueProps}
              {...restItemProps}
            >
              <DatePicker {...datePickerProps} />
            </Form.Item>
            <div>
              <SelectTimezone
                size={inputProps.size}
                value={timezone}
                variant={inputProps.variant}
                onChange={onTimezoneChange}
                disabled={inputProps.disabled}
              />
            </div>
          </Compact>
        </div>
      )
    : (
        <Form.Item
          name={inputName}
          getValueProps={getValueProps}
          {...restItemProps}
        >
          <DatePicker {...datePickerProps} />
        </Form.Item>
      )

  return (
    <>
      <Form.Item
        key="hidden"
        name={name}
        hidden={true}
        initialValue={initialValue}
      >
        <Input />
      </Form.Item>

      {input}
    </>
  )
}

DateTimeInput.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemProps: PropTypes.shape().isRequired,
  inputProps: PropTypes.shape().isRequired,
}

export default DateTimeInput
