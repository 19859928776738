import React from "react"
import { Row, Col, Card, Space, Typography } from "antd"
import { Bullet } from "@ant-design/plots"

import { W0 } from "@components"
import { ReadCampaignMetricsOutputShape } from "@api/services/campaigns"

const LABEL_TOTAL = "Total"
const LABEL_BOUNCES = "Bounces"
const LABEL_SPAM_REPORTS = "Spam Reports"


const CampaignMetricsExtendedSummary = ({ metrics }) => {
  const lineChartBodyStyle = {
    paddingTop: W0,
    paddingBottom: W0
  }

  const {
    emailsCount,
    bouncesCount,
    complainsCount,
  } = metrics

  const bouncesRate = emailsCount !== 0
    ? Math.ceil(bouncesCount / emailsCount * 100)
    : 0

  const complainsRate = emailsCount !== 0
    ? Math.ceil(complainsCount / emailsCount * 100)
    : 0

  const config = {
    height: 50,
    color: {
      range: '#f0efff',
      target: '#3d76dd',
      measure: '#5b8ff9',
    },
    label: false,
    tooltip: false,
    rangeField: 'rangePercentage',
    targetField: 'targetPercentage',
    measureField: 'percentage',
    xAxis: { line: null, label: false, },
    yAxis: {
      line: null,
      min: 0,
      max: 100,
      label: false,
      grid: {
        line: { style: { opacity: 0 } },
      },
    },
  }

  const bouncesConfig = {
    ...config,
    data: [
      {
        title: LABEL_BOUNCES,
        amount: bouncesRate,
        percentage: bouncesRate,
        rangePercentage: 100,
      }
    ]
  }

  const complainsConfig = {
    ...config,
    data: [
      {
        title: LABEL_SPAM_REPORTS,
        amount: complainsRate,
        percentage: complainsRate,
        rangePercentage: 100,
      }
    ]
  }

  return (
    <Row wrap={24} gutter={[ W0, W0 ]}>
      <Col span={8}>
        <Card
          size="small"
          title={LABEL_BOUNCES}
          style={{ body: lineChartBodyStyle }}
        >
          <Bullet {...bouncesConfig} />

          <Space>
            <Typography.Text>{LABEL_TOTAL}</Typography.Text>
            <Typography.Text>{bouncesCount}</Typography.Text>
          </Space>
        </Card>
      </Col>

      <Col span={8}>
        <Card
          size="small"
          title={LABEL_SPAM_REPORTS}
          style={{ body: lineChartBodyStyle }}
        >
          <Bullet {...complainsConfig} />

          <Space>
            <Typography.Text>{LABEL_TOTAL}</Typography.Text>
            <Typography.Text>{complainsCount}</Typography.Text>
          </Space>
        </Card>
      </Col>
    </Row>
  )
}

CampaignMetricsExtendedSummary.propTypes = {
  metrics: ReadCampaignMetricsOutputShape.isRequired,
}

export default CampaignMetricsExtendedSummary
