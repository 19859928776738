import sortBy from "lodash.sortby"

import operationRequest from "@components/Authorization/request/operationRequest"
import { listProjectsOperation } from "@api/services/backstage"


const getOrganizationProjects = async (authorization) => {
  const parameters = {
    operation: listProjectsOperation,
    headers: { authorization },
  }

  const { data: projects } = await operationRequest(parameters)

  return sortBy(projects, "name")
}

export default getOrganizationProjects
