import React, { useRef, useCallback } from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

import { indexInvestorActivitiesOperation as indexOperation } from "@api/services/activities"

import ActivityDrawer from "../ActivityDrawer"
import ActivitiesTable from "../ActivitiesTable"

const LABEL_TITLE = "Activities"


const AccountActivities = ({ investorAccountId }) => {
  const table = useRef({})
  const drawer = useRef({})

  const openActivityDrawer = activityId => {
    const activity = table.current.getItem(activityId)
    drawer.current.open(activity)
  }

  const renderActivity = useCallback(
    activity => table.current.renderActivity(activity)
  , [ table ])

  return (
    <>
      <Typography.Title level={5} style={{ marginTop: 0 }}>
        {LABEL_TITLE}
      </Typography.Title>

      <ActivitiesTable
        table={table}
        indexOperation={indexOperation}
        investorAccountId={investorAccountId}
        openActivityDrawer={openActivityDrawer}
      />

      <ActivityDrawer
        drawer={drawer}
        renderActivity={renderActivity}
      />
    </>
  )
}

AccountActivities.propTypes = {
  investorAccountId: PropTypes.string.isRequired,
}

export default AccountActivities
