import React from "react"
import { App } from "antd"
import { useOutletContext } from "react-router-dom"

import { useAppContext } from "@components/AppContext"

import ProjectValidationErrors from "../ProjectValidationErrors"

const LABEL_TITLE = "Can't Publish Project"
const LABEL_VALIDATE = "Validating project..."

const SKIPABLE_ERRORS = [
  { code: "NO_BANK_ACCOUNT" },
  { code: "NO_PPM_TEMPLATE_ID" }
]


const useValidateProject = ({
  project,
  publish,
  readProject,
  publishProjectWithoutPpm,
  publishProjectWithWarnings,
  publishProjectWithoutBankAccount
}) => {
  const { modal } = App.useApp()
  const { showLoadingMessage } = useAppContext()
  const { fundsMap } = useOutletContext()

  const showErrors = validationErrors => {
    const width = 600
    const title = LABEL_TITLE

    const warningModalProps = {
      width,
      title,
      content: (
        <ProjectValidationErrors
          project={project}
          fundsMap={fundsMap}
          validationErrors={validationErrors}
        />
      ),
    }

    return modal.warning(warningModalProps)
  }

  const processValidationErrors = validationErrors => {
    if (!validationErrors) {
      return
    }

    const shouldSkipValidation = validationErrors
      .every(error => SKIPABLE_ERRORS.some(skipError => error.code === skipError.code))

    const hasNoBankAccountError = validationErrors
      .some(error => error.code === "NO_BANK_ACCOUNT")

    const hasNoPpmTemplateIdError = validationErrors
      .some(error => error.code === "NO_PPM_TEMPLATE_ID")

    const hasNoErrors = validationErrors.length === 0

    if (hasNoErrors) {
      return publish()
    }

    if (shouldSkipValidation) {
      if (hasNoBankAccountError && !hasNoPpmTemplateIdError) {
        return publishProjectWithoutBankAccount()
      }

      if (hasNoPpmTemplateIdError && !hasNoBankAccountError) {
        return publishProjectWithoutPpm()
      }

      return publishProjectWithWarnings()
    }

    showErrors(validationErrors)
  }

  const { id: projectId } = project

  const onPublishClick = () =>
    showLoadingMessage(LABEL_VALIDATE, () =>
      readProject(projectId)
        .then(({ validationErrors }) => processValidationErrors(validationErrors))
    )

  return onPublishClick
}

export default useValidateProject
