import React from "react"
import { Link } from "react-router-dom"

import { hasAccess } from "@components/Authorization"

const PATH_BACKSTAGE = "/backstage"

const LABEL_DETAILS = "Details"
const LABEL_DOCUMENTS = "Documents"
const LABEL_INVESTMENTS = "Investments"
const LABEL_DISTRIBUTIONS = "Distributions"


const getProjectLinks = id => {
  if (!id) {
    return []
  }

  const canReadDocuments = hasAccess(['documents-read'])

  return [
    {
      title: <Link to={`${PATH_BACKSTAGE}/project/${id}/details`}>{LABEL_DETAILS}</Link>,
      path: `${PATH_BACKSTAGE}/project/${id}/details`,
    },
    {
      title: <Link to={`${PATH_BACKSTAGE}/project/${id}/investments`}>{LABEL_INVESTMENTS}</Link>,
      path: `${PATH_BACKSTAGE}/project/${id}/investments`,
    },
    ...(canReadDocuments ? [
      {
        title: <Link to={`${PATH_BACKSTAGE}/project/${id}/documents`}>{LABEL_DOCUMENTS}</Link>,
        path: `${PATH_BACKSTAGE}/project/${id}/documents`,
      }
    ] : []),
    {
      title: <Link to={`${PATH_BACKSTAGE}/project/${id}/distributions`}>{LABEL_DISTRIBUTIONS}</Link>,
      path: `${PATH_BACKSTAGE}/project/${id}/distributions`,
    },
  ]
}

export default getProjectLinks
