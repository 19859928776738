import PropTypes from "prop-types"


const properties = {
  amount: PropTypes.number.isRequired,
  projectTierId: PropTypes.string.isRequired
}

const TierInvestmentShape = PropTypes.exact(properties)

export default TierInvestmentShape
export { properties }
