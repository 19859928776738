import React from "react"

import getProfileItems from "./getProfileItems"
import InvestmentInvestor from "../InvestmentInvestor"

const LABEL_INVESTOR = "Investor"


const getItems = ({
  account,
  investment,
  openAccountDrawer,
  onAfterAccountUpdate,
  openInvestmentProfileUpdateDrawer,
}) => {
  const {
    investor,
    isFunded,
    investorErrors = [],
  } = investment

  const profileItems = getProfileItems(investment, openInvestmentProfileUpdateDrawer)

  const items = [
    {
      label: LABEL_INVESTOR,
      value: (
        <InvestmentInvestor
          errors={investorErrors}
          account={account}
          onClick={openAccountDrawer}
          investor={investor}
          isFunded={isFunded}
          onAfterAccountUpdate={onAfterAccountUpdate}
        />
      ),
    },
    ...profileItems,
  ]

  return items
}

export default getItems
