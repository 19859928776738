import React from "react"

import { W0 } from "@components"
import {
  INVESTMENT_PROFILE_TYPE_JOINT,
  INVESTMENT_PROFILE_TYPE_SDIRA,
  INVESTMENT_PROFILE_TYPE_ENTITY,
  INVESTMENT_PROFILE_TYPE_INDIVIDUAL,
} from "@components/Domain"

import InvestmentErrors from "../InvestmentErrors"
import renderProfileSdira from "../../../../helpers/renderProfileSdira"
import renderProfileJoint from "../../../../helpers/renderProfileJoint"
import renderProfileEntity from "../../../../helpers/renderProfileEntity"
import InvestmentInvestments from "../InvestmentInvestments"
import renderProfileIndividual from "../../../../helpers/renderProfileIndividual"

const LABEL_INVESTMENTS = "Committed Amount"
const LABEL_PROFILE_TYPE = "Profile Type"


const getProfileItems = (investment, onResolve) => {
  const {
    investor,
    projectId,
    investments,
    profileType,
    profileJoint,
    profileSdira,
    profileEntity,
    profileErrors = [],
    profileIndividual,
    isFunded
  } = investment

  const investmentsItem = {
    label: LABEL_INVESTMENTS,
    value: (
      <InvestmentInvestments
        projectId={projectId}
        investments={investments}
      />
    ),
  }

  const hasProfileErrors = profileErrors.length > 0

  const additionalExtra = (
    <div style={{ marginTop: hasProfileErrors ? W0 : 0 }}>
      <InvestmentErrors
        errors={profileErrors}
        isFunded={isFunded}
        onResolve={onResolve}
      />
    </div>
  )

  const isIndividual =
    profileType === INVESTMENT_PROFILE_TYPE_INDIVIDUAL && !!profileIndividual

  if (isIndividual) {
    const items = renderProfileIndividual(profileIndividual, undefined, investor, additionalExtra)
    return [ investmentsItem, ...items ]
  }

  const isJoint =
    profileType === INVESTMENT_PROFILE_TYPE_JOINT && !!profileJoint

  if (isJoint) {
    const items = renderProfileJoint(profileJoint, undefined, undefined, additionalExtra)
    return [ investmentsItem, ...items ]
  }

  const isSdira =
    profileType === INVESTMENT_PROFILE_TYPE_SDIRA && !!profileSdira

  if (isSdira) {
    const items = renderProfileSdira(profileSdira, undefined, undefined, additionalExtra)
    return [ investmentsItem, ...items ]
  }

  const isEntity =
    profileType === INVESTMENT_PROFILE_TYPE_ENTITY && !!profileEntity

  if (isEntity) {
    const items = renderProfileEntity(profileEntity, undefined, undefined, additionalExtra)
    return [ investmentsItem, ...items ]
  }

  return [
    investmentsItem,
    {
      label: LABEL_PROFILE_TYPE,
      value: profileType
    },
  ]
}

export default getProfileItems
