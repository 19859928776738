import Identity from "./Identity"
import IdentityCard from "./IdentityCard"

import UpdateIdentityEmailLink from "./UpdateIdentityEmailLink"
import VerificationRequestModal from "./TwoFactorAuthenticationSwitch/VerificationRequestModal"
import TwoFactorAuthenticationSwitch from "./TwoFactorAuthenticationSwitch"

export default Identity

export {
  IdentityCard,
  UpdateIdentityEmailLink,
  VerificationRequestModal,
  TwoFactorAuthenticationSwitch
}
