import { useCallback } from "react"
import get from "lodash.get"

import { ASCEND } from "./getSortingProps"


const sortItems = (columnsConfig, sortColumn, sortDirection, items) => {
  if (!sortColumn) {
    return items
  }

  const sortColumnConfig = columnsConfig.find(({ key }) => key === sortColumn)

    if (!sortColumnConfig) {
    return items
  }

  items = items.map(item => {
    const { key } = sortColumnConfig
    let { path, compute } = sortColumnConfig

    path = path ? path : key
    compute = compute ? compute : value => get(value, path) || ""

    let computedValue = compute(item)
    const isArray = Array.isArray(computedValue)

    if (isArray) {
      const [ firstValue ] = computedValue
      computedValue = firstValue
    }

    return {
      ...item,
      sortedField: computedValue
    }
  })

  let sortedItems = items.sort((a, b) => {
    if (sortDirection === ASCEND) {
      return a.sortedField < b.sortedField ? -1 : 1
    }

    return a.sortedField > b.sortedField ? -1 : 1
  })

  sortedItems = sortedItems.map(item => {
    delete item.sortedField

    return { ...item }
  })

  return sortedItems
}

const useSortItems = (columnsConfig, sortColumn, sortDirection) => {
  return useCallback(
    items => sortItems(columnsConfig, sortColumn, sortDirection, items)
  , [ columnsConfig, sortColumn, sortDirection ])
}

export default useSortItems
