import React from "react"
import { PlusOutlined, ImportOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"

const LABEL_CREATE = "Create"
const LABEL_IMPORT = "Import"


const getHeaderActions = (selectableActions, openCreateModal, openImportModal) => {
  const canCreateInvestors = hasAccess(['accounts-write'])

  if (!canCreateInvestors) {
    return []
  }

  if (!openCreateModal) {
    return []
  }

  const items = [
    ...selectableActions,
    {
      icon: <PlusOutlined/>,
      title: LABEL_CREATE,
      action: openCreateModal,
    },
    {
      icon: <ImportOutlined />,
      action: openImportModal,
      title: LABEL_IMPORT,
    },
  ]

  return items
}

export default getHeaderActions
