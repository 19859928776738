import React, { useState, useEffect, useCallback } from "react"
import { useParams, useOutletContext, useNavigate } from "react-router-dom"

import { isMobile } from "@components"
import { runRequest, useAppContext } from "@components/AppContext"
import { readPublicPublishedProjectOperation as readOperation } from "@api/services/backstage"
import { DOCUMENT_NOT_FOUND_ERROR } from "@api"

import Header from "./Header"
import InvestorProjectLayoutOutlet from "./InvestorProjectLayoutOutlet"

const ERROR_MESSAGE = "Requested resource not found"

const InvestorProjectLayout = () => {
  const { projectId: id } = useParams()

  const navigate = useNavigate()

  const [ project, setProject ] = useState()

  const getProperty = useCallback(propertyId =>
    project.investmentSubjects.find(item => item.id === propertyId)
  , [ project ])

  const { request, showErrorMessage } = useAppContext()
  const outletContext = useOutletContext()

  useEffect(() => {
    if (project) {
      return
    }

    return runRequest(() => {
      request(readOperation, { id }, [ DOCUMENT_NOT_FOUND_ERROR ])
        .then(({ data }) => setProject(data))
        .catch(() => {
          showErrorMessage(ERROR_MESSAGE)
          navigate("/dashboard")
        })
    })
  }, [ id, project, request, navigate, showErrorMessage ])

  if (!project) {
    return null
  }

  const containerStyle = isMobile
    ? {
        overflow: "hidden",
      }
    : {}

  const context = {
    ...outletContext,
    project,
    getProperty
  }

  return (
    <div style={containerStyle}>
      <Header project={project} />

      <InvestorProjectLayoutOutlet
        context={context}
      />
    </div>
  )
}

export default InvestorProjectLayout
