import { CUSTOMER_STATUS_SUSPENDED } from "@components/Dwolla"


const isCustomerSuspended = customer => {
  if (!customer) {
    return false
  }

  const { verificationStatus } = customer

  const isSuspended = verificationStatus === CUSTOMER_STATUS_SUSPENDED

  return isSuspended
}

export default isCustomerSuspended
