import { useOutletContext } from "react-router-dom"

import { useAppContext } from "@components/AppContext"
import { getFormattedAmountValue } from "@components/Amount"


const useCalculatedTargets = () => {
  const { project } = useOutletContext()
  const { identity, isBroker } = useAppContext()

  let { targetAmount = 0, nonAccreditedInvestorsMax = 0 } = project

  const shouldOverrideSponsorTargets =
    !!project &&
    isBroker

  if (shouldOverrideSponsorTargets) {
    const { id } = project
    const { links = [] } = identity

    const link = links.find(({ projectId }) => projectId === id)

    if (link) {
      const { brokerTargetAmount } = link
      const { brokerNonAccreditedInvestorsMax } = link

      targetAmount = brokerTargetAmount
      nonAccreditedInvestorsMax = brokerNonAccreditedInvestorsMax
    }
  }

  const formattedTargetAmount = getFormattedAmountValue(targetAmount)

  return {
    targetAmount: formattedTargetAmount,
    nonAccreditedInvestorsMax
  }
}

export default useCalculatedTargets
