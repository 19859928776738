import React, { useRef } from "react"
import PropTypes from "prop-types"

import { Title } from "@components/Text"
import { IndexInvestorTransactionsOutputShape } from "@api/services/transactions"

import TransactionDrawer from "../TransactionDrawer"
import AccountTransactionsTable from "./AccountTransactionsTable"

const LABEL_TITLE = "Transactions"


const AccountTransactions = ({
  transactions,
  indexTransactions
}) => {
  const transactionDrawer = useRef({})

  const openTransactionDrawer = transaction =>
    transactionDrawer.current.open(transaction)

  return (
    <>
      <Title>
        {LABEL_TITLE}
      </Title>

      <AccountTransactionsTable
        items={transactions}
        indexItems={indexTransactions}
        openTransactionDrawer={openTransactionDrawer}
      />

      <TransactionDrawer
        drawer={transactionDrawer}
        indexTransactions={indexTransactions}
      />
    </>
  )
}

AccountTransactions.propTypes = {
  transactions: PropTypes.arrayOf(IndexInvestorTransactionsOutputShape).isRequired,
  indexTransactions: PropTypes.func.isRequired,
}

export default AccountTransactions
