import React from "react"
import { Descriptions, Space } from "antd"
import { green, blue } from "@ant-design/colors"
import { CheckCircleOutlined, HourglassOutlined } from "@ant-design/icons"

import { W0 } from "@components"

const { Item } = Descriptions

const LABEL_DOMAIN = "FROM Domain"
const LABEL_STATUS = "Status"
const LABEL_STATUS_VERIFIED = "Connected"
const LABEL_STATUS_UNVERIFIED = "Pending"


const useItems = emailIdentity => {
  const {
    domain,
    isVerified = false
  } = emailIdentity

  const statusLabel = isVerified
    ? LABEL_STATUS_VERIFIED
    : LABEL_STATUS_UNVERIFIED

  const statusIcon = isVerified
    ? <CheckCircleOutlined style={{ color: green.primary }} />
    : <HourglassOutlined style={{ color: blue.primary }} />

  const items = [
    {
      label: LABEL_DOMAIN,
      value: domain
    },
    {
      label: LABEL_STATUS,
      value: <Space size={W0 / 2}>{statusLabel} {statusIcon}</Space>
    },
  ]

  return items
    .map(({ label, value }, key) =>
      <Item
        key={key}
        label={label}
      >
        {value}
      </Item>
    )
}

export default useItems
