import React, {useRef, useState, useMemo, useEffect} from "react"
import { useOutletContext } from "react-router-dom"
import PropTypes from "prop-types"

import { Drawer } from "@components/Page"
import { EditButton } from "@components/Button"
import { EmailPreview } from "@components/Text"
import { useAppContext } from "@components/AppContext"
import { EMAIL_DRAWER_WIDTH } from "@components"

import renderContent from "./helpers/renderContent"
import TemplateUpdateDrawer from "./TemplateUpdateDrawer"


const TemplateDrawer = ({
  projectId = undefined,
  drawer,
  resetTemplate,
  getTemplateByKey,
  updatedTemplateByKey
}) => {
  const templateUpdateDrawer = useRef({})

  const [ templateKey, setTemplateKey ] = useState()

  const { identity } = useAppContext()
  const { organization } = identity

  useEffect(() => {
    setTemplateKey()
  }, [ projectId ])

  const { emailFooterHtml: footerHtml } = organization

  const template = templateKey
    ? getTemplateByKey(templateKey) || {}
    : {}

  const onOpen = item =>
    setTemplateKey(item.key)

  const { project, accountsMap } = useOutletContext()

  const openTemplateUpdateDrawer = () =>
    templateUpdateDrawer.current.open(template)

  const {
    name: title = "",
    html: bodyHtml,
    subject = "",
    variables = [],
    isEditable = true,
  } = template

  const tagsMap = useMemo(() =>
    variables
      .sort((a, b) => a.localeCompare(b))
      .reduce((acc, pair) => {
        const [ variable, tag ] = pair.split(":")
        return { ...acc, [variable]: tag }
      }, {})
  , [ variables ])

  const account = Object.values(accountsMap)[0]
  const context = { project, organization, account, tagsMap }

  const extra = isEditable
    ? (
        <EditButton
          onClick={openTemplateUpdateDrawer}
        />
      )
    : null

  const drawerProps = {
    width: EMAIL_DRAWER_WIDTH,
    styles: { body: { padding: 0 } },
    title,
    extra,
    drawer,
    onOpen
  }

  let html = bodyHtml

  if (footerHtml) {
    html += `\n${footerHtml}`
  }

  const renderedHtml = renderContent(html, context, false, variables)
  const renderedSubject = renderContent(subject, context, true, variables)

  const emailHtml = `
    <h3 style="margin-bottom: 16px">
      ${renderedSubject}
    </h3>
    ${renderedHtml}
  `

  return (
    <Drawer {...drawerProps}>
      <EmailPreview html={emailHtml} />

      <TemplateUpdateDrawer
        drawer={templateUpdateDrawer}
        projectId={projectId}
        resetTemplate={resetTemplate}
        updatedTemplateByKey={updatedTemplateByKey}
      />
    </Drawer>
  )
}

TemplateDrawer.propTypes = {
  drawer: PropTypes.shape().isRequired,
  projectId: PropTypes.string,
  resetTemplate: PropTypes.func.isRequired,
  getTemplateByKey: PropTypes.func.isRequired,
  updatedTemplateByKey: PropTypes.func.isRequired,
}

export default TemplateDrawer
