import keyBy from "lodash.keyby"

import operationRequest from "@components/Authorization/request/operationRequest"
import { indexUsersOperation } from "@api/services/backstage"


const getOrganizationUsers = async (authorization) => {
  const parameters = {
    operation: indexUsersOperation,
    headers: { authorization },
  }

  const { data: users } = await operationRequest(parameters)
  const usersMap = keyBy(users, "id")

  const computeUserFullname = id => {
    const user = usersMap[id]

    if (!user) {
      return id
    }

    const { givenName, familyName } = user

    return `${givenName} ${familyName}`
  }

  return { computeUserFullname }
}

export default getOrganizationUsers
