import get from "lodash.get"
import uniq from "lodash.uniq"


const getTargetAttributeOptions = (targets, attributePath) => {
  const values = uniq(
    targets
      .map(item => get(item, attributePath))
      .filter(value => !!value)
      .sort()
  )

  const options = values.map(value => ({ value }))

  return options
}

export default getTargetAttributeOptions
