import React from "react"
import { ExclamationCircleFilled } from '@ant-design/icons'

import { fundInvestmentOperation } from "@api/services/transactions"
import { updateMyInvestmentFundingStatusOperation } from "@api/services/investments"
import { getFormattedDecimalValue, getCurrencyValue } from "@components/Amount"

import getTransactionDetails from "./getTransactionDetails"

const LABEL_OK = "Send $amount"
const LABEL_TITLE = "Fund Investment"
const LABEL_DESCRIPTION = `
  $amount to be send from your account "$sourceName" to fund "$projectName" investment as "$investmentName".
`


const confirmSendFunds = (modal, request, investment, projectName, sourceName, afterSend) => {
  const details = getTransactionDetails(investment)

  const {
    id: investmentId,
    name: investmentName,
    totalAmount,
    receivedAmount,
    isPartiallyFunded,
  } = investment

  const amount = isPartiallyFunded ? getFormattedDecimalValue(totalAmount, receivedAmount, "minus") : totalAmount

  const description = LABEL_DESCRIPTION
    .replace('$amount', getCurrencyValue(amount))
    .replace('$sourceName', sourceName)
    .replace('$projectName', projectName)
    .replace('$investmentName', investmentName)

  const updateStatusParameters = {
    id: investmentId,
    mutation: { hasSentFunds: true }
  }

  const onOk = () =>
    request(fundInvestmentOperation, { mutation: { investmentId, details } })
      .then(() => request(updateMyInvestmentFundingStatusOperation, updateStatusParameters))
      .then(({ data }) => afterSend(data))

  const labelButton = LABEL_OK.replace('$amount', getCurrencyValue(amount))

  const instance = modal.confirm({
    icon: <ExclamationCircleFilled />,
    title: LABEL_TITLE,
    okText: labelButton,
    content: description,
    onOk: async () => {
      instance.update({
        okButtonProps: {
          loading: true,
          disabled: true
        }
      })

      await onOk()

      instance.update({
        okButtonProps: {
          loading: false,
          disabled: false
        }
      })
    },
    okButtonProps: {
      loading: false,
      disabled: false
    }
  })
}

export default confirmSendFunds
