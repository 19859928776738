import React from "react"
import { Typography, Space, Tooltip } from "antd"
import { WarningOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"
import { Value } from "@components/Text"
import { getCurrencyValue } from "@components/Amount"
import {
  TRANSACTION_TYPES,
  TRANSACTION_STATUS,
  TRANSACTION_PROBLEM_STATUSES
} from "@components/Domain"

import { parseStatusReason } from "../../TransactionDrawer"

const LABEL_INVESTMENTS = "Investments"
const LABEL_DISTRIBUTIONS = "Distributions"


const getAmountColumnProps = (organizationName, openTransactionDrawer, isInvalidBankAccountTransaction, isIncomingColumn = true) => {
  const canUpdateTransaction = hasAccess(['transactions-write'])

  const key = "amount"
  const align = "right"
  const isSearchable = true

  const title = isIncomingColumn
    ? LABEL_INVESTMENTS
    : LABEL_DISTRIBUTIONS

  const render = (amount, transaction) => {
    const {
      type,
      status,
      statusReason,
      retryTransactionId
    } = transaction

    if (!amount) {
      return ""
    }

    if (!canUpdateTransaction) {
      return (
        <Value>
          {amount}
        </Value>
      )
    }

    const isRetried = !!retryTransactionId

    const hasProblem = TRANSACTION_PROBLEM_STATUSES.includes(status)

    const shouldShowStatusReason = hasProblem && !isRetried

    const onClick = () => openTransactionDrawer(transaction)

    const isRejected = status === TRANSACTION_STATUS.REJECTED

    const isCrossedOut = isRejected || isRetried

    const isFundingTransaction = type === TRANSACTION_TYPES.FUNDING

    const isInvalidBankAccount = isInvalidBankAccountTransaction(transaction)

    const tooltipText = parseStatusReason({
      statusReason,
      isTooltip: true,
      organizationName,
      isInvalidBankAccount,
      isFundingTransaction,
    })

    return (
      <Space>
        {
          shouldShowStatusReason && (
            <Tooltip
              placement="top"
              title={tooltipText}
            >
              <WarningOutlined style={{ fontSize: "16px", color: "red" }} />
            </Tooltip>
          )
        }

        <Typography.Text delete={isCrossedOut} strong>
          <Value onClick={onClick}>
            {amount}
          </Value>
        </Typography.Text>
      </Space>
    )
  }

  const compute = transaction => {
    const { amount, type } = transaction

    const isFunding = type === TRANSACTION_TYPES.FUNDING
    const isIncomingAmount = !isFunding && isIncomingColumn
    const isOutgoingAmount = isFunding && !isIncomingColumn

    if (isIncomingAmount || isOutgoingAmount) {
      return ""
    }

    return getCurrencyValue(amount).replace(/\.00$/, '')
  }

  return {
    key,
    align,
    title,
    render,
    compute,
    isSearchable,
  }
}

export default getAmountColumnProps
