import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Button, Typography, Space, Badge } from "antd"
import { Link } from "react-router-dom"
import {
  TeamOutlined,
  PlusOutlined,
  FolderOpenOutlined,
  DollarOutlined,
  HistoryOutlined,
  NotificationOutlined
} from "@ant-design/icons"

import { W0 } from "@components"
import { hasAccess } from "@components/Authorization"
import { SearchInput } from "@components/Page"

import getActiveProjects from "./getActiveProjects"
import renderProjectLabel from "./renderProjectLabel"
import getProjectPagePath from "./getProjectPagePath"

const { Text } = Typography

const LABEL_LOADING = "Loading..."
const LABEL_ACTIVITY = "Activity"
const LABEL_ACCOUNTS = "Investors"
const LABEL_SUBJECTS = "Properties"
const LABEL_PROJECTS = "Projects"
const LABEL_CAMPAIGNS = "Campaigns"
const LABEL_TRANSACTIONS = "Transactions"


const useMainMenu = (projects, openProjectCreateModal, failedTransactionsCount, hasCampaigns) => {
  const initialActiveProjects = useMemo(() => getActiveProjects(projects), [projects])

  const [ activeProjects, setActiveProjects ] = useState(initialActiveProjects)
  const [ searchText, setSearchText ] = useState("")

  const isLoadingProjects = projects === null

  const filterProjects = useCallback(() => {
    const filteredProjects = projects.filter(({ name, shortName }) => {
      const text = shortName ? shortName : name

      return text.toLowerCase().includes(searchText.toLowerCase())
    })

    const newActiveProjects = getActiveProjects(filteredProjects)

    setActiveProjects(newActiveProjects)
  }, [projects, searchText])

  const onSearch =  (query) => setSearchText(query)

  useEffect(() => {
    filterProjects()
  }, [projects, filterProjects])

  const projectsItems = [
    ...activeProjects.map(({ id, name, shortName, state, type, status }) =>
      ({
        key: `/backstage/project/${id}/${getProjectPagePath(status)}`,
        label: (
          <Link to={`/backstage/project/${id}/${getProjectPagePath(status)}`}>
            {renderProjectLabel(name, shortName, state, type, searchText)}
          </Link>
        ),
      })
    )
  ]

  const countActiveProjects = initialActiveProjects.length
  const shouldRenderSearch = countActiveProjects > 10

  const canCreateProject = hasAccess(['projects-write'])
  const canShowActivities = hasAccess(['activities-read'])
  const canReadProperties = hasAccess(['properties-read'])

  const hasFailedTransactions = failedTransactionsCount > 0

  const items = [
    {
      key: "/backstage/investors",
      icon: <TeamOutlined />,
      label: <Link to="/backstage/investors">{LABEL_ACCOUNTS}</Link>,
    },
    ...(
      hasCampaigns ? [{
        key: "/backstage/campaigns",
        icon: <NotificationOutlined />,
        label: <Link to="/backstage/campaigns">{LABEL_CAMPAIGNS}</Link>,
      }] : []
    ),
    ...(
      canReadProperties ? [{
        key: "/backstage/properties",
        icon: <FolderOpenOutlined />,
        label: <Link to="/backstage/properties">{LABEL_SUBJECTS}</Link>
      }] : []
    ),
    {
      key: "/backstage/transactions",
      icon: <DollarOutlined />,
      label: (
        <Space>
          <Link to="/backstage/transactions">{LABEL_TRANSACTIONS}</Link>
          {
            hasFailedTransactions && (
              <Badge
                count={failedTransactionsCount}
              />
            )
          }
        </Space>
      ),
    },
    ...(
      canShowActivities ? [{
        key: "/backstage/activities",
        icon: <HistoryOutlined />,
        label: <Link to="/backstage/activities">{LABEL_ACTIVITY}</Link>,
      }] : []
    ),
    {
      type: 'divider',
    },
    {
      key: "projects",
      type: "group",
      label: isLoadingProjects
        ? LABEL_LOADING
        : (
            <div style={{ marginLeft: -1 * W0 / 2, marginRight: -1 * W0 / 2 }}>
              <div>
                {
                  canCreateProject && (
                    <Button
                      size="small"
                      type="text"
                      icon={<PlusOutlined />}
                      style={{ float: "right" }}
                      onClick={openProjectCreateModal}
                    />
                  )
                }
                <Text strong style={{ marginLeft: 6 }}>
                  {LABEL_PROJECTS}
                </Text>
              </div>

              {
                shouldRenderSearch && (
                  <SearchInput
                    onSearch={onSearch}
                    inputProps={{
                      style: { width: "100%", marginTop: W0 / 2 },
                      variant: "filled",
                    }}
                  />
                )
              }
            </div>
          ),
      className: "project-list",
      children: projectsItems
    },
  ]

  const selectedKeys = [...items, ...projectsItems]
    .map(item => item.key)
    .filter(key => window.location.pathname.startsWith(`${key}`.replace('/details', '/')))

  return [ items, selectedKeys ]
}

export default useMainMenu
