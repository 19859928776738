import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"

import getCampaignTimeZone from "./getCampaignTimeZone"

dayjs.extend(isSameOrAfter)

const validateScheduledAt = scheduledAt => {
  const timeZone = getCampaignTimeZone(scheduledAt)

  const todayPlusOneDay = dayjs().tz(timeZone).add(24, "hour")
  const isValid = dayjs(scheduledAt).tz(timeZone).isSameOrAfter(todayPlusOneDay)

  return isValid
}

export default validateScheduledAt
