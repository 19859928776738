import React from "react"
import { Tag, Tooltip } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const LABEL_PENDING = "Pending Verification"
const LABEL_TOOLTIP = `Dwolla is verifying the information you provided.
 This verification process may take up to 2 business days. Please check back for updates.`


const CustomerPendingVerificationStatus = () => {
  return (
    <Tooltip
      title={LABEL_TOOLTIP}
      placement="top"
    >
      <Tag
        icon={<ExclamationCircleOutlined />}
        color="blue"
        style={{ cursor: "pointer" }}
      >
        {LABEL_PENDING}
      </Tag>
    </Tooltip>
  )
}

export default CustomerPendingVerificationStatus
