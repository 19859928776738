import { useMemo } from "react"
import PropTypes from "prop-types"
import keyBy from "lodash.keyby"

import { useAppContext } from "@components/AppContext"
import {
  indexBankAccountsOperation as indexOperation,
  IndexBankAccountsOutputShape,
} from "@api/services/transactions"

import useStore from "../helpers/useStore"


const useBankAccountsStore = (projects) => {
  const { request } = useAppContext()

  const shouldAutoload = false

  const {
    items: bankAccounts,
    addItem: addBankAccount,
    indexItems: indexBankAccounts,
    removeItem: removeBankAccount,
  } = useStore(indexOperation, request, shouldAutoload)

  const bankAccountsMap = useMemo(() =>
    keyBy(bankAccounts, "id")
  , [ bankAccounts ])

  const getBankAccount = id => bankAccountsMap[id]

  const isInvalidBankAccount = id => {
    const bankAccount = getBankAccount(id)

    if (!bankAccount) {
      return false
    }

    return bankAccount.isInvalid
  }

  const getBankAccountName = id => {
    const bankAccount = getBankAccount(id)

    if (!bankAccount) {
      return id
    }

    const { beneficiaryName, sourceName } = bankAccount

    return `${beneficiaryName} - ${sourceName}`
  }

  const isInvalidBankAccountTransaction = transaction => {
    const {
      senderSourceId,
      receiverSourceId,
    } = transaction

    const bankAccountsBySourceIdMap = keyBy(bankAccounts, 'sourceId')

    const bankAccount =
      bankAccountsBySourceIdMap[senderSourceId] ||
      bankAccountsBySourceIdMap[receiverSourceId]

    if (!bankAccount) {
      return false
    }

    const { isInvalid } = bankAccount

    return isInvalid
  }

  const isVerifiedBankAccount = bankAccountId => {
    const bankAccount = getBankAccount(bankAccountId)
    const { isSourceVerified } = bankAccount

    return isSourceVerified
  }

  return {
    bankAccounts,
    addBankAccount,
    bankAccountsMap,
    removeBankAccount,
    indexBankAccounts,
    getBankAccountName,
    isInvalidBankAccount,
    isVerifiedBankAccount,
    isInvalidBankAccountTransaction
  }
}

const bankAccountsStoreProperties = {
  bankAccounts: PropTypes.arrayOf(IndexBankAccountsOutputShape),
  addBankAccount: PropTypes.func.isRequired,
  bankAccountsMap: PropTypes.shape().isRequired,
  indexBankAccounts: PropTypes.func.isRequired,
  removeBankAccount: PropTypes.func.isRequired,
  getBankAccountName: PropTypes.func.isRequired,
  isInvalidBankAccount: PropTypes.func.isRequired,
  isVerifiedBankAccount: PropTypes.func.isRequired,
  isInvalidBankAccountTransaction: PropTypes.func.isRequired,
}

IndexBankAccountsOutputShape

export default useBankAccountsStore

export {
  indexOperation,
  bankAccountsStoreProperties,
}
