import React from "react"
import { Divider } from "antd"
import PropTypes from "prop-types"

import { W1 } from "@components"

import Header from "./Header"


const Page = ({
  menu = [],
  title = "",
  style = { padding: W1 },
  onSearch = undefined,
  titleExtra = undefined,
  children,
}) => {
  return (
    <>
      <Header
        menu={menu}
        title={title}
        onSearch={onSearch}
        titleExtra={titleExtra}
      />

      <Divider style={{ margin: 0 }} />

      <div style={style}>
        {children}
      </div>
    </>
  )
}

Page.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string,
  style: PropTypes.shape(),
  onSearch: PropTypes.func,
  children: PropTypes.node.isRequired,
  titleExtra: PropTypes.node,
}

export default Page
