import getStepInputs from "./getStepInputs"
import getEntityInputs from "./getEntityInputs"
import getBankAccountInputs from "./getBankAccountInputs"


const getInputs = (form, currentStep, goStep, bankAccounts = []) => {
  const isFistStep = currentStep === 0
  const entityInputs = getEntityInputs(form, bankAccounts)
  const bankAccountInputs = getBankAccountInputs(form, isFistStep, bankAccounts)

  const stepInputs = getStepInputs(currentStep, goStep, entityInputs, bankAccountInputs)

  return stepInputs
}

export default getInputs
