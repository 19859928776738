import React, { useMemo } from "react"
import { useOutletContext } from "react-router-dom"

import EditTagsDropdown from "../EditTagsDropdown"

const LABEL_TAGS = "Tags"


const useTagsColumnProps = (tagOptions, onSave) => {
  const { getOptionName } = useOutletContext()

  const key = "tagIds"
  const title = LABEL_TAGS

  const filters = useMemo(() =>
    tagOptions
      .map(({ id }) => ({
        value: id,
        label: getOptionName(id)
      }))
  , [ tagOptions, getOptionName ])

  const onFilter = (selectedValues, { tagIds = [] }) =>
    selectedValues.filter(tagId => tagIds.includes(tagId)).length > 0

  const props = {
    key,
    title,
    render: (_, { id, tagIds = [] }) => (
      <EditTagsDropdown
        size="small"
        tags={tagOptions}
        tagIds={tagIds}
        onSave={newTagIds => onSave(id, newTagIds)}
      />
    ),
    filters,
    onFilter,
    filterMultiple: true,
    isFilterEnabled: true,
  }

  return props
}

export default useTagsColumnProps
