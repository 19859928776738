
const computeSignStatus = ({ isSigned, isPartiallySigned }) => {
  if (isSigned) {
    return "signed"
  }

  if (isPartiallySigned) {
    return "partiallySigned"
  }

  return "notSigned"
}

export default computeSignStatus
