const getSignStatusColor = value => {
  const isSigned = value === "signed"

  if (isSigned) {
    return "green"
  }

  return "blue"
}

export default getSignStatusColor
