import { useState, useEffect, useCallback } from "react"

import { useAppContext } from "@components/AppContext"
import {
  updateMyAccountOperation as updateAccountOperation,
  updateMyImmutableAccountInvestorOperation as updateImmutableAccountOperation
} from "@api/services/investments"

import getUpdateMyAccountSchema from "./getUpdateMyAccountSchema"
import getUpdateImmutableAccountSchema from "./getUpdateImmutableAccountSchema"


const useUpdateSchema = (form, shouldIncludeAccreditationNote = false, shouldIncludeTimeZone = true) => {
  const { identity } = useAppContext()

  const [ schema, setSchema ] = useState([])

  const { isImmutable } = identity

  const updateSchema = useCallback(
    () => {
      if (isImmutable) {
        const address = form.getFieldValue(["address"])

        const updatedSchema = getUpdateImmutableAccountSchema({
          address,
          updateSchema,
        })

        setSchema(updatedSchema)

        return
      }

      const address = form.getFieldValue(["investor", "address"])
      const updatedSchema = getUpdateMyAccountSchema({
        address,
        updateSchema,
        shouldIncludeTimeZone,
        shouldIncludeAccreditationNote,
      })

      setSchema(updatedSchema)
    }
  , [
    form,
    isImmutable,
    shouldIncludeTimeZone,
    shouldIncludeAccreditationNote,
  ])

  useEffect(() => {
    updateSchema()
  }, [ updateSchema ])

  const operation = isImmutable
    ? updateImmutableAccountOperation
    : updateAccountOperation

  return [ schema, operation ]
}

export default useUpdateSchema
