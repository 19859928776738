import { useState, useMemo, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import keyBy from "lodash.keyby"
import sortBy from "lodash.sortby"

import { InvestmentShape } from "@components/Investment/shapes"
import { INVESTMENT_STATUS } from "@components/Domain"
import { processInvestments } from "@components/Investment"
import { getFormattedDecimalValue } from "@components/Amount"
import { runRequest, useAppContext } from "@components/AppContext"
import { indexMyInvestmentsOperation } from "@api/services/investments"


const useInvestmentsStore = () => {
  const { request } = useAppContext()

  const shouldAutoLoad = false

  const [ investments, setProcessedInvestments ] = useState()

  const indexInvestments = useCallback(() =>
    request(indexMyInvestmentsOperation)
      .then(({ data }) => setInvestments(data))
  , [ request ])

  useEffect(() => {
    if (!shouldAutoLoad) {
      return
    }

    if (investments) {
      return
    }

    return runRequest(indexInvestments)
  }, [ investments, shouldAutoLoad, indexInvestments ])

  const addInvestment = newItem =>
    setProcessedInvestments(prevItems =>
      processInvestments([ newItem, ...prevItems ])
    )

  const updateInvestment = updatedItem =>
    setProcessedInvestments((prevItems = []) =>
      processInvestments(
        prevItems.map(item => ({
          ...(item.id === updatedItem.id ? updatedItem : item)
        }))
      )
    )

  const deleteInvestment = id =>
    setProcessedInvestments(prevItems =>
      processInvestments(
        [...prevItems.filter(item => item.id !== id)]
      )
    )

  const setInvestments = data => {
    const processedInvestments = processInvestments(data)
    setProcessedInvestments(processedInvestments)
  }

  const getInvestment = useCallback(
    id => (investments || []).find(item => item.id === id)
  , [ investments ])

  const getProjectInvestments = useCallback(
    projectId =>
      sortBy(
        investments
          .filter(investment => investment.projectId === projectId)
          .filter(({ status }) => status !== INVESTMENT_STATUS.REJECTED)
      , 'createdAt')
  , [ investments ])

  const getProjectInvestedAmount = projectId =>
    getProjectInvestments(projectId)
      .map(({ investments: classes }) => classes
        .reduce((acc, investment) => getFormattedDecimalValue(acc, investment.amount, "plus"), 0)
      )
      .reduce((acc, amount) => getFormattedDecimalValue(acc, amount, "plus"), 0)

  const investmentsMap = useMemo(() =>
    keyBy(investments, "id")
  , [ investments ])

  const getInvestmentProjectName = investmentId =>
    investmentsMap[investmentId]?.projectName || investmentId

  const getSignedInvestmentIds = (ids = []) =>
    ids.filter(id => investmentsMap[id].isPartiallySigned || investmentsMap[id].isSigned)

  return {
    investments,
    addInvestment,
    getInvestment,
    investmentsMap,
    indexInvestments,
    updateInvestment,
    deleteInvestment,
    getProjectInvestments,
    getSignedInvestmentIds,
    setProcessedInvestments,
    getProjectInvestedAmount,
    getInvestmentProjectName,
  }
}

const investmentsStoreProperties = {
  investments: PropTypes.arrayOf(InvestmentShape),
  addInvestment: PropTypes.func.isRequired,
  getInvestment: PropTypes.func.isRequired,
  investmentsMap: PropTypes.shape().isRequired,
  indexInvestments: PropTypes.func.isRequired,
  updateInvestment: PropTypes.func.isRequired,
  deleteInvestment: PropTypes.func.isRequired,
  getProjectInvestments: PropTypes.func.isRequired,
  getSignedInvestmentIds: PropTypes.func.isRequired,
  setProcessedInvestments: PropTypes.func.isRequired,
  getProjectInvestedAmount: PropTypes.func.isRequired,
  getInvestmentProjectName: PropTypes.func.isRequired,
}

export default useInvestmentsStore

export {
  investmentsStoreProperties
}
