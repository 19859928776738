import PropTypes from "prop-types"

import ProjectTransactionReportShape from "./shapes/ProjectTransactionReportShape"

const properties = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  distributionsAmount: PropTypes.number.isRequired,
  endDate: PropTypes.string,
  equityReceivedAmount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  projectTransactionReports: PropTypes.arrayOf(ProjectTransactionReportShape).isRequired,
  startDate: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const ReadTransactionReportOutputShapeShape = PropTypes.exact(properties)

export default ReadTransactionReportOutputShapeShape
export { properties }
