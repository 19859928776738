

const getUsResidenceState = (isEntity, profile, addressState) => {
  const {
    isUsPerson,
    isUsCitizen,
    residenceState,
    hasOtherUsResidenceState,
  } = profile

  const computedResidenceState = hasOtherUsResidenceState
    ? residenceState
    : addressState

  if (isEntity) {
    return computedResidenceState
  }

  if (isUsCitizen) {
    return computedResidenceState
  }

  if (!isUsPerson) {
    return "Not a resident of US"
  }

  return computedResidenceState
}

export default getUsResidenceState
