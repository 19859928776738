import React, { useRef, useEffect } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"

import { W1 } from "@components"
import { Page } from "@components/Page"
import { runRequest } from "@components/AppContext"

import CampaignsTable from "./CampaignsTable"
import CampaignCloneModal from "./CampaignCloneModal"
import CampaignCreateModal from "./CampaignCreateModal"

const LABEL_TITLE = "Campaigns"


const CampaignsPage = () => {
  const table = useRef({})

  const campaignCloneModal = useRef({})
  const campaignCreateModal = useRef({})

  const {
    campaigns,
    addCampaign,
    indexCampaigns
  } = useOutletContext()

  const navigate = useNavigate()

  useEffect(() => {
    const hasCampaigns = campaigns !== undefined

    if (hasCampaigns) {
      return
    }

    return runRequest(() => table.current.indexItems(true))
  }, [ campaigns ])

  const onSearch = value =>
    table.current.search(value)

  const openCampaignCreateModal = () =>
    campaignCreateModal.current.open()

  const openCampaignCloneModal = (campaign) =>
    campaignCloneModal.current.openItem(campaign)

  const onSuccess = newCampaign => {
    const { id } = newCampaign
    addCampaign(newCampaign)
    navigate(`/backstage/campaigns/${id}`)
  }

  const style = {
    padding: W1,
    paddingBottom: 0,
  }

  return (
    <Page
      style={style}
      title={LABEL_TITLE}
      onSearch={onSearch}
    >
      <CampaignsTable
        table={table}
        items={campaigns}
        indexItems={indexCampaigns}
        openCampaignCloneModal={openCampaignCloneModal}
        openCampaignCreateModal={openCampaignCreateModal}
      />

      <CampaignCreateModal
        modal={campaignCreateModal}
        onSuccess={onSuccess}
      />

      <CampaignCloneModal
        modal={campaignCloneModal}
        onSuccess={onSuccess}
      />
    </Page>
  )
}

export default CampaignsPage
