import React from "react"
import { Alert, Space, Typography } from "antd"

import { W0, W1 } from "@components"
import { Disclaimer } from "@components/Text"
import { US_STATE_OPTIONS } from "@components/Address"
import { TYPE_BOOLEAN, TYPE_STRING, TYPE_NONE } from "@components/Form"

const { Text, Link } = Typography

const TITLE_WARNING = "Warning"

const TOOLTIP_NON_US_PERSON = `
For federal tax purposes, you are considered a U.S. person if you are:
An individual who is a U.S. citizen or U.S. resident alien;
A partnership, corporation, company, or association created or organized in the United States or under the laws of the United States;
An estate (other than a foreign estate); or
A domestic trust (as defined in Regulations section 301.7701-7).
`


const computeResidencyInputs = (profile, updateSchema, labels = { isUsPerson : "" }, organizationEmail = "") => {
  const typesMap = {
    hasOtherUsResidenceState: TYPE_NONE,
    isUsPerson: TYPE_NONE,
    residenceState: TYPE_NONE,
    hasSsn: TYPE_NONE,
    hasEinNumber:  TYPE_NONE
  }

  const { isUsPerson, hasOtherUsResidenceState, isUsCitizen, hasSsn } = profile
  const isNotUsCitizen = isUsCitizen  === false
  const showResidenceState = (isUsCitizen && hasOtherUsResidenceState) || (isNotUsCitizen && isUsPerson && hasOtherUsResidenceState)
  const showHasOtherUsResidenceState = isUsCitizen || (isNotUsCitizen && isUsPerson)

  if (isUsCitizen) {
    typesMap.hasOtherUsResidenceState = TYPE_BOOLEAN

    if (hasOtherUsResidenceState) {
      typesMap.residenceState = TYPE_STRING
    }
  }

  if (isNotUsCitizen) {
    typesMap.isUsPerson = TYPE_BOOLEAN
  } else {
    typesMap.isUsPerson = TYPE_NONE
  }

  if (showHasOtherUsResidenceState) {
    typesMap.hasOtherUsResidenceState = TYPE_BOOLEAN
  }

  if (showResidenceState) {
    typesMap.residenceState = TYPE_STRING
  } else {
    typesMap.residenceState = TYPE_NONE
  }

  const statesInputs = [
    {
      name: "hasOtherUsResidenceState",
      type: typesMap.hasOtherUsResidenceState,
      required: true,
      /* td: we can include state to make it more clear */
      label: labels.hasOtherUsResidenceState || `
        Is the state listed under your address also your state of residence
        for tax purposes?
      `,
      inputProps: {
        hasInvertedValues: true,
        onChange: () => updateSchema()
      }
    },
    {
      name: "residenceState",
      type: typesMap.residenceState,
      label: labels.residenceState || "Please list your state of residence",
      options: US_STATE_OPTIONS,
      required: true,
      placeholder: "",
      inputProps: {
        style: { width: 180 }
      }
    }
  ]
  const mailOrganizationEmail = `mailto:${organizationEmail}`

  const doYouHaveSsnExtra = hasSsn === false
    ? <Alert
      showIcon
      type="warning"
      message={TITLE_WARNING}
      description={
        <Space
          size={0}
          direction="vertical"
          style={{ marginTop: W0, lineHeight: 1 }}
        >
          <div>
            <Text>
              A taxpayer identification number (TIN) is required on all returns, statements, and other
              tax-related documents. We will need to collect a TIN from you in order to proceed with your investment.
              If you do not have a TIN, please visit the IRS website for instructions regarding how to obtain an ITIN
              for yourself or an EIN for your entity (if applicable). Please reach out to
            </Text>
            <Link
              href={mailOrganizationEmail}
            >
              {" "}{organizationEmail}{" "}
            </Link>
            <Text>
              if you have any questions.
            </Text>
          </div>
        </Space>
      }
      style={{marginTop: W1}}
    />
    : undefined

  if (isUsCitizen) {
    return [
      ...[{
        name: "isUsPerson",
        required: true,
        type: typesMap.isUsPerson,
        label: labels.isUsPerson,
        itemProps: {
          extra: <Disclaimer text={TOOLTIP_NON_US_PERSON} />
        },
        inputProps: {
          onChange: () => updateSchema()
        }
      }],
      ...statesInputs,
      ...[
        {
          name: "hasSsn",
          type: TYPE_BOOLEAN,
          required: true,
          label: labels.hasSsn || "Do you have an SSN?",
          itemProps: {
            extra: doYouHaveSsnExtra
          },
          inputProps: {
            onChange: () => updateSchema()
          }
        }
      ]
    ]
  }

  return [
    ...[{
      name: "isUsPerson",
      required: true,
      type: typesMap.isUsPerson,
      label: labels.isUsPerson,
      itemProps: {
        extra: <Disclaimer text={TOOLTIP_NON_US_PERSON} />
      },
      inputProps: {
        onChange: () => updateSchema()
      }
    }],
    ...statesInputs,
    ...[
      {
        name: "hasSsn",
        type: TYPE_BOOLEAN,
        required: true,
        label: labels.hasSsn || "Do you have an SSN?",
        itemProps: {
          extra: doYouHaveSsnExtra
        },
        inputProps: {
          onChange: () => updateSchema()
        }
      }
    ]
  ]
}

export default computeResidencyInputs
