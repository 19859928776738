import { ProjectStateEnumKeys } from "./shapes/ProjectStateEnum"
import { ProjectStatusEnumKeys } from "./shapes/ProjectStatusEnum"
import { ProjectTypeEnumKeys } from "./shapes/ProjectTypeEnum"

const IndexPublishedProjectsItemSchema = {
  status: { enum: ProjectStatusEnumKeys },
  state: { enum: ProjectStateEnumKeys },
  type: { enum: ProjectTypeEnumKeys },
  nonAccreditedInvestorsMax: { type: 'integer' },
  targetAmount: { type: 'integer' },
  amount: { type: 'integer' },
  isAchFundingEnabled: { type: 'boolean' },
  openDate: { format: 'date' },
  closeDate: { format: 'date' },
  signDeadlineDate: { format: 'date' },
  fundDeadlineDate: { format: 'date' },
  targetCloseDate: { format: 'date' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexPublishedProjectsItemSchema
