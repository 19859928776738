import React, { useState } from "react"
import PropTypes from "prop-types"

import { Modal } from "@components/Page"
import { CustomerBalance } from "@components/Dwolla"

import BankAccount from "./BankAccount"
import LinkedProjects from "./LinkedProjects"

const LABEL_TITLE = "Bank Account"
const LABEL_BALANCE = "Dwolla Balance"


const BankAccountModal = ({
  hasLinkedProjects = false,
  modal,
}) => {
  const [ item, setItem ] = useState()
  const [ customerName, setCustomerName ] = useState()

  modal.current.open = (bankAccount, shouldRenderDwollaBalance = false) => {
    if (shouldRenderDwollaBalance) {
      setCustomerName(bankAccount.beneficiaryName)
    }

    setItem(bankAccount)
  }

  const onClose = () => {
    setItem()
    setCustomerName()
  }

  const extraItems = []

  if (customerName) {
    extraItems.push({
      label: LABEL_BALANCE,
      value: (
        <CustomerBalance
          key={customerName}
          customerName={customerName}
        />
      )
    })
  }

  const isOpen = !!item

  return (
    <Modal
      title={LABEL_TITLE}
      width={600}
      modal={modal}
      isOpen={isOpen}
      onClose={onClose}
    >
      {
        item && (
          <BankAccount
            extraItems={extraItems}
            bankAccount={item}
          />
        )
      }

      {
        hasLinkedProjects && item && (
          <LinkedProjects
            bankAccountId={item.id}
          />
        )
      }
    </Modal>
  )
}

BankAccountModal.propTypes = {
  hasLinkedProjects: PropTypes.bool,
  modal: PropTypes.shape().isRequired,
}

export default BankAccountModal
