import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { useForm } from "@components/Form"
import { CreateModal } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { createBankAccountOperation as createOperation } from "@api/services/transactions"

import useInputs from "./helpers/useInputs"
import getExtendedBankAccounts from "./helpers/getExtendedBankAccounts"

const LABEL_ENTITY = "Bank Account"
const LABEL_ADD = `Connect ${LABEL_ENTITY}`
const LABEL_CONNECT = "Connect"


const BankAccountCreateModal = ({ modal }) => {
  const form = useForm()

  const { bankAccounts, addBankAccount } = useOutletContext()
  const { identity, authorizeOrganizationIdentity } = useAppContext()

  const { customers } = identity

  const extendedBankAccounts = useMemo(() =>
    getExtendedBankAccounts(customers, bankAccounts)
  , [
    customers,
    bankAccounts
  ])

  const [ createInputs, setStep ] = useInputs(form, extendedBankAccounts)

  const schema = createOperation.getSchema(createInputs)

  const onSuccess = async (newBankAccount) => {
    addBankAccount(newBankAccount)
    await authorizeOrganizationIdentity()
    setStep(0)
  }

  const createModalProps = {
    title: LABEL_ADD,
    entity: LABEL_ENTITY,
    operation: createOperation,
    submitLabel: LABEL_CONNECT,
    hasSubmitButton: false,
    form,
    modal,
    schema,
    onSuccess,
  }

  return (
    <CreateModal {...createModalProps} />
  )
}

BankAccountCreateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default BankAccountCreateModal
