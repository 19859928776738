import { useState, useEffect } from "react"
import { Form } from "antd"

import getOwnersInputs from "./getOwnersInputs"
import getNoOwnersInputs from "./getNoOwnersInputs"
import getStepInputs, { INITIAL_STEP, OWNERS_STEP, NO_OWNERS_STEP } from "./getStepInputs"


const useInputs = ({
  form,
  customer,
  beneficialOwners,
  indexBeneficialOwners,
}) => {
  const [ step, setStep ] = useState(INITIAL_STEP)
  const [ schema, setSchema ] = useState([])

  const {
    id: customerId,
    name: customerName,
  } = customer

  const stepWatcher = Form.useWatch("_step", form)
  const isControllerConfirmedWatcher = Form.useWatch("_isControllerConfirmed", form)
  const hasNoBeneficialOwnersConfirmedWatcher = Form.useWatch("_hasNoBeneficialOwnersConfirmed", form)

  useEffect(() => {
    const isInitialStep = step === INITIAL_STEP
    const isNoOwnersStep = step === NO_OWNERS_STEP

    if (isInitialStep) {
      const inputs = getStepInputs({
        form,
        setStep,
        customerName,
      })

      setSchema(inputs)
      return
    }

    if (isNoOwnersStep) {
      const inputs = getNoOwnersInputs({
        form,
        setStep,
        customerName,
      })

      setSchema(inputs)
      return
    }

    const inputs = getOwnersInputs({
      form,
      setStep,
      customerId,
      customerName,
      beneficialOwners,
      indexBeneficialOwners,
    })

    setSchema(inputs)
  }, [
    step,
    form,
    customerId,
    stepWatcher,
    customerName,
    beneficialOwners,
    indexBeneficialOwners,
    isControllerConfirmedWatcher,
    hasNoBeneficialOwnersConfirmedWatcher
  ])

  useEffect(() => {
    const hasBeneficialOwners = !!beneficialOwners && beneficialOwners.length > 0

    if (!hasBeneficialOwners) {
      return
    }

    setStep(OWNERS_STEP)
  }, [ beneficialOwners ])

  return schema
}

export default useInputs
