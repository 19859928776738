import React from "react"
import PropTypes from "prop-types"
import keyBy from "lodash.keyby"
import { Space, Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import { Value } from "@components/Text"
import TierInvestmentShape from "@api/services/investments/shapes/TierInvestmentShape"


const InvestmentInvestments = ({
  projectId,
  investments,
}) => {
  const { getProject, getTierName } = useOutletContext()

  const project = getProject(projectId)

  const { tiers } = project
  const tiersMap = keyBy(tiers, "name")

  const getLabel = projectTierId => {
    const tier = tiersMap[projectTierId]

    if (tier) {
      return getTierName(tier)
    }

    return "Undefined"
  }

  const renderInvestment = ({ projectTierId, amount }, key) =>
    <Typography.Text key={key}>
      <Typography.Text type="secondary">
        {getLabel(projectTierId)}:
      </Typography.Text>
      {" "}
      <Value value={amount} />
    </Typography.Text>

  return (
    <Space>
      {investments.map(renderInvestment)}
    </Space>
  )
}

InvestmentInvestments.propTypes = {
  projectId: PropTypes.string.isRequired,
  investments: PropTypes.arrayOf(TierInvestmentShape).isRequired,
}

export default InvestmentInvestments
