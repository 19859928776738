import { TYPE_NONE } from "@components/Form"


const getProfileJointInputsMap = () => ({
  isLiabilityAccepted: {
    name: "isLiabilityAccepted",
    type: TYPE_NONE
  },
  hasPurchasedExemptSecurities: {
    name: "hasPurchasedExemptSecurities",
    type: TYPE_NONE
  },
  membershipInterestsTitle: {
    name: "membershipInterestsTitle",
    type: TYPE_NONE
  },
  hasJointTaxReturn: {
    name: "hasJointTaxReturn",
    type: TYPE_NONE
  },
  shouldCoInvestorSign: {
    name: "shouldCoInvestorSign",
    type: TYPE_NONE
  },
  jointGivenName: {
    name: "jointGivenName",
    type: TYPE_NONE
  },
  jointAdditionalName: {
    name: "jointAdditionalName",
    type: TYPE_NONE
  },
  jointFamilyName: {
    name: "jointFamilyName",
    type: TYPE_NONE
  },
  jointEmail: {
    name: "jointEmail",
    type: TYPE_NONE
  },
  jointAddress: {
    name: "jointAddress",
    type: TYPE_NONE
  },
  isUsCitizen: {
    name: "isUsCitizen",
    type: TYPE_NONE
  },
  isUsPerson: {
    name: "isUsPerson",
    type: TYPE_NONE
  },
  hasOtherUsResidenceState: {
    name: "hasOtherUsResidenceState",
    type: TYPE_NONE
  },
  residenceState: {
    name: "residenceState",
    type: TYPE_NONE
  },
  hasSsn: {
    name: "hasSsn",
    type: TYPE_NONE
  },
  ssnNumber: {
    name: "ssnNumber",
    type: TYPE_NONE
  },
})

export default getProfileJointInputsMap
