import React, { useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Table as AntTable, Space, Row, Col } from "antd"

import { useAppContext } from "@components/AppContext"
import { OperationShape } from "@api"

import Header from "./Header"
import useSearch from "./helpers/useSearch"
import { ASCEND } from "./helpers/getSortingProps"
import getColumns from "./helpers/getColumns"
import TableSegment from "./TableSegment"
import useSortItems from "./helpers/useSortItems"
import useItemsStore from "./helpers/useItemsStore"
import useSelectable from "./helpers/useSelectable"
import TableColumnShape from "./shapes/TableColumnShape"
import TableFilters, { useFilters } from "./TableFilters"

import "./Table.css"
import "./TableCellTruncate.css"

const DEFAULT_ROW_KEY = "id"


const Table = ({
  items: customItems = undefined,
  request: customRequest = undefined,
  indexItems: customIndexItems = undefined,
  table = undefined,
  style = {},
  isReady = true,
  summary = undefined,
  isSelectable = false,
  columnsConfig = [],
  hasHeaderSegment = false,
  hasHeaderFilters = false,
  onSelectionChange = () => {},
  defaultSortColumn = null,
  shouldRenderHeader = true,
  headerFiltersAside = null,
  operationParameters = undefined,
  filterItemsCallback = undefined,
  isColumnSearchEnabled = false,
  defaultSortColumnDirection = ASCEND,
  getSelectableCheckboxProps = () => {},
  headerActions = undefined,
  exportColumns = undefined,
  exportFileName = undefined,
  headerRenderTitle = undefined,
  indexOperation,
  ...otherProps
}) => {
  const antTable = useRef({})
  const newTable = useRef({})

  if (!table) {
    table = newTable
  }

  let { request } = useAppContext()

  if (customRequest) {
    request = customRequest
  }

  const sortItems = useSortItems(columnsConfig, defaultSortColumn, defaultSortColumnDirection)
  const [ items, isLoading, reload ] = useItemsStore(request, table, {
    sortItems,
    customItems,
    indexOperation,
    customIndexItems,
    operationParameters,
    filterItemsCallback,
  })

  const selectableProps = useSelectable(isSelectable, table, onSelectionChange, getSelectableCheckboxProps)
  const [ getColumnSearchProps, searchItems ] = useSearch(table, columnsConfig, isColumnSearchEnabled)

  const extraProps = {
    ...otherProps,
    ...selectableProps
  }

  const sortParams = useMemo(() => ({
    defaultSortColumn,
    defaultSortColumnDirection,
  }), [ defaultSortColumn, defaultSortColumnDirection ])

  const columns = useMemo(() =>
    getColumns(
      indexOperation,
      items,
      columnsConfig,
      getColumnSearchProps,
      sortParams,
      hasHeaderFilters || hasHeaderSegment
  ), [
    items,
    sortParams,
    columnsConfig,
    indexOperation,
    hasHeaderFilters,
    hasHeaderSegment,
    getColumnSearchProps,
  ])

  const {
    filteredItems,
    onTableChange,
    headerFilterProps,
  } = useFilters(items, columns)

  const dataSource = useMemo(() =>
    searchItems(filteredItems)
  , [ searchItems, filteredItems ])

  const { pagination = false } = otherProps
  const showSorterTooltip = false

  const itemsCount = (items || []).length

  const filteredItemsCount = filteredItems.length

  const getFilteredItems = () => filteredItems

  table.current.getFilteredItems = getFilteredItems

  const hasColumnFilters =
    !hasHeaderFilters &&
    !hasHeaderSegment

  if (hasColumnFilters) {
    extraProps.onChange = onTableChange
  }

  const headerProps = {
    actions: headerActions,
    renderTitle: headerRenderTitle,
    items,
    style,
    reload,
    itemsCount,
    exportColumns,
    exportFileName,
    getFilteredItems,
    filteredItemsCount,
  }

  const scrollTableToTop = () => {
    if (!antTable.current) {
      return
    }

    antTable.current.scrollTo({ top: 0 })
  }

  if (pagination) {
    pagination.onChange = () => scrollTableToTop()
  }

  const tableProps = {
    ref: antTable,
    size: "middle",
    rowKey: DEFAULT_ROW_KEY,
    loading: !isReady || isLoading,
    style,
    columns,
    summary,
    dataSource,
    pagination,
    showSorterTooltip,
    ...extraProps
  }

  return (
    <Space
      style={{ width: '100%' }}
      direction="vertical"
    >
      {
        hasHeaderFilters && (
          <Row>
            <Col flex="auto">
              <TableFilters {...headerFilterProps} />
            </Col>
            <Col>
              {headerFiltersAside}
            </Col>
          </Row>
        )
      }

      {
        hasHeaderSegment && (
          <TableSegment {...headerFilterProps} />
        )
      }

      {
        shouldRenderHeader && (
          <Header {...headerProps} />
        )
      }

      <AntTable {...tableProps} />
    </Space>
  )
}

Table.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  indexItems: PropTypes.func,
  table: PropTypes.shape(),
  style: PropTypes.shape(),
  request: PropTypes.func,
  isReady: PropTypes.bool,
  summary: PropTypes.func,
  columnsConfig: PropTypes.arrayOf(TableColumnShape),
  indexOperation: OperationShape.isRequired,
  shouldRenderHeader: PropTypes.bool,
  operationParameters: PropTypes.shape(),
  filterItemsCallback: PropTypes.func,
  defaultSortColumn: PropTypes.string,
  defaultSortColumnDirection: PropTypes.string,
  isSelectable: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  getSelectableCheckboxProps: PropTypes.func,
  headerActions: PropTypes.arrayOf(PropTypes.shape()),
  headerRenderTitle: PropTypes.func,
  exportColumns: PropTypes.arrayOf(PropTypes.shape()),
  exportFileName: PropTypes.string,
  isColumnSearchEnabled: PropTypes.bool,
  hasHeaderFilters: PropTypes.bool,
  hasHeaderSegment: PropTypes.bool,
  headerFiltersAside: PropTypes.shape(),
}

export default Table
