import PropTypes from "prop-types"

import AddressShape from "./AddressShape"
import { JointMembershipInterestsTitleEnumKeys } from "./JointMembershipInterestsTitleEnum"
import { UsStateEnumKeys } from "./UsStateEnum"

const properties = {
  hasJointTaxReturn: PropTypes.bool,
  hasOtherUsResidenceState: PropTypes.bool,
  hasPurchasedExemptSecurities: PropTypes.bool,
  hasSsn: PropTypes.bool,
  isLiabilityAccepted: PropTypes.bool,
  isUsCitizen: PropTypes.bool,
  isUsPerson: PropTypes.bool,
  jointAdditionalName: PropTypes.string,
  jointAddress: AddressShape,
  jointEmail: PropTypes.string,
  jointFamilyName: PropTypes.string,
  jointGivenName: PropTypes.string,
  membershipInterestsTitle: PropTypes.oneOf(JointMembershipInterestsTitleEnumKeys),
  profileName: PropTypes.string,
  residenceState: PropTypes.oneOf(UsStateEnumKeys),
  shouldCoInvestorSign: PropTypes.bool,
  ssnNumber: PropTypes.string
}

const JointShape = PropTypes.exact(properties)

export default JointShape
export { properties }
