import PropTypes from "prop-types"


const TableColumnShape = PropTypes.exact({
  key: PropTypes.string.isRequired,
  type: PropTypes.string,
  path: PropTypes.string,
  align: PropTypes.string,
  fixed: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape()
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onCell: PropTypes.func,
  render: PropTypes.func,
  compute: PropTypes.func,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onFilter: PropTypes.func,
  isHidden: PropTypes.bool,
  filterText: PropTypes.func,
  isSearchable: PropTypes.bool,
  filterValues: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])),
  hasTruncation: PropTypes.bool,
  isFilterEnabled: PropTypes.bool,
  isSortingEnabled: PropTypes.bool,
  widthAutoPadding: PropTypes.number,
  filterMultiple: PropTypes.bool,
})

export default TableColumnShape
