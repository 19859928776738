import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Col, Row, Space, Steps } from "antd"

import { Modal } from "@components/Page"
import { W0, W1 } from "@components"
import { Markdown } from "@components/Text"

const LABEL_OK = "I UNDERSTAND"
const LABEL_NEXT = "Next"
const LABEL_BACK = "Back"


const UnderstandConfirmModal = ({
  buttonTitle = LABEL_OK,
  title,
  modal,
  content,
}) => {
  const [ onOk, setOnOk ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ currentStep, setCurrentStep ] = useState(0)

  const onConfirm = async () => {
    setIsLoading(true)
    await onOk()
    onClose()
  }

  modal.current.open = (callback = () => {}) => {
    setIsLoading(false)
    setIsOpen(true)
    setOnOk(() => callback)
  }

  const onClose = () => {
    setIsLoading(false)
    setCurrentStep(0)
    setIsOpen(false)
  }

  const shouldRenderWizard = Array.isArray(content)
  const contentItems = content.length
  const hasBack = currentStep > 0
  const hasNext = currentStep < contentItems - 1
  const hasDone = currentStep === contentItems - 1

  const next = () =>
    setCurrentStep(currentStep + 1)

  const prev = () =>
    setCurrentStep(currentStep - 1)

  const items = shouldRenderWizard
    ? content
      .map(item => ({
        key: item.title,
        title: item.title,
      }))
    : []

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
    >
      {
        !shouldRenderWizard && (
          <Space
            direction="vertical"
            style={{ marginTop: -1 * W1, marginBottom: W0 }}
          >
            <Markdown text={content} />
            <center>
              <Button
                type="primary"
                onClick={onConfirm}
                loading={isLoading}
              >
                {buttonTitle}
              </Button>
            </center>
          </Space>
        )
      }
      {
        shouldRenderWizard && (
          <Space direction="vertical">
            <Steps current={currentStep} items={items} />

            <div>
              {content[currentStep].content}
            </div>

            <Row>
              <Col flex="auto">
                {hasBack && (
                  <Button onClick={() => prev()}>
                    {LABEL_BACK}
                  </Button>
                )}
              </Col>

              <Col align="right">
                {hasNext && (
                  <Button type="primary" onClick={() => next()}>
                    {LABEL_NEXT}
                  </Button>
                )}

                {
                  hasDone && (
                    <Button
                      type="primary"
                      loading={isLoading}
                      onClick={onConfirm}
                    >
                      {buttonTitle}
                    </Button>
                  )
                }
              </Col>
            </Row>
          </Space>
        )
      }
    </Modal>
  )
}

UnderstandConfirmModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape())
  ]).isRequired,
  buttonTitle: PropTypes.string,
}

export default UnderstandConfirmModal
