import React, { useState } from "react"
import { useOutletContext } from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { App, Typography, Space, Tooltip, Button } from "antd"

import { isMobile } from "@components"
import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"
import { IndexPublishedProjectsOutputShape } from "@api/services/backstage"
import {
  CustomerShape,
  CustomerUpgradeButton,
  SourceVerificationButton,
  CustomerVerificationButton,
  CUSTOMER_STATUS_VERIFIED,
  CUSTOMER_STATUS_SUSPENDED,
  CUSTOMER_STATUS_UNVERIFIED,
  CUSTOMER_STATUS_PENDING_DOCUMENT_VERIFICATION
} from "@components/Dwolla"

import confirmSendFunds from "./helpers/confirmSendFunds"
import CustomerPendingVerificationStatus from "./CustomerPendingVerificationStatus"

const LABEL_SEND_FUNDS = "Send Funds"
const LABEL_PAYMENT_SENT = "Payment is sent for processing"
const LABEL_VERIFY_CUSTOMER = `To proceed with investment funding, please
finalize the verification process.`


const SendFundsButton = ({
  project,
  customer,
  investment,
}) => {
  const [ shouldOpenModal, setShouldOpenModal ] = useState(false)

  const { modal: confirmModal } = App.useApp()
  const { request, showSuccessMessage } = useAppContext()
  const { updateInvestment, indexTransactions } = useOutletContext()

  const {
    isFunded,
    isOverfunded,
    hasSentFunds,
    senderSourceId,
  } = investment

  const { name: projectName } = project

  const hasSenderSource = !!senderSourceId

  const sources = customer.sources || []
  const source = sources.find(s => s.id === senderSourceId)

  const isSourceValid = !source?.isInvalid

  const isSourceVerified = source?.isVerified

  const { verificationStatus: customerVerificationStatus } = customer

  const isNoCustomer = customerVerificationStatus === undefined

  const isCustomerVerified = customerVerificationStatus === CUSTOMER_STATUS_VERIFIED

  const isCustomerSuspended = customerVerificationStatus === CUSTOMER_STATUS_SUSPENDED

  const isCustomerUnverified = customerVerificationStatus === CUSTOMER_STATUS_UNVERIFIED

  const isCustomerPendingVerification = customerVerificationStatus === CUSTOMER_STATUS_PENDING_DOCUMENT_VERIFICATION

  const isCompleted = isFunded || isOverfunded

  const isPending = hasSentFunds

  const showButton =
    hasSenderSource &&
    !isPending &&
    !isCompleted

  const isCustomerUpgradeRequired =
    showButton &&
    !isNoCustomer &&
    // eslint-disable-next-line no-warning-comments
    // TODO: There could be a scenario when upgrade has been already submitted
    //       while status is still unverified.
    isCustomerUnverified

  const isCustomerVerificationRequired =
    showButton &&
    !isNoCustomer &&
    !isCustomerVerified &&
    !isCustomerUpgradeRequired &&
    !isCustomerPendingVerification &&
    !isCustomerSuspended

  const isReadyToSendFunds =
    showButton &&
    isCustomerVerified &&
    isSourceVerified &&
    isSourceValid

  const isSourceVerification =
    showButton &&
    isCustomerVerified &&
    !isSourceVerified

  const onConfirmSendFunds = (updatedCustomer, updatedSource) => {
    const { verificationStatus } = updatedCustomer

    const isUpdatedCustomerVerified = verificationStatus === CUSTOMER_STATUS_VERIFIED

    if (!isUpdatedCustomerVerified) {
      return
    }

    const isUpdatedSourceVerified = updatedSource?.isVerified

    if (!isUpdatedSourceVerified && !isSourceVerified) {
      return
    }

    const afterSend = async (data) => {
      updateInvestment(data)
      await indexTransactions()
      showSuccessMessage(LABEL_PAYMENT_SENT)
    }

    const sourceName = source.name
    confirmSendFunds(confirmModal, request, investment, projectName, sourceName, afterSend)
  }

  const onSuccess = (updatedCustomer, updatedSource) => {
    setShouldOpenModal(true)

    onConfirmSendFunds(updatedCustomer, updatedSource)
  }

  if (isCustomerPendingVerification) {
    return <CustomerPendingVerificationStatus />
  }

  if (isSourceVerification) {
    return (
      <SourceVerificationButton
        title={LABEL_SEND_FUNDS}
        source={source}
        customer={customer}
        onSuccess={onSuccess}
        isModalOpen={shouldOpenModal}
      />
    )
  }

  if (isCustomerVerificationRequired) {
    const isVerifyCustomerModalOpen = isCustomerVerificationRequired && shouldOpenModal

    return (
      <Space>
        <Tooltip
          title={LABEL_VERIFY_CUSTOMER}
          placement="top"
        >
          <ExclamationCircleOutlined />
        </Tooltip>

        <CustomerVerificationButton
          title={LABEL_SEND_FUNDS}
          customer={customer}
          onSuccess={onSuccess}
          investment={investment}
          isModalOpen={isVerifyCustomerModalOpen}
        />
      </Space>
    )
  }

  if (isCustomerUpgradeRequired) {
    return (
      <CustomerUpgradeButton
        title={LABEL_SEND_FUNDS}
        customer={customer}
        onSuccess={onSuccess}
        investment={investment}
      />
    )
  }

  if (!isReadyToSendFunds) {
    return null
  }

  const onClick = () =>
    onConfirmSendFunds(customer, source)

  if (isMobile) {
    const style = { width: "100%" }

    return (
      <Button
        type="primary"
        size="large"
        shape="round"
        style={style}
        onClick={onClick}
      >
        {LABEL_SEND_FUNDS}
      </Button>
    )
  }

  return (
    <Typography.Link onClick={onClick}>
      {LABEL_SEND_FUNDS}
    </Typography.Link>
  )
}

SendFundsButton.propTypes = {
  project: IndexPublishedProjectsOutputShape.isRequired,
  customer: CustomerShape.isRequired,
  investment: InvestmentShape.isRequired,
}

export default SendFundsButton
