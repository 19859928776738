
const getProjectPhotos = (properties = []) => {
  let allPhotos = []

  for (const property of properties) {
    const { photos = [] } = property
    allPhotos = allPhotos.concat(photos)
  }

  return allPhotos.map(({ name, path, url, description }) => ({
    name,
    path,
    url,
    description
  }))
}

export default getProjectPhotos
