import React, { useState } from "react"
import PropTypes from "prop-types"

import InputExtra from "@components/Form/InputExtra"
import { getConfig } from "@components/Config"
import { TYPE_HIDDEN } from "@components/Form/FormItem"
import { UpdateModal } from "@components/Page"
import { MODAL_WIDTH2 } from "@components"
import { useAppContext } from "@components/AppContext"
import { confirmMyIdentityEmailOperation as operation } from "@api/services/identity"

const PLATFORM_NAME = getConfig("PLATFORM_NAME")

const LABEL_CODE = "Verification Code"
const LABEL_TITLE = "Email Verification"
const LABEL_ENTITY = "Email"
const LABEL_SUBMIT = "Submit"
const LABEL_SUCCESS = "Your email has been successfully changed"
const LABEL_EMAIL_EXTRA = `A six-digit verification code has been sent to
[EMAIL] by ${PLATFORM_NAME}. Please check your inbox or spam folder to locate
it.`


const ConfirmEmailModal = ({ modal }) => {
  const { authenticatedRequest: request, resetAuthorization } = useAppContext()

  const [ schema, setSchema ] = useState([])

  const onOpen = ({ email }) => {
    const newSchema = operation.getSchema([
      {
        name: "email",
        type: TYPE_HIDDEN,
      },
      {
        name: "accessToken",
        type: TYPE_HIDDEN,
      },
      {
        name: "code",
        label: LABEL_CODE,
        minLength: 6,
        maxLength: 6,
        inputProps: {
          style: { width: 180 },
        },
        itemProps: {
          extra: <InputExtra text={LABEL_EMAIL_EXTRA.replace('[EMAIL]', email)} />
        }
      },
    ])

    setSchema(newSchema)
  }

  const onSuccess = () =>
    resetAuthorization()

  const updateModalProps = {
    width: MODAL_WIDTH2,
    title: LABEL_TITLE,
    entity: LABEL_ENTITY,
    submitLabel: LABEL_SUBMIT,
    successMessage: LABEL_SUCCESS,
    modal,
    schema,
    onOpen,
    request,
    onSuccess,
    operation,
  }

  return (
    <UpdateModal {...updateModalProps} />
  )
}

ConfirmEmailModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default ConfirmEmailModal
