
const getSortedProperties = properties => {
  const propertiesWithEmptyInvestmentDate =
    properties
      .filter(({ investmentDate }) => investmentDate === "" || !investmentDate)
      .sort((a, b) => a.id < b.id ? 1 : -1)

  const propertiesWithInvestmentDate =
    properties
      .filter(({ investmentDate = "" }) => investmentDate !== "" || investmentDate)
      .sort((a, b) => {
        if (a.investmentDate === b.investmentDate) {
          return a.id < b.id ? 1 : -1
        }

        return a.investmentDate > b.investmentDate ? 1 : -1
      })

  const result = [
    ...propertiesWithInvestmentDate,
    ...propertiesWithEmptyInvestmentDate
  ]

  return result
}

export default getSortedProperties
