import ImgixClient from "@imgix/js-core"

import { getConfig } from "@components/Config"

const IMGIX_DOMAIN = getConfig("IMGIX_DOMAIN")
const S3_BUCKET_URL = getConfig("S3_BUCKET_URL")


const getImgixUrl = (url, options) => {
  const isImgixImage = url.includes('imgix.net')

  if (isImgixImage) {
    return url
  }

  const defaultOptions = {
    auto: 'compress',
  }

  const client = new ImgixClient({ domain: IMGIX_DOMAIN })
  const path = url.replace(S3_BUCKET_URL, '')
  const imgixUrl = client.buildURL(path, { ...options, ...defaultOptions })

  return imgixUrl
}

export default getImgixUrl
