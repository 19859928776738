import React, { useState } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import { Space, DatePicker, Typography } from "antd"

import { hasAccess } from "@components/Authorization"
import { getUsDateFromIsoDate } from "@components/Date"

dayjs.extend(quarterOfYear)

const { Text } = Typography
const { RangePicker } = DatePicker

const format = "MM/YYYY"
const today = new Date().toISOString()

const LABEL_THIS_YEAR = "This Year"
const LABEL_LAST_YEAR = "Last Year"
const LABEL_THIS_MONTH = "This Month"
const LABEL_LAST_MONTH = "Last Month"
const LABEL_THIS_QUARTER = "This Quarter"
const LABEL_LAST_QUARTER = "Last Quarter"


const getDateRange = (startDate, endDate) => {
  const start = startDate.startOf('month')
  const end = endDate.endOf('month')

  return [ start, end ]
}

const getPresetRange = (dates, unit) => {
  const [firstDay, secondDay] = dates

  return [
    firstDay.startOf(unit).startOf('day'),
    secondDay.endOf(unit).endOf('day')
  ]
}

const DEFAULT_RANGE = [
  dayjs(getUsDateFromIsoDate(today)).startOf('month'),
  dayjs(getUsDateFromIsoDate(today)).endOf('month')
]


const TransactionsTableHeader = ({
  table = undefined,
  title,
  updateDateRange,
}) => {
  const [ value, setValue ] = useState(DEFAULT_RANGE)

  const onChange = dateRange => {
    const [ startDate, endDate ] = dateRange

    const newValue = getDateRange(startDate, endDate)
    setValue(dateRange)

    const canApproveOrReject =
      hasAccess(['transactions-approve']) &&
      table

    if (canApproveOrReject) {
      table.current.setSelectedRowKeys([])
    }

    return updateDateRange(newValue)
  }

  const presets = [
    {
      label: LABEL_THIS_MONTH,
      value: () => getPresetRange([dayjs(), dayjs()], 'month')
    },
    {
      label: LABEL_LAST_MONTH,
      value: () => getPresetRange([dayjs().subtract(1, 'month'), dayjs().subtract(1, 'month')], 'month')
    },
    {
      label: LABEL_THIS_QUARTER,
      value: () => getPresetRange([dayjs(), dayjs()], 'quarter')
    },
    {
      label: LABEL_LAST_QUARTER,
      value: () => getPresetRange([dayjs().subtract(1, 'quarter'), dayjs().subtract(1, 'quarter')], 'quarter')
    },
    {
      label: LABEL_THIS_YEAR,
      value: () => getPresetRange([dayjs(), dayjs()], 'year')
    },
    {
      label: LABEL_LAST_YEAR,
      value: () => getPresetRange([dayjs().subtract(1, 'year'), dayjs().subtract(1, 'year')], 'year')
    },
  ]

  const rangePickerProps = {
    size: "small",
    style: { width: 230 },
    picker: "month",
    variant: "borderless",
    className: "month-picker",
    allowClear: false,
    placeholder: "",
    value,
    format,
    presets,
    onChange,
  }

  return (
    <Space size={0}>
      <Text>{title} for</Text>

      <RangePicker {...rangePickerProps} />
    </Space>
  )
}

TransactionsTableHeader.propTypes = {
  table: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  updateDateRange: PropTypes.func.isRequired,
}

export default TransactionsTableHeader
