import React from "react"
import PropTypes from "prop-types"

import { SelectOptionShape } from "@api"

import ListInput from "./ListInput"
import FormShape from "../shapes/FormShape"
import { TYPE_ENUM, TYPE_STRING, TYPE_HIDDEN } from "../FormItem"

const LABEL_ADD = "Add value"

const commonSchema = [
  {
    name: "group",
    type: TYPE_HIDDEN,
  },
  {
    name: "label",
    type: TYPE_ENUM,
    placeholder: "Select a label",
    hasAutoComplete: true,
    itemProps: { noStyle: true },
    inputProps: { style: { width: "50%" } },
  },
  {
    name: "value",
    type: TYPE_STRING,
    placeholder: "",
    itemProps: { noStyle: true },
    inputProps: { style: { width: "50%" } },
  },
]


const TagsListInput = ({
  initialValue = undefined,
  form,
  name,
  path,
  title,
  group,
  options,
}) => {
  const schema = [
    {
      ...commonSchema[0],
      initialValue: group
    },
    {
      ...commonSchema[1],
      options
    },
    {
      ...commonSchema[2]
    }
  ]

  const listInputProps = {
    addTitle: LABEL_ADD,
    className: "horizontal-oriented",
    form,
    name,
    path,
    title,
    schema,
    initialValue,
  }

  return (
    <ListInput {...listInputProps} />
  )
}

TagsListInput.propTypes = {
  form: FormShape.isRequired,
  name: PropTypes.arrayOf(PropTypes.string).isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(SelectOptionShape).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.any,
}

export const createTagsListInput = staticProps => {
  const {
    name,
    group,
    title,
    labels = []
  } = staticProps

  const options = labels.map(label => ({ label, value: label }))

  const initialValue = labels.map(label => ({ group, label, value: "" }))

  const CustomTagsListInput = props => {
    return (
      <TagsListInput
        options={options}
        initialValue={initialValue}
        {...staticProps}
        {...props}
      />
    )
  }

  CustomTagsListInput.group = group
  CustomTagsListInput.title = title
  CustomTagsListInput.fieldName = name

  return CustomTagsListInput
}

export default TagsListInput
