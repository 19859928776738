import capitalize from "lodash.capitalize"

export const JOINT_TENANTS = "JOINT_TENANTS"
export const SPOUSES = "SPOUSES"
export const SPOUSES_SURVIVORSHIP = "SPOUSES_SURVIVORSHIP"
export const TENANTS = "TENANTS"

const JointMembershipInterestsTitleEnum = {
  [JOINT_TENANTS]: JOINT_TENANTS,
  [SPOUSES]: SPOUSES,
  [SPOUSES_SURVIVORSHIP]: SPOUSES_SURVIVORSHIP,
  [TENANTS]: TENANTS
}

const JointMembershipInterestsTitleEnumKeys = Object.keys(JointMembershipInterestsTitleEnum)

const JointMembershipInterestsTitleEnumOptions = JointMembershipInterestsTitleEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  JointMembershipInterestsTitleEnumKeys,
  JointMembershipInterestsTitleEnumOptions
}

export default JointMembershipInterestsTitleEnum
