import dayjs from "dayjs"

export const US_DATE_FORMAT = "MM/DD/YYYY"


export const getFormattedIsoDateTime = isoDateTime =>
  isoDateTime
    ? dayjs(isoDateTime).format('MM/DD/YYYY HH:mm:ss')
    : 'Never'


export const getIsoDateFromDate = date => {
  const [ month, day, year ] = getDateParamsFromDate(date)

  return [ year, month, day ].join("-")
}


const getDateParamsFromDate = date => {
  let day = date.getDate()

  if (day < 10) {
    day = `0${day}`
  }

  let month = date.getMonth() + 1

  if (month < 10) {
    month = `0${month}`
  }

  const year = date.getFullYear()

  return [ month, day, year ]
}


export const getUsDateFromDate = isoDate => {
  if (!isoDate) {
    return ""
  }

  const date = new Date(isoDate)

  const [ month, day, year ] = getDateParamsFromDate(date)

  return [ month, day, year ].join("/")
}


export const getUsDateFromIsoDate = isoDate => {
  const date = new Date(isoDate)

  return getUsDateFromDate(date)
}


export const getIsoDateFromStringDate = (date, shouldIncludeTime = false) => {
  const dateTime = new Date(date)

  if (shouldIncludeTime) {
    return dateTime.toISOString()
  }

  const [ month, day, year ] = getDateParamsFromDate(dateTime)

  return [ year, month, day ].join("-")
}


export const today = () =>
  dayjs().format(US_DATE_FORMAT)
