import React from "react"

import {
  PROJECT_STATES,
  INVESTMENT_STATUS_REJECTED,
} from "@components/Domain"

import InvestmentUpdateTab from "../InvestmentUpdateTab"
import InvestmentCreateTab from "../InvestmentCreateTab"

const LABEL_PROFILE = "Profile"

export const CREATE_KEY = "create"

const getItems = ({
  project,
  navigate,
  activeKey,
  investments,
  currentForm,
  isCreateAction,
  deleteInvestment,
}) => {
  const { id: projectId } = project

  const hasInvestments = investments.length > 0

  const openCreateTab = async () => {
    window.scrollTo({ top: 0 })

    navigate(`/investments/${projectId}/create`)
  }

  const onCreateCancel = () => {
    navigate(`/investments/${projectId}/update`)
  }

  const onDelete = async investmentId => {
    const activeInvestment = investments
      .find(investment => investment.id !== investmentId)

    deleteInvestment(investmentId)

    if (!activeInvestment) {
      return openCreateTab()
    }

    const { id } = activeInvestment
    navigate(`/investments/${projectId}/update/${id}`)
  }

  const getInvestmentLabel = (investment, index) => {
    const label = `${LABEL_PROFILE} ${index + 1}`

    const isRejected = isInvestmentDeclined(investment)

    const investmentLabel = isRejected
      ? `${label} (Declined)`
      : label

    return investmentLabel
  }

  const isInvestmentDeclined = ({ status }) =>
    status === INVESTMENT_STATUS_REJECTED

  const isInvestmentDisabled = investment => {
    const { state: projectState } = project

    const isProjectClosed = projectState === PROJECT_STATES.CLOSED

    if (isProjectClosed) {
      return true
    }

    return isInvestmentDeclined(investment)
  }

  const getNextInvestmentId = key => {
    const currentInvestmentIndex = investments
      .findIndex(({ id }) => id === key)

    const nextInvestmentIndex = currentInvestmentIndex + 1
    const nextInvestment = investments[nextInvestmentIndex] || {}

    const { id } = nextInvestment

    return id
  }

  const isLastInvestment = key => !getNextInvestmentId(key)

  const currentFormKey = activeKey || investments[0]?.id

  const isUpdateDeletable = hasInvestments

  const items = investments
    .map((investment, index) => (
      {
        key: investment.id,
        label: getInvestmentLabel(investment, index),
        disabled: isInvestmentDisabled(investment),
        children: (
          <InvestmentUpdateTab
            index={index + 1}
            onDelete={onDelete}
            investment={investment}
            isDeletable={isUpdateDeletable}
            currentForm={currentFormKey === investment.id ? currentForm : undefined}
            openCreateTab={openCreateTab}
            nextInvestmentId={getNextInvestmentId(investment.id)}
            isLastInvestment={isLastInvestment(investment.id)}
          />
        )
      }
    ))

  if (isCreateAction) {
    const investmentsCount = investments.length
    const isCreateDeletable = hasInvestments

    items.push({
      key: CREATE_KEY,
      label: `${LABEL_PROFILE} ${investmentsCount + 1}`,
      children: (
        <InvestmentCreateTab
          index={investmentsCount + 1}
          onCancel={onCreateCancel}
          isDeletable={isCreateDeletable}
          currentForm={currentForm}
          openCreateTab={openCreateTab}
        />
      )
    })
  }

  return items
}

export default getItems
