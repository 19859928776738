import React from "react"
import PropTypes from "prop-types"

import { TableRowActions } from "@components/Table"
import { CreateBankAccountOutputShape } from "@api/services/transactions"

import useRowMenu from "./helpers/useRowMenu"
import useAction from "./helpers/useAction"

const RowActions = ({
  bankAccount,
  removeBankAccount
}) => {
  const openDeleteConfirm = useAction(bankAccount, removeBankAccount)
  const items = useRowMenu(bankAccount, { openDeleteConfirm })

  return (
    <TableRowActions items={items} />
  )
}

RowActions.propTypes = {
  bankAccount: CreateBankAccountOutputShape.isRequired,
  removeBankAccount: PropTypes.func.isRequired,
}

export default RowActions
