import React, { useRef, useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"


const EmailPreview = ({
  html = "",
  style = undefined,
  isMobile = false
}) => {
  const iframe = useRef({})

  const [ height, setHeight ] = useState(0)

  const updateContent = useCallback(async () => {
    const app = iframe.current?.contentWindow?.app

    if (!app) {
      return
    }

    const options = {}

    if (style) {
      options.style = style
    }

    const updatedHeight = await app.setContent(html, options)
    setHeight(updatedHeight)
  }, [ style, html ])

  useEffect(() => {
    updateContent()
  }, [ updateContent ])

  const onLoad = () =>
    updateContent()

  const width = isMobile
    ? "420px"
    : "100%"

  const iframeProps = {
    src: "/static/preview.html",
    height: `${height}px`,
    frameBorder: "0",
    width,
    onLoad,
  }

  return (
    <div>
      <iframe
        ref={iframe}
        title="preview"
        {...iframeProps}
      />
    </div>
  )
}

EmailPreview.propTypes = {
  html: PropTypes.string,
  style: PropTypes.shape(),
  isMobile: PropTypes.bool,
}

export default EmailPreview
