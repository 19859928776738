import PropTypes from "prop-types"

import AssetShape from "./AssetShape"
import TagShape from "./TagShape"
import UsAddressShape from "./UsAddressShape"
import { PropertyStatusEnumKeys } from "./PropertyStatusEnum"

const properties = {
  address: UsAddressShape.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  equityDeployedAmount: PropTypes.number,
  id: PropTypes.string.isRequired,
  investmentDate: PropTypes.string,
  name: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(AssetShape),
  projectId: PropTypes.string,
  status: PropTypes.oneOf(PropertyStatusEnumKeys),
  tags: PropTypes.arrayOf(TagShape),
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const PropertyShape = PropTypes.exact(properties)

export default PropertyShape
export { properties }
