import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Form } from "antd"
import { Auth } from "aws-amplify"

import { Modal } from "@components/Page"
import FormItem from "@components/Form/FormItem"
import { useForm } from "@components/Form"
import { useAppContext } from "@components/AppContext"
import { W0, W1, MODAL_WIDTH2 } from "@components"

import getInputs from "./helpers/getInputs"

const LABEL_TITLE = "Change Password"
const LABEL_SUBMIT = "Change"
const LABEL_SUCCESS = "Password changed successfully"

const ERROR_INVALID_CURRENT_PASSWORD = "Invalid password"


const ChangePasswordModal = ({ modal }) => {
  const form = useForm()

  const {
    showErrorMessage,
    showSuccessMessage,
  } = useAppContext()

  const [ isOpen, setIsOpen ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)

  const inputs = getInputs(form)

  modal.current.open = () =>
    setIsOpen(true)

  const onClose = () => {
    form.resetFields()
    setIsOpen(false)
    setIsLoading(false)
  }

  const onFinish = async ({ currentPassword, newPassword }) => {
    setIsLoading(true)

    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, currentPassword, newPassword)

    } catch (error) {
      let { message } = error
      const isInvalidCurrentPassword = error.message === "Incorrect username or password."

      if (isInvalidCurrentPassword) {
        message = ERROR_INVALID_CURRENT_PASSWORD
      }

      showErrorMessage(message)
      setIsLoading(false)

      return
    }

    showSuccessMessage(LABEL_SUCCESS)
    onClose()
  }

  return (
    <Modal
      modal={modal}
      title={LABEL_TITLE}
      width={MODAL_WIDTH2}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        scrollToFirstError={true}
      >
        {
          inputs.map((input, index) =>
            <FormItem
              key={index}
              {...input}
              form={form}
              path={[ input.name ]}
            />
          )
        }

        <Form.Item
          style={{ marginTop: W1, marginBottom: W0 }}
        >
          <Button
            type="primary"
            size="large"
            shape="round"
            style={{ width: "100%" }}
            loading={isLoading}
            htmlType="submit"
          >
            {LABEL_SUBMIT}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

ChangePasswordModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default ChangePasswordModal
