import { useState, useCallback } from "react"

import { useAppContext } from "@components/AppContext"

import getPersonalInputs from "../../../../UploadDocumentsModal/helpers/getPersonalInputs"


const useInputs = (form) => {
  const [ schema, setSchema ] = useState([])

  const { showErrorMessage } = useAppContext()

  const updateSchema = useCallback(
    beneficialOwner => {
      const updatedSchema = getPersonalInputs(beneficialOwner, form, showErrorMessage)
      setSchema(updatedSchema)
    }
  , [ form, showErrorMessage ])

  return [ schema, updateSchema ]
}

export default useInputs
