import PropTypes from "prop-types"


const properties = {
  distributionsAmount: PropTypes.number,
  investmentId: PropTypes.string,
  projectId: PropTypes.string,
  year: PropTypes.number
}

const InvestmentTransactionReportShape = PropTypes.exact(properties)

export default InvestmentTransactionReportShape
export { properties }
