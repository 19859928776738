import { useMemo } from "react"

import { useStore } from "@components/Store"
import { useAppContext } from "@components/AppContext"
import { listMyCustomerBeneficialOwnersOperation as indexOperation } from "@api/services/transactions"


const useBeneficialOwnersStore = (customerId, shouldAutoLoad) => {
  const { authenticatedRequest } = useAppContext()

  const indexParameters = useMemo(() =>
    ({ customerId })
  , [ customerId ])

  const {
    items: beneficialOwners,
    indexItems: indexBeneficialOwners,
  } = useStore(indexOperation, authenticatedRequest, shouldAutoLoad, indexParameters)

  return {
    beneficialOwners,
    indexBeneficialOwners,
  }
}

export default useBeneficialOwnersStore
