import { Storage } from "../authenticate"


const authorize = async ({
  setIdentity,
  commitmentId,
  authentication,
  organizationId,
  authorizationMethod,
  onAuthorizationError,
}) =>
  authorizationMethod(authentication, organizationId, commitmentId)
    .then(([ authorization, identity ]) => {
      Storage.setAuthorization(authorization)
      setIdentity(identity)
    })
    .catch(onAuthorizationError)

export default authorize
