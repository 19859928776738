import { TIER_NAMES } from "@components/Domain"

import ProjectDealTags from "./ProjectDealTags"


const extendTiers = tiers =>
  tiers
    .map(({ description, isPrivate, title }, index) => {
      const name = TIER_NAMES[index]

      const minimumAmount = 100
      const returnPercentage = 1
      const upsideBrokerPercentage = 1

      return {
        name,
        title,
        isPrivate,
        description,
        minimumAmount,
        returnPercentage,
        upsideBrokerPercentage
      }
    })


const onProjectSubmit = (parameters, project, hasMultipleSponsors) => {
  const { mutation, ...parametersRest } = parameters
  const {
    targetAmount: projectTargetAmount,
    nonAccreditedInvestorsMax: projectNonAccreditedInvestorsMax,
  } = project

  const { _dealTags, tiers, ...mutationWithoutTags } = mutation
  const { targetAmount, nonAccreditedInvestorsMax } = mutation

  if (!project) {
    project = { _dealTags }
  }

  const updatedParameters = {
    ...parametersRest,
    mutation: mutationWithoutTags
  }

  if (_dealTags) {
    project[ProjectDealTags.fieldName] = _dealTags
  }

  if (_dealTags) {
    updatedParameters.mutation.tags = [
      ...(project[ProjectDealTags.fieldName] || [])
    ]
  }

  if (tiers) {
    updatedParameters.mutation.tiers = extendTiers(tiers)
  }

  if (hasMultipleSponsors) {
    return updatedParameters
  }

  /* note: Always send two parameters below, backend should be refactored to
           get rid of this workaround. */

  if (targetAmount === undefined) {
    updatedParameters.mutation.targetAmount = projectTargetAmount
  }

  if (nonAccreditedInvestorsMax === undefined) {
    updatedParameters.mutation.nonAccreditedInvestorsMax = projectNonAccreditedInvestorsMax
  }

  return updatedParameters
}

export default onProjectSubmit
