import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

import { NotificationDrawer } from "@modules/backstage/notifications"

import NotificationsTable from "./NotificationsTable"

const LABEL_TITLE = "Notifications"


const Notifications = ({ recipientId }) => {
  const notificationDrawer = useRef({})

  const openNotificationDrawer = notification =>
    notificationDrawer.current.open(notification)

  return (
    <>
      <Typography.Title level={5} style={{ marginTop: 0 }}>
        {LABEL_TITLE}
      </Typography.Title>

      <NotificationsTable
        recipientId={recipientId}
        openNotificationDrawer={openNotificationDrawer}
      />

      <NotificationDrawer drawer={notificationDrawer} />
    </>
  )
}

Notifications.propTypes = {
  recipientId: PropTypes.string.isRequired,
}

export default Notifications
