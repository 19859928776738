import PropTypes from "prop-types"


const SelectExtraOptionsShape = PropTypes.exact({
  id: PropTypes.oneOfType([ PropTypes.string, PropTypes.shape() ]).isRequired,
  name: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
  disabled: PropTypes.bool,
})

export default SelectExtraOptionsShape
