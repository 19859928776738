import { getCurrencyValue } from "@components/Amount"

const REGEX = /\{(\w+):([^}]+)\}/g

const OPTION_GROUP_MAP = {
  SEGMENT: 'investor list',
  ACCOUNT_TAG: 'investor tag',
  DOCUMENT_TYPE: 'investor document type',
  SUBSCRIPTION_TYPE: 'subscription type',
  BATCH_DOCUMENT_TYPE: 'project document type',
}

const render = (context, key, value) => {
  const undefinedValue = `{${key}:${value}}`

  const {
    getOption,
    getProject,
    organizations,
    computeUserFullname,
    computeAccountFullname
  } = context

  const isUser = key === 'user'
  const isValue = key === 'value'
  const isAmount = key === 'amount'
  const isToggle = key === 'toggle'
  const isProject = key === 'project'
  const isInvestor = key === 'investor'
  const isForProject = key === 'forProject'
  const isOptionGroup = key === 'optionGroup'
  const isSubscription = key === 'subscription'
  const isOrganization = key === 'organization'
  const isEnabledConfirmed = key === 'enabledConfirmed'

  if (isUser) {
    return computeUserFullname(value) || undefinedValue
  }

  if (isValue) {
    return value
  }

  if (isAmount) {
    return getCurrencyValue(value)
  }

  if (isToggle) {
    return value === "true" ? "Enabled" : "Disabled"
  }

  if (isEnabledConfirmed) {
    return value === "undefined" ? "Enabled" : "Confirmed"
  }

  if (isProject) {
    const project = getProject(value)
    return project?.name || undefinedValue
  }

  if (isInvestor) {
    return computeAccountFullname(value) || undefinedValue
  }

  if (isForProject) {
    if (value === "undefined") {
      return ""
    }

    const project = getProject(value)

    if (!project) {
      return undefinedValue
    }

    return `for ${project.name}`
  }

  if (isOptionGroup) {
    return OPTION_GROUP_MAP[value] || undefinedValue
  }

  if (isSubscription) {
    return getOption(value).name
  }

  if (isOrganization) {
    return (organizations || []).find(organization => organization.id === value)?.name || value
  }

  return undefinedValue
}

const renderTemplate = (context, template) => {
  let result = `${template}`

  const variablesMap = {}

  let match
  while ((match = REGEX.exec(result)) !== null) {
    const [ substring, key, value ] = match
    const renderedValue = render(context, key, value)

    variablesMap[substring] = renderedValue
  }

  for (const key of Object.keys(variablesMap)) {
    result = result.replace(key, variablesMap[key])
  }

  return result
}

export default renderTemplate
