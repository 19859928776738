import React, { useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import { Space, Typography } from "antd"
import { green, blue } from "@ant-design/colors"
import { CheckCircleOutlined, HourglassOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"
import { runRequest } from "@components/AppContext"
import { ReadMyOrganizationOutputShape } from "@api/services/organizations"

const { Text } = Typography


const OrganizationEmail = ({ organization }) => {
  const { updateOrganizationEmailVerificationStatus } = useOutletContext()

  const { email, isEmailVerified } = organization

  const canUpdateOrganization = hasAccess(['org-write'])

  useEffect(() => {
    if (!canUpdateOrganization) {
      return
    }

    if (isEmailVerified) {
      return
    }

    return runRequest(() => updateOrganizationEmailVerificationStatus())
  }, [
    isEmailVerified,
    canUpdateOrganization,
    updateOrganizationEmailVerificationStatus
  ])

  const icon = isEmailVerified
    ? <CheckCircleOutlined style={{ color: green.primary }} />
    : <HourglassOutlined style={{ color: blue.primary }} />

  return (
    <Space>
      <Text>
        {email}
      </Text>

      {icon}
    </Space>
  )
}

OrganizationEmail.propTypes = {
  organization: ReadMyOrganizationOutputShape.isRequired,
}

export default OrganizationEmail
