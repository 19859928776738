import React from "react"

import {
  TYPE_SSN,
  TYPE_DATE,
  TYPE_OBJECT,
} from "@components/Form"
import { validateDob } from "@components/Date"
import { MarkdownAlert } from "@components/Text"
import { getUsAddressSchema, getDwollaAddressSchema } from "@components/Address"

const LABEL_SSN = "Social Security Number"
const LABEL_DOB = "Date of Birth"
const LABEL_ADDRESS = "Physical Address"
const LABEL_LAST_NAME = "Legal Last Name"
const LABEL_FIRST_NAME = "Legal First Name"

const LABEL_RETRY = `Identity verification has failed.

Please ensure the accuracy of the submitted information and click "Continue".`

const LABEL_DESCLAIMER = `PO Box, shipping point, or mail receiving agent /
registered agent addresses are unable to be accepted for account verification
purposes.`


const getInputs = (isRetry) => {
  const retryInput = {
    name: "_retry",
    component: () =>
      <MarkdownAlert
        type="warning"
        text={LABEL_RETRY}
      />
  }

  const inputs = isRetry
    ? [ retryInput ]
    : []

  const addressSchema = getUsAddressSchema()

  inputs.push(
    {
      name: "personalCustomer",
      type: TYPE_OBJECT,
      label: "",
      schema: [
        {
          name: "firstName",
          label: LABEL_FIRST_NAME,
          required: true,
          maxLength: 50,
        },
        {
          name: "lastName",
          label: LABEL_LAST_NAME,
          required: true,
          maxLength: 50,
        },
        {
          name: "dateOfBirth",
          type: TYPE_DATE,
          label: LABEL_DOB,
          required: true,
          itemProps: {
            rules: [validateDob()]
          }
        },
        {
          name: "ssn",
          type: TYPE_SSN,
          label: LABEL_SSN,
          required: true,
        },
        {
          name: "_disclaimer",
          component: () =>
            <MarkdownAlert
              type="warning"
              text={LABEL_DESCLAIMER}
            />
        },
        {
          name: "address",
          type: TYPE_OBJECT,
          label: LABEL_ADDRESS,
          schema: getDwollaAddressSchema(addressSchema),
        }
      ]
    },
  )

  return inputs
}

export default getInputs
