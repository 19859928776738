import { App } from "antd"
import { useOutletContext } from "react-router-dom"

import { confirmAction } from "@components/Page"
import { getFormattedDateTimeWithTimezone } from "@components/Date"

const DEFAULT = "DEFAULT"
const SILENT = "SILENT"

const ACTIONS_MAP = {
  [DEFAULT]: {
    type: "primary",
    title: "Upload Documents",
    action: "Upload",
    content: `By clicking "upload" below, documents will be uploaded to the
investor portal. Investors WILL be notified via email at the scheduled time [SCHEDULED_AT].`,
    templateDisabledContent: `By clicking 'Upload' below, documents will be
 uploaded to the investor portal. Investors will NOT be notified via email as
 email notifications are currently turned off.`
  },
  [SILENT]: {
    type: "primary",
    title: "Upload Documents",
    action: "Upload",
    content: `By clicking "upload" below, documents will be uploaded to the portal. Investors WILL NOT be notified.`,
  },
}


const useBatchActions = (form, collection, onConfirm) => {
  const { modal } = App.useApp()
  const { projectTemplatesStore } = useOutletContext()

  const { isProjectFileCreatedTemplateEnabled } = projectTemplatesStore

  const action = (actionName, formattedScheduledAt) => {
    const {
      templateDisabledContent,
      content: templateEnabledContent
    } = ACTIONS_MAP[actionName]

    let content = templateEnabledContent.replace('[SCHEDULED_AT]', formattedScheduledAt)

    const isDefaultAction = actionName === DEFAULT

    if (isDefaultAction) {
      content = isDefaultAction && isProjectFileCreatedTemplateEnabled()
        ? content
        : templateDisabledContent
    }

    return confirmAction(
      modal,
      ACTIONS_MAP[actionName].action,
      ACTIONS_MAP[actionName].title,
      content,
      () => { onConfirm() },
      ACTIONS_MAP[actionName].type,
    )
  }

  const upload = () => {
    const { isSent, scheduledAt } = form.getFieldsValue([ 'isSent', 'scheduledAt' ])
    const formattedScheduledAt = getFormattedDateTimeWithTimezone(scheduledAt)

    return isSent
      ? action(SILENT)
      : action(DEFAULT, formattedScheduledAt)
  }

  return [ upload ]
}

export default useBatchActions
