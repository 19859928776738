import { getCustomUploadRequest } from "@components/Storage"


const uploadInvestorFiles = async ({
  files,
  items,
  request,
  setProgress,
  showErrorMessage
}) => {
  const upload = getCustomUploadRequest(request, false)

  const mappedItems = items
    .filter(item => !!item.sourceFileName)

  let index = 1
  const operationsCount = mappedItems.length

  const investorFiles = []

  for (const item of mappedItems) {
    const {
      id: investmentId,
      sourceFileName,
      investorAccountId
    } = item

    const onSuccess = ({ url }) =>
      investorFiles.push({
        url,
        investmentId,
        sourceFileName,
        investorAccountId,
      })

    const onError = error => showErrorMessage(error.message)

    const file = files.find(fileItem => fileItem.name === sourceFileName)
    await upload({ file, onSuccess, onError })

    setProgress(Math.ceil(index * 100 / operationsCount))
    index++
  }

  return investorFiles
}

export default uploadInvestorFiles
