import React from "react"
import PropTypes from "prop-types"
import { Descriptions } from "antd"

import { ReadBatchOutputShape } from "@api/services/backstage"

import useItems from "./helpers/useItems"


const BatchDescription = ({ batch, isUrlSigned }) => {
  const items = useItems(batch, isUrlSigned)

  return (
    <Descriptions
      size="small"
      column={1}
    >
      {
        items.map(({ label, value }, key) =>
          <Descriptions.Item
            key={key}
            label={label}
          >
            {value}
          </Descriptions.Item>
        )
      }
    </Descriptions>
  )
}
BatchDescription.propTypes = {
  batch: ReadBatchOutputShape.isRequired,
  isUrlSigned: PropTypes.bool.isRequired,
}

export default BatchDescription
