import React from "react"
import PropTypes from "prop-types"
import { Button, Form, Card, Space, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import TierShape from "@api/services/backstage/shapes/TierShape"
import { extendSchema } from "@api"
import { W1, isMobile } from "@components"
import PublicBrokerShape from "@api/services/backstage/shapes/PublicBrokerShape"
import { COMMITMENT_PROFILES_MAX_COUNT } from "@components/Domain"
import { createCommitmentOperation as operation } from "@api/services/investments"
import { InvestmentsInput, renderProfileTypeOptions } from "@components/Investment"
import { renderFormItems, FormShape, TYPE_ENUM, TYPE_RADIO, TYPE_ARRAY } from "@components/Form"

import CommitmentProfileShape from "./shapes/CommitmentProfileShape"

const { Text } = Typography

const LABEL_ADD = "Add Another Investment Profile"
const LABEL_TITLE = "Investment Profile"
const LABEL_CANCEL = "Cancel"

const { schema } = operation
  .getSchema()
  .find(input => input.name === 'profiles')


const ProfilesListInput = ({
  form,
  name,
  path,
  tiers,
  brokers,
  initialValue,
}) => {
  const brokerOptions = brokers
    .map(({ id: value, givenName, familyName }) =>
      ({ value, label: `${givenName} ${familyName}`})
    )

  const renderListFormItems = item => {
    const isFirstItem = item.key === 0

    const investmentsInput = {
      name: "investments",
      type: TYPE_ARRAY,
      component: InvestmentsInput,
      componentProps: {
        tiers,
        itemProps: {
          style: { marginBottom: 0 },
        },
      },
    }

    const brokerInput = {
      name: "brokerUserId",
      type: TYPE_ENUM,
      label: "Who is your investor liaison?",
      placeholder: "Select your liaison",
      options: brokerOptions,
      required: true
    }

    const typeInput = {
      name: "type",
      type: TYPE_RADIO,
      label: "How do you like to invest?",
      options: renderProfileTypeOptions(),
      itemProps: {
        disabled: true
      }
    }

    const listFormSchema = isFirstItem
      ? extendSchema(schema, [ brokerInput, typeInput, investmentsInput ])
      : extendSchema(schema, [ typeInput, investmentsInput ])

    return renderFormItems(form, listFormSchema, [ item.name ], [ ...path, item.name ])
  }

  const isDisabledAddButton = items =>
    items.length === COMMITMENT_PROFILES_MAX_COUNT

  const renderCancelButton = (remove, item) => {
    const isFirstItem = item.key === 0

    if (isFirstItem) {
      return null
    }

    return (
      <Typography.Link onClick={() => remove(item.name)}>
        {LABEL_CANCEL}
      </Typography.Link>
    )
  }

  return (
    <Form.List
      name={name}
      initialValue={initialValue}
    >
      {
        (items, { add, remove }) => (
          <Space
            size={W1}
            style={{ width: "100%" }}
            direction="vertical"
          >
            {
              items.map(item => (
                <Card
                  key={item.key}
                  type="inner"
                  size={ isMobile ? "small" : "medium" }
                  title={`${LABEL_TITLE} ${item.key + 1}`}
                  extra={renderCancelButton(remove, item)}
                >
                  {renderListFormItems(item)}
                </Card>
              ))
            }

            <Form.Item>
              <Button
                block
                size="large"
                type="dashed"
                icon={<PlusOutlined />}
                onClick={() => add()}
                disabled={isDisabledAddButton(items)}
              >
                <Text strong>{LABEL_ADD}</Text>
              </Button>
            </Form.Item>
          </Space>
        )
      }
    </Form.List>
  )
}

ProfilesListInput.propTypes = {
  form: FormShape.isRequired,
  name: PropTypes.arrayOf(PropTypes.string).isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  tiers: PropTypes.arrayOf(TierShape).isRequired,
  brokers: PropTypes.arrayOf(PublicBrokerShape).isRequired,
  initialValue: PropTypes.arrayOf(CommitmentProfileShape).isRequired,
}

export default ProfilesListInput
