import React from "react"
import PropTypes from "prop-types"
import { Pie } from "@ant-design/plots"

import getPieChartConfig from "./helpers/getPieChartConfig"


const PieChart = ({ data }) => {
  const config = getPieChartConfig(data)

  return (
    <Pie {...config} />
  )
}

PieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    countValue: PropTypes.number.isRequired,
  })).isRequired,
}

export default PieChart
