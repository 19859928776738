
const ERROR_MAX_FILE_SIZE = "The document size is restricted to 10 megabytes"


const validateFileSize = (file, showErrorMessage) => {
  const isLessThen10Megabytes = file.size / 1024 / 1024 <= 10

  if (isLessThen10Megabytes) {
    return true
  }

  showErrorMessage(ERROR_MAX_FILE_SIZE)
  return false
}

export default validateFileSize
