import { wait } from "./wait"


const requestBatch = async (
  setProgress,
  parametersList,
  operationRequest,
  onError = () => ({})
) => {
  let operationCount = 1
  const totalRequestsCount = parametersList.length + 1

  const result = []

  for (const { _rowIndex = operationCount, parameters } of parametersList) {
    const { data } = await operationRequest(parameters)
      // eslint-disable-next-line no-loop-func
      .catch(({ originalError }) => {
        onError(_rowIndex, parameters, originalError)

        result.push(originalError)
        return {}
      })

    if (data) {
      result.push(data)
    }

    setProgress(Math.ceil(operationCount * 100 / totalRequestsCount))
    operationCount++

    await wait(true)
  }

  return result
}

export default requestBatch
