import { ColorEnumKeys } from "./shapes/ColorEnum"
import { OptionGroupEnumKeys } from "./shapes/OptionGroupEnum"

const IndexOptionsItemSchema = {
  color: { enum: ColorEnumKeys },
  order: { type: 'number' },
  group: { enum: OptionGroupEnumKeys },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexOptionsItemSchema
