import React from "react"
import { Space } from "antd"

import { W0 } from "@components"

import renderTag from "./renderTag"
import { LABELS_MAP } from "./computeInvestmentFundingStatus"
import getFundedColor from "./getFundedColor"


const renderFunded = value => {
  const label = LABELS_MAP[value]
  const color = getFundedColor(value)

  return  <Space size={W0 / 2}>
    {renderTag(label, color)}
  </Space>
}

export default renderFunded
