import React  from "react"
import PropTypes from "prop-types"
import { Form, Input } from "antd"

import { ItemPropsShape, InputPropsShape } from "@api"

import FormShape from "../shapes/FormShape"

const ERROR_INVALID_FORMAT = "Invalid value"


const ZipCodeInput = ({
  form,
  path,
  itemProps,
  inputProps,
}) => {
  const { name, rules: itemRules, ...restItemProps } = itemProps

  const rules = [{
    min: 5,
    message: ERROR_INVALID_FORMAT
  }, ...itemRules]

  const onChange = event => {
    form.setFieldValue(path, event.target.value.replace(/\D/g, ''))
  }

  return (
    <Form.Item
      key="input"
      name={name}
      rules={rules}
      {...restItemProps}
    >
      <Input
        style={{ width: 180 }}
        {...inputProps}
        minLength={5}
        maxLength={5}
        onChange={onChange}
      />
    </Form.Item>
  )
}

ZipCodeInput.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemProps: ItemPropsShape.isRequired,
  inputProps: InputPropsShape.isRequired,
}

export default ZipCodeInput
