import { TYPE_NONE } from "@components/Form"

const LABEL_PLACEHOLDER = "Name for the list..."


const getCreateInputs = () => {
  return [
    {
      name: "name",
      placeholder: LABEL_PLACEHOLDER
    },
    {
      name: "description",
      type: TYPE_NONE
    },
    {
      name: "color",
      type: TYPE_NONE
    },
    {
      name: "order",
      type: TYPE_NONE
    },
    {
      name: "group",
      type: TYPE_NONE
    }
  ]
}

export default getCreateInputs
