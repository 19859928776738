import React, { useRef } from "react"
import { Row, Col, Layout, theme } from "antd"
import { useParams, useOutletContext } from "react-router-dom"

import { InvestmentHeader, ResourceNotFoundRedirect } from "@components/Page"
import { W1, W3, isMobile } from "@components"

import Steps from "./Steps"
import Header from "./Header"
import useInvestmentHeaderProps from "./helpers/useInvestmentHeaderProps"
import InvestorInvestmentLayoutOutlet from "./InvestorInvestmentLayoutOutlet"

const InvestorInvestmentLayout = () => {
  const currentForm = useRef({})

  const { token } = theme.useToken()
  const { colorBgLayout } = token

  const outletContext = useOutletContext()
  const { getProject } = outletContext

  const { projectId } = useParams()
  const project = getProject(projectId)

  const investmentHeaderProps = useInvestmentHeaderProps(project)

  if (!project) {
    return (<ResourceNotFoundRedirect />)
  }

  const headerStyle = {
    top: 0,
    width: "100%",
    zIndex: 1,
    padding: 0,
    position: "sticky",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    backgroundColor: colorBgLayout,
  }

  const contentStyle = {
    padding: W1,
    paddingTop: isMobile ? W3 : W1,
  }

  if (!isMobile) {
    contentStyle.minHeight = "60vh"
  }

  const context = {
    project,
    currentForm,
    ...outletContext,
  }

  return (
    <>
      <Layout.Header style={headerStyle}>
        <Header currentForm={currentForm} />
      </Layout.Header>

      <Layout.Content style={contentStyle}>
        <Steps />

        <Row justify="center">
          <Col style={{ maxWidth: 520 }}>
            <InvestmentHeader {...investmentHeaderProps} />

            <InvestorInvestmentLayoutOutlet
              context={context}
            />
          </Col>
        </Row>
      </Layout.Content>
    </>
  )
}

export default InvestorInvestmentLayout
