import React from "react"

import { InvestmentShape } from "@components/Investment/shapes"
import { INVESTMENT_PROFILE_TYPE_ENTITY } from "@components/Domain"

import BusinessCustomerUpgradeButton from "./BusinessCustomerUpgradeButton"
import PersonalCustomerUpgradeButton from "./PersonalCustomerUpgradeButton"


const CustomerUpgradeButton = ({ investment, ...otherProps }) => {
  const { profileType } = investment

  const isBusiness = profileType === INVESTMENT_PROFILE_TYPE_ENTITY

  if (isBusiness) {
    return (
      <BusinessCustomerUpgradeButton {...otherProps} investment={investment} />
    )
  }

  return (
    <PersonalCustomerUpgradeButton {...otherProps} investment={investment} />
  )
}

CustomerUpgradeButton.propTypes = {
  investment: InvestmentShape.isRequired,
}

export default CustomerUpgradeButton
