import React, { useEffect, useCallback } from "react"
import { Link, useLocation } from "react-router-dom"
import {
  RiseOutlined,
  SwapOutlined,
  UserOutlined,
  SafetyCertificateOutlined
} from "@ant-design/icons"

import { Bold } from "@components/Text"


const useMenuItems = (setSelectedKeys) => {
  const location = useLocation()

  const onLocationChange = useCallback(() => {
    const [ , selectedKey ] = window.location.pathname.split(`/`)
    const selectedKeys = [ `/${selectedKey}` ]

    setSelectedKeys(selectedKeys)
  }, [ setSelectedKeys ])

  useEffect(() => {
    onLocationChange()
  }, [ location, onLocationChange ])

  const items = [
    {
      key: "/dashboard",
      icon: <RiseOutlined />,
      label:
        <Link to="/dashboard">
          <Bold>Dashboard</Bold>
        </Link>,
    },
    {
      key: "/transactions",
      icon: <SwapOutlined />,
      label:
        <Link to="/transactions">
          <Bold>Transactions</Bold>
        </Link>
    },
    {
      key: "/documents",
      icon: <SafetyCertificateOutlined />,
      label:
        <Link to="/documents">
          <Bold>Documents</Bold>
        </Link>
    },
    {
      key: "/account",
      icon: <UserOutlined />,
      label:
        <Link to="/account">
          <Bold>My Account</Bold>
        </Link>
    },
  ]

  return items
}

export default useMenuItems
