import React from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import { CopyOutlined } from "@ant-design/icons"

import { useAppContext } from "@components/AppContext"

const LABEL_COPIED = "Value copied to the clipboard"


const CopyValueButton = ({ value }) => {
  const { showSuccessMessage } = useAppContext()

  const onClick = () => {
    navigator.clipboard.writeText(value)
    showSuccessMessage(LABEL_COPIED)
  }

  return (
    <div style={{ position: "relative", height: 18 }}>
      <Button
        size="small"
        type="text"
        icon={<CopyOutlined />}
        style={{ marginLeft: -8, marginTop: -3, position: "absolute" }}
        onClick={onClick}
        iconPosition="end"
      >
        {value}
      </Button>
    </div>
  )
}

CopyValueButton.propTypes = {
  value: PropTypes.string.isRequired,
}

export default CopyValueButton
