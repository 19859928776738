import React from "react"

import { MarkdownAlert } from "@components/Text"

const LABEL_SDIRA_CONNECT_BANK_ACCOUNT = `Connect your custodian's bank account
for distributions. Ensure you have confirmed the bank account details with your
custodian.`


const SdiraConnectBankAccountAlert = () => {
  return (
    <MarkdownAlert
      text={LABEL_SDIRA_CONNECT_BANK_ACCOUNT}
      type="warning"
    />
  )
}

export default SdiraConnectBankAccountAlert
