import { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useNavigate,useOutletContext } from "react-router-dom"

import { hasAccess } from "@components/Authorization"
import { DOCUMENT_NOT_FOUND_ERROR } from "@api"
import { runRequest, useAppContext } from "@components/AppContext"
import { TemplatesStoreShape, useTemplatesStore } from "@components/Templates"
import { readProjectOperation as readOperation, ReadProjectOutputShape } from "@api/services/backstage"

import useProjectInvestmentsStore, { projectInvestmentsStoreProperties } from "./useProjectInvestmentsStore"


const useProjectStore = projectId => {
  const navigate = useNavigate()
  const { request } = useAppContext()
  const { updateProject } = useOutletContext()

  const [ project, setProject ] = useState()
  const [ isLoading, setIsLoading ] = useState()

  const projectTemplatesStore = useTemplatesStore(projectId)
  const projectInvestmentsStore = useProjectInvestmentsStore(projectId)

  const readProject = useCallback(() =>
    request(readOperation, { id: projectId }, [ DOCUMENT_NOT_FOUND_ERROR ])
      .then(({ data }) => {
        setProject(data)

        return data
      })
      .catch(error => navigate('/backstage'))
  , [
    request,
    navigate,
    projectId
  ])

  const updateProjectOverride = updatedProject => {
    updateProject(updatedProject)
    setProject({ ...project, ...updatedProject })
  }

  useEffect(() => {
    const shouldReset = projectId !== project?.id

    if (shouldReset) {
      setProject()
    }
  }, [
    project,
    projectId
  ])

  const { indexTemplates } = projectTemplatesStore
  const { indexInvestments } = projectInvestmentsStore

  const isReady =
    project !== undefined &&
    !isLoading

  const canViewTemplates = hasAccess(['templates-read'])

  useEffect(() => {
    if (isReady) {
      return
    }

    if (isLoading) {
      return
    }

    return runRequest(() => {
      setIsLoading(true)
      const promises = [
        readProject(),
        indexInvestments(),
      ]

      if (canViewTemplates) {
        promises.push(indexTemplates())
      }

      Promise.all(promises)
        .then(() => setIsLoading(false))
    })
  }, [
    isReady,
    isLoading,
    readProject,
    indexTemplates,
    indexInvestments,
    canViewTemplates,
  ])

  const context = {
    updateProject: updateProjectOverride,
    project,
    readProject,
    projectTemplatesStore,
    ...projectInvestmentsStore,
  }

  return {
    isReady,
    context,
  }
}

const projectStoreProperties = {
  project: ReadProjectOutputShape.isRequired,
  readProject: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  projectTemplatesStore: TemplatesStoreShape.isRequired,
  ...projectInvestmentsStoreProperties,
}

export default useProjectStore

export {
  projectStoreProperties
}
