import get from "lodash.get"
import keyBy from "lodash.keyby"

import useColumnsConfig from "@modules/backstage/investors/InvestorsTable/helpers/useColumnsConfig"


const useFilter = (accounts) => {
  const columns = useColumnsConfig(() => {})

  const filters = columns
    .filter(({ isFilterEnabled }) => isFilterEnabled)
    .map(({
      key,
      compute,
      onFilter
    }) => {
      if (!compute) {
        compute = item => get(item, key)
      }

      if (!onFilter) {
        onFilter = (selectedValues, item) => selectedValues.includes(compute(item))
      }

      return {
        key,
        compute,
        onFilter
      }
    })

  const filtersMap = keyBy(filters, 'key')

  const filterValues = (selectedValues, key) => {
    const filter = filtersMap[key]

    const { onFilter } = filter

    const accountsIds = accounts
      .filter(account => onFilter(selectedValues, account))
      .map(({ id }) => id)

    return accountsIds
  }

  return filterValues
}

export default useFilter
