

const IndexCustomersItemSchema = {
  controllerEmail: { format: 'email' },
  isImpersonated: { type: 'boolean' },
  isSoleProprietorship: { type: 'boolean' },
  lastIdentityDocumentUploadedAt: { format: 'date-time' },
  lastBusinessDocumentUploadedAt: { format: 'date-time' },
  upgradedAt: { format: 'date-time' },
  isExtraBusinessDocumentRequested: { type: 'boolean' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexCustomersItemSchema
