import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { List, Space, Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import Identity from "@components/Identity"
import { Modal } from "@components/Page"
import { DRAWER_WIDTH2 } from "@components"

const LABEL_RECIPIENTS = "Recipients"


const RecipientsModal = ({ modal, accountIds = [] }) => {
  const [ isOpen, setIsOpen ] = useState(false)

  const { accountsMap, computeAccountFullname } = useOutletContext()

  modal.current.open = () =>
    setIsOpen(true)

  const onClose = () =>
    setIsOpen(false)

  const modalProps ={
    title: LABEL_RECIPIENTS,
    width: DRAWER_WIDTH2,
    maskClosable: true,
    isOpen,
    onClose,
  }

  const dataSource = useMemo(() =>
    accountIds
      .map(id => accountsMap[id])
      .filter(account => !!account)
      .sort((a, b) => computeAccountFullname(a.id) < computeAccountFullname(b.id) ? -1 : 1)
  , [
    accountIds,
    accountsMap,
    computeAccountFullname,
  ])

  const pagination = {
    style: { padding: 0, margin: 0 },
    align: "start",
    total: dataSource.length,
    position: "bottom",
    showTotal: (total) => `${total} total`
  }

  return (
    <Modal {...modalProps}>
      <List
        size="small"
        height={400}
        pagination={pagination}
        dataSource={dataSource}
        renderItem={({ id, investor }) => (
          <List.Item
            key={id}
            style={{ padding: "8px 2px" }}
          >
            <Space>
              <Identity id={id} strong={true} />
              <Typography.Text type="secondary" ellipsis={true}>{investor.email}</Typography.Text>
              {/* Include tooltip with altenative emails */}
            </Space>
          </List.Item>
        )}
      />
    </Modal>
  )
}

RecipientsModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  accountIds: PropTypes.arrayOf(PropTypes.string),
}

export default RecipientsModal
