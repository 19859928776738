import React from "react"
import { ExclamationCircleFilled } from '@ant-design/icons'

import { deleteBatchOperation as deleteOperation } from "@api/services/backstage"

const LABEL_YES = "Yes"
const LABEL_DELETE = "Delete Batch"
const LABEL_DESCRIPTION = `
  Batch documents would be deleted and not available for investors anymore.
`


const confirmBatchDelete = (modal, request, batchId, afterDelete) => {
  modal.confirm({
    icon: <ExclamationCircleFilled />,
    title: LABEL_DELETE,
    content: LABEL_DESCRIPTION,
    okText: LABEL_YES,
    okType: "danger",
    onOk() {
      return request(deleteOperation, { id: batchId })
        .then(() => afterDelete())
    },
    onCancel() {}
  })
}

export default confirmBatchDelete
