import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"

import { getInvestmentClassColumns, getInvestmentExportColumns } from "@components/Export"


const useExportColumns = () => {
  const {
    project = {},
    properties,
    getAccount,
    getProperty,
    getTierName,
    getSponsorName,
    bankAccountsMap,
    computeUserFullname,
  } = useOutletContext()

  const classColumns = getInvestmentClassColumns(project, getTierName)

  return useMemo(() => getInvestmentExportColumns({
    project,
    properties,
    getAccount,
    getProperty,
    classColumns,
    getSponsorName,
    bankAccountsMap,
    computeUserFullname
  }), [
    project,
    properties,
    getAccount,
    getProperty,
    classColumns,
    getSponsorName,
    bankAccountsMap,
    computeUserFullname
  ])
}

export default useExportColumns
