import React from "react"

import { Value } from "@components/Text"

import breakRow from "./breakRow"
import renderBoolean from "./renderBoolean"
import renderAdditional from "./renderAdditional"
import renderResidenceState from "./renderResidenceState"

const LABEL_SSN = "Social Security Number"
const LABEL_TITLE = "Title of Membership Interests"
const LABEL_SINGLE = "Individually, as a single person"
const LABEL_MARRIED = "A married person, as my sole and separate property"
const LABEL_INVESTMENTS = "Investments"


const renderProfileIndividual = (profileIndividual, formattedInvestments, investor, additionalExtra) => {
  let items = []

  const {
    ssnNumber,
    isMarriedAndSeparateProperty,
  } = profileIndividual

  const { address = {} } = investor
  const { addressRegion, addressCountry } = address

  items.push({
    span: 2,
    label: LABEL_TITLE,
    value: renderBoolean(isMarriedAndSeparateProperty,
      LABEL_MARRIED,
      LABEL_SINGLE,
    )
  })

  items.push({
    label: LABEL_SSN,
    value: <Value value={ssnNumber} />,
  })

  if (formattedInvestments) {
    items.push({
      label: LABEL_INVESTMENTS,
      value: formattedInvestments
    })
  }

  items = [
    ...items,
    ...renderResidenceState(profileIndividual, addressCountry, addressRegion)
  ]

  breakRow(items)
  items.push(renderAdditional(profileIndividual, additionalExtra))

  return items
}

export default renderProfileIndividual
