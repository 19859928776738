
const getQueryParams = () => {
  const queryString = window.location.search
  const queryParams = new URLSearchParams(queryString)

  const email = queryParams.get("email")
  const action = queryParams.get("action")

  return { email, action }
}

export default getQueryParams
