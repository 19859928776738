import React from "react"

import { Value } from "@components/Text"
import { getRegionNameByCountryAndRegionCode } from "@components/Address"

import renderBoolean from "./renderBoolean"

const LABEL_STATE = "State of Residence"
const LABEL_NOT_US_PERSON = "Not a resident of US"

const DEFAULT_LABELS = {
  isUsPerson: "Are you a US person?",
  isUsCitizen: "Are you a US citizen?",
}


const renderResidenceState = (profile, addressCountry, addressRegion, labels = {}) => {
  labels = { ...DEFAULT_LABELS, ...labels }

  const { isUsPerson, isUsCitizen } = profile

  const regionName = getRegionNameByCountryAndRegionCode(addressCountry, addressRegion)

  const items = [
    {
      label: labels.isUsCitizen,
      value: renderBoolean(isUsCitizen)
    }
  ]

  const isNotUsCitizen = isUsCitizen === false

  if (isNotUsCitizen) {
    items.push({
      label: labels.isUsPerson,
      value: renderBoolean(isUsPerson)
    })
  }

  if (isUsPerson) {
    return [
      {
        label: LABEL_STATE,
        value: <Value value={regionName} />
      }
    ]
  }

  const isNotUsPerson = isUsPerson === false

  if (isNotUsPerson) {
    items.push({
      label: LABEL_STATE,
      value: LABEL_NOT_US_PERSON,
    })
  }

  return items
}

export default renderResidenceState
