import React, { useRef } from "react"

import Page from "@components/Page"
import { W1 } from "@components"

import UserGroupsTable from "./UserGroupsTable"
import UserGroupCreateModal from "./UserGroupCreateModal"
import UserGroupUpdateModal from "./UserGroupUpdateModal"

const LABEL_TITLE = "Groups"


const UserGroupsPage = () => {
  const table = useRef({})
  const createModal = useRef({})
  const updateModal = useRef({})

  const onSearch = (value) =>
    table.current.search(value)

  const style = {
    padding: W1,
    paddingBottom: 0,
  }

  const openCreateModal = () =>
    createModal.current.open()

  const openUpdateModal = id =>
    updateModal.current.open(id)

  return (
    <Page
      style={style}
      title={LABEL_TITLE}
      onSearch={onSearch}
    >
      <UserGroupsTable
        table={table}
        openCreateModal={openCreateModal}
        openUpdateModal={openUpdateModal}
      />

      <UserGroupCreateModal modal={createModal} />
      <UserGroupUpdateModal modal={updateModal} />
    </Page>
  )
}


export default UserGroupsPage
