
import upload from "./upload"
import readFileContent from "./readFileContent"


const getCustomUploadRequest = (request, isPublic) => {
  return async ({ file, onSuccess, onError }) => {
    const { type: contentType } = file
    const content = await readFileContent(file)

    let response
    try {
      response = await upload(request, isPublic, contentType, content)

    } catch (error) {
      return onError(error)
    }

    return onSuccess(response)
  }
}

export default getCustomUploadRequest
