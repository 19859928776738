import React from "react"
import { App } from "antd"

import { Markdown } from "@components/Text"
import { confirmAction } from "@components/Page"
import { useAppContext } from "@components/AppContext"

import signInAs from "./signInAs"

const LABEL_MESSAGE = `You are about to open the investor portal in a new tab
as **[FULLNAME]**.`


const useSignInAsConfirm = (account) => {
  const { modal } = App.useApp()
  const { identity, request } = useAppContext()

  const signInAsInvestor = () => signInAs(account, request, identity)

  return () => {
    const { investor: { givenName, familyName } } = account

    const fullName = `${givenName} ${familyName}`
    const text = LABEL_MESSAGE
      .replace('[FULLNAME]', fullName)

    return confirmAction(
      modal,
      "Login",
      "Login As",
      <Markdown text={text} />,
      signInAsInvestor,
    )
  }
}

export default useSignInAsConfirm
