import React from "react"
import PropTypes from "prop-types"
import { App, Table } from "antd"
import { useOutletContext, useParams } from "react-router-dom"

import { EditableGroup } from "@components/Button"
import { useProjectDetailsContext } from "@components/Project"

import PropertyShape from "../../../shapes/PropertyShape"
import getTableColumns from "./helpers/getTableColumns"
import confirmPropertyRemove from "./helpers/confirmPropertyRemove"

const LABEL_TITLE = "Properties"


const FundPropertiesTable = ({
  properties = [],
  isEditable,
  openPropertyModal,
}) => {
  const { modal } = App.useApp()
  const { id: projectId } = useParams()

  const {
    readProject,
    indexProjects,
    indexProperties,
    removeProjectProperty
  } = useOutletContext()

  const {
    openPropertyUpdateModal,
    openPropertyConnectModal
  } = useProjectDetailsContext()

  const openPropertyRemoveConfirm = propertyId =>
    confirmPropertyRemove(modal, () =>
      removeProjectProperty(projectId, propertyId)
        .then(() => Promise.all([
          readProject(),
          indexProjects(),
          indexProperties()
        ]))
    )

  const size = "middle"
  const rowKey = "id"
  const scroll = { x: 700 }
  const columns = getTableColumns(isEditable, openPropertyModal, openPropertyUpdateModal, openPropertyRemoveConfirm)
  const className = "table-custom-header"
  const pagination = false
  const dataSource = properties

  const tableProps = {
    size,
    rowKey,
    scroll,
    columns,
    className,
    dataSource,
    pagination,
  }

  return (
    <EditableGroup
      title={LABEL_TITLE}
      onClick={() => openPropertyConnectModal()}
      isEditable={isEditable}
      buttonProps={{ type: "connect" }}
    >
      <Table {...tableProps} />
    </EditableGroup>
  )
}

FundPropertiesTable.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  properties: PropTypes.arrayOf(PropertyShape),
  openPropertyModal: PropTypes.func.isRequired,
}

export default FundPropertiesTable
