import React from "react"
import { Form, InputNumber } from "antd"

import { TYPE_AMOUNT } from "../types"
import { AmountInput } from "../../Input"


const renderNumber = (itemProps, inputProps, form, path) => {
  const { type } = itemProps

  const isAmount = type === TYPE_AMOUNT

  if (isAmount) {
    return (
      <AmountInput
        form={form}
        path={path}
        inputProps={inputProps}
        itemProps={itemProps}

      />
    )
  }

  return (
    <>
      <Form.Item {...itemProps}>
        <InputNumber {...inputProps} />
      </Form.Item>
    </>
  )
}

export default renderNumber
