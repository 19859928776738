import React from "react"
import PropTypes from "prop-types"
import { Avatar, Space, Typography } from "antd"

import { ROLES_MAP } from "@components/Domain"

const { Text } = Typography


const IdentityCard = ({ identity }) => {
  const { email, givenName, familyName, roles } = identity

  const [ role ] = roles
  const { color } = ROLES_MAP[role]

  const username = `${givenName} ${familyName}`
  const initials = `${givenName[0]}${familyName[0]}`

  return (
    <Space style={{ padding: "10px 14px" }} size={10}>
      <Avatar
        size="large"
        shape="square"
        style={{
          backgroundColor: color[0],
          color: color[5]
        }}
      >
        {initials}
      </Avatar>

      <Space direction="vertical" size={0}>
        <Text
          strong
          style={{ bottom: -2, position: "relative" }}
        >
          {username}
        </Text>
        <Text
          type="secondary"
          style={{ top: -2, position: "relative", fontSize: 13 }}
        >
          {email}
        </Text>
      </Space>
    </Space>
  )
}

IdentityCard.propTypes = {
  identity: PropTypes.shape().isRequired,
}

export default IdentityCard
