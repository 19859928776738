import PropTypes from "prop-types"

import { properties } from "@api/services/investments/shapes/ProfileShape"


const ProfileShape = PropTypes.exact({
  ...properties,
  disabled: PropTypes.bool,
})

export default ProfileShape
