import React from "react"
import PropTypes from "prop-types"
import { Timeline } from "antd"

import { hasAccess } from "@components/Authorization"
import { W0, W1, W2, isMobile } from "@components"

import ProjectShape from "../../shapes/ProjectShape"
import ProjectEditableGroup from "../ProjectEditableGroup"
import getProjectTimelineItems from "./helpers/getProjectTimelineItems"

import "./ProjectKeyDates.css"

const LABEL_TITLE = "Key Dates"


const ProjectKeyDates = ({ project, isEditable }) => {
  const isPartnerProject = hasAccess(['fund-projects-write'])

  const items = getProjectTimelineItems(project)
  const hasItems = items.length > 0

  let fieldNames = [
    'signDeadlineDate',
    'fundDeadlineDate',
    'targetCloseDate'
  ]

  if (!isPartnerProject) {
    fieldNames = [
      'openDate',
      ...fieldNames
    ]
  }

  return (
    <ProjectEditableGroup
      title={LABEL_TITLE}
      fieldNames={fieldNames}
      extraProps={{isEditable}}
    >
      {
        hasItems && (
          <Timeline
            mode="left"
            style={isMobile ? { marginTop: W2 } : { marginTop: W1 + W0 / 2 }}
            items={items}
          />
        )
      }
    </ProjectEditableGroup>
  )
}

ProjectKeyDates.propTypes = {
  project: ProjectShape.isRequired,
  isEditable: PropTypes.bool.isRequired,
}

export default ProjectKeyDates
