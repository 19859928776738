import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { TYPE_RADIO } from "@components/Form"
import { UpdateModal } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { SPONSOR_TYPE_OPTIONS } from "@components/Domain"
import {
  readSponsorOperation as readOperation,
  updateSponsorOperation as updateOperation,
  deleteSponsorOperation as deleteOperation,
} from "@api/services/backstage"

const LABEL_TITLE = "Edit Sponsor"
const LABEL_ENTITY = "Sponsor"
const LABEL_DELETE = "Delete sponsor"
const LABEL_SUBMIT = "Save"
const LABEL_DELETE_MESSAGE = "Sponsor $ is deleted"

const schema = updateOperation.getSchema([
  {
    name: "name",
  },
  {
    name: "type",
    type: TYPE_RADIO,
    options: SPONSOR_TYPE_OPTIONS
  }
])


const SponsorUpdateModal = ({ modal }) => {
  const {
    request,
    showSuccessMessage
  } = useAppContext()

  const {
    indexUsers,
    deleteSponsor,
    updateSponsor,
  } = useOutletContext()

  const deleteAction = sponsor => {
    const { id, name } = sponsor

    return request(deleteOperation, { id })
      .then(() => deleteSponsor(id))
      .then(() => indexUsers())
      .then(() => modal.current.close())
      .then(() => {
        showSuccessMessage(LABEL_DELETE_MESSAGE.replace("$", `"${name}"`))
      })
  }

  return (
    <UpdateModal
      modal={modal}
      title={LABEL_TITLE}
      entity={LABEL_ENTITY}
      schema={schema}
      onSuccess={updateSponsor}
      operation={updateOperation}
      deleteTitle={LABEL_DELETE}
      submitLabel={LABEL_SUBMIT}
      deleteAction={deleteAction}
      readOperation={readOperation}
    />
  )
}

SponsorUpdateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default SponsorUpdateModal
