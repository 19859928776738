import React from "react"
import PropTypes from "prop-types"
import { Alert, List, Space, Typography } from "antd"

import ImportErrorShape from "./shapes/ImportErrorShape"


const ImportModalErrors = ({
  title,
  errors,
  prefix,
}) => {
  return (
    <Alert
      type="error"
      message={title}
      closable={false}
      description={
        <List
          size="small"
          dataSource={errors}
          itemLayout="vertical"
          renderItem={item =>
            (
              <List.Item style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Space>
                  <Typography.Text type="secondary">
                    {`${prefix}${item.index}`}
                  </Typography.Text>
                  <Typography.Text>
                    {item.reason}
                  </Typography.Text>
                </Space>
              </List.Item>
            )
          }
        />
      }
    />
  )
}

ImportModalErrors.propTypes = {
  title: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(ImportErrorShape).isRequired,
  prefix: PropTypes.string.isRequired,
}

export default ImportModalErrors
