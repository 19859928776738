import React, { useState } from "react"
import PropTypes from "prop-types"
import { Modal } from "antd"
import { useOutletContext } from "react-router-dom"

import { Markdown } from "@components/Text"
import { useAppContext } from "@components/AppContext"
import { resetInvestmentSubscriptionOperation as resetOperation } from "@api/services/investments"

import { InvestmentShape } from "../../../shapes"

const LABEL_TEXT = `Send the link for **[NAME]** to sign the subscription
documents to [EMAIL].

The process may take some time, so please be patient and do not close the
window.`

const LABEL_NO_TEMPLATE = `\nProject doesn't have PPM template linked which is
required to generate subscription documents for the investor.`

const LABEL_SEND = "Send"
const LABEL_TITLE = "Send DocuSign Link"
const LABEL_SEND_SUCCESS = "DocuSign link is sent to [NAME]"


const SignUrlShareModal = ({ modal, investment, readInvestment }) => {
  const { request, showSuccessMessage } = useAppContext()
  const { getProject, computeAccountFullname } = useOutletContext()

  const [ isOpen, setIsOpen ] = useState(false)
  const [ isSending, setIsSending ] = useState(false)

  const { id, investorAccountId, projectId, investor } = investment
  const { ppmTemplateId } = getProject(projectId)

  const isDisabled = !ppmTemplateId

  const fullName = computeAccountFullname(investorAccountId)
  const { email } = investor

  const isClosable = !isSending

  const onOk = () => {
    setIsSending(true)
    return request(resetOperation, { id })
      .then(() => readInvestment(id))
      .then(() => onCancel())
      .then(() => showSuccessMessage(LABEL_SEND_SUCCESS.replace('[NAME]', fullName)))
  }

  const onCancel = () => {
    setIsOpen(false)
    setIsSending(false)
  }

  const modalProps = {
    open: isOpen,
    title: LABEL_TITLE,
    okText: LABEL_SEND,
    closable: isClosable,
    maskClosable: false,
    destroyOnClose: true,
    okButtonProps: { loading: isSending, disabled: isDisabled },
    cancelButtonProps: { disabled: isSending },
    onCancel,
    onOk,
  }

  modal.current.open = () =>
    setIsOpen(true)

  let text = LABEL_TEXT
    .replace('[NAME]', fullName)
    .replace('[EMAIL]', email)

  if (isDisabled) {
    text = LABEL_NO_TEMPLATE
  }

  return (
    <Modal {...modalProps}>
      <Markdown text={text} />
    </Modal>
  )
}

SignUrlShareModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  investment: InvestmentShape.isRequired,
  readInvestment: PropTypes.func.isRequired,
}

export default SignUrlShareModal
