import React from "react"
import PropTypes from "prop-types"
import { Card, Space, Button, Typography, Spin, App } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { useOutletContext } from "react-router-dom"

import confirmAction from "@components/Page/helpers/confirmAction"
import { W1, isMobile } from "@components"
import { PROJECT_STATE_OPEN } from "@components/Domain"

const { useApp } = App

const LABEL_ADD = "Add Another Investment Profile"
const LABEL_TITLE = "Investment Profile"
const LABEL_CANCEL = "Cancel"
const LABEL_CONTINUE = "Continue"
const LABEL_CONFIRM_TITLE = `Cancel Investment Profile`
const LABEL_CONTINUE_FINALIZE = "Continue to Finalize"
const LABEL_CONFIRM_DESCRIPTION = `Are you sure about cancelling this investment profile?`


const InvestmentTab = ({
  index,
  onSubmit,
  onCancel,
  children,
  isLoading,
  isDeletable,
  isLastInvestment,
}) => {
  const { modal } = useApp()
  const { project } = useOutletContext()

  const { state } = project

  const isProjectOpen = state === PROJECT_STATE_OPEN

  const showCancelProfileModal = () =>
    confirmAction(modal, null, LABEL_CONFIRM_TITLE, LABEL_CONFIRM_DESCRIPTION, onCancel, "danger")

  const title = isLastInvestment
    ? LABEL_CONTINUE_FINALIZE
    : LABEL_CONTINUE

  return (
    <Spin spinning={isLoading}>
      <Space
        size={W1}
        direction="vertical"
      >
        <Card
          title={`${LABEL_TITLE} ${index}`}
          type="inner"
          size={ isMobile ? "small": "medium" }
          extra={
            isDeletable ? (
              <Typography.Link
                strong
                type="secondary"
                onClick={showCancelProfileModal}
              >
                {LABEL_CANCEL}
              </Typography.Link>
            ) : <></>
          }
        >
          {children}
        </Card>

        {
          isProjectOpen && (
            <Button
              size="large"
              type="dashed"
              icon={<PlusOutlined />}
              style={{ width: "100%", fontWeight: 600, fontSize: 15, height: 50 }}
              onClick={() => onSubmit(true)}
            >
              {LABEL_ADD}
            </Button>
          )
        }

        <Button
          type="primary"
          size="large"
          shape="round"
          style={{ width: "100%", fontWeight: 600, height: 50 }}
          onClick={() => onSubmit(false)}
        >
          {title}
        </Button>
      </Space>
    </Spin>
  )
}

InvestmentTab.propTypes = {
  index: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  isLastInvestment: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export default InvestmentTab
