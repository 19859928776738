import React from "react"
import { Button, Form } from "antd"
import PropTypes from "prop-types"

import { W1 } from "@components"
import DateTimeInput from "@components/Form/Input/DateTimeInput"

const LABEL_GENERATE = "Generate"
const LABEL_MONTH_REQUIRED = "Select month"


const DistributionsReportForm = ({ onSubmit }) => {
  const [ form ] = Form.useForm()

  const formProps = {
    style: { marginTop: W1 },
    layout: "inline",
    onFinish: onSubmit,
    form,
  }

  const buttonProps = {
    type: "primary",
    htmlType: "submit",
  }

  const inputProps = {
    path: [ "date" ],
    itemProps: {
      name: "date",
      hasTimeInput: false,
      rules: [
        {
          message: LABEL_MONTH_REQUIRED,
          required: true,
        },
      ]
    },
    inputProps: {
      picker: "month",
      format: "MM/YYYY"
    },
    form,
  }

  return (
    <Form {...formProps}>
      <DateTimeInput {...inputProps} />

      <Form.Item>
        <Button {...buttonProps}>
          {LABEL_GENERATE}
        </Button>
      </Form.Item>
    </Form>
  )
}

DistributionsReportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default DistributionsReportForm
