import React, { useState } from "react"
import PropTypes from "prop-types"
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons"
import { Button, Spin, Upload} from "antd"
import { useOutletContext } from "react-router-dom"
import groupBy from "lodash.groupby"

import { W2 } from "@components"
import { ExportAction } from "@components/Table"
import { getUsDateFromDate } from "@components/Date"
import { useRequest as useEffect } from "@components/AppContext"
import { INVESTMENT_STATUS, PROJECT_STATES } from "@components/Domain"
import { indexInvestmentsOperation as indexOperation } from "@api/services/investments"

import useImportTransactions from "./helpers/useImportTransactions"

const LABEL_SELECT = "Select CSV Import"
const LABEL_TEMPLATE = "CSV Investments"


const SelectDocumentButton = ({
  onSuccess,
  setErrors,
}) => {
  const [ isLoading, setIsLoading ] = useState(true)
  const [ investmentsMapByProjectId, setInvestmentsMapByProjectId ] = useState()

  const { projects } = useOutletContext()
  const { customRequest } = useImportTransactions(investmentsMapByProjectId, onSuccess, setErrors)

  useEffect(request => {
    setIsLoading(true)
    request(indexOperation, { status: INVESTMENT_STATUS.ACTIVE })
      .then(({ data }) => {
        const investmentsMap = groupBy(data, "projectId")
        setInvestmentsMapByProjectId(investmentsMap)
      })
      .then(() => setIsLoading(false))
  }, [])

  const getInvestmentRowsForExport = () => {
    const openProjectIds = projects
      .filter(({ state }) => state === PROJECT_STATES.OPEN)
      .map(({ id }) => id)

    const result = []

    const activeInvestments = Object
      .values(investmentsMapByProjectId)
      .flat()
      .filter(({ status, projectId }) =>
        status === INVESTMENT_STATUS.ACTIVE &&
        openProjectIds.includes(projectId)
      )

    for (const investment of activeInvestments) {
      const { projectId, id } = investment
      const ReferenceId = `${projectId}-${id}`
      const Amount = 0

      result.push({
        Date: getUsDateFromDate(new Date()),
        "Reference ID": ReferenceId,
        Amount,
        Description: ""
      })
    }

    return result
  }

  return (
    <Spin spinning={isLoading}>
      <div style={{ display: "flex", gap: W2 }}>
        <ExportAction
          icon={<DownloadOutlined />}
          type="link"
          title={LABEL_TEMPLATE}
          fileName="transactions_import"
          getItems={getInvestmentRowsForExport}
        />

        <Upload
          accept=".csv"
          multiple={false}
          customRequest={customRequest}
          showUploadList={false}
        >
          <Button
            type="dashed"
            icon={<UploadOutlined />}
          >
            {LABEL_SELECT}
          </Button>
        </Upload>
      </div>
    </Spin>
  )
}

SelectDocumentButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired
}

export default SelectDocumentButton
