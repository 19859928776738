import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

import { Value } from "@components/Text"
import Table, { DESCEND } from "@components/Table"
import { indexNotificationsOperation as indexOperation } from "@api/services/notifications"

const LABEL_SUBJECT = "Subject"


const NotificationsTable = ({
  recipientId,
  openNotificationDrawer
}) => {
  const operationParameters = useMemo(() => ({ recipientId }), [ recipientId ])

  const size = "small"
  const defaultSortColumn = "sentAt"
  const defaultSortColumnDirection = DESCEND

  const columnsConfig = [
    {
      key: "emailMessage",
      title: LABEL_SUBJECT,
      compute: ({ emailMessage }) => emailMessage.subject,
      render: (value, notification) =>
        <Typography.Text strong>
          <Typography.Link onClick={() => openNotificationDrawer(notification)}>
            {value}
          </Typography.Link>
        </Typography.Text>,
    },
    {
      key: "sentAt",
      width: 180,
      render: value => <Value value={value} />
    }
  ]

  const tableProps = {
    size,
    columnsConfig,
    indexOperation,
    defaultSortColumn,
    operationParameters,
    defaultSortColumnDirection,
  }

  return (
    <Table {...tableProps} />
  )
}

NotificationsTable.propTypes = {
  recipientId: PropTypes.string.isRequired,
  openNotificationDrawer: PropTypes.func.isRequired,
}

export default NotificationsTable
