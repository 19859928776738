import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Checkbox, Form, Input, Typography } from "antd"

import TierShape from "@api/services/backstage/shapes/TierShape"
import { FormShape } from "@components/Form"
import { isBackstage } from "@components"

import InvestmentTierOption, { KEY_AMOUNT, KEY_AMOUNTS_MAP } from "./InvestmentTierOption"

import "./InvestmentsInput.css"

const { Text } = Typography

const KEY_TIER_IDS = "_tierIds"

const LABEL_CLASSES = "What investment class(es) would you like to invest in?"
const LABEL_CLASS_REQUIRED = "Select at least one class"


const InvestmentsInput = ({
  form,
  path,
  name,
  tiers,
  itemProps = {},
}) => {
  const sourceValue = Form.useWatch(path, form)

  const [ shouldAutoFocus, setShouldAutoFocus ] = useState(false)
  const [ selectedTierIds, setSelectedTierIds ] = useState([])

  useEffect(() => {
    const investments = form.getFieldValue(path) || []

    investments.forEach(({ projectTierId: tierId, amount }) => {
      const amountFieldPath = [ ...path.slice(0, -1), KEY_AMOUNTS_MAP, tierId, KEY_AMOUNT ]
      form.setFieldValue(amountFieldPath, amount)
    })

    const tierIdsFieldPath = [ ...path.slice(0, -1), KEY_TIER_IDS ]
    form.setFieldValue(tierIdsFieldPath, investments.map(item => item.projectTierId))

    setSelectedTierIds(investments.map(item => item.projectTierId))
  }, [ form, path, sourceValue ])

  const rules = [{
    message: LABEL_CLASS_REQUIRED,
    required: true,
  }]

  const options = tiers
    .filter(({ isPrivate }) => isBackstage ? true : !isPrivate)
    .sort((a, b) => a.name < b.name ? -1 : 1)
    .map(tier => ({
    value: tier.name,
    label: (
      <InvestmentTierOption
        name={name}
        form={form}
        path={path}
        tier={tier}
        autoFocus={false}
        selectedTierIds={selectedTierIds}
        shouldAutoFocus={shouldAutoFocus}
      />
    )
  }))

  const onChange = checkedTierIds => {
    setShouldAutoFocus(true)

    const investments = form.getFieldValue(path) || []

    const investmentsChanged = []

    for (const tierId of checkedTierIds) {
      const [ investment ] = investments.filter(i => i.projectTierId === tierId)

      if (investment) {
        investmentsChanged.push(investment)

      } else {
        investmentsChanged.push({ projectTierId: tierId })
      }
    }

    form.setFieldValue(path, investmentsChanged)

    /* note: checking / unchecking group doesn't trigger re-render */
    setSelectedTierIds(checkedTierIds)
  }

  /* note: "tierIds" checkboxes should be outside of scope, so input updates
           do not reset fieldPath values: */
  const tierIdsFieldName = [...name.slice(0, -1), KEY_TIER_IDS]

  return (
    <div className="input-investments">
      <Form.Item
        key={"hidden"}
        name={name}
        hidden={true}
      >
        <Input />
      </Form.Item>

      <Form.Item
        key={"input"}
        name={tierIdsFieldName}
        label={<Text strong>{LABEL_CLASSES}</Text>}
        rules={rules}
        {...itemProps}
      >
        <Checkbox.Group
          style={{ display: "flex", flexDirection: "column" }}
          options={options}
          onChange={onChange}
        />
      </Form.Item>
    </div>
  )
}

InvestmentsInput.propTypes = {
  form: FormShape.isRequired,
  name: PropTypes.arrayOf(PropTypes.string).isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  tiers: PropTypes.arrayOf(TierShape).isRequired,
  itemProps: PropTypes.shape(),
}

export default InvestmentsInput

export {
  KEY_TIER_IDS,
  KEY_AMOUNTS_MAP
}
