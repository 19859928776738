import { useOutletContext } from "react-router-dom"
import chunk from "lodash.chunk"

import { getConfig } from "@components/Config"
import { getTierName } from "@components/Store/BackstageStore/useProjectsStore"
import { getCurrencyValue } from "@components/Amount"
import {
  useExportProgress,
  getInvestmentExportColumns,
  getGlobalInvestmentClassColumns
} from "@components/Export"

import getTransactionReport from "../../ProjectsReportModal/helpers/getTransactionReport"
import getOrganizationUsers from "./getOrganizationUsers"
import getProjectInvestments from "./getProjectInvestments"
import getOrganizationAccounts from "./getOrganizationAccounts"
import getOrganizationProperties from "./getOrganizationProperties"
import { getOrganizationProjects } from "../../DistributionsReportModal"
import getOrganizationBankAccounts from "./getOrganizationBankAccounts"

const LABEL_DISTRIBUTIONS = "Distributions"
const LABEL_ORGANIZATION_ID = "Organization ID"
const LABEL_ORGANIZATION_NAME = "Organization Name"
const LABEL_CUMULATIVE_DISTRIBUTIONS = "Cumulative Distributions"

const INVESTMENT_ID_COLUMN = "Investment ID"
const CONCURRENT_CHUNK_SIZE = 3

const consoleOrganizationId = getConfig("consoleOrganizationId")


const concurrently = (items, callback) =>
  Promise.all(items.map(item => callback(item)))


const useInvestmentsReport = (onReady) => {
  const fileName = "investments_report"

  const { organizations } = useOutletContext()

  const targetOrganizations = organizations
    .filter(({ id }) => id !== consoleOrganizationId)
    .filter(({ isDisabled }) => isDisabled !== true)

  const totalCount = targetOrganizations.length * 2

  const computeRow = ({
    project,
    getAccount,
    properties,
    investment,
    getProperty,
    classColumns,
    organization,
    bankAccountsMap,
    computeUserFullname,
    transactionReportsMap,
  }) => {
    const {
      id: organizationId,
      name: organizationName,
    } = organization

    // NOTE: Multi sponsor mode is not supported here:
    const getSponsorName = () => organizationName

    const { id: investmentId, projectId } = investment

    const columns = getInvestmentExportColumns({
      project,
      properties,
      getAccount,
      getProperty,
      classColumns,
      getSponsorName,
      bankAccountsMap,
      computeUserFullname
    })

    const row = {
      [LABEL_ORGANIZATION_ID]: organizationId,
      [LABEL_ORGANIZATION_NAME]: organizationName,
    }

    for (const column of columns) {
      const { title, value } = column
      row[title] = value(investment, organizationId)

      const isInvestmentIdColumn = title === INVESTMENT_ID_COLUMN

      if (isInvestmentIdColumn) {
        row[title] = `${organizationId}-${projectId}-${row[title]}`
      }
    }

    const currentYear = new Date().getFullYear()
    const pastYear = currentYear - 1

    const getDistributionsAmount = year => {
      const transactionReport = transactionReportsMap[organizationId]
      const { projectTransactionReports } = transactionReport

      const projectTransactionReport = projectTransactionReports
        .find(report => report.projectId === projectId)

      if (!projectTransactionReport) {
        return getCurrencyValue(0)
      }

      const { investmentTransactionReports } = projectTransactionReport

      const investmentTransactionReport = year
        ? investmentTransactionReports
            .find(report => report.investmentId === investmentId && report.year === Number(year))
        : investmentTransactionReports
            .find(report => report.investmentId === investmentId && !report.year)

      if (!investmentTransactionReport) {
        return getCurrencyValue(0)
      }

      const { distributionsAmount } = investmentTransactionReport

      return getCurrencyValue(distributionsAmount)
    }

    row[`${pastYear} ${LABEL_DISTRIBUTIONS}`] = getDistributionsAmount(pastYear)
    row[`${currentYear} ${LABEL_DISTRIBUTIONS}`] = getDistributionsAmount(currentYear)
    row[LABEL_CUMULATIVE_DISTRIBUTIONS] = getDistributionsAmount()

    return row
  }

  const getRows = async ({ authorizeOrganization, shouldStop, updateProgress }) => {
    const rows = []
    let index = 0

    const projectsMap = {}
    const transactionReportsMap = {}

    const organizationChunks = chunk(targetOrganizations, CONCURRENT_CHUNK_SIZE)

    for (const items of organizationChunks) {
      if (shouldStop()) {
        break
      }

      await concurrently(items, async organization => {
        const {
          id: organizationId,
          name: organizationName,
        } = organization

        // eslint-disable-next-line no-console
        console.info('Get organization projects', `${organizationId} - ${organizationName}`)

        const authorization = await authorizeOrganization(organizationId)

        if (shouldStop()) {
          return
        }

        const [
          projects,
          transactionReport,
        ] = await Promise.all([
          getOrganizationProjects(authorization),
          getTransactionReport(authorization),
        ])

        projectsMap[organizationId] = projects
          .map(project => ({
            ...project,
            globalProjectId: `${organizationId}-${project.id}`
          }))

        transactionReportsMap[organizationId] = transactionReport
      })

      index += items.length
      updateProgress(index, totalCount)
    }

    const targetProjects = Object.values(projectsMap)
      .reduce((acc, cur) => [...acc, ...cur], [])

    const classColumns = getGlobalInvestmentClassColumns(targetProjects, getTierName)

    for (const organization of targetOrganizations) {
      if (shouldStop()) {
        return
      }

      const {
        id: organizationId,
        name: organizationName,
      } = organization

      // eslint-disable-next-line no-console
      console.info('Export organization', `${organizationId} - ${organizationName}`)

      const projects = projectsMap[organizationId]

      const authorization = await authorizeOrganization(organizationId)

      if (shouldStop()) {
        return
      }

      const [
        { getAccount },
        { bankAccountsMap },
        { computeUserFullname },
        { properties, getProperty }
      ] = await Promise.all([
        getOrganizationAccounts(authorization),
        getOrganizationBankAccounts(authorization),
        getOrganizationUsers(authorization),
        getOrganizationProperties(authorization)
      ])

      const projectChunks = chunk(projects, CONCURRENT_CHUNK_SIZE)

      for (const items of projectChunks) {
        if (shouldStop()) {
          break
        }

        await concurrently(items, async project => {
          const { id: projectId } = project
          const activeProjectInvestments = await getProjectInvestments(authorization, projectId)

          const projectRows = activeProjectInvestments
            .map(investment => computeRow({
              project,
              getAccount,
              properties,
              investment,
              getProperty,
              classColumns,
              organization,
              bankAccountsMap,
              computeUserFullname,
              transactionReportsMap,
            }))

          rows.push(...projectRows)
        })
      }

      index++
      updateProgress(index, totalCount)
    }

    return rows
  }

  return useExportProgress({ onReady, fileName, getRows })
}

export default useInvestmentsReport
