import { AccountStatusEnumKeys } from "./shapes/AccountStatusEnum"

const IndexAccountsItemSchema = {
  isImmutable: { type: 'boolean' },
  status: { enum: AccountStatusEnumKeys },
  noteUpdatedAt: { format: 'date-time' },
  isDisabled: { type: 'boolean' },
  lastAuthenticatedAt: { format: 'date-time' },
  lastAdminAuthorizedAt: { format: 'date-time' },
  is2FaEnabled: { type: 'boolean' },
  verificationRequestCreatedAt: { format: 'date-time' },
  acceptedTermsVersion: { type: 'integer' },
  totalInvestedAmount: { type: 'integer' },
  lastFundedAt: { format: 'date-time' },
  isSubscribedForCampaigns: { type: 'boolean' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexAccountsItemSchema
