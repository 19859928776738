import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Form as AntForm, Spin } from "antd"
import { useOutletContext } from "react-router-dom"

import { Form } from "@components/Form"
import { Modal } from "@components/Page"
import { MarkdownAlert } from "@components/Text"
import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"
import {
  upgradeMyUnverifiedBusinessCustomerOperation as operation,
  IndexBankAccountsOutputShape
} from "@api/services/transactions"

import useInputs from "./helpers/useInputs"
import getInvestmentCustomer from "./helpers/getInvestmentCustomer"
import getBankAccountCustomer from "./helpers/getBankAccountCustomer"
import getClassificationGroup from "./helpers/getClassificationGroup"
import useBusinessClassificationOptions from "./helpers/useBusinessClassificationOptions"
import getBusinessTypes from "./helpers/getBusinessTypes"


const LABEL_TITLE = "Additional Details Required"
const LABEL_CONTINUE = "Continue"

const LABEL_RETRY = `Business verification failed. Please update the provided
information to ensure its accuracy.`


const BusinessCustomerUpgradeModal = ({
  onSuccess: onCustomSuccess,
  isRetry = false,
  investment = undefined,
  bankAccount = undefined,
  modal,
  customerId,
}) => {
  const [ form ] = AntForm.useForm()

  const [ item, setItem ] = useState({})
  const [ isOpen, setIsOpen ] = useState(false)

  const { getInvestorSsnNumber } = useOutletContext()
  const { identity, authenticatedRequest, updateIdentityCustomer } = useAppContext()

  const [ groupOptions, classificationsMap, dwollaCustomer ] = useBusinessClassificationOptions(customerId, isOpen)

  const businessTypes = useMemo(() => getBusinessTypes(investment), [investment])

  const getCustomer = useCallback(() => {
    if (bankAccount) {
      return getBankAccountCustomer(customerId, identity, bankAccount)
    }

    if (investment) {
      const investorSsn = getInvestorSsnNumber()
      return getInvestmentCustomer(customerId, investment, investorSsn)
    }

    return { id: customerId }
  }, [bankAccount, customerId, getInvestorSsnNumber, identity, investment])

  const getRetryCustomer = useCallback(() => {
    const { id, businessCustomer, soleProprietorshipCustomer } = dwollaCustomer

    if (soleProprietorshipCustomer) {
      const { businessClassificationId } = soleProprietorshipCustomer
      const classificationGroup = getClassificationGroup(classificationsMap, businessClassificationId)

      soleProprietorshipCustomer.classificationGroup = classificationGroup

      // if (investment) {
      //   const investorSsn = getInvestorSsnNumber()
      //   const { soleProprietorshipCustomer: { ssn, ein, dateOfBirth } } = getInvestmentCustomer(customerId, investment, investorSsn)

      //   return { id, soleProprietorshipCustomer: { ...soleProprietorshipCustomer, ssn, ein, dateOfBirth } }
      // }

      return { id, soleProprietorshipCustomer }
    }

    const { businessClassificationId } = businessCustomer
    const classificationGroup = getClassificationGroup(classificationsMap, businessClassificationId)

    businessCustomer.classificationGroup = classificationGroup

    return { id, businessCustomer }
  }, [classificationsMap, dwollaCustomer])

  useEffect(() => {
    if (!isOpen) {
      return
    }

    if (!dwollaCustomer) {
      return
    }

    if (!isRetry) {
      const customer = getCustomer()
      setItem(customer)

      return
    }

    const customer = getRetryCustomer(dwollaCustomer)
    setItem(customer)

  }, [ isOpen, isRetry, dwollaCustomer, customerId, investment, bankAccount, getCustomer, getRetryCustomer ])

  const schema = useInputs({
    form,
    isOpen,
    customer: item,
    groupOptions,
    businessTypes,
    classificationsMap
  })

  modal.current.open = () =>
    setIsOpen(true)

  const onClose = () =>
    setIsOpen(false)

  const onSuccess = (upgradedCustomer) => {
    updateIdentityCustomer(upgradedCustomer)
    return onCustomSuccess(upgradedCustomer)
  }

  const modalProps = {
    title: LABEL_TITLE,
    isOpen,
    onClose,
  }

  const formOptions = {
    request: authenticatedRequest,
    submitLabel: LABEL_CONTINUE,
    hasSubmitButton: false,
    shouldOptimizeUpdateMutation: false,
    item,
    form,
    schema,
    onSuccess,
    operation,
  }

  const header = isRetry
    ? <MarkdownAlert text={LABEL_RETRY} type="warning" />
    : null

  const isLoading = !dwollaCustomer

  return (
    <Modal {...modalProps}>
      {header}

      <Spin spinning={isLoading}>
        <div style={{ minHeight: 60 }}>
          <Form {...formOptions} />
        </div>
      </Spin>
    </Modal>
  )
}

BusinessCustomerUpgradeModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  isRetry: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  investment: InvestmentShape,
  bankAccount: IndexBankAccountsOutputShape,
}

export default BusinessCustomerUpgradeModal
