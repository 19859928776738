import { useState } from "react"
import { useOutletContext } from "react-router-dom"

import { wait } from "@api"
import { ORGANIZATION_DOMAIN_STATUS } from "@components/Domain"


const useConnectDomain = () => {
  const [ isConnecting, setIsConnecting ] = useState(false)

  const { updateOrganizationDomainStatus } = useOutletContext()

  const connectDomain = async () => {
    setIsConnecting(true)

    let isConnected = false

    do {
      const organization = await updateOrganizationDomainStatus()
      const { domainStatus } = organization

      isConnected = domainStatus === ORGANIZATION_DOMAIN_STATUS.COMPLETE
      await wait(2500)

    } while (!isConnected)

    setIsConnecting(false)
  }

  return {
    isConnecting,
    connectDomain
  }
}

export default useConnectDomain
