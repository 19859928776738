import React from "react"

import FormHeader from "./FormHeader"


const ResetPasswordHeader = () => {
  return (
    <FormHeader
      heading="Forgot your password?"
      text="Please enter your email below, and we'll send a verification code to reset your password."
    />
  )
}

export default ResetPasswordHeader
