import { getAdditionalPermissionInputs } from "@modules/backstage/users"
import { TYPE_NONE, TYPE_ROLE, TYPE_HIDDEN, TYPE_ARRAY, TYPE_PHONE } from "@components/Form"
import { UserGroupRoleEnumOptions } from "@api/services/backstage/shapes/UserGroupRoleEnum"

const LABEL_ROLE = "Select Role"
const LABEL_GROUPS = "Access Groups"
const LABEL_LAST_NAME = "Last Name"
const LABEL_FIRST_NAME = "First Name"

const getInputs = ({
  userGroups = [],
  isLiaison,
  isManager,
  updateSchema,
  isController,
  organizationName,
  isConsoleOrganization
}) => {
  const additionalPermissionInputs = isConsoleOrganization
    ? []
    : getAdditionalPermissionInputs(organizationName, isLiaison, isController)

  const userGroupsOptions = isConsoleOrganization
    ? userGroups.map(({ id: value, name: label }) => ({ value, label }))
    : []

  const memberRolesInputs = isConsoleOrganization
    ? []
    : [{
      name: "roles",
      type: TYPE_ROLE,
      label: LABEL_ROLE,
      inputProps: {
        onChange: () => updateSchema(),
        disabled: isController,
      }
    }]

  const consoleOrganizationRolesInputs = isConsoleOrganization
    ? [{
      name: "roles",
      type: TYPE_ROLE,
      label: LABEL_ROLE,
      options: UserGroupRoleEnumOptions,
      inputProps: {
        onChange: () => updateSchema(),
      }
    }]
    : []

  const hasGroupsInput =
    isConsoleOrganization &&
    isManager

  const groupsInput = hasGroupsInput
    ? {
        name: "userGroupIds",
        type: TYPE_ARRAY,
        label: LABEL_GROUPS,
        options: userGroupsOptions,
        required: true,
      }
    : {
        name: "userGroupIds",
        type: TYPE_HIDDEN,
        required: false,
      }

  const mobileInput = isConsoleOrganization
    ? {
        name: "mobile",
        type: TYPE_HIDDEN,
      }
    : {
        name: "mobile",
        type: TYPE_PHONE,
      }

  return [
    ...memberRolesInputs,
    ...additionalPermissionInputs,
    {
      name: "givenName",
      label: LABEL_FIRST_NAME,
      itemProps: { style: { width: 270 } },
    },
    {
      name: "familyName",
      label: LABEL_LAST_NAME,
      itemProps: { style: { width: 270 } },
    },
    {
      name: "email",
      itemProps: { style: { width: 270 } },
      inputProps: { disabled: true },
    },
    {
      name: "sponsorId",
      type: TYPE_HIDDEN,
    },
    {
      name: "isDisabled",
      type: TYPE_NONE,
    },
    {
      name: "disabledReason",
      type: TYPE_NONE,
    },
    {
      name: "isMobileVerified",
      type: TYPE_NONE,
    },
    {
      name: "lastAuthenticatedAt",
      type: TYPE_NONE,
    },
    ...consoleOrganizationRolesInputs,
    mobileInput,
    groupsInput,
  ]
}

export default getInputs
