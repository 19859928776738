
const getProjectScfUrl = (identity, projectId, sponsorId) => {
  const { organization } = identity
  const { domain } = organization

  const isLocal = window.location.host.startsWith('localhost')
  const host = isLocal ? 'http://localhost:3000' : `https://${domain}`
  const url = `${host}/scf/create/${projectId}/${sponsorId}`

  return url
}

export default getProjectScfUrl
