import React from "react"
import capitalize from "lodash.capitalize"
import { useOutletContext, useParams, useNavigate } from "react-router-dom"
import { Card, Space, Tooltip, Typography } from "antd"
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons"

import { isMobile } from "@components"
import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"
import { INVESTMENT_STATUS_REJECTED } from "@components/Domain"
import { InvestmentProfile, isSdiraInvestment } from "@components/Investment"

import InvestmentProfileCardSendFunding from "./InvestmentProfileCardSendFunding"
import InvestmentProfileCardSignDocuments from "./InvestmentProfileCardSignDocuments"
import InvestmentProfileCardConnectReceiverSource from "./InvestmentProfileCardConnectReceiverSource"

const { Grid } = Card
const { Text } = Typography

const LABEL_EDIT = "Edit"
const LABEL_DECLINE = "Declined"
const TOOLTIP_DECLINE = `Your investment has been declined. For detailed
information and personalized assistance, please contact our support team at
[organizationEmail] or use the Contact Us form on this page.`


const InvestmentProfileCard = ({ investment }) => {
  const navigate = useNavigate()
  const { project } = useOutletContext()
  const { identity } = useAppContext()
  const { projectId } = useParams()

  const { organization } = identity

  const { tiers } = project
  const { name: investmentName } = investment
  const { email: organizationEmail } = organization

  const formattedTooltipDecline = TOOLTIP_DECLINE
    .replace("[organizationEmail]", organizationEmail)

  const isSdira = isSdiraInvestment(investment)

  const {
    id: investmentId,
    profileType,
    status,
  } = investment

  const onEdit = () =>
    navigate(`/investments/${projectId}/update/${investmentId}`)

  const profileTitle = isSdira
    ? profileType
    : capitalize(profileType)

  const gridStyle = {
    width: '100%',
    padding: isMobile ? 12 : 24,
  }

  const isRejected = status === INVESTMENT_STATUS_REJECTED

  const getExtra =  () => {
    if (isRejected) {
      return (
        <Space style={{color: "red"}}>
          <Text
            strong
            type="danger"
          >
            {LABEL_DECLINE}
          </Text>
          <Tooltip
            placement="top"
            title={formattedTooltipDecline}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      )
    }

    return (
      <Typography.Link
        strong
        onClick={onEdit}
      >
        <Space>
          <EditOutlined />
          {LABEL_EDIT}
        </Space>
      </Typography.Link>
    )
  }

  const cardStyle = isMobile && window.innerWidth - 16 < 520
    ? { width: window.innerWidth - 16 }
    : {}

  return (
    <Card
      id={investmentId}
      size={isMobile ? "small" : "medium"}
      type="inner"
      title={
        <Space>
          {profileTitle}
          <Text
            type="secondary"
            style={isMobile ? { width: window.innerWidth / 2 } : undefined}
            ellipsis={isMobile ? { tooltip: investmentName } : false}
          >({investmentName})</Text>
        </Space>
      }
      extra={getExtra()}
      styles={{ body: cardStyle }}
    >
      <Grid
        key="summary"
        style={gridStyle}
        hoverable={false}
      >
        <InvestmentProfile
          tiers={tiers}
          investment={investment}
        />
      </Grid>

      {
        <Grid
          key="documents"
          style={gridStyle}
          hoverable={false}
        >
          <InvestmentProfileCardSignDocuments
            investment={investment}
          />
        </Grid>
      }
      {
        <Grid
          key="receiverSource"
          style={gridStyle}
          hoverable={false}
        >
          <InvestmentProfileCardConnectReceiverSource
            investment={investment}
          />
        </Grid>
      }
      {
        <>
          <Grid
            key="funding"
            style={gridStyle}
            hoverable={false}
          >
            <InvestmentProfileCardSendFunding
              investment={investment}
              project={project}
            />
          </Grid>
        </>
    }
    </Card>
  )
}

InvestmentProfileCard.propTypes = {
  investment: InvestmentShape.isRequired,
}

export default InvestmentProfileCard
