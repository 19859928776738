import React from "react"
import PropTypes from "prop-types"

import { InvestmentShape } from "@components/Investment/shapes"

import getWireLabelKey from "./helpers/getWireLabelKey"
import { OpenWireModalButton } from "./WireModal"
import InvestmentProfileCardItem from "../InvestmentProfileCardItem"
import { WIRE_DEFAULT_LABELS_MAP } from "./helpers/Constants"


const FundAchDisabledInvestmentItem = ({
  investment,
  openWireModal,
  ...otherProps
}) => {
  const [ key, shouldRenderWireButton ] = getWireLabelKey(investment)
  const labels = WIRE_DEFAULT_LABELS_MAP[key]

  const itemProps = {
    ...labels,
    ...otherProps
  }

  return (
    <InvestmentProfileCardItem {...itemProps}>
      {
        shouldRenderWireButton && (
          <OpenWireModalButton onClick={openWireModal} />
        )
      }
    </InvestmentProfileCardItem>
  )
}

FundAchDisabledInvestmentItem.propTypes = {
  investment: InvestmentShape.isRequired,
  openWireModal: PropTypes.func.isRequired,
}

export default FundAchDisabledInvestmentItem
