import { TYPE_HIDDEN, TYPE_UPLOAD} from "@components/Form"

const LABEL_FILE = "File"


const getCreateInputs = () => {
  return [
    {
      name: "batchId",
      type: TYPE_HIDDEN,
    },
    {
      name: "projectId",
      type: TYPE_HIDDEN,
    },
    {
      name: "date",
      type: TYPE_HIDDEN,
    },
    {
      name: "documentType",
      type: TYPE_HIDDEN,
    },
    {
      name: "investmentId",
      type: TYPE_HIDDEN,
    },
    {
      name: "investorAccountId",
      type: TYPE_HIDDEN,
    },
    {
      name: "sourceFileName",
      type: TYPE_HIDDEN,
    },
    {
      name: "url",
      type: TYPE_UPLOAD,
      label: LABEL_FILE,
      inputProps: {
        onChange: (event, form) => {
          const { name } = event.file
          form.setFieldValue("sourceFileName", name)
        }
      }
    }
  ]
}

export default getCreateInputs
