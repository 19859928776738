import React, { useMemo } from "react"
import uniq from "lodash.uniq"
import keyBy from "lodash.keyby"

import PropTypes from "prop-types"

import { Markdown } from "@components/Text"
import { ProjectShape } from "@components/Project"
import ProjectValidationErrorShape from "@api/services/backstage/shapes/ProjectValidationErrorShape"

import getFundName from "./helpers/getFundName"

const ERRORS_MAP = {
  NO_TIERS: 'Define at least one investment class',
  NO_PARTNERS: 'Funding amount to be defined',
  NO_OPEN_DATE: 'Define the go-live date',
  NO_BANK_ACCOUNT: 'Connect verified bank account to receive funding',
  NO_TARGET_AMOUNT: 'Define the project funding amount',
  NO_PPM_TEMPLATE_ID: 'Select the PPM template',
  NO_TARGET_CLOSE_DATE: 'Define the target close of escrow date',
  NO_SIGN_DEADLINE_DATE: 'Define the signing deadline date',
  NO_FUND_DEADLINE_DATE: 'Define the funding deadline date',
  NO_NON_ACCREDITED_INVESTORS_MAX: 'Specify the maximum number of non-accredited investors',
}

const LABEL_HEADER = `
Prior to publishing the investment project, the following requirements must be
addressed.
`

const LABEL_FUND = `For **[FUND_NAME]** partner project:`


const getValidationErrorsList = (partnerId, errors) =>
  errors
    .filter(error => error.partnerId === partnerId)
    .map(({ code }) => `* ${ERRORS_MAP[code]}`)
    .join("\n")


const getText = (fundsMap, partnersMap, partnerIds, validationErrors) => {
  const getHeader = partnerId =>
    partnerId
      ? LABEL_FUND.replace('[FUND_NAME]', getFundName(fundsMap, partnersMap, partnerId))
      : ""

  const text = partnerIds
    .map(partnerId => `
${getHeader(partnerId)}\n
${getValidationErrorsList(partnerId, validationErrors)}`
    )
    .join("\n")

  return text
}


const ProjectValidationErrors = ({
  project,
  fundsMap,
  validationErrors
}) => {
  const { partners } = project
  const partnersMap = useMemo(() => keyBy(partners, "id"), [ partners ])

  const partnerIds = useMemo(() =>
    uniq(validationErrors.map(({ partnerId }) => partnerId))
  , [ validationErrors ])

  const text = [
    LABEL_HEADER,
    getText(fundsMap, partnersMap, partnerIds, validationErrors)
  ].join("\n")

  return (
    <Markdown text={text} />
  )
}

ProjectValidationErrors.propTypes = {
  project: ProjectShape.isRequired,
  fundsMap: PropTypes.shape().isRequired,
  validationErrors: PropTypes.arrayOf(ProjectValidationErrorShape).isRequired,
}

export default ProjectValidationErrors
