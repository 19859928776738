import React from "react"
import PropTypes from "prop-types"

import Form from "@components/Form/Form"
import { useAppContext } from "@components/AppContext"
import { OperationShape } from "@api"

import getFailedMessage from "./helpers/getFailedMessage"
import getSuccessMessage from "./helpers/getSuccessMessage"


const EntityForm = ({
  item = undefined,
  onSuccess = undefined,
  failedMessage = undefined,
  successMessage = undefined,
  entity,
  operation,
  ...otherProps
}) => {
  const {
    request,
    showErrorMessage,
    showSuccessMessage
  } = useAppContext()

  const { isCreate } = operation

  const afterSubmitSuccess = async (updatedItem) => {
    if (onSuccess) {
      await onSuccess(updatedItem)
    }

    if (successMessage === false) {
      return
    }

    const message = successMessage || getSuccessMessage(isCreate, entity, updatedItem)
    showSuccessMessage(message)
  }

  const onFailure = () => {
    if (failedMessage === false) {
      return
    }

    const message = failedMessage || getFailedMessage(isCreate, entity, item)
    showErrorMessage(message)
  }

  return (
    <Form
      item={item}
      request={request}
      operation={operation}
      onSuccess={afterSubmitSuccess}
      onFailure={onFailure}
      {...otherProps}
    />
  )
}

EntityForm.propTypes = {
  item: PropTypes.shape(),
  entity: PropTypes.string.isRequired,
  operation: OperationShape.isRequired,
  onSuccess: PropTypes.func,
  failedMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  successMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
}

export default EntityForm
