import React from "react"
import { NavLink } from "react-router-dom"
import { PlusOutlined, MinusOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"
import { INVESTMENT_STATUS } from "@components/Domain"

import isInvestmentDeleteable from "./isInvestmentDeleteable"

const LABEL_ACCEPT = "Accept"
const LABEL_BACKUP = "Backup"
const LABEL_DELETE = "Delete"
const LABEL_DROPOUT = "Dropout"
const LABEL_DROPOUT_NO_REASON = "No Reason"
const LABEL_DROPOUT_NOT_INTERESTED = "Not Interested"
const LABEL_DROPOUT_DEADLINE_PASSED = "Deadline Passed"
const LABEL_DROPOUT_NO_ACCREDITATION = "No Accreditation"


const getRowMenu = (investment, { deleteAction, acceptAction, backupAction, rejectAction }) => {
  const { id, status } = investment

  const canDelete = hasAccess(['investments-delete'])

  const isDeleteDisabled = !isInvestmentDeleteable(investment)

  const isBackup = status === INVESTMENT_STATUS.BACKUP
  const isAccepted = status === INVESTMENT_STATUS.ACTIVE
  const isRejected = status === INVESTMENT_STATUS.REJECTED

  const items = []

  if (!isAccepted) {
    items.push({
      key: "accept",
      icon: <PlusOutlined />,
      label: (
        <NavLink onClick={() => acceptAction(id)}>
          {LABEL_ACCEPT}
        </NavLink>
      )
    })
  }

  if (!isBackup) {
    items.push({
      key: "backup",
      icon: <MinusOutlined />,
      label: (
        <NavLink onClick={() => backupAction(id)}>
          {LABEL_BACKUP}
        </NavLink>
      )
    })
  }

  if (!isRejected) {
    items.push({
      key: "dropout",
      icon: <CloseOutlined />,
      label: LABEL_DROPOUT,
      children: [
        {
          danger: true,
          label: (
            <NavLink onClick={() => rejectAction(id, 'NO_ACCREDITATION')}>
              {LABEL_DROPOUT_NO_ACCREDITATION}
            </NavLink>
          )
        },
        {
          danger: true,
          label: (
            <NavLink onClick={() => rejectAction(id, 'DEADLINE_PASSED')}>
              {LABEL_DROPOUT_DEADLINE_PASSED}
            </NavLink>
          )
        },
        {
          danger: true,
          label: (
            <NavLink onClick={() => rejectAction(id, 'NOT_INTERESTED')}>
              {LABEL_DROPOUT_NOT_INTERESTED}
            </NavLink>
          )
        },
        {
          danger: true,
          label: (
            <NavLink onClick={() => rejectAction(id, 'NO_REASON')}>
              {LABEL_DROPOUT_NO_REASON}
            </NavLink>
          )
        },
      ]
    })
  }

  if (canDelete) {
    items.push({
      key: "delete",
      icon: <DeleteOutlined />,
      danger: true,
      disabled: isDeleteDisabled,
      label: (
        <NavLink onClick={() => deleteAction(id)}>
          {LABEL_DELETE}
        </NavLink>
      )
    })
  }

  return items
}

export default getRowMenu
