import React, { useState } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { Button, Modal, Spin } from "antd"

import { ORGANIZATION_DOMAIN_STATUS } from "@components/Domain"
import { ReadMyOrganizationOutputShape } from "@api/services/organizations"

import OrganizationDnsRecord from "./OrganizationDnsRecord"

const LABEL_TITLE = "Connect Domain"
const LABEL_REFRESH = "Refresh"

const MODAL_WIDTH = 820


const ConnectDomainModal = ({
  modal,
  isEditable,
  organization
}) => {
  const { updateOrganizationDomainStatus } = useOutletContext()

  const [ isOpen, setIsOpen ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)

  const { domainStatus } = organization

  const isConnected = domainStatus === ORGANIZATION_DOMAIN_STATUS.COMPLETE

  const handleClose = () =>
    setIsOpen(false)

  modal.current.open = () => setIsOpen(true)
  modal.current.close = () => handleClose()

  const onClick = () => {
    setIsLoading(true)
    return updateOrganizationDomainStatus()
      .then(() => setIsLoading(false))
  }

  const footer = isEditable && !isConnected
    ? (
        <Button
          type="primary"
          size="small"
          shape="round"
          onClick={onClick}
          disabled={isConnected}
        >
          {LABEL_REFRESH}
        </Button>
      )
    : null

  return (
    <Modal
      width={MODAL_WIDTH}
      title={LABEL_TITLE}
      open={isOpen}
      footer={footer}
      onCancel={handleClose}
      maskClosable={false}
    >

      <Spin spinning={isLoading}>
        <OrganizationDnsRecord
          organization={organization}
        />
      </Spin>
    </Modal>
  )
}

ConnectDomainModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  isEditable: PropTypes.bool.isRequired,
  organization: ReadMyOrganizationOutputShape.isRequired,
}

export default ConnectDomainModal
