import { Form as AntForm } from "antd"

import Form from "./Form"
import Header from "./Header"
import useForm from "./Form/helpers/useForm"
import FormShape from "./shapes/FormShape"
import EntityForm from "./EntityForm"
import BackButton from "./Form/BackButton"
import SubmitButton from "./Form/SubmitButton"
import renderFormItems from "./FormItem/renderFormItems"
import optimizeMutation from "./Form/helpers/optimizeMutation"
import FormNavigationFooter from "./Form/FormNavigationFooter"

export * from "./FormItem/types"
export * from "./FormItem/shapes"
export * from "./Input"

export default Form

const { useWatch } = AntForm

export {
  Form,
  Header,
  useForm,
  useWatch,
  FormShape,
  EntityForm,
  BackButton,
  SubmitButton,
  renderFormItems,
  optimizeMutation,
  FormNavigationFooter,
}
