import PropTypes from "prop-types"

import BusinessClassificationGroupShape from "./shapes/BusinessClassificationGroupShape"
import BusinessCustomerShape from "./shapes/BusinessCustomerShape"
import CustomerSourceShape from "./shapes/CustomerSourceShape"
import PersonalCustomerShape from "./shapes/PersonalCustomerShape"
import SoleProprietorshipCustomerShape from "./shapes/SoleProprietorshipCustomerShape"

const properties = {
  balanceAmount: PropTypes.number,
  businessClassificationGroups: PropTypes.arrayOf(BusinessClassificationGroupShape),
  businessCustomer: BusinessCustomerShape,
  businessVerificationFailedReasons: PropTypes.arrayOf(PropTypes.string),
  controllerEmail: PropTypes.string.isRequired,
  controllerId: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  customerEmail: PropTypes.string.isRequired,
  customerUrl: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  id: PropTypes.string.isRequired,
  identityVerificationFailedReasons: PropTypes.arrayOf(PropTypes.string),
  investorAccountId: PropTypes.string,
  isExtraBusinessDocumentRequested: PropTypes.bool,
  isImpersonated: PropTypes.bool,
  isSoleProprietorship: PropTypes.bool,
  lastBusinessDocumentUploadedAt: PropTypes.string,
  lastIdentityDocumentUploadedAt: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string.isRequired,
  organizationId: PropTypes.string,
  personalCustomer: PersonalCustomerShape,
  soleProprietorshipCustomer: SoleProprietorshipCustomerShape,
  sources: PropTypes.arrayOf(CustomerSourceShape),
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  upgradedAt: PropTypes.string,
  verificationStatus: PropTypes.string.isRequired,
  verificationStep: PropTypes.string
}

const ReadMyCustomerOutputShapeShape = PropTypes.exact(properties)

export default ReadMyCustomerOutputShapeShape
export { properties }
