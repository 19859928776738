import { useOutletContext } from "react-router-dom"

import { getCustomRequest } from "@components/Import"
import { getIsoDateFromStringDate } from "@components/Date"
import { getAmountWithoutCents, getFormattedDecimalValue, validateDecimalValue } from "@components/Amount"


const useImportTransactions = (investmentMap, callbackSuccess, setErrors) => {
  const { getProjectName } = useOutletContext()

  const parseRow = row => {
    const {
      "Date": date,
      "Amount": amount,
      "Description": description,
      "Reference ID": referenceId
    } = row

    return {
      date,
      referenceId,
      description,
      amount
    }
  }

  const validateRow = ({amount, referenceId = "-"}, rowIndex) => {
    const [ projectId, investmentId ] = referenceId.split("-")

    const investment = investmentMap[projectId]?.find(({id}) => id === investmentId)

    if (!investment) {
      setErrors((prevItems = []) => ([
        ...prevItems,
        {
          index: rowIndex,
          reason: `Investment id ${investmentId} is not found`
        }
      ]))

      return false
    }

    const isValidDecimalValue = validateDecimalValue(amount)

    if (!isValidDecimalValue) {
      setErrors((prevItems = []) => ([
        ...prevItems,
        {
          index: rowIndex,
          reason: `${referenceId} transaction amount has invalid format: $${amount}`
        }
      ]))

      return false
    }

    return true
  }

  const buildRow = ({description, referenceId, amount, date}) => {
    const [ projectId, investmentId ] = referenceId.split("-")

    const investment = investmentMap[projectId].find(({id}) => id === investmentId)

    const amountValue = getAmountWithoutCents(amount)

    const committedAmount = investment.investments
      .map(({amount: value}) => value)
      .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

    const transaction = {
      id: `${referenceId}-${amountValue}`,
      date: getIsoDateFromStringDate(date),
      amount: amountValue,
      projectName: getProjectName(projectId),
      investmentName: investment.name,
      description,
      referenceId,
      investmentId,
      committedAmount,
    }

    return transaction
  }

  const customRequest = getCustomRequest(parseRow, validateRow, buildRow, callbackSuccess)

  return { customRequest }
}

export default useImportTransactions
