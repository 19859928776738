import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Space, Descriptions } from "antd"
import { useOutletContext } from "react-router-dom"

import { useAppContext } from "@components/AppContext"
import { NotSourceController } from "@components/Investor"
import { updateMyInvestmentSourcesOperation } from "@api/services/investments"
import { isSdiraInvestment, isEntityInvestment } from "@components/Investment"
import { ConnectSourceButton, getSdiraBusinessName, SdiraConnectBankAccountAlert } from "@components/Dwolla"

import ConfirmRetryFailedTransactionsModal from "../ConfirmRetryFailedTransactionsModal"
import DistributionsConfirmModal
  from "../../investment/InvestmentFinalizePage/DistributionsConfirmModal"

import Investment from "./Investment"

const { Item } = Descriptions

const LABEL_INVESTMENTS = "Investments"
const LABEL_BANK_ACCOUNT = "Distributions Account"
const LABEL_NOT_CONNECTED = "Not Connected"
const LABEL_SDIRA_CONNECT_SOURCE = "Connect Custodian Bank Account"


const ProfileInvestments = ({
  receiverSourceId = undefined,
  receiverSourceName = LABEL_NOT_CONNECTED,
  profile,
  investmentIds,
}) => {
  const distributionsConfirmModal = useRef({})
  const confirmRetryFailedTransactionsModal = useRef({})

  const { request, isImpersonated, isSourceController } = useAppContext()
  const { updateInvestment } = useOutletContext()

  const onSelect = async (sourceId) => {
    const updatedInvestments = []
    for (const id of investmentIds) {
      const mutation = { receiverSourceId: sourceId }

      const parameters = { id, mutation }

      const { data: investment } = await request(updateMyInvestmentSourcesOperation, parameters)
      updatedInvestments.push(investment)
    }

    for (const investment of updatedInvestments) {
      updateInvestment(investment)
    }
  }

  const descriptionsProps = {
    size: "small",
    layout: "vertical",
    column: 1,
    bordered: false,
    className: "summary",
  }

  const connectSourceProps = {
    sourceId: receiverSourceId,
    onSelect,
    dropdownProps: {
      onSelectSource: (previousSource, callback) =>
        confirmRetryFailedTransactionsModal.current.open(previousSource, investmentIds, callback),
      onConnectPlaidSelected: onOk =>
        distributionsConfirmModal.current.open(onOk)
    }
  }

  const isSdira = isSdiraInvestment(profile)
  const isEntity = isEntityInvestment(profile)

  const isNotSourceController = !!receiverSourceId && !isSourceController(receiverSourceId)

  if (isEntity) {
    connectSourceProps.businessName = profile.name
  }

  if (isSdira) {
    const connectSourceHeader = <SdiraConnectBankAccountAlert />

    connectSourceProps.businessName = getSdiraBusinessName(profile.name)
    connectSourceProps.isReceiveOnly = true
    connectSourceProps.dropdownProps = {
      ...connectSourceProps.dropdownProps,
      connectSourceHeader,
      connectSourceTitle: LABEL_SDIRA_CONNECT_SOURCE,
    }
  }

  const renderConnectSource = () => {
    if (isImpersonated) {
      return receiverSourceName
    }

    return (
      <Space direction="vertical" size={0}>
        {
          isNotSourceController && (
            <NotSourceController
              sourceName={receiverSourceName}
            />
          )
        }

        <ConnectSourceButton
          {...connectSourceProps}
        />
        <DistributionsConfirmModal
          modal={distributionsConfirmModal}
        />
        <ConfirmRetryFailedTransactionsModal
          modal={confirmRetryFailedTransactionsModal}
        />
      </Space>
    )
  }

  return (
    <Descriptions {...descriptionsProps}>
      <Item
        key="investments"
        label={LABEL_INVESTMENTS}
      >
        <Space direction="vertical" style={{ width: "100%" }} size={2}>
          {
            investmentIds.map(investmentId =>
              <Investment
                key={investmentId}
                investmentId={investmentId}
              />
            )
          }
        </Space>
      </Item>

      <Item
        key="bankAccount"
        label={LABEL_BANK_ACCOUNT}
      >
        {renderConnectSource()}
      </Item>
    </Descriptions>
  )
}

ProfileInvestments.propTypes = {
  profile: PropTypes.shape().isRequired,
  investmentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  receiverSourceId: PropTypes.string,
  receiverSourceName: PropTypes.string,
}

export default ProfileInvestments
