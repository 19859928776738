import PropTypes from "prop-types"

import CustomerSourceShape from "./shapes/CustomerSourceShape"

const properties = {
  businessVerificationFailedReasons: PropTypes.arrayOf(PropTypes.string),
  controllerEmail: PropTypes.string.isRequired,
  controllerId: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  customerEmail: PropTypes.string.isRequired,
  customerUrl: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  id: PropTypes.string.isRequired,
  identityVerificationFailedReasons: PropTypes.arrayOf(PropTypes.string),
  investorAccountId: PropTypes.string,
  isExtraBusinessDocumentRequested: PropTypes.bool,
  isImpersonated: PropTypes.bool,
  isSoleProprietorship: PropTypes.bool,
  lastBusinessDocumentUploadedAt: PropTypes.string,
  lastIdentityDocumentUploadedAt: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string.isRequired,
  organizationId: PropTypes.string,
  sources: PropTypes.arrayOf(CustomerSourceShape),
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  upgradedAt: PropTypes.string,
  verificationStatus: PropTypes.string.isRequired,
  verificationStep: PropTypes.string
}

const CreateImpersonatedUnverifiedBusinessCustomerOutputShapeShape = PropTypes.exact(properties)

export default CreateImpersonatedUnverifiedBusinessCustomerOutputShapeShape
export { properties }
