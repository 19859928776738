import React from "react"
import PropTypes from "prop-types"
import { Alert, List, Typography, Space } from "antd"

const LABEL_TITLE = "Incomplete Profile"


const InvestmentErrors = ({
  title = LABEL_TITLE,
  errors = [],
  isFunded
}) => {
  const dataSource = errors.map((text, key) => ({ key, text }))
  const hasErrors = dataSource.length > 0

  if (!hasErrors) {
    return null
  }

  if (isFunded) {
    return null
  }

  return (
    <Alert
      type="error"
      style={{ padding: "14px 18px" }}
      message={title}
      description={
        <List
          size="small"
          gutter={0}
          itemLayout="vertical"
          dataSource={dataSource}
          renderItem={item =>
            <List.Item style={{ padding: "2px 0px 2px 0px", border: "none" }}>
              <Space>
                <Typography.Text strong>&bull;</Typography.Text>
                <Typography.Text>{item.text}</Typography.Text>
              </Space>
            </List.Item>
          }
        />
      }
    />
  )
}

InvestmentErrors.propTypes = {
  title: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  isFunded: PropTypes.bool.isRequired,
}

export default InvestmentErrors
