import React from "react"
import { Button, Space, Tooltip } from "antd"
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"

import {
  TRANSACTION_TYPES,
  TRANSACTION_TYPE_ABBREVIATIONS_MAP
} from "@components/Domain"
import { Value } from "@components/Text"


const getTitleByType = type => {
  const { title: columnTitle, description } = TRANSACTION_TYPE_ABBREVIATIONS_MAP[type]
  return (
    <Space>
      <span>{columnTitle}</span>
      <Tooltip
        placement="top"
        title={description}
      >
        <QuestionCircleOutlined />
      </Tooltip>
    </Space>
  )
}

const getCollectionColumns = handleDelete => ([
  {
    title: "",
    editable: false,
    dataIndex: "rowIndex",
  },
  {
    title: "Beneficiary",
    editable: false,
    dataIndex: "name",
  },
  {
    title: "Email",
    editable: false,
    dataIndex: "email",
  },
  {
    title: () => getTitleByType(TRANSACTION_TYPES.INVESTMENT_RETURN),
    editable: false,
    width: "11%",
    align: "right",
    dataIndex: "returnOnInvestment",
    render: (_, {returnOnInvestment}) => <Value value={returnOnInvestment} />
  },
  {
    title: () => getTitleByType(TRANSACTION_TYPES.SALE_PROFIT),
    editable: false,
    width: "11%",
    align: "right",
    dataIndex: "profitOnSale",
    render: (_, {profitOnSale}) => <Value value={profitOnSale} />
  },
  {
    title: () => getTitleByType(TRANSACTION_TYPES.CAPITAL_RETURN),
    editable: false,
    width: "11%",
    align: "right",
    dataIndex: "returnOfCapital",
    render: (_, {returnOfCapital}) => <Value value={returnOfCapital} />
  },
  {
    title: () => getTitleByType(TRANSACTION_TYPES.SPONSOR_PROMOTE),
    editable: false,
    width: "11%",
    align: "right",
    dataIndex: "sponsorPromote",
    render: (_, {sponsorPromote}) => <Value value={sponsorPromote} />
  },
  {
    title: "Sum",
    editable: false,
    width: "11%",
    align: "right",
    dataIndex: "total",
    render: (_, {total}) => <Value value={total} />
  },
  {
    title: "",
    width: 60,
    editable: false,
    dataIndex: "operation",
    render: (_, record, index) =>
      <Button
        type="link"
        icon={<DeleteOutlined />}
        onClick={() => handleDelete(record, index)}
      />
  },
])

export default getCollectionColumns
