import capitalize from "lodash.capitalize"

export const DRAFT = "DRAFT"
export const PUBLISHED = "PUBLISHED"

const CampaignStatusEnum = {
  [DRAFT]: DRAFT,
  [PUBLISHED]: PUBLISHED
}

const CampaignStatusEnumKeys = Object.keys(CampaignStatusEnum)

const CampaignStatusEnumOptions = CampaignStatusEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  CampaignStatusEnumKeys,
  CampaignStatusEnumOptions
}

export default CampaignStatusEnum
