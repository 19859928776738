import { TYPE_AMOUNT } from "@components/Form"

const LABEL_AMOUNT_1 = "Amount 1"
const LABEL_AMOUNT_2 = "Amount 2"


const getInputs = () => {
  return [
    {
      name: "amount1",
      type: TYPE_AMOUNT,
      label: LABEL_AMOUNT_1,
      placeholder: "0.01",
      inputProps: {
        style: { width: 160 },
        step: 0.01,
      }
    },
    {
      name: "amount2",
      type: TYPE_AMOUNT,
      label: LABEL_AMOUNT_2,
      placeholder: "0.01",
      inputProps: {
        style: { width: 160 },
        step: 0.01,
      }
    },
  ]
}

export default getInputs
