import React from "react"
import last from "lodash.last"
import PropTypes from "prop-types"
import {Button, Form, Typography, Row, Col} from "antd"
import { MinusCircleOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons"

import { W0 } from "@components"
import { InputShape } from "@api"

import FormShape from "../shapes/FormShape"
import renderFormItems from "../FormItem/renderFormItems"

import "./ListInput.css"

const { Paragraph } = Typography

const LABEL_REMOVE = "Remove"


const ListInput = ({
  min = undefined,
  max = undefined,
  title = null,
  className = "",
  removeTitle = LABEL_REMOVE,
  initialValue = [],
  onlyLastDelete = false,
  form,
  name,
  path,
  schema,
  addTitle,
}) => {
  const hasAddButton = items => {
    if (!max) {
      return true
    }

    return items.length < max
  }

  const isItemRemovable = items => {
    if (onlyLastDelete) {
      return false
    }

    if (!min) {
      return true
    }

    return items.length !== min
  }

  const removeLast = (items, onRemove) => {
    const lastItem = last(items)
    onRemove(lastItem.name)
  }

  const hasRemoveButton = (items) => {
    if (!onlyLastDelete) {
      return false
    }

    if (min) {
      return items.length !== min
    }

    return items.length > 0
  }

  return (
    <>
      {
        title && (
          <Paragraph strong>{title}</Paragraph>
        )
      }

      <Form.List
        name={name}
        initialValue={initialValue}
      >
        {
          (items, { add, remove }) => (
            <>
              {
                items.map(item => (
                  <Row key={item.key} className={className}>
                    {
                      renderFormItems(form, schema, [ item.name ], [ ...path, item.name ])
                    }

                    {
                      isItemRemovable(items) && (
                        <div className="minus-icon">
                          <MinusCircleOutlined onClick={() => remove(item.name)} />
                        </div>
                      )
                    }
                  </Row>
                ))
              }

              {
                !onlyLastDelete && (
                  <Form.Item>
                    <Button
                      block
                      type="dashed"
                      icon={<PlusOutlined />}
                      onClick={() => add()}
                    >
                      {addTitle}
                    </Button>
                  </Form.Item>
                )
              }

              {
                onlyLastDelete && (
                  <Row gutter={W0}>
                    {
                      hasAddButton(items) && (
                        <Col span={12}>
                          <Button
                            block
                            type="dashed"
                            icon={<PlusOutlined />}
                            onClick={() => add()}
                          >
                            {addTitle}
                          </Button>
                        </Col>
                      )
                    }

                    {
                      hasRemoveButton(items) && (
                        <Col span={12}>
                          <Button
                            block
                            danger
                            type="dashed"
                            icon={<MinusOutlined />}
                            onClick={() => removeLast(items, remove)}
                          >
                            {removeTitle}
                          </Button>
                        </Col>
                      )
                    }
                  </Row>
                )
              }
            </>
          )
        }
      </Form.List>
    </>
  )
}

ListInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  form: FormShape.isRequired,
  name: PropTypes.arrayOf(PropTypes.string).isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  schema: PropTypes.arrayOf(InputShape).isRequired,
  addTitle: PropTypes.string.isRequired,
  className: PropTypes.string,
  removeTitle: PropTypes.string,
  onlyLastDelete: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.any,
}

export default ListInput
