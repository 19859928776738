import { useOutletContext } from "react-router-dom"

import { getFormattedIsoDateTime } from "@components/Date"

const LABEL_SENT = "Sent"
const LABEL_EMAIL = "Email"
const LABEL_OPENED = "Opened"
const LABEL_CLICKED = "Clicked"
const LABEL_INVESTOR = "Investor"
const LABEL_DELIVERED = "Delivered"


const useExportColumns = () => {
  const { computeAccountFullname } = useOutletContext()

  const columns = [
    {
      value: ({ investorAccountId }) => computeAccountFullname(investorAccountId),
      title: LABEL_INVESTOR,
    },
    {
      value: ({ recipientEmail }) => recipientEmail,
      title: LABEL_EMAIL,
    },
    {
      value: ({ sentAt }) => getFormattedIsoDateTime(sentAt),
      title: LABEL_SENT,
    },
    {
      value: ({ deliveredAt }) => getFormattedIsoDateTime(deliveredAt),
      title: LABEL_DELIVERED,
    },
    {
      value: ({ openedAt }) => getFormattedIsoDateTime(openedAt),
      title: LABEL_OPENED,
    },
    {
      value: ({ clickedAt }) => getFormattedIsoDateTime(clickedAt),
      title: LABEL_CLICKED,
    },
  ]

  return columns
}

export default useExportColumns
