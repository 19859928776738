import keyBy from "lodash.keyby"

import { COUNTRY_US } from "@components/Address"

const CUSTOMER_PERSONAL = "Personal"


const getExtendedBankAccounts = (customers, bankAccounts = []) => {
  const bankAccountsMap = keyBy(bankAccounts, "beneficiaryName")

  const customerBeneficiaries = customers
    .filter(({ name }) => !bankAccountsMap[name])
    .filter(({ name }) => name !== CUSTOMER_PERSONAL)
    .filter(({ investorAccountId }) => !investorAccountId)
    .map(({ name: beneficiaryName }) => ({
      beneficiaryName,
      beneficiaryAddress: {
        streetAddress: null,
        addressLocality: null,
        addressRegion: null,
        postalCode: null,
        addressCountry: COUNTRY_US
      },
    }))

  return [ ...customerBeneficiaries, ...bankAccounts ]
}

export default getExtendedBankAccounts
