import React, { useMemo } from "react"
import { App } from "antd"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import List from "@components/List"
import { openNewTab } from "@components/Utils"
import { indexOperation } from "@components/Store/InvestorStore/useDocumentsStore"
import { useProjectItems } from "@components/Project"

import useRenderItem from "./helpers/useRenderItem"

const LABEL_OPEN = "Open"
const LABEL_TITLE = "Documents"
const LABEL_CONTENT = "Open the document in a new tab."
const LABEL_OPEN_DOCUMENT = "Open Document"


const DocumentsList = ({ table }) => {
  const { modal } = App.useApp()

  const {
    documents,
    indexDocuments: indexItems,
  } = useOutletContext()

  const items = useProjectItems(documents)

  const sortedItems = useMemo(() =>
    items?.sort((a, b) => a.date > b.date ? -1 : 1)
  , [ items ])

  const render = useRenderItem()

  const onClick = ({ url }) =>
    modal.confirm({
      icon: null,
      onOk: () => openNewTab(url),
      title: LABEL_OPEN_DOCUMENT,
      okText: LABEL_OPEN,
      content: LABEL_CONTENT,
    })

  const listProps = {
    title: LABEL_TITLE,
    items: sortedItems,
    table,
    render,
    onClick,
    indexItems,
    indexOperation,
  }

  return (
    <List {...listProps} />
  )
}

DocumentsList.propTypes = {
  table: PropTypes.shape().isRequired,
}

export default DocumentsList
