import { TYPE_NONE } from "@components/Form"
import { INVESTMENT_PROFILE_FIELD_MAP } from "@components/Domain"

/* td: expose constant, to do not have magic numbers */
const INVESTMENTS_INPUT_INDEX = 5


const extendBlockedInputs = (schema, investment, blockingFieldName) => {
  if (!blockingFieldName) {
    return
  }

  schema[INVESTMENTS_INPUT_INDEX].type = TYPE_NONE

  const { profileType } = investment

  const profileFieldName = INVESTMENT_PROFILE_FIELD_MAP[profileType]
  const profileFieldIndex = schema.findIndex(input => input.name === profileFieldName)

  const { schema: profileSchema } = schema[profileFieldIndex]

  let shouldSetNone = false

  for (const input of profileSchema) {
    if (shouldSetNone) {
      input.type = TYPE_NONE
    }

    if (input.name === blockingFieldName) {
      shouldSetNone = true
    }
  }
}

export default extendBlockedInputs
