import PropTypes from "prop-types"


const properties = {
  bouncesCount: PropTypes.number.isRequired,
  clicksCount: PropTypes.number.isRequired,
  complainsCount: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  deliveriesCount: PropTypes.number.isRequired,
  emailsCount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  opensCount: PropTypes.number.isRequired,
  sendsCount: PropTypes.number.isRequired,
  uniqueClicksCount: PropTypes.number.isRequired,
  uniqueOpensCount: PropTypes.number.isRequired,
  unsubscribesCount: PropTypes.number.isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const ReadCampaignMetricsOutputShapeShape = PropTypes.exact(properties)

export default ReadCampaignMetricsOutputShapeShape
export { properties }
