import { COUNTRY_US } from "@components/Address"

import getTargetAttributeOptions from "./getTargetAttributeOptions"
import getTargetAttributeOnChange from "./getTargetAttributeOnChange"


const getBeneficiaryNameProps = (bankAccounts = []) => {
  const options = getTargetAttributeOptions(bankAccounts, "beneficiaryName")

  const inputProps = {
    onChange: getTargetAttributeOnChange(bankAccounts, "beneficiaryName", {
      beneficiaryAddress: {
        streetAddress: null,
        addressLocality: null,
        addressRegion: null,
        postalCode: null,
        addressCountry: COUNTRY_US
      },
    })
  }

  return {
    options,
    inputProps,
    hasAutoComplete: true,
  }
}

export default getBeneficiaryNameProps
