import React from "react"
import { PlusOutlined, ImportOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"

const LABEL_CREATE = "Create"
const LABEL_IMPORT = "Import"


const getHeaderActions = (openCreateModal, openImportModal) => {
  const canCreateInvestments = hasAccess(['investments-write'])

  if (!canCreateInvestments) {
    return []
  }

  return [
    {
      icon: <PlusOutlined />,
      action: openCreateModal,
      title: LABEL_CREATE,
    },
    {
      icon: <ImportOutlined />,
      action: openImportModal,
      title: LABEL_IMPORT,
    },
  ]
}

export default getHeaderActions
