import PropTypes from "prop-types"

import UsAddressShape from "./UsAddressShape"

const properties = {
  address: UsAddressShape.isRequired,
  businessClassificationId: PropTypes.string.isRequired,
  businessName: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.string,
  ein: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  ssn: PropTypes.string
}

const SoleProprietorshipCustomerShape = PropTypes.exact(properties)

export default SoleProprietorshipCustomerShape
export { properties }
