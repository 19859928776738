import { INVESTMENT_PROFILE_TYPE_INDIVIDUAL } from "@components/Domain"

const LABEL_NOT_AVAILABLE = "Not Available"


const computeInvestor = investment => {
  if (!investment) {
    return LABEL_NOT_AVAILABLE
  }

  const { name, investor, profileType } = investment

  const isIndividual = profileType === INVESTMENT_PROFILE_TYPE_INDIVIDUAL
  const investorName = `${investor.givenName} ${investor.familyName}`

  if (isIndividual) {
    return investorName
  }

  return `${investorName} / ${name}`
}

export default computeInvestor
