import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Form as AntForm, Spin } from "antd"

import { OperationShape, InputShape } from "@api"

import useForm from "./helpers/useForm"
import FormShape from "../shapes/FormShape"
import FormSubmit from "./FormSubmit"
import useFinishProps from "./helpers/useFinishProps"
import renderFormItems from "../FormItem/renderFormItems"


const Form = ({
  form: customForm = undefined,
  formProps: extraFormProps = {},
  item = undefined,
  schema = null,
  request = undefined,
  onSubmit = parameters => parameters,
  onSuccess = () => {},
  onFailure = () => {},
  submitLabel = undefined,
  onSubmitFailed = () => {},
  expectedErrors = [],
  hasSubmitButton = true,
  submitItemProps = undefined,
  onSubmitConfirm = (parameters, action) => action(),
  submitButtonProps = undefined,
  shouldOptimizeUpdateMutation = true,
  operation,
}) => {
  const form = useForm(customForm)

  const [ isSubmitting, setIsSubmitting ] = useState(false)

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(item)
  }, [ form, item ])

  const finishProps = useFinishProps({
    item,
    request,
    onSubmit,
    onSuccess,
    onFailure,
    operation,
    isSubmitting,
    expectedErrors,
    onSubmitFailed,
    onSubmitConfirm,
    setIsSubmitting,
    shouldOptimizeUpdateMutation,
  })

  schema = schema
    ? schema
    : operation.getSchema()

  form.isSubmitting = () => isSubmitting

  const shouldRenderSubmit = request && hasSubmitButton

  const isLoading = operation.isUpdate && !item

  return (
    <Spin spinning={isLoading}>
      <AntForm
        form={form}
        layout="vertical"
        autoComplete="off"
        scrollToFirstError={true}
        {...finishProps}
        {...extraFormProps}
      >
        {renderFormItems(form, schema)}

        {shouldRenderSubmit && (
          <FormSubmit
            isLoading={isSubmitting}
            operation={operation}
            submitLabel={submitLabel}
            submitItemProps={submitItemProps}
            submitButtonProps={submitButtonProps}
          />
        )}
      </AntForm>
    </Spin>
  )
}

Form.propTypes = {
  item: PropTypes.shape(),
  form: FormShape,
  schema: PropTypes.arrayOf(InputShape),
  request: PropTypes.func,
  onSubmit: PropTypes.func,
  formProps: PropTypes.shape(),
  operation: OperationShape.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  submitLabel: PropTypes.string,
  onSubmitFailed: PropTypes.func,
  expectedErrors: PropTypes.arrayOf(PropTypes.string),
  onSubmitConfirm: PropTypes.func,
  hasSubmitButton: PropTypes.bool,
  submitItemProps: PropTypes.shape(),
  submitButtonProps: PropTypes.shape(),
  shouldOptimizeUpdateMutation: PropTypes.bool,
}

export default Form
