import React from "react"

import ActionButtons from "../ActionButtons"
import VerificationStatus from "../VerificationStatus"

const LABEL_OWNER = "Owner"
const LABEL_STATUS = "Status"


const getColumns = (customerId, openUpdateModal, openUploadModal, onSuccess) => {
  return [
    {
      key: "firstName",
      title: LABEL_OWNER,
      render: ({ firstName, lastName }) => `${firstName} ${lastName}`
    },
    {
      key: "verificationStatus",
      title: LABEL_STATUS,
      align: "center",
      render: ({ verificationStatus }) => (
        <VerificationStatus status={verificationStatus} />
      )
    },
    {
      key: "id",
      title: "",
      width: 100,
      align: "right",
      render: beneficialOwner => (
        <ActionButtons
          onSuccess={onSuccess}
          customerId={customerId}
          beneficialOwner={beneficialOwner}
          openUpdateModal={() => openUpdateModal(beneficialOwner)}
          openUploadModal={() => openUploadModal(beneficialOwner)}
        />
      )
    },
  ]
}

export default getColumns
