import React from "react"
import PropsTypes from "prop-types"

import { UpdateModal } from "@components/Page"
import { MODAL_WIDTH2 } from "@components"
import { cloneOperation } from "@components/Store/BackstageStore/useCampaignsStore"

import getInputs from "./helpers/getInputs"

const inputs = getInputs()
const schema = cloneOperation.getSchema(inputs)

const LABEL_ENTITY = "Campaign"
const LABEL_TITLE = `Clone ${LABEL_ENTITY}`
const LABEL_SUBMIT = "Clone"


const CampaignCloneModal = ({ modal, onSuccess }) => {
  const updateModalProps = {
    width: MODAL_WIDTH2,
    title: LABEL_TITLE,
    entity: LABEL_ENTITY,
    operation: cloneOperation,
    submitLabel: LABEL_SUBMIT,
    successMessage: false,
    modal,
    schema,
    onSuccess,
  }

  return <UpdateModal {...updateModalProps} />
}

CampaignCloneModal.propTypes = {
  modal: PropsTypes.shape().isRequired,
  onSuccess: PropsTypes.func.isRequired,
}

export default CampaignCloneModal
