import React from "react"
import { Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import { ago } from "@components/Date"
import Identity from "@components/Identity"
import { hasAccess } from "@components/Authorization"
import { useAppContext } from "@components/AppContext"
import { SPONSOR_TYPE_OPTIONS } from "@components/Domain"

import TableRowActions from "../../UsersTable/TableRowActions"


const useColumnsConfig = (openUpdateSponsorModal, openUpdateBrokerModal, openUpdateMemberModal) => {
  const {
    getOrganizationName
  } = useAppContext()

  const {
    getSponsor,
    getSponsorName,
    computeUserFullname
  } = useOutletContext()

  const organizationName = getOrganizationName()

  const canUpdateSponsor = hasAccess([ "sponsors-write" ])

  const nameColumnProps = {
    key: "name",
    title: "Liaison",
    compute: ({ id }) => computeUserFullname(id),
    render: (name, { id }) =>
      <Identity strong id={id}>{name}</Identity>,
    isSearchable: true,
  }

  const emailColumnProps = {
    key: "email",
    title: "Email",
    isSearchable: true,
  }

  const sponsorColumnProps = {
    key: "sponsorId",
    title: "Sponsor",
    compute: ({ sponsorId }) => getSponsorName(sponsorId),
    render: (value, { sponsorId }) => {
      const sponsorName = getSponsorName(sponsorId)
      const isMainSponsor = sponsorName === organizationName

      const isEditable = canUpdateSponsor && !isMainSponsor

      if (!isEditable) {
        return (
          <Typography.Text style={{ whiteSpace: "nowrap" }}>
            {value}
          </Typography.Text>
        )
      }

      return (
        <Typography.Link
          onClick={() => openUpdateSponsorModal(sponsorId)}
          style={{ whiteSpace: "nowrap" }}
        >
          {value}
        </Typography.Link>
      )
    },
    isSearchable: true,
  }

  const sponsorTypeColumnProps = {
    key: "_sponsorType",
    title: "Type",
    compute: ({ sponsorId }) => {
      const { type: sponsorType } = getSponsor(sponsorId)

      if (!sponsorType) {
        return
      }

      const sponsorTypeLabel = SPONSOR_TYPE_OPTIONS
        .find(({ value: type }) => type === sponsorType)
        ?.label

      return sponsorTypeLabel
    },
    isSearchable: true,
  }

  const lastAuthenticatedAtColumnProps = {
    key: "lastAuthenticatedAt",
    title: "Last Signed In",
    render: value => ago(value, true)
  }

  const actionsColumnProps = {
    key: "actions",
    title: false,
    render: (_, broker) => {
      const { roles } = broker

      const hasMultipleRoles = roles.length > 1

      let openUpdateModal = openUpdateBrokerModal

      if (hasMultipleRoles) {
        openUpdateModal = openUpdateMemberModal
      }

      return (
        <TableRowActions
          user={broker}
          openUpdateModal={openUpdateModal}
        />
      )
    }
  }

  const columns = [
    { ...nameColumnProps },
    { ...emailColumnProps },
    { ...sponsorColumnProps },
    { ...sponsorTypeColumnProps },
    { ...lastAuthenticatedAtColumnProps },
  ]

  const canUpdateBroker = hasAccess([ "sponsors-write", "users-write" ])

  if (canUpdateBroker) {
    columns.push(
      { ...actionsColumnProps, width: 48 },
    )
  }

  return columns
}

export default useColumnsConfig
