import React from "react"
import PropTypes from "prop-types"
import { Card } from "antd"

import { Table } from "@components/Table"
import { OperationShape } from "@api"

import "./List.css"


const List = ({
  table = undefined,
  banner = undefined,
  onClick = undefined,
  title,
  render,
  indexOperation,
  ...otherTableProps
}) => {
  const style = { width: "100%" }

  const columnsConfig = [
    {
      key: "id",
      render,
    }
  ]

  const onRow = item => ({
    onClick: () => onClick ? onClick(item) : {}
  })

  const tableProps = {
    bordered: false,
    className: "list-mobile",
    showHeader: false,
    shouldRenderHeader: false,
    onRow,
    style,
    table,
    columnsConfig,
    indexOperation,
    ...otherTableProps
  }

  const bannerStyle = {
    padding: "8px 12px",
    width: "100%",
    boxShadow: "none",
    borderBottom: "1px solid #f0f0f0",
  }

  const contentStyle = {
    padding: 0,
    width: "100%",
    boxShadow: "none"
  }

  return (
    <Card
      type="inner"
      size={"small"}
      title={title}
      styles={{ body: { padding: 0, paddingTop: 1 }}}
      bordered={false}
      className="card-mobile"
    >
      {
        banner && (
          <Card.Grid
            style={bannerStyle}
            hoverable={false}
          >
            {banner}
          </Card.Grid>
        )
      }
      <Card.Grid
        style={contentStyle}
        hoverable={false}
      >
        <Table {...tableProps} />
      </Card.Grid>
    </Card>
  )
}

List.propTypes = {
  table: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  banner: PropTypes.shape(),
  onClick: PropTypes.func,
  indexOperation: OperationShape.isRequired,
}

export default List
