import uniq from "lodash.uniq"
import intersection from "lodash.intersection"


const getFiltersMap = filterValues => {
  const filtersMap = {}

  for (const filterKeyValue of filterValues) {
    const [key, value] = filterKeyValue.split(":")

    const values = filtersMap[key]

    if (values) {
      filtersMap[key].push(value)

    } else {
      filtersMap[key] = [value]

    }
  }

  return filtersMap
}


const computeRecipientAccountIds = (filter, segments, segmentIds = []) => {
  const orFilterValues = segments
    .filter(option => segmentIds.includes(option.id))
    .map(({ description }) => description.split("::"))

  const hasFilterValues = orFilterValues.length > 0

  if (!hasFilterValues) {
    return []
  }

  const orFilterMaps = orFilterValues
    .map(filterValues => getFiltersMap(filterValues))

  const getResultsForFiltersMap = filtersMap => {
    const accountIds = Object
      .entries(filtersMap)
      .map(([ key, values ]) => filter(values, key))

    const result = intersection(...accountIds)
    return result
  }

  const results = orFilterMaps
    .reduce((acc, filtersMap) => [
      ...acc, ...getResultsForFiltersMap(filtersMap)
    ], [])

  return uniq(results)
}

export default computeRecipientAccountIds
