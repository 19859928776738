import React from "react"
import { Typography, Alert } from "antd"

import { Markdown } from "@components/Text"
import { useAppContext } from "@components/AppContext"


const NoTinWarningAlert = () => {
  const { identity } = useAppContext()

  const { organization } = identity

  const {
    name: organizationName,
    email: organizationEmail
  } = organization

  const text = `A taxpayer identification number (TIN) is required in order to
fund your commitment.

${organizationName} will need to collect a TIN from you in order to proceed
with your investment. If you do not have a TIN, please visit the IRS website
for instructions regarding how to obtain an ITIN for yourself or an EIN for
your entity (if applicable).

Please reach out to [${organizationEmail}](mailto:${organizationEmail}) if you
have any questions.`

  const components = {
    p(props) {
      // eslint-disable-next-line react/prop-types
      const { children } = props

      return (
        <Typography.Paragraph>
          {children}
        </Typography.Paragraph>
      )
    }
  }

  return (
    <Alert
      showIcon
      type="warning"
      message="Warning"
      description={
        <Typography.Text type="secondary">
          <Markdown text={text} components={components} />
        </Typography.Text>
      }
    />
  )
}

export default NoTinWarningAlert
