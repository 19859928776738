import React from "react"
import PropTypes from "prop-types"
import { Menu } from "antd"
import { useNavigate } from "react-router-dom"

import { W0 } from "@components"


const PageMenu = ({ links }) => {
  const navigate = useNavigate()

  const items = links.map(({ title: label, path: key }) => ({ label, key }))

  const { pathname } = window.location

  const selectedKeys = links
    .map(({ path }) => path)
    .filter(path => pathname.startsWith(path))

  const onClick = ({ key }) => navigate(key)

  return (
    <Menu
      mode="horizontal"
      items={items}
      onClick={onClick}
      style={{ paddingLeft: W0, paddingTop: 3 }}
      selectedKeys={selectedKeys}
    />
  )
}

PageMenu.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape()
    ]).isRequired,
  })).isRequired
}

export default PageMenu
