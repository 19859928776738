import { useState } from "react"

import getInputs from "./getInputs"


const useInputs = (operation, form) => {
  const [ schema, setSchema ] = useState([])

  const updateSchema = (beneficialOwner) => {
    const { address: formAddress = {} } = form.getFieldsValue()
    const { address = formAddress } = beneficialOwner || {}

    const inputs = getInputs(updateSchema, address)
    const updatedSchema = operation.getSchema(inputs)

    setSchema(updatedSchema)
  }

  return [ schema, updateSchema ]

}

export default useInputs
