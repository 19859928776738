import { useEffect, useState, useMemo } from "react"
import { useOutletContext } from "react-router-dom"

import { useForm } from "@components/Form"
import { createDistributionTransactionOperation as operation } from "@api/services/transactions"

import getCreateInputs from "./getCreateInputs"
import getTransactionDetailsValue from "./getTransactionDetailsValue"


const useSchema = (investments) => {
  const form = useForm()

  const [ schema, setSchema ] = useState([])

  const { project } = useOutletContext()

  const createInputs = useMemo(() => {
    const investmentsOptions = investments
      .map(({ id: value, name: label }) => ({ value, label }))

    return getCreateInputs(investmentsOptions)
  }, [ investments ])

  useEffect(() => {
    setSchema(operation.getSchema(createInputs))
  }, [ createInputs ])

  const onSubmit = parameters => {
    const { name: projectName } = project

    const { mutation } = parameters
    const { date, investmentId } = mutation

    const currentInvestment = investments
      .find(({ id }) => id === investmentId)

    const entityName = currentInvestment.name

    parameters.mutation.details = getTransactionDetailsValue(date, entityName, projectName)

    return parameters
  }

  return [ form, schema, operation, onSubmit ]
}

export default useSchema
