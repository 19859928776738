import PropTypes from "prop-types"

import { consoleStoreProperties } from "@components/Store/ConsoleStore/useConsoleStore"


const ConsoleLayoutContextShape = PropTypes.exact({
  layoutTopOffset: PropTypes.number.isRequired,
  ...consoleStoreProperties,
})

export default ConsoleLayoutContextShape
