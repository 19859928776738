import { getFormattedDecimalValue } from "@components/Amount"


const computeInvestmentAmount = investment => {
  const { investments = [] } = investment

  const totalAmount = investments
    .map(({ amount }) => amount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  return totalAmount
}

export default computeInvestmentAmount
