import {
  SALE_PROFIT,
  CAPITAL_RETURN,
  SPONSOR_PROMOTE,
  INVESTMENT_RETURN,
} from "@api/services/transactions/shapes/TransactionTypeEnum"
import { getTransactionTypeLabel } from "@components/Transaction"
import { TYPE_AMOUNT, TYPE_RADIO, TYPE_NONE } from "@components/Form"
import { TRANSACTION_TYPES, TRANSACTION_AMOUNT_MAX } from "@components/Domain"

const TRANSACTION_TYPE_OPTIONS = [
  {
    value: SALE_PROFIT,
    label: getTransactionTypeLabel(SALE_PROFIT),
  },
  {
    value: CAPITAL_RETURN,
    label: getTransactionTypeLabel(CAPITAL_RETURN),
  },
  {
    value: INVESTMENT_RETURN,
    label: getTransactionTypeLabel(INVESTMENT_RETURN),
  },
  {
    value: SPONSOR_PROMOTE,
    label: getTransactionTypeLabel(SPONSOR_PROMOTE),
  },
]


const getCreateInputs = investmentsOptions => {
  const inputs = [
    {
      name: "details",
      type: TYPE_NONE,
    },
    {
      name: "type",
      type: TYPE_RADIO,
      label: "Type",
      options: TRANSACTION_TYPE_OPTIONS,
      initialValue: TRANSACTION_TYPES.SALE_PROFIT,
    },
    {
      name: "investmentId",
      label: "Investment",
      options: investmentsOptions,
      placeholder: "Select investor"
    },
    ...[
      {
        name: "date",
        label: "Date",
        type: "date"
      },
      {
        name: "amount",
        type: TYPE_AMOUNT,
        label: "Amount",
        placeholder: "1",
        minimum: 1,
        maximum: TRANSACTION_AMOUNT_MAX,
        inputProps: {
          style: {
            width: 130
          },
          controls: false
        }
      },
    ]
  ]

  return inputs
}

export default getCreateInputs
