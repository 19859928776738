import React, { useState } from "react"
import PropTypes from "prop-types"

import { EntityForm } from "@components/Form"

import Drawer from "./Drawer"
import DeleteButton from "./DeleteButton"


const UpdateDrawer = ({
  onOpen: onOpenCustom = () => {},
  onSuccess: onSuccessCustom = () => {},
  form = undefined,
  extra = undefined,
  table = undefined,
  width = undefined,
  onDelete = undefined,
  children = undefined,
  deleteButtonProps = {},
  title,
  drawer,
  ...otherEntityFormProps
}) => {
  const [ item, setItem ] = useState()

  const onClose = async () =>
    setItem()

  const onOpen = targetItem => {
    setItem(targetItem)
    onOpenCustom(targetItem)
  }

  const onSuccess = async (updatedItem) => {
    if (onSuccessCustom) {
      await onSuccessCustom({ ...item, ...updatedItem })
    }

    if (table) {
      table.current.updateItem(item.id, updatedItem)
    }

    drawer.current.close()
  }

  const drawerProps = {
    forceRender: true,
    destroyOnClose: true,
    extra,
    title,
    width,
    drawer,
    onOpen,
    onClose,
  }

  const formProps = {
    form,
    item,
    setItem,
    onSuccess,
    ...otherEntityFormProps
  }

  return (
    <Drawer {...drawerProps}>
      {
        item && (
          <EntityForm {...formProps} />
        )
      }

      {
        children && (
          <>
            {children}
          </>
        )
      }

      {
        onDelete && (
          <DeleteButton
            onDelete={onDelete}
            {...deleteButtonProps}
          />
        )
      }
    </Drawer>
  )
}

UpdateDrawer.propTypes = {
  form: PropTypes.shape(),
  table: PropTypes.shape(),
  extra: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
  drawer: PropTypes.shape().isRequired,
  onOpen: PropTypes.func,
  onDelete: PropTypes.func,
  onSuccess: PropTypes.func,
  deleteButtonProps: PropTypes.shape(),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any
}

export default UpdateDrawer
