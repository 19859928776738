import PropTypes from "prop-types"


const properties = {
  addressCountry: PropTypes.string,
  addressLocality: PropTypes.string,
  addressRegion: PropTypes.string,
  postalCode: PropTypes.string,
  streetAddress: PropTypes.string
}

const AddressShape = PropTypes.exact(properties)

export default AddressShape
export { properties }
