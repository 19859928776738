import React from "react"

import { Form, Input } from "antd"


const renderHidden = (itemProps) => {
  return (
    <Form.Item {...itemProps} hidden={true}>
      <Input />
    </Form.Item>
  )
}

export default renderHidden
