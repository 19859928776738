import PropTypes from "prop-types"

import { backstageStoreProperties } from "@components/Store/BackstageStore/useBackstageStore"


const BackstageLayoutContextShape = PropTypes.exact({
  layoutTopOffset: PropTypes.number.isRequired,
  ...backstageStoreProperties,
})

export default BackstageLayoutContextShape
