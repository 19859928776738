import React, { useState } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { wait } from "@api"
import { useForm } from "@components/Form"
import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"
import { InvestmentForm, InvestmentProfile } from "@components/Investment"
import {
  deleteMyInvestmentOperation as deleteOperation,
  updateMyInvestmentOperation as updateInvestmentOperation
} from "@api/services/investments"

import InvestmentTab from "./InvestmentTab"
import clearInvalidFields from "./helpers/clearInvalidFields"
import useInvestmentFormProps from "./helpers/useInvestmentFormProps"


const InvestmentUpdateTab = ({
  currentForm = undefined,
  nextInvestmentId = undefined,
  index,
  onDelete,
  investment,
  isDeletable,
  openCreateTab,
  isLastInvestment,
}) => {
  const form = useForm()
  const [ block, setBlock ] = useState()
  const [ isLoading, setIsLoading ] = useState(false)

  const { request } = useAppContext()
  const { project, profiles, updateInvestment } = useOutletContext()

  const { tiers } = project
  const { isImmutable } = investment

  const [ onSubmit, formProps ] = useInvestmentFormProps({
    form,
    investment,
    setIsLoading,
    openCreateTab,
    nextInvestmentId,
    isLastInvestment,
  })

  const onCancel = async () =>
    request(deleteOperation, { id: investment.id})
      .then(() => onDelete(investment.id))
      .then(() => wait())

  const currentFormSubmit = async () => {
    if (isImmutable) {
      return
    }

    let mutation = form.getFieldsValue()
    mutation.isProfileValid = true

    const hasFormError = await form.validateFields()
      .then(() => false)
      .catch(() => true)

    if (hasFormError) {
      mutation.isProfileValid = false
      mutation = clearInvalidFields(form, mutation)
    }

    return request(updateInvestmentOperation, { id: investment.id, mutation })
      .then(({ data }) => updateInvestment(data))
  }

  if (currentForm) {
    currentForm.current.submit = currentFormSubmit
  }

  const {
    isFunded,
    isSigned,
    isPartiallyFunded,
    hasSendFunds,
  } = investment

  const shouldDisableDelete =
    isSigned ||
    isFunded ||
    hasSendFunds ||
    isPartiallyFunded

  const isInvestmentDeletable =
    isDeletable &&
    !shouldDisableDelete

  const renderInvestmentBlock = () => {
    if (isImmutable) {
      return (
        <InvestmentProfile
          key={index + 1}
          tiers={tiers}
          investment={investment}
        />
      )
    }

    return (
      <InvestmentForm
        form={form}
        block={block}
        setBlock={setBlock}
        investment={investment}
        hasSubmitButton={false}
        existingProfiles={profiles}
        isLastInvestment={isLastInvestment}
        {...formProps}
      />
    )
  }

  return (
    <InvestmentTab
      index={index}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isLoading={isLoading}
      isDeletable={isInvestmentDeletable}
      isLastInvestment={isLastInvestment}
      onSubmitAndCreate={onSubmit}
    >
      {renderInvestmentBlock()}
    </InvestmentTab>
  )
}

InvestmentUpdateTab.propTypes = {
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  investment: InvestmentShape.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  currentForm: PropTypes.shape(),
  openCreateTab: PropTypes.func.isRequired,
  isLastInvestment: PropTypes.bool.isRequired,
  nextInvestmentId: PropTypes.string,
}

export default InvestmentUpdateTab
