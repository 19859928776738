import React from "react"
import { Tag, Typography, Space } from "antd"

const { Text } = Typography


const renderTagItem = (id, { name, color, description }) => {
  return (
    <Space>
      <Tag
        key={id}
        color={color?.toLowerCase()}
        bordered={false}
      >
        {name}
      </Tag>
      <Text type="secondary">{description}</Text>
    </Space>
  )
}

export default renderTagItem
