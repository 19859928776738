import PropTypes from "prop-types"

import UserOrganizationShape from "@api/services/backstage/shapes/UserOrganizationShape"
import { properties as backstageUserProperties } from "@api/services/backstage/AuthorizeOutputShape"
import { properties as investorAccountProperties } from "@api/services/investments/AuthorizeOutputShape"
import { properties as backstageAdministratorProperties } from "@api/services/backstage/AuthorizeAdministratorOutputShape"

delete backstageUserProperties.authorizationJwt
delete investorAccountProperties.authorizationJwt
delete backstageAdministratorProperties.authorizationJwt

const InvestorIdentityShape = PropTypes.exact({
  ...investorAccountProperties
})

const BackstageUserIdentityShape = PropTypes.exact({
  ...backstageUserProperties,
  organization: UserOrganizationShape.isRequired,
})

const BackstageAdministratorIdentityShape = PropTypes.exact({
  ...backstageAdministratorProperties,
  organization: UserOrganizationShape.isRequired,
})

const IdentityShape = PropTypes.oneOfType([
  InvestorIdentityShape,
  BackstageUserIdentityShape,
  BackstageAdministratorIdentityShape
])

export default IdentityShape
