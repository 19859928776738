import { Button } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import React from "react"

import { Value } from "@components/Text"


const getCollectionColumns = handleDelete => ([
  {
    title: "Date",
    editable: true,
    dataIndex: "date",
    input: {
      type: "date",
      itemProps: {
        rules: [
          {
            required: true,
            message: "Field is required",
          }
        ]
      }
    }
  },
  {
    title: "Reference ID",
    editable: false,
    dataIndex: "referenceId"
  },
  {
    title: "Description",
    editable: false,
    dataIndex: "description"
  },
  {
    title: "Project",
    editable: false,
    dataIndex: "projectName"
  },
  {
    title: "Investment Profile",
    editable: false,
    dataIndex: "investmentName"
  },
  {
    title: "Amount",
    editable: true,
    dataIndex: "amount",
    render: amount => <Value value={amount}/>,
    input: {
      type: "amount",
      required: true,
      minimum: 0,
      inputProps: {
        controls: false,
        style: {
          width: "100%"
        }
      }
    }
  },
  {
    title: "Committed Amount",
    editable: false,
    dataIndex: "committedAmount",
    render: value => <Value value={value} />
  },
  {
    title: "",
    width: 60,
    editable: false,
    dataIndex: "operation",
    render: (_, record) =>
      <Button
        type="link"
        icon={<DeleteOutlined />}
        onClick={() => handleDelete(record)}
      />
  },
])

export default getCollectionColumns
