

const updateProfileNames = investment => {
  const { name } = investment

  if (investment.profileEntity) {
    investment.profileEntity.profileName = name
  }

  if (investment.profileSdira) {
    investment.profileSdira.profileName = name
  }

  if (investment.profileJoint) {
    investment.profileJoint.profileName = name
  }
}

export default updateProfileNames
