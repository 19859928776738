import React, { useState } from "react"
import { Popover, FloatButton, Form } from "antd"
import { MailOutlined, CloseOutlined } from "@ant-design/icons"

import { isMobile } from "@components"
import { useAppContext } from "@components/AppContext"

import ContactForm from "./ContactForm"
import ContactUsMobile from "./ContactUsMobile"

import "./ContactUs.css"

const LABEL_TITLE = `We're here to help. Please explain to us the problem
you're facing and our team will get in touch with you as soon as possible.`

const LABEL_CONTACT_US = "CONTACT US"


const ContactUs = (props) => {
  const [ form ] = Form.useForm()

  const [ isOpen, setIsOpen ] = useState(false)

  const { request } = useAppContext()

  const onSuccess = () => {
    setIsOpen(false)
    form.resetFields()
  }

  const onClick = () =>
    setIsOpen(!isOpen)

  const content =
    <ContactForm
      form={form}
      request={request}
      onSuccess={onSuccess}
      {...props}
    />

  if (isMobile) {
    return (
      <ContactUsMobile
        icon={<MailOutlined />}
        isOpen={isOpen}
        onClick={onClick}
      >
        {content}
      </ContactUsMobile>
    )
  }

  const type = isOpen
    ? "default"
    : "primary"

  const shape = "square"
  const title = <div style={{ maxWidth: 400 }}>{LABEL_TITLE}</div>
  const placement = "topRight"
  const className = "contact-us"

  const icon = isOpen
    ? <CloseOutlined />
    : <MailOutlined />

  return (
    <Popover
      open={isOpen}
      title={title}
      content={content}
      placement={placement}
    >
      <FloatButton
        icon={icon}
        type={type}
        shape={shape}
        onClick={onClick}
        className={className}
        description={LABEL_CONTACT_US}
      />
    </Popover>
  )
}

export default ContactUs
