import PropTypes from "prop-types"

import { useAppContext } from "@components/AppContext"
import {
  indexUserGroupsOperation as indexOperation,
  IndexUserGroupsOutputShape,
} from "@api/services/backstage"

import useStore from "../helpers/useStore"


const useUserGroupsStore = () => {
  const { request } = useAppContext()

  const shouldAutoLoad = false

  const {
    items: userGroups,
    addItem: addUserGroup,
    getItem: getUserGroup,
    indexItems: indexUserGroups,
    updateItem: updateUserGroup,
    removeItem: removeUserGroup,
  } = useStore(indexOperation, request, shouldAutoLoad)

  return {
    userGroups,
    getUserGroup,
    addUserGroup,
    indexUserGroups,
    updateUserGroup,
    removeUserGroup,
  }
}

const userGroupsStoreProperties = {
  userGroups: PropTypes.arrayOf(IndexUserGroupsOutputShape),
  getUserGroup: PropTypes.func.isRequired,
  addUserGroup: PropTypes.func.isRequired,
  indexUserGroups: PropTypes.func.isRequired,
  updateUserGroup: PropTypes.func.isRequired,
  removeUserGroup: PropTypes.func.isRequired,
}

export default useUserGroupsStore

export {
  indexOperation,
  userGroupsStoreProperties
}
