import PropTypes from "prop-types"

import RoleEnum from "./RoleEnum"

const properties = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  domain: PropTypes.string,
  email: PropTypes.string,
  emailFooterHtml: PropTypes.string,
  id: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  parentOrganizationId: PropTypes.string,
  phoneNumber: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  userId: PropTypes.string.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(RoleEnum)))
}

const UserOrganizationShape = PropTypes.exact(properties)

export default UserOrganizationShape
export { properties }
