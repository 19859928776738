import React from "react"
import PropTypes from "prop-types"
import { Steps, Typography, Tooltip } from "antd"
import { QuestionCircleOutlined } from "@ant-design/icons"

import { Value } from "@components/Text"
import { DATETIME_FORMAT } from "@components/Date"

import DocusignLink from "./DocusignLink"
import { InvestmentShape } from "../../../shapes"
import InvestmentSignedDropdown from "./InvestmentSignedDropdown"

const LABEL_SIGNED = "Signed"
const LABEL_FUNDED = "Funded"
const LABEL_SIGNING = "Signing"
const LABEL_FUNDING = "Funding"
const LABEL_CREATED = "Submitted"
const LABEL_FUNDS_SENT = "Funds Sent"
const LABEL_OVERFUNDED = "Overfunded"
const LABEL_PARTIALLY_FUNDED = "Partially Funded"


const InvestmentSteps = ({
  investment,
  readInvestment,
  isInvestmentSignedTemplateEnabled
}) => {
  const {
    createdAt,
    fundedAt,
    isSigned,
    isFunded,
    hasSentFunds,
    isOverfunded,
    receivedAmount,
    isPartiallyFunded,
    subscriptionAdminUrl,
  } = investment

  const format = DATETIME_FORMAT

  const signedStatus = isSigned
    ? "finish"
    : "process"

  const getFundedStatus = () => {
    if (!isSigned) {
      return "wait"
    }

    return isFunded ? "finish" : "process"
  }

  const getFundedTitle = () => {
    if (isFunded) {
      return LABEL_FUNDED
    }

    if (isPartiallyFunded) {
      return LABEL_PARTIALLY_FUNDED
    }

    if (isOverfunded) {
      return LABEL_OVERFUNDED
    }

    return LABEL_FUNDING
  }

  const getFundedDescription = () => {
    if (isFunded) {
      return <Value type="secondary" value={fundedAt} format={format} />
    }

    if (isOverfunded) {
      return <Value type="secondary" value={receivedAmount} />
    }

    if (isPartiallyFunded) {
      return (
        <Value type="secondary" value={receivedAmount} />
      )
    }

    return hasSentFunds
      ? (
        <Typography.Text type="secondary">
          {LABEL_FUNDS_SENT}{" "}
          <Tooltip title="Investor claimed that funds have been sent.">
            <QuestionCircleOutlined />
          </Tooltip>
        </Typography.Text>
      )
      : ""
  }

  const items = [
    {
      title: LABEL_CREATED,
      status: "finish",
      description: <Value type="secondary" value={createdAt} format={format} />,
    },
    {
      title: isSigned ? LABEL_SIGNED : LABEL_SIGNING,
      subTitle: isSigned && subscriptionAdminUrl && <DocusignLink href={subscriptionAdminUrl} />,
      status: signedStatus,
      description: (
        <InvestmentSignedDropdown
          investment={investment}
          readInvestment={readInvestment}
          isInvestmentSignedTemplateEnabled={isInvestmentSignedTemplateEnabled}
        />
      ),
    },
    {
      title: getFundedTitle(),
      status: getFundedStatus(),
      description: getFundedDescription(),
      style: { minWidth: 200 }
    },
  ]

  const stepsProps = {
    className: "steps-inverted-colors",
    items,
  }

  return (
    <Steps {...stepsProps} />
  )
}

InvestmentSteps.propTypes = {
  investment: InvestmentShape.isRequired,
  readInvestment: PropTypes.func.isRequired,
  isInvestmentSignedTemplateEnabled: PropTypes.func.isRequired,
}

export default InvestmentSteps
