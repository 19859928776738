import React, { useState } from "react"
import PropTypes from "prop-types"

import { Modal } from "@components/Page"
import { OperationShape } from "@api"
import { enableMyAccount2FAOperation as operation } from "@api/services/investments"

import CreateVerificationRequest from "./CreateVerificationRequest"
import ActivateVerificationRequest from "./ActivateVerificationRequest"

const LABEL_TITLE = "Mobile Number Verification"


const VerificationRequestModal = ({
  enableOperation = operation,
  modal,
  mobile,
  onSuccess,
}) => {
  const [ isOpen, setIsOpen ] = useState(false)

  const [ verificationRequestId, setVerificationRequestId ] = useState()

  modal.current.open = () =>
    setIsOpen(true)

  const onClose = () =>
    setIsOpen(false)

  const onActivateSuccess = async () => {
    await onSuccess()
    onClose()
  }

  const onResendVerificationCode = () =>
    setVerificationRequestId()

  return (
    <Modal
      modal={modal}
      title={LABEL_TITLE}
      isOpen={isOpen}
      onClose={onClose}
    >
      {
        !verificationRequestId && mobile && (
          <CreateVerificationRequest
            mobile={mobile}
            enableOperation={enableOperation}
            setVerificationRequestId={setVerificationRequestId}
          />
        )
      }
      {
        verificationRequestId && (
          <ActivateVerificationRequest
            mobile={mobile}
            onSuccess={onActivateSuccess}
            enableOperation={enableOperation}
            verificationRequestId={verificationRequestId}
            onResendVerificationCode={onResendVerificationCode}
          />
        )
      }
    </Modal>
  )
}

VerificationRequestModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  mobile: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  enableOperation: OperationShape,
}

export default VerificationRequestModal
