
const scrollToInvestment = investmentId => {
  const element = document.getElementById(investmentId)

  if (!element) {
    return
  }

  element.scrollIntoView({ behavior: 'smooth' })
}

export default scrollToInvestment
