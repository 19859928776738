import { getConfig } from "@components/Config"
import { ALERT_HEIGHT } from "@components"
import { useAppContext } from "@components/AppContext"
import hasInvalidBankAccounts from "./hasInvalidBankAccounts"

const is2FaRequired = getConfig('is2FaRequired')


const useLayoutTopOffset = (bankAccounts, projects) => {
  const { identity } = useAppContext()

  const { is2FaEnabled } = identity

  const twoFactorAuthenticationAlert = is2FaRequired && !is2FaEnabled

  const hasInvalidBankAccountsAlert = hasInvalidBankAccounts(bankAccounts, projects)

  const layoutAlerts = [
    twoFactorAuthenticationAlert,
    hasInvalidBankAccountsAlert
  ]

  const enabledLayoutAlerts = layoutAlerts.filter(value => value)

  const layoutAlertsCount = enabledLayoutAlerts.length

  const layoutTopOffset = layoutAlertsCount * ALERT_HEIGHT

  return layoutTopOffset
}

export default useLayoutTopOffset
