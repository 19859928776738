import React from "react"
import PropTypes from "prop-types"
import { Tag } from "antd"

import {
  CUSTOMER_STATUS_RETRY,
  CUSTOMER_STATUS_DOCUMENT,
  CUSTOMER_STATUS_VERIFIED,
  CUSTOMER_STATUS_SUSPENDED,
  CUSTOMER_STATUS_UNVERIFIED,
  CUSTOMER_STATUS_UNCERTIFIED,
  CUSTOMER_STATUS_PENDING_DOCUMENT_VERIFICATION
} from "@components/Dwolla"

const NO_STATUS = "noStatus"

export const LABELS_VERIFICATION_STATUS_MAP = {
  [CUSTOMER_STATUS_UNVERIFIED]: "Unverified",
  [CUSTOMER_STATUS_VERIFIED]: "Verified",
  [CUSTOMER_STATUS_RETRY]: "Retry",
  [CUSTOMER_STATUS_DOCUMENT]: "Documents",
  [CUSTOMER_STATUS_UNCERTIFIED]: "Owners",
  [CUSTOMER_STATUS_PENDING_DOCUMENT_VERIFICATION]: "In Review",
  [CUSTOMER_STATUS_SUSPENDED]: "Suspended",
  [NO_STATUS]: "Undefined"
}


const VerificationStatusTag = ({ status = NO_STATUS }) => {
  const isRetry = status === CUSTOMER_STATUS_RETRY
  const isDocument = status === CUSTOMER_STATUS_DOCUMENT
  const isVerified = status === CUSTOMER_STATUS_VERIFIED
  const isSuspended = status === CUSTOMER_STATUS_SUSPENDED
  const isUnverified = status === CUSTOMER_STATUS_UNVERIFIED
  const isUncertified = status === CUSTOMER_STATUS_UNCERTIFIED
  const isPendingDocumentVerification = status === CUSTOMER_STATUS_PENDING_DOCUMENT_VERIFICATION

  const label = LABELS_VERIFICATION_STATUS_MAP[status]

  if (isUnverified) {
    return <Tag>{label}</Tag>
  }

  if (isVerified) {
    return <Tag color="green">{label}</Tag>
  }

  if (isRetry) {
    return <Tag color="orange">{label}</Tag>
  }

  if (isDocument) {
    return <Tag color="orange">{label}</Tag>
  }

  if (isUncertified) {
    return <Tag color="orange">{label}</Tag>
  }

  if (isPendingDocumentVerification) {
    return <Tag color="blue">{label}</Tag>
  }

  if (isSuspended) {
    return <Tag color="red">{label}</Tag>
  }

  return <Tag>{label}</Tag>
}

VerificationStatusTag.propTypes = {
  status: PropTypes.string,
}

export default VerificationStatusTag
