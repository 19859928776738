import React, { useRef } from "react"
import {
  PROJECT_TYPES,
  INVESTMENT_STATUS,
} from "@components/Domain"
import { InvestmentShape } from "@components/Investment/shapes"
import { isSdiraInvestment } from "@components/Investment"
import { IndexPublishedProjectsOutputShape } from "@api/services/backstage"

import WireModal from "./WireModal"
import { hasTin } from "./SendFundsButton"
import FundBlockedItem from "./FundBlockedItem"
import FundInvestmentItem from './FundInvestmentItem'
import useFundingBankAccount from "./helpers/useFundingBankAccount"
import FundSdiraInvestmentItem from './FundSdiraInvestmentItem'
import FundNoTinInvestmentItem from './FundNoTinInvestmentItem'
import FundTargetDatePassedItem from './FundTargetDatePassedItem'
import FundAchDisabledInvestmentItem from './FundAchDisabledInvestmentItem'
import getInvestmentFundingAmountTitle from "./helpers/getInvestmentFundingAmountTitle"


const InvestmentProfileCardSendFunding = ({
  project,
  investment,
}) => {
  const wireModal = useRef({})

  const {
    type: projectType,
    bankAccountId,
    fundDeadlineDate,
    isAchFundingEnabled,
  } = project

  const {
    status,
    isSigned,
    hasSentFunds,
    isPartiallySigned,
  } = investment

  const [ isInvalidFundingBankAccount, fundingBankAccount ] = useFundingBankAccount(investment)

  const isSdira = isSdiraInvestment(investment)

  const isAchFundingDisabled = !isAchFundingEnabled

  const hasInvestmentTin = hasTin(investment)
  const hasNoInvestmentTin = !hasInvestmentTin

  const hasFundDeadline = !!fundDeadlineDate

  const isNonFundProject = projectType !== PROJECT_TYPES.FUND

  const hasFundDeadlinePassed = new Date(fundDeadlineDate).getTime() < Date.now()

  const isTargetDatePassed =
    isNonFundProject &&
    hasFundDeadline &&
    hasFundDeadlinePassed

  const isNonRejected = status !== INVESTMENT_STATUS.REJECTED

  const isDocumentSigned = isSigned || isPartiallySigned

  const hasValidBankAccount =
    !!bankAccountId &&
    !isInvalidFundingBankAccount

  const canBeFunded =
    isNonRejected &&
    isDocumentSigned &&
    hasValidBankAccount

  const isBlocked =
    !canBeFunded &&
    !hasSentFunds

  const openWireModal = () =>
    wireModal.current.open()

  const renderItem = () => {
    const title = getInvestmentFundingAmountTitle(investment)

    const itemProps = {
      title,
      project,
      investment,
      openWireModal
    }

    if (isBlocked) {
      return <FundBlockedItem {...itemProps} />
    }

    if (isSdira) {
      return <FundSdiraInvestmentItem {...itemProps} />
    }

    if (hasNoInvestmentTin) {
      return <FundNoTinInvestmentItem {...itemProps} />
    }

    if (isTargetDatePassed) {
      return <FundTargetDatePassedItem {...itemProps} />
    }

    if (isAchFundingDisabled) {
      return <FundAchDisabledInvestmentItem {...itemProps} />
    }

    return <FundInvestmentItem {...itemProps} />
  }

  const wireModalProps = {
    modal: wireModal,
    project,
    investment,
    fundingBankAccount,
  }

  return (
    <>
      {renderItem()}

      <WireModal {...wireModalProps} />
    </>
  )
}

InvestmentProfileCardSendFunding.propTypes = {
  project: IndexPublishedProjectsOutputShape.isRequired,
  investment: InvestmentShape.isRequired,
}

export default InvestmentProfileCardSendFunding
