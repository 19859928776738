import isEqual from "lodash.isequal"


const optimizeMutation = (formItem, item) => {
  const mutation = {}

  const updatedAttributeKeys = Object
    .keys(formItem)
    .filter(key => !isEqual(formItem[key], item[key]))

  for (const key of updatedAttributeKeys) {
    mutation[key] = formItem[key]
  }

  return mutation
}

export default optimizeMutation
