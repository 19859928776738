import React from "react"
import AssetsListInput from "./AssetsListInput"
import getAssetsListItemSchema from "./getAssetsListItemSchema"

const DOCUMENT_ASSET_TYPE = "DOCUMENT"
const DOCUMENT_TYPES = [
  ".txt",
  ".zip",
  ".pdf",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
]

const IMAGE_ASSET_TYPE = "IMAGE"
const IMAGE_TYPES = [
  ".svg",
  ".png",
  ".jpg",
  ".jpeg",
]

const documentsExtraFieldProps = {
  description: {
    required: true
  }
}

const publicImageSchema = getAssetsListItemSchema(true, IMAGE_ASSET_TYPE, IMAGE_TYPES, "Upload Photo")
const privateImageSchema = getAssetsListItemSchema(true, IMAGE_ASSET_TYPE, IMAGE_TYPES, "Upload Photo")
const publicDocumentSchema = getAssetsListItemSchema(true, DOCUMENT_ASSET_TYPE, DOCUMENT_TYPES, "Upload Document", documentsExtraFieldProps)
const privateDocumentSchema = getAssetsListItemSchema(false, DOCUMENT_ASSET_TYPE, DOCUMENT_TYPES, "Upload Document")

export const PublicDocumentsInput = ({ ...props }) =>
  <AssetsListInput
    title="Documents"
    addTitle="Add document"
    {...props}
    schema={publicDocumentSchema}
  />


export const PrivateDocumentsInput = ({ ...props }) =>
  <AssetsListInput
    title="Documents"
    addTitle="Add document"
    {...props}
    schema={privateDocumentSchema}
  />

export const PublicImagesInput = ({ ...props }) =>
  <AssetsListInput
    title="Images"
    addTitle="Add image"
    {...props}
    schema={publicImageSchema}
  />

export const PrivateImagesInput = ({ ...props }) =>
  <AssetsListInput
    title="Images"
    addTitle="Add image"
    {...props}
    schema={privateImageSchema}
  />
