import React, { useRef } from "react"
import { useOutletContext, Link } from "react-router-dom"
import { Space, Button } from "antd"

import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"
import { NotSourceController } from "@components/Investor"
import { ConnectSourceButton } from "@components/Dwolla"
import { getInvestmentSourceSelectProps } from "@components/Investment"

import DistributionsConfirmModal from "../investment/InvestmentFinalizePage/DistributionsConfirmModal"

const LABEL_SCF = "Continue Process"
const LABEL_NOT_CONNECTED = "Not Connected"
const LABEL_UNSIGNED = `Before linking your bank account, you must sign the
subscription document.`


const ProfileConnect = ({ investment }) => {
  const distributionsConfirmModal = useRef({})

  const {
    request,
    isImpersonated,
    isSourceController
  } = useAppContext()

  const { updateInvestment } = useOutletContext()

  const {
    isSigned,
    projectId,
    receiverSourceId,
    receiverSourceName
  } = investment

  if (isImpersonated) {
    return receiverSourceName || LABEL_NOT_CONNECTED
  }

  const isNotSourceController = !isSourceController(receiverSourceId)

  const connectSourceProps =
    getInvestmentSourceSelectProps({
      request,
      investment,
      onInvestmentSourceChanged: updateInvestment,
      connectBankAccountPlaidConfirmModal: distributionsConfirmModal,
    })

  const projectInvestmentUrl = `/investments/${projectId}`

  return (
    <Space direction="vertical" size={0}>
      {
        isNotSourceController && (
          <NotSourceController
            sourceName={receiverSourceName}
          />
        )
      }

      {
        isSigned && (
          <ConnectSourceButton
            {...connectSourceProps}
          />
        )
      }

      {
        !isSigned && (
          <Space direction="vertical">
            <>{LABEL_UNSIGNED}</>

            <Link to={projectInvestmentUrl}>
              <Button
                block
                type="primary"
                size="large"
                shape="round"
              >
                {LABEL_SCF}
              </Button>
            </Link>
          </Space>
        )
      }

      <DistributionsConfirmModal
        modal={distributionsConfirmModal}
      />
    </Space>
  )
}

ProfileConnect.propTypes = {
  investment: InvestmentShape.isRequired,
}

export default ProfileConnect
