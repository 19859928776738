import React from "react"
import { Tag } from "antd"

import SourceShape from "@api/services/transactions/shapes/SourceShape"

const LABEL_FAILED = "Failed"
const LABEL_INVALID = "Invalid"
const LABEL_PENDING = "Pending"
const LABEL_VERIFIED = "Verified"
const LABEL_UNVERIFIED = "Unverified"


const SourceStatusTag = ({ source }) => {
  const {
    isInvalid,
    isVerified,
    isVerificationFailed,
    isMicroDepositsRequested,
  } = source

  if (isVerified) {
    return <Tag color={"green"}>{LABEL_VERIFIED}</Tag>
  }

  if (isVerificationFailed) {
    return <Tag color={"red"}>{LABEL_FAILED}</Tag>
  }

  if (isMicroDepositsRequested) {
    return <Tag color={"blue"}>{LABEL_PENDING}</Tag>
  }

  if (isInvalid) {
    return <Tag color={"red"}>{LABEL_INVALID}</Tag>
  }

  return <Tag>{LABEL_UNVERIFIED}</Tag>
}

SourceStatusTag.propTypes = {
  source: SourceShape.isRequired,
}

export default SourceStatusTag
