import { useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import keyBy from "lodash.keyby"
import uniq from "lodash.uniq"

import { useAppContext } from "@components/AppContext"
import {
  indexAccountsOperation as indexOperation,
  IndexAccountsOutputShape,
  updateAccountTagsOperation,
} from "@api/services/investments"

import useStore from "../helpers/useStore"


const getReferralSourceOptionsByAccounts = accounts => {
  const referralSources = uniq(
    accounts
      .filter(({ referralSource }) => referralSource)
      .map(({referralSource}) => referralSource)
  )

  return referralSources.map(value => ({
    label: value,
    value
  }))
}


const useAccountsStore = () => {
  const { request } = useAppContext()

  const shouldAutoLoad = false

  const {
    items: accounts,
    addItem: addAccount,
    getItem: getAccount,
    indexItems: indexAccounts,
    updateItem: updateAccount,
  } = useStore(indexOperation, request, shouldAutoLoad)

  const accountsMap = useMemo(() =>
    keyBy(accounts, "id")
  , [ accounts ])

  const tagAccount = (id, tagId) => {
    const account = getAccount(id)
    const { tagIds = [] } = account
    const mutation = { tagIds: [ ...tagIds, tagId ] }

    return request(updateAccountTagsOperation, { id, mutation })
      .then(({ data }) => updateAccount({ ...account, ...data }))
  }

  const untagAccount = (id, droppedTagId) => {
    const account = getAccount(id)
    const { tagIds = [] } = account
    const mutation = { tagIds: [ ...tagIds.filter(tagId => tagId !== droppedTagId) ] }

    return request(updateAccountTagsOperation, { id, mutation })
      .then(({ data }) => updateAccount({ ...account, ...data }))
  }

  const setAccountTags = (id, tagIds) => {
    const mutation = { tagIds }

    return request(updateAccountTagsOperation, { id, mutation })
      .then(({ data }) => {
        const account = getAccount(id)
        updateAccount({ ...account, ...data })

        return { ...account, ...data }
      })
  }

  const computeAccountFullname = useCallback(id => {
    const account = getAccount(id)

    if (!account) {
      return id
    }

    const { givenName, familyName } = account.investor
    return `${givenName} ${familyName}`
  }, [ getAccount ])

  const referralSourceOptions = useMemo(
    () => accounts
      ? getReferralSourceOptionsByAccounts(accounts)
      : []
  , [ accounts ])

  return {
    accounts,
    tagAccount,
    getAccount,
    addAccount,
    accountsMap,
    untagAccount,
    indexAccounts,
    updateAccount,
    setAccountTags,
    referralSourceOptions,
    computeAccountFullname,
  }
}

const accountsStoreProperties = {
  accounts: PropTypes.arrayOf(IndexAccountsOutputShape),
  tagAccount: PropTypes.func.isRequired,
  getAccount: PropTypes.func.isRequired,
  addAccount: PropTypes.func.isRequired,
  accountsMap: PropTypes.shape().isRequired,
  untagAccount: PropTypes.func.isRequired,
  indexAccounts: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
  setAccountTags: PropTypes.func.isRequired,
  referralSourceOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  computeAccountFullname: PropTypes.func.isRequired,
}

export default useAccountsStore

export {
  indexOperation,
  accountsStoreProperties
}
