import React from "react"

import { DateTimeInput } from "@components/Form/Input"


const renderDateTime = (itemProps, inputProps, form, path) => {
  return (
    <DateTimeInput
      path={path}
      form={form}
      itemProps={itemProps}
      inputProps={inputProps}
    />
  )
}

export default renderDateTime
