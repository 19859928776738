import React from "react"
import { Typography, Space, Card, Result, Button } from "antd"

import { W2, isMobile } from "@components"
import { getUsDateFromIsoDate } from "@components/Date"
import CreateCommitmentOutputShape from "@api/services/investments/CreateCommitmentOutputShape"
import ReadPublicPublishedProjectOutputShape from "@api/services/backstage/ReadPublicPublishedProjectOutputShape"

const { Text } = Typography

const LABEL_TITLE = "Thank You!"
const LABEL_SUCCESS = `Thank you for submitting a soft commitment, your spot
has been saved! The next steps are to sign the PPM and initiate your funding.`
const LABEL_CONTINUE = "Continue to Complete Investment"
const LABEL_PPM_DEADLINE = "PPM Deadline"
const LABEL_FUND_DEADLINE = "Funding Deadline"

const LABEL_SUCCESS_NOTE = `Please proceed by clicking "Continue". If you
prefer to continue later, an email with a link has been sent to your email.`


const Success = ({
  project,
  commitment,
}) => {
  const { signDeadlineDate, fundDeadlineDate } = project

  const encodedEmail = encodeURIComponent(commitment.investor.email)
  const usSignDeadline = getUsDateFromIsoDate(signDeadlineDate)
  const usFundDeadline = getUsDateFromIsoDate(fundDeadlineDate)

  const successMessage = () => {
    let message = LABEL_SUCCESS

    if (signDeadlineDate) {
      message += `\n ${LABEL_PPM_DEADLINE}: ${usSignDeadline}`
    }

    if (fundDeadlineDate) {
      message += `\n ${LABEL_FUND_DEADLINE}: ${usFundDeadline}`
    }

    message += `\n ${LABEL_SUCCESS_NOTE}`

    return (
      <Text type="secondary">
        {message}
      </Text>
    )
  }

  const signInPath = `/investments/${project.id}/?action=signIn&email=${encodedEmail}`

  const signUpPath = `/investments/${project.id}/?action=signUp&email=${encodedEmail}`

  const onSignIn = () =>
    window.location = signInPath

  const onSignUp = () =>
    window.location = signUpPath

  const buttonProps = {
    size: "large",
    type: "primary",
    shape: "round",
    style: { width: "100%" }
  }

  const loginActionButton = commitment.hasUser
    ? (
        <Button key='signIn' {...buttonProps} onClick={() => onSignIn()}>
          {LABEL_CONTINUE}
        </Button>
      )
    : (
        <Button key='signUp' {...buttonProps} onClick={() => onSignUp()}>
          {LABEL_CONTINUE}
        </Button>
      )

  const children =
    <Result
      title={LABEL_TITLE}
      style={{ paddingBottom: W2 }}
      status="success"
      subTitle={
        <Space
          size={0}
          style={{ whiteSpace: "pre-wrap" }}
          direction="vertical"
        >
          {successMessage()}
        </Space>
      }
      extra={
        <Space
          style={{ width: "100%" }}
          direction="vertical"
        >
          {loginActionButton}
        </Space>
      }
    />

  if (isMobile) {
    return children
  }

  return (
    <Card>
      {children}
    </Card>
  )
}

Success.propTypes = {
  project: ReadPublicPublishedProjectOutputShape.isRequired,
  commitment: CreateCommitmentOutputShape.isRequired,
}

export default Success
