import PropTypes from "prop-types"


const properties = {
  businessVerificationFailedReasons: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string.isRequired,
  identityVerificationFailedReasons: PropTypes.arrayOf(PropTypes.string),
  updatedAt: PropTypes.string,
  verificationStatus: PropTypes.string.isRequired,
  verificationStep: PropTypes.string
}

const UpdateMyCustomerVerificationStatusOutputShapeShape = PropTypes.exact(properties)

export default UpdateMyCustomerVerificationStatusOutputShapeShape
export { properties }
