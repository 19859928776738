import PropTypes from "prop-types"

import { backstageStoreProperties } from "@components/Store/BackstageStore/useBackstageStore"


const BackstageSettingsLayoutContextShape = PropTypes.exact({
  table: PropTypes.shape().isRequired,
  layoutTopOffset: PropTypes.number.isRequired,
  ...backstageStoreProperties,
})

export default BackstageSettingsLayoutContextShape
