import React from "react"
import { Typography } from "antd"

const { Text } = Typography

const LABEL_STEP_MY_INFORMATION = "My Information"
const LABEL_TEXT_MY_INFORMATION = `
  Complete the required fields below and continue to the next step.
`

const LABEL_TEXT_MY_INFORMATION_REVIEW = `
  Review your information below and continue to the next step.
`

const LABEL_STEP_MY_INVESTMENTS = "My Investments"
const LABEL_TEXT_MY_INVESTMENTS = `
  Specify how you would like to invest below. You can invest as multiple
  profiles such as yourself, yourself and a spouse, entity/trust and as a SDIRA.
`

const LABEL_STEP_REVIEW_AND_FINALIZE = "Review & Finalize"
const LABEL_TEXT_REVIEW_AND_FINALIZE = `
  Review your information and investments below. To finalize each investment
  profile, you will need to connect a bank account for distributions, sign
  subscription documents and send funding.
`

const STEPS = [
  {
    title: LABEL_STEP_MY_INFORMATION,
    text: LABEL_TEXT_MY_INFORMATION,
  },
  {
    title: LABEL_STEP_MY_INVESTMENTS,
    text: LABEL_TEXT_MY_INVESTMENTS,
  },
  {
    title: LABEL_STEP_REVIEW_AND_FINALIZE,
    text: LABEL_TEXT_REVIEW_AND_FINALIZE,
  },
]

const getCurrentStep = pathname => {
  const isAccountUpdate =
    pathname.includes("/update") ||
    pathname.includes("/create")

  const isReviewAndFinalize = pathname.includes("/sign")

  if (isAccountUpdate) {
    return 1
  }

  if (isReviewAndFinalize) {
    return 2
  }

  return 0
}

const getStepsMetadata = (identity, pathname) => {
  const { isImmutable } = identity

  if (isImmutable) {
    STEPS[0].text = LABEL_TEXT_MY_INFORMATION_REVIEW
  }

  const stepsItems = STEPS.map(item => (
    {
      title: <Text strong>{item.title}</Text>
    }
  ))

  const currentStep = getCurrentStep(pathname)
  const currentText = STEPS[currentStep].text
  const currentTitle = STEPS[currentStep].title

  return { stepsItems, currentStep, currentTitle, currentText }
}

export default getStepsMetadata
