import React from "react"
import { Typography } from "antd"

import Identity from "@components/Identity"
import { Value } from "@components/Text"
import { openNewTab } from "@components/Utils"


const useItems = (batch, isUrlSigned) => {
  const {
    name,
    date,
    isSent,
    sentAt,
    fileUrl,
    createdAt,
    createdBy,
    scheduledAt,
    documentType,
    fileSourceFileName,
  } = batch

  const isProjectBatch = !!fileUrl

  const getFileItem = () => {
    if (!isProjectBatch) {
      return []
    }

    if (!isUrlSigned) {
      return [
        {
          label: "File",
          value: fileSourceFileName,
        }
      ]
    }

    return [
      {
        label: "File",
        value: (
          <Typography.Link onClick={() => openNewTab(fileUrl)}>
            {fileSourceFileName}
          </Typography.Link>
        )
      }
    ]
  }

  const getSendItem = () => {
    if (!isSent) {
      return (
        {
          label: "Notifications Scheduled For",
          value: <Value value={scheduledAt} />,
        }
      )
    }

    if (sentAt) {
      return (
        {
          label: "Notifications Sent At",
          value: <Value value={sentAt} />,
        }
      )
    }

    return {
      label: "Notifications",
      value: "Off",
    }
  }

  const getItems = () => [
    {
      label: "Type",
      value: documentType,
    },
    {
      label: "Name",
      value: name,
    },
    {
      label: "Date",
      value: <Value value={date} />,
    },
    {
      label: "Created At",
      value: <Value value={createdAt} />,
    },
    {
      label: "Created By",
      value: <Identity id={createdBy} />,
    },
    getSendItem(),
    ...getFileItem(),
  ]

  return getItems()
}

export default useItems
