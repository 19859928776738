import renderColorOptions from "./renderColorOptions"


const getColorInputProps = () => {
  const tagStyle = {
    marginRight: 20
  }

  const options = renderColorOptions(tagStyle)

  const itemProps = {
    style: {
      top: 29,
      right: 0,
      position: "absolute",
    }
  }

  const inputProps = {
    style: {
      width: 140,
      textAlign: "right",
    },
    variant: "borderless",
    allowClear: false,
    showSearch: false,
    dropdownStyle: {
      textAlign: "right"
    }
  }

  return {
    label: "",
    options,
    itemProps,
    inputProps
  }
}

export default getColorInputProps
