import PropTypes from "prop-types"

import { ColorEnumKeys } from "./shapes/ColorEnum"
import { OptionGroupEnumKeys } from "./shapes/OptionGroupEnum"

const properties = {
  color: PropTypes.oneOf(ColorEnumKeys),
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  description: PropTypes.string,
  group: PropTypes.oneOf(OptionGroupEnumKeys).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const ReadOptionOutputShapeShape = PropTypes.exact(properties)

export default ReadOptionOutputShapeShape
export { properties }
