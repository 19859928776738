import React from "react"
import { useLocation  } from "react-router-dom"
import { Button, Result, Layout, Row, Card, theme } from "antd"

import { W1, W2, W3, W5 } from "@components"

const { Content } = Layout

const LABEL_CONTINUE = "Continue"
const LABEL_TITLE_SIGNED = "Documents Signed"
const LABEL_TITLE_EXPIRED = "Expired Subscription Link"
const LABEL_SUBTITLE_SIGNED = "Thank you for signing!"
const LABEL_SUBTITLE_EXPIRED = `The subscription link you're trying to use has
 expired. Please ask your sponsor for a new one to proceed.`

const SIGNED_EVENT = "signing_complete"
const EXPIRED_EVENT = "ttl_expired"


const ThankYouPage = () => {
  let status
  let lableTitle
  let labelSubtitle

  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const event = queryParams.get("event")
  const isSigned = event === SIGNED_EVENT
  const isExpired = event === EXPIRED_EVENT

  if (isSigned) {
    status = "success"
    lableTitle = LABEL_TITLE_SIGNED
    labelSubtitle = LABEL_SUBTITLE_SIGNED
  }

  if (isExpired) {
    status = "error"
    lableTitle = LABEL_TITLE_EXPIRED
    labelSubtitle = LABEL_SUBTITLE_EXPIRED
  }

  if (!event) {
    return window.location = '/'
  }

  const { token } = theme.useToken()
  const { colorBgContainer } = token

  const contentStyle = {
    marginLeft: W1,
    marginRight: W1,
    marginBottom: W5 * 3
  }

  const buttonProps = {
    size: "large",
    type: "primary",
    shape: "round",
  }

  const continueButton = (
    <Button key='continue' {...buttonProps} onClick={() => window.location = '/'}>
      {LABEL_CONTINUE}
    </Button>
  )

  return (
    <Layout
      style={{
        minHeight: "100vh",
        background: colorBgContainer,
        paddingTop: W3,
      }}
    >
      <Content style={contentStyle}>
        <Row justify="center">
          <div style={{ width: "100%", maxWidth: 520 }}>

            <Card>
              <Result
                status={status}
                title={lableTitle}
                style={{ paddingBottom: W2 }}
                subTitle={labelSubtitle}
                extra={continueButton}
              />
            </Card>

          </div>
        </Row>
      </Content>

    </Layout>
  )
}

export default ThankYouPage
