import capitalize from "lodash.capitalize"

export const CAPITAL_RETURN = "CAPITAL_RETURN"
export const FUNDING = "FUNDING"
export const INVESTMENT_RETURN = "INVESTMENT_RETURN"
export const SALE_PROFIT = "SALE_PROFIT"
export const SPONSOR_PROMOTE = "SPONSOR_PROMOTE"

const TransactionTypeEnum = {
  [CAPITAL_RETURN]: CAPITAL_RETURN,
  [FUNDING]: FUNDING,
  [INVESTMENT_RETURN]: INVESTMENT_RETURN,
  [SALE_PROFIT]: SALE_PROFIT,
  [SPONSOR_PROMOTE]: SPONSOR_PROMOTE
}

const TransactionTypeEnumKeys = Object.keys(TransactionTypeEnum)

const TransactionTypeEnumOptions = TransactionTypeEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  TransactionTypeEnumKeys,
  TransactionTypeEnumOptions
}

export default TransactionTypeEnum
