import { PROJECT_STATES } from "@components/Domain"


const hasInvalidBankAccounts = (bankAccounts = [], projects = []) => {
  const invalidBankAccountsIds = bankAccounts
    .filter(item => item.isInvalid)
    .map(item => item.id)

  const invalidFundignAccountProjects = projects
    .filter(({ state }) => state === PROJECT_STATES.OPEN)
    .filter(({ bankAccountId }) => invalidBankAccountsIds.includes(bankAccountId))

  const invalidDistributionsAccountProjects = projects
    .filter(({ state }) => [ PROJECT_STATES.OPEN, PROJECT_STATES.CLOSED ].includes(state))
    .filter(({ distributionBankAccountId }) => invalidBankAccountsIds.includes(distributionBankAccountId))

  const result = [
    ...invalidFundignAccountProjects,
    ...invalidDistributionsAccountProjects
  ].length > 0

  return result
}

export default hasInvalidBankAccounts
