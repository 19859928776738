import React, { useState } from "react"
import { SearchOutlined } from "@ant-design/icons"
import PropTypes from "prop-types"
import { Input } from "antd"

const LABEL_SEARCH = "Search"

const ESCAPE_KEY = "Escape"


const SearchInput = ({ onSearch }) => {
  const [ value, setValue ] = useState()

  const onKeyDown = ({ key }) => {
    const isEsc = key === ESCAPE_KEY

    if (!isEsc) {
      return
    }

    setValue("")
    onSearch("")
  }

  const onChange = event => {
    const { value: newValue } = event.target

    setValue(newValue)

    const shouldSearch = newValue.length >= 3 || newValue.length === 0

    if (shouldSearch) {
      onSearch(newValue)
      return
    }

    onSearch("")
  }

  const style = {
    width: 280
  }

  const inputProps = {
    prefix: <SearchOutlined />,
    allowClear: true,
    placeholder: LABEL_SEARCH,
    value,
    style,
    onChange,
    onKeyDown,
  }

  return (
    <Input {...inputProps} />
  )
}

SearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
}

export default SearchInput
