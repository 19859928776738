import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Space, Row, Col, Tag, Typography, Spin, Collapse } from "antd"

import { W1 } from "@components"
import { Drawer } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { readActivityOperation } from "@api/services/activities"

import ActivityHeaderDescriptions from "./ActivityHeaderDescriptions"
import ActivitySummaryDescriptions from "./ActivitySummaryDescriptions"
import ActivityAttributesDescriptions from "./ActivityAttributesDescriptions"

const LABEL_ACTIVITY = "Activity"
const LABEL_QUERY = "Query"
const LABEL_RESULT = "Result"
const LABEL_MUTATION = "Mutation"


const ActivityDrawer = ({ drawer, renderActivity }) => {
  const [ activity, setActivity ] = useState()
  const [ activityId, setActivityId ] = useState()

  const { request } = useAppContext()

  const onOpen = ({ id }) => {
    setActivity()
    setActivityId(id)
    request(readActivityOperation, { id })
      .then(({ data }) => setActivity(data))
  }

  const title = useMemo(() =>
    activityId
      ? (
          <Row>
            <Col flex="auto">
              <Typography.Title level={5} style={{ margin: 0 }}>
                {LABEL_ACTIVITY}
              </Typography.Title>
            </Col>
            <Col>
              <Tag bordered={false}>
                {activityId}
              </Tag>
            </Col>
          </Row>
        )
      : ""
  , [ activityId ])

  const getAttributeItems = () => {
    const hasQuery = Object.keys(activity.queryAttributes).length > 0
    const hasMutation = Object.keys(activity.mutationAttributes).length > 0
    const hasResult = Object.keys(activity.resultAttributes).length > 0

    const items = []

    if (hasQuery) {
      items.push({
        key: 'query',
        label: LABEL_QUERY,
        children: <ActivityAttributesDescriptions activity={activity} attributeKey="queryAttributes" />,
      })
    }

    if (hasMutation) {
      items.push({
        key: 'mutation',
        label: LABEL_MUTATION,
        children: <ActivityAttributesDescriptions activity={activity} attributeKey="mutationAttributes" />,
      })
    }

    if (hasResult) {
      items.push({
        key: 'result',
        label: LABEL_RESULT,
        children: <ActivityAttributesDescriptions activity={activity} attributeKey="resultAttributes" />,
      })
    }

    return items
  }

  return (
    <Drawer
      title={title}
      drawer={drawer}
      onOpen={onOpen}
      destroyOnClose={true}
    >
      {
        !activity && (
          <center>
            <Spin />
          </center>
        )
      }

      {
        activity && (
          <Space
            size={W1}
            style={{ width: "100%" }}
            direction="vertical"
          >
            <ActivityHeaderDescriptions
              activity={activity}
            />

            <ActivitySummaryDescriptions
              activity={activity}
              renderActivity={renderActivity}
            />

            <Collapse
              size="small"
              items={getAttributeItems()}
              defaultActiveKey={[ 'query', 'mutation' ]}
            />
          </Space>
        )
      }
    </Drawer>
  )
}

ActivityDrawer.propTypes = {
  drawer: PropTypes.shape().isRequired,
  renderActivity: PropTypes.func.isRequired,
}

export default ActivityDrawer
