import { getUsDateFromIsoDate } from "@components/Date"


const useSetRequestErrors = setErrors => {
  return (requestCounter, parameters, originalError) => {
    const { message } = originalError
    const { mutation } = parameters
    const { date, profileName } = mutation

    const usDate = getUsDateFromIsoDate(date)

    const errorMessage = {
      index: requestCounter,
      reason: `Distribution ${usDate} for ${profileName}: ${message}`
    }

    setErrors((prevState = []) => [
      ...prevState,
      errorMessage
    ])
  }
}

export default useSetRequestErrors
