import React from "react"
import { useOutletContext } from "react-router-dom"

import { W1 } from "@components"

import { BatchesTable } from "@modules/backstage/documents"


const ProjectDocumentsPage = () => {
  const { table } = useOutletContext()

  return (
    <div style={{ padding: W1 }}>
      <BatchesTable table={table} />
    </div>
  )
}

export default ProjectDocumentsPage
