import React from "react"
import get from "lodash.get"
import startCase from "lodash.startcase"

import { Value } from "@components/Text"

import getColumnFilterProps from "./getColumnFilterProps"
import getComputeColumnWidth from "./getComputeColumnWidth"
import getEnumColumnFilterProps from "./getEnumColumnFilterProps"
import { getColumnSortingProps } from "./getSortingProps"

const DATETIME_FORMAT = "date-time"

const TYPE_STRING = "string"
const TYPE_INTEGER = "integer"

const SORTED_TYPES = [TYPE_STRING, TYPE_INTEGER]


const getColumns = (
  operation,
  items,
  columnsConfig,
  getColumnSearchProps,
  sortParams,
  hasHeaderFilters
) => {
  const indexOutputItemSchema = operation.getIndexItemSchema()

  const getColumnProps = config => {
    const format = indexOutputItemSchema[config.key]?.format
    const enumValues = indexOutputItemSchema[config.key]?.enum
    const defaultType = indexOutputItemSchema[config.key]?.type || TYPE_STRING

    const {
      type = defaultType,
      key,
      fixed,
      align,
      width,
      render,
      onCell,
      filterValues,
      isFilterEnabled,
      hasTruncation,
      isHidden,
      filterMultiple
    } = config

    let {
      path,
      title,
      compute
    } = config

    path = path
      ? path
      : key

    title = title !== undefined
      ? title
      : startCase(key)

    compute = compute
      ? compute
      : item => get(item, path) || ""

    const [ dataIndex ] = path.split('.')

    let props = {
      render: (value, item) => compute(item),
      key,
      title,
      dataIndex,
    }

    if (align) {
      props.align = align
    }

    if (width) {
      props.width = getComputeColumnWidth(items, config, compute)
    }

    if (isHidden) {
      props.hidden = isHidden
    }

    if (fixed) {
      props.fixed = fixed
    }

    if (onCell) {
      props.onCell = onCell
    }

    if (filterMultiple) {
      props.filterMultiple = filterMultiple
    }

    const isDateTimeFormat = format === DATETIME_FORMAT

    if (isDateTimeFormat) {
      props.render = (value, item) => <Value value={compute(item)} />
    }

    if (render) {
      props.render = (value, item) => render(compute(item), item)
    }

    if (hasTruncation) {
      props.className = "table-cell-truncate"
    }

    const { isSortingEnabled } = config

    const hasTitle = title !== false

    const isTypeSupportsSorting = hasTitle && SORTED_TYPES.includes(type)

    const shouldIncludeSortingProps =
      isSortingEnabled ||
      isTypeSupportsSorting ||
      (sortParams.defaultSortColumn && sortParams.defaultSortColumn === dataIndex)

    if (shouldIncludeSortingProps) {
      props = {
        ...props,
        ...getColumnSortingProps(compute, dataIndex)
      }
    }

    const isSortableColumn = dataIndex === sortParams.defaultSortColumn

    if (sortParams.defaultSortColumn && isSortableColumn) {
      props = {
        ...props,
        defaultSortOrder: sortParams.defaultSortColumnDirection
      }
    }

    const { isSearchable } = config

    if (isSearchable) {
      props = {
        ...props,
        ...getColumnSearchProps(dataIndex, compute, render)
      }

      return props
    }

    if (!isFilterEnabled) {
      return props
    }

    if (enumValues) {
      props = {
        ...props,
        ...getEnumColumnFilterProps(hasHeaderFilters, operation, key, enumValues, compute, filterValues)
      }

      return props
    }

    const { filterText, filters, onFilter } = config

    props = {
      ...props,
      ...getColumnFilterProps(hasHeaderFilters, items, compute, onFilter, filters, filterText)
    }

    return props
  }

  const columns = columnsConfig.map(getColumnProps)

  return columns
}

export default getColumns
