import { UNAUTHORIZED_ERROR } from "@api"

import authorize from "./authorize"
import { Storage } from "../authenticate"
import { operationRequest } from "../request"

let authorizationPromise = null

const authorizedRequest = (onUnexpectedError, operation, parameters, expectedErrors) =>
  operationRequest({
    headers: { authorization: Storage.authorization },
    operation,
    parameters,
    expectedErrors,
    onUnexpectedError,
  })

const getAuthorizedRequest = (setIdentity, onUnexpectedError, onAuthorizationError, authorizationMethod, authentication) => {
  if (!authentication) {
    return (operation, parameters, expectedErrors = []) =>
      operationRequest({
        operation,
        parameters,
        expectedErrors,
        onUnexpectedError,
      })
  }

  return (operation, parameters, expectedErrors = []) =>
    authorizedRequest(onUnexpectedError, operation, parameters, [ ...expectedErrors, UNAUTHORIZED_ERROR ])
      .catch(async (error) => {
        const isAuthorizationExpired = error.code === UNAUTHORIZED_ERROR

        if (!isAuthorizationExpired) {
          throw error
        }

        if (!authorizationPromise) {
          authorizationPromise = (async () => {
            try {
              Storage.startAuthorization()
              const { customOrganizationId: organizationId } = Storage

              await authorize({
                setIdentity,
                authentication,
                organizationId,
                authorizationMethod,
                onAuthorizationError,
              })
            } finally {
              Storage.stopAuthorization()
              authorizationPromise = null
            }
          })()
        }

        await authorizationPromise

        return authorizedRequest(onUnexpectedError, operation, parameters, expectedErrors)
      })
}

export default getAuthorizedRequest
