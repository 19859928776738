
const LABEL_CARD = "ID Card"
const LABEL_LICENCE = "Licence"
const LABEL_PASSPORT = "Passport"

export const PASSPORT_TYPE = "PASSPORT"

export const FILE_TYPES = [
  ".png",
  ".jpg",
  ".jpeg",
]

export const DOCUMENT_TYPES = [
  {
    value: "PASSPORT",
    label: LABEL_PASSPORT,
  },
  {
    value: "LICENCE",
    label: LABEL_LICENCE,
  },
  {
    value: "ID_CARD",
    label: LABEL_CARD,
  },
]

export const NON_US_DOCUMENT_TYPES = [
  {
    value: PASSPORT_TYPE,
    label: LABEL_PASSPORT,
  },
]

export const PDF_FILE_TYPE = ".pdf"

export const BUSINESS_VERIFICATION_STEPS = [
  'verify-business-with-document',
  'verify-controller-and-business-with-document'
]

export const CONTROLLER_VERIFICATION_STEPS = [
  'verify-with-document',
  'verify-controller-and-business-with-document'
]
