import React, { useMemo } from "react"
import { Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import Identity from "@components/Identity"
import { hasAccess } from "@components/Authorization"
import { Value } from "@components/Text"
import TableRowActions from "@components/Table/TableRowActions"

import getRowMenu from "./getRowMenu"

const { Link: TextLink } = Typography

const LABEL_BATCH_NAME = "Name"
const LABEL_UPLOADED_BY = "Uploaded By"
const LABEL_UPLOADED_AT = "Uploaded At"
const LABEL_DOCUMENT_TYPE = "Document Type"


const useColumnsConfig = (openBatchDrawer, openDeleteConfirm) => {
  const { computeUserFullname } = useOutletContext()

  return useMemo(() => {
    const dateColumnProps = {
      key: "date",
      render: value =>
        <Value value={value} />
    }

    const nameColumnProps = {
      key: "name",
      title: LABEL_BATCH_NAME,
      render: (name, batch) => (
        <TextLink strong onClick={() => openBatchDrawer(batch)}>
          {name}
        </TextLink>
      ),
      isSearchable: true
    }

    const documentTypeColumnProps = {
      key: "documentType",
      title: LABEL_DOCUMENT_TYPE,
      isFilterEnabled: true,
    }

    const createdByColumnProps = {
      key: "createdBy",
      title: LABEL_UPLOADED_BY,
      compute: ({ createdBy }) => computeUserFullname(createdBy),
      render: (fullname, { createdBy }) =>
        <Identity id={createdBy}>{fullname}</Identity>,
      isFilterEnabled: true,
    }

    const fileNameColumnProps = {
      key: "fileSourceFileName",
      title: "File Name",
    }

    const createdAtColumnProps = {
      key: "createdAt",
      title: LABEL_UPLOADED_AT,
      render: value =>
        <Value value={value} />
    }

    const actionsColumnProps = {
      key: "actions",
      title: false,
      render: (_, item) => (
        <TableRowActions
          items={getRowMenu(item, openDeleteConfirm)}
        />
      )
    }

    const columns = [
      { ...dateColumnProps, width: "auto" },
      { ...documentTypeColumnProps, width: "auto" },
      { ...nameColumnProps },
      { ...fileNameColumnProps },
      { ...createdByColumnProps, width: "auto", widthAutoPadding: 40 },
      { ...createdAtColumnProps, width: "auto" },
    ]

    const canUpdateBatches = hasAccess(['documents-write'])

    if (canUpdateBatches) {
      columns.push({ ...actionsColumnProps, width: 48 })
    }

    return columns
  }, [
    openBatchDrawer,
    openDeleteConfirm,
    computeUserFullname,
  ])
}

export default useColumnsConfig
