import capitalize from "lodash.capitalize"

export const ACCOUNT_TAG = "ACCOUNT_TAG"
export const BATCH_DOCUMENT_TYPE = "BATCH_DOCUMENT_TYPE"
export const CAMPAIGN_TAG = "CAMPAIGN_TAG"
export const DOCUMENT_TYPE = "DOCUMENT_TYPE"
export const SEGMENT = "SEGMENT"
export const SUBSCRIPTION_TYPE = "SUBSCRIPTION_TYPE"

const OptionGroupEnum = {
  [ACCOUNT_TAG]: ACCOUNT_TAG,
  [BATCH_DOCUMENT_TYPE]: BATCH_DOCUMENT_TYPE,
  [CAMPAIGN_TAG]: CAMPAIGN_TAG,
  [DOCUMENT_TYPE]: DOCUMENT_TYPE,
  [SEGMENT]: SEGMENT,
  [SUBSCRIPTION_TYPE]: SUBSCRIPTION_TYPE
}

const OptionGroupEnumKeys = Object.keys(OptionGroupEnum)

const OptionGroupEnumOptions = OptionGroupEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  OptionGroupEnumKeys,
  OptionGroupEnumOptions
}

export default OptionGroupEnum
