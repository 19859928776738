

const getSponsorOptions = (partners, getSponsorName) =>
  partners
    .filter(({ sponsorId }) => !!sponsorId)
    .map(item => ({
      label: getSponsorName(item.sponsorId),
      value: item.sponsorId,
    }))

export default getSponsorOptions
