import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "antd"
import { useOutletContext } from "react-router-dom"

import { hasAccess } from "@components/Authorization"
import Templates from "@components/Templates"
import { Markdown } from "@components/Text"
import { W2, isMobile } from "@components"
import { PROJECT_TYPES } from "@components/Domain"

import Banner from "./Banner"
import Sidebar from "./Sidebar"
import ProjectDeal from "./ProjectDeal"
import ProjectShape from "../shapes/ProjectShape"
import FundProperties from "./FundProperties"
import ProjectProperties from "./ProjectProperties"
import ProjectEditableGroup from "./ProjectEditableGroup"

const LABEL_ABOUT_FUND = "About this Fund"
const LABEL_ABOUT_PORTFOLIO = "About this Portfolio"
const LABEL_ABOUT_SYNDICATION = "About this Deal"


const ProjectDetails = ({
  project,
  isEditable
}) => {
  const {
    getProperty,
    projectTemplatesStore,
  } = useOutletContext()

  const {
    id: projectId,
    type,
    description,
    investmentSubjects = [],
  } = project

  const properties = investmentSubjects
    .map(({ id }) => id)
    .map(propertyId => getProperty(propertyId))

  const canViewTemplates =
    hasAccess(['templates-read']) &&
    projectTemplatesStore

  const isPortfolio = type === PROJECT_TYPES.PORTFOLIO

  let aboutTitle = isPortfolio
    ? LABEL_ABOUT_PORTFOLIO
    : LABEL_ABOUT_SYNDICATION

  const isFund = type === PROJECT_TYPES.FUND

  const fieldNames = [
    'description',
  ]

  if (isFund) {
    fieldNames.push('coverImageUrl')
    aboutTitle = LABEL_ABOUT_FUND
  }

  const sidebar =
    <Sidebar
      project={project}
      isEditable={isEditable}
    />

  const about =
    <ProjectEditableGroup
      title={aboutTitle}
      fieldNames={fieldNames}
      extraProps={{
        level: 4,
        style: { marginTop: 0 },
        isEditable
      }}
    >
      <Markdown>{description}</Markdown>
    </ProjectEditableGroup>

  const propertiesCollection = isFund
    ? <FundProperties
        isEditable={isEditable}
        properties={properties}
      />
    : <ProjectProperties
        isEditable={isEditable}
        properties={properties}
        projectType={type}
      />

  const deal = isFund
    ? null
    : <ProjectDeal
        project={project}
        isEditable={isEditable}
      />

  const templates = !canViewTemplates
    ? null
    : (
        <Col span={24}>
          <div style={{ maxWidth: 540 }}>
            <Templates
              projectId={projectId}
              templatesStore={projectTemplatesStore}
            />
          </div>
        </Col>
      )

  const content =
    <Row gutter={[ W2, W2 ]}>
      <Col span={24}>
        <Banner project={project} />
      </Col>

      <Col span={24}>
        {about}
      </Col>

      <Col span={24}>
        {propertiesCollection}
      </Col>

      <Col span={24}>
        {deal}
      </Col>

      {templates}
    </Row>

  const isFundMobileLayout = isFund && isMobile

  if (isFundMobileLayout) {
    return (
      <>
        <Row gutter={[ W2, W2 ]}>
          <Col span={24}>
            <Row gutter={[ W2, W2 ]}>
              <Col span={24}>
                <Banner project={project} />
              </Col>

              <Col span={24}>
                {about}
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            {sidebar}
          </Col>
        </Row>

        <Row style={{ margin: `${W2}px -8px 0 -8px` }}>
          <Col span={24}>
            {propertiesCollection}
          </Col>
        </Row>
      </>
    )
  }

  return (
    <Row gutter={[ W2, W2 ]}>
      <Col span={ isMobile ? 24 : 16 }>
        {content}
      </Col>

      <Col span={ isMobile ? 24 : 8 }>
        {sidebar}
      </Col>
    </Row>
  )
}

ProjectDetails.propTypes = {
  project: ProjectShape.isRequired,
  isEditable: PropTypes.bool.isRequired,
}

export default ProjectDetails
