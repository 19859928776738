import React from "react"
import PropTypes from "prop-types"
import { InfoCircleOutlined } from "@ant-design/icons"
import { Col, Row, Space, Statistic, Tooltip, Typography } from "antd"

import { W0, W2 } from "@components"

import useStatisticItems from "./helpers/useStatisticItems"
import { TransactionShape } from "../../shapes"

const { Text } = Typography


const AccountStatistics = ({ transactions }) => {
  const items = useStatisticItems(transactions)

  const renderTitle = (title, tooltip) =>
    <Space>
      <Text type="secondary">{title}</Text>
      {
        tooltip && (
          <Tooltip title={tooltip}>
            <InfoCircleOutlined />
          </Tooltip>
        )
      }
    </Space>

  const style = {
    padding: "0 4px",
    marginBottom: W0
  }

  return (
    <Row
      style={style}
      gutter={W2}
    >
      {
        items.map(({ title, value, tooltip, props = { prefix: "$" } }, key) =>
          <Col key={key} flex="auto">
            <Statistic
              title={renderTitle(title, tooltip)}
              value={value}
              {...props}
            />
          </Col>
        )
      }
    </Row>
  )
}

AccountStatistics.propTypes = {
  transactions: PropTypes.arrayOf(TransactionShape).isRequired,
}

export default AccountStatistics
