import { useMemo } from "react"
import PropTypes from "prop-types"

import { InvestmentShape } from "@components/Investment/shapes"
import { properties as publicProjectProperties } from "@api/services/backstage/IndexPublishedProjectsOutputShape"
import { PROJECT_TYPES, PROJECT_STATE, INVESTMENT_STATUS } from "@components/Domain"
import getStatistics, {
  OUTGOING_TOTAL_KEY,
  INCOMING_TOTAL_KEY,
} from "@components/Investor/helpers/getStatistics"


const ExtendedProjectShape = PropTypes.shape({
  ...publicProjectProperties,
  investments: PropTypes.arrayOf(InvestmentShape).isRequired,
  capitalInvested: PropTypes.number.isRequired,
  receiveDistribution: PropTypes.number.isRequired,
})


const extendProjects = (projects, investments, transactions) => {
  const extendedProjects = []

  for (const project of projects) {
    const projectInvestments = investments
      .filter(({ projectId }) => project.id === projectId)

    const projectTransactions = transactions
      .filter(({ projectId }) => project.id === projectId)

    const statistics = getStatistics(projectTransactions)

    extendedProjects.push({
      ...project,
      investments: projectInvestments,
      capitalInvested: statistics[OUTGOING_TOTAL_KEY] || 0,
      receiveDistribution: statistics[INCOMING_TOTAL_KEY] || 0
    })
  }

  return extendedProjects
}


const isProjectOpen = project =>
  project.state === PROJECT_STATE.OPEN


const isOpenFundProject = project =>
  isProjectOpen(project) && project.type === PROJECT_TYPES.FUND


const isProjectClosed = project =>
  project.state === PROJECT_STATE.CLOSED


const isProjectArchived = project =>
  project.state === PROJECT_STATE.ARCHIVED


const hasPartnerNonFundedInvestments = project =>
  project.investments
    .filter(({ isPartner, isFunded }) => isPartner && !isFunded)
    .length > 0


const hasNonRejectedInvestments = project =>
  project.investments
    .filter(({ status }) => status !== INVESTMENT_STATUS.REJECTED)
    .length > 0


const hasActiveInvestments = project =>
  project.investments
    .filter(({ status }) => status !== INVESTMENT_STATUS.REJECTED)
    .length > 0


const computeGroupedProjects = (projects = [], investments = [], transactions =[]) => {
  const extendedProjects = extendProjects(projects, investments, transactions)

  const openProjects = extendedProjects
    .filter(hasNonRejectedInvestments)
    .filter(project => isProjectOpen(project) || hasPartnerNonFundedInvestments(project))

  const closedProjects = extendedProjects
    .filter(hasActiveInvestments)
    .filter(project => isProjectClosed(project) || isOpenFundProject(project))
    .filter(project => !hasPartnerNonFundedInvestments(project))

  const archivedProjects = extendedProjects
    .filter(isProjectArchived)
    .filter(hasActiveInvestments)

  return {
    openProjects,
    closedProjects,
    archivedProjects,
  }
}


const useGroupedProjects = (projects, investments, transactions) => {
  const groupedProjects = useMemo(
    () => computeGroupedProjects(projects, investments, transactions)
  , [ projects, investments, transactions ])

  return groupedProjects
}

export default useGroupedProjects

export {
  ExtendedProjectShape
}
