
const getBankAccountCustomer = (id, identity, bankAccount) => {
  const {
    givenName: firstName,
    familyName: lastName,
  } = identity

  const {
    beneficiaryName: businessName,
    beneficiaryAddress: address,
  } = bankAccount

  const controller = {
    lastName,
    firstName,
  }

  const businessCustomer = {
    address,
    lastName,
    firstName,
    controller,
    businessName,
  }

  return { id, businessCustomer }
}

export default getBankAccountCustomer
