import React from "react"
import PropTypes from "prop-types"

import { useAppContext } from "@components/AppContext"
import { updateOperation } from "@components/Store/BackstageStore/useCampaignsStore"
import Form, { FormShape } from "@components/Form"

import useInputs from "./helpers/useInputs"


const CampaignSettingsUpdateForm = ({
  item,
  form,
  tabId,
  onSuccess,
  uploadAssets,
  saveCampaign,
  onActionSuccess,
  openPreviewModal
}) => {
  const { request } = useAppContext()

  const inputs = useInputs(form, tabId, item, saveCampaign, uploadAssets, onActionSuccess, openPreviewModal)
  const schema = updateOperation.getSchema(inputs)

  const formProps = {
    form,
    item,
    schema,
    request,
    onSuccess,
    // onFailure,
    operation: updateOperation,
    hasSubmitButton: false,
  }

  return (
    <Form {...formProps} />
  )
}

CampaignSettingsUpdateForm.propTypes = {
  item: PropTypes.shape().isRequired,
  form: FormShape.isRequired,
  tabId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  uploadAssets: PropTypes.func.isRequired,
  saveCampaign: PropTypes.func.isRequired,
  onActionSuccess: PropTypes.func.isRequired,
  openPreviewModal: PropTypes.func.isRequired,
}

export default CampaignSettingsUpdateForm
