import { FUNDING_STATUS } from "./computeInvestmentFundingStatus"


const getFundedColor = value => {
  const isFunded = value === FUNDING_STATUS.FUNDED
  const isPartially = value === FUNDING_STATUS.PARTIALLY
  const isOverfunded = value === FUNDING_STATUS.OVERFUNDED
  const isNotFunded = value === FUNDING_STATUS.NOT_FUNDED

  let color = "blue"

  if (isFunded) {
    color = "green"
  }

  if (isPartially) {
    color = "orange"
  }

  if (isNotFunded) {
    color = "blue"
  }

  if (isOverfunded) {
    color = "red"
  }

  return  color
}

export default getFundedColor
