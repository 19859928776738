import React from "react"
import brnv from "bank-routing-number-validator"

import { getUsAddressSchema } from "@components/Address"
import { TYPE_RADIO, TYPE_STRING, Header } from "@components/Form"

import getBankNameProps from "./getBankNameProps"

const ERROR_NUMBER_MISMATCH = "Should match account number"
const ERROR_INVALID_ROUTING_NUMBER = "Invalid routing number"

const LABEL_BANK = "Bank"
const LABEL_TYPE = "Type"
const LABEL_CONFIRM = "Confirm"
const LABEL_BANK_CODE = "Bank Identifier Code (BIC) / SWIFT Code"
const LABEL_ROUTING_ACH = "ACH Routing Number"
const LABEL_ROUTING_WIRE = "Wire Routing Number"
const LABEL_ACCOUNT_NUMBER = "Account Number"


const getBankAccountInputs = (form, isHideInputs = false, bankAccounts = []) => {
  const bankNameProps = getBankNameProps(bankAccounts)

  const inputs = [
    {
      name: "_bank",
      component: ({ style }) =>
        <Header style={style}>
          {LABEL_BANK}
        </Header>
    },
    {
      name: "bankName",
      label: "Bank Name",
      maxLength: 64,
      placeholder: "",
      ...bankNameProps,
    },
    {
      name: "bankAddress",
      label: "",
      schema: getUsAddressSchema(),
    },
    {
      name: "bankCodeName",
      label: LABEL_BANK_CODE,
      maxLength: 64,
      placeholder: "",
      inputProps: {
        style: { width: 180 },
      },
    },
    {
      name: "routingNumber",
      label: LABEL_ROUTING_ACH,
      placeholder: "",
      minLength: 9,
      maxLength: 9,
      itemProps: {
        rules: [{
          message: ERROR_INVALID_ROUTING_NUMBER,
          validator: async (_, value) => {
            if (isHideInputs) {
              return true
            }

            const isValid = brnv.ABARoutingNumberIsValid(value)

            if (!isValid) {
              throw new Error(ERROR_INVALID_ROUTING_NUMBER)
            }
          }
        }]
      },
      inputProps: {
        style: { width: 180 },
      },
    },
    {
      name: "alternativeRoutingNumber",
      label: LABEL_ROUTING_WIRE,
      required: true,
      placeholder: "",
      minLength: 9,
      maxLength: 9,
      itemProps: {
        rules: [{
          message: ERROR_INVALID_ROUTING_NUMBER,
          validator: async (_, value) => {
            if (isHideInputs) {
              return true
            }

            const isValid = brnv.ABARoutingNumberIsValid(value)

            if (!isValid) {
              throw new Error(ERROR_INVALID_ROUTING_NUMBER)
            }
          }
        }]
      },
      inputProps: {
        style: { width: 180 },
      },
    },
    {
      name: "accountType",
      label: LABEL_TYPE,
      type: TYPE_RADIO,
    },
    {
      name: "accountNumber",
      label: LABEL_ACCOUNT_NUMBER,
      placeholder: "",
      minLength: 4,
      maxLength: 17,
      inputProps: {
        style: { width: 180 },
      },
    },
    {
      name: "_confirmAccountNumber",
      type: TYPE_STRING,
      label: `${LABEL_CONFIRM} ${LABEL_ACCOUNT_NUMBER}`,
      placeholder: "",
      minLength: 4,
      maxLength: 17,
      inputProps: {
        style: { width: 180 },
      },
      itemProps: {
        rules: [
          {
            message: ERROR_NUMBER_MISMATCH,
            validator: async () => {
              const { accountNumber, _confirmAccountNumber } = form.getFieldsValue()

              if (!accountNumber) {
                return
              }

              const isValid = accountNumber === _confirmAccountNumber

              if (!isValid) {
                throw new Error(ERROR_NUMBER_MISMATCH)
              }
            }
          }
        ],
      },
    },
  ]

  return inputs
}

export default getBankAccountInputs
