import PropTypes from "prop-types"

import { SponsorTypeEnumKeys } from "./shapes/SponsorTypeEnum"

const properties = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(SponsorTypeEnumKeys),
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const ReadSponsorOutputShapeShape = PropTypes.exact(properties)

export default ReadSponsorOutputShapeShape
export { properties }
