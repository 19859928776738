import React, { useRef } from "react"
import { ExclamationCircleFilled } from "@ant-design/icons"
import { App, Row, Col, Button } from "antd"

import { signOut } from "@components/Authorization"
import { useAppContext } from "@components/AppContext"
import { W1, W2, W3, isMobile } from "@components"

import AccountCard from "./AccountCard"
import BankAccountsCard from "./BankAccountsCard"
import UnusedBankAccountsCard from "./UnusedBankAccountsCard"
import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal"

const WIDTH_MAX = 520

const LABEL_TITLE = "My Account"

const LABEL_SIGNOUT = "Sign Out"
const LABEL_CHANGE_PASSWORD = "Change Password"
const LABEL_MESSAGE = `You're about to sign out of the investor portal, please
confirm.`


const InvestorAccountPage = () => {
  const changePasswordModal = useRef({})

  const { modal } = App.useApp()

  const { isImpersonated } = useAppContext()

  const style = {
    maxWidth: WIDTH_MAX,
    marginTop: isMobile ? 0 : W2,
  }

  const cardProps = {}

  if (isMobile) {
    cardProps.bordered = false
    cardProps.className = "card-mobile"
  }

  const onClick = () =>
    modal.confirm({
      onOk: () => signOut(),
      icon: <ExclamationCircleFilled />,
      title: LABEL_SIGNOUT,
      okText: LABEL_SIGNOUT,
      content: LABEL_MESSAGE,
    })

  const onOpenChangePasswordModal = () =>
    changePasswordModal.current.open()

  return (
    <Row justify="center">
      <Col style={style}>
        <AccountCard
          title={LABEL_TITLE}
          cardProps={cardProps}
          shouldShow2FASwitch={true}
        />

        <div style={{ marginTop: isMobile ? 0 : W2 }}>
          <BankAccountsCard
            cardProps={cardProps}
          />
        </div>

        <div style={{ marginTop: isMobile ? 0 : W2 }}>
          <UnusedBankAccountsCard
            cardProps={cardProps}
          />
        </div>

        <div
          style={{
            marginTop: W3,
            padding: isMobile ? "0 8px" : "0 16px"
          }}
        >
          <Button
            ghost
            size="large"
            type="primary"
            shape="round"
            style={{ width: "100%", marginBottom: W1 }}
            onClick={onOpenChangePasswordModal}
            disabled={isImpersonated}
          >
            {LABEL_CHANGE_PASSWORD}
          </Button>

          <Button
            danger
            ghost
            size="large"
            type="primary"
            shape="round"
            style={{ width: "100%" }}
            onClick={onClick}
          >
            {LABEL_SIGNOUT}
          </Button>
        </div>

        <ChangePasswordModal
          modal={changePasswordModal}
        />
      </Col>
    </Row>
  )
}

export default InvestorAccountPage
