import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { PlusOutlined } from "@ant-design/icons"
import { Row, Col, Space, Button, Card, Image, Typography } from "antd"

import { Value } from "@components/Text"
import { W1, isMobile } from "@components"
import { InvestmentShape } from "@components/Investment/shapes"
import { getResizedImageUrl } from "@components/Storage"
import { getFormattedDecimalValue } from "@components/Amount"
import { getProjectInvestmentsUrl, computeProjectInvestmentsFlags } from "@components/Project"

import InvestmentSteps from "./InvestmentSteps"
import InvestmentStatusTag from "./InvestmentStatusTag"

import "./Investment.css"

const { Title, Text } = Typography

const LABEL_SCF = "Continue Process"
const LABEL_VIEW = "View Investment"
const LABEL_COMMITTED = "Committed"
const LABEL_SHOW_PROJECT = "View Offering Details"
const LABEL_NEW_INVESTMENT = "Add Additional Investments"


const Investment = ({
  coverImageUrl = "",
  id,
  name,
  investments,
}) => {
  const flags = computeProjectInvestmentsFlags(investments)

  const {
    isSigned,
    isFunded,
    isProfileComplete,
    isInvestorComplete
  } = flags

  const titleStyle = {
    margin: 0,
    marginTop: 6,
  }

  const investedAmount = investments
    .map(({ investments: classes }) => classes
      .reduce((acc, investment) => getFormattedDecimalValue(acc, investment.amount, "plus"), 0)
    )
    .reduce((acc, amount) => getFormattedDecimalValue(acc, amount, "plus"), 0)

  const cardStyle = isMobile
    ? {
        border: "none",
        padding: 0,
        borderTop: "1px solid #f0f0f0",
        borderRadius: 0,
      }
    : {}

  const bodyStyle = isMobile
    ? { padding: 12 }
    : {}

  const projectInvestmentUrl = getProjectInvestmentsUrl(id, investments)

  const continueButtonLabel = (isFunded && isSigned)
    ? LABEL_VIEW
    : LABEL_SCF

  return (
    <Card
      style={cardStyle}
      styles={{ body: bodyStyle }}
      className="investment-card"
    >
      <Row gutter={[ W1, W1 * 1.25 ]}>
        {
          coverImageUrl && (
            <Col>
              <Image
                src={getResizedImageUrl(coverImageUrl, 160, 160)}
                width={80}
                preview={false}
              />
            </Col>
          )
        }

        <Col flex="auto">
          <InvestmentStatusTag
            isSigned={isSigned}
            isFunded={isFunded}
          />

          <Title level={4} style={titleStyle}>
            {name}
          </Title>

          <Text type="secondary">
            <Value value={investedAmount} /> {LABEL_COMMITTED}
          </Text>
        </Col>

        <Col span={24}>
          <InvestmentSteps
            isFunded={isFunded}
            isSigned={isSigned}
            isProfileComplete={isProfileComplete}
            isInvestorComplete={isInvestorComplete}
          />
        </Col>

        <Col span={24}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Link to={projectInvestmentUrl}>
              <Button
                block
                type="primary"
                size="large"
                shape="round"
              >
                {continueButtonLabel}
              </Button>
            </Link>

            <Link to={`/investments/${id}/create`}>
              <Button
                block
                icon={<PlusOutlined />}
                shape="round"
              >
                {LABEL_NEW_INVESTMENT}
              </Button>
            </Link>

            <Link to={`/projects/${id}/about`}>
              <Button
                block
                shape="round"
              >
                {LABEL_SHOW_PROJECT}
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
    </Card>
  )
}

Investment.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  investments: PropTypes.arrayOf(InvestmentShape).isRequired,
  coverImageUrl: PropTypes.string,
}

export default Investment
