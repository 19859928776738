import PropTypes from "prop-types"


const properties = {
  code: PropTypes.string.isRequired,
  partnerId: PropTypes.string
}

const ProjectValidationErrorShape = PropTypes.exact(properties)

export default ProjectValidationErrorShape
export { properties }
