import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Space, Descriptions } from "antd"

import { hasAccess } from "@components/Authorization"
import { W1, W5 } from "@components"
import { getConfig } from "@components/Config"
import { runRequest } from "@components/AppContext"
import { EditableGroup } from "@components/Button"
import Templates, { useTemplatesStore } from "@components/Templates"
import { ReadMyOrganizationOutputShape } from "@api/services/organizations"

import useItems from "./helpers/useItems"
import getDocusignItems from "./helpers/getDocusignItems"

const MAX_WIDTH = 602

const LABEL_ENTITY = "Organization"
const LABEL_DOCUSIGN_SETTINGS = "DocuSign Settings"

const hasDocusign = getConfig("hasDocusign")


const Organization = ({
  organization,
  openUpdateDocusign,
  openUpdateOrganization
}) => {
  const items = useItems(organization)
  const docusignItems = getDocusignItems(organization)

  const isEditable = hasAccess(['org-write'])
  const canViewTemplates = hasAccess(['templates-read'])

  const organizationTemplatesStore = useTemplatesStore()
  const { indexTemplates } = organizationTemplatesStore

  useEffect(() => {
    return runRequest(() => {
      if (canViewTemplates) {
        indexTemplates()
      }
    })
  }, [
    indexTemplates,
    canViewTemplates,
  ])

  return (
    <Space direction="vertical" size={W1} style={{ marginBottom: W5 }}>
      <EditableGroup
        title={LABEL_ENTITY}
        style={{ width: "100%" }}
        onClick={openUpdateOrganization}
        isEditable={isEditable}
      >
        <Descriptions
          bordered
          size="small"
          style={{ minWidth: MAX_WIDTH }}
          column={1}
          labelStyle={{ width: 140 }}
        >
          {items}
        </Descriptions>
      </EditableGroup>

      {
        hasDocusign && (
          <EditableGroup
            title={LABEL_DOCUSIGN_SETTINGS}
            style={{ width: "100%" }}
            onClick={openUpdateDocusign}
            isEditable={isEditable}
          >
            <Descriptions
              bordered
              size="small"
              style={{ minWidth: MAX_WIDTH }}
              column={1}
              labelStyle={{ width: 140 }}
            >
              {docusignItems}
            </Descriptions>
          </EditableGroup>
        )
      }

      {
        canViewTemplates && (
          <Templates
            templatesStore={organizationTemplatesStore}
          />
        )
      }
    </Space>
  )
}

Organization.propTypes = {
  organization: ReadMyOrganizationOutputShape.isRequired,
  openUpdateDocusign: PropTypes.func.isRequired,
  openUpdateOrganization: PropTypes.func.isRequired,
}

export default Organization
