import dayjs from "dayjs"

export const ACTIVITY_DAYS = {
  DAYS_30: 30,
  DAYS_60: 60,
  DAYS_90: 90,
  DAYS_365: 365,
}

export const ACTIVITY_DAY_LABELS_MAP = {
  [ACTIVITY_DAYS.DAYS_30]: "Last 30 days",
  [ACTIVITY_DAYS.DAYS_60]: "Last 60 days",
  [ACTIVITY_DAYS.DAYS_90]: "Last 90 days",
  [ACTIVITY_DAYS.DAYS_365]: "Last 365 days"
}

const computeActivityFilterValue = dateTimeIso => {
  if (!dateTimeIso) {
    return dateTimeIso
  }

  const today = dayjs()
  const dateTimeDayJs = dayjs(dateTimeIso)

  const differenceInDays = today.diff(dateTimeDayJs, "day")

  if (differenceInDays <= ACTIVITY_DAYS.DAYS_30) {
    return ACTIVITY_DAY_LABELS_MAP[ACTIVITY_DAYS.DAYS_30]
  }

  if (differenceInDays <= ACTIVITY_DAYS.DAYS_60) {
    return ACTIVITY_DAY_LABELS_MAP[ACTIVITY_DAYS.DAYS_60]
  }

  if (differenceInDays <= ACTIVITY_DAYS.DAYS_90) {
    return ACTIVITY_DAY_LABELS_MAP[ACTIVITY_DAYS.DAYS_90]
  }

  return ACTIVITY_DAY_LABELS_MAP[ACTIVITY_DAYS.DAYS_365]
}

export default computeActivityFilterValue
