import PropTypes from "prop-types"


const properties = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  id: PropTypes.string.isRequired,
  investmentId: PropTypes.string.isRequired,
  payloadJson: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const IndexDocusignWebhooksOutputShapeShape = PropTypes.exact(properties)

export default IndexDocusignWebhooksOutputShapeShape
export { properties }
