import { useLocation } from "react-router-dom"

import { W1 } from "@components"
import { useAppContext } from "@components/AppContext"

import getStepsMetadata from "../Steps/helpers/getStepsMetadata"


const useInvestmentHeaderProps = (project) => {
  const { pathname } = useLocation()

  const { identity } = useAppContext()

  const {
    currentText,
    currentTitle,
  } = getStepsMetadata(identity, pathname)

  const pageHeaderStyle = {
    marginBottom: W1 * 1.5
  }

  const subtitle = (project?.name || "").toUpperCase()

  return {
    text: currentText,
    title: currentTitle,
    style: pageHeaderStyle,
    titleLevel: 2,
    subtitle,
  }
}

export default useInvestmentHeaderProps
