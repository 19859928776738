
const renderDescription = (template) => {
  const {
    sendTime,
    description,
    sendAfterDaysCount,
    sendAfterHoursCount,
  } = template

  let formattedDescription = description

  const isSentDaily = description
    ? description.includes('sent daily')
    : false

  if (isSentDaily) {
    formattedDescription = formattedDescription.replace(/%X%/, sendTime)
  }

  if (sendAfterHoursCount > 0) {
    formattedDescription = formattedDescription.replace(/%X%/, sendAfterHoursCount)
  }

  if (sendAfterDaysCount > 0) {
    formattedDescription = formattedDescription.replace(/%X%/, sendAfterDaysCount)
  }

  if (sendAfterDaysCount < 0) {
    formattedDescription = formattedDescription.replace(/%X%/, sendAfterDaysCount * -1)
  }

  return formattedDescription
}

export default renderDescription
