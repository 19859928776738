import React from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

import { Markdown } from "@components/Text"

import { STYLE_TYPES } from "./InvestmentProfileCardSendFunding/helpers/Constants"

const { Text } = Typography

export const LABEL_PENDING = "Pending"
export const LABEL_COMPLETE = "Complete"


const InvestmentProfileCardItem = ({
  children = undefined,
  title,
  status,
  subtitle,
  styleType,
}) => {
  const components = {
    p(props) {
      const { children: content } = props

      return (
        <p style={{ marginTop: 0, marginBottom: 12 }}>
          {content}
        </p>
      )
    }
  }

  return (
    <>
      <Text>
        <Text style={{ fontSize: 16 }} strong>{title}:</Text>
        <Text style={{ fontSize: 16 }} strong type={styleType}>{' '}
          {status}
        </Text>

        <Markdown text={subtitle} components={components} />
      </Text>

      {
        children && (
          <>{children}</>
        )
      }
    </>
  )
}

InvestmentProfileCardItem.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape()
  ]).isRequired,
  status: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  styleType: PropTypes.oneOf(STYLE_TYPES).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
}

export default InvestmentProfileCardItem
