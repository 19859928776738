import { useState, useEffect } from "react"

import { runRequest, useAppContext } from "@components/AppContext"

import useUsersStore, { usersStoreProperties } from "./useUsersStore"
import useOptionsStore, { optionsStoreProperties } from "./useOptionsStore"
import usePartnersStore, { partnersStoreProperties } from "./usePartnersStore"
import useProjectsStore, { projectsStoreProperties } from "./useProjectsStore"
import useAccountsStore, { accountsStoreProperties } from "./useAccountsStore"
import useCampaignsStore, { campaignsStoreProperties } from "./useCampaignsStore"
import usePropertiesStore, { propertiesStoreProperties } from "./usePropertiesStore"
import useOrganizationStore, { organizationStoreProperties } from "./useOrganizationStore"
import useBankAccountsStore, { bankAccountsStoreProperties } from "./useBankAccountsStore"
import useFailedTransactionsStore, { failedTransactionsStoreProperties } from "./useFailedTransactionsStore"


const useBackstageStore = () => {
  const { getOrganizationId, getOrganization } = useAppContext()

  const [ isLoading, setIsLoading ] = useState()

  const usersStore = useUsersStore()
  const optionsStore = useOptionsStore()
  const projectsStore = useProjectsStore()
  const partnersStore = usePartnersStore()
  const accountsStore = useAccountsStore()
  const campaignsStore = useCampaignsStore()
  const propertiesStore = usePropertiesStore()
  const organizationStore = useOrganizationStore(getOrganization)
  const { projects } = projectsStore
  const bankAccountsStore = useBankAccountsStore(projects)
  const failedTransactionsStore = useFailedTransactionsStore()

  const { indexUsers } = usersStore
  const { indexOptions } = optionsStore
  const { indexSponsors } = partnersStore
  const { indexAccounts } = accountsStore
  const { indexProjects } = projectsStore
  const { indexProperties } = propertiesStore
  const { readOrganization } = organizationStore
  const { indexFailedTransactions } = failedTransactionsStore
  // NOTE: Broker doesn't have access:
  const { indexBankAccounts } = bankAccountsStore

  const isReady = isLoading !== undefined && !isLoading
  const oraganizationId = getOrganizationId()

  useEffect(() => {
    setIsLoading()
  }, [ oraganizationId ])

  useEffect(() => {
    if (isReady) {
      return
    }

    if (isLoading) {
      return
    }

    return runRequest(() => {
      setIsLoading(true)
      Promise.all([
        indexUsers(),
        indexOptions(),
        indexProjects(),
        indexAccounts(),
        indexProperties(),
        readOrganization(),
        indexFailedTransactions(),
        indexSponsors(),
        indexBankAccounts()
      ]).then(() => setIsLoading(false))
    })
  }, [
    isReady,
    isLoading,
    indexUsers,
    indexOptions,
    indexProjects,
    indexSponsors,
    indexAccounts,
    indexProperties,
    readOrganization,
    indexBankAccounts,
    indexFailedTransactions
  ])

  const context = {
    ...usersStore,
    ...optionsStore,
    ...partnersStore,
    ...projectsStore,
    ...accountsStore,
    ...campaignsStore,
    ...propertiesStore,
    ...organizationStore,
    ...bankAccountsStore,
    ...failedTransactionsStore,
  }

  return {
    isReady,
    context
  }
}

const backstageStoreProperties = {
  ...usersStoreProperties,
  ...optionsStoreProperties,
  ...partnersStoreProperties,
  ...projectsStoreProperties,
  ...accountsStoreProperties,
  ...campaignsStoreProperties,
  ...propertiesStoreProperties,
  ...organizationStoreProperties,
  ...bankAccountsStoreProperties,
  ...failedTransactionsStoreProperties
}

export default useBackstageStore

export { backstageStoreProperties }
