import React, { useState } from "react"
import PropTypes from "prop-types"
import { Spin, Space, Descriptions, Typography } from "antd"

import { W1 } from "@components"
import { Drawer } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { readCustomerOperation } from "@api/services/transactions"

import getItems from "./helpers/getItems"
import CustomerSource from "./CustomerSource"

const LABEL_TITLE = "ACH Account"
const LABEL_SOURCES = "Bank Accounts"


const CustomerDrawer = ({ drawer }) => {
  const { authenticatedRequest } = useAppContext()

  const [ customer, setCustomer ] = useState()
  const [ isLoading, setIsLoading ] = useState()

  const onClose = () =>
    setCustomer()

  const onOpen = (id) => {
    setIsLoading(true)
    authenticatedRequest(readCustomerOperation, { id })
      .then(({ data }) => setCustomer(data))
      .then(() => setIsLoading(false))
  }

  const items = getItems(customer)

  const getUrl = () => {
    const url = customer.customerUrl.replace('://api', '://dashboard')
    return `${url}/details`
  }

  const extra = !customer
    ? null
    : (
      <Typography.Link href={getUrl()} target="_blank">
        View in Dwolla
      </Typography.Link>
    )

  const drawerProps = {
    title: LABEL_TITLE,
    extra,
    drawer,
    onOpen,
    onClose,
  }

  const renderSources = () => {
    if (!customer) {
      return null
    }

    const { sources } = customer

    return (
      <Space
        size={W1}
        style={{ width: "100%" }}
        direction="vertical"
      >
        <Typography.Title level={5} style={{ margin: 0 }}>
          {LABEL_SOURCES}
        </Typography.Title>
        {
          sources.map(source => (
            <CustomerSource key={source.id} source={source} />
          ))
        }
      </Space>
    )
  }

  const hasItems = items.length > 0

  return (
    <Drawer {...drawerProps}>
      <Spin spinning={isLoading}>
        {
          hasItems && (
            <Descriptions
              size="small"
              items={items}
              style={{ marginBottom: W1 }}
              column={1}
              bordered={true}
            />
          )
        }

        {renderSources()}
      </Spin>
    </Drawer>
  )
}

CustomerDrawer.propTypes = {
  drawer: PropTypes.shape({}).isRequired,
}

export default CustomerDrawer
