import React from "react"
import { Statistic, Table, Typography } from "antd"

import {
  getFormattedAmountValue,
  getFormattedDecimalValue
} from "@components/Amount"

const { Text } = Typography
const { Summary: { Row, Cell } } = Table


const getTableSummary = () => {
  const render = rows => {
    const shouldNotRender = rows.length <= 2

    if (shouldNotRender) {
      return
    }

    const total = rows
      .map(({ amount }) => amount)
      .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

    return (
      <Row>
        <Cell
          align="right"
          index={1}
          colSpan={2}
        >
          <Text>Total:</Text>
        </Cell>

        <Cell
          index={1}
          colSpan={2}
        >
          <Statistic
            value={getFormattedAmountValue(total)}
            prefix="$"
            valueStyle={{ fontSize: 14 }}
          />
        </Cell>
      </Row>
    )
  }

  return render
}

export default getTableSummary
