import React, { useRef, useMemo } from "react"
import get from "lodash.get"
import { useOutletContext } from "react-router-dom"

import { W0, W1, W5 } from "@components"
import { CollapseTable } from "@components/Table"
import { UsersTable, UserCreateModal, UserUpdateModal } from "@modules/backstage/users"

const LABEL_ACTIVE = "Active Staff"
const LABEL_DISABLED = "Inactive Staff"


const SettingsMembersPage = () => {
  const { table, members } = useOutletContext()

  const createModal = useRef({})
  const updateModal = useRef({})
  const activeUsersTable = useRef({})
  const disabledUsersTable = useRef({})

  const activeMembers = useMemo(() => members
    .filter(({ isDisabled }) => isDisabled === false)
  , [ members ])

  const inactiveMembers = useMemo(() => members
    .filter(({ isDisabled }) => isDisabled === true)
  , [ members ])

  const openCreateModal = () =>
    createModal.current.open()

  const openUpdateModal = id =>
    updateModal.current.open(id)

  const style = {
    padding: W1,
    paddingTop: W0,
    paddingBottom: W5
  }

  table.current.search = value => {
    const tables = [
      activeUsersTable,
      disabledUsersTable,
    ]

    for (const usersTable of tables) {
      const search = get(usersTable, 'current.search')

      if (search) {
        search(value)
      }
    }
  }

  return (
    <div style={style}>
      <CollapseTable
        items={activeMembers}
        title={LABEL_ACTIVE}
      >
        <UsersTable
          table={activeUsersTable}
          items={activeMembers}
          openCreateModal={openCreateModal}
          openUpdateModal={openUpdateModal}
        />
      </CollapseTable>

      <CollapseTable
        items={inactiveMembers}
        title={LABEL_DISABLED}
        isOpenByDefault={false}
      >
        <UsersTable
          table={disabledUsersTable}
          items={inactiveMembers}
          openCreateModal={openCreateModal}
          openUpdateModal={openUpdateModal}
        />
      </CollapseTable>

      <UserCreateModal modal={createModal} />
      <UserUpdateModal modal={updateModal} />
    </div>
  )
}

export default SettingsMembersPage
