import React from "react"
import { App, Input, Space, Typography } from "antd"

import { W1 } from "@components"
import { useAppContext } from "@components/AppContext"
import confirmAction from "@components/Page/helpers/confirmAction"
import { enableAccountOperation, disableAccountOperation } from "@api/services/investments"

const { Text } = Typography
const { TextArea } = Input

const ACTION_ENABLE = "ENABLE"
const ACTION_DISABLE = "DISABLE"

let disableReason = ""
const onChange = ({ currentTarget }) => disableReason = currentTarget.value


const useActions = (account, updateAccount) => {
  const { modal } = App.useApp()

  const { request, showSuccessMessage } = useAppContext()

  if (!account) {
    return {}
  }

  const { id, investor: { givenName, familyName } } = account

  const fullName = `${givenName} ${familyName}`

  const ACTIONS_MAP = {
    [ACTION_DISABLE]: {
      type: "danger",
      title: "Revoke Access",
      action: "Revoke",
      success: "Revoked access for $",
      operation: disableAccountOperation,
      content: (
        <Space
          direction="vertical"
          style={{ width: "100%", marginBottom: W1 }}
        >
          <Text>
            Investor becomes unauthorized to access the service.
          </Text>

          <TextArea
            rows={4}
            onChange={onChange}
            showCount={true}
            maxLength={128}
            placeholder="Specify the reason..."
          />
        </Space>
      ),
    },
    [ACTION_ENABLE]: {
      type: "primary",
      title: "Grant Access",
      action: "Enable",
      success: "Granted access for $",
      content: "Investor becomes authorized to get access to the service.",
      operation: enableAccountOperation,
    },
  }

  const action = actionName =>
    confirmAction(
      modal,
      ACTIONS_MAP[actionName].action,
      ACTIONS_MAP[actionName].title,
      ACTIONS_MAP[actionName].content,
      async () => {
        const message = ACTIONS_MAP[actionName].success.replace("$", fullName)
        const { operation } = ACTIONS_MAP[actionName]
        const parameters = { id }

        const isDisable = actionName === ACTION_DISABLE

        if (isDisable) {
          parameters.mutation = { disableReason }
        }

        request(operation, parameters)
          .then(({ data }) => updateAccount(data))
          .then(() => showSuccessMessage(message))
          .then(() => disableReason = "")
      },
      ACTIONS_MAP[actionName].type,
    )

  const enableAccount = () => action(ACTION_ENABLE)
  const disableAccount = () => action(ACTION_DISABLE)

  return {
    enableAccount,
    disableAccount,
  }
}

export default useActions
