import React from "react"
import { Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import Identity from "@components/Identity"
import { Value } from "@components/Text"
import { useAppContext } from "@components/AppContext"

import useComputeUser from "./useComputeUser"

const LABEL_USER = "User"
const LABEL_ACTIVITY = "Activity"

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

const isUuid = value => UUID_REGEX.test(value)

const isInvestor = id => id.startsWith("ACC_")

const isBackstageUser = id => id.startsWith("USR_") && ![ "USR_ROOT", "USR_GUEST" ].includes(id)

const useColumnsConfig = (renderActivitySummary, openActivityDrawer) => {
  const { users } = useOutletContext()
  const { isConsoleOrganization } = useAppContext()

  const getUserId = userId => {
    if (!isConsoleOrganization) {
      return userId
    }

    const isCorrelationId = isUuid(userId)

    if (!isCorrelationId) {
      return userId
    }

    const user = users.find(candidate => candidate.correlationId === userId)

    return user?.id || userId
  }

  const computeUser = useComputeUser(getUserId, isInvestor, isBackstageUser, isUuid)

  const userColumnProps = {
    key: "userId",
    title: LABEL_USER,
    compute: activity => computeUser(activity),
    render: (fullname, { id, userId }) => {
      const hasIdentity =
        isInvestor(userId) ||
        isBackstageUser(userId) ||
        (isConsoleOrganization && isUuid(userId))

      if (hasIdentity) {
        return (
          <Identity
            id={getUserId(userId)}
            strong={true}
            onClick={() => openActivityDrawer(id)}
            shouldFallbackToId={true}
          >
            {fullname}
          </Identity>
        )
      }

      return (
        <Typography.Link
          strong={true}
          onClick={() => openActivityDrawer(id)}
        >
          {fullname}
        </Typography.Link>
      )
    },
    isSearchable: true,
  }

  const summaryColumnProps = {
    key: "template",
    title: LABEL_ACTIVITY,
    compute: activity => renderActivitySummary(activity),
    isSearchable: true,
  }

  const createdAtColumnProps = {
    key: "createdAt",
    render: value =>
      <Value value={value} />
  }

  return [
    { ...userColumnProps, width: "auto", widthAutoPadding: 40 },
    { ...summaryColumnProps },
    { ...createdAtColumnProps, width: 160 }
  ]
}

export default useColumnsConfig
