import React, { useState } from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

import { Modal } from "@components/Page"
import { W0, W1 } from "@components"
import { useAppContext } from "@components/AppContext"

import ProjectFunds from "./ProjectFunds"
import ProjectSponsors from "./ProjectSponsors"

const { Title } = Typography

const LABEL_TITLE = "Project Partners"
const LABEL_FUNDS = "Funds"
const LABEL_SPONSORS = "Sponsors"


const ProjectPartnersModal = ({ modal }) => {
  const [ isOpen, setIsOpen ] = useState(false)

  const { identity } = useAppContext()
  const { organization: { name, parentOrganizationId } } = identity

  const titleStyle = { marginTop: 0, marginLeft: W0 }

  const isChildFundOrganization = !!parentOrganizationId

  modal.current.open = () =>
    setIsOpen(true)

  const onClose = () =>
    setIsOpen(false)

  const style = {
    marginTop: W1,
    marginLeft: -W0,
    marginRight: -W0,
  }

  return (
    <Modal
      title={LABEL_TITLE}
      modal={modal}
      width={720}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div style={style}>
        {
          !isChildFundOrganization && (
            <>
              <Title level={5} style={titleStyle}>
                {name} & {LABEL_FUNDS}
              </Title>

              <ProjectFunds organizationName={name} />
            </>
          )
        }

        <Title level={5} style={titleStyle}>
          {LABEL_SPONSORS}
        </Title>

        <ProjectSponsors organizationName={name} />
      </div>
    </Modal>
  )
}

ProjectPartnersModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default ProjectPartnersModal
