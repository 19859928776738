import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import Table from "@components/Table"
import { indexUsersOperation as indexOperation } from "@api/services/backstage"

import { UserShape } from "../shapes"
import useColumnsConfig from "./helpers/useColumnsConfig"
import getHeaderActions from "./helpers/getHeaderActions"


const BrokersTable = ({
  table,
  items,
  openCreateBrokerModal,
  openUpdateBrokerModal,
  openUpdateMemberModal,
  openUpdateSponsorModal
}) => {
  const { sponsors, indexUsers: indexItems } = useOutletContext()

  const columnsConfig = useColumnsConfig(
    openUpdateSponsorModal,
    openUpdateBrokerModal,
    openUpdateMemberModal
  )

  const size = "small"
  const isReady = !!sponsors
  const className = "last-column-action"
  const headerActions = getHeaderActions(openCreateBrokerModal)
  const defaultSortColumn = "name"

  const tableProps = {
    size,
    table,
    items,
    isReady,
    className,
    indexItems,
    headerActions,
    columnsConfig,
    indexOperation,
    defaultSortColumn,
  }

  return (
    <Table {...tableProps} />
  )
}

BrokersTable.propTypes = {
  table: PropTypes.shape().isRequired,
  items: PropTypes.arrayOf(UserShape).isRequired,
  openCreateBrokerModal: PropTypes.func.isRequired,
  openUpdateBrokerModal: PropTypes.func.isRequired,
  openUpdateMemberModal: PropTypes.func.isRequired,
  openUpdateSponsorModal: PropTypes.func.isRequired,
}

export default BrokersTable
