import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import Table from "@components/Table"
import { useAppContext } from "@components/AppContext"
import { BankAccountModal } from "@modules/backstage/bankAccounts"
import { CUSTOMER_STATUS_VERIFIED } from "@components/Dwolla"
import { indexBankAccountsOperation as indexOperation } from "@api/services/transactions"

import useHeaderActions from "./helpers/useHeaderActions"
import getColumnsConfig from "./helpers/getColumnsConfig"


const BankAccountsTable = ({
  table = undefined,
  openCreateModal
}) => {
  const bankAccountModal = useRef({})

  const { getCustomer } = useAppContext()

  const {
    bankAccounts: items,
    indexBankAccounts: indexItems,
    removeBankAccount,
    isOrganizationController
  } = useOutletContext()

  const isController = isOrganizationController()

  const openBankAccount = bankAccount => {
    const { beneficiaryName } = bankAccount

    const customer = getCustomer(beneficiaryName)

    const isVerifiedCustomer =
      !!customer &&
      customer.verificationStatus === CUSTOMER_STATUS_VERIFIED

    const shouldRenderDwolla =
      isController &&
      isVerifiedCustomer

    bankAccountModal.current.open(bankAccount, shouldRenderDwolla)
  }

  const size = "small"
  const headerActions = useHeaderActions(openCreateModal)
  const columnsConfig = getColumnsConfig(openBankAccount, removeBankAccount, isController)
  const defaultSortColumn = "beneficiaryName"

  const tableProps = {
    size,
    table,
    items,
    indexItems,
    headerActions,
    columnsConfig,
    indexOperation,
    defaultSortColumn,
  }

  return (
    <>
      <Table {...tableProps} />

      <BankAccountModal
        modal={bankAccountModal}
        hasLinkedProjects={true}
      />
    </>
  )
}

BankAccountsTable.propTypes = {
  table: PropTypes.shape(),
  openCreateModal: PropTypes.func.isRequired,
}

export default BankAccountsTable
