import { ENTITY_MEMBERSHIP_INTEREST_TRUSTEE } from "@components/Domain"

import isEntityInvestment from "./isEntityInvestment"


const isTrustInvestment = investment => {
  const isEntity = isEntityInvestment(investment)

  if (!isEntity) {
    return false
  }

  const { profileEntity = {} } = investment
  const { membershipInterestsTitle } = profileEntity

  const isTrustee = membershipInterestsTitle === ENTITY_MEMBERSHIP_INTEREST_TRUSTEE

  if (isTrustee) {
    return true
  }

  return false
}

export default isTrustInvestment
