import keyBy from "lodash.keyby"
import { useNavigate } from "react-router-dom"

import { PROJECT_STATES } from "@components/Domain"

const LABEL_TEXT = `"BANK_ACCOUNT_NAME" was flagged as invalid. [Click here](#)
to link another ACCOUNT_TYPE account for "PROJECT_NAME".`


const useInvalidBankAccountAlert = (bankAccounts, projects) => {
  const navigate = useNavigate()

  const bankAccountsMap = keyBy(bankAccounts, 'id')

  const invalidBankAccountsIds = bankAccounts
    .filter(item => item.isInvalid)
    .map(item => item.id)

  const invalidFundignAccountProjects = projects
    .filter(({ state }) => state === PROJECT_STATES.OPEN)
    .filter(({ bankAccountId }) => invalidBankAccountsIds.includes(bankAccountId))

  const invalidDistributionsAccountProjects = projects
    .filter(({ state }) => [ PROJECT_STATES.OPEN, PROJECT_STATES.CLOSED ].includes(state))
    .filter(({ distributionBankAccountId }) => invalidBankAccountsIds.includes(distributionBankAccountId))

  const [ invalidFundingAccountProject ] = invalidFundignAccountProjects

  let shouldRenderAlert = false

  if (invalidFundingAccountProject) {
    const { id, bankAccountId, name: projectName } = invalidFundingAccountProject

    const bankAccount = bankAccountsMap[bankAccountId]
    const { sourceName: bankAccountName } = bankAccount

    const path = `/backstage/project/${id}/details`
    const onClick = () => navigate(path)

    shouldRenderAlert = true

    const text = LABEL_TEXT
      .replace("BANK_ACCOUNT_NAME", bankAccountName)
      .replace("ACCOUNT_TYPE", "funding")
      .replace("PROJECT_NAME", projectName)

    return { shouldRenderAlert, text, onClick }
  }

  const [ invalidDistributionsAccountProject ] = invalidDistributionsAccountProjects

  if (invalidDistributionsAccountProject) {
    const { id, distributionBankAccountId, name: projectName } = invalidDistributionsAccountProject

    const bankAccount = bankAccountsMap[distributionBankAccountId]
    const { sourceName: bankAccountName } = bankAccount

    const path = `/backstage/project/${id}/distributions`
    const onClick = () => navigate(path)

    shouldRenderAlert = true

    const text = LABEL_TEXT
      .replace("BANK_ACCOUNT_NAME", bankAccountName)
      .replace("ACCOUNT_TYPE", "distribution")
      .replace("PROJECT_NAME", projectName)

    return { shouldRenderAlert, text, onClick }
  }

  return { shouldRenderAlert }
}

export default useInvalidBankAccountAlert