import React from "react"

import { TimeZoneInput } from "@components/Form/Input"


const renderTimeZone = (itemProps, inputProps, request, form) => {
  return (
    <TimeZoneInput
      form={form}
      request={request}
      itemProps={itemProps}
      inputProps={inputProps}
    />
  )
}

export default renderTimeZone
