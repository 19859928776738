import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { hasAccess } from "@components/Authorization"
import { TYPE_NONE, TYPE_STRING } from "@components/Form"
import {
  readOperation,
  indexOperation,
  updateOperation,
} from "@components/Store/BackstageStore/useOptionsStore"

import Collection from "./Collection"
import renderTagItem from "./helpers/renderTagItem"
import getColorInputProps from "./helpers/getColorInputProps"
import OptionsCollectionFooter from "./OptionsCollectionFooter"

const updateSchema = updateOperation.getSchema([
  { name: "order", type: TYPE_NONE },
  { name: "name", type: TYPE_STRING },
  { name: "color", ...getColorInputProps() },
  { name: "description" },
])

const ENTITY_LABEL = 'Tag'


const OptionsCollection = ({ group, items }) => {
  const {
    addOption: onItemCreated,
    deleteOption: deleteItem,
    updateOption: onItemUpdated,
  } = useOutletContext()

  const isEditable = hasAccess(['options-write'])

  const collectionProps = {
    items,
    deleteItem,
    onItemCreated,
    onItemUpdated,
    readOperation,
    indexOperation,
    updateOperation,
  }

  collectionProps.footer = () =>
    <OptionsCollectionFooter group={group} />

  collectionProps.entity = ENTITY_LABEL
  collectionProps.renderItem = renderTagItem
  collectionProps.isEditable = isEditable
  collectionProps.indexItems = () => {}
  collectionProps.updateSchema = updateSchema

  return (
    <Collection {...collectionProps} />
  )
}

OptionsCollection.propTypes = {
  group: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export default OptionsCollection
