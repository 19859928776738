import PropTypes from "prop-types"

import AddressShape from "./AddressShape"

const properties = {
  accountName: PropTypes.string,
  custodianAddress: AddressShape,
  custodianEinNumber: PropTypes.string,
  custodianId: PropTypes.string,
  custodianName: PropTypes.string,
  hasPurchasedExemptSecurities: PropTypes.bool,
  isLiabilityAccepted: PropTypes.bool,
  profileName: PropTypes.string,
  shouldCustodianSign: PropTypes.bool
}

const SdiraShape = PropTypes.exact(properties)

export default SdiraShape
export { properties }
