

const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Byte'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'Kb', 'Mb', 'Gb', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  // eslint-disable-next-line no-restricted-properties, prefer-exponentiation-operator
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`
}

export default formatBytes
