import { useMemo } from "react"
import keyBy from "lodash.keyby"
import uniqBy from "lodash.uniqby"
import sortBy from "lodash.sortby"
import { useOutletContext } from "react-router-dom"

import { getConfig } from "@components/Config"

const PLATFORM_EMAIL = getConfig("PLATFORM_EMAIL")
const PLATFORM_SHORT_NAME = getConfig("PLATFORM_SHORT_NAME")

const [ , PLATFORM_HOST ] = PLATFORM_EMAIL.split("@")
const DEFAULT_CAMPAIGN_FROM_USER = "marketing"


const useSenders = (organization) => {
  const {
    activeMembers,
    activeBrokers,
    computeUserFullname
  } = useOutletContext()

  const senders = useMemo(() => {
    if (!organization) {
      return []
    }

    const { name } = organization
    const fromName = `${name} via ${PLATFORM_SHORT_NAME}`

    const fromAddress = `${DEFAULT_CAMPAIGN_FROM_USER}@${PLATFORM_HOST}`

    const { emailIdentity = {} } = organization
    const { domain: customDomain } = emailIdentity

    const values = [
      {
        fromName,
        fromAddress,
      }
    ]

    if (!customDomain) {
      return values
    }

    const identities = uniqBy([
      ...activeMembers,
      ...activeBrokers,
    ], "id")

    const identityValues = sortBy(
      identities
        .map(({ id, email }) => ({
          fromName: computeUserFullname(id),
          fromAddress: email,
          disabled: !email.endsWith(customDomain),
        }))
    , "fromName")

    values.push(...identityValues)

    return values
  }, [
    organization,
    activeMembers,
    activeBrokers,
    computeUserFullname
  ])

  const sendersMap = useMemo(() =>
    keyBy(senders, "fromAddress")
  , [ senders ])

  return [ senders, sendersMap ]
}

export default useSenders
