import React, { useState } from "react"
import PropTypes from "prop-types"

import { Modal } from "@components/Page"
import Form, { useForm } from "@components/Form"
import { useAppContext } from "@components/AppContext"
import { updateMyCustomerBeneficialOwnerOperation } from "@api/services/transactions"

import useInputs from "../BeneficialOwnerCreateModal/helpers/useInputs"

const LABEL_TITLE = "Edit [NAME]"


const UpdateBeneficialOwnerModal = ({
  onSuccess: customOnSuccess,
  modal,
  customerId,
}) => {
  const form = useForm()

  const [ isOpen, setIsOpen ] = useState(false)
  const [ beneficialOwner, setBeneficialOwner ] = useState({})

  const { authenticatedRequest } = useAppContext()
  const [ schema, updateSchema ] = useInputs(updateMyCustomerBeneficialOwnerOperation, form)

  const { beneficialOwnerUrl, firstName, lastName } = beneficialOwner

  const onClose = () => {
    setBeneficialOwner({})
    form.resetFields()
    setIsOpen(false)
  }

  modal.current.open = newBeneficialOwner => {
    setBeneficialOwner(newBeneficialOwner)
    form.setFieldsValue(newBeneficialOwner)
    updateSchema(newBeneficialOwner)
    setIsOpen(true)
  }

  const onSuccess = () => {
    onClose()
    customOnSuccess()
  }

  const onSubmit = parameters => {
    parameters.customerId = customerId
    parameters.beneficialOwnerUrl = beneficialOwnerUrl

    return parameters
  }

  const fullName = `${firstName} ${lastName}`
  const title = LABEL_TITLE
    .replace("[NAME]", fullName)

  const formOptions = {
    form,
    schema,
    onSuccess,
    onSubmit,
    item: beneficialOwner,
    request: authenticatedRequest,
    operation: updateMyCustomerBeneficialOwnerOperation,
    shouldOptimizeUpdateMutation: false,
  }

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Form
        {...formOptions}
      />
    </Modal>
  )
}

UpdateBeneficialOwnerModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
}

export default UpdateBeneficialOwnerModal
