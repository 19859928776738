import React, { useRef, useCallback } from "react"
import { useOutletContext, useParams } from "react-router-dom"

import { W1 } from "@components"
import { InvestmentDrawer } from "@components/Investment"
import {
  TransactionDrawer,
  DistributionsTable,
  useSendNotifications,
  TransactionCreateModal,
  DistributionsImportModal,
} from "@modules/backstage/transactions"

import DistributionBankAccount from "./DistributionBankAccount"


const ProjectDistributionsPage = () => {
  const createModal = useRef({})
  const investmentDrawer = useRef({})
  const transactionDrawer = useRef({})
  const distributionsImportModal = useRef({})

  const { id: projectId } = useParams()
  const sendNotifications = useSendNotifications()
  const { table, getProject, activeInvestments = [], bankAccountsMap } = useOutletContext()

  const openCreateModal = () =>
    createModal.current.open()

  const openImportModal = () =>
    distributionsImportModal.current.open()

  const openTransactionDrawer = useCallback(
    distribution => transactionDrawer.current.open(distribution)
  , [])

  const openInvestmentDrawer = useCallback(
    investmentId => investmentDrawer.current.open(investmentId)
  , [])

  const indexTransactions = showSpinner =>
    table.current.indexItems(showSpinner)

  const onCreateSuccess = async () => {
    await table.current.indexItems(false)
    await sendNotifications()
  }

  const onImportSuccess = async (isRegisterOperation) => {
    await table.current.indexItems(false)

    if (!isRegisterOperation) {
      await sendNotifications()
    }
  }

  const project = getProject(projectId)
  const { distributionBankAccountId } = project

  const bankAccount = bankAccountsMap[distributionBankAccountId]
  const isInvalidBankAccount = !bankAccount || bankAccount.isInvalid

  const hasDistributionsAccount = !!project && !!distributionBankAccountId
  return (
    <div style={{ padding: W1 }}>
      <DistributionBankAccount />

      <DistributionsTable
        table={table}
        projectId={projectId}
        openCreateModal={openCreateModal}
        openImportModal={openImportModal}
        openInvestmentDrawer={openInvestmentDrawer}
        isInvalidBankAccount={isInvalidBankAccount}
        openTransactionDrawer={openTransactionDrawer}
        hasDistributionsAccount={hasDistributionsAccount}
      />

      <TransactionCreateModal
        modal={createModal}
        onSuccess={onCreateSuccess}
        investments={activeInvestments}
      />

      <InvestmentDrawer
        drawer={investmentDrawer}
      />

      <TransactionDrawer
        drawer={transactionDrawer}
        indexTransactions={indexTransactions}
      />

      <DistributionsImportModal
        modal={distributionsImportModal}
        onSuccess={onImportSuccess}
        investments={activeInvestments}
        isInvalidBankAccount={isInvalidBankAccount}
        hasDistributionsAccount={hasDistributionsAccount}
      />
    </div>
  )
}

export default ProjectDistributionsPage
