import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Space } from "antd"
import { useOutletContext } from "react-router-dom"

import { wait } from "@api/helpers/wait"
import { Modal } from "@components/Page"
import { MarkdownAlert } from "@components/Text"
import { useAppContext } from "@components/AppContext"
import { IndexOrganizationsOutputShape } from "@api/services/organizations"

import config from "./config"
import OrganizationDestroySteps from "./OrganizationDestroySteps"

const LABEL_TITLE = "Destroy Organization"
const LABEL_WARNING = `The destroy operation is irreversible. All
organization-related resources, including all investor data, will be deleted
from the platform. This process takes some time, so please do not close the
window.`

const ERROR_DESTROY_FAILED = "Failed to destroy [STEP_TITLE]"


const OrganizationDestroyModal = ({ modal, organization }) => {
  const { updateOrganization } = useOutletContext()
  const { authenticatedRequest, showErrorMessage } = useAppContext()

  const [ isOpen, setIsOpen ] = useState(false)
  const [ currentStep, setCurrentStep ] = useState(-1)
  const [ isInProgress, setIsInProgress ] = useState(false)

  modal.current.open = () =>
    setIsOpen(true)

  const onClose = () => {
    setIsOpen(false)
    setIsInProgress(false)
  }

  const destroy = async () => {
    const { id } = organization

    setIsInProgress(true)
    setCurrentStep((prev) => prev + 1)

    for (let i = 0; i < config.length; i++) {
      const configItem = config[i]

      const {
        title,
        operation,
        retryableErrors,
        timestampFieldName,
      } = configItem

      const isStepCompleted = !!organization[timestampFieldName]

      if (isStepCompleted) {
        setCurrentStep((prev) => prev + 1)

        // eslint-disable-next-line no-continue
        continue
      }

      let successExecution = false

      while (!successExecution) {
        if (!isOpen) {
          return
        }

        try {
          const parameters = { organizationId: id }
          const { data: updatedOrganization } = await authenticatedRequest(operation, parameters, retryableErrors)

          setCurrentStep((prev) => prev + 1)
          await wait(1000)

          updateOrganization(updatedOrganization)
          successExecution = true

        } catch (error) {
          const isExpected = retryableErrors
            ? retryableErrors.includes(error.code)
            : false

          if (!isExpected) {
            setIsInProgress(false)

            const message = ERROR_DESTROY_FAILED.replace('[STEP_TITLE]', title)
            showErrorMessage(message)

            return
          }

          await wait(5000)
        }
      }
    }

    onClose()
  }

  const modalProps = {
    title: LABEL_TITLE,
    isOpen,
    onClose,
  }

  return (
    <Modal {...modalProps}>
      <Space
        style={{ width: "100%" }}
        direction="vertical"
      >
        <MarkdownAlert
          text={LABEL_WARNING}
          type="warning"
        />

        <OrganizationDestroySteps
          current={currentStep}
          organization={organization}
        />

        <Button
          danger
          style={{ width: "100%" }}
          loading={isInProgress}
          onClick={destroy}
        >
          {LABEL_TITLE}
        </Button>
      </Space>
    </Modal>
  )
}

OrganizationDestroyModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  organization: IndexOrganizationsOutputShape.isRequired,
}

export default OrganizationDestroyModal
