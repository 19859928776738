import React, { useEffect, useCallback, useMemo } from "react"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { DatePicker, Form, Input } from "antd"

import { ItemPropsShape, InputPropsShape } from "@api"
import { getUsDateFromIsoDate, getIsoDateFromStringDate } from "@components/Date"

import FormShape from "../shapes/FormShape"

const { Item } = Form

const US_DATE_FORMAT = "MM/DD/YYYY"


const UsDateInput = ({
  form,
  path,
  itemProps,
  inputProps
}) => {
  const {
    name,
    initialValue,
    ...restItemProps
  } = itemProps

  const {
    onChange: onChangeCustom,
    ...restInputProps
  } = inputProps

  if (inputProps.placeholder) {
    delete inputProps.placeholder
  }

  const placeholder = getUsDateFromIsoDate(new Date().toISOString())

  const inputField = `_${name.slice(-1)}`
  const inputName = [...name.slice(0, -1), inputField]
  const inputPath = useMemo(() => [...path.slice(0, -1), inputField], [path, inputField])

  const sourceValue = Form.useWatch(path, form)
  const inputSourceValue = Form.useWatch(inputName, form)

  const setValue = useCallback(() => {
    const value = form.getFieldValue(path)
    form.setFieldValue(inputPath, value)
  }, [ form, path, inputPath ])

  useEffect(() => {
    setValue()
  }, [ sourceValue, inputSourceValue, setValue ])

  const onChange = dateObject => {
    if (!dateObject) {
      return form.setFieldValue(path, undefined)
    }

    const { $d } = dateObject
    const isoDate = getIsoDateFromStringDate($d)
    form.setFieldValue(path, isoDate)

    if (onChangeCustom) {
      onChangeCustom(isoDate, form)
    }
  }

  return (
    <>
      <Item
        key="hidden"
        name={name}
        hidden={true}
        initialValue={initialValue}
      >
        <Input />
      </Item>
      <Item
        name={inputName}
        getValueProps={value => ({ value: value ? dayjs(value) : value })}
        {...restItemProps}
      >
        <DatePicker
          format={US_DATE_FORMAT}
          onChange={onChange}
          allowClear={true}
          placeholder={placeholder}
          {...restInputProps}
        />
      </Item>
    </>
  )
}

UsDateInput.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  form: FormShape.isRequired,
  itemProps: ItemPropsShape.isRequired,
  inputProps: InputPropsShape.isRequired,
}

export default UsDateInput
