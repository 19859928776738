import PropTypes from "prop-types"

import { UserGroupRoleEnumKeys } from "./shapes/UserGroupRoleEnum"

const properties = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  email: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.oneOf(UserGroupRoleEnumKeys).isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const CreateUserGroupOutputShapeShape = PropTypes.exact(properties)

export default CreateUserGroupOutputShapeShape
export { properties }
