import React from "react"
import PropTypes from "prop-types"
import { Space, Tooltip } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons"

const LABEL_TOOLTIP = "Distributions bank account is linked by your sponsor."


const NotSourceController = ({ sourceName = undefined }) => {
  const isEmpty = !sourceName || sourceName === ""

  if (isEmpty) {
    return null
  }

  return (
    <Space>
      <Tooltip title={LABEL_TOOLTIP}>
        <InfoCircleOutlined />
      </Tooltip>

      {sourceName}
    </Space>
  )
}

NotSourceController.propTypes = {
  sourceName: PropTypes.string
}

export default NotSourceController
