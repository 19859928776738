import getOperation from "../../helpers/getOperation"

import IndexDocusignWebhooksOutputShape from "./IndexDocusignWebhooksOutputShape"
import ListTemplatesOutputShape from "./ListTemplatesOutputShape"

export {
  IndexDocusignWebhooksOutputShape,
  ListTemplatesOutputShape
}

export const indexDocusignWebhooksOperation = getOperation("documents/IndexDocusignWebhooks")
export const listTemplatesOperation = getOperation("documents/ListTemplates")
