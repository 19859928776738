import React, { useRef, useMemo } from "react"
import get from "lodash.get"
import { useOutletContext } from "react-router-dom"

import { W0, W1, W5 } from "@components"
import { CollapseTable } from "@components/Table"
import { SponsorUpdateModal } from "@modules/backstage/sponsors"
import {
  BrokersTable,
  UserUpdateModal,
  BrokerUpdateModal,
  BrokerCreateModal,
} from "@modules/backstage/users"

const LABEL_ACTIVE = "Active Liaisons"
const LABEL_DISABLED = "Inactive Liaisons"


const SettingsBrokersPage = () => {
  const { table, brokers } = useOutletContext()

  const userUpdateModal = useRef({})
  const brokerUpdateModal = useRef({})
  const brokerCreateModal = useRef({})
  const sponsorUpdateModal = useRef({})
  const activeBrokersTable = useRef({})
  const disabledBrokersTable = useRef({})

  const activeLiaisons = useMemo(() => brokers
    .filter(({ isDisabled }) => isDisabled === false)
  , [ brokers ])

  const disabledLiaisons = useMemo(() => brokers
    .filter(({ isDisabled }) => isDisabled === true)
  , [ brokers ])

  const openUpdateSponsorModal = sponsorId =>
    sponsorUpdateModal.current.open(sponsorId)

  const openCreateBrokerModal = () =>
    brokerCreateModal.current.open()

  const openUpdateBrokerModal = id =>
    brokerUpdateModal.current.open(id)

  const openUpdateMemberModal = id =>
    userUpdateModal.current.open(id)

  const style = {
    padding: W1,
    paddingTop: W0,
    paddingBottom: W5
  }

   table.current.search = value => {
    const tables = [
      activeBrokersTable,
      disabledBrokersTable,
    ]

    for (const usersTable of tables) {
      const search = get(usersTable, 'current.search')

      if (search) {
        search(value)
      }
    }
  }

  return (
    <div style={style}>
      <CollapseTable
        title={LABEL_ACTIVE}
        items={activeLiaisons}
      >
        <BrokersTable
          table={activeBrokersTable}
          items={activeLiaisons}
          openCreateBrokerModal={openCreateBrokerModal}
          openUpdateBrokerModal={openUpdateBrokerModal}
          openUpdateMemberModal={openUpdateMemberModal}
          openUpdateSponsorModal={openUpdateSponsorModal}
        />
      </CollapseTable>

      <CollapseTable
        title={LABEL_DISABLED}
        items={disabledLiaisons}
        isOpenByDefault={false}
      >
        <BrokersTable
          table={disabledBrokersTable}
          items={disabledLiaisons}
          openCreateBrokerModal={openCreateBrokerModal}
          openUpdateBrokerModal={openUpdateBrokerModal}
          openUpdateMemberModal={openUpdateMemberModal}
          openUpdateSponsorModal={openUpdateSponsorModal}
        />
      </CollapseTable>

      <UserUpdateModal modal={userUpdateModal} />
      <BrokerUpdateModal modal={brokerUpdateModal} />
      <BrokerCreateModal modal={brokerCreateModal} />
      <SponsorUpdateModal modal={sponsorUpdateModal} />
    </div>
  )
}

export default SettingsBrokersPage
