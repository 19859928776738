import uniq from "lodash.uniq"

import { PROJECT_TAG_LABELS } from "@components/Domain"


const getProjectTagLabelOptions = projects => {
  const labels = projects
    .reduce((result, { tags = [] }) => [ ...result, ...tags ], [])
    .map(({ label }) => label)

  const uniqLabels = uniq([ ...PROJECT_TAG_LABELS, ...labels ]).sort()

  const options = uniqLabels.map(item => ({ value: item, label: item }))

  return options
}

export default getProjectTagLabelOptions
