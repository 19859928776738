import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Descriptions } from "antd"

import { Drawer } from "@components/Page"
import { readOperation } from "@components/Store/BackstageStore/useCampaignsStore"
import { Value, EmailPreview } from "@components/Text"
import { W1, EMAIL_DRAWER_WIDTH } from "@components"
import { useRequest as useEffect } from "@components/AppContext"

const LABEL_TO = "To"
const LABEL_FROM = "From"
const LABEL_SENT = "Sent At"
const LABEL_CAMPAIGN_DELETED = "Related campaign was deleted."


const NotificationDrawer = ({ drawer }) => {
  const [ html, setHtml ] = useState('')
  const [ notification, setNotification ] = useState()
  const [ isDeletedCampaign, setIsDeletedCampaign ] = useState(false)

  useEffect(request => {
    if (!notification) {
      return
    }

    const {
      body,
      html: messageHtml
    } = notification.emailMessage

    const isCampaign = !messageHtml

    if (!isCampaign) {
      setHtml(messageHtml)
      return
    }

    const isJson = body.startsWith('json:')

    if (!isJson) {
      setHtml(body)
      return
    }

    const [ , bodyJson ] = body.split('json:')

    const parameters = JSON.parse(bodyJson)

    const { campaignId } = parameters

    request(readOperation, { id: campaignId }, ['DocumentNotFoundError'])
      .then(({ data }) => setHtml(data.html))
      .catch(() => setIsDeletedCampaign(true))
  }, [ notification ])

  const items = useMemo(() => !notification
    ? []
    : [
        {
          key: 'toAddress',
          label: LABEL_TO,
          children: notification.emailMessage.toAddress,
        },
        {
          key: 'fromAddress',
          label: LABEL_FROM,
          children: notification.emailMessage.fromAddress,
        },
        ...(
          !notification.sentAt
            ? []
            : [
              {
                key: 'sentAt',
                label: LABEL_SENT,
                children: <Value value={notification.sentAt} />,
              }
            ]
        ),
      ]
  , [ notification ])

  const title = useMemo(() => (
    notification
      ? notification.emailMessage.subject
      : ""
  ), [ notification ])

  const onClose = () => {
    setHtml('')
    setNotification()
    setIsDeletedCampaign(false)
  }

  const drawerProps = {
    width: EMAIL_DRAWER_WIDTH,
    onOpen: setNotification,
    destroyOnClose: true,
    title,
    drawer,
    onClose,
  }

  const previewStyle = {
    marginTop: W1,
    marginLeft: -1 * W1,
    marginRight: -1 * W1
  }

  const preview = isDeletedCampaign
    ? LABEL_CAMPAIGN_DELETED
    : (
        <div style={previewStyle}>
          <EmailPreview html={html} />
        </div>
      )

  return (
    <Drawer {...drawerProps}>
      <Descriptions
        size="small"
        items={items}
        column={1}
        bordered={false}
      />

      {preview}
    </Drawer>
  )
}

NotificationDrawer.propTypes = {
  drawer: PropTypes.shape().isRequired,
}

export default NotificationDrawer
