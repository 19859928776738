import React from "react"
import { useOutletContext } from "react-router-dom"

import Identity from "@components/Identity"

const LABEL_LIAISON = "Liaison"


const useLiaisonColumnProps = renderRejected => {
  const { computeUserFullname } = useOutletContext()

  return {
    key: "brokerUserId",
    title: LABEL_LIAISON,
    compute: ({ brokerUserId }) => computeUserFullname(brokerUserId),
    render: (value, { status, brokerUserId }) =>
      renderRejected(status, <Identity id={brokerUserId}>{value}</Identity>)
    ,
    isSearchable: true,
  }
}

export default useLiaisonColumnProps
