import React, { useRef } from "react"
import { Alert, Typography } from "antd"

import { Markdown } from "@components/Text"
import { getConfig } from "@components/Config"
import { useAppContext } from "@components/AppContext"
import UserMobileUpdateModal from "@modules/backstage/users/UserMobileUpdateModal"
import { VerificationRequestModal } from "@components/Identity"
import { enableMyUser2FAOperation as enableOperation } from "@api/services/backstage"

const is2FaRequired = getConfig('is2FaRequired')

const LABEL_MESSAGE = `
  Two factor authentication should be enabled,
  [click here](link) to proceed.
`


const SecondFactorAlert = () => {
  const userMobileUpdateModal = useRef({})
  const verificationRequestModal = useRef({})

  const { identity, updateIdentityMobile, updateIdentityIs2FaEnabled } = useAppContext()
  const { id: userId, is2FaEnabled, mobile } = identity

  const openUserMobileUpdateModal = () =>
    userMobileUpdateModal.current.open(userId)

  const openVerificationRequestModal = () =>
    verificationRequestModal.current.open(userId)

  const onClick = mobile
    ? openVerificationRequestModal
    : openUserMobileUpdateModal

  const onMobileUpdateSuccess = (updatedUser) => {
    updateIdentityMobile(updatedUser.mobile)
    openVerificationRequestModal()
  }

  const onVerificationSuccess = async () => {
    updateIdentityIs2FaEnabled(true)
  }

  if (!is2FaRequired) {
    return null
  }

  if (is2FaEnabled) {
    return null
  }

  const components = {
    a(props) {
      // eslint-disable-next-line react/prop-types
      const { children } = props

      return (
        <Typography.Link onClick={onClick}>
          {children}
        </Typography.Link>
      )
    }
  }

  return (
    <div className="header-alerts">
      <Alert
        banner
        message={
          <Markdown
            text={LABEL_MESSAGE}
            components={components}
          />
        }
      />

      <UserMobileUpdateModal
        modal={userMobileUpdateModal}
        onSuccess={onMobileUpdateSuccess}
      />

      <VerificationRequestModal
        modal={verificationRequestModal}
        mobile={mobile}
        onSuccess={onVerificationSuccess}
        enableOperation={enableOperation}
      />
    </div>
  )
}

export default SecondFactorAlert
