import React from "react"
import PropTypes from "prop-types"
import { Form } from "antd"

import InputExtra from "@components/Form/InputExtra"
import { getConfig } from "@components/Config"
import { UpdateModal } from "@components/Page"
import { MODAL_WIDTH2 } from "@components"
import { useAppContext } from "@components/AppContext"
import { changeMyIdentityEmailOperation as operation } from "@api/services/identity"

const PLATFORM_NAME = getConfig("PLATFORM_NAME")

const LABEL_EMAIL = "New Email Address"
const LABEL_ENTITY = "Email"
const LABEL_CONTINUE = "Continue"
const LABEL_CHANGE_EMAIL = "Change Email"
const LABEL_FAILED_MESSAGE = "Failed to change the email"
const LABEL_EMAIL_EXTRA = `${PLATFORM_NAME} sends an email verification code to
the specified address. Be sure to check the spam folder in case you can't find
it in your inbox.`


const ChangeEmailModal = ({ modal, onSuccess: onSuccessCustom }) => {
  const [ form ] = Form.useForm()

  const { authenticatedRequest: request } = useAppContext()

  const schema = operation.getSchema([
    {
      name: "email",
      label: LABEL_EMAIL,
      itemProps: {
        extra: <InputExtra text={LABEL_EMAIL_EXTRA} />,
      },
    }
  ])

  const onSuccess = () => {
    const { email } = form.getFieldsValue()

    return onSuccessCustom(email)
  }

  const updateModalProps = {
    width: MODAL_WIDTH2,
    title: LABEL_CHANGE_EMAIL,
    entity: LABEL_ENTITY,
    submitLabel: LABEL_CONTINUE,
    failedMessage: LABEL_FAILED_MESSAGE,
    successMessage: false,
    form,
    modal,
    schema,
    request,
    onSuccess,
    operation,
  }

  return (
    <UpdateModal {...updateModalProps} />
  )
}

ChangeEmailModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default ChangeEmailModal
