import PropTypes from "prop-types"

import EmailMessageShape from "./shapes/EmailMessageShape"

const properties = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  emailMessage: EmailMessageShape.isRequired,
  id: PropTypes.string.isRequired,
  investmentId: PropTypes.string,
  isSent: PropTypes.bool.isRequired,
  key: PropTypes.string,
  projectId: PropTypes.string,
  recipientId: PropTypes.string,
  scheduledAt: PropTypes.string,
  sentAt: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const SendNotificationOutputShapeShape = PropTypes.exact(properties)

export default SendNotificationOutputShapeShape
export { properties }
