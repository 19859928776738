import React from "react"
import { Typography, Tag, Space } from "antd"

import Identity from "@components/Identity"
import { hasAccess } from "@components/Authorization"
import BankAccountVerificationButton from "../../BankAccountVerificationButton"

import TableRowActions from "../TableRowActions"

const LABEL_ACH = "ACH Funding / Distributions"
const LABEL_WIRE = "Wire Funding"
const LABEL_LABEL = "Enabled"
const LABEL_ENTITY = "Entity"
const LABEL_CREATED = "Created By"
const LABEL_INVALID = "Invalid"


const getColumnsConfig = (
  openBankAccount,
  removeBankAccount,
  isController
) => {
  const beneficiaryNameColumnProps = {
    key: "beneficiaryName",
    title: LABEL_ENTITY,
    render: value => <Typography.Text>{value}</Typography.Text>,
    isSearchable: true
  }

  const sourceNameColumnProps = {
    key: "sourceName",
    title: "Account Name",
    render: (value, bankAccount) => (
        <Space>
          <Typography.Link strong onClick={() => openBankAccount(bankAccount)}>
            {value}
          </Typography.Link>

          {
            bankAccount.isInvalid && (
              <Tag color="red" bordered={false}>
                {LABEL_INVALID}
              </Tag>
            )
          }
        </Space>
    ),
    isSearchable: true
  }

  const createdByColumnProps = {
    key: "createdBy",
    title: LABEL_CREATED,
    render: userId => <Identity id={userId} />
  }

  const fundingColumnProps = {
    key: "_funding",
    title: LABEL_WIRE,
    align: "center",
    render: () => <Tag color="green" bordered={false}>{LABEL_LABEL}</Tag>,
  }

  const distributionsColumnProps = {
    key: "_distributions",
    title: LABEL_ACH,
    align: "center",
    render: (_, bankAccount) =>
      <BankAccountVerificationButton bankAccount={bankAccount} />
  }

  const items = [
    { ...beneficiaryNameColumnProps },
    { ...sourceNameColumnProps },
    { ...fundingColumnProps, width: 200 },
    { ...distributionsColumnProps, width: 200 },
    { ...createdByColumnProps },
  ]

  const canDelete = hasAccess(['bank-accounts-write'])

  if (!canDelete) {
    return items
  }

  if (!isController) {
    return items
  }

  items.push({
    key: "actions",
    title: false,
    width: 48,
    render: (_, bankAccount) =>
      <TableRowActions
        bankAccount={bankAccount}
        removeBankAccount={removeBankAccount}
      />
  })

  return items
}

export default getColumnsConfig
