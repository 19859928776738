import React from "react"
import PropTypes from "prop-types"
import { Dropdown, Checkbox, Space, Tag, theme } from "antd"

const { useToken } = theme


const ActionsDropdown = ({
  tagIds,
  children,
  onChange,
  tagOptions,
}) => {
  const { token } = useToken()

  const contentStyle = {
    boxShadow: token.boxShadowSecondary,
    borderRadius: token.borderRadiusLG,
    backgroundColor: token.colorBgElevated,
  }

  const dropdownRender = () => (
    <div style={contentStyle}>
      <div style={{ maxHeight: 400, overflow: "scroll" }}>
        <Space style={{ padding: 8 }} direction="vertical">
          {
            tagOptions.map(tag => (
              <Checkbox
                key={tag.id}
                checked={tagIds.includes(tag.id)}
                onChange={e => onChange(tag.id, e.target.checked)}
              >
                <Tag
                  key={tag.id}
                  color={tag.color?.toLowerCase()}
                  bordered={false}
                >
                  {tag.name}
                </Tag>
              </Checkbox>
            ))
          }
        </Space>
      </div>
    </div>
  )

  const trigger = ['click']

  return (
    <Dropdown
      trigger={trigger}
      dropdownRender={dropdownRender}
    >
      {children}
    </Dropdown>
  )
}

ActionsDropdown.propTypes = {
  tagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  tagOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export default ActionsDropdown
