import React from "react"
import { Descriptions } from "antd"
import PropTypes from "prop-types"

import { Value } from "@components/Text"
import { ReadActivityOutputShape } from "@api/services/activities"


const ActivityAttributesDescriptions = ({
  activity,
  attributeKey,
}) => {
  const attributes = activity[attributeKey]

  const items = attributes
    .map(({ label, key, value }) => (
      {
        key,
        label,
        children: <Value value={value} />,
      }
    ))

  return (
    <Descriptions
      size="small"
      items={items}
      column={1}
      bordered={false}
    />
  )
}

ActivityAttributesDescriptions.propTypes = {
  activity: ReadActivityOutputShape.isRequired,
  attributeKey: PropTypes.string.isRequired,
}

export default ActivityAttributesDescriptions
