

const getCurrencyValue = (value, minimumFractionDigits = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits
  })

  const amount = value / 100

  return formatter.format(amount)
}

export default getCurrencyValue
