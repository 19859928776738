import { BeneficialOwnerVerificationStatusEnumKeys } from "./shapes/BeneficialOwnerVerificationStatusEnum"

const ListMyCustomerBeneficialOwnersItemSchema = {
  controllerEmail: { format: 'email' },
  dateOfBirth: { format: 'date' },
  verificationStatus: { enum: BeneficialOwnerVerificationStatusEnumKeys },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default ListMyCustomerBeneficialOwnersItemSchema
