import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { App, Dropdown, Button, Space, Typography } from "antd"
import { DownOutlined, ReloadOutlined, CheckOutlined, SendOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"
import { Value } from "@components/Text"
import { confirmAction } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { DATETIME_FORMAT } from "@components/Date"
import { updateInvestmentSignedOperation as updateSignedOperation } from "@api/services/investments"

import SignUrlShareModal from "./SignUrlShareModal"
import { InvestmentShape } from "../../../shapes"

const LABEL_URL = "Send DocuSign Link"
const LABEL_RESET = "Reset"
const LABEL_SIGNED = "Update to Signed"
const LABEL_PENDING = "Pending"
const LABEL_INCOMPLETE = "Incomplete Investment Profile"
const LABEL_SEND_BLOCKED = `Unable to send the DocuSign link to the investor
because the investment profile is incomplete. Be sure to include all necessary
information in the investment profile and try again.`
const LABEL_SIGN_BLOCKED = `The investment signing is on hold. Please ensure
that the investor and investment profile have all the necessary information
filled out and try again.`

const KEY_URL = "url"
const KEY_SIGNED = "signed"
const KEY_PENDING = "pending"

const ACTION_SIGN = {
  type: "primary",
  title:  "Update Subscription Document Status?",
  action: "Confirm",
  content: `By clicking "Confirm" below, an email notification will be sent to
the investor to notify them that their subscription documents are signed.`,
  templateDisabledContent: `By clicking "Confirm" below, the status of the
subscription documents will be updated to signed. Email notification will not
be sent to investor because the notifications are currently turned off.`
}


const InvestmentSignedDropdown = ({
  investment,
  readInvestment,
  isInvestmentSignedTemplateEnabled
}) => {
  const { modal } = App.useApp()
  const { request } = useAppContext()

  const canUpdateInvestment = hasAccess(['investments-write'])

  const signUrlShareModal = useRef({})
  const [ isLoading, setIsLoading ] = useState(false)

  const {
    id,
    signedAt,
    isSigned
  } = investment

  const format = DATETIME_FORMAT
  const children = !isSigned
    ? LABEL_PENDING
    : <Value type="secondary" value={signedAt} format={format} />

  const items = []

  const isPending = !isSigned

  if (!isSigned) {
    items.push({
      key: KEY_SIGNED,
      icon: <CheckOutlined />,
      label: LABEL_SIGNED,
    })

    items.push({
      key: KEY_URL,
      icon: <SendOutlined  />,
      label: LABEL_URL,
    })
  }

  if (!isPending) {
    items.push({
      key: KEY_PENDING,
      icon: <ReloadOutlined />,
      label: LABEL_RESET,
      danger: true,
    })
  }

  const style = {
    marginTop: -1,
    marginLeft: -8,
  }

  const updateInvestmentSigned = key => {
    const mutationsMap = {
      [KEY_SIGNED]: { isSigned: true },
      [KEY_PENDING]: { isSigned: false }
    }

    const mutation = mutationsMap[key]

    setIsLoading(true)
    return request(updateSignedOperation, { id, mutation })
      .then(() => readInvestment(id))
      .then(() => setIsLoading(false))
  }

  const { isProfileComplete, isInvestorComplete } = investment
  const isComplete = isProfileComplete && isInvestorComplete

  const createSignUrl = () => isComplete
    ? signUrlShareModal.current.open()
    : modal.error({
        title: LABEL_INCOMPLETE,
        content: LABEL_SEND_BLOCKED,
      })

  const signAction = actionKey => {
    const shouldShowErrorModal =
      !isComplete &&
      actionKey === KEY_SIGNED

    if (shouldShowErrorModal) {
      return modal.error({
        title: LABEL_INCOMPLETE,
        content: LABEL_SIGN_BLOCKED,
      })
    }

      const {
        templateDisabledContent,
        content: templateEnabledContent
      } = ACTION_SIGN

      const content = isInvestmentSignedTemplateEnabled()
        ? templateEnabledContent
        : templateDisabledContent

    return confirmAction(
      modal,
      ACTION_SIGN.action,
      ACTION_SIGN.title,
      content,
      () => updateInvestmentSigned(actionKey),
      ACTION_SIGN.type
    )
  }

  const onClick = ({ key }) => {
    const isUrl = key === KEY_URL

    const action = isUrl
      ? () => createSignUrl()
      : () => signAction(key)

    return action()
  }

  if (!canUpdateInvestment) {
    return signedAt && <Value type="secondary" value={signedAt} format={format} />
  }

  const menu = { items, onClick }
  const trigger = ['click']

  return (
    <div style={style}>
      <Dropdown
        menu={menu}
        trigger={trigger}
      >
        <Button
          type="text"
          size="small"
          loading={isLoading}
        >
          <Typography.Text type="secondary">
            <Space>
              {children}
              <DownOutlined />
            </Space>
          </Typography.Text>
        </Button>
      </Dropdown>

      <SignUrlShareModal
        modal={signUrlShareModal}
        investment={investment}
        readInvestment={readInvestment}
      />
    </div>
  )
}

InvestmentSignedDropdown.propTypes = {
  investment: InvestmentShape.isRequired,
  readInvestment: PropTypes.func.isRequired,
  isInvestmentSignedTemplateEnabled: PropTypes.func.isRequired,
}

export default InvestmentSignedDropdown
