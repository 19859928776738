import React from "react"
import { Row, Col, Typography, Space } from "antd"

import { Value } from "@components/Text"

import PropertyStatusTag from "../../PropertyStatusTag"

const { Text } = Typography

const LABEL_NA = "N/A"
const LABEL_ENTITY_NAME = "Entity Name"


const useRenderItem = () => {
  const getEntity = tags => tags
    .find(({ label }) => label === LABEL_ENTITY_NAME)?.value

  const render = (id, { tags, name, equityDeployedAmount, investmentDate, status }) =>
    <>
      <Row>
        <Col flex="auto">
          <Space>
            <Text strong>{name}</Text>
            {status ? <PropertyStatusTag status={status} /> : LABEL_NA}
          </Space>
        </Col>

        <Col>
          <Value value={equityDeployedAmount} />
        </Col>
      </Row>

      <Row>
        <Col flex="auto">
          <Text>{getEntity(tags)}</Text>
        </Col>

        <Col align="right">
          <Text type="secondary">
            <Value value={investmentDate} format={"L"} />
          </Text>
        </Col>
      </Row>
    </>

  return render
}

export default useRenderItem
