import React, { useState } from "react"
import PropTypes from "prop-types"
import { Checkbox, Space } from "antd"

import { Markdown } from "@components/Text"
import { TERMS_URLS } from "@components/Domain"
import { useAppContext } from "@components/AppContext"
import { SMALL_FONT_SIZE } from "@components"

const {
  DWOLLA_TERMS,
  PLAID_PRIVACY,
  DWOLLA_PRIVACY,
  FUNDFLOW_TERMS,
} = TERMS_URLS

export const LABEL_TEXT = `
  I agree to the [Terms of Services and Privacy Policy](${FUNDFLOW_TERMS}),
  as well as our partner Dwolla's [Terms of Service](${DWOLLA_TERMS}) and
  [Privacy Policy](${DWOLLA_PRIVACY}) and our partner Plaid's end user
  [Privacy Policy](${PLAID_PRIVACY}).
`


const TermsCheckbox = ({ renderButton }) => {
  const [ isButtonEnabled, setIsButtonEnabled ] = useState(false)

  const { identity } = useAppContext()
  const { customers = [] } = identity

  const hasCustomers = customers.length > 0
  const hasAcceptedTerms = hasCustomers

  if (hasAcceptedTerms) {
    return renderButton(true)
  }

  const onChange = event => {
    const hasAccepted = event.target.checked
    setIsButtonEnabled(hasAccepted)
  }

  return (
    <Space direction="vertical">
      <Space
        align="start"
        style={{ fontSize: SMALL_FONT_SIZE }}
        direction="horizontal"
      >
        <Checkbox
          style={{ marginTop: 10 }}
          onChange={onChange}
        />

        <Markdown
          text={LABEL_TEXT}
        />
      </Space>

      {renderButton(isButtonEnabled)}
    </Space>
  )
}

TermsCheckbox.propTypes = {
  renderButton: PropTypes.func.isRequired,
}

export default TermsCheckbox
