import React from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"


const Title = ({
  level = 5,
  children,
}) => {
  return (
    <Typography.Title level={level} style={{ marginTop: 0 }}>
      {children}
    </Typography.Title>
  )
}

Title.propTypes = {
  level: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export default Title
