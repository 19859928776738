import PropTypes from "prop-types"


const ProjectDetailsContextShape = PropTypes.exact({
  openProjectUpdateModal: PropTypes.func.isRequired,
  openPropertyCreateModal: PropTypes.func.isRequired,
  openPropertyUpdateModal: PropTypes.func.isRequired,
  openPropertyConnectModal: PropTypes.func.isRequired,
})

export default ProjectDetailsContextShape
