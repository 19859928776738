import {
  TRANSACTION_TYPES,
  TRANSACTION_STATUS
} from "@components/Domain"
import { getFormattedAmountValue, getFormattedDecimalValue } from "@components/Amount"

export const INCOMING_TOTAL_KEY = "in"
export const OUTGOING_TOTAL_KEY = "out"

export const DASHBOARD_TOTALS = [
  TRANSACTION_TYPES.SALE_PROFIT,
  TRANSACTION_TYPES.CAPITAL_RETURN,
  TRANSACTION_TYPES.INVESTMENT_RETURN
]

export const STATISTIC_LABELS_MAP = {
  [OUTGOING_TOTAL_KEY]: "Capital Invested",
  [TRANSACTION_TYPES.CAPITAL_RETURN]: "Capital Returned",
}

export const TITLE_DISTRIBUTIONS = "Distributions"
export const TITLE_OUTSTANDING = "Capital Outstanding"


const getStatistics = transactions => {
  if (transactions === undefined || transactions.length === 0) {
    return {}
  }

  const calculateTotalAmountForType = type =>
    transactions
      .filter(transaction => transaction.type === type)
      .filter(transaction => transaction.status === TRANSACTION_STATUS.PROCESSED)
      .map(({ amount }) => amount)
      .reduce((_, a) => _ + a, 0)

  const totalsMap = {
    [OUTGOING_TOTAL_KEY]: getFormattedAmountValue(calculateTotalAmountForType(TRANSACTION_TYPES.FUNDING)),
  }

  for (const type of DASHBOARD_TOTALS) {
    totalsMap[type] = getFormattedAmountValue(calculateTotalAmountForType(type))
  }

  totalsMap[INCOMING_TOTAL_KEY] = DASHBOARD_TOTALS
    .map(type => totalsMap[type])
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  return totalsMap
}

export default getStatistics
