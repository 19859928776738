import React from "react"

import {
  BackButton,
  SubmitButton,
  TYPE_CHECKBOX,
  FormNavigationFooter,
} from "@components/Form"

import { INITIAL_STEP } from "./getStepInputs"
import BeneficialOwnersInput from "../BeneficialOwnersInput"
import { BENEFICIAL_OWNER_STATUS_VERIFIED } from "../../../../Constants"

const LABEL_CONTROLLER = `I hereby certify that the information provided for
[ORGANIZATION] controller and beneficial owners is complete and correct.`

const LABEL_SUBMIT = "Certify"


const getOwnersInputs = ({
  form,
  setStep,
  customerId,
  customerName,
  beneficialOwners,
  indexBeneficialOwners,
}) => {
  const labelController = LABEL_CONTROLLER
    .replace("[ORGANIZATION]", customerName)

  const hasBeneficialOwners =
    !!beneficialOwners &&
    beneficialOwners.length > 0

  const isControllerConfirmed = form.getFieldValue('_isControllerConfirmed')

  const hasBeneficialOnwersVerified =
    !!beneficialOwners &&
    beneficialOwners
      .filter(({ verificationStatus }) => verificationStatus !== BENEFICIAL_OWNER_STATUS_VERIFIED )
      .length === 0

  const isSubmitEnabled = hasBeneficialOwners && hasBeneficialOnwersVerified && isControllerConfirmed

  const inputs = [
    {
      name: "_beneficialOwners",
      component: () => (
        <BeneficialOwnersInput
          onSuccess={indexBeneficialOwners}
          customerId={customerId}
          beneficialOwners={beneficialOwners}
        />
      )
    },
    {
      name: "_isControllerConfirmed",
      type: TYPE_CHECKBOX,
      label: "",
      required: true,
      inputProps: {
        label: labelController
      }
    },
    {
      name: "_submit",
      component: () => {
        const isLoading = form.isSubmitting()

        return (
          <FormNavigationFooter>
            {
              !hasBeneficialOwners && (
                <BackButton onClick={() => setStep(INITIAL_STEP)} />
              )
            }

            <SubmitButton
              isLoading={isLoading}
              isDisabled={!isSubmitEnabled}
            >
              {LABEL_SUBMIT}
            </SubmitButton>
          </FormNavigationFooter>
        )
      }
    },
  ]

  return inputs
}

export default getOwnersInputs
