import keyBy from "lodash.keyby"

import operationRequest from "@components/Authorization/request/operationRequest"
import { indexAccountsOperation } from "@api/services/investments"


const getOrganizationAccounts = async (authorization) => {
  const parameters = {
    operation: indexAccountsOperation,
    headers: { authorization },
  }

  const { data: accounts } = await operationRequest(parameters)
  const accountsMap = keyBy(accounts, "id")

  const getAccount = id => accountsMap[id]

  return { getAccount }
}

export default getOrganizationAccounts
