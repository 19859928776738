import React  from "react"

import { Disclaimer } from "@components/Text"
import { getAddressSchema } from "@components/Address"
import {
  INVESTMENT_PROFILE_FIELD_MAP,
  INVESTMENT_PROFILE_TYPE_JOINT,
  JOINT_MEMBERSHIP_INTERESTS_TITLES_MAP,
} from "@components/Domain"
import { TYPE_BOOLEAN, TYPE_RADIO, TYPE_STRING, TYPE_SSN, TYPE_NONE } from "@components/Form"

import InvestorSsnNumber from "../InvestorSsnNumber"
import computeProfileInput from "./computeProfileInput"
import computeLiabilityInputs from "./computeLiabilityInputs"
import computeResidencyInputs from "./computeResidencyInputs"
import getProfileJointInputsMap from "./getProfileJointInputsMap"

const MESSAGE_ALERT = `
  If you and your spouse file separate tax returns, please be aware the K‐1 can
  only be issued with one partner SSN. If you would like to receive separate
  K‐1s, please create separate users and select "Individual" as each of your
  title of membership interest.
`

const TOOLTIP_DO_YOUR_HAVE_CO_INVESTOR = "Adding a second signer is not required due to the language in our documents."

const LABEL_JOINT = "Joint"
const LABEL_SEPARATE = "Separate"
const LABEL_INVALID_PROFILE_TYPE = 'Invalid investment profile type selected'


const getProfileJointSchema = (organization, updateSchema, getInvestorSsnNumber, isEdit, profile = {}, investor = {}, existingProfiles = []) => {
  const { email: organizationEmail } = organization

  const inputPropsMap = getProfileJointInputsMap()

  const { givenName, additionalName, familyName, address = {} } = investor
  const { jointAddress, hasSsn, shouldCoInvestorSign, isUsPerson, isUsCitizen, profileName } = profile

  const isNotUsPerson = !isUsCitizen && isUsPerson === false

  const labels = {
    isUsPerson: "Is this person considered a US person for federal tax purposes, as defined by the IRS?",
    hasSsn: isNotUsPerson ? "Do you have a US TIN" : "Do you have an SSN?",
  }

  const residencyInputs = computeResidencyInputs(profile, updateSchema, labels, organizationEmail)
  const liabilityInputs = computeLiabilityInputs(updateSchema, organization)
  const profileSelectInput = computeProfileInput(INVESTMENT_PROFILE_TYPE_JOINT, updateSchema, existingProfiles, () => {})
  const hasProfiles = existingProfiles.filter(({ disabled }) => !disabled).length > 0

  const shouldHideAnotherFields = hasProfiles && !profileName && !isEdit

  let ssnType = TYPE_SSN

  if (!hasSsn) {
    ssnType = "none"
  }

  const coInvestorInputs = shouldCoInvestorSign
    ? [
      {
        name: "jointEmail",
        label: "Co-Investor Email Address",
        required: true,
        placeholder: "example@host.com"
      },
    ]
    : [
      {
        name: "jointEmail",
        type: TYPE_NONE
      }
    ]

  if (shouldHideAnotherFields) {
    return [
      ...profileSelectInput,
      inputPropsMap.hasPurchasedExemptSecurities,
      inputPropsMap.isLiabilityAccepted,
      inputPropsMap.membershipInterestsTitle,
      inputPropsMap.hasJointTaxReturn,
      inputPropsMap.shouldCoInvestorSign,
      inputPropsMap.jointGivenName,
      inputPropsMap.jointAdditionalName,
      inputPropsMap.jointFamilyName,
      inputPropsMap.jointEmail,
      inputPropsMap.jointAddress,
      inputPropsMap.isUsCitizen,
      inputPropsMap.isUsPerson,
      inputPropsMap.hasOtherUsResidenceState,
      inputPropsMap.residenceState,
      inputPropsMap.hasSsn,
      inputPropsMap.ssnNumber,
    ]
  }

  return [
    ...profileSelectInput,
    ...liabilityInputs,
    {
      name: "membershipInterestsTitle",
      type: TYPE_RADIO,
      label: "Title of membership interests",
      required: true,
      inputProps: {
        vertical: "true",
      },
      options: Object.keys(JOINT_MEMBERSHIP_INTERESTS_TITLES_MAP)
        .map(value => ({ value, label: JOINT_MEMBERSHIP_INTERESTS_TITLES_MAP[value] }))
    },
    {
      name: "hasJointTaxReturn",
      type: TYPE_BOOLEAN,
      label: "Do you and your spouse (tenant) file a joint or separate individual tax return?",
      required: true,
      itemProps: {
        rules: [
          {
            message: LABEL_INVALID_PROFILE_TYPE,
            type: "enum",
            enum: [true]
          }
        ]
      },
      inputProps: {
        vertical: true,
        options: [
          {
            label: LABEL_JOINT,
            value: "true"
          },
          {
            label: LABEL_SEPARATE,
            value: "false"
          }
        ],
        onChange: (event, form) => {
          const { profileType } = form.getFieldsValue()
          const path = [ INVESTMENT_PROFILE_FIELD_MAP[profileType], "hasJointTaxReturn" ]

          form.setFields([
            {
              name: path,
              errors: [],
            },
          ])

          const { value: isSeparateTaxReturn } = event.target

          if (!isSeparateTaxReturn) {
            return updateSchema(MESSAGE_ALERT, "hasJointTaxReturn")
          }

          return updateSchema()
        }
      }
    },
    {
      name: "_investorGivenName",
      type: TYPE_STRING,
      label: "Investor Legal First Name",
      initialValue: givenName,
      required: false,
      inputProps: {
        disabled: true
      }
    },
    {
      name: "_investorAdditionalName",
      type: TYPE_STRING,
      label: "Investor Legal Middle Name",
      initialValue: additionalName,
      required: false,
      inputProps: {
        disabled: true
      }
    },
    {
      name: "_investorFamilyName",
      type: TYPE_STRING,
      label: "Investor Legal Last Name",
      initialValue: familyName,
      required: false,
      inputProps: {
        disabled: true
      }
    },
    {
      name: "shouldCoInvestorSign",
      type: TYPE_BOOLEAN,
      label: "Would you like to add a second signer to your subscription documents?",
      required: true,
      itemProps: {
        extra: <Disclaimer text={TOOLTIP_DO_YOUR_HAVE_CO_INVESTOR} />
      },
      inputProps: {
        hasInvertedItems: true,
        onChange: () => updateSchema()
      }
    },
    {
      name: "jointGivenName",
      label: "Co-Investor Legal First Name",
      required: true,
      placeholder: ""
    },
    {
      name: "jointAdditionalName",
      label: "Co-Investor Legal Middle Name",
      placeholder: ""
    },
    {
      name: "jointFamilyName",
      label: "Co-Investor Legal Last Name",
      required: true,
      placeholder: ""
    },
    ...coInvestorInputs,
    {
      name: "jointAddress",
      label: "",
      required: true,
      schema: getAddressSchema(updateSchema, ["profileJoint", "jointAddress"], jointAddress, address),
    },
    {
      name: "isUsCitizen",
      label: "Are you a US citizen?",
      required: true,
      inputProps: {
        onChange: () => updateSchema()
      }
    },
    ...residencyInputs,
    {
      name: "ssnNumber",
      required: true,
      component: InvestorSsnNumber,
      type: ssnType,
      componentProps: {
        required: true,
        itemProps: {
          initialValue: getInvestorSsnNumber(),
          label: isNotUsPerson ? "Investor Individual Taxpayer Identification Number" : "Investor Social Security Number",
        }
      }
    }
  ]
}

export default getProfileJointSchema
