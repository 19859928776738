import { useOutletContext } from "react-router-dom"

import { useAppContext } from "@components/AppContext"


const useMainSponsorId = () => {
  const { sponsors = [] } = useOutletContext()
  const { getOrganizationName } = useAppContext()

  const organizationName = getOrganizationName()

  const mainSponsorId = sponsors
    .find(({ name }) => name === organizationName)?.id

  return mainSponsorId
}

export default useMainSponsorId
