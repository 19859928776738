import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import Table from "@components/Table"
import { TRANSACTION_STATUS } from "@components/Domain"
import { indexTransactionsOperation as indexOperation } from "@api/services/transactions"

import getFilterItems from "./helpers/getFilterItems"
import getTableSummary from "./helpers/getTableSummary"
import getExportColumns from "./helpers/getExportColumns"
import useColumnsConfig from "./helpers/useColumnsConfig"
import getHeaderActions from "./helpers/getHeaderActions"
import useSelectableActions from "./helpers/useSelectableActions"
import TransactionsTableHeader from "../TransactionsTable/TransactionsTableHeader"
import useIndexOperationParameters from "../TransactionsTable/helpers/useIndexOperationParameters"


const DistributionsTable = ({
  table,
  projectId,
  openCreateModal,
  openImportModal,
  openInvestmentDrawer,
  isInvalidBankAccount,
  openTransactionDrawer,
  hasDistributionsAccount
}) => {
  const { project, accounts = [] } = useOutletContext()

  const [ selectableActions, onSelectionChange ] = useSelectableActions(table)
  const { updateDateRange, operationParameters } = useIndexOperationParameters(projectId)

  const columnsConfig = useColumnsConfig(openInvestmentDrawer, openTransactionDrawer)

  const headerRenderTitle = title => (
    <TransactionsTableHeader
      title={title}
      table={table}
      updateDateRange={updateDateRange}
    />
  )

  const size = "small"
  const summary = getTableSummary()
  const bordered = true
  const className = "last-column-action"

  const filterItemsCallback = useCallback((transactions) =>
    getFilterItems(transactions), [])

  const headerActions = getHeaderActions({
    openCreateModal,
    openImportModal,
    selectableActions,
    hasDistributionsAccount,
    isInvalidBankAccount
  })
  const exportColumns = getExportColumns(project, accounts)
  const exportFileName = "distributions"
  const defaultSortColumn = "date"

  const isReady = !!operationParameters && !!accounts

  const onItemsUpdated = () => {
    if (!selectableActions) {
     return
    }

    table.current.setSelectedRowKeys([])
    onSelectionChange([])
  }

  const tableProps = {
    size,
    table,
    summary,
    isReady,
    bordered,
    className,
    headerActions,
    columnsConfig,
    exportColumns,
    exportFileName,
    indexOperation,
    onItemsUpdated,
    headerRenderTitle,
    defaultSortColumn,
    filterItemsCallback,
    operationParameters
  }

  if (selectableActions) {
    const getSelectableCheckboxProps = distribution => {
      const { status, isApproved, isExternal } = distribution
      const isRejected = status === TRANSACTION_STATUS.REJECTED
      const isBackfill = isExternal && isApproved

      if (isBackfill) {
        return {}
      }

      const isDisabled = isApproved || isRejected

      if (isDisabled) {
        return { disabled: true }
      }
    }

    tableProps.isSelectable = true
    tableProps.onSelectionChange = onSelectionChange
    tableProps.getSelectableCheckboxProps = getSelectableCheckboxProps
  }

  return (
    <Table {...tableProps} />
  )
}

DistributionsTable.propTypes = {
  table: PropTypes.shape().isRequired,
  projectId: PropTypes.string.isRequired,
  openCreateModal: PropTypes.func.isRequired,
  openImportModal: PropTypes.func.isRequired,
  openInvestmentDrawer: PropTypes.func.isRequired,
  isInvalidBankAccount: PropTypes.bool.isRequired,
  openTransactionDrawer: PropTypes.func.isRequired,
  hasDistributionsAccount: PropTypes.bool.isRequired,
}

export default DistributionsTable
