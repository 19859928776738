import React from "react"

import { CountryInput } from "../../Input"


const renderCountryInput = (itemProps, inputProps, request, form) => {
  return (
    <CountryInput
      form={form}
      request={request}
      itemProps={itemProps}
      inputProps={inputProps}
    />
  )
}

export default renderCountryInput
