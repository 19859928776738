import React from "react"
import { Typography } from "antd"
import { useOutletContext } from "react-router-dom"

const LABEL_SENT = "Sent"
const LABEL_EMAIL = "Email"
const LABEL_OPENED = "Opened"
const LABEL_CLICKED = "Clicked"
const LABEL_INVESTOR = "Investor"
const LABEL_DELIVERED = "Delivered"


const useColumnsConfig = () => {
  const { computeAccountFullname } = useOutletContext()

  return [
    {
      key: "investorAccountId",
      title: LABEL_INVESTOR,
      compute: ({ investorAccountId }) => computeAccountFullname(investorAccountId),
      render: value => <Typography.Text strong>{value}</Typography.Text>,
      isSearchable: true,
    },
    {
      key: "recipientEmail",
      title: LABEL_EMAIL,
      isSearchable: true,
    },
    {
      key: "sentAt",
      title: LABEL_SENT,
    },
    {
      key: "deliveredAt",
      title: LABEL_DELIVERED,
    },
    {
      key: "openedAt",
      title: LABEL_OPENED,
    },
    {
      key: "clickedAt",
      title: LABEL_CLICKED,
    },
  ]
}

export default useColumnsConfig
