import Auth from "./Auth"
import Storage from "./Storage"
import parseJwt from "./parseJwt"
import authenticate from "./authenticate"
import getAuthenticatedRequest from "./getAuthenticatedRequest"

const signOut = () => Auth.signOut()

const getIdentityEmail = (isBackstage) => Storage.getIdentityEmail(isBackstage)

const getAuthentication = (isBackstage) => Storage.getAuthentication(isBackstage)

const refreshAuthentication = (isBackstage) => Auth.refreshAuthentication(authenticate, isBackstage)

export {
  signOut,
  Storage,
  parseJwt,
  authenticate,
  getIdentityEmail,
  getAuthentication,
  refreshAuthentication,
  getAuthenticatedRequest,
}
