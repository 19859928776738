import { getOffset } from "@components/Date"
import { DEFAULT_TIMEZONE } from "@components/Form/Input/DateTimeInput/SelectTimezone"
import { getTimezoneByOffset } from "@components/Form/Input/DateTimeInput"


const getCampaignTimeZone = scheduledAt => {
  let timeZone = DEFAULT_TIMEZONE
  if (scheduledAt) {
    const offset = getOffset(scheduledAt)
    timeZone = getTimezoneByOffset(offset)
  }

  return timeZone
}

export default getCampaignTimeZone
