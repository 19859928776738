import React from "react"
import { W0 } from "@components"
import { ACCOUNT_TAG } from "@components/Store/BackstageStore/useOptionsStore"
import { TYPE_NONE, TYPE_CHECKBOX, TYPE_TAGS } from "@components/Form"
import keyBy from "lodash.keyby"

import BrokerOptionLabel from "../BrokerOptionLabel"

const LABEL_TAGS = "Tags"
const LABEL_LIAISON = "Liaison"
const LABEL_REFERRED = "Referred From"
const LABEL_LAST_NAME = "Last Name"
const LABEL_SUBSCRIBE = "Subscribe investor to receive campaigns"
const LABEL_FIRST_NAME = "First Name"
const LABEL_LIAISON_PLACEHOLDER = "Select liaison"


const getInputs = (brokers, referralSourceOptions, emailAlert) => {
  const brokerOptions =  brokers
    .map(({ fullName: label, id: value }) => ({ label, value }))

  const brokersMap = keyBy(brokers, 'id')

  const brokerOptionRender = ({ value }) => {
    const broker = brokersMap[value]
    const { fullName, sponsorId } = broker

    return <BrokerOptionLabel
      name={fullName}
      sponsorId={sponsorId}
    />
  }

  const inputs = [
    {
      name: "investor",
      label: "",
      schema: [
        {
          name: "givenName",
          label: LABEL_FIRST_NAME,
          required: true,
        },
        {
          name: "familyName",
          label: LABEL_LAST_NAME,
          required: true,
        },
        {
          name: "email",
          required: true,
          itemProps: {
            extra: emailAlert,
            style: { marginBottom: W0 },
          }
        },
        {
          name: "alternativeEmail",
          type: TYPE_NONE,
        },
        {
          name: "alternativeName",
          type: TYPE_NONE,
        },
        {
          name: "additionalName",
          type: TYPE_NONE,
        },
        {
          name: "address",
          type: TYPE_NONE,
        },
        {
          name: "mobileNumber",
          type: TYPE_NONE,
        },
        {
          name: "landNumber",
          type: TYPE_NONE,
        },
        {
          name: "dateOfBirth",
          type: TYPE_NONE,
        },
        {
          name: "isUsCitizen",
          type: TYPE_NONE,
        },
        {
          name: "hasAccreditation",
          type: TYPE_NONE,
        },
        {
          name: "accreditationExpiresAt",
          type: TYPE_NONE,
        },
        {
          name: "timeZoneCode",
          type: TYPE_NONE,
        },
        {
          name: "campaignEmails",
          type: TYPE_NONE,
        },
      ]
    },
    {
      name: "isSubscribedForCampaigns",
      type: TYPE_CHECKBOX,
      label: "",
      inputProps: {
        label: LABEL_SUBSCRIBE
      }
    },
    {
      name: "brokerUserId",
      label: LABEL_LIAISON,
      options: brokerOptions,
      required: true,
      placeholder: LABEL_LIAISON_PLACEHOLDER,
      inputProps: {
        optionRender: brokerOptionRender
      },
    },
    {
      name: "tagIds",
      type: TYPE_TAGS,
      label: LABEL_TAGS,
      inputProps: {
        group: ACCOUNT_TAG
      },
    },
    {
      name: "referralSource",
      label: LABEL_REFERRED,
      options: referralSourceOptions,
      hasAutoComplete: true
    },
  ]

  return inputs
}

export default getInputs
