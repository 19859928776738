import { Table, Typography } from "antd"
import React from "react"

import { Value } from "@components/Text"
import { getFormattedDecimalValue } from "@components/Amount"

const { Text } = Typography
const { Row, Cell } = Table.Summary


const getCollectionSummary = transactions => {
  const total = transactions
    .map(({amount}) => amount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  return (
    <Row>
      <Cell
        align="right"
        index={1}
        colSpan={4}
      >
        <Text strong>Total:</Text>
      </Cell>

      <Cell
        index={1}
        align="right"
      >
        <Value value={total} />
      </Cell>
    </Row>
  )
}

export default getCollectionSummary
