import React from "react"
import PropTypes from "prop-types"
import { Table, Typography } from "antd"

import { ListMyCustomerBeneficialOwnersOutputShape } from "@api/services/transactions"

import getColumns from "./helpers/getColumns"

const LABEL_NO_OWNERS = "No owners have been added."


const BeneficialOwnersList = ({
  isLoading,
  onSuccess,
  customerId,
  openUpdateModal,
  openUploadModal,
  beneficialOwners,
}) => {
  const columns = getColumns(customerId, openUpdateModal, openUploadModal, onSuccess)

  const tableProps = {
    size: "small",
    rowKey: "id",
    loading: isLoading,
    pagination: false,
    dataSource: beneficialOwners,
    columns,
  }

  const hasBeneficialOwners = beneficialOwners.length > 0

  if (!hasBeneficialOwners) {
    return <Typography.Paragraph>{LABEL_NO_OWNERS}</Typography.Paragraph>
  }

  return (
    <Table {...tableProps} />
  )
}

BeneficialOwnersList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  openUpdateModal: PropTypes.func.isRequired,
  openUploadModal: PropTypes.func.isRequired,
  beneficialOwners: PropTypes.arrayOf(ListMyCustomerBeneficialOwnersOutputShape).isRequired,
}

export default BeneficialOwnersList
