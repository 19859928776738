import {
  TYPE_NONE,
  TYPE_HIDDEN,
  TYPE_DATE,
  TYPE_RADIO,
  TYPE_DATETIME
} from "@components/Form"
import { W1 } from "@components"

const LABEL_TYPE = "Type"
const LABEL_SEND_NO = "Do not send"
const LABEL_SEND_YES = "Schedule for"
const LABEL_DATETIME = "Date & Time"
const LABEL_NOTIFICATIONS = "Notifications"
const LABEL_TYPE_PLACEHOLDER = "Select document type"


const getCreateInputs = (projectId, typeOptions, isSent) => {
  const commonInputProps = {
    size: "medium",
  }

  const shouldSchedule = !isSent

  const scheduledAtProps = !shouldSchedule
    ? { type: TYPE_NONE }
    : {
        type: TYPE_DATETIME,
        label: LABEL_DATETIME,
        required: true,
        itemProps: {
          style: { marginBottom: W1 },
        },
        inputProps: {
          minuteStep: 15,
          showNow: true,
          ...commonInputProps,
        }
      }

  return [
    {
      name: "isPublicFile",
      type: TYPE_NONE,
    },
    {
      name: "investorFiles",
      type: TYPE_NONE,
    },
    {
      name: "projectId",
      type: TYPE_HIDDEN,
      initialValue: projectId,
    },
    {
      name: "documentType",
      label: LABEL_TYPE,
      options: typeOptions,
      required: true,
      placeholder: LABEL_TYPE_PLACEHOLDER,
      hasAutoComplete: true,
      inputProps: {
        style: { width: 492 }
      }
    },
    {
      name: "name",
      inputProps: {
        style: { width: 492 }
      }
    },
    {
      name: "date",
      type: TYPE_DATE,
      inputProps: {
        style: { width: 124 }
      }
    },
    {
      name: "isSent",
      type: TYPE_RADIO,
      label: LABEL_NOTIFICATIONS,
      initialValue: true,
      options: [
        {
          value: true,
          label: LABEL_SEND_NO,
        },
        {
          value: false,
          label: LABEL_SEND_YES,
        },
      ],
      itemProps: {
        style: { marginBottom: W1 },
      },
    },
    {
      name: "scheduledAt",
      ...scheduledAtProps,
    },
  ]
}

export default getCreateInputs
