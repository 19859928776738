import React from "react"
import { useTheme, View, Text } from "@aws-amplify/ui-react"

import { Markdown } from "@components/Text"
import { getConfig } from "@components/Config"

const PLATFORM_EMAIL = getConfig('PLATFORM_EMAIL')

const LABEL_TEXT = `If you're having trouble signing up, please contact us at
[${PLATFORM_EMAIL}](mailto:${PLATFORM_EMAIL}). We'll assist you promptly.`


const ConfirmSignUpFooter = () => {
  const { tokens } = useTheme()

  const color = tokens.colors.neutral[60]

  return (
    <View>
      <Text
        color={color}
        fontSize="small"
      >
        <Markdown text={LABEL_TEXT} />
      </Text>
    </View>
  )
}

export default ConfirmSignUpFooter
