import { COUNTRY_US } from "@components/Address"

import getTargetAttributeOptions from "./getTargetAttributeOptions"
import getTargetAttributeOnChange from "./getTargetAttributeOnChange"


const getBankNameProps = (bankAccounts = []) => {
  const options = getTargetAttributeOptions(bankAccounts, "bankName")

  const inputProps = {
    onChange: getTargetAttributeOnChange(bankAccounts, "bankName", {
      routingNumber: null,
      alternativeRoutingNumber: null,
      bankName: null,
      bankAddress: {
        streetAddress: null,
        addressLocality: null,
        addressRegion: null,
        postalCode: null,
        addressCountry: COUNTRY_US
      },
      bankCodeName: null,
    })
  }

  return {
    options,
    inputProps,
    hasAutoComplete: true,
  }
}

export default getBankNameProps
