import React from "react"
import PropTypes from "prop-types"
import { Carousel, Image } from "antd"

import { AssetShape } from "@components/Form"
import { getResizedImageUrl } from "@components/Storage"

import Arrow from "./Arrow"

import "./Slider.css"

export const DEFAULT_SIZE = [ 1040, 480 ]


const Slider = ({
  size = DEFAULT_SIZE,
  photos,
}) => {
  const hasPhotos = photos.length > 0

  if (!hasPhotos) {
    return null
  }

  const hasMultiplePhotos = photos.length > 1

  const [ width, height ] = size

  return (
    <Carousel
      arrows={hasMultiplePhotos}
      nextArrow={<Arrow />}
      prevArrow={<Arrow />}
    >
      {
        photos.map(({ name, path }, index) => (
          <Image
            key={index}
            alt={name}
            src={getResizedImageUrl(path, width * 2, height * 2)}
            width="100%"
            preview={false}
          />
        ))
      }
    </Carousel>
  )
}

Slider.propTypes = {
  size: PropTypes.arrayOf(PropTypes.number),
  photos: PropTypes.arrayOf(AssetShape).isRequired,
}

export default Slider
