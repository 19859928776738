import React, { useMemo } from "react"
import { Table, Typography } from "antd"

import { Value } from "@components/Text"
import { TRANSACTION_TYPES } from "@components/Domain"

const { Summary: { Row, Cell } } = Table


const useTableSummary = (investments, transactions) => {
  const investmentIds = useMemo(() =>
    investments.map(({ id }) => id)
  , [ investments ])

  const investmentsAmount = useMemo(() =>
    investments
      .map(investment => investment.investments)
      .reduce((allInvestments, partial) => [...allInvestments, ...partial], [])
      .reduce((amount, investment) => amount + investment.amount, 0)
  , [ investments ])

  const distributionsAmount = useMemo(() =>
    transactions
      .filter(({ type }) => type !== TRANSACTION_TYPES.FUNDING)
      .filter(({ investmentId }) => investmentIds.includes(investmentId))
      .reduce((amount, transaction) => amount + transaction.amount, 0)
  , [
    transactions,
    investmentIds
  ])

  const render = () => {
    return (
      <Row>
        <Cell
          align="right"
          index={1}
          colSpan={3}
        >
          <Typography.Text strong>Total:</Typography.Text>
        </Cell>

        <Cell
          index={1}
          align="right"
        >
          <Value value={investmentsAmount} />
        </Cell>
        <Cell
          index={1}
          align="right"
        >
          <Value value={distributionsAmount} />
        </Cell>
      </Row>
    )
  }

  return render
}

export default useTableSummary
