import { TYPE_PASSWORD } from "@components/Form/FormItem"

const ERROR_PASSWORD_MISMATCH = "Should match new password"
const ERROR_PASSWORD_MIN_LENGTH = "Minimum length 8 symbols"
const ERROR_PASSWORD_SYMBOL_LOWERCASE = "Should contain at least 1 lowercase letter"
const ERROR_PASSWORD_SYMBOL_UPPERCASE = "Should contain at least 1 uppercase letter"
const ERROR_PASSWORD_SYMBOL_NUMBER = "Should contain at least 1 number"
const ERROR_PASSWORD_SYMBOL_SPECIAL = "Should contain at least 1 special character"

const LABEL_PASSWORD = "Current Password"
const LABEL_PASSWORD_NEW = "New Password"
const LABEL_PASSWORD_CONFIRM = "Confirm New Password"


const getInputs = form => {
  return [
    {
      name: 'currentPassword',
      type: TYPE_PASSWORD,
      label: LABEL_PASSWORD,
    },
    {
      name: 'newPassword',
      label: LABEL_PASSWORD_NEW,
      type: TYPE_PASSWORD,
      itemProps: {
        rules: [
          {
            message: ERROR_PASSWORD_MIN_LENGTH,
            validator: async (_, value) => {
              if (!value) {
                return
              }

              const isValid = value && value.length >= 8

              if (!isValid) {
                throw new Error(ERROR_PASSWORD_MIN_LENGTH)
              }
            }
          },
          {
            message: ERROR_PASSWORD_SYMBOL_LOWERCASE,
            pattern: /[a-z]/,
          },
          {
            message: ERROR_PASSWORD_SYMBOL_UPPERCASE,
            pattern: /[A-Z]/,
          },
          {
            message: ERROR_PASSWORD_SYMBOL_NUMBER,
            pattern: /[\d]/,
          },
          {
            message: ERROR_PASSWORD_SYMBOL_SPECIAL,
            pattern: /[~^*!@#$%_&+\-()/{}\\"'.?[\]|,:;<>=`]/,
          },
        ]
      }
    },
    {
      name: 'confirmNewPassword',
      label: LABEL_PASSWORD_CONFIRM,
      type: TYPE_PASSWORD,
      itemProps: {
        rules: [
          {
            message: ERROR_PASSWORD_MISMATCH,
            validator: async () => {
              const { newPassword, confirmNewPassword } = form.getFieldsValue()

              if (!newPassword) {
                return
              }

              const isValid = newPassword === confirmNewPassword

              if (!isValid) {
                throw new Error(ERROR_PASSWORD_MISMATCH)
              }
            }
          }
        ]
      }
    }
  ]
}

export default getInputs
