import keyBy from "lodash.keyby"

import { getInvestmentAmountForProjectTier } from "@components/Export"


const getGlobalInvestmentClassColumns = (projects, getTierTitle) => {
  const projectsMap = keyBy(projects, "globalProjectId")

  const isClassMatched = (organizationId, columnTitle, tierId, investment) => {
    const { projectId } = investment
    const globalProjectId = `${organizationId}-${projectId}`

    const project = projectsMap[globalProjectId]
    const { tiers } = project

    const tier = tiers.find(({ name }) => name === tierId)

    if (!tier) {
      return false
    }

    const title = getTierTitle(tier).toLowerCase()
    const isMatched = columnTitle === title

    return isMatched
  }

  const columnsMap = {}

  for (const project of projects) {
    const { tiers: projectTiers } = project

    for (const tier of projectTiers) {
      const { name: tierId } = tier

      const columnTitle = getTierTitle(tier).toLowerCase()

      const value = (investment, organizationId) => {
        const isMatched = isClassMatched(organizationId, columnTitle, tierId, investment)

        if (!isMatched) {
          return "-"
        }

        return getInvestmentAmountForProjectTier(tierId, investment)
      }

      const title = getTierTitle(tier)
      const column = columnsMap[columnTitle]

      if (!column) {
        columnsMap[columnTitle] = {
          title,
          value,
        }
      }
    }
  }

  return Object.values(columnsMap)
}

export default getGlobalInvestmentClassColumns
