import computeDistributions from "./computeDistributions"


const getFilterItems = (transactions) => {
  const distributions = computeDistributions(transactions)

  return distributions
}

export default getFilterItems
