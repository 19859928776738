import getOperation from "../../helpers/getOperation"

import CreateMultipleTransactionsCreatedNotificationOutputShape from "./CreateMultipleTransactionsCreatedNotificationOutputShape"
import IndexNotificationsOutputShape from "./IndexNotificationsOutputShape"
import ListTemplatesOutputShape from "./ListTemplatesOutputShape"
import ResetTemplateOutputShape from "./ResetTemplateOutputShape"
import SendNotificationOutputShape from "./SendNotificationOutputShape"
import ToggleTemplateOutputShape from "./ToggleTemplateOutputShape"
import UpdateTemplateOutputShape from "./UpdateTemplateOutputShape"

export {
  CreateMultipleTransactionsCreatedNotificationOutputShape,
  IndexNotificationsOutputShape,
  ListTemplatesOutputShape,
  ResetTemplateOutputShape,
  SendNotificationOutputShape,
  ToggleTemplateOutputShape,
  UpdateTemplateOutputShape
}

export const createMultipleTransactionsCreatedNotificationOperation = getOperation("notifications/CreateMultipleTransactionsCreatedNotification")
export const indexNotificationsOperation = getOperation("notifications/IndexNotifications")
export const listTemplatesOperation = getOperation("notifications/ListTemplates")
export const resetTemplateOperation = getOperation("notifications/ResetTemplate")
export const sendContactUsGuestNotificationOperation = getOperation("notifications/SendContactUsGuestNotification")
export const sendContactUsInvestorNotificationOperation = getOperation("notifications/SendContactUsInvestorNotification")
export const sendDistributionPendingApprovalNotificationOperation = getOperation("notifications/SendDistributionPendingApprovalNotification")
export const sendInvestmentPendingRefundNotificationOperation = getOperation("notifications/SendInvestmentPendingRefundNotification")
export const sendNotificationOperation = getOperation("notifications/SendNotification")
export const toggleTemplateOperation = getOperation("notifications/ToggleTemplate")
export const updateTemplateOperation = getOperation("notifications/UpdateTemplate")
