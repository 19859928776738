import React, { useState } from "react"
import { Progress, Space } from "antd"
import PropTypes from "prop-types"

import { MODAL_LARGE_HEIGHT, MODAL_LARGE_WIDTH, W3, W0, W1 } from "@components"

import Modal from "../Modal"
import ImportErrorShape from "./shapes/ImportErrorShape"
import ImportModalErrors from "./ImportModalErrors"

const LABEL_ERRORS = "Errors"
const LABEL_PREFIX = "Row "


const ImportModal = ({
 errors = undefined,
 onClose = undefined,
 progress = undefined,
 errorTitle = LABEL_ERRORS,
 errorPrefix = LABEL_PREFIX,
 modal,
 title,
 header,
 children,
}) => {
  const [ isOpen, setIsOpen ] = useState(false)

  const handleClose = () => {
    if (onClose) {
      onClose()
    }

    setIsOpen(false)
  }

  modal.current.open = () =>
    setIsOpen(true)

  modal.current.close = () =>
    handleClose()

  const modalProps = {
    width: MODAL_LARGE_WIDTH,
    onClose: handleClose,
    destroyOnClose: true,
    title,
    isOpen,
  }

  return (
    <Modal {...modalProps}>
      <div style={{ minHeight: MODAL_LARGE_HEIGHT }}>
        <Space
          style={{ width: "100%" }}
          direction="vertical"
        >
          <Space
            align="top"
            className="import-modal-form"
            direction="horizontal"
            style={{
              display: "flex",
              minHeight: W3 - W0,
              justifyContent: "space-between"
            }}
          >
            {header}
          </Space>

          {
            !!progress && (
              <Progress percent={progress} />
            )
          }

          <div style={{ marginTop: W0 }}>
            {
              errors && (
                <div style={{ marginBottom: W1 }}>
                  <ImportModalErrors
                    title={errorTitle}
                    errors={errors}
                    prefix={errorPrefix}
                  />
                </div>
              )
            }

            {children}
          </div>
        </Space>
      </div>
    </Modal>
  )
}

ImportModal.propTypes = {
  title: PropTypes.string.isRequired,
  modal: PropTypes.shape().isRequired,
  header: PropTypes.shape().isRequired,
  errors: PropTypes.arrayOf(ImportErrorShape),
  onClose: PropTypes.func,
  progress: PropTypes.number,
  children: PropTypes.node.isRequired,
  errorTitle: PropTypes.string,
  errorPrefix: PropTypes.string,
}

export default ImportModal
