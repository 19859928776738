import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, Typography } from "antd"
import { useOutletContext, useNavigate } from "react-router-dom"

import { Value } from "@components/Text"
import { computeInvestmentAmount } from "@components/Investment"

import { ProfileModal } from "../../InvestorProjectInvestmentsPage"

const LABEL_VIEW_PROJECT = "View Project"


const Investment = ({ investmentId }) => {
  const modal = useRef({})

  const navigate = useNavigate()
  const { investmentsMap } = useOutletContext()

  const investment = investmentsMap[investmentId]

  const { projectName, projectId, isFunded } = investment
  const totalAmount = computeInvestmentAmount(investment)

  const onClick = () =>
    modal.current.open()

  const goToProject = () => isFunded
    ? navigate(`/projects/${projectId}/investments`)
    : navigate(`/projects/${projectId}/about`)

  return (
    <Row>
      <Col flex="auto">
        <Typography.Text strong>
          <Typography.Link onClick={onClick}>
            {projectName}
          </Typography.Link>
        </Typography.Text>
      </Col>

      <Col>
        <Value value={totalAmount} />
      </Col>

      <ProfileModal
        modal={modal}
        footer={
          <Button
            ghost
            size="large"
            type="primary"
            shape="round"
            style={{ width: "100%" }}
            onClick={goToProject}
          >
            {LABEL_VIEW_PROJECT}
          </Button>
        }
        investment={investment}
      />
    </Row>
  )
}

Investment.propTypes = {
  investmentId: PropTypes.string.isRequired,
}

export default Investment
