import React from "react"
import { Link, useOutletContext } from "react-router-dom"
import { Row, Col, Button, Typography } from "antd"

import { useAppContext } from "@components/AppContext"
import { PROJECT_TYPES, PROJECT_STATES } from "@components/Domain"
import { W0, W1, W2, isMobile } from "@components"
import { getProjectInvestmentsUrl, computeProjectInvestmentsFlags } from "@components/Project"

const LABEL_CTA = "Your Investment Commitment is Incomplete"
const LABEL_CONTINUE = "Continue Process"


const ContinueButton = () =>  {
  const { isSponsorInvestor } = useAppContext()
  const { project, getProjectInvestments } = useOutletContext()

  const { id, type, state } = project

  const isClosed = state === PROJECT_STATES.CLOSED

  const investments = getProjectInvestments(id)

  const { isFunded, isSigned } = computeProjectInvestmentsFlags(investments)

  let shouldRender  = !isFunded || !isSigned

  const isNonFund = type !== PROJECT_TYPES.FUND
  const isClosedNonFund = isNonFund && isClosed

  if (isClosedNonFund) {
    shouldRender = false

    if (isSponsorInvestor) {
      shouldRender = !isFunded || !isSigned
    }
  }

  if (!shouldRender) {
    return null
  }

  const projectInvestmentUrl = getProjectInvestmentsUrl(id, investments)

  const containerStyle = {
    padding: `${W1 * 1.5}px 0`,
    background: "#ECF5F9",
    borderRadius: isMobile ? 0 : 4,
    marginBottom: W2,
  }

  return (
    <Row style={containerStyle} gutter={[ 0, W0 ]}>
      <Col span={24} align="center">
        <Typography.Title level={4} style={{ margin: 0 }}>
          {LABEL_CTA}
        </Typography.Title>
      </Col>

      <Col span={24} align="center">
        <Link to={projectInvestmentUrl}>
          <Button
            size="large"
            type="primary"
            shape="round"
          >
            {LABEL_CONTINUE}
          </Button>
        </Link>
      </Col>
    </Row>
  )
}

export default ContinueButton
