import React, { useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { useOutletContext, useParams } from "react-router-dom"

import { SIDEBAR_WIDTH } from "@components"
import { useAppContext } from "@components/AppContext"
import { indexOperation } from "@components/Project/stores/useProjectInvestmentsStore"
import { InvestmentShape } from "@components/Investment/shapes"
import Table, { CollapseTable } from "@components/Table"

import useColumnsConfig from "./helpers/useColumnsConfig"
import getHeaderActions from "./helpers/getHeaderActions"
import useExportColumns from "./helpers/useExportColumns"
import InvestmentsImportModal from "../InvestmentsImportModal"

const DEFAULT_SORT_COLUMN = "investor"


const InvestmentsTable = ({
  items = undefined,
  isOpenByDefault = false,
  table,
  title,
  openInvestmentDrawer,
  openCreateInvestmentModal,
}) => {
  const investmentsImportModal = useRef({})
  const { id: projectId } = useParams()

  const { isBroker } = useAppContext()
  const { indexInvestments: indexItems } = useOutletContext()

  const openInvestmentsImportModal = () =>
    investmentsImportModal.current.open()

  const size = "small"
  const style = { maxWidth: `calc(98vw - ${SIDEBAR_WIDTH}px)` }
  const scroll = { x: 800 }
  const bordered = true
  const className = "last-column-action"
  const columnsConfig = useColumnsConfig(isBroker, openInvestmentDrawer)
  const exportColumns = useExportColumns()
  const headerActions = getHeaderActions(openCreateInvestmentModal, openInvestmentsImportModal)
  const exportFileName = "investments"
  const hasHeaderFilters = false
  const defaultSortColumn = DEFAULT_SORT_COLUMN
  const operationParameters = useMemo(() => ({ projectId }), [projectId])

  const tableProps = {
    size,
    items,
    table,
    style,
    scroll,
    bordered,
    className,
    indexItems,
    columnsConfig,
    headerActions,
    exportColumns,
    exportFileName,
    indexOperation,
    hasHeaderFilters,
    defaultSortColumn,
    operationParameters,
  }

  return (
    <CollapseTable
      title={title}
      items={items}
      isOpenByDefault={isOpenByDefault}
    >
      <Table {...tableProps} />

      <InvestmentsImportModal
        modal={investmentsImportModal}
        indexInvestments={indexItems}
      />
    </CollapseTable>
  )
}

InvestmentsTable.propTypes = {
  table: PropTypes.shape().isRequired,
  items: PropTypes.arrayOf(InvestmentShape),
  title: PropTypes.string.isRequired,
  isOpenByDefault: PropTypes.bool,
  openInvestmentDrawer: PropTypes.func.isRequired,
  openCreateInvestmentModal: PropTypes.func.isRequired,
}

export default InvestmentsTable
