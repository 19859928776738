import React, { useState } from "react"
import { useParams, useNavigate, useOutletContext } from "react-router-dom"
import { Button, Space } from "antd"

import { W1 } from "@components"
import { wait } from "@api"
import { useForm } from "@components/Form"
import { AccountCard } from "@modules/investor/InvestorAccountPage"
import { useAppContext } from "@components/AppContext"
import clearInvalidFields from "@modules/investor/investment/InvestmentProfilesPage/helpers/clearInvalidFields"
import { updateMyAccountOperation } from "@api/services/investments"

import UpdateMyAccountForm from "./UpdateMyAccountForm"

const LABEL_ADD = "Continue"
const LABEL_CONTINUE = "Continue to My Investments"


const InvestmentAccountPage = () => {
  const form = useForm()

  const [ isLoading, setIsLoading ] = useState(false)

  const navigate = useNavigate()
  const { projectId } = useParams()
  const { request, identity, updateIdentity } = useAppContext()

  const {
    currentForm,
    indexInvestments,
    getProjectInvestments,
  } = useOutletContext()

  const { isImmutable } = identity

  const projectInvestments = getProjectInvestments(projectId)
  const hasInvestments = projectInvestments.length > 0

  const onSaveAndExit = async () => {
    if (isImmutable) {
      return
    }

    let mutation = form.getFieldsValue()

    const hasFormError = await form.validateFields()
      .then(() => false)
      .catch(() => true)

    if (hasFormError) {
      mutation = clearInvalidFields(form, mutation)
    }

    return request(updateMyAccountOperation, { mutation })
      .then(({ data }) => updateIdentity(data))
      .then(() => indexInvestments())
  }

  currentForm.current.submit = onSaveAndExit

  const onSuccess = () => {
    setIsLoading(true)
    window.scrollTo({ top: 0 })

    if (!hasInvestments) {
      navigate(`/investments/${projectId}/create`)
      return
    }

    return indexInvestments()
      .then(() => wait(250))
      .then(() => {
        navigate(`/investments/${projectId}/update`)
      })
  }

  const submitButtonProps = {
    style: {
      width: "100%",
      height: 50,
      fontWeight: 600
    }
  }

  const submitLabel = hasInvestments
    ? LABEL_CONTINUE
    : LABEL_ADD

  if (!isImmutable) {
    return (
      <UpdateMyAccountForm
        form={form}
        onSuccess={onSuccess}
        submitLabel={submitLabel}
        submitButtonProps={submitButtonProps}
      />
    )
  }

  return (
    <Space
      size={W1}
      direction="vertical"
    >
      <AccountCard />

      <Button
        type="primary"
        size="large"
        shape="round"
        style={submitButtonProps.style}
        onClick={onSuccess}
        loading={isLoading}
      >
        {submitLabel}
      </Button>
    </Space>
  )
}

export default InvestmentAccountPage
