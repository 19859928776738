import PropTypes from "prop-types"

import { useAppContext } from "@components/AppContext"
import { PROJECT_TYPES } from "@components/Domain"
import {
  listProjectsOperation as indexOperation,
  updatePropertyOperation,
  disconnectFundProjectPropertyOperation,
  ListProjectsOutputShape
} from "@api/services/backstage"

import useStore from "../helpers/useStore"

const LABEL_CLASS = "Class"


export const getTierName = ({ name, title }) =>
  (title || `${LABEL_CLASS} ${name}`).trim()


const useProjectsStore = () => {
  const { request } = useAppContext()

  const shouldAutoLoad = false

  const {
    items: projects,
    getItem: getProject,
    addItem: addProject,
    updateItem: updateProject,
    removeItem: deleteProject,
    indexItems: indexProjects,
  } = useStore(indexOperation, request, shouldAutoLoad)

  const removeProjectProperty = async (projectId, propertyId) => {
    const project = getProject(projectId)

    if (!project) { return }

    const isFund = project.type === PROJECT_TYPES.FUND

    if (isFund) {
      const mutation = { propertyId }
      await request(disconnectFundProjectPropertyOperation, { id: project.id, mutation })

      return
    }

    const mutation = { projectId: "" }
    await request(updatePropertyOperation, { id: propertyId, mutation })
  }

  const getProjectName = id =>
    getProject(id)?.name || id

  const store = {
    projects,
    getProject,
    addProject,
    getTierName,
    updateProject,
    deleteProject,
    indexProjects,
    getProjectName,
    removeProjectProperty,
  }

  return store
}

const projectsStoreProperties = {
  projects: PropTypes.arrayOf(ListProjectsOutputShape),
  getProject: PropTypes.func.isRequired,
  addProject: PropTypes.func.isRequired,
  getTierName: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
  indexProjects: PropTypes.func.isRequired,
  getProjectName: PropTypes.func.isRequired,
  removeProjectProperty: PropTypes.func.isRequired,
}

export default useProjectsStore

export {
  projectsStoreProperties
}
