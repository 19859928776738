import React from "react"
import { Space, Typography, Card } from "antd"

import { W0 } from "@components"
import { validateDob } from "@components/Date"
import { getAddressSchema } from "@components/Address"
import { TYPE_NONE, TYPE_HIDDEN, TYPE_TIMEZONE } from "@components/Form"
import { updateMyAccountOperation as updateOperation } from "@api/services/investments"

const { Text, Link } = Typography

const ACCREDITATION_REFERENCE_URL = "https://bit.ly/2NwpyTF"
const LABEL_ACCREDITATION_NOTE = `
  A person is considered an accredited investor if (1) net worth exceeds $1 million, either individually or
  jointly with spouse or (2) have an annual income exceeding $200,000, or $300,000 for joint income, for the last two years.
  To find out more about accredited investors click here:
`
const LABEL_TIMEZONE = "Time Zone"


const getUpdateMyAccountSchema = ({
  address,
  updateSchema,
  shouldIncludeTimeZone,
  shouldIncludeAccreditationNote,
}) => {
  const timeZoneCodeProps = !shouldIncludeTimeZone
    ? { type: TYPE_NONE }
    : {
        type: TYPE_TIMEZONE,
        label: LABEL_TIMEZONE,
        required: false,
      }

  const inputs = [
    {
      name: "investor",
      label: "",
      schema: [
        {
          name: "givenName",
          label: "Legal First Name",
          required: true
        },
        {
          name: "alternativeName",
          label: "Preferred First Name"
        },
        {
          name: "additionalName",
          label: "Legal Middle Name"
        },
        {
          name: "familyName",
          label: "Legal Last Name",
          required: true
        },
        {
          name: "dateOfBirth",
          label: "Date of Birth",
          placeholder: "06/21/1990",
          required: true,
          itemProps: {
            rules: [validateDob()]
          }
        },
        {
          name: "mobileNumber",
          label: "Contact Phone Number",
          required: true,
        },
        {
          name: "address",
          label: "",
          schema: getAddressSchema(updateSchema, ["investor", "address"], address),
        },
        {
          name: "timeZoneCode",
          ...timeZoneCodeProps,
        },
        {
          name: "hasAccreditation",
          label: "Are you an accredited investor?",
          required: true,
          itemProps: {
            style: { marginBottom: 0 },
            extra: (
              <Space
                size={0}
                style={{ marginTop: W0, lineHeight: 1.2 }}
                direction="vertical"
              >
                <Text type="secondary" style={{ fontSize: 12 }}>
                  {LABEL_ACCREDITATION_NOTE}{' '}
                </Text>

                <Link
                  href={ACCREDITATION_REFERENCE_URL}
                  target="_blank"
                  style={{ fontSize: 12 }}
                >
                  {ACCREDITATION_REFERENCE_URL}
                </Link>
              </Space>
            )
          }
        },
        {
          name: "email",
          type: TYPE_HIDDEN,
        },
        {
          /* note: value is inherited on the backend */
          name: "accreditationExpiresAt",
          type: TYPE_NONE,
        },
        {
          /* td: remove after spec is updated */
          name: "alternativeEmail",
          label: "Alternative Email Address",
          type: TYPE_NONE,
        },
        {
          /* td: remove after spec is updated */
          name: "landNumber",
          type: TYPE_NONE,
        },
        {
          name: "campaignEmails",
          type: TYPE_NONE,
        },
      ],
      ...(
        shouldIncludeAccreditationNote ? {
          containerComponent: ({ children }) => (
            <Card>
              {children}
            </Card>
          ),
        } : {}
      )
    }
  ]

  const schema = updateOperation.getSchema(inputs)

  return schema
}

export default getUpdateMyAccountSchema
