import { useCustomer } from "@components/Dwolla"
import { INVESTMENT_PROFILE_TYPE_ENTITY } from "@components/Domain"


const useInvestmentCustomer = investment => {
  const { name: investmentName, profileType } = investment

  const isBusiness = profileType === INVESTMENT_PROFILE_TYPE_ENTITY

  const customerName = isBusiness
    ? investmentName
    : null

  const customer = useCustomer(customerName)

  return customer
}

export default useInvestmentCustomer
