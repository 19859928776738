import React, { useRef } from "react"
import { Space, Button, Typography } from "antd"
import { green, blue } from "@ant-design/colors"
import { CheckCircleOutlined, HourglassOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"
import { getConfig } from "@components/Config"
import { ORGANIZATION_DOMAIN_STATUS } from "@components/Domain"
import { BlockingModal, BeforeUnload } from "@components/Page"
import { ReadMyOrganizationOutputShape } from "@api/services/organizations"

import useConnectDomain from "./helpers/useConnectDomain"
import ConnectDomainModal from "./ConnectDomainModal"

const { Link } = Typography

const PLATFORM_DOMAIN = getConfig("PLATFORM_DOMAIN")

const LABEL_CONNECT = "Connect"
const LABEL_WAIT = "Your domain is being configured. Please keep this window open until the process is complete. Closing or refreshing this window may interrupt the setup, and you would need to restart the process by clicking the 'Connect' button again."


const OrganizationDomain = ({ organization }) => {
  const modal = useRef({})

  const { isConnecting, connectDomain } = useConnectDomain()

  const { domain, domainStatus } = organization

  const onClick = async () => {
    const domainChunks = domain.split('.')
    domainChunks.shift()
    const zone = domainChunks.join('.')

    const isHostedZone = PLATFORM_DOMAIN === zone

    if (!isHostedZone) {
      return modal.current.open()
    }

    connectDomain()
  }

  const isConnected = domainStatus === ORGANIZATION_DOMAIN_STATUS.COMPLETE

  const icon = isConnected
    ? <CheckCircleOutlined style={{ color: green.primary }} />
    : <HourglassOutlined style={{ color: blue.primary }} />

  const canUpdateOrganization = hasAccess(['org-write'])

  return (
    <div style={{ position: "relative" }}>
      {
        !isConnected && (
          <Button
            size="small"
            style={{ position: "absolute", top: -1, right: 0 }}
            onClick={onClick}
            loading={isConnecting}
          >
            {LABEL_CONNECT}
          </Button>
        )
      }

      <Space>
        <Link href={`https://${domain}/backstage/`} target="_blank">
          {domain}
        </Link>

        {icon}
      </Space>

      <ConnectDomainModal
        modal={modal}
        isEditable={canUpdateOrganization}
        organization={organization}
      />

      <BlockingModal
        text={<BeforeUnload>{LABEL_WAIT}</BeforeUnload>}
        isOpen={isConnecting}
      />
    </div>
  )
}

OrganizationDomain.propTypes = {
  organization: ReadMyOrganizationOutputShape.isRequired,
}

export default OrganizationDomain
