import React from "react"
import PropTypes from "prop-types"

import { CreateModal } from "@components/Page"
import { createOptionOperation as createOperation } from "@api/services/backstage"

import getCreateInputs from "./helpers/getCreateInputs"

const LABEL_SAVE = "Save"
const LABEL_TITLE = "Save List"
const LABEL_ENTITY = "List"


const SegmentNameModal = ({ modal, onSubmit, onSuccess }) => {
  const createInputs = getCreateInputs()
  const createSchema = createOperation.getSchema(createInputs)

  const modalProps = {
    modal,
    width: 400,
    title: LABEL_TITLE,
    entity: LABEL_ENTITY,
    schema: createSchema,
    operation: createOperation,
    submitLabel: LABEL_SAVE,
    onSubmit,
    onSuccess,
  }

  return (
    <CreateModal {...modalProps}/>
  )
}

SegmentNameModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default SegmentNameModal
