import { useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import keyBy from "lodash.keyby"
import { useAppContext } from "@components/AppContext"

import { ROLES } from "@components/Domain"
import { indexUsersOperation, IndexUsersOutputShape } from "@api/services/backstage"
import { properties as IndexUsersOutputShapeProperties } from "@api/services/backstage/IndexUsersOutputShape"

import useStore from "../helpers/useStore"

const UserWithFullnameShape = PropTypes.exact({
  ...IndexUsersOutputShapeProperties,
  fullName: PropTypes.string.isRequired,
})


const isBroker = user =>
  user.roles.includes(ROLES.BROKER)


const isBrokerOnly = user =>
  user.roles.includes(ROLES.BROKER) && user.roles.length === 1


const getBrokers = users => !users
  ? []
  : users
      .filter(user => isBroker(user))
      .map(broker => ({
        ...broker,
        fullName: `${broker.givenName} ${broker.familyName}`
      }))


const getMembers = users => !users
  ? []
  : users
      .filter(user => !isBrokerOnly(user))
      .map(member => ({
        ...member,
        fullName: `${member.givenName} ${member.familyName}`
      }))


const useUsersStore = () => {
  const { request } = useAppContext()

  const shouldAutoLoad = false

  const {
    items: users,
    addItem: addUser,
    getItem: getUser,
    indexItems: indexUsers,
    updateItem: updateUser,
  } = useStore(indexUsersOperation, request, shouldAutoLoad)

  const brokers = useMemo(() =>
    getBrokers(users)
  , [ users ])

  const members = useMemo(() =>
    getMembers(users)
  , [ users ])

  const activeUsers = useMemo(() => (users || [])
    .filter(({ isDisabled }) => isDisabled === false)
    .map(user => ({
      ...user,
      fullName: `${user.givenName} ${user.familyName}`
    }))
  , [ users ])

  const inactiveUsers = useMemo(() => (users || [])
    .filter(({ isDisabled }) => isDisabled === true)
    .map(user => ({
      ...user,
      fullName: `${user.givenName} ${user.familyName}`
    }))
  , [ users ])

  const brokersMap = useMemo(() =>
    keyBy(getBrokers(users), "id")
  , [ users ])

  const membersMap = useMemo(() =>
    keyBy(getMembers(users), "id")
  , [ users ])

  const accountants = members
    .filter(({ roles }) => roles.includes(ROLES.ACCOUNTANT))

  const activeBrokers = brokers
    .filter(({ isDisabled }) => !isDisabled)

  const activeMembers = members
    .filter(({ isDisabled }) => !isDisabled)

  const activeApprovers = activeMembers
    .filter(({ roles }) => roles.includes(ROLES.APPROVER))

  const activeAccountants = activeMembers
    .filter(({ roles }) => roles.includes(ROLES.ACCOUNTANT))

  const computeUserFullname = useCallback(id => {
    const user = getUser(id)

    if (!user) {
      return id
    }

    const { givenName, familyName } = user

    return `${givenName} ${familyName}`
  }, [ getUser ])

  const store = {
    users,
    brokers,
    members,
    addUser,
    updateUser,
    indexUsers,
    brokersMap,
    membersMap,
    accountants,
    activeUsers,
    inactiveUsers,
    activeBrokers,
    activeMembers,
    activeApprovers,
    activeAccountants,
    computeUserFullname,
  }

  return store
}

const usersStoreProperties = {
  users: PropTypes.arrayOf(IndexUsersOutputShape),
  brokers: PropTypes.arrayOf(UserWithFullnameShape).isRequired,
  members: PropTypes.arrayOf(UserWithFullnameShape).isRequired,
  addUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  indexUsers: PropTypes.func.isRequired,
  brokersMap: PropTypes.shape().isRequired,
  membersMap: PropTypes.shape().isRequired,
  accountants: PropTypes.arrayOf(UserWithFullnameShape),
  activeUsers: PropTypes.arrayOf(UserWithFullnameShape),
  inactiveUsers: PropTypes.arrayOf(UserWithFullnameShape),
  activeBrokers: PropTypes.arrayOf(UserWithFullnameShape),
  activeMembers: PropTypes.arrayOf(UserWithFullnameShape),
  activeApprovers: PropTypes.arrayOf(UserWithFullnameShape),
  activeAccountants: PropTypes.arrayOf(UserWithFullnameShape),
  computeUserFullname: PropTypes.func.isRequired,
}

export default useUsersStore

export {
  usersStoreProperties
}
