import React, { useState } from "react"
import PropTypes from "prop-types"

import { W1 } from "@components"
import { Modal } from "@components/Page"
import { Markdown } from "@components/Text"
import Form, { useForm } from "@components/Form"
import { useAppContext } from "@components/AppContext"
import {
  ListMyCustomerBeneficialOwnersOutputShape,
  certifyMyCustomerBeneficialOwnershipOperation
} from "@api/services/transactions"

import useInputs from "./helpers/useInputs"
import CustomerShape from "../../../shapes/CustomerShape"


const LABEL_TITLE = "Add Owners"
const LABEL_HEADER = `Due to regulatory requirements, we need to identify anyone who owns 25% or more of 
 **[ORGANIZATION]** and has managerial rights over the entity. This includes managing directors, individual owners, 
 etc., but does not include limited partners (investors).
 
 Beneficial owners are always natural persons. If this company only has one owner
 with a 25% or more ownership stake, and that is another company, then you must look
 through to the ownership of that company. If the proportion of any owners of that
 second company translates to 25% of the first company, they need to be listed. 
 
 For example:
 * Company B owns 100% of Company A, and Person 1 owns 25% of Company B. 
 Therefore, Person 1 is an ultimate beneficial owner of Company A.
 * Company B owns 50% of Company A, and Person 1 owns 50% of Company B. 
 This translates to Person 1 owning 25% of Company A.
`


const CertifyBeneficialOwnershipModal = ({
  beneficialOwners = undefined,
  modal,
  customer,
  onSuccess,
  isModalOpen,
  indexBeneficialOwners,
}) => {
  const form = useForm()

  const { authenticatedRequest } = useAppContext()

  const [ isOpen, setIsOpen ] = useState(isModalOpen)

  const schema = useInputs({
    form,
    customer,
    beneficialOwners,
    indexBeneficialOwners,
  })

  const onClose = () =>
    setIsOpen(false)

  modal.current.open = () =>
    setIsOpen(true)

  modal.current.close = () =>
    onClose()

  const onSubmit = async parameters => {
    const hasFormError = await form.validateFields()
      .then(() => false)
      .catch(() => true)

    if (!hasFormError) {
      return parameters
    }
  }

  const style = { marginTop: W1 }

  const formOptions = {
    item: customer,
    request: authenticatedRequest,
    operation: certifyMyCustomerBeneficialOwnershipOperation,
    hasSubmitButton: false,
    shouldOptimizeUpdateMutation: false,
    form,
    schema,
    onSubmit,
    onSuccess,
  }

  const { name } = customer

  const header = LABEL_HEADER
    .replace('[ORGANIZATION]', name)

  return (
    <Modal
      title={LABEL_TITLE}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Markdown>
        {header}
      </Markdown>

      <div style={style}>
        <Form
          {...formOptions}
        />
      </div>

    </Modal>
  )
}

CertifyBeneficialOwnershipModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  customer: CustomerShape.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  beneficialOwners: PropTypes.arrayOf(ListMyCustomerBeneficialOwnersOutputShape),
  indexBeneficialOwners: PropTypes.func.isRequired,
}

export default CertifyBeneficialOwnershipModal
