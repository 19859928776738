import React from "react"
import PropTypes from "prop-types"
import { List, Space, Typography } from "antd"
import { DownloadOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"
import { AssetShape } from "@components/Form"
import { downloadFile } from "@components/Import"

import ProjectEditableGroup from "./ProjectEditableGroup"

const LABEL_TITLE = "Documents"


const Documents = ({ documents = [], isEditable }) => {
  const hasAssets = documents.length > 0
  const fieldNames = [ 'documents' ]

  const canUpdateDocuments = hasAccess(['fund-projects-write'])
  isEditable = isEditable || canUpdateDocuments

  const size = "small"
  const itemStyle = { paddingLeft: 0, paddingRight: 0 }
  const extraProps = { isEditable, marginBottom: 0 }

  return (
    <ProjectEditableGroup
      title={LABEL_TITLE}
      fieldNames={fieldNames}
      extraProps={extraProps}
    >
      {
        hasAssets && (
          <List
            size={size}
            dataSource={documents}
            renderItem={({ url, description }) =>
              <List.Item style={itemStyle}>
                <Typography.Link strong onClick={() => downloadFile(url)}>
                  <Space>
                    <DownloadOutlined />
                    {description}
                  </Space>
                </Typography.Link>
              </List.Item>
            }
          />
        )
      }
    </ProjectEditableGroup>
  )
}

Documents.propTypes = {
  documents: PropTypes.arrayOf(AssetShape),
  isEditable: PropTypes.bool.isRequired,
}

export default Documents
