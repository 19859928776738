import React, { useState } from "react"
import PropTypes from "prop-types"
import { PlusOutlined } from "@ant-design/icons"
import { useOutletContext } from "react-router-dom"

import { createOperation as operation } from "@components/Store/BackstageStore/useOptionsStore"
import { TYPE_NONE, TYPE_STRING, TYPE_HIDDEN, EntityForm, useForm } from "@components/Form"

const LABEL_ADD = "Add"
const LABEL_ENTITY = "Tag"
const LABEL_PLACEHOLDER = `New tag...`

const INPUT_NAME_WIDTH = 264


const createSchema = (onNameChanged, placeholder = "") =>
  operation.getSchema([
    {
      name: "group",
      type: TYPE_NONE,
    },
    {
      name: "description",
      type: TYPE_NONE,
    },
    {
      name: "color",
      type: TYPE_HIDDEN,
      initialValue: "BLUE",
    },
    {
      name: "order",
      type: TYPE_HIDDEN,
      initialValue: 0,
    },
    {
      name: "name",
      type: TYPE_STRING,
      label: "",
      required: false,
      placeholder,
      itemProps: { style: { marginBottom: 0 } },
      inputProps: {
        allowClear: true,
        style: { width: INPUT_NAME_WIDTH },
        onChange: onNameChanged
      }
    }
  ])


const OptionsCollectionFooter = ({ group }) => {
  const form = useForm()

  const { addOption } = useOutletContext()
  const [ isDisabled, setIsDisabled ] = useState(true)

  const onSubmit = parameters => {
    parameters.mutation.group = group
    return parameters
  }

  const onSuccess = createdOption => {
    addOption(createdOption)
    form.resetFields()

    setIsDisabled(true)
  }

  const onNameChanged = () => {
    const name = form.getFieldValue('name')
    const isSubmittable = name.length > 0

    setIsDisabled(!isSubmittable)
  }

  const schema = createSchema(onNameChanged, LABEL_PLACEHOLDER)

  const submitItemProps = {
    style: {
      position: "absolute",
      top: 0,
      left: INPUT_NAME_WIDTH + 8,
    }
  }

  const submitButtonProps = {
    size: "medium",
    type: "default",
    shape: "default",
    icon: <PlusOutlined />,
    style: {},
    disabled: isDisabled,
  }

  const formProps = {
    entity: LABEL_ENTITY,
    submitLabel: LABEL_ADD,
    form,
    schema,
    onSubmit,
    operation,
    onSuccess,
    submitItemProps,
    submitButtonProps,
  }

  return (
    <EntityForm {...formProps} />
  )
}

OptionsCollectionFooter.propTypes = {
  group: PropTypes.string.isRequired,
}

export default OptionsCollectionFooter
