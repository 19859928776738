import React, {useState} from "react"
import PropTypes from "prop-types"
import { Progress } from "antd"

import { Modal } from "@components/Page"


const ProgressModal = ({ title, modal, percent, start, stop }) => {
  const [ isOpen, setIsOpen ] = useState(false)

  modal.current.close = () =>
    setIsOpen(false)

  modal.current.open = (...parameters) => {
    setIsOpen(true)
    start(...parameters)
  }

  const onClose = () => {
    setIsOpen(false)
    stop()
  }

  const modalProps = {
    destroyOnClose: true,
    title,
    isOpen,
    onClose,
  }

  return (
    <Modal {...modalProps}>
      <Progress percent={percent} />
    </Modal>
  )
}

ProgressModal.propTypes = {
  stop: PropTypes.func.isRequired,
  start: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  modal: PropTypes.shape().isRequired,
  percent: PropTypes.number.isRequired,
}

export default ProgressModal
