import { UNAUTHORIZED_ERROR } from "@api"

import Storage from "./Storage"
import authenticate from "./authenticate"
import { operationRequest } from "../request"


const authenticatedRequest = (onUnexpectedError, operation, parameters, expectedErrors, isBackstage) =>
  operationRequest({
    headers: { authentication: Storage.getAuthentication(isBackstage) },
    operation,
    parameters,
    expectedErrors,
    onUnexpectedError,
  })


const getAuthenticatedRequest = (isBackstage, onUnexpectedError) => {
  return (operation, parameters, expectedErrors = []) =>
    authenticatedRequest(onUnexpectedError, operation, parameters, [ ...expectedErrors, UNAUTHORIZED_ERROR ], isBackstage)
      .catch(error => {
        const isAuthorizationExpired = error.code === UNAUTHORIZED_ERROR

        if (!isAuthorizationExpired) {
          throw error
        }

        return authenticate(() => {}, isBackstage, true)
          .then(() => authenticatedRequest(onUnexpectedError, operation, parameters, expectedErrors, isBackstage))
      })
}

export default getAuthenticatedRequest
