import React from "react"
import { Button, Form, DatePicker } from "antd"
import PropTypes from "prop-types"

import { W1 } from "@components"

const LABEL_GENERATE = "Generate"
const LABEL_RANGE_REQUIRED = "Select date range"


const OrganizationsReportForm = ({ onSubmit }) => {
  const [ form ] = Form.useForm()

  const formProps = {
    style: { marginTop: W1 },
    layout: "inline",
    onFinish: onSubmit,
    form,
  }

  const buttonProps = {
    type: "primary",
    htmlType: "submit",
  }

  const rangePickerProps = {
    picker: "month",
    format: "MM/YYYY",
  }

  const dateRangeItemProps = {
    name: "dateRange",
    rules: [
      {
        required: true,
        message: LABEL_RANGE_REQUIRED,
      },
    ],
  }

  return (
    <Form {...formProps}>
      <Form.Item {...dateRangeItemProps}>
        <DatePicker.RangePicker {...rangePickerProps} />
      </Form.Item>

      <Form.Item>
        <Button {...buttonProps}>
          {LABEL_GENERATE}
        </Button>
      </Form.Item>
    </Form>
  )
}

OrganizationsReportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default OrganizationsReportForm
