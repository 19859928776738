import { useMemo } from "react"

import { useStore } from "@components/Store"
import { useAppContext } from "@components/AppContext"
import {
  INVESTMENT_FUNDED,
  PROJECT_CLOSED_NON_FUND,
  INVESTMENT_SIGNED,
  INVESTMENT_REJECTED_NOT_INTERESTED,
  INVESTMENT_REJECTED_DEADLINE_PASSED,
  INVESTMENT_REJECTED_NO_ACCREDITATION,
  PROJECT_FILE_CREATED,
  INVESTOR_FILE_CREATED,
} from "@api/services/notifications/shapes/TemplateKeyEnum"
import {
  listTemplatesOperation as indexOperation,
  resetTemplateOperation as resetOperation,
  toggleTemplateOperation as toggleOperation,
  updateTemplateOperation as updateOperation,
} from "@api/services/notifications"


const useTemplatesStore = (projectId = undefined) => {
  const { request } = useAppContext()

  const parameters = useMemo(() => projectId ? ({ projectId }) : {}, [ projectId ])
  const shouldAutoload = false

  const {
    items: templates = [],
    indexItems: indexTemplates,
    setItems,
  } = useStore(indexOperation, request, shouldAutoload, parameters)

  const getTemplates = category =>
    templates.filter(template => template.category === category)

  const getTemplateByKey = key =>
    (templates || []).find(item => item.key === key)

  const updatedTemplateByKey = (updatedTemplate) => {
    setItems((prevItems = []) =>
      prevItems.map(template => ({
        ...(template.key === updatedTemplate.key ? updatedTemplate : template)
      }))
    )
  }

  const resetTemplate = id =>
    request(resetOperation, { id })
      .then(({ data }) => updatedTemplateByKey(data))

  const toggleTemplate = (id, isEnabled) =>
    request(toggleOperation, { id, ...parameters, mutation: { isEnabled } })
      .then(({ data }) => updatedTemplateByKey(data))

  const isTemplateEnabled = key => {
    const template = getTemplateByKey(key)
    return template.isEnabled
  }

  const isInvestmentFundedTemplateEnabled = () =>
    isTemplateEnabled(INVESTMENT_FUNDED)

  const isProjectClosedNonFundTemplateEnabled = () =>
    isTemplateEnabled(PROJECT_CLOSED_NON_FUND)

  const isInvestmentSignedTemplateEnabled = () =>
    isTemplateEnabled(INVESTMENT_SIGNED)

  const isInvestmentRejectedNotInterestedTemplateEnabled = () =>
    isTemplateEnabled(INVESTMENT_REJECTED_NOT_INTERESTED)

  const isInvestmentRejectedDeadlinePassedTemplateEnabled = () =>
    isTemplateEnabled(INVESTMENT_REJECTED_DEADLINE_PASSED)

  const isInvestmentRejectedNoAccreditationTemplateEnabled = () =>
    isTemplateEnabled(INVESTMENT_REJECTED_NO_ACCREDITATION)

  const isProjectFileCreatedTemplateEnabled = () =>
    isTemplateEnabled(PROJECT_FILE_CREATED)

  const isInvestorFileCreatedTemplateEnabled = () =>
    isTemplateEnabled(INVESTOR_FILE_CREATED)

  return {
    getTemplates,
    resetTemplate,
    toggleTemplate,
    indexTemplates,
    getTemplateByKey,
    updatedTemplateByKey,
    isInvestmentFundedTemplateEnabled,
    isInvestmentSignedTemplateEnabled,
    isProjectFileCreatedTemplateEnabled,
    isInvestorFileCreatedTemplateEnabled,
    isProjectClosedNonFundTemplateEnabled,
    isInvestmentRejectedNotInterestedTemplateEnabled,
    isInvestmentRejectedDeadlinePassedTemplateEnabled,
    isInvestmentRejectedNoAccreditationTemplateEnabled,
  }
}

export default useTemplatesStore

export {
  indexOperation,
  resetOperation,
  toggleOperation,
  updateOperation,
}
