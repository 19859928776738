import PropTypes from "prop-types"

import { useAppContext } from "@components/AppContext"
import {
  indexMyTransactionsOperation as indexOperation,
  IndexMyTransactionsOutputShape
} from "@api/services/transactions"

import useStore from "../helpers/useStore"


const useTransactionsStore = () => {
  const { request } = useAppContext()

  const shouldAutoLoad = false

  const {
    items: transactions,
    indexItems: indexTransactions
  } = useStore(indexOperation, request, shouldAutoLoad)

  const getInvestmentTransactions = (investmentId) => {
    return transactions
      .filter(t => t.investmentId === investmentId)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  }

  return {
    transactions,
    indexTransactions,
    getInvestmentTransactions,
  }
}

const transactionsStoreProperties = {
  transactions: PropTypes.arrayOf(IndexMyTransactionsOutputShape),
  indexTransactions: PropTypes.func.isRequired,
  getInvestmentTransactions: PropTypes.func.isRequired,
}

export default useTransactionsStore

export {
  indexOperation,
  transactionsStoreProperties
}
