import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import { InvestmentShape } from "@components/Investment/shapes"
import { getCurrencyValue } from "@components/Amount"
import { UnderstandConfirmModal } from "@components/Page"

const LABEL_TITLE = "Send Funds"
const LABEL_CONTENT = `You have selected to send in your investment funds
through a linked bank account. Once you click "Send Funds", a confirmation
window will pop up to confirm the amount you would like to invest.

Click the blue "Send [AMOUNT]" to initiate the transfer.

Your investment amount will be electronically transferred through ACH from your
bank account. This means that the funds are pulled from your account within 1-2
business days and are received by your Sponsor within an additional 3-5 business
days.

The total time to transfer the funds should take 4-7 business days in
total (not including holidays and weekends).

If you send funds today, your funds will most likely arrive by [DATE].`


const SendFundsConfirmModal = ({ modal, investment }) => {
  const {
    totalAmount,
    receivedAmount,
    isPartiallyFunded,
  } = investment

  const amount = isPartiallyFunded
    ? totalAmount - receivedAmount
    : totalAmount

  const currencyAmount = getCurrencyValue(amount)

  const content = LABEL_CONTENT
    .replace('[AMOUNT]', currencyAmount)
    .replace('[DATE]', dayjs().add(9, 'day').format('MM/DD/YYYY'))

  return (
    <UnderstandConfirmModal
      modal={modal}
      content={content}
      title={LABEL_TITLE}
    />
  )
}

SendFundsConfirmModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  investment: InvestmentShape.isRequired,
}

export default SendFundsConfirmModal

