import React, { useState } from "react"
import { useOutletContext } from "react-router-dom"
import { Typography, Dropdown, Space, Spin } from "antd"

import { hasAccess } from "@components/Authorization"
import { DownOutlined } from "@ant-design/icons"
import { useAppContext } from "@components/AppContext"
import { transferControllerCustomersOperation } from "@api/services/transactions"
import { setMyOrganizationControllerOperation } from "@api/services/organizations"

const LABEL_SELECT = "Select"

const ERROR_EXPECTED = 'UnprocessibleConditionError'


const OrganizationControllerDropdown = () => {
  const [ isLoading, setIsLoading ] = useState(false)

  const {
    request,
    identity,
    showErrorMessage,
    authorizeOrganizationIdentity,
  } = useAppContext()

  const {
    accountants,
    readOrganization,
    computeUserFullname,
    getOrganizationControllerId,
  } = useOutletContext()

  const { id: myId } = identity
  const controllerId = getOrganizationControllerId()

  const transferController = async (controllerUserId) => {
    setIsLoading(true)

    if (controllerId) {
      const mutation = { controllerUserId }
      await request(transferControllerCustomersOperation, { mutation }, [ERROR_EXPECTED])
        .catch(({ originalError: { message } }) => showErrorMessage(message))

    } else {
      const mutation = { controllerId: controllerUserId }
      await request(setMyOrganizationControllerOperation, { mutation })

    }

    const isMyControllerUserId = controllerUserId === myId

    const wasMyControllerUserId = controllerId === myId

    const shouldRefreshIdentity =
      isMyControllerUserId ||
      wasMyControllerUserId

    if (shouldRefreshIdentity) {
      await authorizeOrganizationIdentity()

    }

    await readOrganization()

    setIsLoading(false)
  }

  const controllerName = controllerId
    ? computeUserFullname(controllerId)
    : null

  const canChangeController = hasAccess(['controllers-write'])

  if (!canChangeController) {
    return controllerName
  }

  const items = accountants
    .filter(({ isDisabled }) => !isDisabled)
    .filter(({ referenceUserGroupId }) => !referenceUserGroupId)
    .filter(({ id }) => computeUserFullname(id) !== controllerName)
    .map(({ id }) => (
      {
        key: id,
        label: computeUserFullname(id),
        onClick: ({ key: controllerUserId }) => transferController(controllerUserId)
      }
    ))

  const hasAccountants = accountants.length > 0

  return (
    <Spin spinning={isLoading}>
      <Dropdown
        menu={{ items }}
        trigger={[ "click" ]}
        disabled={!hasAccountants}
      >
        <Typography.Link>
          <Space>
            {controllerName || LABEL_SELECT}

            <DownOutlined />
          </Space>
        </Typography.Link>
      </Dropdown>
    </Spin>
  )
}

export default OrganizationControllerDropdown
