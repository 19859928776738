import { HOST } from "@components"

import { jsonRequest } from "../request"

const AUTHORIZE_INVESTOR_URL = `${HOST}/api/investments/Authorize`


const authorizeInvestor = async (authentication, organizationId, commitmentId) => {
  const query = {}

  if (commitmentId) {
    query.commitmentId = commitmentId
  }

  const { data } = await jsonRequest(AUTHORIZE_INVESTOR_URL, query, { authentication })
  const { authorizationJwt, ...investor } = data

  return [ authorizationJwt, investor ]
}

export default authorizeInvestor
