import { useMemo } from "react"

import { useStore } from "@components/Store"
import { useAppContext } from "@components/AppContext"
import { indexInvestorTransactionsOperation as indexOperation } from "@api/services/transactions"


const useTransactionsStore = (investorAccountId) => {
  const { request } = useAppContext()

  const shouldAutoLoad = false

  const operationParameters = useMemo(() =>
    ({ investorAccountId })
  , [ investorAccountId ])

  const {
    items: transactions,
    indexItems: indexTransactions
  } = useStore(indexOperation, request, shouldAutoLoad, operationParameters)

  return {
    transactions,
    indexTransactions,
  }
}

export default useTransactionsStore
export { indexOperation }
