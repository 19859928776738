import last from "lodash.last"
import unset from "lodash.unset"


const clearInvalidFields = (form, mutation) => {
  const { investments = [] } = mutation
  const fieldsWithErrors = form.getFieldsError()
  const invalidFields = fieldsWithErrors.filter(({errors}) => errors.length > 0)

  for(const field of invalidFields) {
    const lastFieldName = last(field.name)
    const isVirtualField = lastFieldName.startsWith("_")

    if (isVirtualField) {
      const originFieldName = field.name.join(".").replace("_", "")
      unset(mutation, originFieldName)
    }

    unset(mutation, field.name.join("."))
  }

  const validInvestments = investments.filter(({amount}) => amount > 0)
  mutation.investments = validInvestments

  return mutation
}

export default clearInvalidFields
