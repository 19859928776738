import {
  PENDING_KEY,
  COMPLETE_KEY,
  INCOMPLETE_KEY,
  OVERFUNDED_KEY,
  ACH_PENDING_KEY,
  CONNECT_SOURCE_KEY,
  PARTIALLY_FUNDED_KEY,
  CUSTOMER_SUSPENDED_KEY,
  INVALID_INVESTOR_BANK_ACCOUNT_KEY,
  INSUFFICIENT_FUNDS_INVESTOR_BANK_ACCOUNT_KEY,
} from "./Constants"

import isCustomerSuspended from "./isCustomerSuspended"
import useRecentFundingTransaction from "./useRecentFundingTransaction"


const useAchLabelKey = (investment, customer) => {
  const {
    hasPendingFundingTransaction,
    isInvalidInvestorBankAccount,
    isInsufficientFundsInvestorBankAccount
  } = useRecentFundingTransaction(investment.id)

  const isInvestmentCustomerSuspended = isCustomerSuspended(customer)

  const {
    isFunded,
    hasSentFunds,
    isOverfunded,
    senderSourceId,
    isPartiallyFunded
  } = investment

  const hasNoSenderSource = !senderSourceId

  const isAchPending = hasSentFunds && hasPendingFundingTransaction

  const isWirePending = hasSentFunds

  if (isFunded) {
    return [ COMPLETE_KEY ]
  }

  if (isOverfunded) {
    return [ OVERFUNDED_KEY ]
  }

  if (isPartiallyFunded) {
    return [ PARTIALLY_FUNDED_KEY, false, true ]
  }

  if (isInsufficientFundsInvestorBankAccount) {
    return [ INSUFFICIENT_FUNDS_INVESTOR_BANK_ACCOUNT_KEY, true ]
  }

  if (isInvalidInvestorBankAccount) {
    return [ INVALID_INVESTOR_BANK_ACCOUNT_KEY, true ]
  }

  if (isAchPending) {
    return [ ACH_PENDING_KEY ]
  }

  if (isWirePending) {
    return [ PENDING_KEY, false, true ]
  }

  if (isInvestmentCustomerSuspended) {
    return [ CUSTOMER_SUSPENDED_KEY, false, true ]
  }

  if (hasNoSenderSource) {
    return [ CONNECT_SOURCE_KEY, true ]
  }

  return [ INCOMPLETE_KEY, true ]
}

export default useAchLabelKey
