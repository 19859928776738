import ImgixClient from "@imgix/js-core"

import { getConfig } from "@components/Config"

const FIT_CROP = "crop"
const IMGIX_DOMAIN = getConfig("IMGIX_DOMAIN")
const S3_BUCKET_URL = getConfig("S3_BUCKET_URL")


const getResizedImageUrl = (url, width, height, extraOptions = {}) => {
  const path = url.replace(S3_BUCKET_URL, "")
  const client = new ImgixClient({ domain: IMGIX_DOMAIN })

  const options = {
    fit: FIT_CROP,
    ...extraOptions,
  }

  if (width){
    options.w = width
  }

  if (height) {
    options.h = height
  }

  return client.buildURL(path, options)
}

export default getResizedImageUrl
