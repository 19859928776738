import React from "react"

import { Disclaimer, Markdown } from "@components/Text"
import { INVESTMENT_PROFILE_FIELD_MAP } from "@components/Domain"

const LABEL_YES_REQUIRED = 'Required to be "Yes"'

const LABEL_PURCHASED_EXEMPT_SECURITIES = `
  Have you ever purchased securities which are exempt from federal and
  state registration (private placements or limited offerings)?
`

const TOOLTIP_PURCHASED_EXEMPT_SECURITIES = `
  If you have ever purchased an investment that is not publicly traded or have invested
  with us before (or in a similar real estate syndication) you may select, yes.
  Otherwise, you may select no.
`

const computeLiabilityInputs = (updateSchema, organization) => {
  const { email: organizationEmail } = organization

  const messageAlert = `In order to participate in this investment opportunity
you must confirm that you make your own investment decisions. If you have any
questions, please email [${organizationEmail}](mailto:${organizationEmail}).`

  return [
    {
      name: "isLiabilityAccepted",
      required: true,
      label: `
        I make my own investment decisions with respect to the investments in
        marketable securities, limited partnerships, limited liability
        companies or other private investments.
      `,
      itemProps: {
        rules: [
          {
            message: LABEL_YES_REQUIRED,
            type: "enum",
            enum: [true]
          }
        ]
      },
      inputProps: {
        onChange: (event, form) => {
          const { profileType } = form.getFieldsValue()
          const path = [ INVESTMENT_PROFILE_FIELD_MAP[profileType], "isLiabilityAccepted" ]

          form.setFields([
            {
              name: path,
              errors: [],
            },
          ])

          const { value: isAccepted } = event.target

          if (isAccepted) {
            return updateSchema()
          }

          updateSchema(<Markdown text={messageAlert} />, "isLiabilityAccepted")
        }
      }
    },
    {
      name: "hasPurchasedExemptSecurities",
      required: true,
      label: LABEL_PURCHASED_EXEMPT_SECURITIES,
      itemProps: {
        extra: <Disclaimer text={TOOLTIP_PURCHASED_EXEMPT_SECURITIES} />
      }
    },
  ]
}

export default computeLiabilityInputs
