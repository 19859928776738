import capitalize from "lodash.capitalize"

export const A = "A"
export const B = "B"
export const C = "C"
export const D = "D"
export const E = "E"
export const F = "F"
export const G = "G"
export const H = "H"
export const I = "I"
export const J = "J"

const TierNameEnum = {
  [A]: A,
  [B]: B,
  [C]: C,
  [D]: D,
  [E]: E,
  [F]: F,
  [G]: G,
  [H]: H,
  [I]: I,
  [J]: J
}

const TierNameEnumKeys = Object.keys(TierNameEnum)

const TierNameEnumOptions = TierNameEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  TierNameEnumKeys,
  TierNameEnumOptions
}

export default TierNameEnum
