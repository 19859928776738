import React from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { Menu, Space, Typography } from "antd"

import { Value } from "@components/Text"
import { W1, W2, isMobile } from "@components"
import { ReadPublicPublishedProjectOutputShape } from "@api/services/backstage"

import StatusTag from "./StatusTag"

const PATH = "/projects"

const { Title, Text } = Typography

const LABEL_ABOUT = "About"
const LABEL_DOCUMENTS = "Documents"
const LABEL_INVESTMENTS = "Investments"
const LABEL_DISTRIBUTIONS = "Distributions"


const Header = ({ project }) => {
  const navigate = useNavigate()

  const { getProjectInvestedAmount } = useOutletContext()

  const { id, name } = project

  const projectPath = `${PATH}/${id}`

  const items = [
    {
      key: `${projectPath}/about`,
      label: LABEL_ABOUT,
    },
    {
      key: `${projectPath}/distributions`,
      label: LABEL_DISTRIBUTIONS,
    },
    {
      key: `${projectPath}/documents`,
      label: LABEL_DOCUMENTS,
    },
    {
      key: `${projectPath}/investments`,
      label: LABEL_INVESTMENTS,
    }
  ]

  const { pathname } = window.location

  const selectedKeys = items
    .map(({ key }) => key)
    .filter(key => pathname.startsWith(key))

  const investedAmount = getProjectInvestedAmount(id)

  const onClick = ({ key }) => navigate(key)

  const containerStyle = {
    padding: isMobile ? 0 : "0 8px",
    marginTop: W2,
    marginBottom: isMobile ? 0 : W1 * 1.5
  }

  const titleStyle = isMobile
    ? { padding: "0 16px" }
    : {}

  const menuStyle = isMobile
    ? {}
    : { marginLeft: -16 }

  return (
    <div style={containerStyle}>
      <Space
        size={0}
        style={titleStyle}
        direction="vertical"
      >
        <Space>
          <Title level={3} style={{ margin: 0 }}>
            {name}
          </Title>

          <StatusTag project={project} />
        </Space>

        <Text type="secondary">
          <Value value={investedAmount} /> Invested
        </Text>
      </Space>

      <Menu
        mode="horizontal"
        items={items}
        theme="light"
        style={menuStyle}
        onClick={onClick}
        selectedKeys={selectedKeys}
      />
    </div>
  )
}

Header.propTypes = {
  project: ReadPublicPublishedProjectOutputShape.isRequired,
}

export default Header
