import React from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

const { Text } = Typography


const Small = ({
  style = {},
  children,
}) => {
  return (
    <Text style={{ fontSize: 12, ...style }} type="secondary">
      {children}
    </Text>
  )
}

Small.propTypes = {
  style: PropTypes.shape(),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired
}

export default Small
