import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { isBackstage } from "@components"

import { signOut } from "./authenticate"

const path = isBackstage
  ? "/backstage"
  : "/"


const LogoutRedirect = () => {
  const navigate = useNavigate()

  useEffect(() => {
    signOut()
      .then(() => navigate(path))
  }, [ navigate ])

  return null
}

export default LogoutRedirect
