import { getFormattedDecimalValue, getCurrencyValue } from "@components/Amount"


const getInvestmentAmountForProjectTier = (tierId, investment) => {
  const { investments } = investment

  const amountValue = investments
    .filter(({ projectTierId }) => projectTierId === tierId)
    .map(({ amount }) => amount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  return getCurrencyValue(amountValue)
}

export default getInvestmentAmountForProjectTier
