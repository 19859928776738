import { useEffect, useState, useMemo, useCallback } from "react"
import { useOutletContext } from "react-router-dom"

import { useAppContext } from "@components/AppContext"
import { SPONSOR_TYPE_OPTIONS } from "@components/Domain"
import { DOCUMENT_EXISTS_ERROR } from "@api"
import { TYPE_RADIO, TYPE_STRING, useForm } from "@components/Form"
import { createSponsorOperation, createBrokerOperation as createOperation } from "@api/services/backstage"

import getCreateInputs from "./getCreateInputs"

const LABEL_SPONSOR_EXISTS = "Sponsor already exists"


const useCreateBrokerSchema = () => {
  const form = useForm()

  const [ schema, setSchema ] = useState([])

  const { request } = useAppContext()
  const { sponsors, addSponsor } = useOutletContext()

  const sponsorNameInput = useMemo(() => ({
    name: "_sponsorName",
    type: TYPE_STRING,
    label: "Sponsor Name",
    required: true,
    placeholder: "Name for a new sponsor",
  }), [])

  const sponsorTypeInput = useMemo(() => ({
    name: "_sponsorType",
    type: TYPE_RADIO,
    label: "Sponsor Type",
    options: SPONSOR_TYPE_OPTIONS,
    required: true,
  }), [])

  const updateSchema = useCallback(() => {
    const chosenSponsorId = form.getFieldValue([ "sponsorId" ])

    const isChosenNewSponsor = chosenSponsorId === "new"

    if (isChosenNewSponsor) {
      const newInputs = getCreateInputs(updateSchema, sponsors, [
        sponsorNameInput,
        sponsorTypeInput
      ])

      setSchema(createOperation.getSchema(newInputs))

      return
    }

    const newInputs = getCreateInputs(updateSchema, sponsors)
    setSchema(createOperation.getSchema(newInputs))
  }, [
    form,
    sponsors,
    sponsorNameInput,
    sponsorTypeInput,
  ])

  useEffect(() => {
    const hasSponsors = !!sponsors
    const extraInputs = hasSponsors ? [] : [ sponsorNameInput ]

    const createInputs = getCreateInputs(updateSchema, sponsors, extraInputs)
    setSchema(createOperation.getSchema(createInputs))
  }, [
    sponsors,
    updateSchema,
    sponsorNameInput
  ])

  const onSubmit = async parameters => {
    const { mutation } = parameters
    let { sponsorId } = mutation

    const {
      _sponsorName: name,
      _sponsorType: type
    } = mutation

    const isChosenNewSponsor = sponsorId === "new"

    if (isChosenNewSponsor) {
      const sponsorMutation = { name, type }
      const expectedErrors = [ DOCUMENT_EXISTS_ERROR ]
      const sponsorParameters = { mutation: sponsorMutation }

      await request(createSponsorOperation, sponsorParameters, expectedErrors)
        .then(({ data }) => {
          sponsorId = data.id
          return data
        })
        .then(newSponsor => addSponsor(newSponsor))
        .catch(error => {
          const isSponsorNameTaken = error.code === DOCUMENT_EXISTS_ERROR

          if (isSponsorNameTaken) {
            error.originalError.message = LABEL_SPONSOR_EXISTS
          }

          throw error
        })

      parameters.mutation.sponsorId = sponsorId
    }

    return parameters
  }

  return [ form, schema, onSubmit ]
}

export default useCreateBrokerSchema
export { createOperation }
