import PropTypes from "prop-types"

import DnsRecordShape from "./DnsRecordShape"

const properties = {
  dnsRecords: PropTypes.arrayOf(DnsRecordShape),
  domain: PropTypes.string.isRequired,
  isVerified: PropTypes.bool.isRequired
}

const SesIdentityShape = PropTypes.exact(properties)

export default SesIdentityShape
export { properties }
