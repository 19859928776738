import getBuildInvestorTransactions from "./investorTransactions/getBuildInvestorTransactions"
import getParseInvestorTransactions from "./investorTransactions/getParseInvestorTransactions"
import getValidateInvestorTransactions from "./investorTransactions/getValidateInvestorTransactions"


const parseDistributions = (investments, setErrors, projectId) => {
  const parseInvestorRow = getParseInvestorTransactions(setErrors)

  const validateInvestorRow = getValidateInvestorTransactions(investments, setErrors)

  const buildInvestorRow = getBuildInvestorTransactions(investments, projectId)

  const parseRow = (row, rowIndex) =>
    parseInvestorRow(row, rowIndex)

  const validateRow = (row, rowIndex) =>
    validateInvestorRow(row, rowIndex)

  const buildRow = (row, rowIndex) =>
    buildInvestorRow(row, rowIndex)

  return { parseRow, validateRow, buildRow }
}

export default parseDistributions
