import keyBy from "lodash.keyby"
import timezones from "timezones-list"

const TIMEZONES_MAP = keyBy(timezones, "tzCode")


const getFormattedTimezone = tzCode => {
  if (!tzCode) {
    return ""
  }

  const timezone = TIMEZONES_MAP[tzCode]

  if (!timezone) {
    console.error(`Not found timezone for ${tzCode}`)
    return ""
  }

  return timezone.name
}

export default getFormattedTimezone
