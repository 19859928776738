import capitalize from "lodash.capitalize"

export const IMAGE = "IMAGE"
export const OTHER = "OTHER"

const AssetTypeEnum = {
  [IMAGE]: IMAGE,
  [OTHER]: OTHER
}

const AssetTypeEnumKeys = Object.keys(AssetTypeEnum)

const AssetTypeEnumOptions = AssetTypeEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  AssetTypeEnumKeys,
  AssetTypeEnumOptions
}

export default AssetTypeEnum
