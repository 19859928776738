import { TYPE_HIDDEN, TYPE_STRING, TYPE_UPLOAD } from "../../FormItem/types"

const LABEL_TITLE = "Title"
const LABEL_ATTACHEMENT = "Attachment"


const onUploadChange = (targetPath, form, { file }) => {
  const isDone = file.status === "done"
  const isRemoved = file.status === "removed"
  const shouldIgnore = !isDone && !isRemoved

  if (shouldIgnore) {
    return
  }

  const namePath = [...targetPath.slice(0, -1), 'name']
  const pathPath = [...targetPath.slice(0, -1), 'path']
  const isPublicPath = [...targetPath.slice(0, -1), 'isPublic']

  let name
  let url
  let path
  let isPublic

  if (isDone) {
    url = file.response.url
    path = file.response.path
    name = file.name
    isPublic = file.response.isPublic
  }

  form.setFieldValue(targetPath, url)
  form.setFieldValue(namePath, name)
  form.setFieldValue(pathPath, path)
  form.setFieldValue(isPublicPath, isPublic)
}

const getDefaultFileList = (targetPath, form) => {
  const namePath = [...targetPath.slice(0, -1), 'name']
  const url = form.getFieldValue(targetPath)
  const name = form.getFieldValue(namePath)

  if (name) {
    return [{ name, url }]
  }

  return []
}

const getAssetsListItemSchema = (isPublic, assetType, fileTypes, actionTitle, fieldExtraProps = {}) => {
  const extraPropsByName = name =>
    fieldExtraProps[name]
      ? fieldExtraProps[name]
      : {}

  const schema = [
    {
      ...extraPropsByName("url"),
      name: "url",
      type: TYPE_UPLOAD,
      label: LABEL_ATTACHEMENT,
      itemProps: { noStyle: true },
      inputProps: {
        isPublic,
        fileTypes,
        actionTitle,
        onUploadChange,
        getDefaultFileList,
        isUploadRemovable: false
      }
    }, {
      ...extraPropsByName("description"),
      name: "description",
      type: TYPE_STRING,
      placeholder: LABEL_TITLE,
      inputProps: { rows: 1 },
      itemProps: { noStyle: true }
    }, {
      ...extraPropsByName("name"),
      name: "name",
      type: TYPE_HIDDEN,
    }, {
      ...extraPropsByName("type"),
      name: "type",
      type: TYPE_HIDDEN,
      initialValue: assetType,
    }, {
      ...extraPropsByName("isPublic"),
      name: "isPublic",
      type: TYPE_HIDDEN,
    }, {
      ...extraPropsByName("path"),
      name: "path",
      type: TYPE_HIDDEN,
    }
  ]

  return schema
}

export default getAssetsListItemSchema
