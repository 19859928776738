import React from "react"
import { Outlet } from "react-router-dom"

import BackstageSettingsLayoutContextShape from "./shapes/BackstageSettingsLayoutContextShape"


const BackstageSettingsLayoutOutlet = ({ context }) => {
  return (
    <Outlet context={context} />
  )
}

BackstageSettingsLayoutOutlet.propTypes = {
  context: BackstageSettingsLayoutContextShape.isRequired,
}

export default BackstageSettingsLayoutOutlet
