import React from "react"
import { Table, Typography } from "antd"

import { Value } from "@components/Text"
import {
  computeFundingTransactionsTotal,
  computeDistributionsTransactionsTotal
} from "@components/Transaction"

const { Text } = Typography
const { Summary } = Table
const { Row, Cell } = Summary


const useTableSummary = () => {
  const summary = transactions => {
    const fundingTotalAmount = computeFundingTransactionsTotal(transactions)
    const distributionsTotalAmount = computeDistributionsTransactionsTotal(transactions)

    return (
      <Summary fixed="bottom">
        <Row>
          <Cell
            align="right"
            colSpan={4}
          >
            <Text strong>Total:</Text>
          </Cell>

          <Cell
            align="right"
          >
            <Value value={fundingTotalAmount} />
          </Cell>
          <Cell
            align="right"
          >
            <Value value={distributionsTotalAmount} />
          </Cell>
          <Cell>
            {' '}
          </Cell>
        </Row>
      </Summary>
    )
  }

  return summary
}

export default useTableSummary
