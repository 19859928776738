import PropTypes from "prop-types"


const properties = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  email: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  investorAccountId: PropTypes.string.isRequired,
  subjectId: PropTypes.string.isRequired,
  successMessage: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const ListRecipientOptOutsOutputShapeShape = PropTypes.exact(properties)

export default ListRecipientOptOutsOutputShapeShape
export { properties }
