import React, { useState } from "react"
import PropTypes from "prop-types"
import { EnvironmentOutlined } from "@ant-design/icons"
import { Modal, Space, Typography, Descriptions } from "antd"
import { useOutletContext } from "react-router-dom"

import { Slider } from "@components/Page"
import PropertyInformationTags from "@components/Project/PropertyInformationTags"
import { W2, isMobile, MODAL_WIDTH } from "@components"

const { Item } = Descriptions


const PropertyModal = ({ modal }) => {
  const [ propertyId, setPropertyId ] = useState()

  const { getProperty } = useOutletContext()

  modal.current.open = id =>
    setPropertyId(id)

  const property = propertyId
    ? getProperty(propertyId)
    : null

  const title = property?.name

  const propertyPhotos = property?.photos || []

  const width = MODAL_WIDTH * 1.6

  const style = {
    top: W2
  }

  const labelStyle = {
    width: "50%"
  }

  if (isMobile) {
    labelStyle.padding = 8
  }

  const renderItems = () =>
    property.tags
      .filter(tag => tag.group === PropertyInformationTags.group)
      .map(({ label, value }) => <Item key={label} label={label}>{value}</Item>)

  const getAddress = () =>
    [
      property.address.streetAddress,
      property.address.addressLocality,
      property.address.addressRegion,
    ].join(" ")

  const children = !property
    ? null
    : (
        <Space
          style={{ width: "100%" }}
          direction="vertical"
        >
          <Slider photos={propertyPhotos} />

          <Descriptions
            bordered
            size="small"
            title={
              <Typography.Title level={5} type="secondary" style={{ margin: 0 }}>
                <EnvironmentOutlined />
                {" "}
                {getAddress()}
              </Typography.Title>
            }
            column={1}
            className="compact black"
            labelStyle={labelStyle}
          >
            {renderItems()}
          </Descriptions>
        </Space>
    )

  const onCancel = () =>
    setPropertyId()

  const modalProps = {
    open: !!property,
    footer: false,
    width,
    title,
    style,
    onCancel,
  }

  return (
    <Modal {...modalProps}>
      {children}
    </Modal>
  )
}

PropertyModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default PropertyModal
