import React from "react"
import { Form, Input } from "antd"

const { Item } = Form
const { TextArea } = Input


const renderText = (itemProps, inputProps) => {
  return (
    <Item {...itemProps}>
      <TextArea rows={3} {...inputProps} />
    </Item>
  )
}

export default renderText
