import { useOutletContext } from "react-router-dom"

const LABEL_SPONSOR = "Sponsor"


const useSponsorsColumnProps = () => {
  const { getSponsorName } = useOutletContext()

  return {
    key: "sponsorId",
    title: LABEL_SPONSOR,
    compute: ({ sponsorId }) => getSponsorName(sponsorId),
    filterMultiple: true,
    isFilterEnabled: true,
  }
}

export default useSponsorsColumnProps
