import keyBy from "lodash.keyby"

import { MIME_TYPES_MAP } from "@components/Storage"

import formatBytes from "./formatBytes"

const FILE_TYPE = "OTHER"
const IMAGE_TYPE = "IMAGE"

const FILE_TYPES = Object
  .keys(MIME_TYPES_MAP)
  .filter(type => !type.startsWith("image"))

const IMAGE_TYPES = Object
  .keys(MIME_TYPES_MAP)
  .filter(type => type.startsWith("image"))


const getTitle = upload => {
  const stack = upload.app.modal.getStack()
  const data = stack.getData()

  if (!data) {
    return
  }

  const { title } = data

  return title
}

const formatAssetsSize = assets =>
  assets
    .map(({ size, ...rest }) => {
      const formattedSize = formatBytes(size)

      return {
        size: formattedSize, ...rest
      }
    })

const getRedactorProps = (isDisabled, uploadAssets, assets = []) => {
  const uploadAsset = async (upload, data, type) => {
    const title = getTitle(upload)

    const utils = upload.app.create("utils")
    const getRandomId = () => utils.getRandomId()

    const files = Object
      .values(data.files)
      .filter(file => typeof file === "object")

    upload.app.progress.show()

    let [ uploadedAssets ] = await uploadAssets(files, getRandomId, title, type)

    const isImage = type === IMAGE_TYPE

    const target = isImage
      ? "image"
      : "filelink"

    const prevAssets = upload.app.opts.get(`${target}.select`)

    uploadedAssets = formatAssetsSize(uploadedAssets)

    upload.app.opts.set(`${target}.select`, [
      ...uploadedAssets,
      ...prevAssets
    ])

    const filesMap = keyBy(uploadedAssets, "id")
    upload.complete(filesMap, data.e)
  }

  const formattedAssets = formatAssetsSize(assets)

  const filesAssets = formattedAssets
    .filter(asset => asset.type === FILE_TYPE)

  const imageAssets = formattedAssets
    .filter(asset => asset.type === IMAGE_TYPE)

  const uploadFile = (upload, data) =>
    uploadAsset(upload, data, FILE_TYPE)

  const uploadImage = (upload, data) =>
    uploadAsset(upload, data, IMAGE_TYPE)

  return {
    compact: false,
    bordered: false,
    buttons: {
      extrabar: [
        "undo",
        "redo",
        "hotkeys"
      ]
    },
    options: {
      focus: true,
      control: true,
      disabled: isDisabled,
      toolbar: {
        hide: [
          "preview",
        ]
      },
      plugins: [
        "email",
        "emoji",
        "filelink",
        "fontsize",
        "alignment",
        "fontcolor",
        "fontfamily",
        "blockcolor",
        "blockborder",
        "imageresize",
        "blockspacing",
        "blockfontsize",
        "blockbackground",
      ],
      filelink: {
        types: FILE_TYPES,
        select: filesAssets,
        upload: uploadFile,
        multiple: false,
      },
      image: {
        url: false,
        width: true,
        types: IMAGE_TYPES,
        select: imageAssets,
        upload: uploadImage,
        multiple: false,
      },
      email: {
        dark: false,
      }
    },
  }
}

export default getRedactorProps
