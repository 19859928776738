import React from "react"
import { NavLink } from "react-router-dom"
import { EditOutlined, StopOutlined } from "@ant-design/icons"

const LABEL_EDIT = "Edit"
const LABEL_DELETE = "Delete"


const useRowMenu = (userGroup, { openUpdateModal, openDeleteConfirm }) => {
  const { id } = userGroup

  const items = [
    {
      key: "update",
      icon: <EditOutlined />,
      label: (
        <NavLink onClick={() => openUpdateModal(id)}>
          {LABEL_EDIT}
        </NavLink>
      )
    },
    {
      type: "divider"
    },
    {
      key: "delete",
      icon: <StopOutlined />,
      danger: true,
      label: (
        <NavLink onClick={() => openDeleteConfirm(id)}>
          {LABEL_DELETE}
        </NavLink>
      )
    }
  ]

  return items
}

export default useRowMenu
