

const readFileContent = file => {
  return new Promise(resolve => {
    const reader = new FileReader()

    reader.onload = async (event) => {
      const content = event.target.result
      resolve(content)
    }

    reader.readAsArrayBuffer(file)
  })
}

export default readFileContent
