import React, { useState } from "react"
import PropTypes from "prop-types"

import { UpdateModal } from "@components/Page"
import {
  readUserOperation as readOperation,
  updateMyUserOperation as updateOperation,
} from "@api/services/backstage"

import getUpdateInputs from "./helpers/getUpdateInputs"

const LABEL_ENTITY = "Mobile"
const LABEL_UPDATE = `Edit ${LABEL_ENTITY}`
const LABEL_SUBMIT = "Continue"

const inputs = getUpdateInputs()
const schema = updateOperation.getSchema(inputs)


const UserMobileUpdateModal = ({ modal, onSuccess }) => {
  const [ , setCurrentUser ] = useState({})

  const onItemRead = user =>
    setCurrentUser(user)

  return (
    <UpdateModal
      modal={modal}
      title={LABEL_UPDATE}
      entity={LABEL_ENTITY}
      schema={schema}
      onSuccess={onSuccess}
      operation={updateOperation}
      onItemRead={onItemRead}
      submitLabel={LABEL_SUBMIT}
      readOperation={readOperation}
    />
  )
}

UserMobileUpdateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default UserMobileUpdateModal
