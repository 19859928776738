import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import cloneDeep from "lodash.clonedeep"

import Collection from "@components/Collection"

import getCollectionColumns from "./helpers/getCollectionColumns"
import getCollectionSummary from "./helpers/getCollectionSummary"


const TransactionsCollection = ({collection, onUpdateCollection}) => {
  const [transactions, setTransactions] = useState([])

  const collectionRef = useRef({})

  collection.current.setNewTransactions = (transactionsList) => {
    setTransactions(transactionsList)
    onUpdateCollection(transactionsList)
  }

  collection.current.hasFormError = () => {
    return collectionRef.current.hasFormError()
  }

  collection.current.getTransactions = () => transactions

  const update = record => {
    const transactionsList = cloneDeep(transactions)
    const index = transactionsList.findIndex(item => item.id === record.id)
    transactionsList[index] = record

    setTransactions(transactionsList)
    onUpdateCollection(transactionsList)

    return Promise.resolve()
  }

  const handleDelete = record => {
    const transactionsList = cloneDeep(transactions)
    const index = transactionsList.findIndex(item => item.id === record.id)
    if (index >= 0) {
      transactionsList.splice(index, 1)
    }

    setTransactions(transactionsList)
    onUpdateCollection(transactionsList)
  }

  const columns = getCollectionColumns(handleDelete)
  const tableProps = { bordered: false, rowKey: "id" }
  const enableSelectItem = false
  const selectedRowItems = transactions.map(({ id }) => id)

  const summary = items => getCollectionSummary(items)

  return (
    <Collection
      items={transactions}
      onSave={update}
      tableProps={tableProps}
      collection={collectionRef}
      columnsConfig={columns}
      enableSelectItem={enableSelectItem}
      selectedRowItems={selectedRowItems}
      summary={summary}
    />
  )
}

TransactionsCollection.propTypes = {
  collection: PropTypes.shape().isRequired,
  onUpdateCollection: PropTypes.func.isRequired,
}

export default TransactionsCollection
