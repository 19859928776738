import { INVESTMENT_PROFILE_TYPE_INDIVIDUAL } from "@components/Domain"


const getInvestmentPersonalCustomer = (investorSsn, investment) => {
  const {
    investor,
    profileType,
    profileJoint,
    profileIndividual
  } = investment

  const isIndividual = profileType === INVESTMENT_PROFILE_TYPE_INDIVIDUAL

  const profile = isIndividual
    ? profileIndividual
    : profileJoint

  const { ssnNumber } = profile
  const ssn = investorSsn || ssnNumber

  const {
    givenName: firstName,
    familyName: lastName,
    address,
    dateOfBirth,
  } = investor

  const personalCustomer = {
    ssn,
    address,
    lastName,
    firstName,
    dateOfBirth,
  }

  return personalCustomer
}

export default getInvestmentPersonalCustomer
