import React, { useReducer } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { getInputs } from "@components/Import"
import { CreateModal } from "@components/Page"
import { useForm, useWatch } from "@components/Form"
import { useRequest as useEffect} from "@components/AppContext"
import { importAccountsOperation as operation } from "@api/services/investments"

import useOnSubmit from "./helpers/useOnSubmit"
import getExtraInputs from "./helpers/getExtraInputs"

const LABEL_TITLE = "Import Investors"
const LABEL_ENTITY = "Investors"
const LABEL_SUBMIT = "Import"
const LABEL_SUCCESS = "Investors are imported"

const TEMPLATE_URL = "/static/templates/INVESTORS_IMPORT.xlsx"


const reducer = (state, { errorsJson = undefined, isDisabled = true, isValidating = false }) => {
  return { ...state, errorsJson, isDisabled, isValidating }
}


const AccountsImportModal = ({ modal }) => {
  const form = useForm()

  const [ state, dispatch ] = useReducer(reducer, {
    errorsJson: undefined,
    isDisabled: true,
    isValidating: false,
  })

  const {
    segments,
    indexAccounts,
  } = useOutletContext()

  const importFileUrl = useWatch('importFileUrl', form)

  useEffect(request => {
    if (!importFileUrl) {
      dispatch({})
      return
    }

    dispatch({ isValidating: true })

    const mutation = {
      importFileUrl,
      shouldValidateOnly: true
    }

    request(operation, { mutation })
      .then(({ data }) => {
        const { validationErrorsJson: errorsJson } = data
        const isDisabled = !!errorsJson
        const isValidating = false

        dispatch({ errorsJson, isDisabled, isValidating })
      })
  }, [ dispatch, importFileUrl ])

  const extraInputs = getExtraInputs(segments)

  const {
    errorsJson,
    isDisabled,
    isValidating,
  } = state

  const inputs = getInputs(TEMPLATE_URL, isValidating, errorsJson, null, extraInputs)
  const schema = operation.getSchema(inputs)

  const onSubmit = useOnSubmit()

  const onExit = () =>
    dispatch({})

  const onSuccess = () =>
    indexAccounts()

  const submitButtonProps = {
    disabled: isDisabled,
  }

  const createModalProps = {
    title: LABEL_TITLE,
    entity: LABEL_ENTITY,
    submitLabel: LABEL_SUBMIT,
    successMessage: LABEL_SUCCESS,
    form,
    modal,
    schema,
    onExit,
    onSubmit,
    onSuccess,
    operation,
    submitButtonProps,
  }

  return (
    <CreateModal {...createModalProps} />
  )
}

AccountsImportModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default AccountsImportModal
