import React from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { Spin, Alert, Typography } from "antd"

import { W0, W1 } from "@components"
import { ReadMyOrganizationOutputShape } from "@api/services/organizations"

const SETTINGS_CUSTOM_DOMAIN_PATH = '/backstage/settings/email-identity'

const LABEL_ACTION = "click here"


const EmailIdentityAlert = ({
  organization,
  saveCampaign
}) => {
  const navigate = useNavigate()

  const { emailIdentity = {} } = organization
  const { domain: customDomain } = emailIdentity

  if (customDomain) {
    return null
  }

  const style = {
    marginTop: -1 * W0,
    marginBottom: W1
  }

  const onClick = async () => {
    await saveCampaign()
    navigate(SETTINGS_CUSTOM_DOMAIN_PATH)
  }

  const alertProps = {
    type: "warning",
    style,
    message: (
      <Typography.Text>
        You can send campaigns using a custom email address as the FROM address.
        {" "}
        To get started
        {" "}
        <Typography.Link onClick={onClick}>
          {LABEL_ACTION}
        </Typography.Link>
        .
      </Typography.Text>
    ),
  }

  return (
    <Spin spinning={false}>
      <Alert {...alertProps} />
    </Spin>
  )
}

EmailIdentityAlert.propTypes = {
  saveCampaign: PropTypes.func.isRequired,
  organization: ReadMyOrganizationOutputShape.isRequired,
}

export default EmailIdentityAlert
