import get from "lodash.get"


const getTargetAttributeOnChange = (targets, attributePath, defaultAttributes = {}) =>
  (value, form) => {
    if (!value) {
      form.setFieldsValue(defaultAttributes)
      return
    }

    const selectedTargets = targets
      .filter(item => get(item, attributePath) === value)

    const [ target ] = selectedTargets

    if (!target) {
      return
    }

    const targetFieldsMap = {}
    const targetAttributeKeys = Object.keys(defaultAttributes)

    for (const fieldKey of targetAttributeKeys) {
      const fieldValue = get(target, fieldKey)
      targetFieldsMap[fieldKey] = fieldValue
    }

    form.setFieldsValue({ ...targetFieldsMap })
  }

export default getTargetAttributeOnChange
