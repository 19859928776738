import React, { useRef } from "react"

import { W1 } from "@components"
import { Page } from "@components/Page"

import CustomerDrawer from "./CustomerDrawer"
import CustomersTable from "./CustomersTable"

const LABEL_TITLE = "ACH Accounts"


const CustomersPage = () => {
  const table = useRef({})
  const customerDrawer = useRef({})

  const onSearch = value =>
    table.current.search(value)

  const openCustomerDrawer = customerId => {
    customerDrawer.current.open(customerId)
  }

  const style = {
    padding: W1,
    paddingBottom: 0,
  }

  return (
    <Page
      style={style}
      title={LABEL_TITLE}
      onSearch={onSearch}
    >
      <CustomersTable
        table={table}
        openCustomerDrawer={openCustomerDrawer}
      />

      <CustomerDrawer
        drawer={customerDrawer}
      />
    </Page>
  )
}

export default CustomersPage
