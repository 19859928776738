import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { CreateModal } from "@components/Page"

import useCreateBrokerSchema, { createOperation } from "./helpers/useCreateBrokerSchema"

const LABEL_TITLE = "Create Liaison"
const LABEL_ENTITY = "Liaison"


const BrokerCreateModal = ({ modal }) => {
  const { addUser } = useOutletContext()

  const [
    form,
    schema,
    onSubmit
  ] = useCreateBrokerSchema()

  const onSuccess = newUser =>
    addUser(newUser)

  const createModalProps = {
    title: LABEL_TITLE,
    entity: LABEL_ENTITY,
    operation: createOperation,
    form,
    modal,
    schema,
    onSubmit,
    onSuccess,
  }

  return (
    <CreateModal {...createModalProps} />
  )
}

BrokerCreateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default BrokerCreateModal
