import React from "react"
import PropTypes from "prop-types"
import { useAppContext } from "@components/AppContext"

import { Bold } from "@components/Text"
import { SelectOptionShape, OperationShape } from "@api"

import {
  TYPE_TAGS,
  TYPE_TEXT,
  TYPE_TIME,
  TYPE_ENUM,
  TYPE_HIDDEN,
  TYPE_UPLOAD,
  TYPE_AMOUNT,
  TYPE_NUMBER,
  TYPE_INTEGER,
  TYPE_ARRAY_ENUM,
  TYPE_DATE,
  TYPE_ROLE,
  TYPE_PHONE,
  TYPE_BOOLEAN,
  TYPE_RADIO,
  TYPE_SSN,
  TYPE_EIN,
  TYPE_COUNTRY,
  TYPE_ZIP,
  TYPE_DOMAIN,
  TYPE_CHECKBOX,
  TYPE_REDACTOR,
  TYPE_TIMEZONE,
  TYPE_DATETIME,
  STRING_LIST_TYPE,
  TYPE_PASSWORD,
  TYPE_STRING
} from "./types"

import SelectExtraOptionsShape from "./shapes/SelectExtraOptionsShape"
import FormShape from "../shapes/FormShape"

import renderTags from "./helpers/renderTags"
import renderTime from "./helpers/renderTime"
import renderText from "./helpers/renderText"
import renderDate from "./helpers/renderDate"
import renderRole from "./helpers/renderRole"
import renderInput from "./helpers/renderInput"
import renderRadio from "./helpers/renderRadio"
import renderPhone from "./helpers/renderPhone"
import renderSelect from "./helpers/renderSelect"
import renderUpload from "./helpers/renderUpload"
import renderHidden from "./helpers/renderHidden"
import renderNumber from "./helpers/renderNumber"
import renderDomain from "./helpers/renderDomain"
import renderBoolean from "./helpers/renderBoolean"
import renderZipCode from "./helpers/renderZipCode"
import renderDateTime from "./helpers/renderDateTime"
import renderRedactor from "./helpers/renderRedactor"
import renderTimeZone from "./helpers/renderTimeZone"
import renderSsnInput from "./helpers/renderSsnInput"
import renderPassword from "./helpers/renderPassword"
import renderCheckbox from "./helpers/renderCheckbox"
import renderEinInput from "./helpers/renderEinInput"
import renderCountryInput from "./helpers/renderCountryInput"

import getHasOptions from "./helpers/getHasOptions"
import renderStringList from "./helpers/renderStringList"

import "./FormItem.css"

const VALIDATION_ERRORS = {
  email: "Invalid email address",
  upload: "File upload is required",
  default: "Field is required",
}

const SELECT_TYPES = [
  TYPE_ENUM,
  TYPE_ARRAY_ENUM,
]

const NUMBER_TYPES = [
  TYPE_AMOUNT,
  TYPE_NUMBER,
  TYPE_INTEGER
]


const FormItem = ({
  label = "",
  options = undefined,
  required = true,
  itemProps = {},
  operation = undefined,
  inputProps = {},
  placeholder = "",
  extraOptions = undefined,
  hasAutoComplete = false,
  form,
  type = TYPE_STRING,
  name,
  path,
  minimum,
  maximum,
  maxLength,
  minLength,
  initialValue,
}) => {
  const { request } = useAppContext()

  const hasOptions = getHasOptions(options)

  const isTags = type === TYPE_TAGS
  const isDateTime = type === TYPE_DATETIME
  const isDate = type === TYPE_DATE
  const isTime = type === TYPE_TIME
  const isCheckbox = type === TYPE_CHECKBOX
  const isSsn = type === TYPE_SSN
  const isEin = type === TYPE_EIN
  const isZip = type === TYPE_ZIP
  const isBool = type === TYPE_BOOLEAN
  const isPhone = type === TYPE_PHONE
  const isNumber = NUMBER_TYPES.includes(type)
  const isHidden = type === TYPE_HIDDEN
  const isUpload = type === TYPE_UPLOAD
  const isSelect = hasOptions || operation || SELECT_TYPES.includes(type)
  const isRadio = type === TYPE_RADIO && hasOptions
  const isText = type === TYPE_TEXT
  const isCountry = type === TYPE_COUNTRY
  const isRole = type === TYPE_ROLE
  const isDomain = type === TYPE_DOMAIN
  const isStringList = type === STRING_LIST_TYPE
  const isRedactor = type === TYPE_REDACTOR
  const isTimeZone = type === TYPE_TIMEZONE
  const isPassword = type === TYPE_PASSWORD

  const rules = []

  if (required) {
    rules.push({ required, message: VALIDATION_ERRORS.default })
  }

  const { rules: itemPropsRules = [], ...restItemProps } = itemProps

  rules.push(...itemPropsRules)

  const validateTrigger = []

  const shouldBoldifyLabel = !!label && typeof(label) === "string"

  if (shouldBoldifyLabel) {
    label = <Bold>{label}</Bold>
  }

  const commonItemProps = {
    type, /* td: Check if we need type for FormItem? */
    name,
    label,
    rules,
    initialValue,
    validateTrigger,
    ...restItemProps
  }

  const commonInputProps = {
    placeholder,
    ...inputProps
  }

  const commonNumberInputProps = {
    min: minimum,
    max: maximum
  }

  const commonStringInputProps = {
    maxLength,
    minLength
  }

  if (isUpload) {
    return renderUpload(commonItemProps, commonInputProps, form, path, request)
  }

  if (isRole) {
    return renderRole(commonItemProps, commonInputProps, form, path)
  }

  if (isHidden) {
    return renderHidden(commonItemProps)
  }

  if (isRadio) {
    return renderRadio(commonItemProps, commonInputProps, options, form)
  }

  if (isCheckbox) {
    return renderCheckbox(commonItemProps, commonInputProps)
  }

  if (isSelect) {
    const selectInputProps = { ...commonInputProps, hasAutoComplete, ...commonStringInputProps }
    return renderSelect(commonItemProps, selectInputProps, options, extraOptions, operation, form)
  }

  if (isTimeZone) {
    return renderTimeZone(commonItemProps, commonInputProps, request, form)
  }

  if (isNumber) {
    return renderNumber(commonItemProps, {...commonInputProps, ...commonNumberInputProps}, form, path)
  }

  if (isDate) {
    return renderDate(commonItemProps, commonInputProps, form, path)
  }

  if (isTime) {
    return renderTime(commonItemProps, commonInputProps, form, path)
  }

  if (isDateTime) {
    return renderDateTime(commonItemProps, commonInputProps, form, path)
  }

  if (isEin) {
    return renderEinInput(commonItemProps, commonInputProps, form, path)
  }

  if (isSsn) {
    return renderSsnInput(commonItemProps, commonInputProps, form, path)
  }

  if (isPhone) {
    return renderPhone(commonItemProps, commonInputProps, form, path)
  }

  if (isZip) {
    return renderZipCode(commonItemProps, commonInputProps, form, path)
  }

  if (isDomain) {
    return renderDomain(commonItemProps, commonInputProps, form, path)
  }

  if (isBool) {
    return renderBoolean(form, commonItemProps, commonInputProps)
  }

  if (isText) {
    return renderText(commonItemProps, {...commonInputProps, ...commonStringInputProps})
  }

  if (isCountry) {
    return renderCountryInput(commonItemProps, commonInputProps, request, form)
  }

  if (isRedactor) {
    return renderRedactor(commonItemProps, { ...commonInputProps, ...commonStringInputProps }, form, path)
  }

  if (isTags) {
    return renderTags(commonItemProps, commonInputProps, form, path)
  }

  if (isStringList) {
    return renderStringList(commonItemProps, commonInputProps, form, path)
  }

  if (isPassword) {
    return renderPassword(commonItemProps, commonInputProps)
  }

  return renderInput(form, commonItemProps, {...commonInputProps, ...commonStringInputProps})
}

const PathShape = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
)

const LabelShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape()
])

const OptionsShape = PropTypes.oneOfType([
  PropTypes.array,
  PropTypes.arrayOf(PropTypes.string),
  PropTypes.arrayOf(SelectOptionShape),
])

FormItem.propTypes = {
  form: FormShape.isRequired,
  type: PropTypes.string,
  path: PathShape.isRequired,
  label: LabelShape,
  options: OptionsShape,
  minimum: PropTypes.number,
  maximum: PropTypes.number,
  required: PropTypes.bool,
  itemProps: PropTypes.shape(),
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  operation: OperationShape,
  inputProps: PropTypes.shape(),
  placeholder: PropTypes.string,
  extraOptions: PropTypes.arrayOf(SelectExtraOptionsShape),
  hasAutoComplete: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.any,
}

export default FormItem
