import { useState, useMemo, useEffect, useReducer, useCallback } from "react"
import PropTypes from "prop-types"

import { runRequest } from "@components/AppContext"
import {
  refreshAuthentication as refreshCognitoAuthentication,
  parseJwt,
  authenticate,
  getAuthenticatedRequest,
} from "../authenticate"

const SCF_PATH = "/scf"


const useAuthenticationStore = ({
  isBackstage,
  showErrorMessage,
  showLoadingMessage,
  messageContextHolder
}) => {
  const [ authenticationError, setAuthenticationError ] = useState()

  const reducer = (state, authenticationJwt) => {
    const payload = parseJwt(authenticationJwt)

    return {
      isRoot: payload.isRoot,
      authentication: authenticationJwt,
      isImpersonated: payload.isImpersonated,
    }
  }

  const [ state, updateAuthentication ] = useReducer(reducer, {
    isRoot: undefined,
    isImpersonated: undefined,
    authentication: undefined,
  })

  const isGuest = useMemo(() =>
    window.location.pathname.startsWith(SCF_PATH)
  , [])

  const {
    isRoot,
    isImpersonated,
    authentication,
  } = state

  const isAuthenticated = !!authentication

  const resetAuthentication = useCallback(async () => {
    const authenticationJwt = await refreshCognitoAuthentication(isBackstage)
    updateAuthentication(authenticationJwt)
  }, [ isBackstage ])

  const refreshAuthentication = useCallback(() =>
    authenticate(setAuthenticationError, isBackstage, true)
      .then(authenticationJwt => {
        updateAuthentication(authenticationJwt)
        return authenticationJwt
      })
  , [ isBackstage ])

  const authenticatedRequest = useCallback((...args) => {
    const onUnexpectedError = error =>
      showErrorMessage(error.message, error.originalError)

    const authenticatedRequestMethod = getAuthenticatedRequest(isBackstage, onUnexpectedError)
    return authenticatedRequestMethod(...args)
  }, [
    isBackstage,
    showErrorMessage
  ])

  useEffect(() => {
    if (isGuest) {
      return
    }

    if (isAuthenticated) {
      return
    }

    return runRequest(() =>
      authenticate(setAuthenticationError, isBackstage)
        .then(authenticationJwt => updateAuthentication(authenticationJwt))
    )
  }, [
    isGuest,
    isBackstage,
    isAuthenticated,
  ])

  return {
    isRoot,
    isGuest,
    isImpersonated,
    isAuthenticated,
    resetAuthentication,
    authenticationError,
    authenticatedRequest,
    updateAuthentication,
    refreshAuthentication,
  }
}

const authenticationStoreProperties = {
  isRoot: PropTypes.bool,
  isGuest: PropTypes.bool.isRequired,
  isImpersonated: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  resetAuthentication: PropTypes.func.isRequired,
  authenticationError: PropTypes.string,
  authenticatedRequest: PropTypes.func.isRequired,
  updateAuthentication: PropTypes.func.isRequired,
  refreshAuthentication: PropTypes.func.isRequired,
}

export default useAuthenticationStore

export {
  authenticationStoreProperties
}
