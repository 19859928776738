import PropTypes from "prop-types"


const properties = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  fundId: PropTypes.string,
  fundRaisedAmount: PropTypes.number,
  id: PropTypes.string.isRequired,
  nonAccreditedInvestorsCount: PropTypes.number,
  nonAccreditedInvestorsMax: PropTypes.number,
  partnerProjectId: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  sponsorId: PropTypes.string,
  targetAmount: PropTypes.number,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const ConnectSponsorPartnerOutputShapeShape = PropTypes.exact(properties)

export default ConnectSponsorPartnerOutputShapeShape
export { properties }
