import capitalize from "lodash.capitalize"

export const US = "US"

const UsCountryEnum = {
  [US]: US
}

const UsCountryEnumKeys = Object.keys(UsCountryEnum)

const UsCountryEnumOptions = UsCountryEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  UsCountryEnumKeys,
  UsCountryEnumOptions
}

export default UsCountryEnum
