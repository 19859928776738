import React, { useCallback } from "react"
import PropTypes from "prop-types"

import Table from "@components/Table"
import { PROJECT_STATES } from "@components/Domain"
import { listProjectsOperation as indexOperation } from "@api/services/backstage"

import useColumnsConfig from "./helpers/useColumnsConfig"


const ProjectsTable = ({ table }) => {
  const size = "small"
  const sticky = true
  const columnsConfig = useColumnsConfig()

  const filterItemsCallback = useCallback(items =>
    items
      .filter(({ state }) => state === PROJECT_STATES.ARCHIVED)
  , [])

  const tableProps = {
    size,
    table,
    sticky,
    columnsConfig,
    indexOperation,
    filterItemsCallback,
  }

  return (
    <Table {...tableProps} />
  )
}

ProjectsTable.propTypes = {
  table: PropTypes.shape().isRequired,
}

export default ProjectsTable
