import capitalize from "lodash.capitalize"

export const DOCUMENT = "DOCUMENT"
export const DOCUMENT_VERIFICATION_PENDING = "DOCUMENT_VERIFICATION_PENDING"
export const INCOMPLETE = "INCOMPLETE"
export const VERIFIED = "VERIFIED"

const BeneficialOwnerVerificationStatusEnum = {
  [DOCUMENT]: DOCUMENT,
  [DOCUMENT_VERIFICATION_PENDING]: DOCUMENT_VERIFICATION_PENDING,
  [INCOMPLETE]: INCOMPLETE,
  [VERIFIED]: VERIFIED
}

const BeneficialOwnerVerificationStatusEnumKeys = Object.keys(BeneficialOwnerVerificationStatusEnum)

const BeneficialOwnerVerificationStatusEnumOptions = BeneficialOwnerVerificationStatusEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  BeneficialOwnerVerificationStatusEnumKeys,
  BeneficialOwnerVerificationStatusEnumOptions
}

export default BeneficialOwnerVerificationStatusEnum
