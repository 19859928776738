import React from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

import { W0, W1 } from "@components"
import { MarkdownAlert } from "@components/Text"


const Header = ({
  info = undefined,
  style = {},
  children,
}) => {
  if (info) {
    return (
      <div style={{ marginBottom: W1, ...style }}>
        <Typography.Title level={5} style={{ marginTop: 0, marginBottom: W0 }}>
          {children}
        </Typography.Title>

        <MarkdownAlert text={info} />
      </div>
    )
  }

  return (
    <Typography.Title level={5} style={style}>
      {children}
    </Typography.Title>
  )
}

Header.propTypes = {
  info: PropTypes.string,
  style: PropTypes.shape(),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export default Header
