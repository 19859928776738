export const PENDING_KEY = "PENDING"
export const COMPLETE_KEY = "COMPLETE"
export const NOT_SIGNED_KEY = "NOT_SIGNED"
export const OVERFUNDED_KEY = "OVERFUNDED"
export const INCOMPLETE_KEY = "INCOMPLETE"
export const ACH_PENDING_KEY = "ACH_PENDING"
export const CONNECT_SOURCE_KEY = "CONNECT_SOURCE"
export const PARTIALLY_FUNDED_KEY = "PARTIALLY_FUNDED"
export const CUSTOMER_SUSPENDED_KEY = "CUSTOMER_SUSPENDED"
export const BANK_ACCOUNT_NOT_READY_KEY = "BANK_ACCOUNT_NOT_READY"
export const INVALID_INVESTOR_BANK_ACCOUNT_KEY = "INVALID_INVESTOR_BANK_ACCOUNT"
export const INSUFFICIENT_FUNDS_INVESTOR_BANK_ACCOUNT_KEY = "INSUFFICIENT_FUNDS_INVESTOR_BANK_ACCOUNT"

export const DANGER_STYLE_TYPE = "danger"
export const WARNING_STYLE_TYPE = "warning"
export const SUCCESS_STYLE_TYPE = "success"

export const PENDING_STATUS = "Pending"
export const COMPLETE_STATUS = "Complete"
export const OVERFUNDED_STATUS = "Overfunded"
export const INCOMPLETE_STATUS = "Incomplete"
export const PARTIALLY_FUNDED_STATUS = "Partially Funded"

export const STYLE_TYPES = [
  DANGER_STYLE_TYPE,
  WARNING_STYLE_TYPE,
  SUCCESS_STYLE_TYPE,
]

export const PENDING_SUBTITLE = `Your funding is being processed. The process
will be marked as complete, and you will receive a confirmation email once your
sponsor has confirmed the receipt of the funds.`

export const COMPLETE_SUBTITLE = `Success! Your funding is complete. Thank you
for your investment.`

export const INCOMPLETE_DEFAULT_SUBTITLE = `Your funding is incomplete. Click
"View Wire Instructions" to view and download the wire instructions to initiate
a wire to fund your investment.`

export const OVERFUNDED_SUBTITLE = `You've sent more funds than your committed
amount. Please contact your sponsor for the next steps to resolve this issue.`

export const PARTIALLY_FUNDED_SUBTITLE = `Received amount doesn't match the
committed amount. Add funds via wire transfer to match your commitment or
contact support for assistance.`

export const WIRE_DEFAULT_LABELS_MAP = {
  [INCOMPLETE_KEY]: {
    status: INCOMPLETE_STATUS,
    subtitle: INCOMPLETE_DEFAULT_SUBTITLE,
    styleType: DANGER_STYLE_TYPE
  },
  [PENDING_KEY]: {
    status: PENDING_STATUS,
    subtitle: PENDING_SUBTITLE,
    styleType: WARNING_STYLE_TYPE,
  },
  [COMPLETE_KEY]: {
    status: COMPLETE_STATUS,
    subtitle: COMPLETE_SUBTITLE,
    styleType: SUCCESS_STYLE_TYPE,
  },
  [OVERFUNDED_KEY]: {
    status: OVERFUNDED_STATUS,
    subtitle: OVERFUNDED_SUBTITLE,
    styleType: DANGER_STYLE_TYPE,
  },
  [PARTIALLY_FUNDED_KEY]: {
    status: PARTIALLY_FUNDED_STATUS,
    subtitle: PARTIALLY_FUNDED_SUBTITLE,
    styleType: DANGER_STYLE_TYPE
  },
}
