import React from "react"
import { useOutletContext } from "react-router-dom"

import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"

import SignDocumentsButton from "./SignDocumentsButton"
import InvestmentProfileCardItem, { LABEL_PENDING, LABEL_COMPLETE } from "./InvestmentProfileCardItem"

const LABEL_DOCUMENTS = "Sign Subscription Documents"
const LABEL_DOCUMENTS_TEXT_READY = `
  Review investment profile details to be accurate and sign the subscription documents.
`
const LABEL_DOCUMENTS_NO_PPM_TEMPLATE = `
  Subscription documents are not yet available. We will notify you when they're ready so you can complete your investment process.
`
const LABEL_PARTIALLY_SIGNED = "Completed (Partially Signed)"


const InvestmentProfileCardSignDocuments = ({ investment }) => {
  const { isImpersonated } = useAppContext()
  const { project } = useOutletContext()

  const { ppmTemplateId } = project

  const {
    id: investmentId,
    subscriptionId,
    isProfileComplete,
    isInvestorComplete,
    isSigned,
    isPartiallySigned,
  } = investment

  const isReadyToSign = isInvestorComplete && isProfileComplete
  const hasPpmTemplateId = !!ppmTemplateId

  const isSignDocumentsDisabled =
    !(isReadyToSign && hasPpmTemplateId) ||
    isImpersonated

  const isDocumentSigned = isSigned || isPartiallySigned
  const shouldRenderDocumentsSignButton = () => {
    return !isDocumentSigned
  }

  const isDocumentPartiallySigned =
    !isSigned &&
    isPartiallySigned

  let subtitle = ""

  if (!isDocumentSigned && hasPpmTemplateId) {
    subtitle = LABEL_DOCUMENTS_TEXT_READY
  }

  if (!hasPpmTemplateId) {
    subtitle = LABEL_DOCUMENTS_NO_PPM_TEMPLATE
  }

  let status = isDocumentPartiallySigned
    ? LABEL_PARTIALLY_SIGNED
    : LABEL_PENDING

  let styleType = "danger"

  if (isDocumentSigned) {
    status = LABEL_COMPLETE
    styleType = "success"
  }

  return (
    <InvestmentProfileCardItem
      title={LABEL_DOCUMENTS}
      status={status}
      subtitle={subtitle}
      styleType={styleType}
    >
      {
        shouldRenderDocumentsSignButton() && (
          <SignDocumentsButton
            isDisabled={isSignDocumentsDisabled}
            investmentId={investmentId}
            subscriptionId={subscriptionId}
          />
        )
      }
    </InvestmentProfileCardItem>
  )
}

InvestmentProfileCardSignDocuments.propTypes = {
  investment: InvestmentShape.isRequired,
}

export default InvestmentProfileCardSignDocuments
