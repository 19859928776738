import getOrganization from "./getOrganization"


const extendIdentityOrganization = identity => {
  const isInvestor = !!identity.investor

  if (isInvestor) {
    return identity
  }

  const { organizations } = identity
  const organization = getOrganization(organizations)

  return { ...identity, organization }
}

export default extendIdentityOrganization
