import React from "react"
import PropTypes from "prop-types"

import { W1 } from "@components"
import { InvestmentShape } from "@components/Investment/shapes"

import getWireLabelKey from "./helpers/getWireLabelKey"
import NoTinWarningAlert from "./NoTinWarningAlert"
import { OpenWireModalButton } from "./WireModal"
import InvestmentProfileCardItem from "../InvestmentProfileCardItem"
import { WIRE_DEFAULT_LABELS_MAP } from "./helpers/Constants"


const FundNoTinInvestmentItem = ({
  investment,
  openWireModal,
  ...otherProps
}) => {
  const { isFundingUnlocked } = investment

  const [ key, shouldRenderWireButton ] = getWireLabelKey(investment)
  const labels = WIRE_DEFAULT_LABELS_MAP[key]

  const itemProps = {
    ...labels,
    ...otherProps
  }

  if (!isFundingUnlocked) {
    itemProps.subtitle = ""
  }

  return (
    <InvestmentProfileCardItem {...itemProps}>
      {
        !isFundingUnlocked && (
          <div style={{ marginTop: W1 }}>
            <NoTinWarningAlert />
          </div>
        )
      }

      {
        isFundingUnlocked && shouldRenderWireButton && (
          <OpenWireModalButton
            onClick={openWireModal}
          />
        )
      }
    </InvestmentProfileCardItem>
  )
}

FundNoTinInvestmentItem.propTypes = {
  investment: InvestmentShape.isRequired,
  openWireModal: PropTypes.func.isRequired,
}

export default FundNoTinInvestmentItem
