import React from "react"
import { Typography, Space, Tag } from "antd"

import { Value } from "@components/Text"

import VerificationStatusTag, {LABELS_VERIFICATION_STATUS_MAP} from "../VerificationStatusTag"

const LABEL_NAME = "Account"
const LABEL_STATUS = "Status"
const LABEL_IMPERSONATED = "Impersonated"


const getColumnsConfig = (openCustomerDrawer) => {
  const renderName = (name, email, customer) => {
    const { id, isImpersonated } = customer

    const onClick = () => openCustomerDrawer(id)

    return (
      <Space>
        <Typography.Link strong onClick={onClick}>
          {name}
        </Typography.Link>

        <Typography.Text type="secondary">
          {email}
        </Typography.Text>

        {
          isImpersonated && (
            <Tag>{LABEL_IMPERSONATED}</Tag>
          )
        }
      </Space>
    )
  }

  const nameColumnProps = {
    key: "name",
    title: LABEL_NAME,
    compute: ({ name, firstName, lastName, controllerEmail }) => {
      const isPersonal = [ "Impersonated", "Personal" ].includes(name)

      if (isPersonal) {
        const fullName = `${firstName} ${lastName}`
        return [ fullName, controllerEmail ]
      }

      return [ name, controllerEmail ]
    },
    render: ([ name, email ], customer) => renderName(name, email, customer),
    isSearchable: true,
  }

  const verificationStatusColumnProps = {
    key: "verificationStatus",
    title: LABEL_STATUS,
    align: "center",
    isFilterEnabled: true,
    filterText: value => LABELS_VERIFICATION_STATUS_MAP[value],
    render: (status, { name }) => (
      <VerificationStatusTag
        name={name}
        status={status}
      />
    )
  }

  const createdAtColumnProps = {
    key: "createdAt",
    render: value => <Value value={value} />,
  }

  return [
    { ...nameColumnProps },
    { ...verificationStatusColumnProps, width: 170 },
    { ...createdAtColumnProps, width: 170 },
  ]
}

export default getColumnsConfig
