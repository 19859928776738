import React from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

const LABEL_REVIEW = "Review on DocuSign"


const DocusignLink = ({ href }) => {
  return (
    <Typography.Link
      href={href}
      target="_blank"
    >
      {LABEL_REVIEW}
    </Typography.Link>
  )
}

DocusignLink.propTypes = {
  href: PropTypes.string.isRequired,
}

export default DocusignLink
