
const MAX_DETAILS_LENGTH = 80


const getTransactionDetails = investment => {
  const {
    id: investmentId,
    name: investmentName,
    projectId,
  } = investment

  const details = `${projectId}-${investmentId} — ${investmentName}`
    .substring(0, MAX_DETAILS_LENGTH)

  return details
}

export default getTransactionDetails
