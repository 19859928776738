import { validateDob } from "@components/Date"
import { TYPE_NONE, TYPE_STRING, TYPE_SSN } from "@components/Form"
import { getAddressSchema, COUNTRY_US, getDwollaAddressSchema } from "@components/Address"

const LABEL_DOB = "Date of Birth"
const LABEL_SSN = "Social Security Number"
const LABEL_PASSPORT = "Passport Number"
const LABEL_INDIVIDUAL = "Individual"


const getInputs = (updateSchema, address = {}) => {
  const { addressCountry = COUNTRY_US } = address
  const isUsPerson = addressCountry === COUNTRY_US

  const addressSchema = getAddressSchema(updateSchema, ["address"], address)

  let inputs = [
    {
      name: "firstName",
      required: true,
      maxLength: 50,
    },
    {
      name: "lastName",
      required: true,
      maxLength: 50,
    },
    {
      name: "dateOfBirth",
      label: LABEL_DOB,
      placeholder: "",
      required: true,
      itemProps: {
        rules: [validateDob(LABEL_INDIVIDUAL)]
      }
    },
    {
      name: "address",
      label: "",
      schema: getDwollaAddressSchema(addressSchema),
    },
  ]

  if (isUsPerson) {
    inputs = [
      ...inputs,
      ...[
        {
          name: "ssn",
          type: TYPE_SSN,
          label: LABEL_SSN,
          required: true
        },
        {
          name: "passportNumber",
          type: TYPE_NONE,
        }
      ]
    ]

    return inputs
  }

  inputs = [
    ...inputs,
    ...[
      {
        name: "passportNumber",
        type: TYPE_STRING,
        label: LABEL_PASSPORT,
        required: true,
        inputProps: { style: { width: 140 } },
      },
      {
        name: "ssn",
        type: TYPE_NONE,
      }
    ]
  ]

  return inputs
}

export default getInputs
