import React, { useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import { Form, Input, Radio, Space, Typography } from "antd"

import { hasAccess } from "@components/Authorization"
import { useAppContext } from "@components/AppContext"
import { ItemPropsShape, InputPropsShape } from "@api"
import {
  ROLES_MAP,
  CONSOLE_ROLES,
  BACKSTAGE_ROLES,
  CONSOLE_ROLES_MAP,
  BACKSTAGE_FUND_ROLES
} from "@components/Domain"

import FormShape from "../shapes/FormShape"

const { Text } = Typography

const CUSTOM_FIELD_NAME = '_role'


const RolesInput = ({
  form,
  path,
  itemProps,
  inputProps
}) => {
  const { isConsoleOrganization } = useAppContext()

  const { name, ...restItemProps } = itemProps
  const { onChange: onChangeCustom, disabled = false } = inputProps

  const roles = Form.useWatch(name, form)

  const customFieldPath = useMemo(() =>
    [ ...path.slice(0, -1), CUSTOM_FIELD_NAME]
  , [ path ])

  useEffect(() => {
    if (!roles) { return }

    const [ role ] = roles

    form.setFieldValue(customFieldPath, role)
  }, [ form, roles, customFieldPath ])

  const isFund = hasAccess(['fund-users-write'])

  const getSupportedRoles = () => {
    if (isConsoleOrganization) {
      return CONSOLE_ROLES
    }

    if (isFund) {
      return BACKSTAGE_FUND_ROLES
    }

    return BACKSTAGE_ROLES
  }

  const rolesMap = isConsoleOrganization
    ? CONSOLE_ROLES_MAP
    : ROLES_MAP

  const options = getSupportedRoles()
    .map(key => ({
      value: key,
      label: (
        <Space
          size={0}
          direction="vertical"
        >
          <Text>
            {rolesMap[key].title}
          </Text>

          <Text type="secondary">
            {rolesMap[key].summary}
          </Text>
        </Space>
      )
    }))

  const onChange = () => {
    const role = form.getFieldValue(customFieldPath)
    form.setFieldValue(path, [ role ])

    onChangeCustom ? onChangeCustom(role, form) : {}
  }

  return (
    <>
      <Form.Item
        key="hidden"
        name={name}
        hidden={true}
      >
        <Input />
      </Form.Item>

      <Form.Item
        {...restItemProps}
        name={customFieldPath}
      >
        <Radio.Group onChange={onChange}>
          <Space direction="vertical">
            {
              options.map(({ label, value }, index) =>
                <Radio
                  key={index}
                  value={value}
                  disabled={disabled}
                >
                  {label}
                </Radio>
              )
            }
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  )
}

RolesInput.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemProps: ItemPropsShape.isRequired,
  inputProps: InputPropsShape.isRequired,
}

export default RolesInput
