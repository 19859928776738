import React from "react"
import PropTypes from "prop-types"

import ListInput from "@components/Form/Input/ListInput"
import { FormShape } from "@components/Form"
import { TIER_NAMES } from "@components/Domain"
import { TYPE_STRING, TYPE_CHECKBOX } from "@components/Form/FormItem/types"

const LABEL_NAME = "Name"
const LABEL_INTERNAL = "Internal"
const LABEL_DESCRIPTION = "Description"


const TiersListInput = ({
  form,
  name,
  path,
  title,
  addTitle
}) => {
  const max = TIER_NAMES.length
  const onlyLastDelete = true

  // eslint-disable-next-line no-warning-comments
  // TODO: Should add auto complete options:
  const schema = [
    {
      name: "title",
      type: TYPE_STRING,
      required: true,
      maxLength: 64,
      itemProps: { noStyle: true },
      inputProps: { style: { width: "25%" }},
      placeholder: LABEL_NAME,
    },
    {
      name: "description",
      type: TYPE_STRING,
      required: true,
      maxLength: 256,
      itemProps: { noStyle: true },
      inputProps: { style: { width: "75%" } },
      placeholder: LABEL_DESCRIPTION,
    },
    {
      name: "isPrivate",
      type: TYPE_CHECKBOX,
      required: false,
      itemProps: { noStyle: true },
      inputProps: {
        label: LABEL_INTERNAL,
      }
    }
  ]

  const listInputProps = {
    min: 1,
    className: "horizontal-oriented",
    max,
    form,
    name,
    path,
    title,
    schema,
    addTitle,
    onlyLastDelete
  }

  return (
    <div style={{ marginBottom: 24 }}>
      <ListInput {...listInputProps} />
    </div>
  )
}

TiersListInput.propTypes = {
  form: FormShape.isRequired,
  name: PropTypes.arrayOf(PropTypes.string).isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  addTitle: PropTypes.string.isRequired,
}

export default TiersListInput
