import React, { useState } from "react"
import PropTypes from "prop-types"
import { Input } from "antd"
import { SearchOutlined } from "@ant-design/icons"

const LABEL_SEARCH = "Search"


const SearchInput = ({
  input = undefined,
  inputProps = {},
  onSearch
}) => {
  const [ searchValue, setSearchValue ] = useState()

  const reset = () => {
    setSearchValue("")
    onSearch("")
  }

  if (input) {
    input.current.reset = () => reset()
  }

  const handleSearch = event => {
    const { value } = event.target

    setSearchValue(value)

    const shouldSearch = value.length >= 3 || value.length === 0

    if (shouldSearch) {
      onSearch(value)
      return
    }

    onSearch("")
  }

  const onKeyDown = ({ key }) => {
    const isEsc = key === "Escape"

    if (!isEsc) {
      return
    }

    reset()
  }

  const style = { width: 280 }

  return (
    <Input
      allowClear
      value={searchValue}
      style={style}
      prefix={<SearchOutlined />}
      onChange={handleSearch}
      onKeyDown={onKeyDown}
      placeholder={LABEL_SEARCH}
      {...inputProps}
    />
  )
}

SearchInput.propTypes = {
  input: PropTypes.shape(),
  onSearch: PropTypes.func.isRequired,
  inputProps: PropTypes.shape(),
}

export default SearchInput
