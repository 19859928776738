import React, { useRef } from "react"

import Page from "@components/Page"

import ProjectsTable from "./ProjectsTable"

const LABEL_TITLE = "Archived Projects"


const ArchivedProjectsPage = () => {
  const table = useRef({})

  const onSearch = value =>
    table.current.search(value)

  return (
    <Page
      title={LABEL_TITLE}
      onSearch={onSearch}
    >
      <ProjectsTable table={table} />
    </Page>
  )
}

export default ArchivedProjectsPage
