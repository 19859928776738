import { Storage } from "@components/Authorization"
import { openNewTab } from "@components/Utils"
import { isLocal, LOCAL_DOMAIN } from "@components"
import { authenticateAsOperation } from "@api/services/identity"


const signInAs = (account, request, identity) => {
  const { organization } = identity
  const { domain } = organization

  let url = `https://${domain}/`

  const isLocalhost = isLocal && domain === LOCAL_DOMAIN

  if (isLocalhost) {
    url = `http://localhost:3000/`
  }

  const { id: accountId } = account

  const mutation = {
    accountId
  }

  return request(authenticateAsOperation, { mutation })
    .then(({ data }) => {
      Storage.setImpersonatedAuthentication(data.authenticationJwt)
      return openNewTab(url)
    })
}

export default signInAs
