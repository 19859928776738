import React from "react"
import { Image } from "antd"

import { DEFAULT_SIZE } from "@components/Page/Slider"
import { PROJECT_TYPES } from "@components/Domain"
import { getResizedImageUrl } from "@components/Storage"

import Photos from "./Photos"
import ProjectShape from "../../shapes/ProjectShape"
import getProjectPhotos from "./helpers/getProjectPhotos"

const PLACEHOLDER_URL = "https://placehold.co/2080x960/DEDEDE/FFF"


const Banner = ({ project }) => {
  const {
    name,
    type,
    coverImageUrl,
    investmentSubjects: properties = []
  } = project

  const isFund = type === PROJECT_TYPES.FUND

  const propertyPhotos = getProjectPhotos(properties)

  const hasPropertyPhotos = propertyPhotos.length > 0

  let src = PLACEHOLDER_URL

  if (hasPropertyPhotos) {
    const [ firstPhoto ] = propertyPhotos
    src = getResizedImageUrl(firstPhoto.url, DEFAULT_SIZE[0] * 2,  DEFAULT_SIZE[1] * 2)
  }

  if (coverImageUrl) {
    src = getResizedImageUrl(coverImageUrl, DEFAULT_SIZE[0] * 2,  DEFAULT_SIZE[1] * 2)
  }

  return (
    <>
      <Image
        src={src}
        alt={name}
        width="100%"
        preview={false}
      />

      {
        !isFund && (
          <Photos photos={propertyPhotos} />
        )
      }
    </>
  )
}

Banner.propTypes = {
  project: ProjectShape.isRequired,
}

export default Banner
