import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { List, Typography, Space, Row, Col, Tag } from "antd"

import { W1 } from "@components"
import { CopyValueButton } from "@components/Button"
import { ListSourceBankAccountsOutputShape } from "@api/services/transactions"

const LABEL_INVALID = "Invalid"
const LABEL_VERFIED = "Verified"
const LABEL_LINKED_TO = "Linked To"
const LABEL_UNVERIFIED = "Unverified"


const BankAccountsList = ({ bankAccounts = undefined }) => {
  const { getOrganizationName } = useOutletContext()

  if (!bankAccounts) {
    return null
  }

  const getStatusTag = ({ isSourceVerified, isInvalid }) => {
    if (isInvalid) {
      return <Tag color="red">{LABEL_INVALID}</Tag>
    }

    if (isSourceVerified) {
      return <Tag color="green">{LABEL_VERFIED}</Tag>
    }

    return <Tag>{LABEL_UNVERIFIED}</Tag>
  }

  const renderItem = bankAccount => (
    <List.Item>
      <Row style={{ width: "100%" }}>
        <Col flex="auto">
          <Space size={W1}>
            <Typography.Text strong>
              {getOrganizationName(bankAccount.organizationId)}
            </Typography.Text>

            <CopyValueButton value={bankAccount.organizationId} />
          </Space>
        </Col>
        <Col>
          {getStatusTag(bankAccount)}
        </Col>
      </Row>
    </List.Item>
  )

  const header =
    <Typography.Text strong>
      {LABEL_LINKED_TO}
    </Typography.Text>

  return (
    <List
      header={header}
      renderItem={renderItem}
      dataSource={bankAccounts}
    />
  )
}

BankAccountsList.propTypes = {
  bankAccounts: PropTypes.arrayOf(ListSourceBankAccountsOutputShape)
}

export default BankAccountsList
