import { App } from "antd"

import confirmAction from "@components/Page/helpers/confirmAction"
import {
  createDistributionTransactionOperation as createOperation,
  registerExternalDistributionTransactionOperation as registerOperation,
} from "@api/services/transactions"

const CREATE = "CREATE"
const REGISTER = "REGISTER"

const ACTIONS_MAP = {
  [CREATE]: {
    type: "primary",
    title: "Import Distributions",
    action: "Import Distributions",
    content: `By clicking "Import Distributions" below, you will import
distributions from the upload file. Once these distributions are imported, they
will be sent to the Controller for review and approval. Once approved,
distributions will be sent to the investors. Investors will be notified that
distributions are on their way to their bank account.`,
  },
  [REGISTER]: {
    type: "primary",
    title: "Backfill Distributions",
    action: "Import Distributions",
    content: "You're about to fill in designated distributions, investors are not notified, and all transactions are automatically approved and processed.",
  },
}


const useActions = (onSubmit) => {
  const { modal } = App.useApp()

  const action = actionName =>
    confirmAction(
      modal,
      ACTIONS_MAP[actionName].action,
      ACTIONS_MAP[actionName].title,
      ACTIONS_MAP[actionName].content,
      async () => {
        const isBackfillAction = actionName === REGISTER
        const operation = isBackfillAction
          ? registerOperation
          : createOperation

        onSubmit(operation, isBackfillAction)
      },
      ACTIONS_MAP[actionName].type,
    )

  const defaultImport = () => action(CREATE)
  const registerImport = () => action(REGISTER)

  return {
    defaultImport,
    registerImport,
  }
}

export default useActions
