import React from "react"

import { Value } from "@components/Text"

import {
  ENTITY_TYPE_TITLES_MAP,
  ENTITY_TAX_FILING_STATUS_TITLES_MAP,
  ENTITY_MEMBERSHIP_INTEREST_TRUSTEE,
  ENTITY_MEMBERSHIP_INTERESTS_TITLES_MAP,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TITLES_MAP,
  BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_IRA
} from "@components/Domain"

import breakRow from "./breakRow"
import renderBoolean from "./renderBoolean"
import renderAdditional from "./renderAdditional"
import renderResidenceState from "./renderResidenceState"

const EXCLUDE_KEYS = [
  "isUsCitizen",
  "hasEinNumber",
  "isUsPerson",
  "residenceState",
  "isLiabilityAccepted",
  "hasOtherUsResidenceState",
  "hasPurchasedExemptSecurities",
  "_shouldProvideEin",
  "_shouldProvideBeneficialOwnerEin",
]

const LABEL_TITLE = "Title of Membership Interests"
const LABEL_ADDRESS = "Address"
const LABEL_EIN_TRUST = "Trust EIN"
const LABEL_EIN_ENTITY = "Entity EIN"
const LABEL_NAME_TRUST = "Trust’s Full Legal Name"
const LABEL_TAX_STATUS = "Tax Filing Status"
const LABEL_INVESTMENTS = "Investments"
const LABEL_NAME_ENTITY = "Entity’s Full Legal Name"
const LABEL_ENTITY_TYPE = "Entity Type"
const LABEL_CUSTODIAN_EIN = "Custodian EIN"
const LABEL_INVESTOR_TITLE = "Your Title"
const LABEL_BENEFICIAL_EIN = "Beneficial Owner EIN"
const LABEL_BENEFICIAL_SSN = "Beneficial Owner SSN"
const LABEL_BENEFICIAL_ITIN = "Beneficial Owner ITIN"
const LABEL_RETIREMENT_PLAN = "Retirement Plan (IRA/SEP/Keogh/etc.)"
const LABEL_DISREGARDED_TRUST = "Disregarded Trust"
const LABEL_DISREGARDED_ENTITY = "Disregarded Entity"
const LABEL_BENEFICIAL_OWNER_NAME = "Beneficial Owner Name"
const LABEL_BENEFICIAL_OWNDER_ADDRESS = "Beneficial Owner Address"
const LABEL_BENEFICIAL_OWNER_TAX_STATUS = "Beneficial Owner Tax Filing Status"
const LABEL_BENEFICIAL_OWNER_ENTITY_TYPE = "Beneficial Owner Entity Type"
const LABEL_BENEFICIAL_OWNER_CUSTODIAN_EIN = "Beneficial Owner Custodian EIN"


const renderProfileEntity = (profileEntity, investmentsValue, investor, additionalExtra) => {
  const {
    name,
    address,
    einNumber,
    entityType,
    taxFilingStatus,
    isRetirementPlan,
    isDisregardedForTaxPurposes,
    beneficialOwnerName,
    beneficialOwnerSsnNumber,
    beneficialOwnerEinNumber,
    beneficialOwnerEntityType,
    beneficialOwnerTaxFilingStatus,
    beneficialOwnerAddress,
    beneficialOwnerCustodianEinNumber,
    membershipInterestsTitle,
    title,
    hasSsn,
    profileName,
    isUsPerson,
    ...restOfProfile
  } = profileEntity

  const items = [
    {
      span: 2,
      label: LABEL_TITLE,
      value: ENTITY_MEMBERSHIP_INTERESTS_TITLES_MAP[membershipInterestsTitle]?.replace("As ", ""),
    }
  ]

  const isTrustee = membershipInterestsTitle === ENTITY_MEMBERSHIP_INTEREST_TRUSTEE

  items.push({
    label: isTrustee
      ? LABEL_NAME_TRUST
      : LABEL_NAME_ENTITY,
    value: name,
  })

  if (!isTrustee) {
    breakRow(items)

    items.push({
      span: 2,
      label: LABEL_INVESTOR_TITLE,
      value: title,
    })
  }

  if (investmentsValue) {
    items.push({
      label: LABEL_INVESTMENTS,
      value: investmentsValue
    })
  }

  if (einNumber) {
    items.push({
      label: isTrustee
        ? LABEL_EIN_TRUST
        : LABEL_EIN_ENTITY,
      value: <Value value={einNumber} isEin />,
    })
  }

  const hasEntityType = !isTrustee && entityType

  if (hasEntityType) {
    items.push({
      label: LABEL_ENTITY_TYPE,
      value: ENTITY_TYPE_TITLES_MAP[entityType],
    })
  }

  if (taxFilingStatus) {
    items.push({
      label: LABEL_TAX_STATUS,
      value: ENTITY_TAX_FILING_STATUS_TITLES_MAP[taxFilingStatus],
    })
  }

  items.push({
    label: isTrustee
      ? LABEL_DISREGARDED_TRUST
      : LABEL_DISREGARDED_ENTITY,
    value: renderBoolean(isDisregardedForTaxPurposes)
  })

  const residenceRegion =
    address?.addressRegion || beneficialOwnerAddress?.addressRegion

  const residenceCountry =
    address?.addressCountry || beneficialOwnerAddress?.addressCountry

  items.push(
    ...renderResidenceState(profileEntity, residenceCountry, residenceRegion)
  )

  if (address) {
    items.push({
      label: LABEL_ADDRESS,
      value: <Value value={address} />,
    })
  }

  const isRetirementPlanDefined = isRetirementPlan !== undefined

  if (isRetirementPlanDefined) {
    items.push({
      label: LABEL_RETIREMENT_PLAN,
      value: renderBoolean(isRetirementPlan)
    })
  }

  if (beneficialOwnerEntityType) {
    items.push({
      label: LABEL_BENEFICIAL_OWNER_ENTITY_TYPE,
      value: ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TITLES_MAP[beneficialOwnerEntityType],
    })
  }

  if (beneficialOwnerTaxFilingStatus) {
    items.push({
      label: LABEL_BENEFICIAL_OWNER_TAX_STATUS,
      value: BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP[beneficialOwnerTaxFilingStatus],
    })
  }

  if (beneficialOwnerName) {
    items.push({
      label: LABEL_BENEFICIAL_OWNER_NAME,
      value: beneficialOwnerName,
    })
  }

  const isNotUsPerson = isUsPerson === false

  if (beneficialOwnerSsnNumber) {
    items.push({
      label: isNotUsPerson
        ? LABEL_BENEFICIAL_ITIN
        : LABEL_BENEFICIAL_SSN,
      value: <Value value={beneficialOwnerSsnNumber} />,
    })
  }

  const isIra =
    isDisregardedForTaxPurposes && beneficialOwnerEntityType === ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_IRA

  if (beneficialOwnerEinNumber) {
    items.push({
      label: isIra
        ? LABEL_CUSTODIAN_EIN
        : LABEL_BENEFICIAL_EIN,
      value: <Value value={beneficialOwnerEinNumber} isEin />,
    })
  }

  if (beneficialOwnerAddress) {
    items.push({
      label: LABEL_BENEFICIAL_OWNDER_ADDRESS,
      value: <Value value={beneficialOwnerAddress} />,
    })
  }

  if (beneficialOwnerCustodianEinNumber) {
    items.push({
      label: LABEL_BENEFICIAL_OWNER_CUSTODIAN_EIN,
      value: <Value value={beneficialOwnerCustodianEinNumber} />,
    })
  }

  breakRow(items)
  items.push(renderAdditional(profileEntity, additionalExtra))

  const missingItems = Object.keys(restOfProfile)
    .filter(key => !EXCLUDE_KEYS.includes(key))
    .map(label => ({ label, value: JSON.stringify(profileEntity[label]) }))

  return [
    ...items,
    ...missingItems
  ]
}

export default renderProfileEntity
