import React from "react"
import { Tag } from "antd"
import capitalize from "lodash.capitalize"

import { ColorEnumKeys } from "@api/services/backstage/shapes/ColorEnum"


const renderColorOptions = (style = {}) => {
  const options = ColorEnumKeys
    .map(value => ({
      value,
      label: (
        <Tag
          key={value}
          size="small"
          style={style}
          color={value.toLowerCase()}
        >
          {capitalize(value)}
        </Tag>
      )
    }))

  return options
}

export default renderColorOptions
