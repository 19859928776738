import { CampaignStatusEnumKeys } from "./shapes/CampaignStatusEnum"

const IndexCampaignsItemSchema = {
  status: { enum: CampaignStatusEnumKeys },
  scheduledAt: { format: 'date-time' },
  shouldSendImmediately: { type: 'boolean' },
  fromAddress: { format: 'email' },
  replyToAddress: { format: 'email' },
  shouldUseRecipientTimeZone: { type: 'boolean' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexCampaignsItemSchema
