import React from "react"
import PropTypes from "prop-types"

import ReactMarkdown from "react-markdown"
import rehypeHighlight from "rehype-highlight"
import rehypeExternalLinks from "rehype-external-links"


const Markdown = ({
  text = undefined,
  children = "",
  ...extraProps
}) => {
  const rehypePlugins = [
    rehypeHighlight,
    [ rehypeExternalLinks, { target: '_blank' } ]
  ]

  return (
    <ReactMarkdown
      rehypePlugins={rehypePlugins}
      {...extraProps}
    >
      {text || children}
    </ReactMarkdown>
  )
}

Markdown.propTypes = {
  text: PropTypes.string,
  children: PropTypes.string,
}

export default Markdown
