import React from "react"
import { useTheme, View, Text } from "@aws-amplify/ui-react"

import { Markdown } from "@components/Text"
import { getConfig } from "@components/Config"

const PLATFORM_EMAIL = getConfig('PLATFORM_EMAIL')

const LABEL_TEXT = `
If you haven't received a confirmation code, try resending it by clicking
"Back to Sign In" and signing in again.

If you're having trouble signing in, please contact us at
[${PLATFORM_EMAIL}](mailto:${PLATFORM_EMAIL}). We'll assist you promptly.`


const ConfirmSignInFooter = () => {
  const { tokens } = useTheme()

  const color = tokens.colors.neutral[60]

  return (
    <View>
      <Text
        color={color}
        fontSize="small"
      >
        <Markdown text={LABEL_TEXT} />
      </Text>
    </View>
  )
}

export default ConfirmSignInFooter
